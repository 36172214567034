/*
 * GoJS v2.3.15 JavaScript Library for HTML Diagrams, https://gojs.net
 * GoJS and Northwoods Software are registered trademarks of Northwoods Software Corporation, https://www.nwoods.com.
 * Copyright (C) 1998-2023 by Northwoods Software Corporation.  All Rights Reserved.
 * THIS SOFTWARE IS LICENSED.  THE LICENSE AGREEMENT IS AT: https://gojs.net/2.3.15/license.html.
 * DO NOT MODIFY THIS FILE.  DO NOT DISTRIBUTE A MODIFIED COPY OF THE CONTENTS OF THIS FILE.
 */
(function() { var t;function aa(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function ba(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:aa(a)}}function da(a){for(var b,c=[];!(b=a.next()).done;)c.push(b.value);return c}var ea="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},fa;
if("function"==typeof Object.setPrototypeOf)fa=Object.setPrototypeOf;else{var ha;a:{var ia={a:!0},ja={};try{ja.__proto__=ia;ha=ja.a;break a}catch(a){}ha=!1}fa=ha?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var ka=fa;
function la(a,b){a.prototype=ea(b.prototype);a.prototype.constructor=a;if(ka)ka(a,b);else for(var c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.QA=b.prototype}
var ma="undefined"!=typeof global&&null!=global?global:self,oa="function"==typeof Object.assign?Object.assign:function(a,b){for(var c=1;c<arguments.length;c++){var d=arguments[c];if(d)for(var e in d)Object.prototype.hasOwnProperty.call(d,e)&&(a[e]=d[e])}return a},pa="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){a!=Array.prototype&&a!=Object.prototype&&(a[b]=c.value)};
function qa(a,b){if(b){var c=ma;a=a.split(".");for(var d=0;d<a.length-1;d++){var e=a[d];e in c||(c[e]={});c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&pa(c,a,{writable:!0,value:b})}}qa("Object.assign",function(a){return a||oa});qa("Array.prototype.fill",function(a){return a?a:function(a,c,d){var b=this.length||0;0>c&&(c=Math.max(0,b+c));if(null==d||d>b)d=b;d=Number(d);0>d&&(d=Math.max(0,b+d));for(c=Number(c||0);c<d;c++)this[c]=a;return this}});var x="object"===typeof globalThis&&globalThis||"object"===typeof global&&global.global===global&&global||"object"===typeof self&&self.self===self&&self||{};void 0===x.requestAnimationFrame&&(void 0===x.setImmediate?x.requestAnimationFrame=function(a){x.setTimeout(a,0)}:x.requestAnimationFrame=x.setImmediate);function ra(){}function sa(a,b){var c=-1;return function(){var d=this,e=arguments;-1!==c&&x.clearTimeout(c);c=ta(function(){c=-1;a.apply(d,e)},b)}}function ta(a,b){return x.setTimeout(a,b)}
function ua(a){return x.document.createElement(a)}function C(a){throw Error(a);}function va(a,b){a="The object is frozen, so its properties cannot be set: "+a.toString();void 0!==b&&(a+="  to value: "+b);C(a)}function xa(a,b,c,d){c=null===c?"*":"string"===typeof c?c:"function"===typeof c&&"string"===typeof c.className?c.className:"";void 0!==d&&(c+="."+d);C(c+" is not in the range "+b+": "+a)}function ya(a){x.console&&x.console.log(a)}function za(a){return"object"===typeof a&&null!==a}
function Aa(a){return Array.isArray(a)||x.NodeList&&a instanceof x.NodeList||x.HTMLCollection&&a instanceof x.HTMLCollection}function Ba(a){return Array.prototype.slice.call(a)}function Ca(a,b,c){Array.isArray(a)?b>=a.length?a.push(c):a.splice(b,0,c):C("Cannot insert an object into an HTMLCollection or NodeList: "+c+" at "+b)}function Da(a,b){Array.isArray(a)?b>=a.length?a.pop():a.splice(b,1):C("Cannot remove an object from an HTMLCollection or NodeList at "+b)}
function Ea(){var a=Fa.pop();return void 0===a?[]:a}function Ga(a){a.length=0;Fa.push(a)}function Ha(a){if("function"===typeof a){if(a.className)return a.className;if(a.name)return a.name;var b=a.toString();b=b.substring(9,b.indexOf("(")).trim();if(""!==b)return a._className=b}else if(za(a)&&a.constructor)return Ha(a.constructor);return typeof a}
function Ia(a){var b=a;za(a)&&(a.text?b=a.text:a.name?b=a.name:void 0!==a.key?b=a.key:void 0!==a.id?b=a.id:a.constructor===Object&&(a.Text?b=a.Text:a.Name?b=a.Name:void 0!==a.Key?b=a.Key:void 0!==a.Id?b=a.Id:void 0!==a.ID&&(b=a.ID)));return void 0===b?"undefined":null===b?"null":b.toString()}function Ja(a,b){if(a.hasOwnProperty(b))return!0;for(a=Object.getPrototypeOf(a);a&&a!==Function;){if(a.hasOwnProperty(b))return!0;var c=a.DA;if(c&&c[b])return!0;a=Object.getPrototypeOf(a)}return!1}
function Ka(a,b,c){Object.defineProperty(La.prototype,a,{get:b,set:c})}
function Ma(a){for(var b=[],c=0;256>c;c++)b["0123456789abcdef".charAt(c>>4)+"0123456789abcdef".charAt(c&15)]=String.fromCharCode(c);a.length%2&&(a="0"+a);c=[];for(var d=0,e=0;e<a.length;e+=2)c[d++]=b[a.substr(e,2)];a=c.join("");a=""===a?"0":a;b=[];for(c=0;256>c;c++)b[c]=c;for(c=d=0;256>c;c++)d=(d+b[c]+119)%256,e=b[c],b[c]=b[d],b[d]=e;d=c=0;for(var f="",g=0;g<a.length;g++)c=(c+1)%256,d=(d+b[c])%256,e=b[c],b[c]=b[d],b[d]=e,f+=String.fromCharCode(a.charCodeAt(g)^b[(b[c]+b[d])%256]);return f}
var Na=void 0!==x.navigator&&0<x.navigator.userAgent.indexOf("Trident/7"),Pa=void 0!==x.navigator&&void 0!==x.navigator.platform&&null!==x.navigator.platform.match(/(iPhone|iPod|iPad|Mac)/i),Fa=[];Object.freeze([]);ra.className="Util";ra.Dx="32ab5ff3b26f42dc0ed90f21472913b5";ra.adym="gojs.net";ra.vfo="28e645fdb263";ra.className="Util";function D(a,b,c){Qa(this);this.j=a;this.xa=b;this.o=c}D.prototype.toString=function(){return"EnumValue."+this.xa};
function Ra(a,b){return void 0===b||null===b||""===b?null:a[b]}ma.Object.defineProperties(D.prototype,{classType:{get:function(){return this.j}},name:{get:function(){return this.xa}},value:{get:function(){return this.o}}});D.className="EnumValue";function Ta(){this.fw=[]}Ta.prototype.toString=function(){return this.fw.join("")};Ta.prototype.add=function(a){""!==a&&this.fw.push(a)};Ta.className="StringBuilder";
function Ua(){}Ua.className="PropertyCollection";
var Wa={FA:!1,KA:!1,HA:!1,JA:!1,OA:!1,MA:!1,ox:!1,GA:null,trace:function(a){x.console&&x.console.log(a)},IA:function(a,b,c,d){a.strokeStyle="red";a.fillStyle="red";a.font="8px sans-serif";a.beginPath();a.moveTo(-10,0);a.lineTo(10,0);a.moveTo(0,-10);a.lineTo(0,10);a.stroke();a.setTransform(1,0,0,1,0,0);a.scale(c,c);a.transform(b.m11,b.m12,b.m21,b.m22,b.dx,b.dy);a.lineWidth=2;a.beginPath();a.moveTo(d.left,d.top+20);a.lineTo(d.left,d.top);a.lineTo(d.left+20,d.top);a.moveTo(d.right,d.bottom-20);a.lineTo(d.right,
d.bottom);a.lineTo(d.right-20,d.bottom);a.stroke();a.fillText("DB: "+Math.round(d.x)+", "+Math.round(d.y)+", "+Math.round(d.width)+", "+Math.round(d.height),d.left,d.top-5)},EA:function(a){var b={},c;for(c in a){b.x=c;if("licenseKey"!==b.x){var d=a[b.x];if(void 0!==d.prototype){b.Zl=Object.getOwnPropertyNames(d.prototype);for(var e={Sj:0};e.Sj<b.Zl.length;e={Sj:e.Sj},e.Sj++){var f=Object.getOwnPropertyDescriptor(d.prototype,b.Zl[e.Sj]);void 0!==f.get&&void 0===f.set&&Object.defineProperty(d.prototype,
b.Zl[e.Sj],{set:function(a,b){return function(){throw Error("Property "+a.Zl[b.Sj]+" of "+a.x+" is read-only.");}}(b,e)})}}}b={Zl:b.Zl,x:b.x}}}};function Xa(){}Xa.prototype.reset=function(){};Xa.prototype.next=function(){return!1};Xa.prototype.Ad=function(){return!1};Xa.prototype.first=function(){return null};Xa.prototype.any=function(){return!1};Xa.prototype.all=function(){return!0};Xa.prototype.each=function(){return this};Xa.prototype.map=function(){return this};Xa.prototype.filter=function(){return this};
Xa.prototype.de=function(){};Xa.prototype.toString=function(){return"EmptyIterator"};ma.Object.defineProperties(Xa.prototype,{iterator:{get:function(){return this}},count:{get:function(){return 0}}});Xa.prototype.first=Xa.prototype.first;Xa.prototype.hasNext=Xa.prototype.Ad;Xa.prototype.next=Xa.prototype.next;Xa.prototype.reset=Xa.prototype.reset;var Ya=null;Xa.className="EmptyIterator";Ya=new Xa;function Za(a){this.key=-1;this.value=a}
Za.prototype.reset=function(){this.key=-1};Za.prototype.next=function(){return-1===this.key?(this.key=0,!0):!1};Za.prototype.Ad=function(){return this.next()};Za.prototype.first=function(){this.key=0;return this.value};Za.prototype.any=function(a){this.key=-1;return a(this.value)};Za.prototype.all=function(a){this.key=-1;return a(this.value)};Za.prototype.each=function(a){this.key=-1;a(this.value);return this};Za.prototype.map=function(a){return new Za(a(this.value))};
Za.prototype.filter=function(a){return a(this.value)?new Za(this.value):Ya};Za.prototype.de=function(){this.value=null};Za.prototype.toString=function(){return"SingletonIterator("+this.value+")"};ma.Object.defineProperties(Za.prototype,{iterator:{get:function(){return this}},count:{get:function(){return 1}}});Za.prototype.first=Za.prototype.first;Za.prototype.hasNext=Za.prototype.Ad;Za.prototype.next=Za.prototype.next;
Za.prototype.reset=Za.prototype.reset;Za.className="SingletonIterator";function $a(a){this.Cb=a;this.qf=null;a.Sa=null;this.qa=a.Ja;this.Za=-1}$a.prototype.reset=function(){var a=this.Cb;a.Sa=null;this.qa=a.Ja;this.Za=-1};$a.prototype.next=function(){var a=this.Cb;if(a.Ja!==this.qa&&0>this.key)return!1;a=a.s;var b=a.length,c=++this.Za,d=this.qf;if(null!==d)for(;c<b;){var e=a[c];if(d(e))return this.key=this.Za=c,this.value=e,!0;c++}else{if(c<b)return this.key=c,this.value=a[c],!0;this.de()}return!1};
$a.prototype.Ad=function(){return this.next()};$a.prototype.first=function(){var a=this.Cb;this.qa=a.Ja;this.Za=0;a=a.s;var b=a.length,c=this.qf;if(null!==c){for(var d=0;d<b;){var e=a[d];if(c(e))return this.key=this.Za=d,this.value=e;d++}return null}return 0<b?(a=a[0],this.key=0,this.value=a):null};$a.prototype.any=function(a){var b=this.Cb;b.Sa=null;this.Za=-1;b=b.s;for(var c=b.length,d=this.qf,e=0;e<c;e++){var f=b[e];if((null===d||d(f))&&a(f))return!0}return!1};
$a.prototype.all=function(a){var b=this.Cb;b.Sa=null;this.Za=-1;b=b.s;for(var c=b.length,d=this.qf,e=0;e<c;e++){var f=b[e];if((null===d||d(f))&&!a(f))return!1}return!0};$a.prototype.each=function(a){var b=this.Cb;b.Sa=null;this.Za=-1;b=b.s;for(var c=b.length,d=this.qf,e=0;e<c;e++){var f=b[e];(null===d||d(f))&&a(f)}return this};
$a.prototype.map=function(a){var b=this.Cb;b.Sa=null;this.Za=-1;var c=[];b=b.s;for(var d=b.length,e=this.qf,f=0;f<d;f++){var g=b[f];(null===e||e(g))&&c.push(a(g))}a=new F;a.s=c;a.yb();return a.iterator};$a.prototype.filter=function(a){var b=this.Cb;b.Sa=null;this.Za=-1;var c=[];b=b.s;for(var d=b.length,e=this.qf,f=0;f<d;f++){var g=b[f];(null===e||e(g))&&a(g)&&c.push(g)}a=new F;a.s=c;a.yb();return a.iterator};
$a.prototype.de=function(){this.key=-1;this.value=null;this.qa=-1;this.qf=null;this.Cb.Sa=this};$a.prototype.toString=function(){return"ListIterator@"+this.Za+"/"+this.Cb.count};
ma.Object.defineProperties($a.prototype,{iterator:{get:function(){return this}},predicate:{get:function(){return this.qf},set:function(a){this.qf=a}},count:{get:function(){var a=this.qf;if(null!==a){for(var b=0,c=this.Cb.s,d=c.length,e=0;e<d;e++)a(c[e])&&b++;return b}return this.Cb.s.length}}});$a.prototype.first=$a.prototype.first;$a.prototype.hasNext=$a.prototype.Ad;$a.prototype.next=$a.prototype.next;
$a.prototype.reset=$a.prototype.reset;$a.className="ListIterator";function ab(a){this.Cb=a;a.Hg=null;this.qa=a.Ja;this.Za=a.s.length}ab.prototype.reset=function(){var a=this.Cb;a.Hg=null;this.qa=a.Ja;this.Za=a.s.length};ab.prototype.next=function(){var a=this.Cb;if(a.Ja!==this.qa&&0>this.key)return!1;var b=--this.Za;if(0<=b)return this.key=b,this.value=a.s[b],!0;this.de();return!1};ab.prototype.Ad=function(){return this.next()};
ab.prototype.first=function(){var a=this.Cb;this.qa=a.Ja;var b=a.s;this.Za=a=b.length-1;return 0<=a?(b=b[a],this.key=a,this.value=b):null};ab.prototype.any=function(a){var b=this.Cb;b.Hg=null;b=b.s;var c=b.length;this.Za=c;for(--c;0<=c;c--)if(a(b[c]))return!0;return!1};ab.prototype.all=function(a){var b=this.Cb;b.Hg=null;b=b.s;var c=b.length;this.Za=c;for(--c;0<=c;c--)if(!a(b[c]))return!1;return!0};
ab.prototype.each=function(a){var b=this.Cb;b.Hg=null;b=b.s;var c=b.length;this.Za=c;for(--c;0<=c;c--)a(b[c]);return this};ab.prototype.map=function(a){var b=this.Cb;b.Hg=null;var c=[];b=b.s;var d=b.length;this.Za=d;for(--d;0<=d;d--)c.push(a(b[d]));a=new F;a.s=c;a.yb();return a.iterator};ab.prototype.filter=function(a){var b=this.Cb;b.Hg=null;var c=[];b=b.s;var d=b.length;this.Za=d;for(--d;0<=d;d--){var e=b[d];a(e)&&c.push(e)}a=new F;a.s=c;a.yb();return a.iterator};
ab.prototype.de=function(){this.key=-1;this.value=null;this.qa=-1;this.Cb.Hg=this};ab.prototype.toString=function(){return"ListIteratorBackwards("+this.Za+"/"+this.Cb.count+")"};ma.Object.defineProperties(ab.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.Cb.s.length}}});ab.prototype.first=ab.prototype.first;ab.prototype.hasNext=ab.prototype.Ad;ab.prototype.next=ab.prototype.next;ab.prototype.reset=ab.prototype.reset;
ab.className="ListIteratorBackwards";function F(a){Qa(this);this._isFrozen=!1;this.s=[];this.Ja=0;this.Hg=this.Sa=null;"string"===typeof a&&C("Error: Since 2.0 List/Map/Set constructors no longer take an argument that enforces type.");void 0!==a&&this.addAll(a)}t=F.prototype;t.yb=function(){var a=this.Ja;a++;999999999<a&&(a=0);this.Ja=a};t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){this._isFrozen=!1;return this};t.toString=function(){return"List()#"+bb(this)};
t.add=function(a){if(null===a)return this;this._isFrozen&&va(this,a);this.s.push(a);this.yb();return this};t.push=function(a){this.add(a)};t.addAll=function(a){if(null===a)return this;this._isFrozen&&va(this);var b=this.s;if(Aa(a))for(var c=a.length,d=0;d<c;d++)b.push(a[d]);else for(a=a.iterator;a.next();)b.push(a.value);this.yb();return this};t.clear=function(){this._isFrozen&&va(this);this.s.length=0;this.yb()};t.contains=function(a){return null===a?!1:-1!==this.s.indexOf(a)};t.has=function(a){return this.contains(a)};
t.indexOf=function(a){return null===a?-1:this.s.indexOf(a)};t.M=function(a){var b=this.s;(0>a||a>=b.length)&&xa(a,"0 <= i < length",F,"elt:i");return b[a]};t.get=function(a){return this.M(a)};t.cd=function(a,b){var c=this.s;(0>a||a>=c.length)&&xa(a,"0 <= i < length",F,"setElt:i");this._isFrozen&&va(this,a);c[a]=b};t.set=function(a,b){this.cd(a,b)};t.first=function(){var a=this.s;return 0===a.length?null:a[0]};t.kc=function(){var a=this.s,b=a.length;return 0<b?a[b-1]:null};
t.pop=function(){this._isFrozen&&va(this);var a=this.s;return 0<a.length?a.pop():null};F.prototype.any=function(a){for(var b=this.s,c=b.length,d=0;d<c;d++)if(a(b[d]))return!0;return!1};F.prototype.all=function(a){for(var b=this.s,c=b.length,d=0;d<c;d++)if(!a(b[d]))return!1;return!0};F.prototype.each=function(a){for(var b=this.s,c=b.length,d=0;d<c;d++)a(b[d]);return this};F.prototype.map=function(a){for(var b=new F,c=[],d=this.s,e=d.length,f=0;f<e;f++)c.push(a(d[f]));b.s=c;b.yb();return b};
F.prototype.filter=function(a){for(var b=new F,c=[],d=this.s,e=d.length,f=0;f<e;f++){var g=d[f];a(g)&&c.push(g)}b.s=c;b.yb();return b};t=F.prototype;t.Hb=function(a,b){0>a&&xa(a,">= 0",F,"insertAt:i");this._isFrozen&&va(this,a);var c=this.s;a>=c.length?c.push(b):c.splice(a,0,b);this.yb()};t.remove=function(a){if(null===a)return!1;this._isFrozen&&va(this,a);var b=this.s;a=b.indexOf(a);if(-1===a)return!1;a===b.length-1?b.pop():b.splice(a,1);this.yb();return!0};t.delete=function(a){return this.remove(a)};
t.mb=function(a){var b=this.s;(0>a||a>=b.length)&&xa(a,"0 <= i < length",F,"removeAt:i");this._isFrozen&&va(this,a);a===b.length-1?b.pop():b.splice(a,1);this.yb()};t.removeRange=function(a,b){var c=this.s,d=c.length;if(0>a)a=0;else if(a>=d)return this;if(0>b)return this;b>=d&&(b=d-1);if(a>b)return this;this._isFrozen&&va(this);for(var e=a,f=b+1;f<d;)c[e++]=c[f++];c.length=d-(b-a+1);this.yb();return this};
F.prototype.copy=function(){var a=new F,b=this.s;0<b.length&&(a.s=Array.prototype.slice.call(b));return a};t=F.prototype;t.Fa=function(){for(var a=this.s,b=this.count,c=Array(b),d=0;d<b;d++)c[d]=a[d];return c};t.Jv=function(){for(var a=new H,b=this.s,c=this.count,d=0;d<c;d++)a.add(b[d]);return a};t.sort=function(a){this._isFrozen&&va(this);this.s.sort(a);this.yb();return this};
t.zi=function(a,b,c){var d=this.s,e=d.length;void 0===b&&(b=0);void 0===c&&(c=e);this._isFrozen&&va(this);var f=c-b;if(1>=f)return this;(0>b||b>=e-1)&&xa(b,"0 <= from < length",F,"sortRange:from");if(2===f)return c=d[b],e=d[b+1],0<a(c,e)&&(d[b]=e,d[b+1]=c,this.yb()),this;if(0===b)if(c>=e)d.sort(a);else for(b=d.slice(0,c),b.sort(a),a=0;a<c;a++)d[a]=b[a];else if(c>=e)for(c=d.slice(b),c.sort(a),a=b;a<e;a++)d[a]=c[a-b];else for(e=d.slice(b,c),e.sort(a),a=b;a<c;a++)d[a]=e[a-b];this.yb();return this};
t.reverse=function(){this._isFrozen&&va(this);this.s.reverse();this.yb();return this};
ma.Object.defineProperties(F.prototype,{_dataArray:{get:function(){return this.s}},count:{get:function(){return this.s.length}},size:{get:function(){return this.s.length}},length:{get:function(){return this.s.length}},iterator:{get:function(){if(0>=this.s.length)return Ya;var a=this.Sa;return null!==a?(a.reset(),a):new $a(this)}},iteratorBackwards:{
get:function(){if(0>=this.s.length)return Ya;var a=this.Hg;return null!==a?(a.reset(),a):new ab(this)}}});F.prototype.reverse=F.prototype.reverse;F.prototype.sortRange=F.prototype.zi;F.prototype.sort=F.prototype.sort;F.prototype.toSet=F.prototype.Jv;F.prototype.toArray=F.prototype.Fa;F.prototype.removeRange=F.prototype.removeRange;F.prototype.removeAt=F.prototype.mb;F.prototype["delete"]=F.prototype.delete;F.prototype.remove=F.prototype.remove;F.prototype.insertAt=F.prototype.Hb;
F.prototype.pop=F.prototype.pop;F.prototype.last=F.prototype.kc;F.prototype.first=F.prototype.first;F.prototype.set=F.prototype.set;F.prototype.setElt=F.prototype.cd;F.prototype.get=F.prototype.get;F.prototype.elt=F.prototype.M;F.prototype.indexOf=F.prototype.indexOf;F.prototype.has=F.prototype.has;F.prototype.contains=F.prototype.contains;F.prototype.clear=F.prototype.clear;F.prototype.addAll=F.prototype.addAll;F.prototype.push=F.prototype.push;F.prototype.add=F.prototype.add;F.prototype.thaw=F.prototype.ka;
F.prototype.freeze=F.prototype.freeze;F.className="List";function cb(a){this.eg=a;a.Sa=null;this.qa=a.Ja;this.sa=null}cb.prototype.reset=function(){var a=this.eg;a.Sa=null;this.qa=a.Ja;this.sa=null};cb.prototype.next=function(){var a=this.eg;if(a.Ja!==this.qa&&null===this.key)return!1;var b=this.sa;b=null===b?a.ea:b.va;if(null!==b)return this.sa=b,this.value=b.value,this.key=b.key,!0;this.de();return!1};cb.prototype.Ad=function(){return this.next()};
cb.prototype.first=function(){var a=this.eg;this.qa=a.Ja;a=a.ea;if(null!==a){this.sa=a;var b=a.value;this.key=a.key;return this.value=b}return null};cb.prototype.any=function(a){var b=this.eg;this.sa=b.Sa=null;for(b=b.ea;null!==b;){if(a(b.value))return!0;b=b.va}return!1};cb.prototype.all=function(a){var b=this.eg;this.sa=b.Sa=null;for(b=b.ea;null!==b;){if(!a(b.value))return!1;b=b.va}return!0};cb.prototype.each=function(a){var b=this.eg;this.sa=b.Sa=null;for(b=b.ea;null!==b;)a(b.value),b=b.va;return this};
cb.prototype.map=function(a){var b=this.eg;b.Sa=null;var c=new F;for(b=b.ea;null!==b;)c.add(a(b.value)),b=b.va;return c.iterator};cb.prototype.filter=function(a){var b=this.eg;b.Sa=null;var c=new F;for(b=b.ea;null!==b;){var d=b.value;a(d)&&c.add(d);b=b.va}return c.iterator};cb.prototype.de=function(){this.value=this.key=null;this.qa=-1;this.eg.Sa=this};cb.prototype.toString=function(){return null!==this.sa?"SetIterator@"+this.sa.value:"SetIterator"};
ma.Object.defineProperties(cb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.eg.Ub}}});cb.prototype.first=cb.prototype.first;cb.prototype.hasNext=cb.prototype.Ad;cb.prototype.next=cb.prototype.next;cb.prototype.reset=cb.prototype.reset;cb.className="SetIterator";
function H(a){Qa(this);this._isFrozen=!1;this.Vb={};this.Ub=0;this.Sa=null;this.Ja=0;this.kf=this.ea=null;"string"===typeof a&&C("Error: Since 2.0 List/Map/Set constructors no longer take an argument that enforces type.");void 0!==a&&this.addAll(a)}t=H.prototype;t.yb=function(){var a=this.Ja;a++;999999999<a&&(a=0);this.Ja=a};t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){this._isFrozen=!1;return this};t.toString=function(){return"Set()#"+bb(this)};
t.add=function(a){if(null===a)return this;this._isFrozen&&va(this,a);var b=a;za(a)&&(b=db(a));void 0===this.Vb[b]&&(this.Ub++,a=new hb(a,a),this.Vb[b]=a,b=this.kf,null===b?this.ea=a:(a.ll=b,b.va=a),this.kf=a,this.yb());return this};t.addAll=function(a){if(null===a)return this;this._isFrozen&&va(this);if(Aa(a))for(var b=a.length,c=0;c<b;c++)this.add(a[c]);else for(a=a.iterator;a.next();)this.add(a.value);return this};
t.contains=function(a){if(null===a)return!1;var b=a;return za(a)&&(b=bb(a),void 0===b)?!1:void 0!==this.Vb[b]};t.has=function(a){return this.contains(a)};t.Ty=function(a){if(null===a)return!0;for(a=a.iterator;a.next();)if(!this.contains(a.value))return!1;return!0};t.Uy=function(a){if(null===a)return!0;for(a=a.iterator;a.next();)if(this.contains(a.value))return!0;return!1};t.first=function(){var a=this.ea;return null===a?null:a.value};
H.prototype.any=function(a){for(var b=this.ea;null!==b;){if(a(b.value))return!0;b=b.va}return!1};H.prototype.all=function(a){for(var b=this.ea;null!==b;){if(!a(b.value))return!1;b=b.va}return!0};H.prototype.each=function(a){for(var b=this.ea;null!==b;)a(b.value),b=b.va;return this};H.prototype.map=function(a){for(var b=new H,c=this.ea;null!==c;)b.add(a(c.value)),c=c.va;return b};H.prototype.filter=function(a){for(var b=new H,c=this.ea;null!==c;){var d=c.value;a(d)&&b.add(d);c=c.va}return b};t=H.prototype;
t.remove=function(a){if(null===a)return!1;this._isFrozen&&va(this,a);var b=a;if(za(a)&&(b=bb(a),void 0===b))return!1;a=this.Vb[b];if(void 0===a)return!1;var c=a.va,d=a.ll;null!==c&&(c.ll=d);null!==d&&(d.va=c);this.ea===a&&(this.ea=c);this.kf===a&&(this.kf=d);delete this.Vb[b];this.Ub--;this.yb();return!0};t.delete=function(a){return this.remove(a)};
t.hp=function(a){if(null===a)return this;this._isFrozen&&va(this);if(Aa(a))for(var b=a.length,c=0;c<b;c++)this.remove(a[c]);else for(a=a.iterator;a.next();)this.remove(a.value);return this};t.qA=function(a){if(null===a||0===this.count)return this;this._isFrozen&&va(this);var b=new H;b.addAll(a);a=[];for(var c=this.iterator;c.next();){var d=c.value;b.contains(d)||a.push(d)}this.hp(a);return this};
t.clear=function(){this._isFrozen&&va(this);this.Vb={};this.Ub=0;null!==this.Sa&&this.Sa.reset();this.kf=this.ea=null;this.yb()};H.prototype.copy=function(){var a=new H,b=this.Vb,c;for(c in b)a.add(b[c].value);return a};H.prototype.Fa=function(){var a=Array(this.Ub),b=this.Vb,c=0,d;for(d in b)a[c]=b[d].value,c++;return a};H.prototype.Iv=function(){var a=new F,b=this.Vb,c;for(c in b)a.add(b[c].value);return a};function Qa(a){a.__gohashid=ib++}
function db(a){var b=a.__gohashid;void 0===b&&(b=ib++,a.__gohashid=b);return b}function bb(a){return a.__gohashid}ma.Object.defineProperties(H.prototype,{count:{get:function(){return this.Ub}},size:{get:function(){return this.Ub}},iterator:{get:function(){if(0>=this.Ub)return Ya;var a=this.Sa;return null!==a?(a.reset(),a):new cb(this)}}});H.prototype.toList=H.prototype.Iv;H.prototype.toArray=H.prototype.Fa;
H.prototype.clear=H.prototype.clear;H.prototype.retainAll=H.prototype.qA;H.prototype.removeAll=H.prototype.hp;H.prototype["delete"]=H.prototype.delete;H.prototype.remove=H.prototype.remove;H.prototype.first=H.prototype.first;H.prototype.containsAny=H.prototype.Uy;H.prototype.containsAll=H.prototype.Ty;H.prototype.has=H.prototype.has;H.prototype.contains=H.prototype.contains;H.prototype.addAll=H.prototype.addAll;H.prototype.add=H.prototype.add;H.prototype.thaw=H.prototype.ka;H.prototype.freeze=H.prototype.freeze;
var ib=1;H.className="Set";H.uniqueHash=Qa;H.hashIdUnique=db;H.hashId=bb;function kb(a){this.ma=a;this.qa=a.Ja;this.sa=null}kb.prototype.reset=function(){this.qa=this.ma.Ja;this.sa=null};kb.prototype.next=function(){var a=this.ma;if(a.Ja!==this.qa&&null===this.key)return!1;var b=this.sa;b=null===b?a.ea:b.va;if(null!==b)return this.sa=b,this.value=this.key=a=b.key,!0;this.de();return!1};kb.prototype.Ad=function(){return this.next()};
kb.prototype.first=function(){var a=this.ma;this.qa=a.Ja;a=a.ea;return null!==a?(this.sa=a,this.value=this.key=a=a.key):null};kb.prototype.any=function(a){var b=this.ma;this.sa=null;for(b=b.ea;null!==b;){if(a(b.key))return!0;b=b.va}return!1};kb.prototype.all=function(a){var b=this.ma;this.sa=null;for(b=b.ea;null!==b;){if(!a(b.key))return!1;b=b.va}return!0};kb.prototype.each=function(a){var b=this.ma;this.sa=null;for(b=b.ea;null!==b;)a(b.key),b=b.va;return this};
kb.prototype.map=function(a){var b=this.ma;this.sa=null;var c=new F;for(b=b.ea;null!==b;)c.add(a(b.key)),b=b.va;return c.iterator};kb.prototype.filter=function(a){var b=this.ma;this.sa=null;var c=new F;for(b=b.ea;null!==b;){var d=b.key;a(d)&&c.add(d);b=b.va}return c.iterator};kb.prototype.de=function(){this.value=this.key=null;this.qa=-1};kb.prototype.toString=function(){return null!==this.sa?"MapKeySetIterator@"+this.sa.value:"MapKeySetIterator"};
ma.Object.defineProperties(kb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.ma.Ub}}});kb.prototype.first=kb.prototype.first;kb.prototype.hasNext=kb.prototype.Ad;kb.prototype.next=kb.prototype.next;kb.prototype.reset=kb.prototype.reset;kb.className="MapKeySetIterator";function lb(a){H.call(this);Qa(this);this._isFrozen=!0;this.ma=a}la(lb,H);t=lb.prototype;t.freeze=function(){return this};t.ka=function(){return this};
t.toString=function(){return"MapKeySet("+this.ma.toString()+")"};t.add=function(){C("This Set is read-only: "+this.toString())};t.contains=function(a){return this.ma.contains(a)};t.has=function(a){return this.contains(a)};t.remove=function(){C("This Set is read-only: "+this.toString())};t.delete=function(a){return this.remove(a)};t.clear=function(){C("This Set is read-only: "+this.toString())};t.first=function(){var a=this.ma.ea;return null!==a?a.key:null};
lb.prototype.any=function(a){for(var b=this.ma.ea;null!==b;){if(a(b.key))return!0;b=b.va}return!1};lb.prototype.all=function(a){for(var b=this.ma.ea;null!==b;){if(!a(b.key))return!1;b=b.va}return!0};lb.prototype.each=function(a){for(var b=this.ma.ea;null!==b;)a(b.key),b=b.va;return this};lb.prototype.map=function(a){for(var b=new H,c=this.ma.ea;null!==c;)b.add(a(c.key)),c=c.va;return b};lb.prototype.filter=function(a){for(var b=new H,c=this.ma.ea;null!==c;){var d=c.key;a(d)&&b.add(d);c=c.va}return b};
lb.prototype.copy=function(){return new lb(this.ma)};lb.prototype.Jv=function(){var a=new H,b=this.ma.Vb,c;for(c in b)a.add(b[c].key);return a};lb.prototype.Fa=function(){var a=this.ma.Vb,b=Array(this.ma.Ub),c=0,d;for(d in a)b[c]=a[d].key,c++;return b};lb.prototype.Iv=function(){var a=new F,b=this.ma.Vb,c;for(c in b)a.add(b[c].key);return a};
ma.Object.defineProperties(lb.prototype,{count:{get:function(){return this.ma.Ub}},size:{get:function(){return this.ma.Ub}},iterator:{get:function(){return 0>=this.ma.Ub?Ya:new kb(this.ma)}}});lb.prototype.toList=lb.prototype.Iv;lb.prototype.toArray=lb.prototype.Fa;lb.prototype.toSet=lb.prototype.Jv;lb.prototype.first=lb.prototype.first;lb.prototype.clear=lb.prototype.clear;lb.prototype["delete"]=lb.prototype.delete;
lb.prototype.remove=lb.prototype.remove;lb.prototype.has=lb.prototype.has;lb.prototype.contains=lb.prototype.contains;lb.prototype.add=lb.prototype.add;lb.prototype.thaw=lb.prototype.ka;lb.prototype.freeze=lb.prototype.freeze;lb.className="MapKeySet";function mb(a){this.ma=a;a.jf=null;this.qa=a.Ja;this.sa=null}mb.prototype.reset=function(){var a=this.ma;a.jf=null;this.qa=a.Ja;this.sa=null};
mb.prototype.next=function(){var a=this.ma;if(a.Ja!==this.qa&&null===this.key)return!1;var b=this.sa;b=null===b?a.ea:b.va;if(null!==b)return this.sa=b,this.value=b.value,this.key=b.key,!0;this.de();return!1};mb.prototype.Ad=function(){return this.next()};mb.prototype.first=function(){var a=this.ma;this.qa=a.Ja;a=a.ea;if(null!==a){this.sa=a;var b=a.value;this.key=a.key;return this.value=b}return null};
mb.prototype.any=function(a){var b=this.ma;this.sa=b.jf=null;for(b=b.ea;null!==b;){if(a(b.value))return!0;b=b.va}return!1};mb.prototype.all=function(a){var b=this.ma;this.sa=b.jf=null;for(b=b.ea;null!==b;){if(!a(b.value))return!1;b=b.va}return!0};mb.prototype.each=function(a){var b=this.ma;this.sa=b.jf=null;for(b=b.ea;null!==b;)a(b.value),b=b.va;return this};mb.prototype.map=function(a){var b=this.ma;this.sa=b.jf=null;var c=new F;for(b=b.ea;null!==b;)c.add(a(b.value)),b=b.va;return c.iterator};
mb.prototype.filter=function(a){var b=this.ma;this.sa=b.jf=null;var c=new F;for(b=b.ea;null!==b;){var d=b.value;a(d)&&c.add(d);b=b.va}return c.iterator};mb.prototype.de=function(){this.value=this.key=null;this.qa=-1;this.ma.jf=this};mb.prototype.toString=function(){return null!==this.sa?"MapValueSetIterator@"+this.sa.value:"MapValueSetIterator"};ma.Object.defineProperties(mb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.ma.Ub}}});
mb.prototype.first=mb.prototype.first;mb.prototype.hasNext=mb.prototype.Ad;mb.prototype.next=mb.prototype.next;mb.prototype.reset=mb.prototype.reset;mb.className="MapValueSetIterator";function hb(a,b){this.key=a;this.value=b;this.ll=this.va=null}hb.prototype.toString=function(){return"{"+this.key+":"+this.value+"}"};hb.className="KeyValuePair";function nb(a){this.ma=a;a.Sa=null;this.qa=a.Ja;this.sa=null}nb.prototype.reset=function(){var a=this.ma;a.Sa=null;this.qa=a.Ja;this.sa=null};
nb.prototype.next=function(){var a=this.ma;if(a.Ja!==this.qa&&null===this.key)return!1;var b=this.sa;b=null===b?a.ea:b.va;if(null!==b)return this.sa=b,this.key=b.key,this.value=b.value,!0;this.de();return!1};nb.prototype.Ad=function(){return this.next()};nb.prototype.first=function(){var a=this.ma;this.qa=a.Ja;a=a.ea;return null!==a?(this.sa=a,this.key=a.key,this.value=a.value,a):null};nb.prototype.any=function(a){var b=this.ma;this.sa=b.Sa=null;for(b=b.ea;null!==b;){if(a(b))return!0;b=b.va}return!1};
nb.prototype.all=function(a){var b=this.ma;this.sa=b.Sa=null;for(b=b.ea;null!==b;){if(!a(b))return!1;b=b.va}return!0};nb.prototype.each=function(a){var b=this.ma;this.sa=b.Sa=null;for(b=b.ea;null!==b;)a(b),b=b.va;return this};nb.prototype.map=function(a){var b=this.ma;this.sa=b.Sa=null;var c=new F;for(b=b.ea;null!==b;)c.add(a(b)),b=b.va;return c.iterator};nb.prototype.filter=function(a){var b=this.ma;this.sa=b.Sa=null;var c=new F;for(b=b.ea;null!==b;)a(b)&&c.add(b),b=b.va;return c.iterator};
nb.prototype.de=function(){this.value=this.key=null;this.qa=-1;this.ma.Sa=this};nb.prototype.toString=function(){return null!==this.sa?"MapIterator@"+this.sa:"MapIterator"};ma.Object.defineProperties(nb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.ma.Ub}}});nb.prototype.first=nb.prototype.first;nb.prototype.hasNext=nb.prototype.Ad;nb.prototype.next=nb.prototype.next;nb.prototype.reset=nb.prototype.reset;
nb.className="MapIterator";function ob(a){Qa(this);this._isFrozen=!1;this.Vb={};this.Ub=0;this.jf=this.Sa=null;this.Ja=0;this.kf=this.ea=null;"string"===typeof a&&C("Error: Since 2.0 List/Map/Set constructors no longer take an argument that enforces type.");void 0!==a&&this.addAll(a)}t=ob.prototype;t.yb=function(){var a=this.Ja;a++;999999999<a&&(a=0);this.Ja=a};t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){this._isFrozen=!1;return this};t.toString=function(){return"Map()#"+bb(this)};
t.add=function(a,b){this._isFrozen&&va(this,a);var c=a;za(a)&&(c=db(a));var d=this.Vb[c];void 0===d?(this.Ub++,a=new hb(a,b),this.Vb[c]=a,c=this.kf,null===c?this.ea=a:(a.ll=c,c.va=a),this.kf=a,this.yb()):d.value=b;return this};t.set=function(a,b){return this.add(a,b)};
t.addAll=function(a){if(null===a)return this;if(Aa(a))for(var b=a.length,c=0;c<b;c++){var d=a[c];this.add(d.key,d.value)}else if(a instanceof ob)for(a=a.iterator;a.next();)this.add(a.key,a.value);else for(a=a.iterator;a.next();)b=a.value,this.add(b.key,b.value);return this};t.first=function(){return this.ea};ob.prototype.any=function(a){for(var b=this.ea;null!==b;){if(a(b))return!0;b=b.va}return!1};ob.prototype.all=function(a){for(var b=this.ea;null!==b;){if(!a(b))return!1;b=b.va}return!0};
ob.prototype.each=function(a){for(var b=this.ea;null!==b;)a(b),b=b.va;return this};ob.prototype.map=function(a){for(var b=new ob,c=this.ea;null!==c;)b.add(c.key,a(c)),c=c.va;return b};ob.prototype.filter=function(a){for(var b=new ob,c=this.ea;null!==c;)a(c)&&b.add(c.key,c.value),c=c.va;return b};t=ob.prototype;t.contains=function(a){var b=a;return za(a)&&(b=bb(a),void 0===b)?!1:void 0!==this.Vb[b]};t.has=function(a){return this.contains(a)};
t.I=function(a){var b=a;if(za(a)&&(b=bb(a),void 0===b))return null;a=this.Vb[b];return void 0===a?null:a.value};t.get=function(a){return this.I(a)};t.remove=function(a){if(null===a)return!1;this._isFrozen&&va(this,a);var b=a;if(za(a)&&(b=bb(a),void 0===b))return!1;a=this.Vb[b];if(void 0===a)return!1;var c=a.va,d=a.ll;null!==c&&(c.ll=d);null!==d&&(d.va=c);this.ea===a&&(this.ea=c);this.kf===a&&(this.kf=d);delete this.Vb[b];this.Ub--;this.yb();return!0};t.delete=function(a){return this.remove(a)};
t.clear=function(){this._isFrozen&&va(this);this.Vb={};this.Ub=0;null!==this.Sa&&this.Sa.reset();null!==this.jf&&this.jf.reset();this.kf=this.ea=null;this.yb()};ob.prototype.copy=function(){var a=new ob,b=this.Vb,c;for(c in b){var d=b[c];a.add(d.key,d.value)}return a};ob.prototype.Fa=function(){var a=this.Vb,b=Array(this.Ub),c=0,d;for(d in a){var e=a[d];b[c]=new hb(e.key,e.value);c++}return b};ob.prototype.Lf=function(){return new lb(this)};
ma.Object.defineProperties(ob.prototype,{count:{get:function(){return this.Ub}},size:{get:function(){return this.Ub}},iterator:{get:function(){if(0>=this.count)return Ya;var a=this.Sa;return null!==a?(a.reset(),a):new nb(this)}},iteratorKeys:{get:function(){return 0>=this.count?Ya:new kb(this)}},iteratorValues:{get:function(){if(0>=this.count)return Ya;
var a=this.jf;return null!==a?(a.reset(),a):new mb(this)}}});ob.prototype.toKeySet=ob.prototype.Lf;ob.prototype.toArray=ob.prototype.Fa;ob.prototype.clear=ob.prototype.clear;ob.prototype["delete"]=ob.prototype.delete;ob.prototype.remove=ob.prototype.remove;ob.prototype.get=ob.prototype.get;ob.prototype.getValue=ob.prototype.I;ob.prototype.has=ob.prototype.has;ob.prototype.contains=ob.prototype.contains;ob.prototype.first=ob.prototype.first;ob.prototype.addAll=ob.prototype.addAll;
ob.prototype.set=ob.prototype.set;ob.prototype.add=ob.prototype.add;ob.prototype.thaw=ob.prototype.ka;ob.prototype.freeze=ob.prototype.freeze;ob.className="Map";function I(a,b){void 0===a?this.y=this.x=0:"number"===typeof a&&"number"===typeof b?(this.x=a,this.y=b):C("Invalid arguments to Point constructor: "+a+", "+b);this._isFrozen=!1}var pb,qb,rb,sb,tb;I.prototype.assign=function(a){this.x=a.x;this.y=a.y;return this};I.prototype.h=function(a,b){this.x=a;this.y=b;return this};
I.prototype.sg=function(a,b){this.x=a;this.y=b;return this};I.prototype.set=function(a){this.x=a.x;this.y=a.y;return this};I.prototype.copy=function(){var a=new I;a.x=this.x;a.y=this.y;return a};t=I.prototype;t.ja=function(){this._isFrozen=!0;Object.freeze(this);return this};t.J=function(){return this._isFrozen||Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this._isFrozen=!0;return this};
t.ka=function(){Object.isFrozen(this)&&C("cannot thaw constant: "+this);this._isFrozen=!1;return this};function ub(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new I(c,e)}return new I}function vb(a){return a.x.toString()+" "+a.y.toString()}t.toString=function(){return"Point("+this.x+","+this.y+")"};t.D=function(a){return a instanceof I?this.x===a.x&&this.y===a.y:!1};
t.hi=function(a,b){return this.x===a&&this.y===b};t.Xa=function(a){return K.B(this.x,a.x)&&K.B(this.y,a.y)};t.add=function(a){this.x+=a.x;this.y+=a.y;return this};t.re=function(a){this.x-=a.x;this.y-=a.y;return this};t.offset=function(a,b){this.x+=a;this.y+=b;return this};
I.prototype.rotate=function(a){if(0===a)return this;var b=this.x,c=this.y;if(0===b&&0===c)return this;360<=a?a-=360:0>a&&(a+=360);if(90===a){a=0;var d=1}else 180===a?(a=-1,d=0):270===a?(a=0,d=-1):(d=a*Math.PI/180,a=Math.cos(d),d=Math.sin(d));this.x=a*b-d*c;this.y=d*b+a*c;return this};t=I.prototype;t.scale=function(a,b){this.x*=a;this.y*=b;return this};t.Bf=function(a){var b=a.x-this.x;a=a.y-this.y;return b*b+a*a};t.zd=function(a,b){a-=this.x;b-=this.y;return a*a+b*b};
t.normalize=function(){var a=this.x,b=this.y,c=Math.sqrt(a*a+b*b);0<c&&(this.x=a/c,this.y=b/c);return this};t.ab=function(a){return wb(a.x-this.x,a.y-this.y)};t.direction=function(a,b){return wb(a-this.x,b-this.y)};function wb(a,b){if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;var c=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?c=0>b?c+180:180-c:0>b&&(c=360-c);return c}t.Sy=function(a,b){return K.Fl(a.x,a.y,b.x,b.y,this.x,this.y)};
t.gA=function(a,b,c,d){K.qh(a,b,c,d,this.x,this.y,this);return this};t.hA=function(a,b){K.qh(a.x,a.y,b.x,b.y,this.x,this.y,this);return this};t.vA=function(a,b,c,d){K.No(this.x,this.y,a,b,c,d,this);return this};t.wA=function(a,b){K.No(this.x,this.y,a.x,a.y,b.width,b.height,this);return this};t.xi=function(a,b){this.x=a.x+b.x*a.width+b.offsetX;this.y=a.y+b.y*a.height+b.offsetY;return this};t.yi=function(a,b,c,d,e){this.x=a+e.x*c+e.offsetX;this.y=b+e.y*d+e.offsetY;return this};
t.transform=function(a){a.Ga(this);return this};function xb(a,b){b.Cd(a);return a}function yb(a,b,c,d,e,f){var g=e-c,h=f-d,k=g*g+h*h;c-=a;d-=b;var l=-c*g-d*h;if(0>=l||l>=k)return g=e-a,h=f-b,Math.min(c*c+d*d,g*g+h*h);a=g*d-h*c;return a*a/k}function zb(a,b,c,d){a=c-a;b=d-b;return a*a+b*b}function Ab(a,b,c,d){a=c-a;b=d-b;if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;d=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?d=0>b?d+180:180-d:0>b&&(d=360-d);return d}
t.w=function(){return isFinite(this.x)&&isFinite(this.y)};I.alloc=function(){var a=Bb.pop();return void 0===a?new I:a};I.allocAt=function(a,b){var c=Bb.pop();if(void 0===c)return new I(a,b);c.x=a;c.y=b;return c};I.free=function(a){Bb.push(a)};I.prototype.isReal=I.prototype.w;I.prototype.setSpot=I.prototype.yi;I.prototype.setRectSpot=I.prototype.xi;I.prototype.snapToGridPoint=I.prototype.wA;I.prototype.snapToGrid=I.prototype.vA;I.prototype.projectOntoLineSegmentPoint=I.prototype.hA;
I.prototype.projectOntoLineSegment=I.prototype.gA;I.intersectingLineSegments=function(a,b,c,d,e,f,g,h){return K.Rl(a,b,c,d,e,f,g,h)};I.prototype.compareWithLineSegmentPoint=I.prototype.Sy;I.compareWithLineSegment=function(a,b,c,d,e,f){return K.Fl(a,b,c,d,e,f)};I.prototype.direction=I.prototype.direction;I.prototype.directionPoint=I.prototype.ab;I.prototype.normalize=I.prototype.normalize;I.prototype.distanceSquared=I.prototype.zd;I.prototype.distanceSquaredPoint=I.prototype.Bf;I.prototype.scale=I.prototype.scale;
I.prototype.rotate=I.prototype.rotate;I.prototype.offset=I.prototype.offset;I.prototype.subtract=I.prototype.re;I.prototype.add=I.prototype.add;I.prototype.equalsApprox=I.prototype.Xa;I.prototype.equalTo=I.prototype.hi;I.prototype.equals=I.prototype.D;I.prototype.set=I.prototype.set;I.prototype.setTo=I.prototype.sg;var Bb=[];I.className="Point";I.parse=ub;I.stringify=vb;I.distanceLineSegmentSquared=yb;I.distanceSquared=zb;I.direction=Ab;I.Origin=pb=(new I(0,0)).ja();
I.InfiniteTopLeft=qb=(new I(-Infinity,-Infinity)).ja();I.InfiniteBottomRight=rb=(new I(Infinity,Infinity)).ja();I.SixPoint=sb=(new I(6,6)).ja();I.NoPoint=tb=(new I(NaN,NaN)).ja();I.parse=ub;I.stringify=vb;I.distanceLineSegmentSquared=yb;I.distanceSquared=zb;I.direction=Ab;
function M(a,b){void 0===a?this.height=this.width=0:"number"===typeof a&&(0<=a||isNaN(a))&&"number"===typeof b&&(0<=b||isNaN(b))?(this.width=a,this.height=b):C("Invalid arguments to Size constructor: "+a+", "+b);this._isFrozen=!1}var Cb,Db,Hb,Ib,Jb,Kb,Lb;M.prototype.assign=function(a){this.width=a.width;this.height=a.height;return this};M.prototype.h=function(a,b){this.width=a;this.height=b;return this};M.prototype.sg=function(a,b){this.width=a;this.height=b;return this};
M.prototype.set=function(a){this.width=a.width;this.height=a.height;return this};M.prototype.copy=function(){var a=new M;a.width=this.width;a.height=this.height;return a};t=M.prototype;t.ja=function(){this._isFrozen=!0;Object.freeze(this);return this};t.J=function(){return this._isFrozen||Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){Object.isFrozen(this)&&C("cannot thaw constant: "+this);this._isFrozen=!1;return this};
function Mb(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new M(c,e)}return new M}function Nb(a){return a.width.toString()+" "+a.height.toString()}t.toString=function(){return"Size("+this.width+","+this.height+")"};t.D=function(a){return a instanceof M?this.width===a.width&&this.height===a.height:!1};t.hi=function(a,b){return this.width===a&&this.height===b};
t.Xa=function(a){return K.B(this.width,a.width)&&K.B(this.height,a.height)};t.Bc=function(a,b){a=this.width+a;this.width=0<=a?a:0;b=this.height+b;this.height=0<=b?b:0;return this};t.w=function(){return isFinite(this.width)&&isFinite(this.height)};M.alloc=function(){var a=Ob.pop();return void 0===a?new M:a};M.free=function(a){Ob.push(a)};M.prototype.isReal=M.prototype.w;M.prototype.inflate=M.prototype.Bc;M.prototype.equalsApprox=M.prototype.Xa;M.prototype.equalTo=M.prototype.hi;
M.prototype.equals=M.prototype.D;M.prototype.set=M.prototype.set;M.prototype.setTo=M.prototype.sg;var Ob=[];M.className="Size";M.parse=Mb;M.stringify=Nb;M.ZeroSize=Cb=(new M(0,0)).ja();M.OneSize=Db=(new M(1,1)).ja();M.SixSize=Hb=(new M(6,6)).ja();M.EightSize=Ib=(new M(8,8)).ja();M.TenSize=Jb=(new M(10,10)).ja();M.InfiniteSize=Kb=(new M(Infinity,Infinity)).ja();M.NoSize=Lb=(new M(NaN,NaN)).ja();M.parse=Mb;M.stringify=Nb;
function N(a,b,c,d){void 0===a?this.height=this.width=this.y=this.x=0:"number"===typeof a&&"number"===typeof b&&"number"===typeof c&&(0<=c||isNaN(c))&&"number"===typeof d&&(0<=d||isNaN(d))?(this.x=a,this.y=b,this.width=c,this.height=d):a instanceof I?(c=a.x,a=a.y,b instanceof I?(d=b.x,b=b.y,this.x=Math.min(c,d),this.y=Math.min(a,b),this.width=Math.abs(c-d),this.height=Math.abs(a-b)):b instanceof M?(this.x=c,this.y=a,this.width=b.width,this.height=b.height):C("Incorrect second argument supplied to Rect constructor "+
b)):C("Invalid arguments to Rect constructor: "+a+", "+b+", "+c+", "+d);this._isFrozen=!1}t=N.prototype;t.assign=function(a){this.x=a.x;this.y=a.y;this.width=a.width;this.height=a.height;return this};t.h=function(a,b,c,d){this.x=a;this.y=b;this.width=c;this.height=d;return this};function Pb(a,b,c){a.width=b;a.height=c}t.sg=function(a,b,c,d){this.x=a;this.y=b;this.width=c;this.height=d;return this};t.set=function(a){this.x=a.x;this.y=a.y;this.width=a.width;this.height=a.height;return this};
t.dd=function(a){this.x=a.x;this.y=a.y;return this};t.uA=function(a){this.width=a.width;this.height=a.height;return this};N.prototype.copy=function(){var a=new N;a.x=this.x;a.y=this.y;a.width=this.width;a.height=this.height;return a};t=N.prototype;t.ja=function(){this._isFrozen=!0;Object.freeze(this);return this};t.J=function(){return this._isFrozen||Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this._isFrozen=!0;return this};
t.ka=function(){Object.isFrozen(this)&&C("cannot thaw constant: "+this);this._isFrozen=!1;return this};function Qb(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));for(var f=0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));for(var g=0;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));return new N(c,e,f,g)}return new N}
function Rb(a){return a.x.toString()+" "+a.y.toString()+" "+a.width.toString()+" "+a.height.toString()}t.toString=function(){return"Rect("+this.x+","+this.y+","+this.width+","+this.height+")"};t.D=function(a){return a instanceof N?this.x===a.x&&this.y===a.y&&this.width===a.width&&this.height===a.height:!1};t.hi=function(a,b,c,d){return this.x===a&&this.y===b&&this.width===c&&this.height===d};t.Xa=function(a){return K.B(this.x,a.x)&&K.B(this.y,a.y)&&K.B(this.width,a.width)&&K.B(this.height,a.height)};
function Sb(a,b){return K.$(a.x,b.x)&&K.$(a.y,b.y)&&K.$(a.width,b.width)&&K.$(a.height,b.height)}t.ca=function(a){return this.x<=a.x&&this.x+this.width>=a.x&&this.y<=a.y&&this.y+this.height>=a.y};t.Re=function(a){return this.x<=a.x&&a.x+a.width<=this.x+this.width&&this.y<=a.y&&a.y+a.height<=this.y+this.height};t.contains=function(a,b,c,d){void 0===c&&(c=0);void 0===d&&(d=0);return this.x<=a&&a+c<=this.x+this.width&&this.y<=b&&b+d<=this.y+this.height};t.offset=function(a,b){this.x+=a;this.y+=b;return this};
t.Bc=function(a,b){return Ub(this,b,a,b,a)};t.Co=function(a){return Ub(this,a.top,a.right,a.bottom,a.left)};t.Gv=function(a){return Ub(this,-a.top,-a.right,-a.bottom,-a.left)};t.Iz=function(a,b,c,d){return Ub(this,a,b,c,d)};function Ub(a,b,c,d,e){var f=a.width;c+e<=-f?(a.x+=f/2,a.width=0):(a.x-=e,a.width+=c+e);c=a.height;b+d<=-c?(a.y+=c/2,a.height=0):(a.y-=b,a.height+=b+d);return a}t.Fx=function(a){return Vb(this,a.x,a.y,a.width,a.height)};t.Vu=function(a,b,c,d){return Vb(this,a,b,c,d)};
function Vb(a,b,c,d,e){var f=Math.max(a.x,b),g=Math.max(a.y,c);b=Math.min(a.x+a.width,b+d);c=Math.min(a.y+a.height,c+e);a.x=f;a.y=g;a.width=Math.max(0,b-f);a.height=Math.max(0,c-g);return a}t.Yc=function(a){return this.Wu(a.x,a.y,a.width,a.height)};t.Wu=function(a,b,c,d){var e=this.width,f=this.x;if(Infinity!==e&&Infinity!==c&&(e+=f,c+=a,isNaN(c)||isNaN(e)||f>c||a>e))return!1;a=this.height;c=this.y;return Infinity!==a&&Infinity!==d&&(a+=c,d+=b,isNaN(d)||isNaN(a)||c>d||b>a)?!1:!0};
function Wb(a,b){var c=a.width,d=a.x,e=b.x-10;if(d>b.width+10+10+e||e>c+d)return!1;c=a.height;a=a.y;d=b.y-10;return a>b.height+10+10+d||d>c+a?!1:!0}t.Ye=function(a){return Xb(this,a.x,a.y,0,0)};t.ed=function(a){return Xb(this,a.x,a.y,a.width,a.height)};t.Lv=function(a,b,c,d){void 0===c&&(c=0);void 0===d&&(d=0);return Xb(this,a,b,c,d)};
function Xb(a,b,c,d,e){var f=Math.min(a.x,b),g=Math.min(a.y,c);b=Math.max(a.x+a.width,b+d);c=Math.max(a.y+a.height,c+e);a.x=f;a.y=g;a.width=b-f;a.height=c-g;return a}t.yi=function(a,b,c){this.x=a-c.offsetX-c.x*this.width;this.y=b-c.offsetY-c.y*this.height;return this};function Yb(a,b,c,d,e,f,g,h){void 0===g&&(g=0);void 0===h&&(h=0);return a<=e&&e+g<=a+c&&b<=f&&f+h<=b+d}function Zb(a,b,c,d,e,f,g,h){return a>g+e||e>c+a?!1:b>h+f||f>d+b?!1:!0}
t.w=function(){return isFinite(this.x)&&isFinite(this.y)&&isFinite(this.width)&&isFinite(this.height)};t.Nz=function(){return 0===this.width&&0===this.height};N.alloc=function(){var a=$b.pop();return void 0===a?new N:a};N.allocAt=function(a,b,c,d){var e=$b.pop();return void 0===e?new N(a,b,c,d):e.h(a,b,c,d)};N.free=function(a){$b.push(a)};
ma.Object.defineProperties(N.prototype,{left:{get:function(){return this.x},set:function(a){this.x=a}},top:{get:function(){return this.y},set:function(a){this.y=a}},right:{get:function(){return this.x+this.width},set:function(a){this.x+=a-(this.x+this.width)}},bottom:{get:function(){return this.y+this.height},set:function(a){this.y+=a-(this.y+this.height)}},position:{
get:function(){return new I(this.x,this.y)},set:function(a){this.x=a.x;this.y=a.y}},size:{get:function(){return new M(this.width,this.height)},set:function(a){this.width=a.width;this.height=a.height}},center:{get:function(){return new I(this.x+this.width/2,this.y+this.height/2)},set:function(a){this.x=a.x-this.width/2;this.y=a.y-this.height/2}},centerX:{get:function(){return this.x+this.width/2},
set:function(a){this.x=a-this.width/2}},centerY:{get:function(){return this.y+this.height/2},set:function(a){this.y=a-this.height/2}}});N.prototype.isEmpty=N.prototype.Nz;N.prototype.isReal=N.prototype.w;N.intersectsLineSegment=function(a,b,c,d,e,f,g,h){return K.Gx(a,b,c,d,e,f,g,h)};N.prototype.setSpot=N.prototype.yi;N.prototype.union=N.prototype.Lv;N.prototype.unionRect=N.prototype.ed;N.prototype.unionPoint=N.prototype.Ye;N.prototype.intersects=N.prototype.Wu;
N.prototype.intersectsRect=N.prototype.Yc;N.prototype.intersect=N.prototype.Vu;N.prototype.intersectRect=N.prototype.Fx;N.prototype.grow=N.prototype.Iz;N.prototype.subtractMargin=N.prototype.Gv;N.prototype.addMargin=N.prototype.Co;N.prototype.inflate=N.prototype.Bc;N.prototype.offset=N.prototype.offset;N.prototype.contains=N.prototype.contains;N.prototype.containsRect=N.prototype.Re;N.prototype.containsPoint=N.prototype.ca;N.prototype.equalsApprox=N.prototype.Xa;N.prototype.equalTo=N.prototype.hi;
N.prototype.equals=N.prototype.D;N.prototype.setSize=N.prototype.uA;N.prototype.setPoint=N.prototype.dd;N.prototype.set=N.prototype.set;N.prototype.setTo=N.prototype.sg;var ac=null,bc=null,$b=[];N.className="Rect";N.parse=Qb;N.stringify=Rb;N.contains=Yb;N.intersects=Zb;N.ZeroRect=ac=(new N(0,0,0,0)).ja();N.NoRect=bc=(new N(NaN,NaN,NaN,NaN)).ja();N.parse=Qb;N.stringify=Rb;N.contains=Yb;N.intersects=Zb;
function cc(a,b,c,d){void 0===a?this.left=this.bottom=this.right=this.top=0:void 0===b?this.left=this.bottom=this.right=this.top=a:void 0===c?(this.top=a,this.right=b,this.bottom=a,this.left=b):void 0!==d?(this.top=a,this.right=b,this.bottom=c,this.left=d):C("Invalid arguments to Margin constructor: "+a+", "+b+", "+c+", "+d);this._isFrozen=!1}cc.prototype.assign=function(a){this.top=a.top;this.right=a.right;this.bottom=a.bottom;this.left=a.left;return this};
cc.prototype.sg=function(a,b,c,d){this.top=a;this.right=b;this.bottom=c;this.left=d;return this};cc.prototype.set=function(a){this.top=a.top;this.right=a.right;this.bottom=a.bottom;this.left=a.left;return this};cc.prototype.copy=function(){var a=new cc;a.top=this.top;a.right=this.right;a.bottom=this.bottom;a.left=this.left;return a};t=cc.prototype;t.ja=function(){this._isFrozen=!0;Object.freeze(this);return this};t.J=function(){return this._isFrozen||Object.isFrozen(this)?this:this.copy().freeze()};
t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){Object.isFrozen(this)&&C("cannot thaw constant: "+this);this._isFrozen=!1;return this};
function gc(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=NaN;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));if(isNaN(c))return new cc;for(var e=NaN;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));if(isNaN(e))return new cc(c);for(var f=NaN;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));if(isNaN(f))return new cc(c,e);for(var g=NaN;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));return isNaN(g)?new cc(c,e):new cc(c,e,f,g)}return new cc}
function hc(a){return a.top.toString()+" "+a.right.toString()+" "+a.bottom.toString()+" "+a.left.toString()}t.toString=function(){return"Margin("+this.top+","+this.right+","+this.bottom+","+this.left+")"};t.D=function(a){return a instanceof cc?this.top===a.top&&this.right===a.right&&this.bottom===a.bottom&&this.left===a.left:!1};t.hi=function(a,b,c,d){return this.top===a&&this.right===b&&this.bottom===c&&this.left===d};
t.Xa=function(a){return K.B(this.top,a.top)&&K.B(this.right,a.right)&&K.B(this.bottom,a.bottom)&&K.B(this.left,a.left)};t.w=function(){return isFinite(this.top)&&isFinite(this.right)&&isFinite(this.bottom)&&isFinite(this.left)};cc.alloc=function(){var a=ic.pop();return void 0===a?new cc:a};cc.free=function(a){ic.push(a)};cc.prototype.isReal=cc.prototype.w;cc.prototype.equalsApprox=cc.prototype.Xa;cc.prototype.equalTo=cc.prototype.hi;cc.prototype.equals=cc.prototype.D;cc.prototype.set=cc.prototype.set;
cc.prototype.setTo=cc.prototype.sg;var jc=null,kc=null,ic=[];cc.className="Margin";cc.parse=gc;cc.stringify=hc;cc.ZeroMargin=jc=(new cc(0,0,0,0)).ja();cc.TwoMargin=kc=(new cc(2,2,2,2)).ja();cc.parse=gc;cc.stringify=hc;function O(a,b,c,d){void 0===a?this.offsetY=this.offsetX=this.y=this.x=0:(void 0===b&&(b=0),void 0===c&&(c=0),void 0===d&&(d=0),this.x=a,this.y=b,this.offsetX=c,this.offsetY=d);this._isFrozen=!1}
var lc,sc,tc,uc,vc,wc,xc,yc,zc,Bc,Cc,Dc,Ec,Fc,Gc,Hc,Jc,Kc,Lc,Mc,Nc,Oc,Pc,Qc,Rc,Sc,Tc,Uc,Vc,$c,ad,bd,cd,dd,ed,fd;O.prototype.assign=function(a){this.x=a.x;this.y=a.y;this.offsetX=a.offsetX;this.offsetY=a.offsetY;return this};O.prototype.sg=function(a,b,c,d){this.x=a;this.y=b;this.offsetX=c;this.offsetY=d;return this};O.prototype.set=function(a){this.x=a.x;this.y=a.y;this.offsetX=a.offsetX;this.offsetY=a.offsetY;return this};
O.prototype.copy=function(){var a=new O;a.x=this.x;a.y=this.y;a.offsetX=this.offsetX;a.offsetY=this.offsetY;return a};t=O.prototype;t.ja=function(){this._isFrozen=!0;Object.freeze(this);return this};t.J=function(){return this._isFrozen||Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){Object.isFrozen(this)&&C("cannot thaw constant: "+this);this._isFrozen=!1;return this};function gd(a,b){a.x=NaN;a.y=NaN;a.offsetX=b;return a}
function hd(a){if("string"===typeof a){a=a.trim();if("None"===a)return lc;if("TopLeft"===a)return sc;if("Top"===a||"TopCenter"===a||"MiddleTop"===a)return tc;if("TopRight"===a)return uc;if("Left"===a||"LeftCenter"===a||"MiddleLeft"===a)return vc;if("Center"===a)return wc;if("Right"===a||"RightCenter"===a||"MiddleRight"===a)return xc;if("BottomLeft"===a)return yc;if("Bottom"===a||"BottomCenter"===a||"MiddleBottom"===a)return zc;if("BottomRight"===a)return Bc;if("TopSide"===a)return Cc;if("LeftSide"===
a)return Dc;if("RightSide"===a)return Ec;if("BottomSide"===a)return Fc;if("TopBottomSides"===a)return Gc;if("LeftRightSides"===a)return Hc;if("TopLeftSides"===a)return Jc;if("TopRightSides"===a)return Kc;if("BottomLeftSides"===a)return Lc;if("BottomRightSides"===a)return Mc;if("NotTopSide"===a)return Nc;if("NotLeftSide"===a)return Oc;if("NotRightSide"===a)return Pc;if("NotBottomSide"===a)return Qc;if("AllSides"===a)return Rc;if("Default"===a)return Sc;a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;
var d=a[b++];void 0!==d&&0<d.length&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(e=parseFloat(d));for(var f=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(f=parseFloat(d));for(var g=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(g=parseFloat(d));return new O(c,e,f,g)}return new O}function id(a){return a.sb()?a.x.toString()+" "+a.y.toString()+" "+a.offsetX.toString()+" "+a.offsetY.toString():a.toString()}
t.toString=function(){return this.sb()?0===this.offsetX&&0===this.offsetY?"Spot("+this.x+","+this.y+")":"Spot("+this.x+","+this.y+","+this.offsetX+","+this.offsetY+")":this.D(lc)?"None":this.D(sc)?"TopLeft":this.D(tc)?"Top":this.D(uc)?"TopRight":this.D(vc)?"Left":this.D(wc)?"Center":this.D(xc)?"Right":this.D(yc)?"BottomLeft":this.D(zc)?"Bottom":this.D(Bc)?"BottomRight":this.D(Cc)?"TopSide":this.D(Dc)?"LeftSide":this.D(Ec)?"RightSide":this.D(Fc)?"BottomSide":this.D(Gc)?"TopBottomSides":this.D(Hc)?
"LeftRightSides":this.D(Jc)?"TopLeftSides":this.D(Kc)?"TopRightSides":this.D(Lc)?"BottomLeftSides":this.D(Mc)?"BottomRightSides":this.D(Nc)?"NotTopSide":this.D(Oc)?"NotLeftSide":this.D(Pc)?"NotRightSide":this.D(Qc)?"NotBottomSide":this.D(Rc)?"AllSides":this.D(Sc)?"Default":"None"};t.D=function(a){return a instanceof O?(this.x===a.x||isNaN(this.x)&&isNaN(a.x))&&(this.y===a.y||isNaN(this.y)&&isNaN(a.y))&&this.offsetX===a.offsetX&&this.offsetY===a.offsetY:!1};
t.hv=function(){return new O(.5-(this.x-.5),.5-(this.y-.5),-this.offsetX,-this.offsetY)};t.Hf=function(a){if(!this.$c())return!1;if(!a.$c())if(a.D(Tc))a=Dc;else if(a.D(Uc))a=Ec;else if(a.D(Vc))a=Cc;else if(a.D($c))a=Fc;else return!1;a=a.offsetY;return(this.offsetY&a)===a};t.sb=function(){return!isNaN(this.x)&&!isNaN(this.y)};t.Mc=function(){return isNaN(this.x)||isNaN(this.y)};t.$c=function(){return isNaN(this.x)&&isNaN(this.y)&&1===this.offsetX&&0!==this.offsetY};
t.ys=function(){return isNaN(this.x)&&isNaN(this.y)&&0===this.offsetX&&0===this.offsetY};t.Zb=function(){return isNaN(this.x)&&isNaN(this.y)&&-1===this.offsetX&&0===this.offsetY};O.alloc=function(){var a=jd.pop();return void 0===a?new O:a};O.free=function(a){jd.push(a)};O.prototype.isDefault=O.prototype.Zb;O.prototype.isNone=O.prototype.ys;O.prototype.isSide=O.prototype.$c;O.prototype.isNoSpot=O.prototype.Mc;O.prototype.isSpot=O.prototype.sb;O.prototype.includesSide=O.prototype.Hf;
O.prototype.opposite=O.prototype.hv;O.prototype.equals=O.prototype.D;O.prototype.set=O.prototype.set;O.prototype.setTo=O.prototype.sg;var jd=[];O.className="Spot";O.parse=hd;O.stringify=id;O.None=lc=gd(new O(0,0,0,0),0).ja();O.Default=Sc=gd(new O(0,0,-1,0),-1).ja();O.TopLeft=sc=(new O(0,0,0,0)).ja();O.TopCenter=tc=(new O(.5,0,0,0)).ja();O.TopRight=uc=(new O(1,0,0,0)).ja();O.LeftCenter=vc=(new O(0,.5,0,0)).ja();O.Center=wc=(new O(.5,.5,0,0)).ja();O.RightCenter=xc=(new O(1,.5,0,0)).ja();
O.BottomLeft=yc=(new O(0,1,0,0)).ja();O.BottomCenter=zc=(new O(.5,1,0,0)).ja();O.BottomRight=Bc=(new O(1,1,0,0)).ja();O.MiddleTop=ad=tc;O.MiddleLeft=bd=vc;O.MiddleRight=cd=xc;O.MiddleBottom=dd=zc;O.Top=Vc=tc;O.Left=Tc=vc;O.Right=Uc=xc;O.Bottom=$c=zc;O.TopSide=Cc=gd(new O(0,0,1,1),1).ja();O.LeftSide=Dc=gd(new O(0,0,1,2),1).ja();O.RightSide=Ec=gd(new O(0,0,1,4),1).ja();O.BottomSide=Fc=gd(new O(0,0,1,8),1).ja();O.TopBottomSides=Gc=gd(new O(0,0,1,9),1).ja();O.LeftRightSides=Hc=gd(new O(0,0,1,6),1).ja();
O.TopLeftSides=Jc=gd(new O(0,0,1,3),1).ja();O.TopRightSides=Kc=gd(new O(0,0,1,5),1).ja();O.BottomLeftSides=Lc=gd(new O(0,0,1,10),1).ja();O.BottomRightSides=Mc=gd(new O(0,0,1,12),1).ja();O.NotTopSide=Nc=gd(new O(0,0,1,14),1).ja();O.NotLeftSide=Oc=gd(new O(0,0,1,13),1).ja();O.NotRightSide=Pc=gd(new O(0,0,1,11),1).ja();O.NotBottomSide=Qc=gd(new O(0,0,1,7),1).ja();O.AllSides=Rc=gd(new O(0,0,1,15),1).ja();ed=(new O(.156,.156)).ja();fd=(new O(.844,.844)).ja();O.parse=hd;O.stringify=id;
function ld(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0}ld.prototype.set=function(a){this.m11=a.m11;this.m12=a.m12;this.m21=a.m21;this.m22=a.m22;this.dx=a.dx;this.dy=a.dy;return this};ld.prototype.setTransform=function(a,b,c,d,e,f){this.m11=a;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=f;return this};ld.prototype.copy=function(){var a=new ld;a.m11=this.m11;a.m12=this.m12;a.m21=this.m21;a.m22=this.m22;a.dx=this.dx;a.dy=this.dy;return a};
ld.prototype.toString=function(){return"Transform("+this.m11+","+this.m12+","+this.m21+","+this.m22+","+this.dx+","+this.dy+")"};ld.prototype.D=function(a){return this.m11===a.m11&&this.m12===a.m12&&this.m21===a.m21&&this.m22===a.m22&&this.dx===a.dx&&this.dy===a.dy};ld.prototype.ws=function(){return 0===this.dx&&0===this.dy&&1===this.m11&&0===this.m12&&0===this.m21&&1===this.m22};ld.prototype.reset=function(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0;return this};
ld.prototype.multiply=function(a){var b=this.m11*a.m11+this.m21*a.m12,c=this.m12*a.m11+this.m22*a.m12,d=this.m11*a.m21+this.m21*a.m22,e=this.m12*a.m21+this.m22*a.m22;this.dx=this.m11*a.dx+this.m21*a.dy+this.dx;this.dy=this.m12*a.dx+this.m22*a.dy+this.dy;this.m11=b;this.m12=c;this.m21=d;this.m22=e;return this};
ld.prototype.cv=function(a){var b=1/(a.m11*a.m22-a.m12*a.m21),c=a.m22*b,d=-a.m12*b,e=-a.m21*b,f=a.m11*b,g=b*(a.m21*a.dy-a.m22*a.dx);a=b*(a.m12*a.dx-a.m11*a.dy);b=this.m11*c+this.m21*d;c=this.m12*c+this.m22*d;d=this.m11*e+this.m21*f;e=this.m12*e+this.m22*f;this.dx=this.m11*g+this.m21*a+this.dx;this.dy=this.m12*g+this.m22*a+this.dy;this.m11=b;this.m12=c;this.m21=d;this.m22=e;return this};
ld.prototype.Uj=function(){var a=1/(this.m11*this.m22-this.m12*this.m21),b=-this.m12*a,c=-this.m21*a,d=this.m11*a,e=a*(this.m21*this.dy-this.m22*this.dx),f=a*(this.m12*this.dx-this.m11*this.dy);this.m11=this.m22*a;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=f;return this};
ld.prototype.rotate=function(a,b,c){360<=a?a-=360:0>a&&(a+=360);if(0===a)return this;this.translate(b,c);if(90===a){a=0;var d=1}else 180===a?(a=-1,d=0):270===a?(a=0,d=-1):(d=a*Math.PI/180,a=Math.cos(d),d=Math.sin(d));var e=this.m12*a+this.m22*d,f=this.m11*-d+this.m21*a,g=this.m12*-d+this.m22*a;this.m11=this.m11*a+this.m21*d;this.m12=e;this.m21=f;this.m22=g;this.translate(-b,-c);return this};t=ld.prototype;t.translate=function(a,b){this.dx+=this.m11*a+this.m21*b;this.dy+=this.m12*a+this.m22*b;return this};
t.scale=function(a,b){void 0===b&&(b=a);this.m11*=a;this.m12*=a;this.m21*=b;this.m22*=b;return this};t.Ga=function(a){var b=a.x,c=a.y;return a.h(b*this.m11+c*this.m21+this.dx,b*this.m12+c*this.m22+this.dy)};t.Cd=function(a){var b=1/(this.m11*this.m22-this.m12*this.m21),c=a.x,d=a.y;return a.h(c*this.m22*b+d*-this.m21*b+b*(this.m21*this.dy-this.m22*this.dx),c*-this.m12*b+d*this.m11*b+b*(this.m12*this.dx-this.m11*this.dy))};
t.Fu=function(a){var b=1/(this.m11*this.m22-this.m12*this.m21),c=1.25*a.x,d=1.2*a.y;return a.h(c*this.m22*b+d*-this.m21*b+b*(this.m21*this.dy-this.m22*this.dx),c*-this.m12*b+d*this.m11*b+b*(this.m12*this.dx-this.m11*this.dy))};
t.pp=function(a){var b=a.x,c=a.y,d=b+a.width,e=c+a.height,f=this.m11,g=this.m12,h=this.m21,k=this.m22,l=this.dx,m=this.dy,n=b*f+c*h+l,p=b*g+c*k+m,q=d*f+c*h+l,r=d*g+c*k+m;c=b*f+e*h+l;b=b*g+e*k+m;f=d*f+e*h+l;d=d*g+e*k+m;e=Math.min(n,q);n=Math.max(n,q);q=Math.min(p,r);p=Math.max(p,r);e=Math.min(e,c);n=Math.max(n,c);q=Math.min(q,b);p=Math.max(p,b);e=Math.min(e,f);n=Math.max(n,f);q=Math.min(q,d);p=Math.max(p,d);a.h(e,q,n-e,p-q);return a};ld.alloc=function(){var a=md.pop();return void 0===a?new ld:a};
ld.free=function(a){md.push(a)};ld.prototype.transformRect=ld.prototype.pp;ld.prototype.transformPoint=ld.prototype.Ga;ld.prototype.scale=ld.prototype.scale;ld.prototype.translate=ld.prototype.translate;ld.prototype.rotate=ld.prototype.rotate;ld.prototype.invert=ld.prototype.Uj;ld.prototype.multiplyInverted=ld.prototype.cv;ld.prototype.multiply=ld.prototype.multiply;ld.prototype.reset=ld.prototype.reset;ld.prototype.isIdentity=ld.prototype.ws;ld.prototype.equals=ld.prototype.D;
ld.prototype.setTransform=ld.prototype.setTransform;ld.prototype.set=ld.prototype.set;var md=[];ld.className="Transform";ld.xF="54a702f3e53909c447824c6706603faf4c";
var K={BA:"7da71ca0ad381e90",wg:(Math.sqrt(2)-1)/3*4,Vv:null,sqrt:function(a){if(0>=a)return 0;var b=K.Vv;if(null===b){b=[];for(var c=0;2E3>=c;c++)b[c]=Math.sqrt(c);K.Vv=b}return 1>a?(c=1/a,2E3>=c?1/b[c|0]:Math.sqrt(a)):2E3>=a?b[a|0]:Math.sqrt(a)},B:function(a,b){a-=b;return.5>a&&-.5<a},$:function(a,b){a-=b;return 5E-8>a&&-5E-8<a},$b:function(a,b,c,d,e,f,g){0>=e&&(e=1E-6);if(a<c){var h=a;var k=c}else h=c,k=a;if(b<d){var l=b;var m=d}else l=d,m=b;if(a===c)return l<=g&&g<=m&&a-e<=f&&f<=a+e;if(b===d)return h<=
f&&f<=k&&b-e<=g&&g<=b+e;k+=e;h-=e;if(h<=f&&f<=k&&(m+=e,l-=e,l<=g&&g<=m))if(k-h>m-l)if(a-c>e||c-a>e){if(f=(d-b)/(c-a)*(f-a)+b,f-e<=g&&g<=f+e)return!0}else return!0;else if(b-d>e||d-b>e){if(g=(c-a)/(d-b)*(g-b)+a,g-e<=f&&f<=g+e)return!0}else return!0;return!1},cs:function(a,b,c,d,e,f,g,h,k,l,m,n){if(K.$b(a,b,g,h,n,c,d)&&K.$b(a,b,g,h,n,e,f))return K.$b(a,b,g,h,n,l,m);var p=(a+c)/2,q=(b+d)/2,r=(c+e)/2,u=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(p+r)/2;c=(q+u)/2;r=(r+e)/2;u=(u+f)/2;var w=(d+r)/2,v=(c+u)/2;return K.cs(a,
b,p,q,d,c,w,v,k,l,m,n)||K.cs(w,v,r,u,e,f,g,h,k,l,m,n)},bx:function(a,b,c,d,e,f,g,h,k){var l=(c+e)/2,m=(d+f)/2;k.h((((a+c)/2+l)/2+(l+(e+g)/2)/2)/2,(((b+d)/2+m)/2+(m+(f+h)/2)/2)/2);return k},Ny:function(a,b,c,d,e,f,g,h){var k=(c+e)/2,l=(d+f)/2;return Ab(((a+c)/2+k)/2,((b+d)/2+l)/2,(k+(e+g)/2)/2,(l+(f+h)/2)/2)},Dl:function(a,b,c,d,e,f,g,h,k,l){if(K.$b(a,b,g,h,k,c,d)&&K.$b(a,b,g,h,k,e,f))Xb(l,a,b,0,0),Xb(l,g,h,0,0);else{var m=(a+c)/2,n=(b+d)/2,p=(c+e)/2,q=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(m+p)/2;c=(n+q)/
2;p=(p+e)/2;q=(q+f)/2;var r=(d+p)/2,u=(c+q)/2;K.Dl(a,b,m,n,d,c,r,u,k,l);K.Dl(r,u,p,q,e,f,g,h,k,l)}return l},Qe:function(a,b,c,d,e,f,g,h,k,l){if(K.$b(a,b,g,h,k,c,d)&&K.$b(a,b,g,h,k,e,f))0===l.length&&(l.push(a),l.push(b)),l.push(g),l.push(h);else{var m=(a+c)/2,n=(b+d)/2,p=(c+e)/2,q=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(m+p)/2;c=(n+q)/2;p=(p+e)/2;q=(q+f)/2;var r=(d+p)/2,u=(c+q)/2;K.Qe(a,b,m,n,d,c,r,u,k,l);K.Qe(r,u,p,q,e,f,g,h,k,l)}return l},kv:function(a,b,c,d,e,f,g,h,k,l){if(K.$b(a,b,e,f,l,c,d))return K.$b(a,
b,e,f,l,h,k);var m=(a+c)/2,n=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var p=(m+c)/2,q=(n+d)/2;return K.kv(a,b,m,n,p,q,g,h,k,l)||K.kv(p,q,c,d,e,f,g,h,k,l)},PA:function(a,b,c,d,e,f,g){g.h(((a+c)/2+(c+e)/2)/2,((b+d)/2+(d+f)/2)/2);return g},jv:function(a,b,c,d,e,f,g,h){if(K.$b(a,b,e,f,g,c,d))Xb(h,a,b,0,0),Xb(h,e,f,0,0);else{var k=(a+c)/2,l=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var m=(k+c)/2,n=(l+d)/2;K.jv(a,b,k,l,m,n,g,h);K.jv(m,n,c,d,e,f,g,h)}return h},ep:function(a,b,c,d,e,f,g,h){if(K.$b(a,b,e,f,g,c,d))0===h.length&&(h.push(a),
h.push(b)),h.push(e),h.push(f);else{var k=(a+c)/2,l=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var m=(k+c)/2,n=(l+d)/2;K.ep(a,b,k,l,m,n,g,h);K.ep(m,n,c,d,e,f,g,h)}return h},Eo:function(a,b,c,d,e,f,g,h,k,l,m,n,p,q){if(K.$b(a,b,g,h,p,c,d)&&K.$b(a,b,g,h,p,e,f)){if(!K.Rl(a,b,g,h,k,l,m,n))return!1;p=(a-g)*(l-n)-(b-h)*(k-m);if(0===p)return!1;q.h(((a*h-b*g)*(k-m)-(a-g)*(k*n-l*m))/p,((a*h-b*g)*(l-n)-(b-h)*(k*n-l*m))/p);return!0}var r=(a+c)/2,u=(b+d)/2,w=(c+e)/2,v=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(r+w)/2;c=(u+v)/2;w=(w+e)/
2;v=(v+f)/2;var y=(d+w)/2,z=(c+v)/2,A=Infinity,B=!1,E=0,L=0;K.Eo(a,b,r,u,d,c,y,z,k,l,m,n,p,q)&&(a=(q.x-k)*(q.x-k)+(q.y-l)*(q.y-l),a<A&&(A=a,B=!0,E=q.x,L=q.y));K.Eo(y,z,w,v,e,f,g,h,k,l,m,n,p,q)&&(q.x-k)*(q.x-k)+(q.y-l)*(q.y-l)<A&&(B=!0,E=q.x,L=q.y);B&&(q.x=E,q.y=L);return B},Fo:function(a,b,c,d,e,f,g,h,k,l,m,n,p){var q=0;if(K.$b(a,b,g,h,p,c,d)&&K.$b(a,b,g,h,p,e,f)){p=(a-g)*(l-n)-(b-h)*(k-m);if(0===p)return q;var r=((a*h-b*g)*(k-m)-(a-g)*(k*n-l*m))/p,u=((a*h-b*g)*(l-n)-(b-h)*(k*n-l*m))/p;if(r>=m)return q;
if((k>m?k-m:m-k)<(l>n?l-n:n-l)){if(b<h?(k=b,a=h):(k=h,a=b),u<k||u>a)return q}else if(a<g?(k=a,a=g):k=g,r<k||r>a)return q;0<p?q++:0>p&&q--}else{r=(a+c)/2;u=(b+d)/2;var w=(c+e)/2,v=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(r+w)/2;c=(u+v)/2;w=(w+e)/2;v=(v+f)/2;var y=(d+w)/2,z=(c+v)/2;q+=K.Fo(a,b,r,u,d,c,y,z,k,l,m,n,p);q+=K.Fo(y,z,w,v,e,f,g,h,k,l,m,n,p)}return q},qh:function(a,b,c,d,e,f,g){if(K.$(a,c)){b<d?(c=b,b=d):c=d;if(f<c)return g.h(a,c),!1;if(f>b)return g.h(a,b),!1;g.h(a,f);return!0}if(K.$(b,d)){a<c?(d=a,
a=c):d=c;if(e<d)return g.h(d,b),!1;if(e>a)return g.h(a,b),!1;g.h(e,b);return!0}e=((a-e)*(a-c)+(b-f)*(b-d))/((c-a)*(c-a)+(d-b)*(d-b));if(-5E-6>e)return g.h(a,b),!1;if(1.000005<e)return g.h(c,d),!1;g.h(a+e*(c-a),b+e*(d-b));return!0},Ve:function(a,b,c,d,e,f,g,h,k){if(K.B(a,c)&&K.B(b,d))return k.h(a,b),!1;if(K.$(e,g))return K.$(a,c)?(K.qh(a,b,c,d,e,f,k),!1):K.qh(a,b,c,d,e,(d-b)/(c-a)*(e-a)+b,k);h=(h-f)/(g-e);if(K.$(a,c)){c=h*(a-e)+f;b<d?(e=b,b=d):e=d;if(c<e)return k.h(a,e),!1;if(c>b)return k.h(a,b),!1;
k.h(a,c);return!0}g=(d-b)/(c-a);if(K.$(h,g))return K.qh(a,b,c,d,e,f,k),!1;e=(g*a-h*e+f-b)/(g-h);if(K.$(g,0)){a<c?(d=a,a=c):d=c;if(e<d)return k.h(d,b),!1;if(e>a)return k.h(a,b),!1;k.h(e,b);return!0}return K.qh(a,b,c,d,e,g*(e-a)+b,k)},NA:function(a,b,c,d,e){return K.Ve(c.x,c.y,d.x,d.y,a.x,a.y,b.x,b.y,e)},LA:function(a,b,c,d,e,f,g,h,k,l){function m(c,d){var e=(c-a)*(c-a)+(d-b)*(d-b);e<n&&(n=e,k.h(c,d))}var n=Infinity;m(k.x,k.y);var p=0,q=0,r=0,u=0;e<g?(p=e,q=g):(p=g,q=e);f<h?(r=e,u=g):(r=g,u=e);p=(q-
p)/2+l;l=(u-r)/2+l;e=(e+g)/2;f=(f+h)/2;if(0===p||0===l)return k;if(.5>(c>a?c-a:a-c)){p=1-(c-e)*(c-e)/(p*p);if(0>p)return k;p=Math.sqrt(p);d=-l*p+f;m(c,l*p+f);m(c,d)}else{c=(d-b)/(c-a);d=1/(p*p)+c*c/(l*l);h=2*c*(b-c*a)/(l*l)-2*c*f/(l*l)-2*e/(p*p);p=h*h-4*d*(2*c*a*f/(l*l)-2*b*f/(l*l)+f*f/(l*l)+e*e/(p*p)-1+(b-c*a)*(b-c*a)/(l*l));if(0>p)return k;p=Math.sqrt(p);l=(-h+p)/(2*d);m(l,c*l-c*a+b);p=(-h-p)/(2*d);m(p,c*p-c*a+b)}return k},Ff:function(a,b,c,d,e,f,g,h,k){var l=1E21,m=a,n=b;if(K.Ve(a,b,a,d,e,f,g,
h,k)){var p=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f);p<l&&(l=p,m=k.x,n=k.y)}K.Ve(c,b,c,d,e,f,g,h,k)&&(p=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f),p<l&&(l=p,m=k.x,n=k.y));K.Ve(a,b,c,b,e,f,g,h,k)&&(b=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f),b<l&&(l=b,m=k.x,n=k.y));K.Ve(a,d,c,d,e,f,g,h,k)&&(a=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f),a<l&&(l=a,m=k.x,n=k.y));k.h(m,n);return 1E21>l},us:function(a,b,c){return K.Gx(a.x,a.y,a.width,a.height,b.x,b.y,c.x,c.y)},Gx:function(a,b,c,d,e,f,g,h){var k=a+c,l=b+d;return e===g?(f<h?(g=f,f=h):g=h,a<=e&&e<=
k&&g<=l&&f>=b):f===h?(e<g?(h=e,e=g):h=g,b<=f&&f<=l&&h<=k&&e>=a):Yb(a,b,c,d,e,f)||Yb(a,b,c,d,g,h)||K.Rl(a,b,k,b,e,f,g,h)||K.Rl(k,b,k,l,e,f,g,h)||K.Rl(k,l,a,l,e,f,g,h)||K.Rl(a,l,a,b,e,f,g,h)?!0:!1},Rl:function(a,b,c,d,e,f,g,h){return a===c&&b===d&&e===g&&f===h?a===e&&b===f:0>=K.Fl(a,b,c,d,e,f)*K.Fl(a,b,c,d,g,h)&&0>=K.Fl(e,f,g,h,a,b)*K.Fl(e,f,g,h,c,d)},Fl:function(a,b,c,d,e,f){c-=a;d-=b;a=e-a;b=f-b;f=a*d-b*c;0===f&&(f=a*c+b*d,0<f&&(f=(a-c)*c+(b-d)*d,0>f&&(f=0)));return f},bp:function(a){0>a&&(a+=360);
360<=a&&(a-=360);return a},kx:function(a,b,c,d,e,f){var g=Math.PI;f||(d*=g/180,e*=g/180);var h=d>e?-1:1;f=[];var k=g/2,l=d;d=Math.min(2*g,Math.abs(e-d));if(1E-5>d)return k=l+h*Math.min(d,k),h=a+c*Math.cos(l),l=b+c*Math.sin(l),a+=c*Math.cos(k),b+=c*Math.sin(k),c=(h+a)/2,k=(l+b)/2,f.push([h,l,c,k,c,k,a,b]),f;for(;1E-5<d;)e=l+h*Math.min(d,k),f.push(K.Wy(c,l,e,a,b)),d-=Math.abs(e-l),l=e;return f},Wy:function(a,b,c,d,e){var f=(c-b)/2,g=a*Math.cos(f),h=a*Math.sin(f),k=-h,l=g*g+k*k,m=l+g*g+k*h;l=4/3*(Math.sqrt(2*
l*m)-m)/(g*h-k*g);h=g-l*k;g=k+l*g;k=-g;l=f+b;f=Math.cos(l);l=Math.sin(l);return[d+a*Math.cos(b),e+a*Math.sin(b),d+h*f-g*l,e+h*l+g*f,d+h*f-k*l,e+h*l+k*f,d+a*Math.cos(c),e+a*Math.sin(c)]},No:function(a,b,c,d,e,f,g){c=Math.floor((a-c)/e)*e+c;d=Math.floor((b-d)/f)*f+d;var h=c;c+e-a<e/2&&(h=c+e);a=d;d+f-b<f/2&&(a=d+f);g.h(h,a);return g},wx:function(a,b){var c=Math.max(a,b);a=Math.min(a,b);var d;do b=c%a,c=d=a,a=b;while(0<b);return d},az:function(a,b,c,d){var e=0>c,f=0>d;if(a<b){var g=1;var h=0}else g=
0,h=1;var k=0===g?a:b;var l=0===g?c:d;if(0===g?e:f)l=-l;g=h;c=0===g?c:d;if(0===g?e:f)c=-c;return K.bz(k,0===g?a:b,l,c,0,0)},bz:function(a,b,c,d,e,f){if(0<d)if(0<c){e=a*a;f=b*b;a*=c;var g=b*d,h=-f+g,k=-f+Math.sqrt(a*a+g*g);b=h;for(var l=0;9999999999>l;++l){b=.5*(h+k);if(b===h||b===k)break;var m=a/(b+e),n=g/(b+f);m=m*m+n*n-1;if(0<m)h=b;else if(0>m)k=b;else break}c=e*c/(b+e)-c;d=f*d/(b+f)-d;c=Math.sqrt(c*c+d*d)}else c=Math.abs(d-b);else d=a*a-b*b,f=a*c,f<d?(d=f/d,f=b*Math.sqrt(Math.abs(1-d*d)),c=a*d-
c,c=Math.sqrt(c*c+f*f)):c=Math.abs(c-a);return c},Ze:new Ua,um:new Ua,Mf:new Ua,Nf:0,vm:100};K.za=K.BA;function P(a){Qa(this);this.l=2;void 0===a&&(a=P.j);this.na=a;this.Ec=this.Dc=this.pd=this.od=0;a===P.j?this.ee=new F:(null===P.V&&(P.V=(new F).freeze()),this.ee=P.V);this.aq=this.ee.Ja;this.Ip=(new N).freeze();this.ym=this.sk=null;this.zm=NaN;this.fg=sc;this.gg=Bc;this.Qk=this.Rk=NaN;this.Uf=nd}
P.prototype.copy=function(){var a=new P;a.l=this.l&-2;a.na=this.na;a.od=this.od;a.pd=this.pd;a.Dc=this.Dc;a.Ec=this.Ec;if(this.na===P.j){for(var b=this.ee.s,c=a.ee,d=0;d<b.length;d++)c.add(b[d].copy());a.ee=c}else a.ee=this.ee;a.aq=this.aq;a.Ip.assign(this.Ip);a.sk=this.sk;a.ym=this.ym;a.zm=this.zm;a.fg=this.fg.J();a.gg=this.gg.J();a.Rk=this.Rk;a.Qk=this.Qk;a.Uf=this.Uf;return a};P.prototype.ja=function(){this.freeze();Object.freeze(this);return this};
P.prototype.freeze=function(){this._isFrozen=!0;if(this.type!==P.j)return this;var a=this.figures;a.freeze();a=a.s;for(var b=a.length,c=0;c<b;c++)a[c].freeze();return this};P.prototype.ka=function(){Object.isFrozen(this)&&C("cannot thaw constant: "+this);this._isFrozen=!1;if(this.type!==P.j)return this;var a=this.figures;a.ka();a=a.s;for(var b=a.length,c=0;c<b;c++)a[c].ka();return this};
P.prototype.Xa=function(a){if(!(a instanceof P))return!1;if(this.type!==a.type)return this.type===P.o&&a.type===P.j?od(this,a):a.type===P.o&&this.type===P.j?od(a,this):!1;if(this.type===P.j){var b=this.figures.s;a=a.figures.s;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Xa(a[d]))return!1;return!0}return K.B(this.startX,a.startX)&&K.B(this.startY,a.startY)&&K.B(this.endX,a.endX)&&K.B(this.endY,a.endY)};
function od(a,b){return a.type!==P.o||b.type!==P.j?!1:1===b.figures.count&&(b=b.figures.M(0),1===b.segments.count&&K.B(a.startX,b.startX)&&K.B(a.startY,b.startY)&&(b=b.segments.M(0),b.type===pd&&K.B(a.endX,b.endX)&&K.B(a.endY,b.endY)))?!0:!1}P.ia=function(a){return a.toString()};P.prototype.qb=function(a){a.classType===P&&(this.type=a)};
P.prototype.toString=function(a){void 0===a&&(a=-1);switch(this.type){case P.o:return 0>a?"M"+this.startX.toString()+" "+this.startY.toString()+"L"+this.endX.toString()+" "+this.endY.toString():"M"+this.startX.toFixed(a)+" "+this.startY.toFixed(a)+"L"+this.endX.toFixed(a)+" "+this.endY.toFixed(a);case P.u:var b=new N(this.startX,this.startY,0,0);b.Lv(this.endX,this.endY,0,0);return 0>a?"M"+b.x.toString()+" "+b.y.toString()+"H"+b.right.toString()+"V"+b.bottom.toString()+"H"+b.left.toString()+"z":"M"+
b.x.toFixed(a)+" "+b.y.toFixed(a)+"H"+b.right.toFixed(a)+"V"+b.bottom.toFixed(a)+"H"+b.left.toFixed(a)+"z";case P.F:b=new N(this.startX,this.startY,0,0);b.Lv(this.endX,this.endY,0,0);if(0>a)return a=b.left.toString()+" "+(b.y+b.height/2).toString(),"M"+a+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+(b.right.toString()+" "+(b.y+b.height/2).toString())+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+a;var c=b.left.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);return"M"+
c+"A"+(b.width/2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+(b.right.toFixed(a)+" "+(b.y+b.height/2).toFixed(a))+"A"+(b.width/2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+c;case P.j:b="";c=this.figures.s;for(var d=c.length,e=0;e<d;e++){var f=c[e];0<e&&(b+=" x ");f.isFilled&&(b+="F ");b+=f.toString(a)}return b;default:return this.type.toString()}};
P.nb=function(a){a=a.split(/[Xx]/);for(var b=a.length,c="",d=0;d<b;d++){var e=a[d];c=null!==e.match(/[Ff]/)?0===d?c+e:c+("X"+(" "===e[0]?"":" ")+e):c+((0===d?"":"X ")+"F"+(" "===e[0]?"":" ")+e)}return c};
P.parse=function(a,b){function c(){return u>=A-1?!0:null!==l[u+1].match(B)}function d(){u++;return l[u]}function e(a){var b=parseFloat(d()),c=parseFloat(d());w===w.toLowerCase()&&(b=z.x+b,c=z.y+c);a.h(b,c)}function f(){e(z);return z}function g(){e(y);return y}function h(){var a=v.toLowerCase();return"c"!==a&&"s"!==a&&"q"!==a&&"t"!==a?new I(z.x,z.y):new I(2*z.x-y.x,2*z.y-y.y)}void 0===b&&(b=!1);var k=/([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])/gm;a=a.replace(/,/gm," ");a=a.replace(k,
"$1 $2");a=a.replace(k,"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])([^s])/gm,"$1 $2");a=a.replace(/([^s])([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])/gm,"$1 $2");a=a.replace(/([0-9])([+\-])/gm,"$1 $2");a=a.replace(/[\s\r\t\n]+/gm," ");a=a.replace(/^\s+|\s+$/g,"");var l=a.split(" ");for(a=0;a<l.length;a++){var m=l[a];if(null!==m.match(/(\.[0-9]*)(\.)/gm)){k=Ea();for(var n="",p=!1,q=0;q<m.length;q++){var r=m[q];"."!==r||p?"."===r?(k.push(n),n="."):n+=r:(p=!0,n+=r)}k.push(n);l.splice(a,1);for(m=0;m<k.length;m++)l.splice(a+
m,0,k[m]);a+=k.length-1;Ga(k)}}var u=-1,w="",v="";k=new I(0,0);var y=new I(0,0),z=new I(0,0),A=l.length;a=qd(null);p=n=m=!1;q=!0;r=null;for(var B=/[UuBbMmZzLlHhVvCcSsQqTtAaFfXx]/;!(u>=A-1);)if(v=w,w=d(),""!==w)switch(w.toUpperCase()){case "X":q=!0;n=m=!1;break;case "M":r=f();null===a.nc||!0===q?(rd(a,r.x,r.y,m,!n,p),q=!1):a.moveTo(r.x,r.y);for(k.assign(z);!c();)r=f(),a.lineTo(r.x,r.y);break;case "L":for(;!c();)r=f(),a.lineTo(r.x,r.y);break;case "H":for(;!c();)z.h((w===w.toLowerCase()?z.x:0)+parseFloat(d()),
z.y),a.lineTo(z.x,z.y);break;case "V":for(;!c();)z.h(z.x,(w===w.toLowerCase()?z.y:0)+parseFloat(d())),a.lineTo(z.x,z.y);break;case "C":for(;!c();){r=new I;e(r);var E=g(),L=f();sd(a,r.x,r.y,E.x,E.y,L.x,L.y)}break;case "S":for(;!c();)r=h(),E=g(),L=f(),sd(a,r.x,r.y,E.x,E.y,L.x,L.y);break;case "Q":for(;!c();)r=g(),E=f(),td(a,r.x,r.y,E.x,E.y);break;case "T":for(;!c();)r=h(),y.assign(r),E=f(),td(a,r.x,r.y,E.x,E.y),y.assign(E);break;case "B":for(;!c();){r=parseFloat(d());E=parseFloat(d());L=parseFloat(d());
var R=parseFloat(d()),S=parseFloat(d()),J=S,G=!1;c()||(J=parseFloat(d()),c()||(G=0!==parseFloat(d())));w===w.toLowerCase()&&(L+=z.x,R+=z.y);a.arcTo(r,E,L,R,S,J,G)}break;case "A":for(;!c();)r=Math.abs(parseFloat(d())),E=Math.abs(parseFloat(d())),L=parseFloat(d()),S=R=!1,G=J=0,J=d(),1===J.length?(R=!!parseFloat(J),S=!!parseFloat(d()),J=parseFloat(d())):2===J.length?(R=!!parseFloat(J[0]),S=!!parseFloat(J[1]),J=parseFloat(d())):(R=!!parseFloat(J[0]),S=!!parseFloat(J[1]),J=parseFloat(J.slice(2))),G=parseFloat(d()),
w===w.toLowerCase()&&(J=z.x+J,G=z.y+G),z.h(J,G),wd(a,r,E,L,R,S,J,G);break;case "Z":xd(a);z.assign(k);break;case "F":r="";for(E=1;l[u+E];)if("0"===l[u+E])p=!0,E++;else if(null!==l[u+E].match(/[Uu]/))E++;else if(null===l[u+E].match(B))E++;else{r=l[u+E];break}r.match(/[Mm]/)?m=!0:0<a.nc.segments.length&&(a.nc.isFilled=!0);break;case "U":r="";for(E=1;l[u+E];)if(null!==l[u+E].match(/[Ff]/))E++;else if(null===l[u+E].match(B))E++;else{r=l[u+E];break}r.match(/[Mm]/)?n=!0:a.nc.isShadowed=!1}k=a.os;yd=a;if(b)for(b=
k.figures.iterator;b.next();)b.value.isFilled=!0;return k};P.Ha=function(a,b){for(var c=a.length,d=I.alloc(),e=0;e<c;e++){var f=a[e];d.x=f[0];d.y=f[1];b.Ga(d);f[0]=d.x;f[1]=d.y;d.x=f[2];d.y=f[3];b.Ga(d);f[2]=d.x;f[3]=d.y;d.x=f[4];d.y=f[5];b.Ga(d);f[4]=d.x;f[5]=d.y;d.x=f[6];d.y=f[7];b.Ga(d);f[6]=d.x;f[7]=d.y}I.free(d)};P.prototype.$u=function(){if(this._isInvalidBounds)return!0;if(this.type===P.j){if(this.aq!==this.figures.Ja)return!0;for(var a=this.figures.s,b=a.length,c=0;c<b;c++)if(a[c].$u())return!0}return!1};
P.prototype.computeBounds=function(){this._isInvalidBounds=!1;this.ym=this.sk=null;this.zm=NaN;if(this.type===P.j){this.aq=this.figures.Ja;for(var a=this.figures.s,b=a.length,c=0;c<b;c++){var d=a[c];d._isInvalidBounds=!1;var e=d.segments;d.sr=e.Ja;d=e.s;e=d.length;for(var f=0;f<e;f++){var g=d[f];g._isInvalidBounds=!1;g.ae=null}}}a=this.Ip;a.ka();isNaN(this.Rk)||isNaN(this.Qk)?a.h(0,0,0,0):a.h(0,0,this.Rk,this.Qk);zd(this,a,!1);Xb(a,0,0,0,0);a.freeze()};
P.prototype.jx=function(){var a=new N;zd(this,a,!0);return a};
function zd(a,b,c){switch(a.type){case P.o:case P.u:case P.F:c?b.h(a.od,a.pd,0,0):Xb(b,a.od,a.pd,0,0);Xb(b,a.Dc,a.Ec,0,0);break;case P.j:var d=a.figures;a=d.s;d=d.length;for(var e=0;e<d;e++){var f=a[e];c&&0===e?b.h(f.startX,f.startY,0,0):Xb(b,f.startX,f.startY,0,0);for(var g=f.segments.s,h=g.length,k=f.startX,l=f.startY,m=0;m<h;m++){var n=g[m];switch(n.type){case pd:case Ad:k=n.endX;l=n.endY;Xb(b,k,l,0,0);break;case Bd:K.Dl(k,l,n.point1X,n.point1Y,n.point2X,n.point2Y,n.endX,n.endY,.5,b);k=n.endX;
l=n.endY;break;case Cd:K.jv(k,l,n.point1X,n.point1Y,n.endX,n.endY,.5,b);k=n.endX;l=n.endY;break;case Dd:case Jd:var p=n.type===Dd?Kd(n,f):Ld(n,f,k,l),q=p.length;if(0===q){k=n.type===Dd?n.centerX:n.endX;l=n.type===Dd?n.centerY:n.endY;Xb(b,k,l,0,0);break}n=null;for(var r=0;r<q;r++)n=p[r],K.Dl(n[0],n[1],n[2],n[3],n[4],n[5],n[6],n[7],.5,b);null!==n&&(k=n[6],l=n[7]);break;default:C("Unknown Segment type: "+n.type)}}}break;default:C("Unknown Geometry type: "+a.type)}}
P.prototype.normalize=function(){this._isFrozen&&va(this);var a=this.jx();this.offset(-a.x,-a.y);return new I(-a.x,-a.y)};P.prototype.offset=function(a,b){this._isFrozen&&va(this);this.transform(1,0,0,1,a,b);return this};P.prototype.scale=function(a,b){this._isFrozen&&va(this);this.transform(a,0,0,b,0,0);return this};
P.prototype.rotate=function(a,b,c){this._isFrozen&&va(this);void 0===b&&(b=0);void 0===c&&(c=0);var d=ld.alloc();d.reset();d.rotate(a,b,c);this.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);ld.free(d);return this};t=P.prototype;
t.transform=function(a,b,c,d,e,f){switch(this.type){case P.o:case P.u:case P.F:var g=this.od;var h=this.pd;this.od=g*a+h*c+e;this.pd=g*b+h*d+f;g=this.Dc;h=this.Ec;this.Dc=g*a+h*c+e;this.Ec=g*b+h*d+f;break;case P.j:for(var k=this.figures.s,l=k.length,m=0;m<l;m++){var n=k[m];g=n.startX;h=n.startY;n.startX=g*a+h*c+e;n.startY=g*b+h*d+f;n=n.segments.s;for(var p=n.length,q=0;q<p;q++){var r=n[q];switch(r.type){case pd:case Ad:g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;break;case Bd:g=r.point1X;
h=r.point1Y;r.point1X=g*a+h*c+e;r.point1Y=g*b+h*d+f;g=r.point2X;h=r.point2Y;r.point2X=g*a+h*c+e;r.point2Y=g*b+h*d+f;g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;break;case Cd:g=r.point1X;h=r.point1Y;r.point1X=g*a+h*c+e;r.point1Y=g*b+h*d+f;g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;break;case Dd:g=r.centerX;h=r.centerY;r.centerX=g*a+h*c+e;r.centerY=g*b+h*d+f;0!==b&&(g=180*Math.atan2(b,a)/Math.PI,0>g&&(g+=360),r.startAngle+=g);0>a&&(r.startAngle=180-r.startAngle,r.sweepAngle=-r.sweepAngle);
0>d&&(r.startAngle=-r.startAngle,r.sweepAngle=-r.sweepAngle);r.radiusX*=Math.sqrt(a*a+c*c);void 0!==r.radiusY&&(r.radiusY*=Math.sqrt(b*b+d*d));break;case Jd:g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;if(0===r.radiusX||0===r.radiusY)break;0!==b&&(g=180*Math.atan2(b,a)/Math.PI,0>g&&(g+=360),r.xAxisRotation+=g);0>a&&(r.xAxisRotation=180-r.xAxisRotation,r.isClockwiseArc=!r.isClockwiseArc);0>d&&(r.xAxisRotation=-r.xAxisRotation,r.isClockwiseArc=!r.isClockwiseArc);r.radiusX*=Math.sqrt(a*a+c*c);
r.radiusY*=Math.sqrt(b*b+d*d);break;default:C("Unknown Segment type: "+r.type)}}}}this._isInvalidBounds=!0;return this};
t.ca=function(a,b){void 0===b&&(b=0);var c=this.od,d=this.pd,e=this.Dc,f=this.Ec;switch(this.type){case P.o:return K.$b(c,d,e,f,b,a.x,a.y);case P.u:var g=N.allocAt(Math.min(c,e)-b,Math.min(d,f)-b,Math.abs(e-c)+2*b,Math.abs(f-d)+2*b);a=g.ca(a);N.free(g);return a;case P.F:g=Math.min(c,e)-b;var h=Math.min(d,f)-b;c=(Math.abs(e-c)+2*b)/2;b=(Math.abs(f-d)+2*b)/2;if(0>=c||0>=b)return!1;g=a.x-(g+c);h=a.y-(h+b);return 1>=g*g/(c*c)+h*h/(b*b);case P.j:return Md(this,a,b,!0,!1);default:return!1}};
function Md(a,b,c,d,e){var f=b.x;b=b.y;for(var g=a.bounds.x-20,h=0,k,l,m,n,p=a.figures.s,q=p.length,r=0;r<q;r++){var u=p[r],w=!u.isEvenOdd;if(u.isFilled){if(d&&u.ca(f,b,c))return!0;var v=u.segments;k=u.startX;l=u.startY;for(var y=k,z=l,A=v.s,B=0;B<=v.length;B++){var E=void 0;if(B!==v.length){E=A[B];var L=E.type;a=E.endX;n=E.endY}else L=pd,a=y,n=z;switch(L){case Ad:y=Nd(f,b,g,b,k,l,y,z);if(isNaN(y))return!0;h+=y;y=a;z=n;break;case pd:k=Nd(f,b,g,b,k,l,a,n);if(isNaN(k))return!0;h+=k;break;case Bd:m=
K.Fo(k,l,E.point1X,E.point1Y,E.point2X,E.point2Y,a,n,g,b,f,b,.5);h+=m;break;case Cd:m=K.Fo(k,l,(k+2*E.point1X)/3,(l+2*E.point1Y)/3,(2*E.point1X+a)/3,(2*E.point1Y+n)/3,a,n,g,b,f,b,.5);h+=m;break;case Dd:case Jd:L=E.type===Dd?Kd(E,u):Ld(E,u,k,l);var R=L.length;if(0===R){k=Nd(f,b,g,b,k,l,E.type===Dd?E.centerX:E.endX,E.type===Dd?E.centerY:E.endY);if(isNaN(k))return!0;h+=k;break}E=null;for(var S=0;S<R;S++){E=L[S];if(0===S){m=Nd(f,b,g,b,k,l,E[0],E[1]);if(isNaN(m))return!0;h+=m}m=K.Fo(E[0],E[1],E[2],E[3],
E[4],E[5],E[6],E[7],g,b,f,b,.5);h+=m}null!==E&&(a=E[6],n=E[7]);break;default:C("Unknown Segment type: "+E.type)}k=a;l=n}if(w){if(0!==h)return!0}else if(0!==h%2)return!0;h=0}else if(u.ca(f,b,e?c:c+2))return!0}return!1}
function Nd(a,b,c,d,e,f,g,h){if(K.$b(e,f,g,h,.05,a,b))return NaN;var k=(a-c)*(f-h);if(0===k)return 0;var l=((a*d-b*c)*(e-g)-(a-c)*(e*h-f*g))/k;b=(a*d-b*c)*(f-h)/k;if(l>=a)return 0;if((e>g?e-g:g-e)<(f>h?f-h:h-f))if(f<h){if(b<f||b>h)return 0}else{if(b<h||b>f)return 0}else if(e<g){if(l<e||l>g)return 0}else if(l<g||l>e)return 0;return 0<k?1:-1}function Od(a,b,c,d){a=a.figures.s;for(var e=a.length,f=0;f<e;f++)if(a[f].ca(b,c,d))return!0;return!1}
t.Qu=function(a,b){0>a?a=0:1<a&&(a=1);void 0===b&&(b=new I);if(this.type===P.o)return b.h(this.startX+a*(this.endX-this.startX),this.startY+a*(this.endY-this.startY)),b;for(var c=this.flattenedSegments,d=this.flattenedLengths,e=c.length,f=this.flattenedTotalLength*a,g=0,h=0;h<e;h++){var k=d[h],l=k.length;for(a=0;a<l;a++){var m=k[a];if(g+m>=f)return d=f-g,d=0===m?0:d/m,c=c[h],h=c[2*a],e=c[2*a+1],b.h(h+(c[2*a+2]-h)*d,e+(c[2*a+3]-e)*d),b;g+=m}}return b};
t.xx=function(a){0>a?a=0:1<a&&(a=1);if(this.type===P.o)return 180*Math.atan2(this.endY-this.startY,this.endX-this.startX)/Math.PI;for(var b=this.flattenedSegments,c=this.flattenedLengths,d=b.length,e=this.flattenedTotalLength*a,f=0,g=0;g<d;g++){var h=c[g],k=h.length;for(a=0;a<k;a++){var l=h[a];if(f+l>=e)return e=b[g],b=e[2*a],c=e[2*a+1],d=e[2*a+2],a=e[2*a+3],1>Math.abs(d-b)&&1>Math.abs(a-c)?0:1>Math.abs(d-b)?0<=a-c?90:270:1>Math.abs(a-c)?0<=d-b?0:180:180*Math.atan2(a-c,d-b)/Math.PI;f+=l}}return NaN};
t.Ru=function(a,b){0>a?a=0:1<a&&(a=1);void 0===b&&(b=[]);b.length=3;if(this.type===P.o)return b[0]=this.startX+a*(this.endX-this.startX),b[1]=this.startY+a*(this.endY-this.startY),b[2]=180*Math.atan2(this.endY-this.startY,this.endX-this.startX)/Math.PI,b;for(var c=this.flattenedSegments,d=this.flattenedLengths,e=c.length,f=this.flattenedTotalLength*a,g=0,h=0;h<e;h++){var k=d[h],l=k.length;for(a=0;a<l;a++){var m=k[a];if(g+m>=f)return d=f-g,d=0===m?0:d/m,m=c[h],c=m[2*a],h=m[2*a+1],e=m[2*a+2],a=m[2*
a+3],b[0]=c+(e-c)*d,b[1]=h+(a-h)*d,b[2]=1>Math.abs(e-c)&&1>Math.abs(a-h)?0:1>Math.abs(e-c)?0<=a-h?90:270:1>Math.abs(a-h)?0<=e-c?0:180:180*Math.atan2(a-h,e-c)/Math.PI,b;g+=m}}return b};
t.yx=function(a){if(this.type===P.o){var b=this.startX,c=this.startY,d=this.endX,e=this.endY;if(b!==d||c!==e){var f=a.x;a=a.y;if(b===d){if(c<e){var g=c;d=e}else g=e,d=c;return a<=g?g===c?0:1:a>=d?d===c?0:1:Math.abs(a-c)/(d-g)}if(c===e)return b<d?g=b:(g=d,d=b),f<=g?g===b?0:1:f>=d?d===b?0:1:Math.abs(f-b)/(d-g);g=(d-b)*(d-b)+(e-c)*(e-c);var h=I.alloc();K.qh(b,c,d,e,f,a,h);a=h.x;f=h.y;I.free(h);return Math.sqrt(((a-b)*(a-b)+(f-c)*(f-c))/g)}}else if(this.type===P.u){g=this.startX;h=this.startY;var k=this.endX;
e=this.endY;if(g!==k||h!==e){b=k-g;c=e-h;f=2*b+2*c;d=a.x;a=a.y;d=Math.min(Math.max(d,g),k);a=Math.min(Math.max(a,h),e);g=Math.abs(d-g);k=Math.abs(d-k);h=Math.abs(a-h);e=Math.abs(a-e);var l=Math.min(g,k,h,e);if(l===h)return d/f;if(l===k)return(b+a)/f;if(l===e)return(2*b+c-d)/f;if(l===g)return(2*b+2*c-a)/f}}else{b=this.flattenedSegments;c=this.flattenedLengths;f=this.flattenedTotalLength;d=I.alloc();e=Infinity;h=g=0;k=b.length;for(var m=l=0,n=0;n<k;n++)for(var p=b[n],q=c[n],r=p.length,u=0;u<r;u+=2){var w=
p[u],v=p[u+1];if(0!==u){K.qh(l,m,w,v,a.x,a.y,d);var y=(d.x-a.x)*(d.x-a.x)+(d.y-a.y)*(d.y-a.y);y<e&&(e=y,g=h,g+=Math.sqrt((d.x-l)*(d.x-l)+(d.y-m)*(d.y-m)));h+=q[(u-2)/2]}l=w;m=v}I.free(d);a=g/f;return 0>a?0:1<a?1:a}return 0};
function Pd(a){if(null===a.sk){var b=a.sk=[],c=a.ym=[],d=[],e=[];if(a.type===P.o)d.push(a.startX),d.push(a.startY),d.push(a.endX),d.push(a.endY),b.push(d),e.push(Math.sqrt((a.startX-a.endX)*(a.startX-a.endX)+(a.startY-a.endY)*(a.startY-a.endY))),c.push(e);else if(a.type===P.u)d.push(a.startX),d.push(a.startY),d.push(a.endX),d.push(a.startY),d.push(a.endX),d.push(a.endY),d.push(a.startX),d.push(a.endY),d.push(a.startX),d.push(a.startY),b.push(d),e.push(Math.abs(a.startX-a.endX)),e.push(Math.abs(a.startY-
a.endY)),e.push(Math.abs(a.startX-a.endX)),e.push(Math.abs(a.startY-a.endY)),c.push(e);else if(a.type===P.F){var f=new Qd;f.startX=a.endX;f.startY=(a.startY+a.endY)/2;var g=new Rd(Dd);g.startAngle=0;g.sweepAngle=360;g.centerX=(a.startX+a.endX)/2;g.centerY=(a.startY+a.endY)/2;g.radiusX=Math.abs(a.startX-a.endX)/2;g.radiusY=Math.abs(a.startY-a.endY)/2;f.add(g);a=Kd(g,f);e=a.length;if(0===e)d.push(g.centerX),d.push(g.centerY);else{g=f.startX;f=f.startY;for(var h=0;h<e;h++){var k=a[h];K.Qe(g,f,k[2],k[3],
k[4],k[5],k[6],k[7],.5,d);g=k[6];f=k[7]}}b.push(d);c.push(Sd(d))}else for(a=a.figures.iterator;a.next();){e=a.value;d=[];d.push(e.startX);d.push(e.startY);g=e.startX;f=e.startY;h=g;k=f;for(var l=e.segments.s,m=l.length,n=0;n<m;n++){var p=l[n];switch(p.type){case Ad:4<=d.length&&(b.push(d),c.push(Sd(d)));d=[];d.push(p.endX);d.push(p.endY);g=p.endX;f=p.endY;h=g;k=f;break;case pd:d.push(p.endX);d.push(p.endY);g=p.endX;f=p.endY;break;case Bd:K.Qe(g,f,p.point1X,p.point1Y,p.point2X,p.point2Y,p.endX,p.endY,
.5,d);g=p.endX;f=p.endY;break;case Cd:K.ep(g,f,p.point1X,p.point1Y,p.endX,p.endY,.5,d);g=p.endX;f=p.endY;break;case Dd:var q=Kd(p,e),r=q.length;if(0===r){d.push(p.centerX);d.push(p.centerY);g=p.centerX;f=p.centerY;break}for(var u=0;u<r;u++){var w=q[u];K.Qe(g,f,w[2],w[3],w[4],w[5],w[6],w[7],.5,d);g=w[6];f=w[7]}break;case Jd:q=Ld(p,e,g,f);r=q.length;u=p.endX;w=p.endY;if(0===r){d.push(u);d.push(w);g=u;f=w;break}for(u=0;u<r;u++)w=q[u],K.Qe(g,f,w[2],w[3],w[4],w[5],w[6],w[7],.5,d),g=w[6],f=w[7];break;default:C("Segment not of valid type: "+
p.type)}p.isClosed&&(d.push(h),d.push(k))}4<=d.length&&(b.push(d),c.push(Sd(d)))}}}function Sd(a){for(var b=[],c=0,d=0,e=a.length,f=0;f<e;f+=2){var g=a[f],h=a[f+1];0!==f&&b.push(Math.sqrt(zb(c,d,g,h)));c=g;d=h}return b}t.add=function(a){this.ee.add(a);return this};t.pm=function(a,b,c,d,e,f,g,h){this._isFrozen&&va(this);this.fg=(new O(a,b,e,f)).freeze();this.gg=(new O(c,d,g,h)).freeze();return this};
ma.Object.defineProperties(P.prototype,{_isFrozen:{get:function(){return 0!==(this.l&1)},set:function(a){this.l=a?this.l|1:this.l&-2}},_isInvalidBounds:{get:function(){return 0!==(this.l&2)},set:function(a){this.l=a?this.l|2:this.l&-3}},flattenedSegments:{get:function(){Pd(this);return this.sk}},flattenedLengths:{get:function(){Pd(this);return this.ym}},flattenedTotalLength:{
get:function(){var a=this.zm;if(isNaN(a)){if(this.type===P.o){a=Math.abs(this.endX-this.startX);var b=Math.abs(this.endY-this.startY);a=Math.sqrt(a*a+b*b)}else if(this.type===P.u)a=2*Math.abs(this.endX-this.startX)+2*Math.abs(this.endY-this.startY);else{b=this.flattenedLengths;for(var c=b.length,d=a=0;d<c;d++)for(var e=b[d],f=e.length,g=0;g<f;g++)a+=e[g]}this.zm=a}return a}},type:{get:function(){return this.na},set:function(a){this.na!==a&&(this._isFrozen&&
va(this,a),this.na=a,a===P.j?this.ee=new F:(null===P.V&&(P.V=(new F).freeze()),this.ee=P.V),this._isInvalidBounds=!0)}},startX:{get:function(){return this.od},set:function(a){this.od!==a&&(this._isFrozen&&va(this,a),this.od=a,this._isInvalidBounds=!0)}},startY:{get:function(){return this.pd},set:function(a){this.pd!==a&&(this._isFrozen&&va(this,a),this.pd=a,this._isInvalidBounds=!0)}},endX:{get:function(){return this.Dc},
set:function(a){this.Dc!==a&&(this._isFrozen&&va(this,a),this.Dc=a,this._isInvalidBounds=!0)}},endY:{get:function(){return this.Ec},set:function(a){this.Ec!==a&&(this._isFrozen&&va(this,a),this.Ec=a,this._isInvalidBounds=!0)}},figures:{get:function(){return this.ee},set:function(a){this.ee!==a&&(this._isFrozen&&va(this,a),this.ee=a,this._isInvalidBounds=!0)}},spot1:{get:function(){return this.fg},set:function(a){this._isFrozen&&
va(this,a);this.fg=a.J()}},spot2:{get:function(){return this.gg},set:function(a){this._isFrozen&&va(this,a);this.gg=a.J()}},defaultStretch:{get:function(){return this.Uf},set:function(a){this._isFrozen&&va(this,a);this.Uf=a}},bounds:{get:function(){this.$u()&&this.computeBounds();return this.Ip}}});P.prototype.setSpots=P.prototype.pm;P.prototype.add=P.prototype.add;P.prototype.getFractionForPoint=P.prototype.yx;
P.prototype.getPointAndAngleAlongPath=P.prototype.Ru;P.prototype.getAngleAlongPath=P.prototype.xx;P.prototype.getPointAlongPath=P.prototype.Qu;P.prototype.containsPoint=P.prototype.ca;P.prototype.transform=P.prototype.transform;P.prototype.rotate=P.prototype.rotate;P.prototype.scale=P.prototype.scale;P.prototype.offset=P.prototype.offset;P.prototype.normalize=P.prototype.normalize;P.prototype.computeBoundsWithoutOrigin=P.prototype.jx;P.prototype.equalsApprox=P.prototype.Xa;P.className="Geometry";
P.o=new D(P,"Line",0);P.u=new D(P,"Rectangle",1);P.F=new D(P,"Ellipse",2);P.j=new D(P,"Path",3);P.V=null;P.hb="UuBbMmZzLlHhVvCcSsQqTtAaFfXx";P.className="Geometry";P.stringify=P.ia;P.fillPath=P.nb;P.parse=P.parse;P.Line=P.o;P.Rectangle=P.u;P.Ellipse=P.F;P.Path=P.j;function Qd(a,b,c,d,e){Qa(this);void 0===c&&(c=!0);void 0===d&&(d=!0);void 0===e&&(e=!1);this.l=2|(c?4:0)|(d?8:0)|(e?16:0);void 0!==a?this.od=a:this.od=0;void 0!==b?this.pd=b:this.pd=0;this.pl=new F;this.sr=this.pl.Ja;this.gw=null}
Qd.prototype.copy=function(){var a=new Qd;a.l=this.l&-2;a.od=this.od;a.pd=this.pd;for(var b=this.pl.s,c=b.length,d=a.pl,e=0;e<c;e++){var f=b[e].copy();d.add(f)}a.sr=this.sr;a.gw=this.gw;return a};t=Qd.prototype;t.Xa=function(a){if(!(a instanceof Qd&&K.B(this.startX,a.startX)&&K.B(this.startY,a.startY)))return!1;var b=this.segments.s;a=a.segments.s;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Xa(a[d]))return!1;return!0};
t.toString=function(a){void 0===a&&(a=-1);var b=0>a?"M"+this.startX.toString()+" "+this.startY.toString():"M"+this.startX.toFixed(a)+" "+this.startY.toFixed(a);for(var c=this.segments.s,d=c.length,e=0;e<d;e++)b+=" "+c[e].toString(a);return b};t.freeze=function(){this._isFrozen=!0;var a=this.segments;a.freeze();var b=a.s;a=a.length;for(var c=0;c<a;c++)b[c].freeze();return this};t.ka=function(){this._isFrozen=!1;var a=this.segments;a.ka();a=a.s;for(var b=a.length,c=0;c<b;c++)a[c].ka();return this};
t.$u=function(){if(this._isInvalidBounds)return!0;var a=this.segments;if(this.sr!==a.Ja)return!0;a=a.s;for(var b=a.length,c=0;c<b;c++)if(a[c]._isInvalidBounds)return!0;return!1};t.add=function(a){this.pl.add(a);return this};
t.ca=function(a,b,c){for(var d=this.startX,e=this.startY,f=d,g=e,h=this.segments.s,k=h.length,l=0;l<k;l++){var m=h[l];switch(m.type){case Ad:f=m.endX;g=m.endY;d=m.endX;e=m.endY;break;case pd:if(K.$b(d,e,m.endX,m.endY,c,a,b))return!0;d=m.endX;e=m.endY;break;case Bd:if(K.cs(d,e,m.point1X,m.point1Y,m.point2X,m.point2Y,m.endX,m.endY,.5,a,b,c))return!0;d=m.endX;e=m.endY;break;case Cd:if(K.kv(d,e,m.point1X,m.point1Y,m.endX,m.endY,.5,a,b,c))return!0;d=m.endX;e=m.endY;break;case Dd:case Jd:var n=m.type===
Dd?Kd(m,this):Ld(m,this,d,e),p=n.length,q=m.type===Dd?m.centerX:m.endX,r=m.type===Dd?m.centerY:m.endY;if(0===p){if(K.$b(d,e,q,r,c,a,b))return!0;d=q;e=r;break}q=null;for(r=0;r<p;r++)if(q=n[r],0===r&&K.$b(d,e,q[0],q[1],c,a,b)||K.cs(q[0],q[1],q[2],q[3],q[4],q[5],q[6],q[7],.5,a,b,c))return!0;null!==q&&(d=q[6],e=q[7]);break;default:C("Unknown Segment type: "+m.type)}if(m.isClosed&&(d!==f||e!==g)&&K.$b(d,e,f,g,c,a,b))return!0}return!1};
ma.Object.defineProperties(Qd.prototype,{_isFrozen:{get:function(){return 0!==(this.l&1)},set:function(a){this.l=a?this.l|1:this.l&-2}},_isInvalidBounds:{get:function(){return 0!==(this.l&2)},set:function(a){this.l=a?this.l|2:this.l&-3}},isFilled:{get:function(){return 0!==(this.l&4)},set:function(a){this._isFrozen&&va(this,a);this.l=a?this.l|4:this.l&-5}},isShadowed:{get:function(){return 0!==
(this.l&8)},set:function(a){this._isFrozen&&va(this,a);this.l=a?this.l|8:this.l&-9}},isEvenOdd:{get:function(){return 0!==(this.l&16)},set:function(a){this._isFrozen&&va(this,a);this.l=a?this.l|16:this.l&-17}},startX:{get:function(){return this.od},set:function(a){this._isFrozen&&va(this,a);this.od=a;this._isInvalidBounds=!0}},startY:{get:function(){return this.pd},set:function(a){this._isFrozen&&va(this,a);
this.pd=a;this._isInvalidBounds=!0}},segments:{get:function(){return this.pl},set:function(a){this._isFrozen&&va(this,a);this.pl=a;this._isInvalidBounds=!0}}});Qd.prototype.add=Qd.prototype.add;Qd.prototype.equalsApprox=Qd.prototype.Xa;Qd.className="PathFigure";
function Rd(a,b,c,d,e,f,g,h){Qa(this);void 0===a&&(a=pd);this.na=a;this.l=2;void 0!==b?this.Dc=b:this.Dc=0;void 0!==c?this.Ec=c:this.Ec=0;void 0===d&&(d=0);void 0===e&&(e=0);void 0===f&&(f=0);void 0===g&&(g=0);a===Jd?(a=f%360,0>a&&(a+=360),this.Ne=a,this.Vh=0,this.Wh=Math.max(d,0),this.Pg=Math.max(e,0),"boolean"===typeof g?g&&(this.isLargeArc=!0):"number"===typeof g&&g&&(this.isLargeArc=!0),h&&(this.isClockwiseArc=!0)):(this.Ne=d,this.Vh=e,a===Dd&&(f=Math.max(f,0)),this.Wh=f,"number"===typeof g?(a===
Dd&&(g=Math.max(g,0)),this.Pg=g):this.Pg=0);this.ae=null}Rd.prototype.copy=function(){var a=new Rd;a.na=this.na;a.l=this.l&-2;a.Dc=this.Dc;a.Ec=this.Ec;a.Ne=this.Ne;a.Vh=this.Vh;a.Wh=this.Wh;a.Pg=this.Pg;return a};t=Rd.prototype;
t.Xa=function(a){if(!(a instanceof Rd)||this.type!==a.type||this.isClosed!==a.isClosed)return!1;switch(this.type){case Ad:case pd:return K.B(this.endX,a.endX)&&K.B(this.endY,a.endY);case Bd:return K.B(this.endX,a.endX)&&K.B(this.endY,a.endY)&&K.B(this.point1X,a.point1X)&&K.B(this.point1Y,a.point1Y)&&K.B(this.point2X,a.point2X)&&K.B(this.point2Y,a.point2Y);case Cd:return K.B(this.endX,a.endX)&&K.B(this.endY,a.endY)&&K.B(this.point1X,a.point1X)&&K.B(this.point1Y,a.point1Y);case Dd:return K.B(this.startAngle,
a.startAngle)&&K.B(this.sweepAngle,a.sweepAngle)&&K.B(this.centerX,a.centerX)&&K.B(this.centerY,a.centerY)&&K.B(this.radiusX,a.radiusX)&&K.B(this.radiusY,a.radiusY);case Jd:return this.isClockwiseArc===a.isClockwiseArc&&this.isLargeArc===a.isLargeArc&&K.B(this.xAxisRotation,a.xAxisRotation)&&K.B(this.endX,a.endX)&&K.B(this.endY,a.endY)&&K.B(this.radiusX,a.radiusX)&&K.B(this.radiusY,a.radiusY);default:return!1}};t.qb=function(a){a.classType===Rd&&(this.type=a)};
t.toString=function(a){void 0===a&&(a=-1);switch(this.type){case Ad:a=0>a?"M"+this.endX.toString()+" "+this.endY.toString():"M"+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case pd:a=0>a?"L"+this.endX.toString()+" "+this.endY.toString():"L"+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case Bd:a=0>a?"C"+this.point1X.toString()+" "+this.point1Y.toString()+" "+this.point2X.toString()+" "+this.point2Y.toString()+" "+this.endX.toString()+" "+this.endY.toString():"C"+this.point1X.toFixed(a)+
" "+this.point1Y.toFixed(a)+" "+this.point2X.toFixed(a)+" "+this.point2Y.toFixed(a)+" "+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case Cd:a=0>a?"Q"+this.point1X.toString()+" "+this.point1Y.toString()+" "+this.endX.toString()+" "+this.endY.toString():"Q"+this.point1X.toFixed(a)+" "+this.point1Y.toFixed(a)+" "+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case Dd:a=0>a?"B"+this.startAngle.toString()+" "+this.sweepAngle.toString()+" "+this.centerX.toString()+" "+this.centerY.toString()+
" "+this.radiusX.toString()+" "+this.radiusY.toString():"B"+this.startAngle.toFixed(a)+" "+this.sweepAngle.toFixed(a)+" "+this.centerX.toFixed(a)+" "+this.centerY.toFixed(a)+" "+this.radiusX.toFixed(a)+" "+this.radiusY.toFixed(a);break;case Jd:a=0>a?"A"+this.radiusX.toString()+" "+this.radiusY.toString()+" "+this.xAxisRotation.toString()+" "+(this.isLargeArc?1:0)+" "+(this.isClockwiseArc?1:0)+" "+this.endX.toString()+" "+this.endY.toString():"A"+this.radiusX.toFixed(a)+" "+this.radiusY.toFixed(a)+
" "+this.xAxisRotation.toFixed(a)+" "+(this.isLargeArc?1:0)+" "+(this.isClockwiseArc?1:0)+" "+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;default:a=this.type.toString()}return a+(this.isClosed?"z":"")};t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){this._isFrozen=!1;return this};t.close=function(){this.isClosed=!0;return this};
function Kd(a,b){if(null!==a.ae&&!1===b._isInvalidBounds)return a.ae;var c=a.radiusX,d=a.radiusY;void 0===d&&(d=c);if(0===c||0===d)return a.ae=[],a.ae;b=a.Ne;var e=a.Vh,f=K.kx(0,0,c<d?c:d,a.startAngle,a.startAngle+a.sweepAngle,!1);if(c!==d){var g=ld.alloc();g.reset();c<d?g.scale(1,d/c):g.scale(c/d,1);P.Ha(f,g);ld.free(g)}c=f.length;for(d=0;d<c;d++)g=f[d],g[0]+=b,g[1]+=e,g[2]+=b,g[3]+=e,g[4]+=b,g[5]+=e,g[6]+=b,g[7]+=e;a.ae=f;return a.ae}
function Ld(a,b,c,d){function e(a,b,c,d){return(a*d<b*c?-1:1)*Math.acos((a*c+b*d)/(Math.sqrt(a*a+b*b)*Math.sqrt(c*c+d*d)))}if(null!==a.ae&&!1===b._isInvalidBounds)return a.ae;if(0===a.radiusX||0===a.radiusY)return a.ae=[],a.ae;b=a.Wh;var f=a.Pg;0===b&&(b=1E-4);0===f&&(f=1E-4);var g=Math.PI/180*a.Ne,h=a.isLargeArc,k=a.isClockwiseArc,l=a.Dc,m=a.Ec,n=Math.cos(g),p=Math.sin(g),q=n*(c-l)/2+p*(d-m)/2;g=-p*(c-l)/2+n*(d-m)/2;var r=q*q/(b*b)+g*g/(f*f);1<r&&(b*=Math.sqrt(r),f*=Math.sqrt(r));r=(h===k?-1:1)*
Math.sqrt((b*b*f*f-b*b*g*g-f*f*q*q)/(b*b*g*g+f*f*q*q));isNaN(r)&&(r=0);h=r*b*g/f;r=r*-f*q/b;isNaN(h)&&(h=0);isNaN(r)&&(r=0);c=(c+l)/2+n*h-p*r;d=(d+m)/2+p*h+n*r;m=e(1,0,(q-h)/b,(g-r)/f);n=(q-h)/b;l=(g-r)/f;q=(-q-h)/b;h=(-g-r)/f;g=e(n,l,q,h);q=(n*q+l*h)/(Math.sqrt(n*n+l*l)*Math.sqrt(q*q+h*h));-1>=q?g=Math.PI:1<=q&&(g=0);!k&&0<g&&(g-=2*Math.PI);k&&0>g&&(g+=2*Math.PI);k=b>f?1:b/f;q=b>f?f/b:1;b=K.kx(0,0,b>f?b:f,m,m+g,!0);f=ld.alloc();f.reset();f.translate(c,d);f.rotate(a.Ne,0,0);f.scale(k,q);P.Ha(b,f);
ld.free(f);a.ae=b;return a.ae}
ma.Object.defineProperties(Rd.prototype,{_isFrozen:{get:function(){return 0!==(this.l&1)},set:function(a){this.l=a?this.l|1:this.l&-2}},_isInvalidBounds:{get:function(){return 0!==(this.l&2)},set:function(a){this.l=a?this.l|2:this.l&-3}},isClosed:{get:function(){return 0!==(this.l&8)},set:function(a){this.isClosed!==a&&(this.l=a?this.l|8:this.l&-9,this._isInvalidBounds=!0)}},type:{
get:function(){return this.na},set:function(a){this._isFrozen&&va(this,a);this.na=a;this._isInvalidBounds=!0}},endX:{get:function(){return this.Dc},set:function(a){this._isFrozen&&va(this,a);this.Dc=a;this._isInvalidBounds=!0}},endY:{get:function(){return this.Ec},set:function(a){this._isFrozen&&va(this,a);this.Ec=a;this._isInvalidBounds=!0}},point1X:{get:function(){return this.Ne},set:function(a){this._isFrozen&&
va(this,a);this.Ne=a;this._isInvalidBounds=!0}},point1Y:{get:function(){return this.Vh},set:function(a){this._isFrozen&&va(this,a);this.Vh=a;this._isInvalidBounds=!0}},point2X:{get:function(){return this.Wh},set:function(a){this._isFrozen&&va(this,a);this.Wh=a;this._isInvalidBounds=!0}},point2Y:{get:function(){return this.Pg},set:function(a){this._isFrozen&&va(this,a);this.Pg=a;this._isInvalidBounds=!0}},centerX:{
get:function(){return this.Ne},set:function(a){this._isFrozen&&va(this,a);this.Ne=a;this._isInvalidBounds=!0}},centerY:{get:function(){return this.Vh},set:function(a){this._isFrozen&&va(this,a);this.Vh=a;this._isInvalidBounds=!0}},radiusX:{get:function(){return this.Wh},set:function(a){0>a&&xa(a,">= zero",Rd,"radiusX");this._isFrozen&&va(this,a);this.Wh=a;this._isInvalidBounds=!0}},radiusY:{get:function(){return this.Pg},
set:function(a){0>a&&xa(a,">= zero",Rd,"radiusY");this._isFrozen&&va(this,a);this.Pg=a;this._isInvalidBounds=!0}},startAngle:{get:function(){return this.Dc},set:function(a){this._isFrozen&&va(this,a);a%=360;0>a&&(a+=360);this.Dc=a;this._isInvalidBounds=!0}},sweepAngle:{get:function(){return this.Ec},set:function(a){this._isFrozen&&va(this,a);360<a&&(a=360);-360>a&&(a=-360);this.Ec=a;this._isInvalidBounds=!0}},isClockwiseArc:{
get:function(){return 0!==(this.l&4)},set:function(a){this._isFrozen&&va(this,a);this.l=a?this.l|4:this.l&-5;this._isInvalidBounds=!0}},isLargeArc:{get:function(){return 0!==(this.l&16)},set:function(a){this._isFrozen&&va(this,a);this.l=a?this.l|16:this.l&-17;this._isInvalidBounds=!0}},xAxisRotation:{get:function(){return this.Ne},set:function(a){this._isFrozen&&va(this,a);a%=360;0>a&&(a+=360);this.Ne=a;this._isInvalidBounds=
!0}}});Rd.prototype.equalsApprox=Rd.prototype.Xa;var Ad=new D(Rd,"Move",0),pd=new D(Rd,"Line",1),Bd=new D(Rd,"Bezier",2),Cd=new D(Rd,"QuadraticBezier",3),Dd=new D(Rd,"Arc",4),Jd=new D(Rd,"SvgArc",4);Rd.className="PathSegment";Rd.Move=Ad;Rd.Line=pd;Rd.Bezier=Bd;Rd.QuadraticBezier=Cd;Rd.Arc=Dd;Rd.SvgArc=Jd;
function Td(){this.H=null;this.xu=(new I(0,0)).freeze();this.mt=(new I(0,0)).freeze();this.Bp=this.Rq=0;this.Cp=1;this.xq="";this.Wr=this.Tp=!1;this.Rp=this.Ep=0;this.xg=this.nq=this.tq=!1;this.Zp=null;this.Rr=0;this.xd=this.Nr=null}Td.prototype.copy=function(){var a=new Td;return this.clone(a)};
Td.prototype.clone=function(a){a.H=this.H;a.xu.assign(this.viewPoint);a.mt.assign(this.documentPoint);a.Rq=this.Rq;a.Bp=this.Bp;a.Cp=this.Cp;a.xq=this.xq;a.Tp=this.Tp;a.Wr=this.Wr;a.Ep=this.Ep;a.Rp=this.Rp;a.tq=this.tq;a.nq=this.nq;a.xg=this.xg;a.Zp=this.Zp;a.Rr=this.Rr;a.Nr=this.Nr;a.xd=this.xd;return a};
Td.prototype.toString=function(){var a="^";0!==this.modifiers&&(a+="M:"+this.modifiers);0!==this.button&&(a+="B:"+this.button);""!==this.key&&(a+="K:"+this.key);0!==this.clickCount&&(a+="C:"+this.clickCount);0!==this.delta&&(a+="D:"+this.delta);this.handled&&(a+="h");this.bubbles&&(a+="b");null!==this.documentPoint&&(a+="@"+this.documentPoint.toString());return a};Td.prototype.So=function(a,b){var c=this.diagram;if(null===c)return b;Ud(c,a,b);return b};
Td.prototype.Cz=function(a,b){var c=this.diagram;if(null===c)return b;Ud(c,a,b);b.assign(c.Ps(b));return b};
ma.Object.defineProperties(Td.prototype,{diagram:{get:function(){return this.H},set:function(a){this.H=a}},viewPoint:{get:function(){return this.xu},set:function(a){this.xu.assign(a)}},documentPoint:{get:function(){return this.mt},set:function(a){this.mt.assign(a)}},modifiers:{get:function(){return this.Rq},set:function(a){this.Rq=a}},button:{get:function(){return this.Bp},
set:function(a){this.Bp=a;if(null===this.event)switch(a){case 0:this.buttons=1;break;case 1:this.buttons=4;break;case 2:this.buttons=2}}},buttons:{get:function(){return this.Cp},set:function(a){this.Cp=a}},key:{get:function(){return this.xq},set:function(a){this.xq=a}},down:{get:function(){return this.Tp},set:function(a){this.Tp=a}},up:{get:function(){return this.Wr},set:function(a){this.Wr=
a}},clickCount:{get:function(){return this.Ep},set:function(a){this.Ep=a}},delta:{get:function(){return this.Rp},set:function(a){this.Rp=a}},isMultiTouch:{get:function(){return this.tq},set:function(a){this.tq=a}},handled:{get:function(){return this.nq},set:function(a){this.nq=a}},bubbles:{get:function(){return this.xg},set:function(a){this.xg=a}},event:{
get:function(){return this.Zp},set:function(a){this.Zp=a}},isTouchEvent:{get:function(){var a=this.event;return null===a?!1:"touch"===a.pointerType||"pen"===a.pointerType}},timestamp:{get:function(){return this.Rr},set:function(a){this.Rr=a}},targetDiagram:{get:function(){return this.Nr},set:function(a){this.Nr=a}},targetObject:{get:function(){return this.xd},set:function(a){this.xd=
a}},control:{get:function(){return 0!==(this.modifiers&1)},set:function(a){this.modifiers=a?this.modifiers|1:this.modifiers&-2}},shift:{get:function(){return 0!==(this.modifiers&4)},set:function(a){this.modifiers=a?this.modifiers|4:this.modifiers&-5}},alt:{get:function(){return 0!==(this.modifiers&2)},set:function(a){this.modifiers=a?this.modifiers|2:this.modifiers&-3}},meta:{get:function(){return 0!==
(this.modifiers&8)},set:function(a){this.modifiers=a?this.modifiers|8:this.modifiers&-9}},left:{get:function(){var a=this.event;return null===a||"mousedown"!==a.type&&"mouseup"!==a.type&&"pointerdown"!==a.type&&"pointerup"!==a.type?0!==(this.buttons&1):0===this.button},set:function(a){this.buttons=a?this.buttons|1:this.buttons&-2}},right:{get:function(){var a=this.event;return null===a||"mousedown"!==a.type&&"mouseup"!==a.type&&"pointerdown"!==
a.type&&"pointerup"!==a.type?0!==(this.buttons&2):2===this.button},set:function(a){this.buttons=a?this.buttons|2:this.buttons&-3}},middle:{get:function(){var a=this.event;return null===a||"mousedown"!==a.type&&"mouseup"!==a.type&&"pointerdown"!==a.type&&"pointerup"!==a.type?0!==(this.buttons&4):1===this.button},set:function(a){this.buttons=a?this.buttons|4:this.buttons&-5}}});Td.prototype.getMultiTouchDocumentPoint=Td.prototype.Cz;
Td.prototype.getMultiTouchViewPoint=Td.prototype.So;Td.className="InputEvent";function Vd(){this.H=null;this.xa="";this.ar=this.Lr=null}Vd.prototype.copy=function(){var a=new Vd;a.H=this.H;a.xa=this.xa;a.Lr=this.Lr;a.ar=this.ar;return a};Vd.prototype.toString=function(){var a="*"+this.name;null!==this.subject&&(a+=":"+this.subject.toString());null!==this.parameter&&(a+="("+this.parameter.toString()+")");return a};
ma.Object.defineProperties(Vd.prototype,{diagram:{get:function(){return this.H},set:function(a){this.H=a}},name:{get:function(){return this.xa},set:function(a){this.xa=a}},subject:{get:function(){return this.Lr},set:function(a){this.Lr=a}},parameter:{get:function(){return this.ar},set:function(a){this.ar=a}}});Vd.className="DiagramEvent";
function ce(){this.Fm=de;this.rf=this.Qq="";this.Ln=this.Mn=this.Rn=this.Sn=this.Qn=this.H=this.dc=null}ce.prototype.clear=function(){this.Ln=this.Mn=this.Rn=this.Sn=this.Qn=this.H=this.dc=null};
ce.prototype.copy=function(){var a=new ce;a.Fm=this.Fm;a.Qq=this.Qq;a.rf=this.rf;a.dc=this.dc;a.H=this.H;a.Qn=this.Qn;var b=this.Sn;a.Sn=za(b)&&"function"===typeof b.J?b.J():b;b=this.Rn;a.Rn=za(b)&&"function"===typeof b.J?b.J():b;b=this.Mn;a.Mn=za(b)&&"function"===typeof b.J?b.J():b;b=this.Ln;a.Ln=za(b)&&"function"===typeof b.J?b.J():b;return a};ce.prototype.qb=function(a){a.classType===ce&&(this.change=a)};
ce.prototype.toString=function(){var a="";a=this.change===ee?a+"* ":this.change===de?a+(null!==this.model?"!m":"!d"):a+((null!==this.model?"!m":"!d")+this.change);this.propertyName&&"string"===typeof this.propertyName&&(a+=" "+this.propertyName);this.modelChange&&this.modelChange!==this.propertyName&&(a+=" "+this.modelChange);a+=": ";this.change===ee?null!==this.oldValue&&(a+=" "+this.oldValue):(null!==this.object&&(a+=Ia(this.object)),null!==this.oldValue&&(a+="  old: "+Ia(this.oldValue)),null!==
this.oldParam&&(a+=" "+this.oldParam),null!==this.newValue&&(a+="  new: "+Ia(this.newValue)),null!==this.newParam&&(a+=" "+this.newParam));return a};ce.prototype.I=function(a){return a?this.oldValue:this.newValue};ce.prototype.Ez=function(a){return a?this.oldParam:this.newParam};ce.prototype.canUndo=function(){return null!==this.model||null!==this.diagram?!0:!1};
ce.prototype.undo=function(){this.canUndo()&&(null!==this.model?this.model.changeState(this,!0):null!==this.diagram&&this.diagram.changeState(this,!0))};ce.prototype.canRedo=function(){return null!==this.model||null!==this.diagram?!0:!1};ce.prototype.redo=function(){this.canRedo()&&(null!==this.model?this.model.changeState(this,!1):null!==this.diagram&&this.diagram.changeState(this,!1))};
ma.Object.defineProperties(ce.prototype,{model:{get:function(){return this.dc},set:function(a){this.dc=a}},diagram:{get:function(){return this.H},set:function(a){this.H=a}},change:{get:function(){return this.Fm},set:function(a){this.Fm=a}},modelChange:{get:function(){return this.Qq},set:function(a){this.Qq=a}},propertyName:{get:function(){return this.rf},
set:function(a){this.rf=a}},isTransactionFinished:{get:function(){return this.Fm===ee&&("CommittedTransaction"===this.rf||"FinishedUndo"===this.rf||"FinishedRedo"===this.rf)}},object:{get:function(){return this.Qn},set:function(a){this.Qn=a}},oldValue:{get:function(){return this.Sn},set:function(a){this.Sn=a}},oldParam:{get:function(){return this.Rn},set:function(a){this.Rn=a}},
newValue:{get:function(){return this.Mn},set:function(a){this.Mn=a}},newParam:{get:function(){return this.Ln},set:function(a){this.Ln=a}}});ce.prototype.redo=ce.prototype.redo;ce.prototype.canRedo=ce.prototype.canRedo;ce.prototype.undo=ce.prototype.undo;ce.prototype.canUndo=ce.prototype.canUndo;ce.prototype.getParam=ce.prototype.Ez;ce.prototype.getValue=ce.prototype.I;ce.prototype.clear=ce.prototype.clear;
var ee=new D(ce,"Transaction",-1),de=new D(ce,"Property",0),fe=new D(ce,"Insert",1),ge=new D(ce,"Remove",2);ce.className="ChangedEvent";ce.Transaction=ee;ce.Property=de;ce.Insert=fe;ce.Remove=ge;function he(){this.o=(new F).freeze();this.xa="";this.j=!1}he.prototype.toString=function(a){var b="Transaction: "+this.name+" "+this.changes.count.toString()+(this.isComplete?"":", incomplete");if(void 0!==a&&0<a){a=this.changes.count;for(var c=0;c<a;c++){var d=this.changes.M(c);null!==d&&(b+="\n  "+d.toString())}}return b};
he.prototype.clear=function(){var a=this.changes;a.ka();for(var b=a.count-1;0<=b;b--){var c=a.M(b);null!==c&&c.clear()}a.clear();a.freeze()};he.prototype.canUndo=function(){return this.isComplete};he.prototype.undo=function(){if(this.canUndo())for(var a=this.changes.count-1;0<=a;a--){var b=this.changes.M(a);null!==b&&b.undo()}};he.prototype.canRedo=function(){return this.isComplete};
he.prototype.redo=function(){if(this.canRedo())for(var a=this.changes.count,b=0;b<a;b++){var c=this.changes.M(b);null!==c&&c.redo()}};
he.prototype.$j=function(){if(this.isComplete){var a=this.changes;a.ka();for(var b=new ob,c=0;c<a.count;c++){var d=a.M(c);if(null!==d&&d.change===de&&d.object){var e=b.get(d.object);e||(e=new ob,b.set(d.object,e));var f=e.get(d.propertyName);null===f?e.set(d.propertyName,-1):(0<f&&a.cd(f,null),e.set(d.propertyName,c))}}for(c=b=0;c<a.count;c++)d=a.M(c),null!==d&&(c>b&&a.cd(b,d),b++);for(;a.length>b;)a.pop();a.freeze()}};
ma.Object.defineProperties(he.prototype,{changes:{get:function(){return this.o}},name:{get:function(){return this.xa},set:function(a){this.xa=a}},isComplete:{get:function(){return this.j},set:function(a){this.j=a}}});he.prototype.optimize=he.prototype.$j;he.prototype.redo=he.prototype.redo;he.prototype.canRedo=he.prototype.canRedo;he.prototype.undo=he.prototype.undo;he.prototype.canUndo=he.prototype.canUndo;
he.prototype.clear=he.prototype.clear;he.className="Transaction";function ie(){this.Pt=new H;this.Jd=!1;this.u=(new F).freeze();this.Ce=-1;this.o=999;this.Fe=!1;this.Np=null;this.bi=0;this.j=!1;this.Ie=(new F).freeze();this.gl=new F;this.tt=!0;this.Dt=this.rq=this.Gt=this.Ft=!1}
ie.prototype.toString=function(a){var b="UndoManager "+this.historyIndex+"<"+this.history.count+"<="+this.maxHistoryLength;b+="[";for(var c=this.nestedTransactionNames.count,d=0;d<c;d++)0<d&&(b+=" "),b+=this.nestedTransactionNames.M(d);b+="]";if(void 0!==a&&0<a)for(c=this.history.count,d=0;d<c;d++)b+="\n "+this.history.M(d).toString(a-1);return b};
ie.prototype.clear=function(){var a=this.history;a.ka();for(var b=a.count-1;0<=b;b--){var c=a.M(b);null!==c&&c.clear()}a.clear();this.Ce=-1;a.freeze();this.Fe=!1;this.Np=null;this.bi=0;this.Ie.ka();this.Ie.clear();this.Ie.freeze();this.gl.clear();this.Dt=this.rq=this.Gt=this.Ft=!1};ie.prototype.copyProperties=function(a){this.isEnabled=a.isEnabled;this.maxHistoryLength=a.maxHistoryLength;this.checksTransactionLevel=a.checksTransactionLevel};t=ie.prototype;t.Yw=function(a){this.Pt.add(a)};t.Sx=function(a){this.Pt.remove(a)};
t.Ea=function(a){void 0===a&&(a="");null===a&&(a="");if(this.isUndoingRedoing)return!1;!0===this.tt&&(this.tt=!1,this.bi++,this.isInternalTransaction||this.Pb("StartingFirstTransaction",a,this.currentTransaction),0<this.bi&&this.bi--);this.isEnabled&&(this.Ie.ka(),this.Ie.add(a),this.Ie.freeze(),null===this.currentTransaction?this.gl.add(0):this.gl.add(this.currentTransaction.changes.count));this.bi++;var b=1===this.transactionLevel;b&&(this.isInternalTransaction||this.Pb("StartedTransaction",a,this.currentTransaction));
return b};t.eb=function(a){void 0===a&&(a="");return je(this,!0,a)};t.Kf=function(){return je(this,!1,"")};
function je(a,b,c){if(a.isUndoingRedoing)return!1;a.checksTransactionLevel&&1>a.transactionLevel&&ya("Ending transaction without having started a transaction: "+c);var d=1===a.transactionLevel,e=a.currentTransaction;d&&b&&(a.isInternalTransaction||a.Pb("CommittingTransaction",c,e));var f=0;if(0<a.transactionLevel&&(a.bi--,a.isEnabled)){var g=a.Ie.count;0<g&&(""===c&&(c=a.Ie.M(0)),a.Ie.ka(),a.Ie.mb(g-1),a.Ie.freeze());g=a.gl.count;0<g&&(f=a.gl.M(g-1),a.gl.mb(g-1))}if(d){if(b)a.rq=!1,null===e&&""!==
c&&(e=a.currentTransaction),a.isEnabled&&null!==e&&(e.isComplete||(e.isComplete=!0,e.name=c),a.mx(),b=a.history,b.ka(),d=a.maxHistoryLength,0<=d&&(0===d?b.clear():b.count>=d&&(f=b.M(0),null!==f&&f.clear(),b.mb(0),a.Ce--)),0===d||0!==b.count&&b.get(b.count-1)===e||(b.add(e),a.Ce++),b.freeze()),a.isInternalTransaction||a.Pb("CommittedTransaction",c,e);else{a.Fe=!0;try{a.isEnabled&&null!==e&&(e.isComplete=!0,e.undo())}finally{a.isInternalTransaction||a.Pb("RolledBackTransaction",c,e),a.Fe=!1}null!==
e&&e.clear()}a.Np=null;a.isPendingClear&&a.clear();a.isPendingClear=!1;a.isPendingUnmodified=!1;return!0}if(a.isEnabled&&!b&&null!==e){a=f;c=e.changes;for(e=c.count-1;e>=a;e--)b=c.M(e),null!==b&&b.undo(),c.ka(),c.mb(e);c.freeze()}return!1}t.mx=function(){if(!this.isUndoingRedoing&&this.canRedo()){var a=this.history;a.ka();for(var b=a.count-1;b>this.historyIndex;b--){var c=a.M(b);null!==c&&c.clear();a.mb(b);this.rq=!0}a.freeze()}};
ie.prototype.canUndo=function(){if(!this.isEnabled||0<this.transactionLevel)return!1;var a=this.transactionToUndo;return null!==a&&a.canUndo()?!0:!1};ie.prototype.undo=function(){if(this.canUndo()){var a=this.transactionToUndo;try{this.Fe=!0,this.Pb("StartingUndo","Undo",a),this.Ce--,a.undo()}catch(b){ya("undo error: "+b.toString())}finally{this.Pb("FinishedUndo","Undo",a),this.Fe=!1}}};
ie.prototype.canRedo=function(){if(!this.isEnabled||0<this.transactionLevel)return!1;var a=this.transactionToRedo;return null!==a&&a.canRedo()?!0:!1};ie.prototype.redo=function(){if(this.canRedo()){var a=this.transactionToRedo;try{this.Fe=!0,this.Pb("StartingRedo","Redo",a),this.Ce++,a.redo()}catch(b){ya("redo error: "+b.toString())}finally{this.Pb("FinishedRedo","Redo",a),this.Fe=!1}}};
ie.prototype.Pb=function(a,b,c){void 0===c&&(c=null);var d=new ce;d.change=ee;d.propertyName=a;d.object=c;d.oldValue=b;for(a=this.models;a.next();)b=a.value,d.model=b,b.ds(d)};
ie.prototype.Uu=function(a){if(this.isEnabled&&!this.isUndoingRedoing&&!this.skipsEvent(a)){var b=this.currentTransaction;null===b&&(this.Np=b=new he);var c=a.copy();b=b.changes;b.ka();b.add(c);b.freeze();this.checksTransactionLevel&&0>=this.transactionLevel&&!this.tt&&(a=a.diagram,null!==a&&!1===a.pi||ya("Change not within a transaction: "+c.toString()))}};
ie.prototype.skipsEvent=function(a){if(null===a||0>a.change.value)return!0;a=a.object;if(null===a)return!1;if(void 0!==a.layer){if(a=a.layer,null!==a&&a.isTemporary)return!0}else if(a.isTemporary)return!0;return!1};
ma.Object.defineProperties(ie.prototype,{models:{get:function(){return this.Pt.iterator}},isEnabled:{get:function(){return this.Jd},set:function(a){this.Jd=a}},transactionToUndo:{get:function(){return 0<=this.historyIndex&&this.historyIndex<=this.history.count-1?this.history.M(this.historyIndex):null}},transactionToRedo:{get:function(){return this.historyIndex<this.history.count-
1?this.history.M(this.historyIndex+1):null}},isUndoingRedoing:{get:function(){return this.Fe}},history:{get:function(){return this.u}},maxHistoryLength:{get:function(){return this.o},set:function(a){this.o=a}},historyIndex:{get:function(){return this.Ce}},currentTransaction:{get:function(){return this.Np}},transactionLevel:{
get:function(){return this.bi}},isInTransaction:{get:function(){return 0<this.bi}},checksTransactionLevel:{get:function(){return this.j},set:function(a){this.j=a}},nestedTransactionNames:{get:function(){return this.Ie}},isPendingClear:{get:function(){return this.Ft},set:function(a){this.Ft=a}},isPendingUnmodified:{get:function(){return this.Gt},set:function(a){this.Gt=
a}},isInternalTransaction:{get:function(){return this.Dt},set:function(a){this.Dt=a}},isJustDiscarded:{get:function(){return this.rq}}});ie.prototype.handleChanged=ie.prototype.Uu;ie.prototype.redo=ie.prototype.redo;ie.prototype.undo=ie.prototype.undo;ie.prototype.canUndo=ie.prototype.canUndo;ie.prototype.discardHistoryAfterIndex=ie.prototype.mx;ie.prototype.rollbackTransaction=ie.prototype.Kf;ie.prototype.commitTransaction=ie.prototype.eb;
ie.prototype.startTransaction=ie.prototype.Ea;ie.prototype.removeModel=ie.prototype.Sx;ie.prototype.addModel=ie.prototype.Yw;ie.prototype.clear=ie.prototype.clear;ie.className="UndoManager";function ke(){Qa(this);this.H=le;this.xa="";this.Jd=!0;this.nb=!1;this.wm=null;this.zy=new Td;this.Zr=-1}ke.prototype.toString=function(){return""!==this.name?this.name+" Tool":Ha(this.constructor)};ke.prototype.updateAdornments=function(){};ke.prototype.canStart=function(){return this.isEnabled};
ke.prototype.doStart=function(){};ke.prototype.doActivate=function(){this.isActive=!0};ke.prototype.doDeactivate=function(){this.isActive=!1};ke.prototype.doStop=function(){};ke.prototype.doCancel=function(){this.transactionResult=null;this.stopTool()};ke.prototype.stopTool=function(){var a=this.diagram;a.currentTool===this&&(a.currentTool=null,a.currentCursor="")};ke.prototype.doMouseDown=function(){!this.isActive&&this.canStart()&&this.doActivate()};ke.prototype.doMouseMove=function(){};
ke.prototype.doMouseUp=function(){this.stopTool()};ke.prototype.doMouseWheel=function(){};ke.prototype.canStartMultiTouch=function(){return!0};ke.prototype.standardPinchZoomStart=function(){var a=this.diagram,b=a.lastInput,c=b.So(0,I.allocAt(NaN,NaN)),d=b.So(1,I.allocAt(NaN,NaN));if(c.w()&&d.w()&&(this.doCancel(),a.Ql("hasGestureZoom"))){a.Cw=a.scale;var e=d.x-c.x,f=d.y-c.y;a.Kw=Math.sqrt(e*e+f*f);b.bubbles=!1}I.free(c);I.free(d)};
ke.prototype.standardPinchZoomMove=function(){var a=this.diagram,b=a.lastInput,c=b.So(0,I.allocAt(NaN,NaN)),d=b.So(1,I.allocAt(NaN,NaN));if(c.w()&&d.w()&&(this.doCancel(),a.Ql("hasGestureZoom"))){var e=d.x-c.x,f=d.y-c.y;f=Math.sqrt(e*e+f*f)/a.Kw;e=new I((Math.min(d.x,c.x)+Math.max(d.x,c.x))/2,(Math.min(d.y,c.y)+Math.max(d.y,c.y))/2);f*=a.Cw;var g=a.commandHandler;if(f!==a.scale&&g.canResetZoom(f)){var h=a.zoomPoint;a.zoomPoint=e;g.resetZoom(f);a.zoomPoint=h}b.bubbles=!1}I.free(c);I.free(d)};
ke.prototype.doKeyDown=function(){"Esc"===this.diagram.lastInput.key&&this.doCancel()};ke.prototype.doKeyUp=function(){};ke.prototype.Ea=function(a){void 0===a&&(a=this.name);this.transactionResult=null;return this.diagram.Ea(a)};ke.prototype.stopTransaction=function(){var a=this.diagram;return null===this.transactionResult?a.Kf():a.eb(this.transactionResult)};
ke.prototype.standardMouseSelect=function(){var a=this.diagram;if(a.allowSelect){var b=a.lastInput,c=a.Ol(b.documentPoint,!1);if(null!==c)if(Pa?b.meta:b.control){a.S("ChangingSelection",a.selection);for(b=c;null!==b&&!b.canSelect();)b=b.containingGroup;null!==b&&(b.isSelected=!b.isSelected);a.S("ChangedSelection",a.selection)}else if(b.shift){if(!c.isSelected){a.S("ChangingSelection",a.selection);for(b=c;null!==b&&!b.canSelect();)b=b.containingGroup;null!==b&&(b.isSelected=!0);a.S("ChangedSelection",
a.selection)}}else{if(!c.isSelected){for(b=c;null!==b&&!b.canSelect();)b=b.containingGroup;null!==b&&a.select(b)}}else!b.left||(Pa?b.meta:b.control)||b.shift||a.clearSelection()}};ke.prototype.standardMouseClick=function(a,b){void 0===a&&(a=null);void 0===b&&(b=function(a){return!a.layer.isTemporary});var c=this.diagram,d=c.lastInput;a=c.jc(d.documentPoint,a,b);d.targetObject=a;me(a,d,c);return d.handled};
function me(a,b,c){b.handled=!1;if(null===a||a.If()){var d=0;b.left?d=1===b.clickCount?1:2===b.clickCount?2:1:b.right&&1===b.clickCount&&(d=3);var e="ObjectSingleClicked";if(null!==a){switch(d){case 1:e="ObjectSingleClicked";break;case 2:e="ObjectDoubleClicked";break;case 3:e="ObjectContextClicked"}0!==d&&c.S(e,a)}else{switch(d){case 1:e="BackgroundSingleClicked";break;case 2:e="BackgroundDoubleClicked";break;case 3:e="BackgroundContextClicked"}0!==d&&c.S(e)}if(null!==a)for(;null!==a;){c=null;switch(d){case 1:c=
a.click;break;case 2:c=a.doubleClick?a.doubleClick:a.click;break;case 3:c=a.contextClick}if(null!==c&&(c(b,a),b.handled))break;a=a.panel}else{a=null;switch(d){case 1:a=c.click;break;case 2:a=c.doubleClick?c.doubleClick:c.click;break;case 3:a=c.contextClick}null!==a&&a(b)}}}
ke.prototype.standardMouseOver=function(){var a=this.diagram,b=a.lastInput;if(!0!==a.animationManager.Ab){var c=a.skipsUndoManager;a.skipsUndoManager=!0;var d=a.viewportBounds.ca(b.documentPoint)?a.jc(b.documentPoint,null,null):null;!b.event||"pointercancel"!==b.event.type&&"pointerout"!==b.event.type||(d=null);b.targetObject=d;var e=!1;if(d!==a.Bk){var f=a.Bk,g=f;a.Bk=d;this.doCurrentObjectChanged(f,d);for(b.handled=!1;null!==f;){var h=f.mouseLeave;if(null!==h){if(d===f)break;if(null!==d&&d.rg(f))break;
h(b,f,d);e=!0;if(b.handled)break}f=f.panel}f=g;for(b.handled=!1;null!==d;){g=d.mouseEnter;if(null!==g){if(f===d)break;if(null!==f&&f.rg(d))break;g(b,d,f);e=!0;if(b.handled)break}d=d.panel}d=a.Bk}if(null!==d){f=d;for(g="";null!==f;){g=f.cursor;if(""!==g)break;f=f.panel}a.currentCursor=g;b.handled=!1;for(f=d;null!==f;){d=f.mouseOver;if(null!==d&&(d(b,f),e=!0,b.handled))break;f=f.panel}}else this.doUpdateCursor(null),d=a.mouseOver,null!==d&&(d(b),e=!0);e&&a.Rb();a.skipsUndoManager=c}};
ke.prototype.doUpdateCursor=function(){var a=this.diagram;a&&(a.currentCursor="")};ke.prototype.doCurrentObjectChanged=function(){};
ke.prototype.standardMouseWheel=function(){var a=this.diagram,b=a.lastInput,c=b.delta;if(0!==c&&a.documentBounds.w()){var d=a.commandHandler,e=a.toolManager.mouseWheelBehavior;if(null!==d&&(e===ne&&!b.shift||e===oe&&b.control)){if(0<c?d.canIncreaseZoom():d.canDecreaseZoom())e=a.zoomPoint,a.zoomPoint=b.viewPoint,0<c?d.increaseZoom():d.decreaseZoom(),a.zoomPoint=e;b.bubbles=!1}else if(e===ne&&b.shift||e===oe&&!b.control){d=a.position.copy();var f=b.event;e=f.deltaMode;var g=f.deltaX;f=f.deltaY;var h=
"pixel";if(0===g&&0===f||b.shift)!b.shift&&a.allowVerticalScroll?(c=3*c*a.scrollVerticalLineChange,a.scroll(h,0<c?"up":"down",Math.abs(c))):b.shift&&a.allowHorizontalScroll&&(c=3*c*a.scrollHorizontalLineChange,a.scroll(h,0<c?"left":"right",Math.abs(c)));else{switch(e){case 0:h="pixel";break;case 1:h="line";break;case 2:h="page"}0!==g&&a.allowHorizontalScroll&&(g*=a.scrollHorizontalLineChange/16,a.scroll(h,0<g?"right":"left",Math.abs(g)));0!==f&&a.allowVerticalScroll&&(f*=a.scrollVerticalLineChange/
16,a.scroll(h,0<f?"down":"up",Math.abs(f)))}this.doWheelChange(a,d,b)}}};ke.prototype.doWheelChange=function(a,b,c){a.position.D(b)&&0===c.event.wheelDeltaX||(c.bubbles=!1)};ke.prototype.standardWaitAfter=function(a,b){void 0===b&&(b=this.diagram.lastInput);this.cancelWaitAfter();var c=this,d=b.clone(this.zy);this.Zr=ta(function(){c.doWaitAfter(d)},a)};ke.prototype.cancelWaitAfter=function(){-1!==this.Zr&&x.clearTimeout(this.Zr);this.Zr=-1};ke.prototype.doWaitAfter=function(){};
ke.prototype.findToolHandleAt=function(a,b){a=this.diagram.jc(a,function(a){for(;null!==a&&!(a.panel instanceof pe);)a=a.panel;return a});return null===a?null:a.part.category===b?a:null};ke.prototype.isBeyondDragSize=function(a,b){var c=this.diagram;void 0===a&&(a=c.firstInput.viewPoint);void 0===b&&(b=c.lastInput.viewPoint);var d=c.toolManager.dragSize,e=d.width;d=d.height;c.firstInput.isTouchEvent&&(e+=6,d+=6);return Math.abs(b.x-a.x)>e||Math.abs(b.y-a.y)>d};
ma.Object.defineProperties(ke.prototype,{diagram:{get:function(){return this.H},set:function(a){a instanceof Q&&(this.H=a)}},name:{get:function(){return this.xa},set:function(a){this.xa=a}},isEnabled:{get:function(){return this.Jd},set:function(a){this.Jd=a}},isActive:{get:function(){return this.nb},set:function(a){this.nb=a}},transactionResult:{get:function(){return this.wm},
set:function(a){this.wm=a}}});ke.prototype.startTransaction=ke.prototype.Ea;ke.className="Tool";function La(){ke.call(this);this.name="ToolManager";this.hb=new F;this.ob=new F;this.Ib=new F;this.F=this.V=850;this.o=(new M(2,2)).ja();this.Ha=5E3;this.ia=oe;this.u=qe;this.Mp=this.j=null;this.zj=-1}la(La,ke);La.prototype.initializeStandardTools=function(){};
La.prototype.updateAdornments=function(a){var b=this.currentToolTip;if(b instanceof pe&&this.Mp===a){var c=b.adornedObject;(null!==a?c.part===a:null===c)?this.showToolTip(b,c):this.hideToolTip()}};
La.prototype.doMouseDown=function(){var a=this.diagram,b=a.lastInput;b.isTouchEvent&&this.gestureBehavior===ye&&(b.bubbles=!1);if(b.isMultiTouch){this.cancelWaitAfter();if(this.gestureBehavior===ze){b.bubbles=!0;return}if(this.gestureBehavior===ye)return;if(a.currentTool.canStartMultiTouch()){a.currentTool.standardPinchZoomStart();return}}for(var c=this.mouseDownTools.length,d=0;d<c;d++){var e=this.mouseDownTools.M(d);e.diagram=this.diagram;if(e.canStart()){a.doFocus();a.currentTool=e;a.currentTool===
e&&(e.isActive||e.doActivate(),e.doMouseDown());return}}1===a.lastInput.button&&(this.mouseWheelBehavior===oe?this.mouseWheelBehavior=ne:this.mouseWheelBehavior===ne&&(this.mouseWheelBehavior=oe));this.doActivate();this.standardWaitAfter(this.holdDelay,b)};
La.prototype.doMouseMove=function(){var a=this.diagram,b=a.lastInput;if(b.isMultiTouch){if(this.gestureBehavior===ze){b.bubbles=!0;return}if(this.gestureBehavior===ye)return;if(a.currentTool.canStartMultiTouch()){a.currentTool.standardPinchZoomMove();return}}if(this.isActive)for(var c=this.mouseMoveTools.length,d=0;d<c;d++){var e=this.mouseMoveTools.M(d);e.diagram=this.diagram;if(e.canStart()){a.doFocus();a.currentTool=e;a.currentTool===e&&(e.isActive||e.doActivate(),e.doMouseMove());return}}Ae(this,
a);a=b.event;null===a||"pointermove"!==a.type&&a.cancelable||(b.bubbles=!0)};function Ae(a,b){a.standardMouseOver();a.isBeyondDragSize()&&a.standardWaitAfter(a.isActive?a.holdDelay:a.hoverDelay,b.lastInput)}La.prototype.doCurrentObjectChanged=function(a,b){a=this.currentToolTip;null===a||null!==b&&a instanceof pe&&(b===a||b.rg(a))||this.hideToolTip()};
La.prototype.doWaitAfter=function(a){var b=this.diagram;null!==b.ta&&(this.doMouseHover(),this.isActive||this.doToolTip(),a.isTouchEvent&&!b.lastInput.handled&&(a=a.copy(),a.button=2,a.buttons=2,b.lastInput=a,b.qu=!0,b.doMouseUp()))};
La.prototype.doMouseHover=function(){var a=this.diagram,b=a.lastInput;null===b.targetObject&&(b.targetObject=a.jc(b.documentPoint,null,null));var c=b.targetObject;if(null!==c)for(b.handled=!1;null!==c;){a=this.isActive?c.mouseHold:c.mouseHover;if(null!==a&&(a(b,c),b.handled))break;c=c.panel}else c=this.isActive?a.mouseHold:a.mouseHover,null!==c&&c(b)};
La.prototype.doToolTip=function(){var a=this.diagram,b=a.lastInput;null===b.targetObject&&(b.targetObject=a.jc(b.documentPoint,null,null));b=b.targetObject;if(null!==b){if(a=this.currentToolTip,!(a instanceof pe)||b!==a&&!b.rg(a)){for(;null!==b;){a=b.toolTip;if(null!==a){this.showToolTip(a,b);return}b=b.panel}this.hideToolTip()}}else b=a.toolTip,null!==b?this.showToolTip(b,null):this.hideToolTip()};
La.prototype.showToolTip=function(a,b){var c=this.diagram;a!==this.currentToolTip&&this.hideToolTip();if(a instanceof pe){a.layerName="Tool";a.selectable=!1;a.scale=1/c.scale;a.category="ToolTip";null!==a.placeholder&&(a.placeholder.scale=c.scale);var d=a.diagram;null!==d&&d!==c&&d.remove(a);c.add(a);null!==b?a.adornedObject=b:a.data=c.model;a.Va();this.positionToolTip(a,b)}else a instanceof Be&&a!==this.currentToolTip&&a.show(b,c,this);this.currentToolTip=a;-1!==this.zj&&(x.clearTimeout(this.zj),
this.zj=-1);a=this.toolTipDuration;if(0<a&&Infinity!==a){var e=this;this.zj=ta(function(){e.hideToolTip()},a)}};La.prototype.positionToolTip=function(a){if(null===a.placeholder){var b=this.diagram,c=b.lastInput.documentPoint.copy(),d=a.measuredBounds,e=b.viewportBounds;b.lastInput.isTouchEvent&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5/b.scale);c.x<e.x&&(c.x=e.x);c.y=c.y+20/b.scale+d.height>e.bottom?c.y-(d.height+5/b.scale):c.y+20/b.scale;c.y<e.y&&(c.y=e.y);a.position=c}};
La.prototype.hideToolTip=function(){-1!==this.zj&&(x.clearTimeout(this.zj),this.zj=-1);var a=this.diagram,b=this.currentToolTip;null!==b&&(b instanceof pe?(a.remove(b),null!==this.Mp&&this.Mp.Jf(b.category),b.data=null,b.adornedObject=null):b instanceof Be&&null!==b.hide&&b.hide(a,this),this.currentToolTip=null)};
La.prototype.doMouseUp=function(){this.cancelWaitAfter();var a=this.diagram;if(this.isActive)for(var b=this.mouseUpTools.length,c=0;c<b;c++){var d=this.mouseUpTools.M(c);d.diagram=this.diagram;if(d.canStart()){a.doFocus();a.currentTool=d;a.currentTool===d&&(d.isActive||d.doActivate(),d.doMouseUp());return}}a.doFocus();this.doDeactivate()};La.prototype.doMouseWheel=function(){this.standardMouseWheel()};La.prototype.doKeyDown=function(){var a=this.diagram;null!==a.commandHandler&&a.commandHandler.doKeyDown()};
La.prototype.doKeyUp=function(){var a=this.diagram;null!==a.commandHandler&&a.commandHandler.doKeyUp()};La.prototype.findTool=function(a){for(var b=this.mouseDownTools.length,c=0;c<b;c++){var d=this.mouseDownTools.M(c);if(d.name===a)return d}b=this.mouseMoveTools.length;for(c=0;c<b;c++)if(d=this.mouseMoveTools.M(c),d.name===a)return d;b=this.mouseUpTools.length;for(c=0;c<b;c++)if(d=this.mouseUpTools.M(c),d.name===a)return d;return null};
La.prototype.replaceTool=function(a,b){null!==b&&(b.diagram=this.diagram);for(var c=this.mouseDownTools.length,d=0;d<c;d++){var e=this.mouseDownTools.M(d);if(e.name===a)return null!==b?this.mouseDownTools.cd(d,b):this.mouseDownTools.mb(d),e}c=this.mouseMoveTools.length;for(d=0;d<c;d++)if(e=this.mouseMoveTools.M(d),e.name===a)return null!==b?this.mouseMoveTools.cd(d,b):this.mouseMoveTools.mb(d),e;c=this.mouseUpTools.length;for(d=0;d<c;d++)if(e=this.mouseUpTools.M(d),e.name===a)return null!==b?this.mouseUpTools.cd(d,
b):this.mouseUpTools.mb(d),e;return null};La.prototype.gb=function(a,b,c){null!==b&&(b.name=a,b.diagram=this.diagram);this.findTool(a)?this.replaceTool(a,b):null!==b&&c.add(b)};
ma.Object.defineProperties(La.prototype,{mouseWheelBehavior:{get:function(){return this.ia},set:function(a){this.ia=a}},gestureBehavior:{get:function(){return this.u},set:function(a){this.u=a}},currentToolTip:{get:function(){return this.j},set:function(a){this.j=a;this.Mp=null!==a&&a instanceof pe?a.adornedPart:null}},mouseDownTools:{get:function(){return this.hb}},mouseMoveTools:{
get:function(){return this.ob}},mouseUpTools:{get:function(){return this.Ib}},hoverDelay:{get:function(){return this.V},set:function(a){this.V=a}},holdDelay:{get:function(){return this.F},set:function(a){this.F=a}},dragSize:{get:function(){return this.o},set:function(a){this.o=a.J()}},toolTipDuration:{get:function(){return this.Ha},set:function(a){this.Ha=
a}}});La.prototype.replaceStandardTool=La.prototype.gb;var oe=new D(La,"WheelScroll",0),ne=new D(La,"WheelZoom",1),Ce=new D(La,"WheelNone",2),qe=new D(La,"GestureZoom",3),ye=new D(La,"GestureCancel",4),ze=new D(La,"GestureNone",5);La.className="ToolManager";La.WheelScroll=oe;La.WheelZoom=ne;La.WheelNone=Ce;La.GestureZoom=qe;La.GestureCancel=ye;La.GestureNone=ze;
function De(){ke.call(this);this.name="Dragging";this.u=this.Tw=this.ob=!0;this.o=this.Ha=this.V=this.Oe=null;this.Ym=this.Ib=!1;this.tl=new I(NaN,NaN);this.Fr=new I;this.hb=!0;this.Hk=100;this.Cg=[];this.te=(new H).freeze();this.ia=new Ee;this.Bn=null;this.F="copy";this.Oc="";this.Pc="no-drop"}var Fe;la(De,ke);
De.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(a.isReadOnly&&!a.allowDragOut||!a.allowMove&&!a.allowCopy&&!a.allowDragOut||!a.allowSelect)return!1;var b=a.lastInput;return!b.left||a.currentTool!==this&&(!this.isBeyondDragSize()||b.isTouchEvent&&b.timestamp-a.firstInput.timestamp<this.Hk)?!1:null!==this.findDraggablePart()};
De.prototype.findDraggablePart=function(){var a=this.diagram;a=a.Ol(a.firstInput.documentPoint,!1);if(null===a)return null;for(;null!==a&&!a.canSelect();)a=a.containingGroup;return null!==a&&(a.canMove()||a.canCopy())?a:null};
De.prototype.standardMouseSelect=function(){var a=this.diagram;if(a.allowSelect){var b=a.Ol(a.firstInput.documentPoint,!1);if(null!==b){for(;null!==b&&!b.canSelect();)b=b.containingGroup;this.currentPart=b;null===this.currentPart||this.currentPart.isSelected||(a.S("ChangingSelection",a.selection),b=a.lastInput,(Pa?b.meta:b.control)||b.shift||a.clearSelection(!0),this.currentPart.isSelected=!0,a.S("ChangedSelection",a.selection))}}};
De.prototype.doActivate=function(){var a=this.diagram;this.Bn=null;null===this.currentPart&&this.standardMouseSelect();var b=this.currentPart;null!==b&&(b.canMove()||b.canCopy())&&(a.Gu(!0),Ge=null,this.isActive=!0,this.tl.set(a.position),He(this,a.selection),this.Cg.length=0,a.animationManager.Cc(),this.draggedParts=this.computeEffectiveCollection(a.selection,this.dragOptions),this.Tw=this.diagram.layout.isRealtime,this.diagram.layout.isRealtime=!1,a.Ns=!0,!0===a.Gf("temporaryPixelRatio")&&30<a.ax&&
Ie(a),Je(a,this.draggedParts),this.Ea("Drag"),this.startPoint=a.firstInput.documentPoint,a.isMouseCaptured=!0,a.allowDragOut&&(this.isDragOutStarted=!0,this.Ym=!1,Ge=this,Ke=this.diagram,this.doSimulatedDragOut()))};
function He(a,b){if(a.dragsLink){var c=a.diagram;c.allowRelink&&(c.model.Vj()&&1===b.count&&b.first()instanceof T?(a.draggedLink=b.first(),a.draggedLink.canRelinkFrom()&&a.draggedLink.canRelinkTo()&&a.draggedLink.Dj(),a.Oe=c.toolManager.findTool("Relinking"),null===a.Oe&&(a.Oe=new Le,a.Oe.diagram=c),a.Oe.originalLink=a.draggedLink):(a.draggedLink=null,a.Oe=null))}}De.prototype.computeEffectiveCollection=function(a,b){return this.diagram.commandHandler.computeEffectiveCollection(a,b)};
De.prototype.Pd=function(a){return void 0===a?new Me(pb):this.isGridSnapEnabled?new Me(new I(Math.round(1E3*a.x)/1E3,Math.round(1E3*a.y)/1E3)):new Me(a.copy())};
De.prototype.doDeactivate=function(){this.isActive=!1;var a=this.diagram;this.diagram.layout.isRealtime=this.Tw;a.Gu(!1);a.ug();Ne(this);Oe(a,this.draggedParts);this.draggedParts=this.currentPart=this.Bn=null;this.Ym=this.isDragOutStarted=!1;if(0<Fe.count){for(var b=Fe,c=b.length,d=0;d<c;d++){var e=b.M(d);Pe(e);Ye(e);Ne(e);e.diagram.ug()}b.clear()}Pe(this);this.tl.h(NaN,NaN);null!==Ke&&(Ke.currentCursor="");Ge=Ke=null;Ye(this);a.isMouseCaptured=!1;a.currentCursor="";a.Ns=!1;this.stopTransaction();
Ze(a,!0)};De.prototype.stopTransaction=function(){var a=this.diagram,b=ke.prototype.stopTransaction.call(this);b&&null!==a.undoManager.transactionToUndo&&a.undoManager.transactionToUndo.$j();return b};function Ne(a){var b=a.diagram,c=b.skipsUndoManager;b.skipsUndoManager=!0;$e(a,b.lastInput,null);b.skipsUndoManager=c;a.Cg.length=0}function af(){var a=Ge;Ye(a);bf(a);var b=a.diagram;a.tl.w()&&(b.position=a.tl);b.ug()}
De.prototype.doCancel=function(){Ye(this);bf(this);var a=this.diagram;this.tl.w()&&(a.position=this.tl);this.stopTool()};De.prototype.doKeyDown=function(){var a=this.diagram,b=a.lastInput;this.isActive&&("Esc"===b.key?this.doCancel():b.key!==a.previousInput.key&&this.doMouseMove())};De.prototype.doKeyUp=function(){this.isActive&&this.doMouseMove()};
function cf(a,b){if(null===a.copiedParts){var c=a.diagram;if((!b||!c.isReadOnly&&!c.isModelReadOnly)&&null!==a.draggedParts){var d=c.undoManager;d.isEnabled&&d.isInTransaction?null!==d.currentTransaction&&0<d.currentTransaction.changes.count&&(c.undoManager.Kf(),c.Ea("Drag")):bf(a);c.skipsUndoManager=!b;c.partManager.addsToTemporaryLayer=!b;a.startPoint=c.firstInput.documentPoint;b=a.copiesEffectiveCollection?a.draggedParts.Lf():c.selection;b=c.Jj(b,c,!0);c=new ob;for(d=a.draggedParts.iterator;d.next();){var e=
d.key;if(e.Zc()&&e.canCopy()){var f=b.I(e);null!==f&&(f.location=e.location,f.Va(),c.add(f,a.Pd(f.location)))}}for(b=b.iterator;b.next();)d=b.value,d instanceof T&&d.canCopy()&&c.add(d,a.Pd());a.copiedParts=c;He(a,c.Lf());null!==a.draggedLink&&(c=a.draggedLink,b=c.routeBounds,df(c,a.startPoint.x-(b.x+b.width/2),a.startPoint.y-(b.y+b.height/2)));a.doUpdateCursor(null)}}}
function Ye(a){var b=a.diagram;if(null!==a.copiedParts){b.Is(a.copiedParts.Lf(),!1);a.copiedParts=null;if(null!==a.draggedParts)for(var c=a.draggedParts.iterator;c.next();)c.key instanceof T&&(c.value.point=new I(0,0));a.doUpdateCursor(null)}b.skipsUndoManager=!1;b.partManager.addsToTemporaryLayer=!1;a.startPoint=b.firstInput.documentPoint}
function Pe(a){if(null!==a.draggedLink){if(a.dragsLink&&null!==a.Oe){var b=a.Oe;b.diagram.remove(b.temporaryFromNode);b.diagram.remove(b.temporaryToNode);a.Oe.originalLink=null}a.draggedLink=null;a.Oe=null}}function ef(a,b,c){var d=a.diagram,e=a.startPoint,f=I.alloc();f.assign(d.lastInput.documentPoint);a.moveParts(b,f.re(e),c);I.free(f);!0===d.Gf("temporaryPixelRatio")&&null===d.Yh&&30<d.ax&&(Ie(d),d.gk())}
De.prototype.moveParts=function(a,b,c){void 0===c&&(c=!1);var d=this.diagram;null!==d&&ff(d,a,b,this.dragOptions,c)};function bf(a){if(null!==a.draggedParts){for(var b=a.diagram,c=a.draggedParts.iterator;c.next();){var d=c.key;d.Zc()&&(d.location=c.value.point)}for(c=a.draggedParts.iterator;c.next();)if(d=c.key,d instanceof T&&d.suspendsRouting){var e=c.value.point;a.draggedParts.add(d,a.Pd());df(d,-e.x,-e.y)}b.bd()}}
function gf(a,b){var c=a.diagram;a.dragsLink&&(null!==a.draggedLink&&(a.draggedLink.fromNode=null,a.draggedLink.toNode=null),hf(a,!1));var d=a.findDragOverObject(b),e=c.lastInput;e.targetObject=d;a.doUpdateCursor(d);var f=c.skipsUndoManager,g=!1;try{c.skipsUndoManager=!0;g=$e(a,e,d);if(!a.isActive&&null===Ge)return;var h=null!==d?d.part:null;if(null===h||c.handlesDragDropForTopLevelParts&&h.isTopLevel&&!(h instanceof jf)){var k=c.mouseDragOver;null!==k&&(k(e),g=!0)}if(!a.isActive&&null===Ge)return;
a.doDragOver(b,d);if(!a.isActive&&null===Ge)return}finally{c.skipsUndoManager=f,g&&c.bd()}a.Bn=d;c.isReadOnly||!c.allowMove&&!c.allowCopy||!c.allowHorizontalScroll&&!c.allowVerticalScroll||c.gs(e.viewPoint)}De.prototype.findDragOverObject=function(a){var b=this;return qf(this.diagram,a,null,function(a){null===a?a=!0:(a=a.part,a=null===a||a instanceof pe||a.layer.isTemporary||b.draggedParts&&b.draggedParts.contains(a)||b.copiedParts&&b.copiedParts.contains(a)?!0:!1);return!a})};
De.prototype.doUpdateCursor=function(a){var b=this.diagram;if(null===a||this.Bn!==a)!this.diagram.currentTool.isActive||this.mayCopy()?b.currentCursor=this.copyCursor:this.mayMove()?b.currentCursor=this.moveCursor:this.mayDragOut()&&(b.currentCursor=this.nodropCursor)};
function $e(a,b,c){var d=!1,e=a.Cg.length,f=0<e?a.Cg[0]:null;if(c===f)return!1;b.handled=!1;for(var g=0;g<e;g++){var h=a.Cg[g],k=h.mouseDragLeave;if(null!==k&&(k(b,h,c),d=!0,b.handled))break}a.Cg.length=0;if(!a.isActive&&null===Ge||null===c)return d;b.handled=!1;for(e=c;null!==e;)a.Cg.push(e),e=rf(e);e=a.Cg.length;for(c=0;c<e&&(g=a.Cg[c],h=g.mouseDragEnter,null===h||(h(b,g,f),d=!0,!b.handled));c++);return d}
function rf(a){var b=a.panel;return null!==b?b:a instanceof U&&!(a instanceof jf)&&(a=a.containingGroup,null!==a&&a.handlesDragDropForMembers)?a:null}function sf(a,b,c){var d=a.Oe;if(null===d)return null;var e=a.diagram.og(b,d.portGravity,function(a){return d.findValidLinkablePort(a,c)});a=I.alloc();var f=Infinity,g=null;for(e=e.iterator;e.next();){var h=e.value;if(null!==h.part){var k=h.ga(wc,a);k=b.Bf(k);k<f&&(g=h,f=k)}}I.free(a);return g}
function hf(a,b){var c=a.draggedLink;if(null!==c&&!(2>c.pointsCount)){var d=a.diagram;if(!d.isReadOnly){var e=a.Oe;if(null!==e){var f=null,g=null;null===c.fromNode&&(f=sf(a,c.i(0),!1),null!==f&&(g=f.part));var h=null,k=null;null===c.toNode&&(h=sf(a,c.i(c.pointsCount-1),!0),null!==h&&(k=h.part));e.isValidLink(g,f,k,h)?b?(c.defaultFromPoint=c.i(0),c.defaultToPoint=c.i(c.pointsCount-1),c.suspendsRouting=!1,c.fromNode=g,null!==f&&(c.fromPortId=f.portId),c.toNode=k,null!==h&&(c.toPortId=h.portId),c.fromPort!==
d.Nx&&d.S("LinkRelinked",c,d.Nx),c.toPort!==d.Ox&&d.S("LinkRelinked",c,d.Ox)):tf(e,g,f,k,h):tf(e,null,null,null,null)}}}}De.prototype.doDragOver=function(){};
function uf(a,b){var c=a.diagram;a.dragsLink&&hf(a,!0);Ne(a);var d=a.findDragOverObject(b),e=c.lastInput;e.targetObject=d;if(null!==d){e.handled=!1;for(var f=d;null!==f;){var g=f.mouseDrop;if(null!==g&&(g(e,f),e.handled))break;vf(a,e,f);f=rf(f)}}else f=c.mouseDrop,null!==f&&f(e);if(a.isActive||null!==Ge){for(e=(a.copiedParts||a.draggedParts).iterator;e.next();)f=e.key,f instanceof V&&f.linksConnected.each(function(a){a.suspendsRouting=!1});a.doDropOnto(b,d);if(a.isActive||null!==Ge){a=N.alloc();for(b=
c.selection.iterator;b.next();)d=b.value,d instanceof V&&wf(c,d,a);N.free(a)}}}function vf(a,b,c){a=a.diagram;c=c.part;!a.handlesDragDropForTopLevelParts||!c.isTopLevel||c instanceof jf||(c=a.mouseDrop,null!==c&&c(b))}function wf(a,b,c){var d=!1;c=b.getAvoidableRect(c);a.viewportBounds.Re(c)&&(d=!0);a=a.Ou(c,function(a){return a.part},function(a){return a instanceof T},!0,function(a){return a instanceof T},d);if(0!==a.count)for(a=a.iterator;a.next();)c=a.value,!c.Td(b)&&c.isAvoiding&&c.bb()}
De.prototype.doDropOnto=function(){};De.prototype.doMouseMove=function(){if(this.isActive){var a=this.diagram,b=a.lastInput;this.simulatedMouseMove(b.event,b.documentPoint,b.targetDiagram)||null===this.currentPart||null===this.draggedParts||(this.mayCopy()?(cf(this,!1),Je(a,this.copiedParts),ef(this,this.copiedParts,!1),Oe(a,this.copiedParts)):this.mayMove()?(Ye(this),ef(this,this.draggedParts,!0)):this.mayDragOut()?(cf(this,!1),ef(this,this.copiedParts,!1)):Ye(this),gf(this,a.lastInput.documentPoint))}};
De.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram,b=a.lastInput;if(!this.simulatedMouseUp(b.event,b.documentPoint,b.targetDiagram)){b=!1;var c=this.mayCopy();c&&null!==this.copiedParts?(Ye(this),cf(this,!0),Je(a,this.copiedParts),ef(this,this.copiedParts,!1),Oe(a,this.copiedParts),null!==this.copiedParts&&(a.S("ChangingSelection",a.selection),a.clearSelection(!0),this.copiedParts.iteratorKeys.each(function(a){a.isSelected=!0}))):(b=!0,Ye(this),this.mayMove()&&(ef(this,this.draggedParts,
!0),gf(this,a.lastInput.documentPoint)));this.Ym=!0;uf(this,a.lastInput.documentPoint);if(this.isActive){var d=c?this.copiedParts.Lf():this.draggedParts.Lf();this.copiedParts=null;b&&xf(this);a.Ya();Oe(a,this.draggedParts);this.transactionResult=c?"Copy":"Move";a.S(c?"SelectionCopied":"SelectionMoved",d)}this.stopTool();c&&a.S("ChangedSelection",a.selection)}}};
De.prototype.simulatedMouseMove=function(a,b,c){if(null===Ge)return!1;var d=Ge.diagram;c instanceof Q||(c=null);var e=Ke;c!==e&&(null!==e&&e!==d&&(e.ug(),Ge.isDragOutStarted=!1,e=e.toolManager.findTool("Dragging"),null!==e&&e.doSimulatedDragLeave()),Ke=c,null!==c&&c!==d&&(af(),e=c.toolManager.findTool("Dragging"),null!==e&&(Fe.contains(e)||Fe.add(e),e.doSimulatedDragEnter())),this.doUpdateCursor(null));if(null===c||c===d||!c.allowDrop||c.isReadOnly||!c.allowInsert)return!1;d=c.toolManager.findTool("Dragging");
null!==d&&(null!==a&&(void 0!==a.targetTouches&&(0<a.targetTouches.length?a=a.targetTouches[0]:0<a.changedTouches.length&&(a=a.changedTouches[0])),b=c.getMouse(a)),c.lastInput.documentPoint=b,c.lastInput.viewPoint=c.op(b),c.lastInput.down=!1,c.lastInput.up=!1,d.doSimulatedDragOver());return!0};
De.prototype.simulatedMouseUp=function(a,b,c){if(null===Ge)return!1;var d=Ke,e=Ge.diagram;if(null===c)return Ge.doCancel(),!0;if(c!==d){var f=d.toolManager.findTool("Dragging");if(null!==d&&d!==e&&null!==f)return d.ug(),Ge.isDragOutStarted=!1,f.doSimulatedDragLeave(),!1;Ke=c;d=c.toolManager.findTool("Dragging");null!==d&&(af(),Fe.contains(d)||Fe.add(d),d.doSimulatedDragEnter())}return c!==this.diagram?(null!==a?(void 0!==a.targetTouches&&(0<a.targetTouches.length?a=a.targetTouches[0]:0<a.changedTouches.length&&
(a=a.changedTouches[0])),b=c.getMouse(a)):null===b&&(b=new I),c.lastInput.documentPoint=b,c.lastInput.viewPoint=c.op(b),c.lastInput.down=!1,c.lastInput.up=!0,a=c.toolManager.findTool("Dragging"),null!==a&&a.doSimulatedDrop(),a=Ge,null!==a&&(c=a.mayCopy(),a.transactionResult=c?"Copy":"Move",a.stopTool()),!0):!1};
function xf(a){if(null!==a.draggedParts)for(var b=a.draggedParts.iterator;b.next();){var c=b.key;c instanceof V&&(c=c.containingGroup,null===c||null===c.placeholder||a.draggedParts.contains(c)||c.placeholder.v())}}
De.prototype.mayCopy=function(){if(!this.isCopyEnabled)return!1;var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.allowCopy||(Pa?!a.lastInput.alt:!a.lastInput.control))return!1;for(a=a.selection.iterator;a.next();)if(a.value.canCopy())return!0;return null!==this.draggedLink&&this.dragsLink&&this.draggedLink.canCopy()?!0:!1};
De.prototype.mayDragOut=function(){if(!this.isCopyEnabled)return!1;var a=this.diagram;if(!a.allowDragOut||!a.allowCopy||a.allowMove)return!1;for(a=a.selection.iterator;a.next();)if(a.value.canCopy())return!0;return null!==this.draggedLink&&this.dragsLink&&this.draggedLink.canCopy()?!0:!1};
De.prototype.mayMove=function(){var a=this.diagram;if(a.isReadOnly||!a.allowMove)return!1;for(a=a.selection.iterator;a.next();)if(a.value.canMove())return!0;return null!==this.draggedLink&&this.dragsLink&&this.draggedLink.canMove()?!0:!1};De.prototype.computeBorder=function(a,b,c){return this.Ym||null===this.draggedParts||this.draggedParts.contains(a)?null:c.assign(b)};De.prototype.Bz=function(){return Ge};
De.prototype.mayDragIn=function(){var a=this.diagram;if(!a.allowDrop||a.isReadOnly||a.isModelReadOnly||!a.allowInsert)return!1;var b=Ge;return null===b||b.diagram.model.dataFormat!==a.model.dataFormat?!1:!0};De.prototype.doSimulatedDragEnter=function(){if(this.mayDragIn()){var a=this.diagram;a.animationManager.Cc();yf(a);a.animationManager.Cc();var b=Ge;null!==b&&(b.diagram.Ns=!1,null===a.lastInput.event&&(a.lastInput.event=b.diagram.lastInput.event));this.doUpdateCursor(null)}};
De.prototype.doSimulatedDragLeave=function(){var a=Ge;null!==a&&a.doSimulatedDragOut();this.doCancel()};De.prototype.doSimulatedDragOver=function(){var a=this.diagram;a.animationManager.Dm=!0;var b=Ge;if(null!==b&&null!==b.draggedParts){if(!this.mayDragIn())return;zf(this,b.draggedParts.Lf(),!1,a.firstInput);ef(this,this.copiedParts,!1);gf(this,a.lastInput.documentPoint)}a.animationManager.Dm=!1};
De.prototype.doSimulatedDrop=function(){var a=this.diagram,b=Ge;if(null!==b){var c=b.diagram;b.Ym=!0;Ye(this);if(!this.mayDragIn())return;a.animationManager.Dm=!0;a.S("ChangingSelection",a.selection);this.Ea("Drop");zf(this,b.draggedParts.Lf(),!0,a.lastInput);ef(this,this.copiedParts,!1);var d=new H;null!==this.copiedParts&&(a.clearSelection(!0),this.copiedParts.iteratorKeys.each(function(a){a.isSelected=!0;d.add(a)}));uf(this,a.lastInput.documentPoint);a.Ya();null!==this.copiedParts&&(this.transactionResult=
"ExternalCopy");this.copiedParts=null;a.doFocus();a.S("ExternalObjectsDropped",d,c);this.stopTransaction();a.S("ChangedSelection",a.selection)}a.animationManager.Dm=!1};
function zf(a,b,c,d){if(null===a.copiedParts){var e=a.diagram;if(!e.isReadOnly&&!e.isModelReadOnly){e.skipsUndoManager=!c;e.partManager.addsToTemporaryLayer=!c;a.startPoint=d.documentPoint;c=e.Jj(b,e,!0);var f=N.alloc();e=d=Infinity;for(var g=-Infinity,h=-Infinity,k=b.iterator;k.next();){var l=k.value;if(l.isVisible()){var m=l.location;l=m.x;m=m.y;isNaN(l)||isNaN(m)||(l<d&&(d=l),m<e&&(e=m),l>g&&(g=l),m>h&&(h=m))}}Infinity===d?f.h(0,0,0,0):f.h(d,e,g-d,h-e);d=f.x+f.width/2;e=f.y+f.height/2;N.free(f);
f=a.Fr;g=new ob;h=I.alloc();for(k=b.iterator;k.next();)l=k.value,l instanceof T&&l.canCopy()&&(m=c.I(l),null!==m&&(m.points=l.points,df(m,f.x-d,f.y-e),m.suspendsRouting=!0,g.add(m,a.Pd())));for(b=b.iterator;b.next();)l=b.value,l.Zc()&&l.canCopy()&&(k=c.I(l),null!==k&&(l=l.location,h.h(f.x-(d-l.x),f.y-(e-l.y)),k.location=h,k.Va(),g.add(k,a.Pd(h))));I.free(h);a.copiedParts=g;He(a,g.Lf());null!==a.draggedLink&&(c=a.draggedLink,b=c.routeBounds,df(c,a.startPoint.x-(b.x+b.width/2),a.startPoint.y-(b.y+b.height/
2)));a.doUpdateCursor(null)}}}De.prototype.doSimulatedDragOut=function(){var a=this.diagram;a.Ns=!1;this.mayCopy()||this.mayMove()?a.currentCursor="":a.currentCursor=this.nodropCursor;this.Bn=null};De.prototype.computeMove=function(a,b,c,d){c=this.diagram;return null!==c?c.computeMove(a,b,this.dragOptions,d):new I};
ma.Object.defineProperties(De.prototype,{isCopyEnabled:{get:function(){return this.ob},set:function(a){this.ob=a}},copiesEffectiveCollection:{get:function(){return this.u},set:function(a){this.u=a}},dragOptions:{get:function(){return this.ia},set:function(a){this.ia=a}},isGridSnapEnabled:{get:function(){return this.dragOptions.isGridSnapEnabled},set:function(a){this.dragOptions.isGridSnapEnabled=
a}},isComplexRoutingRealtime:{get:function(){return this.hb},set:function(a){this.hb=a}},isGridSnapRealtime:{get:function(){return this.dragOptions.isGridSnapRealtime},set:function(a){this.dragOptions.isGridSnapRealtime=a}},gridSnapCellSize:{get:function(){return this.dragOptions.gridSnapCellSize},set:function(a){this.dragOptions.gridSnapCellSize.D(a)||(a=a.J(),this.dragOptions.gridSnapCellSize=a)}},gridSnapCellSpot:{
get:function(){return this.dragOptions.gridSnapCellSpot},set:function(a){this.dragOptions.gridSnapCellSpot.D(a)||(a=a.J(),this.dragOptions.gridSnapCellSpot=a)}},gridSnapOrigin:{get:function(){return this.dragOptions.gridSnapOrigin},set:function(a){this.dragOptions.gridSnapOrigin.D(a)||(a=a.J(),this.dragOptions.gridSnapOrigin=a)}},dragsLink:{get:function(){return this.dragOptions.dragsLink},set:function(a){this.dragOptions.dragsLink=
a}},dragsTree:{get:function(){return this.dragOptions.dragsTree},set:function(a){this.dragOptions.dragsTree=a}},copyCursor:{get:function(){return this.F},set:function(a){this.F=a}},moveCursor:{get:function(){return this.Oc},set:function(a){this.Oc=a}},nodropCursor:{get:function(){return this.Pc},set:function(a){this.Pc=a}},currentPart:{get:function(){return this.V},
set:function(a){this.V=a}},copiedParts:{get:function(){return this.o},set:function(a){this.o=a}},draggedParts:{get:function(){return this.Ha},set:function(a){this.Ha=a}},draggingParts:{get:function(){return null!==this.copiedParts?this.copiedParts.Lf():null!==this.draggedParts?this.draggedParts.Lf():this.te}},draggedLink:{get:function(){return this.diagram.draggedLink},set:function(a){this.diagram.draggedLink=
a}},isDragOutStarted:{get:function(){return this.Ib},set:function(a){this.Ib=a}},startPoint:{get:function(){return this.Fr},set:function(a){this.Fr.D(a)||this.Fr.assign(a)}},delay:{get:function(){return this.Hk},set:function(a){this.Hk=a}}});De.prototype.getDraggingSource=De.prototype.Bz;var Ge=null,Ke=null;De.className="DraggingTool";Fe=new F;
Ka("draggingTool",function(){return this.findTool("Dragging")},function(a){this.gb("Dragging",a,this.mouseMoveTools)});La.prototype.doCancel=function(){null!==Ge&&Ge.doCancel();ke.prototype.doCancel.call(this)};
function Af(){ke.call(this);this.te=100;this.Ha=!1;this.u="pointer";var a=new T,b=new Bf;b.isPanelMain=!0;b.stroke="blue";a.add(b);b=new Bf;b.toArrow="Standard";b.fill="blue";b.stroke="blue";a.add(b);a.layerName="Tool";this.Ci=a;a=new V;b=new Bf;b.portId="";b.figure="Rectangle";b.fill=null;b.stroke="magenta";b.strokeWidth=2;b.desiredSize=Db;a.add(b);a.selectable=!1;a.layerName="Tool";this.Bi=a;this.j=b;a=new V;b=new Bf;b.portId="";b.figure="Rectangle";b.fill=null;b.stroke="magenta";b.strokeWidth=
2;b.desiredSize=Db;a.add(b);a.selectable=!1;a.layerName="Tool";this.Di=a;this.o=b;this.Pc=this.Oc=this.ob=this.hb=this.Ib=null;this.ia=!0;this.iy=new ob;this.Of=this.gj=this.Pf=null}la(Af,ke);Af.prototype.doStop=function(){this.diagram.ug();this.originalToPort=this.originalToNode=this.originalFromPort=this.originalFromNode=this.originalLink=null;this.validPortsCache.clear();this.targetPort=null};
Af.prototype.copyPortProperties=function(a,b,c,d,e){if(null!==a&&null!==b&&null!==c&&null!==d){var f=b.Df(),g=M.alloc();g.width=b.naturalBounds.width*f;g.height=b.naturalBounds.height*f;d.desiredSize=g;M.free(g);e?(d.toSpot=b.toSpot,d.toEndSegmentLength=b.toEndSegmentLength):(d.fromSpot=b.fromSpot,d.fromEndSegmentLength=b.fromEndSegmentLength);c.locationSpot=wc;f=I.alloc();c.location=b.ga(wc,f);I.free(f);d.angle=b.li();null!==this.portTargeted&&this.portTargeted(a,b,c,d,e)}};
Af.prototype.setNoTargetPortProperties=function(a,b,c){null!==b&&(b.desiredSize=Db,b.fromSpot=lc,b.toSpot=lc);null!==a&&(a.location=this.diagram.lastInput.documentPoint);null!==this.portTargeted&&this.portTargeted(null,null,a,b,c)};Af.prototype.doMouseDown=function(){this.isActive&&this.doMouseMove()};
Af.prototype.doMouseMove=function(){if(this.isActive){var a=this.diagram;this.targetPort=this.findTargetPort(this.isForwards);if(null!==this.targetPort&&this.targetPort.part instanceof V){var b=this.targetPort.part;this.isForwards?this.copyPortProperties(b,this.targetPort,this.temporaryToNode,this.temporaryToPort,!0):this.copyPortProperties(b,this.targetPort,this.temporaryFromNode,this.temporaryFromPort,!1)}else this.isForwards?this.setNoTargetPortProperties(this.temporaryToNode,this.temporaryToPort,
!0):this.setNoTargetPortProperties(this.temporaryFromNode,this.temporaryFromPort,!1);(a.allowHorizontalScroll||a.allowVerticalScroll)&&a.gs(a.lastInput.viewPoint)}};Af.prototype.findValidLinkablePort=function(a,b){if(null===a)return null;var c=a.part;if(!(c instanceof V))return null;for(;null!==a;){var d=b?a.toLinkable:a.fromLinkable;if(!0===d&&(null!==a.portId||a instanceof V)&&(b?this.isValidTo(c,a):this.isValidFrom(c,a)))return a;if(!1===d)break;a=a.panel}return null};
Af.prototype.findTargetPort=function(a){var b=this.diagram,c=b.lastInput.documentPoint,d=this.portGravity;0>=d&&(d=.1);var e=this,f=b.og(c,d,function(b){return e.findValidLinkablePort(b,a)},null,!0);d=Infinity;b=null;for(f=f.iterator;f.next();){var g=f.value,h=g.part;if(h instanceof V){var k=g.ga(wc,I.alloc()),l=c.x-k.x,m=c.y-k.y;I.free(k);k=l*l+m*m;k<d&&(l=this.validPortsCache.I(g),null!==l?l&&(b=g,d=k):a&&this.isValidLink(this.originalFromNode,this.originalFromPort,h,g)||!a&&this.isValidLink(h,
g,this.originalToNode,this.originalToPort)?(this.validPortsCache.add(g,!0),b=g,d=k):this.validPortsCache.add(g,!1))}}return null!==b&&(c=b.part,c instanceof V&&(null===c.layer||c.layer.allowLink))?b:null};
Af.prototype.isValidFrom=function(a,b){if(null===a||null===b)return this.isUnconnectedLinkValid;if(this.diagram.currentTool===this&&(null!==a.layer&&!a.layer.allowLink||!0!==b.fromLinkable))return!1;var c=b.fromMaxLinks;if(Infinity>c){if(null!==this.originalLink&&a===this.originalFromNode&&b===this.originalFromPort)return!0;b=b.portId;null===b&&(b="");if(a.Mo(b).count>=c)return!1}return!0};
Af.prototype.isValidTo=function(a,b){if(null===a||null===b)return this.isUnconnectedLinkValid;if(this.diagram.currentTool===this&&(null!==a.layer&&!a.layer.allowLink||!0!==b.toLinkable))return!1;var c=b.toMaxLinks;if(Infinity>c){if(null!==this.originalLink&&a===this.originalToNode&&b===this.originalToPort)return!0;b=b.portId;null===b&&(b="");if(a.Sd(b).count>=c)return!1}return!0};
Af.prototype.isInSameNode=function(a,b){if(null===a||null===b)return!1;if(a===b)return!0;a=a.part;b=b.part;return null!==a&&a===b};Af.prototype.isLinked=function(a,b){if(null===a||null===b)return!1;var c=a.part;if(!(c instanceof V))return!1;a=a.portId;null===a&&(a="");var d=b.part;if(!(d instanceof V))return!1;b=b.portId;null===b&&(b="");for(b=d.Sd(b);b.next();)if(d=b.value,d.fromNode===c&&d.fromPortId===a)return!0;return!1};
Af.prototype.isValidLink=function(a,b,c,d){if(!this.isValidFrom(a,b)||!this.isValidTo(c,d)||!(null===b||null===d||(b.fromLinkableSelfNode&&d.toLinkableSelfNode||!this.isInSameNode(b,d))&&(b.fromLinkableDuplicates&&d.toLinkableDuplicates||!this.isLinked(b,d)))||null!==this.originalLink&&(null!==a&&this.isLabelDependentOnLink(a,this.originalLink)||null!==c&&this.isLabelDependentOnLink(c,this.originalLink))||null!==a&&null!==c&&(null===a.data&&null!==c.data||null!==a.data&&null===c.data)||!this.isValidCycle(a,
c,this.originalLink))return!1;if(null!==a){var e=a.linkValidation;if(null!==e&&!e(a,b,c,d,this.originalLink))return!1}if(null!==c&&(e=c.linkValidation,null!==e&&!e(a,b,c,d,this.originalLink)))return!1;e=this.linkValidation;return null!==e?e(a,b,c,d,this.originalLink):!0};Af.prototype.isLabelDependentOnLink=function(a,b){if(null===a)return!1;var c=a.labeledLink;if(null===c)return!1;if(c===b)return!0;var d=new H;d.add(a);return Cf(this,c,b,d)};
function Cf(a,b,c,d){if(b===c)return!0;var e=b.fromNode;if(null!==e&&e.isLinkLabel&&(d.add(e),Cf(a,e.labeledLink,c,d)))return!0;b=b.toNode;return null!==b&&b.isLinkLabel&&(d.add(b),Cf(a,b.labeledLink,c,d))?!0:!1}
Af.prototype.isValidCycle=function(a,b,c){void 0===c&&(c=null);if(null===a||null===b)return this.isUnconnectedLinkValid;var d=this.diagram.validCycle;if(d!==Df){if(d===Ef){d=c||this.temporaryLink;if(null!==d&&!d.isTreeLink)return!0;for(d=b.linksConnected;d.next();){var e=d.value;if(e!==c&&e.isTreeLink&&e.toNode===b)return!1}return!Ff(this,a,b,c,!0)}if(d===Gf){d=c||this.temporaryLink;if(null!==d&&!d.isTreeLink)return!0;for(d=a.linksConnected;d.next();)if(e=d.value,e!==c&&e.isTreeLink&&e.fromNode===
a)return!1;return!Ff(this,a,b,c,!0)}if(d===Hf)return a===b?a=!0:(d=new H,d.add(b),a=If(this,d,a,b,c)),!a;if(d===Jf)return!Ff(this,a,b,c,!1);if(d===Kf)return a===b?a=!0:(d=new H,d.add(b),a=Lf(this,d,a,b,c)),!a}return!0};function Ff(a,b,c,d,e){if(b===c)return!0;if(null===b||null===c)return!1;for(var f=b.linksConnected;f.next();){var g=f.value;if(g!==d&&(!e||g.isTreeLink)&&g.toNode===b&&(g=g.fromNode,g!==b&&Ff(a,g,c,d,e)))return!0}return!1}
function If(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var f=c.linksConnected;f.next();){var g=f.value;if(g!==e&&g.toNode===c&&(g=g.fromNode,g!==c&&If(a,b,g,d,e)))return!0}return!1}function Lf(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var f=c.linksConnected;f.next();){var g=f.value;if(g!==e){var h=g.fromNode;g=g.toNode;h=h===c?g:h;if(h!==c&&Lf(a,b,h,d,e))return!0}}return!1}
ma.Object.defineProperties(Af.prototype,{portGravity:{get:function(){return this.te},set:function(a){0<=a&&(this.te=a)}},isUnconnectedLinkValid:{get:function(){return this.Ha},set:function(a){this.Ha=a}},linkingCursor:{get:function(){return this.u},set:function(a){this.u=a}},temporaryLink:{get:function(){return this.Ci},set:function(a){this.Ci=a}},temporaryFromNode:{
get:function(){return this.Bi},set:function(a){if(this.Bi=a)this.j=a.port}},temporaryFromPort:{get:function(){return this.j},set:function(a){if(null!==this.j){var b=this.j.panel;if(null!==b){var c=b.O.indexOf(this.j);b.mb(c);b.Hb(c,a)}}this.j=a}},temporaryToNode:{get:function(){return this.Di},set:function(a){if(this.Di=a)this.o=a.port}},temporaryToPort:{get:function(){return this.o},set:function(a){if(null!==
this.o){var b=this.o.panel;if(null!==b){var c=b.O.indexOf(this.o);b.mb(c);b.Hb(c,a)}}this.o=a}},originalLink:{get:function(){return this.Ib},set:function(a){this.Ib=a}},originalFromNode:{get:function(){return this.hb},set:function(a){this.hb=a}},originalFromPort:{get:function(){return this.ob},set:function(a){this.ob=a}},originalToNode:{get:function(){return this.Oc},set:function(a){this.Oc=
a}},originalToPort:{get:function(){return this.Pc},set:function(a){this.Pc=a}},isForwards:{get:function(){return this.ia},set:function(a){this.ia=a}},validPortsCache:{get:function(){return this.iy}},targetPort:{get:function(){return this.Pf},set:function(a){this.Pf=a}},linkValidation:{get:function(){return this.gj},set:function(a){this.gj=a}},portTargeted:{
get:function(){return this.Of},set:function(a){this.Of=a}}});Af.className="LinkingBaseTool";function Mf(){Af.call(this);this.name="Linking";this.V={};this.F=null;this.K=Uf;this.Xd=null}la(Mf,Af);Mf.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return a.isReadOnly||a.isModelReadOnly||!a.allowLink||!a.model.xs()||!a.lastInput.left||a.currentTool!==this&&!this.isBeyondDragSize()?!1:null!==this.findLinkablePort()};
Mf.prototype.findLinkablePort=function(){var a=this.diagram,b=this.startObject;null===b&&(b=a.jc(a.firstInput.documentPoint,null,null));if(null===b)return null;a=b.part;if(!(a instanceof V))return null;var c=this.direction;if(c===Uf||c===Vf){var d=this.findValidLinkablePort(b,!1);if(null!==d||this.startObject===a&&(d=a.port,this.findValidLinkablePort(d,!1)))return this.isForwards=!0,d}if(c===Uf||c===Wf)if(b=this.findValidLinkablePort(b,!0),null!==b||this.startObject===a&&(b=a.port,this.findValidLinkablePort(b,
!0)))return this.isForwards=!1,b;return null};
Mf.prototype.doActivate=function(){var a=this.diagram,b=this.findLinkablePort();null===b?this.stopTool():(this.Ea(this.name),a.isMouseCaptured=!0,a.currentCursor=this.linkingCursor,this.isForwards?(null!==this.temporaryToNode&&(this.temporaryToNode.location=a.lastInput.documentPoint),this.originalFromPort=b,b=this.originalFromPort.part,b instanceof V&&(this.originalFromNode=b),this.copyPortProperties(this.originalFromNode,this.originalFromPort,this.temporaryFromNode,this.temporaryFromPort,!1)):(null!==
this.temporaryFromNode&&(this.temporaryFromNode.location=a.lastInput.documentPoint),this.originalToPort=b,b=this.originalToPort.part,b instanceof V&&(this.originalToNode=b),this.copyPortProperties(this.originalToNode,this.originalToPort,this.temporaryToNode,this.temporaryToPort,!0)),a.add(this.temporaryFromNode),this.temporaryFromNode&&this.temporaryFromNode.Va(),a.add(this.temporaryToNode),this.temporaryToNode&&this.temporaryToNode.Va(),null!==this.temporaryLink&&(null!==this.temporaryFromNode&&
(this.temporaryLink.fromNode=this.temporaryFromNode),null!==this.temporaryToNode&&(this.temporaryLink.toNode=this.temporaryToNode),this.temporaryLink.isTreeLink=this.isNewTreeLink(),this.temporaryLink.bb(),a.add(this.temporaryLink)),this.isActive=!0)};Mf.prototype.doDeactivate=function(){this.isActive=!1;var a=this.diagram;a.remove(this.temporaryLink);a.remove(this.temporaryFromNode);a.remove(this.temporaryToNode);a.isMouseCaptured=!1;a.currentCursor="";this.stopTransaction()};
Mf.prototype.doStop=function(){Af.prototype.doStop.call(this);this.startObject=null};
Mf.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram,b=this.transactionResult=null,c=null,d=null,e=null,f=null;try{var g=this.targetPort=this.findTargetPort(this.isForwards);if(null!==g){var h=g.part;h instanceof V&&(this.isForwards?(null!==this.originalFromNode&&(b=this.originalFromNode,c=this.originalFromPort),d=h,e=g):(b=h,c=g,null!==this.originalToNode&&(d=this.originalToNode,e=this.originalToPort)))}else this.isForwards?null!==this.originalFromNode&&this.isUnconnectedLinkValid&&
(b=this.originalFromNode,c=this.originalFromPort):null!==this.originalToNode&&this.isUnconnectedLinkValid&&(d=this.originalToNode,e=this.originalToPort);null!==b||null!==d?(f=this.insertLink(b,c,d,e),null!==f?(null===g&&(this.isForwards?f.defaultToPoint=a.lastInput.documentPoint:f.defaultFromPoint=a.lastInput.documentPoint),a.allowSelect&&(a.S("ChangingSelection",a.selection),a.clearSelection(!0),f.isSelected=!0),this.transactionResult=this.name,a.S("LinkDrawn",f)):this.doNoLink(b,c,d,e)):this.isForwards?
this.doNoLink(this.originalFromNode,this.originalFromPort,null,null):this.doNoLink(null,null,this.originalToNode,this.originalToPort)}finally{this.stopTool(),f&&a.allowSelect&&a.S("ChangedSelection",a.selection)}}};Mf.prototype.isNewTreeLink=function(){var a=this.archetypeLinkData;if(null===a)return!0;if(a instanceof T)return a.isTreeLink;var b=this.diagram;if(null===b)return!0;a=b.partManager.getLinkCategoryForData(a);b=b.partManager.findLinkTemplateForCategory(a);return null!==b?b.isTreeLink:!0};
Mf.prototype.insertLink=function(a,b,c,d){return this.diagram.partManager.insertLink(a,b,c,d)};Mf.prototype.doNoLink=function(){};
ma.Object.defineProperties(Mf.prototype,{archetypeLinkData:{get:function(){return this.V},set:function(a){this.V=a}},archetypeLabelNodeData:{get:function(){return this.F},set:function(a){this.F=a}},direction:{get:function(){return this.K},set:function(a){this.K=a}},startObject:{get:function(){return this.Xd},set:function(a){this.Xd=a}}});
var Uf=new D(Mf,"Either",0),Vf=new D(Mf,"ForwardsOnly",0),Wf=new D(Mf,"BackwardsOnly",0);Mf.className="LinkingTool";Mf.Either=Uf;Mf.ForwardsOnly=Vf;Mf.BackwardsOnly=Wf;
function Le(){Af.call(this);this.name="Relinking";var a=new Bf;a.figure="Diamond";a.desiredSize=Ib;a.fill="lightblue";a.stroke="dodgerblue";a.cursor=this.linkingCursor;a.segmentIndex=0;this.V=a;a=new Bf;a.figure="Diamond";a.desiredSize=Ib;a.fill="lightblue";a.stroke="dodgerblue";a.cursor=this.linkingCursor;a.segmentIndex=-1;this.Xd=a;this.F=null;this.Fw=new N}la(Le,Af);
Le.prototype.updateAdornments=function(a){if(null!==a&&a instanceof T){var b="RelinkFrom",c=null;if(a.isSelected&&!this.diagram.isReadOnly){var d=a.selectionObject;null!==d&&a.canRelinkFrom()&&a.actualBounds.w()&&a.isVisible()&&d.actualBounds.w()&&d.Ed()&&(c=a.Oj(b),null===c&&(c=this.makeAdornment(d,!1),a.bh(b,c)))}null===c&&a.Jf(b);b="RelinkTo";c=null;a.isSelected&&!this.diagram.isReadOnly&&(d=a.selectionObject,null!==d&&a.canRelinkTo()&&a.actualBounds.w()&&a.isVisible()&&d.actualBounds.w()&&d.Ed()&&
(c=a.Oj(b),null===c?(c=this.makeAdornment(d,!0),a.bh(b,c)):c.v()));null===c&&a.Jf(b)}};Le.prototype.makeAdornment=function(a,b){var c=new pe;c.type=W.Link;b=b?this.toHandleArchetype:this.fromHandleArchetype;null!==b&&c.add(b.copy());c.adornedObject=a;return c};
Le.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowRelink||!a.model.xs()||!a.lastInput.left)return!1;var b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkTo"));return null!==b};
Le.prototype.doActivate=function(){var a=this.diagram;if(null===this.originalLink){var b=this.handle;null===b&&(b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkFrom"),null===b&&(b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkTo")));if(null===b)return;var c=b.part;if(!(c instanceof pe&&c.adornedPart instanceof T))return;this.handle=b;this.isForwards=null===c||"RelinkTo"===c.category;this.originalLink=c.adornedPart}this.Ea(this.name);a.isMouseCaptured=!0;a.currentCursor=this.linkingCursor;
this.originalFromPort=this.originalLink.fromPort;this.originalFromNode=this.originalLink.fromNode;this.originalToPort=this.originalLink.toPort;this.originalToNode=this.originalLink.toNode;this.Fw.set(this.originalLink.actualBounds);null!==this.originalLink&&0<this.originalLink.pointsCount&&(null===this.originalLink.fromNode&&(null!==this.temporaryFromPort&&(this.temporaryFromPort.desiredSize=Cb),null!==this.temporaryFromNode&&(this.temporaryFromNode.location=this.originalLink.i(0))),null===this.originalLink.toNode&&
(null!==this.temporaryToPort&&(this.temporaryToPort.desiredSize=Cb),null!==this.temporaryToNode&&(this.temporaryToNode.location=this.originalLink.i(this.originalLink.pointsCount-1))));this.copyPortProperties(this.originalFromNode,this.originalFromPort,this.temporaryFromNode,this.temporaryFromPort,!1);this.copyPortProperties(this.originalToNode,this.originalToPort,this.temporaryToNode,this.temporaryToPort,!0);a.add(this.temporaryFromNode);a.add(this.temporaryToNode);null!==this.temporaryLink&&(null!==
this.temporaryFromNode&&(this.temporaryLink.fromNode=this.temporaryFromNode),null!==this.temporaryToNode&&(this.temporaryLink.toNode=this.temporaryToNode),this.copyLinkProperties(this.originalLink,this.temporaryLink),this.temporaryLink.bb(),a.add(this.temporaryLink));this.isActive=!0};
Le.prototype.copyLinkProperties=function(a,b){if(null!==a&&null!==b){b.adjusting=a.adjusting;b.corner=a.corner;var c=a.curve;if(c===Xf||c===Yf)c=Zf;b.curve=c;b.curviness=a.curviness;b.isTreeLink=a.isTreeLink;b.points=a.points;b.routing=a.routing;b.smoothness=a.smoothness;b.fromSpot=a.fromSpot;b.fromEndSegmentLength=a.fromEndSegmentLength;b.fromShortLength=a.fromShortLength;b.toSpot=a.toSpot;b.toEndSegmentLength=a.toEndSegmentLength;b.toShortLength=a.toShortLength}};
Le.prototype.doDeactivate=function(){this.isActive=!1;var a=this.diagram;a.remove(this.temporaryLink);a.remove(this.temporaryFromNode);a.remove(this.temporaryToNode);a.isMouseCaptured=!1;a.currentCursor="";this.stopTransaction()};Le.prototype.doStop=function(){Af.prototype.doStop.call(this);this.handle=null};
Le.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram;this.transactionResult=null;var b=this.originalFromNode,c=this.originalFromPort,d=this.originalToNode,e=this.originalToPort,f=this.originalLink;try{this.targetPort=this.findTargetPort(this.isForwards);if(null!==this.targetPort){var g=this.targetPort.part;g instanceof V&&(this.isForwards?(d=g,e=this.targetPort):(b=g,c=this.targetPort))}else this.isUnconnectedLinkValid?this.isForwards?e=d=null:c=b=null:f=null;null!==f?(this.reconnectLink(f,
this.isForwards?d:b,this.isForwards?e:c,this.isForwards),null===this.targetPort&&(this.isForwards?f.defaultToPoint=a.lastInput.documentPoint:f.defaultFromPoint=a.lastInput.documentPoint,f.bb()),this.transactionResult=this.name,a.S("LinkRelinked",f,this.isForwards?this.originalToPort:this.originalFromPort)):this.doNoRelink(this.originalLink,this.isForwards);this.originalLink.Xo(this.Fw)}finally{this.stopTool()}}};
Le.prototype.reconnectLink=function(a,b,c,d){c=null!==c&&null!==c.portId?c.portId:"";d?(a.toNode=b,a.toPortId=c):(a.fromNode=b,a.fromPortId=c);return!0};Le.prototype.doNoRelink=function(){};
function tf(a,b,c,d,e){null!==b?(a.copyPortProperties(b,c,a.temporaryFromNode,a.temporaryFromPort,!1),a.diagram.add(a.temporaryFromNode)):a.diagram.remove(a.temporaryFromNode);null!==d?(a.copyPortProperties(d,e,a.temporaryToNode,a.temporaryToPort,!0),a.diagram.add(a.temporaryToNode)):a.diagram.remove(a.temporaryToNode)}
ma.Object.defineProperties(Le.prototype,{fromHandleArchetype:{get:function(){return this.V},set:function(a){this.V=a}},toHandleArchetype:{get:function(){return this.Xd},set:function(a){this.Xd=a}},handle:{get:function(){return this.F},set:function(a){if(null!==a&&!(a.part instanceof pe))throw Error("new handle is not in an Adornment: "+a);this.F=a}}});Le.className="RelinkingTool";
Ka("linkingTool",function(){return this.findTool("Linking")},function(a){this.gb("Linking",a,this.mouseMoveTools)});Ka("relinkingTool",function(){return this.findTool("Relinking")},function(a){this.gb("Relinking",a,this.mouseDownTools)});
function $f(){ke.call(this);this.name="LinkReshaping";var a=new Bf;a.figure="Rectangle";a.desiredSize=Hb;a.fill="lightblue";a.stroke="dodgerblue";this.o=a;a=new Bf;a.figure="Diamond";a.desiredSize=Ib;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="move";this.u=a;this.F=3;this.Ts=this.j=null;this.Gw=new I;this.Yq=new F}la($f,ke);$f.prototype.Su=function(a){return a&&0!==a.reshapingBehavior.value?a.reshapingBehavior:ag};$f.prototype.om=function(a,b){a.reshapingBehavior=b};
$f.prototype.updateAdornments=function(a){if(null!==a&&a instanceof T){var b=null;if(a.isSelected&&!this.diagram.isReadOnly){var c=a.path;null!==c&&a.canReshape()&&a.actualBounds.w()&&a.isVisible()&&c.actualBounds.w()&&c.Ed()&&(b=a.Oj(this.name),null===b||b.Bw!==a.pointsCount||b.Vw!==a.resegmentable)&&(b=this.makeAdornment(c),null!==b&&(b.Bw=a.pointsCount,b.Vw=a.resegmentable,a.bh(this.name,b)))}null===b&&a.Jf(this.name)}};
$f.prototype.makeAdornment=function(a){var b=a.part,c=b.pointsCount,d=b.isOrthogonal,e=null;if(null!==b.points&&1<c){e=new pe;e.type=W.Link;c=b.firstPickIndex;var f=b.lastPickIndex,g=d?1:0;if(b.resegmentable&&b.computeCurve()!==bg)for(var h=c+g;h<f-g;h++){var k=this.makeResegmentHandle(a,h);null!==k&&(k.segmentIndex=h,k.segmentFraction=.5,k.fromMaxLinks=999,e.add(k))}for(g=c+1;g<f;g++)if(h=this.makeHandle(a,g),null!==h){h.segmentIndex=g;if(g!==c)if(g===c+1&&d){k=b.i(c);var l=b.i(c+1);K.B(k.x,l.x)&&
K.B(k.y,l.y)&&(l=b.i(c-1));K.B(k.x,l.x)?(this.om(h,cg),h.cursor="n-resize"):K.B(k.y,l.y)&&(this.om(h,dg),h.cursor="w-resize")}else g===f-1&&d?(k=b.i(f-1),l=b.i(f),K.B(k.x,l.x)&&K.B(k.y,l.y)&&(k=b.i(f+1)),K.B(k.x,l.x)?(this.om(h,cg),h.cursor="n-resize"):K.B(k.y,l.y)&&(this.om(h,dg),h.cursor="w-resize")):g!==f&&(this.om(h,eg),h.cursor="move");e.add(h)}e.adornedObject=a}return e};$f.prototype.makeHandle=function(){var a=this.handleArchetype;return null===a?null:a.copy()};
$f.prototype.makeResegmentHandle=function(){var a=this.midHandleArchetype;return null===a?null:a.copy()};$f.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.isReadOnly&&a.allowReshape&&a.lastInput.left?null!==this.findToolHandleAt(a.firstInput.documentPoint,this.name):!1};
$f.prototype.doActivate=function(){var a=this.diagram;null===this.handle&&(this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name));if(null!==this.handle){var b=this.handle.part.adornedPart;if(b instanceof T){this.Ts=b;a.isMouseCaptured=!0;this.Ea(this.name);if(b.resegmentable&&999===this.handle.fromMaxLinks){var c=b.points.copy(),d=this.getResegmentingPoint();c.Hb(this.handle.segmentIndex+1,d);b.isOrthogonal&&c.Hb(this.handle.segmentIndex+1,d);b.points=c;b.Yb();b.updateAdornments();
this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name);if(null===this.handle){this.doDeactivate();return}}this.Gw=b.i(this.handle.segmentIndex);this.Yq=b.points.copy();this.isActive=!0}}};$f.prototype.doDeactivate=function(){this.stopTransaction();this.Ts=this.handle=null;this.isActive=this.diagram.isMouseCaptured=!1};
$f.prototype.stopTransaction=function(){var a=this.diagram,b=ke.prototype.stopTransaction.call(this);b&&null!==a.undoManager.transactionToUndo&&a.undoManager.transactionToUndo.$j();return b};$f.prototype.doCancel=function(){var a=this.adornedLink;null!==a&&(a.points=this.Yq);this.stopTool()};$f.prototype.getResegmentingPoint=function(){return this.handle.ga(wc)};$f.prototype.doMouseMove=function(){var a=this.diagram;this.isActive&&(a=this.computeReshape(a.lastInput.documentPoint),this.reshape(a))};
$f.prototype.doMouseUp=function(){var a=this.diagram;if(this.isActive){var b=this.computeReshape(a.lastInput.documentPoint);this.reshape(b);b=this.adornedLink;if(null!==b&&b.resegmentable){var c=this.handle.segmentIndex,d=b.i(c-1),e=b.i(c),f=b.i(c+1);if(b.isOrthogonal){if(c>b.firstPickIndex+1&&c<b.lastPickIndex-1){var g=b.i(c-2);if(Math.abs(d.x-e.x)<this.resegmentingDistance&&Math.abs(d.y-e.y)<this.resegmentingDistance&&(fg(this,g,d,e,f,!0)||fg(this,g,d,e,f,!1))){var h=b.points.copy();fg(this,g,d,
e,f,!0)?(h.cd(c-2,new I(g.x,(f.y+g.y)/2)),h.cd(c+1,new I(f.x,(f.y+g.y)/2))):(h.cd(c-2,new I((f.x+g.x)/2,g.y)),h.cd(c+1,new I((f.x+g.x)/2,f.y)));h.mb(c);h.mb(c-1);b.points=h;b.Yb()}else g=b.i(c+2),Math.abs(e.x-f.x)<this.resegmentingDistance&&Math.abs(e.y-f.y)<this.resegmentingDistance&&(fg(this,d,e,f,g,!0)||fg(this,d,e,f,g,!1))&&(h=b.points.copy(),fg(this,d,e,f,g,!0)?(h.cd(c-1,new I(d.x,(d.y+g.y)/2)),h.cd(c+2,new I(g.x,(d.y+g.y)/2))):(h.cd(c-1,new I((d.x+g.x)/2,d.y)),h.cd(c+2,new I((d.x+g.x)/2,g.y))),
h.mb(c+1),h.mb(c),b.points=h,b.Yb())}}else g=I.alloc(),K.qh(d.x,d.y,f.x,f.y,e.x,e.y,g)&&g.Bf(e)<this.resegmentingDistance*this.resegmentingDistance&&(d=b.points.copy(),d.mb(c),b.points=d,b.Yb()),I.free(g)}a.Ya();this.transactionResult=this.name;a.S("LinkReshaped",this.adornedLink,this.Yq)}this.stopTool()};
function fg(a,b,c,d,e,f){return f?Math.abs(b.y-c.y)<a.resegmentingDistance&&Math.abs(c.y-d.y)<a.resegmentingDistance&&Math.abs(d.y-e.y)<a.resegmentingDistance:Math.abs(b.x-c.x)<a.resegmentingDistance&&Math.abs(c.x-d.x)<a.resegmentingDistance&&Math.abs(d.x-e.x)<a.resegmentingDistance}
$f.prototype.reshape=function(a){var b=this.adornedLink;b.uh();var c=this.handle.segmentIndex,d=this.Su(this.handle);if(b.isOrthogonal)if(c===b.firstPickIndex+1)c=b.firstPickIndex+1,d===cg?(b.L(c,b.i(c-1).x,a.y),b.L(c+1,b.i(c+2).x,a.y)):d===dg&&(b.L(c,a.x,b.i(c-1).y),b.L(c+1,a.x,b.i(c+2).y));else if(c===b.lastPickIndex-1)c=b.lastPickIndex-1,d===cg?(b.L(c-1,b.i(c-2).x,a.y),b.L(c,b.i(c+1).x,a.y)):d===dg&&(b.L(c-1,a.x,b.i(c-2).y),b.L(c,a.x,b.i(c+1).y));else{d=c;var e=b.i(d),f=b.i(d-1),g=b.i(d+1);K.B(f.x,
e.x)&&K.B(e.y,g.y)?(K.B(f.x,b.i(d-2).x)&&!K.B(f.y,b.i(d-2).y)?(b.m(d,a.x,f.y),c++,d++):b.L(d-1,a.x,f.y),K.B(g.y,b.i(d+2).y)&&!K.B(g.x,b.i(d+2).x)?b.m(d+1,g.x,a.y):b.L(d+1,g.x,a.y)):K.B(f.y,e.y)&&K.B(e.x,g.x)?(K.B(f.y,b.i(d-2).y)&&!K.B(f.x,b.i(d-2).x)?(b.m(d,f.x,a.y),c++,d++):b.L(d-1,f.x,a.y),K.B(g.x,b.i(d+2).x)&&!K.B(g.y,b.i(d+2).y)?b.m(d+1,a.x,g.y):b.L(d+1,a.x,g.y)):K.B(f.x,e.x)&&K.B(e.x,g.x)?(K.B(f.x,b.i(d-2).x)&&!K.B(f.y,b.i(d-2).y)?(b.m(d,a.x,f.y),c++,d++):b.L(d-1,a.x,f.y),K.B(g.x,b.i(d+2).x)&&
!K.B(g.y,b.i(d+2).y)?b.m(d+1,a.x,g.y):b.L(d+1,a.x,g.y)):K.B(f.y,e.y)&&K.B(e.y,g.y)&&(K.B(f.y,b.i(d-2).y)&&!K.B(f.x,b.i(d-2).x)?(b.m(d,f.x,a.y),c++,d++):b.L(d-1,f.x,a.y),K.B(g.y,b.i(d+2).y)&&!K.B(g.x,b.i(d+2).x)?b.m(d+1,g.x,a.y):b.L(d+1,g.x,a.y));b.L(c,a.x,a.y)}else b.L(c,a.x,a.y),d=b.fromNode,e=b.fromPort,null!==d&&(f=d.findVisibleNode(),null!==f&&f!==d&&(d=f,e=d.port)),1===c&&b.computeSpot(!0,e).Mc()&&(f=e.ga(wc,I.alloc()),d=b.getLinkPointFromPoint(d,e,f,a,!0,I.alloc()),b.L(0,d.x,d.y),I.free(f),
I.free(d)),d=b.toNode,e=b.toPort,null!==d&&(f=d.findVisibleNode(),null!==f&&f!==d&&(d=f,e=d.port)),c===b.pointsCount-2&&b.computeSpot(!1,e).Mc()&&(c=e.ga(wc,I.alloc()),a=b.getLinkPointFromPoint(d,e,c,a,!1,I.alloc()),b.L(b.pointsCount-1,a.x,a.y),I.free(c),I.free(a));b.zf()};$f.prototype.computeReshape=function(a){var b=this.adornedLink,c=this.handle.segmentIndex;switch(this.Su(this.handle)){case eg:return a;case cg:return new I(b.i(c).x,a.y);case dg:return new I(a.x,b.i(c).y);default:case ag:return b.i(c)}};
ma.Object.defineProperties($f.prototype,{handleArchetype:{get:function(){return this.o},set:function(a){this.o=a}},midHandleArchetype:{get:function(){return this.u},set:function(a){this.u=a}},handle:{get:function(){return this.j},set:function(a){if(null!==a&&!(a.part instanceof pe))throw Error("new handle is not in an Adornment: "+a);this.j=a}},adornedLink:{get:function(){return this.Ts}},
resegmentingDistance:{get:function(){return this.F},set:function(a){this.F=a}},originalPoint:{get:function(){return this.Gw}},originalPoints:{get:function(){return this.Yq}}});$f.prototype.setReshapingBehavior=$f.prototype.om;$f.prototype.getReshapingBehavior=$f.prototype.Su;var ag=new D($f,"None",0),dg=new D($f,"Horizontal",1),cg=new D($f,"Vertical",2),eg=new D($f,"All",3);$f.className="LinkReshapingTool";
$f.None=ag;$f.Horizontal=dg;$f.Vertical=cg;$f.All=eg;Ka("linkReshapingTool",function(){return this.findTool("LinkReshaping")},function(a){this.gb("LinkReshaping",a,this.mouseDownTools)});
function gg(){ke.call(this);this.name="Resizing";this.o=(new M(1,1)).freeze();this.j=(new M(9999,9999)).freeze();this.yg=(new M(NaN,NaN)).freeze();this.V=!1;this.ia=!0;this.ue=null;var a=new Bf;a.alignmentFocus=wc;a.figure="Rectangle";a.desiredSize=Hb;a.fill="lightblue";a.stroke="dodgerblue";a.strokeWidth=1;a.cursor="pointer";this.F=a;this.u=null;this.Xq=new I;this.Ew=new M;this.Tn=new I;this.rt=new M(0,0);this.qt=new M(Infinity,Infinity);this.pt=new M(1,1)}la(gg,ke);
gg.prototype.updateAdornments=function(a){if(!(null===a||a instanceof T)){if(a.isSelected&&!this.diagram.isReadOnly){var b=a.resizeObject,c=a.Oj(this.name);if(null!==b&&a.canResize()&&a.actualBounds.w()&&a.isVisible()&&b.actualBounds.w()&&b.Ed()){if(null===c||c.adornedObject!==b)c=this.makeAdornment(b);if(null!==c){b=b.li();hg(a)&&this.updateResizeHandles(c,b);a.bh(this.name,c);return}}}a.Jf(this.name)}};
gg.prototype.makeAdornment=function(a){var b=a.part.resizeAdornmentTemplate;if(null===b){b=new pe;b.type=W.Spot;b.locationSpot=wc;var c=new ig;c.isPanelMain=!0;b.add(c);b.add(this.makeHandle(a,sc));b.add(this.makeHandle(a,uc));b.add(this.makeHandle(a,Bc));b.add(this.makeHandle(a,yc));b.add(this.makeHandle(a,ad));b.add(this.makeHandle(a,cd));b.add(this.makeHandle(a,dd));b.add(this.makeHandle(a,bd))}else if(jg(b),b=b.copy(),null===b)return null;b.adornedObject=a;return b};
gg.prototype.makeHandle=function(a,b){a=this.handleArchetype;if(null===a)return null;a=a.copy();a.alignment=b;return a};
gg.prototype.updateResizeHandles=function(a,b){if(null!==a)if(!a.alignment.Zb()&&("pointer"===a.cursor||0<a.cursor.indexOf("resize")))a:{var c=a.alignment;c.Mc()&&(c=wc);if(0>=c.x)b=0>=c.y?b+225:1<=c.y?b+135:b+180;else if(1<=c.x)0>=c.y?b+=315:1<=c.y&&(b+=45);else if(0>=c.y)b+=270;else if(1<=c.y)b+=90;else break a;0>b?b+=360:360<=b&&(b-=360);a.cursor=22.5>b?"e-resize":67.5>b?"se-resize":112.5>b?"s-resize":157.5>b?"sw-resize":202.5>b?"w-resize":247.5>b?"nw-resize":292.5>b?"n-resize":337.5>b?"ne-resize":
"e-resize"}else if(a instanceof W)for(a=a.elements;a.next();)this.updateResizeHandles(a.value,b)};gg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.isReadOnly&&a.allowResize&&a.lastInput.left?null!==this.findToolHandleAt(a.firstInput.documentPoint,this.name):!1};
gg.prototype.doActivate=function(){var a=this.diagram;null===this.handle&&(this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name));null!==this.handle&&(this.adornedObject=this.handle.part.adornedObject,null!==this.adornedObject&&(a.isMouseCaptured=!0,a.animationManager.Cc(),this.Ea(this.name),this.Xq.set(this.adornedObject.ga(this.handle.alignment.hv())),this.Tn.set(this.adornedObject.part.location),this.Ew.set(this.adornedObject.desiredSize),this.pt=this.computeCellSize(),this.rt=
this.computeMinSize(),this.qt=this.computeMaxSize(),this.isActive=!0))};gg.prototype.doDeactivate=function(){var a=this.diagram;this.stopTransaction();this.ue=this.handle=null;this.isActive=a.isMouseCaptured=!1};gg.prototype.stopTransaction=function(){var a=this.diagram,b=ke.prototype.stopTransaction.call(this);b&&null!==a.undoManager.transactionToUndo&&a.undoManager.transactionToUndo.$j();return b};
gg.prototype.doCancel=function(){null!==this.adornedObject&&(this.adornedObject.desiredSize=this.originalDesiredSize,this.adornedObject.part.location=this.originalLocation);this.stopTool()};gg.prototype.doMouseMove=function(){var a=this.diagram;if(this.isActive){var b=this.rt,c=this.qt,d=this.pt,e=this.adornedObject.qs(a.lastInput.documentPoint,I.alloc()),f=this.computeReshape();b=this.computeResize(e,this.handle.alignment,b,c,d,f);this.resize(b);a.bd();I.free(e)}};
gg.prototype.doMouseUp=function(){var a=this.diagram;if(this.isActive){var b=this.rt,c=this.qt,d=this.pt,e=this.adornedObject.qs(a.lastInput.documentPoint,I.alloc()),f=this.computeReshape();b=this.computeResize(e,this.handle.alignment,b,c,d,f);this.resize(b);I.free(e);a.Ya();this.transactionResult=this.name;a.S("PartResized",this.adornedObject,this.originalDesiredSize)}this.stopTool()};
gg.prototype.resize=function(a){var b=this.diagram,c=this.adornedObject;if(null!==c){c.desiredSize=a.size;a=c.part;a.Va();c=c.ga(this.handle.alignment.hv());if(a instanceof jf){var d=new F;d.add(a);if(!this.dragsMembers&&null===a.placeholder){var e=new Ee;e.dragsMembers=!1}b.moveParts(d,this.oppositePoint.copy().re(c),!0,e)}else a.location=a.location.copy().re(c).add(this.oppositePoint);b.bd()}};
gg.prototype.computeResize=function(a,b,c,d,e,f){b.Mc()&&(b=wc);var g=this.adornedObject.naturalBounds,h=g.x,k=g.y,l=g.x+g.width,m=g.y+g.height,n=1;if(!f){n=g.width;var p=g.height;0>=n&&(n=1);0>=p&&(p=1);n=p/n}p=I.alloc();K.No(a.x,a.y,h,k,e.width,e.height,p);a=g.copy();0>=b.x?0>=b.y?(a.x=Math.max(p.x,l-d.width),a.x=Math.min(a.x,l-c.width),a.width=Math.max(l-a.x,c.width),a.y=Math.max(p.y,m-d.height),a.y=Math.min(a.y,m-c.height),a.height=Math.max(m-a.y,c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*
a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width),a.x=l-a.width,a.y=m-a.height)):1<=b.y?(a.x=Math.max(p.x,l-d.width),a.x=Math.min(a.x,l-c.width),a.width=Math.max(l-a.x,c.width),a.height=Math.max(Math.min(p.y-k,d.height),c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width),a.x=l-a.width)):(a.x=
Math.max(p.x,l-d.width),a.x=Math.min(a.x,l-c.width),a.width=l-a.x,f||(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n,a.y=k+.5*(m-k-a.height))):1<=b.x?0>=b.y?(a.width=Math.max(Math.min(p.x-h,d.width),c.width),a.y=Math.max(p.y,m-d.height),a.y=Math.min(a.y,m-c.height),a.height=Math.max(m-a.y,c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width),
a.y=m-a.height)):1<=b.y?(a.width=Math.max(Math.min(p.x-h,d.width),c.width),a.height=Math.max(Math.min(p.y-k,d.height),c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width))):(a.width=Math.max(Math.min(p.x-h,d.width),c.width),f||(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n,a.y=k+.5*(m-k-a.height))):0>=b.y?(a.y=Math.max(p.y,m-d.height),
a.y=Math.min(a.y,m-c.height),a.height=m-a.y,f||(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width,a.x=h+.5*(l-h-a.width))):1<=b.y&&(a.height=Math.max(Math.min(p.y-k,d.height),c.height),f||(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width,a.x=h+.5*(l-h-a.width)));I.free(p);return a};gg.prototype.computeReshape=function(){var a=kg;this.adornedObject instanceof Bf&&(a=lg(this.adornedObject));return!(a===mg||this.diagram.lastInput.shift)};
gg.prototype.computeMinSize=function(){var a=this.adornedObject.minSize.copy(),b=this.minSize;!isNaN(b.width)&&b.width>a.width&&(a.width=b.width);!isNaN(b.height)&&b.height>a.height&&(a.height=b.height);return a};gg.prototype.computeMaxSize=function(){var a=this.adornedObject.maxSize.copy(),b=this.maxSize;!isNaN(b.width)&&b.width<a.width&&(a.width=b.width);!isNaN(b.height)&&b.height<a.height&&(a.height=b.height);return a};
gg.prototype.computeCellSize=function(){var a=new M(NaN,NaN),b=this.adornedObject.part;null!==b&&(b=b.resizeCellSize,!isNaN(b.width)&&0<b.width&&(a.width=b.width),!isNaN(b.height)&&0<b.height&&(a.height=b.height));b=this.cellSize;isNaN(a.width)&&!isNaN(b.width)&&0<b.width&&(a.width=b.width);isNaN(a.height)&&!isNaN(b.height)&&0<b.height&&(a.height=b.height);b=this.diagram;(isNaN(a.width)||isNaN(a.height))&&b&&(b=b.grid,null!==b&&b.visible&&this.isGridSnapEnabled&&(b=b.gridCellSize,isNaN(a.width)&&
!isNaN(b.width)&&0<b.width&&(a.width=b.width),isNaN(a.height)&&!isNaN(b.height)&&0<b.height&&(a.height=b.height)));if(isNaN(a.width)||0===a.width||Infinity===a.width)a.width=1;if(isNaN(a.height)||0===a.height||Infinity===a.height)a.height=1;return a};
ma.Object.defineProperties(gg.prototype,{handleArchetype:{get:function(){return this.F},set:function(a){this.F=a}},handle:{get:function(){return this.u},set:function(a){if(null!==a&&!(a.part instanceof pe))throw Error("new handle is not in an Adornment: "+a);this.u=a}},adornedObject:{get:function(){return this.ue},set:function(a){if(null!==a&&a.part instanceof pe)throw Error("new handle must not be in an Adornment: "+
a);this.ue=a}},minSize:{get:function(){return this.o},set:function(a){if(!this.o.D(a)){var b=a.width;isNaN(b)&&(b=0);a=a.height;isNaN(a)&&(a=0);this.o.h(b,a)}}},maxSize:{get:function(){return this.j},set:function(a){if(!this.j.D(a)){var b=a.width;isNaN(b)&&(b=Infinity);a=a.height;isNaN(a)&&(a=Infinity);this.j.h(b,a)}}},cellSize:{get:function(){return this.yg},set:function(a){this.yg.D(a)||this.yg.assign(a)}},
isGridSnapEnabled:{get:function(){return this.V},set:function(a){this.V=a}},dragsMembers:{get:function(){return this.ia},set:function(a){this.ia=a}},oppositePoint:{get:function(){return this.Xq},set:function(a){this.Xq.D(a)||this.Xq.assign(a)}},originalDesiredSize:{get:function(){return this.Ew}},originalLocation:{get:function(){return this.Tn}}});
gg.className="ResizingTool";Ka("resizingTool",function(){return this.findTool("Resizing")},function(a){this.gb("Resizing",a,this.mouseDownTools)});function ng(){ke.call(this);this.name="Rotating";this.ia=45;this.V=2;this.Tn=new I;this.ue=null;var a=new Bf;a.figure="Ellipse";a.desiredSize=Ib;a.fill="lightblue";a.stroke="dodgerblue";a.strokeWidth=1;a.cursor="pointer";this.o=a;this.j=null;this.Dw=0;this.kr=new I(NaN,NaN);this.u=0;this.F=50}la(ng,ke);
ng.prototype.updateAdornments=function(a){if(null!==a){if(a.ph()){var b=a.rotateObject;if(b===a||b===a.path||b.isPanelMain)return}if(a.isSelected&&!this.diagram.isReadOnly&&(b=a.rotateObject,null!==b&&a.canRotate()&&a.actualBounds.w()&&a.isVisible()&&b.actualBounds.w()&&b.Ed())){var c=a.Oj(this.name);if(null===c||c.adornedObject!==b)c=this.makeAdornment(b);if(null!==c){c.angle=b.li();null===c.placeholder&&(c.location=this.computeAdornmentLocation(b));a.bh(this.name,c);return}}a.Jf(this.name)}};
ng.prototype.makeAdornment=function(a){var b=a.part.rotateAdornmentTemplate;if(null===b){b=new pe;b.type=W.Position;b.locationSpot=wc;var c=this.handleArchetype;null!==c&&b.add(c.copy())}else if(jg(b),b=b.copy(),null===b)return null;b.adornedObject=a;return b};ng.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.isReadOnly&&a.allowRotate&&a.lastInput.left?null!==this.findToolHandleAt(a.firstInput.documentPoint,this.name):!1};
ng.prototype.doActivate=function(){var a=this.diagram;if(null===this.adornedObject){null===this.handle&&(this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name));if(null===this.handle)return;this.adornedObject=this.handle.part.adornedObject}null!==this.adornedObject&&(a.isMouseCaptured=!0,a.delaysLayout=!0,this.Ea(this.name),this.Dw=this.adornedObject.angle,this.kr=this.computeRotationPoint(this.adornedObject),this.Tn=this.adornedObject.part.location.copy(),this.isActive=!0)};
ng.prototype.computeRotationPoint=function(a){var b=a.part,c=b.locationObject;return b.rotationSpot.sb()?a.ga(b.rotationSpot):a===b||a===c?c.ga(b.locationSpot):a.ga(wc)};
ng.prototype.computeAdornmentLocation=function(a){var b=this.rotationPoint;b.w()||(b=this.computeRotationPoint(a));b=a.qs(b);var c=this.handleAngle;0>c?c+=360:360<=c&&(c-=360);c=Math.round(45*Math.round(c/45));var d=this.handleDistance;0===c?b.x=a.naturalBounds.width+d:45===c?(b.x=a.naturalBounds.width+d,b.y=a.naturalBounds.height+d):90===c?b.y=a.naturalBounds.height+d:135===c?(b.x=-d,b.y=a.naturalBounds.height+d):180===c?b.x=-d:225===c?(b.x=-d,b.y=-d):270===c?b.y=-d:315===c&&(b.x=a.naturalBounds.width+
d,b.y=-d);return a.ga(b)};ng.prototype.doDeactivate=function(){var a=this.diagram;this.stopTransaction();this.ue=this.handle=null;this.kr=new I(NaN,NaN);this.isActive=a.isMouseCaptured=!1};ng.prototype.stopTransaction=function(){var a=this.diagram,b=ke.prototype.stopTransaction.call(this);b&&null!==a.undoManager.transactionToUndo&&a.undoManager.transactionToUndo.$j();return b};ng.prototype.doCancel=function(){this.diagram.delaysLayout=!1;this.rotate(this.originalAngle);this.stopTool()};
ng.prototype.doMouseMove=function(){var a=this.diagram;this.isActive&&(a=this.computeRotate(a.lastInput.documentPoint),this.rotate(a))};ng.prototype.doMouseUp=function(){var a=this.diagram;if(this.isActive){a.delaysLayout=!1;var b=this.computeRotate(a.lastInput.documentPoint);this.rotate(b);a.Ya();this.transactionResult=this.name;a.S("PartRotated",this.adornedObject,this.originalAngle)}this.stopTool()};
ng.prototype.rotate=function(a){var b=this.adornedObject;if(null!==b){b.angle=a;b=b.part;b.Va();var c=b.locationObject,d=b.rotateObject;if(c===d||c.rg(d))c=this.Tn.copy(),b.location=c.re(this.rotationPoint).rotate(a-this.originalAngle).add(this.rotationPoint);this.diagram.bd()}};
ng.prototype.computeRotate=function(a){a=this.rotationPoint.ab(a)-this.handleAngle;var b=this.adornedObject.panel;null!==b&&(a-=b.li());360<=a?a-=360:0>a&&(a+=360);b=Math.min(Math.abs(this.snapAngleMultiple),180);var c=Math.min(Math.abs(this.snapAngleEpsilon),b/2);!this.diagram.lastInput.shift&&0<b&&0<c&&(a%b<c?a=Math.floor(a/b)*b:a%b>b-c&&(a=(Math.floor(a/b)+1)*b));360<=a?a-=360:0>a&&(a+=360);return a};
ma.Object.defineProperties(ng.prototype,{handleArchetype:{get:function(){return this.o},set:function(a){this.o=a}},handle:{get:function(){return this.j},set:function(a){if(null!==a&&!(a.part instanceof pe))throw Error("new handle is not in an Adornment: "+a);this.j=a}},adornedObject:{get:function(){return this.ue},set:function(a){if(null!==a&&a.part instanceof pe)throw Error("new handle must not be in an Adornment: "+
a);this.ue=a}},snapAngleMultiple:{get:function(){return this.ia},set:function(a){this.ia=a}},snapAngleEpsilon:{get:function(){return this.V},set:function(a){this.V=a}},originalAngle:{get:function(){return this.Dw}},rotationPoint:{get:function(){return this.kr},set:function(a){this.kr=a.copy()}},handleAngle:{get:function(){return this.u},set:function(a){this.u=
a}},handleDistance:{get:function(){return this.F},set:function(a){this.F=a}}});ng.className="RotatingTool";Ka("rotatingTool",function(){return this.findTool("Rotating")},function(a){this.gb("Rotating",a,this.mouseDownTools)});function og(){ke.call(this);this.name="ClickSelecting"}la(og,ke);og.prototype.canStart=function(){return!this.isEnabled||this.isBeyondDragSize()?!1:!0};
og.prototype.doMouseUp=function(){this.isActive&&(this.standardMouseSelect(),!this.standardMouseClick()&&this.diagram.lastInput.isTouchEvent&&this.diagram.toolManager.doToolTip());this.stopTool()};og.className="ClickSelectingTool";function pg(){ke.call(this);this.name="Action";this.nk=null}la(pg,ke);
pg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram,b=a.lastInput,c=a.jc(b.documentPoint,function(a){for(;null!==a.panel&&!a.isActionable;)a=a.panel;return a});if(null!==c){if(!c.isActionable)return!1;this.nk=c;a.Bk=a.jc(b.documentPoint,null,null);return!0}return!1};pg.prototype.doMouseDown=function(){if(this.isActive){var a=this.diagram.lastInput,b=this.nk;null!==b&&(a.targetObject=b,null!==b.actionDown&&b.actionDown(a,b))}else this.canStart()&&this.doActivate()};
pg.prototype.doMouseMove=function(){if(this.isActive){var a=this.diagram.lastInput,b=this.nk;null!==b&&(a.targetObject=b,null!==b.actionMove&&b.actionMove(a,b))}};pg.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram.lastInput,b=this.nk;if(null===b)return;a.targetObject=b;null!==b.actionUp&&b.actionUp(a,b);this.standardMouseClick(function(a){for(;null!==a.panel&&(!a.isActionable||a!==b);)a=a.panel;return a},function(a){return a===b})}this.stopTool()};
pg.prototype.doCancel=function(){var a=this.diagram.lastInput,b=this.nk;null!==b&&(a.targetObject=b,null!==b.actionCancel&&b.actionCancel(a,b),this.stopTool())};pg.prototype.doStop=function(){this.nk=null};pg.className="ActionTool";function qg(){ke.call(this);this.name="ClickCreating";this.Ri=null;this.o=!0;this.j=!1;this.nw=new I(0,0)}la(qg,ke);
qg.prototype.canStart=function(){if(!this.isEnabled||null===this.archetypeNodeData)return!1;var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.lastInput.left||this.isBeyondDragSize())return!1;if(this.isDoubleClick){if(1===a.lastInput.clickCount&&(this.nw=a.lastInput.viewPoint.copy()),2!==a.lastInput.clickCount||this.isBeyondDragSize(this.nw))return!1}else if(1!==a.lastInput.clickCount)return!1;return a.currentTool!==this&&null!==a.Ol(a.lastInput.documentPoint,!0)?!1:!0};
qg.prototype.doMouseUp=function(){var a=this.diagram;this.isActive&&this.insertPart(a.lastInput.documentPoint);this.stopTool()};
qg.prototype.insertPart=function(a){var b=this.diagram,c=this.archetypeNodeData;if(null===c)return null;var d=null;try{b.S("ChangingSelection",b.selection);this.Ea(this.name);if(c instanceof U)c.Zc()&&(jg(c),d=c.copy(),null!==d&&b.add(d));else if(null!==c){var e=b.model.copyNodeData(c);za(e)&&(b.model.xf(e),d=b.Lc(e))}if(null!==d){var f=I.allocAt(a.x,a.y);this.isGridSnapEnabled&&rg(this.diagram,d,a,f);d.location=f;b.allowSelect&&(b.clearSelection(!0),d.isSelected=!0);I.free(f)}b.Ya();this.transactionResult=
this.name;b.S("PartCreated",d)}finally{this.stopTransaction(),b.S("ChangedSelection",b.selection)}return d};ma.Object.defineProperties(qg.prototype,{archetypeNodeData:{get:function(){return this.Ri},set:function(a){this.Ri=a}},isDoubleClick:{get:function(){return this.o},set:function(a){this.o=a}},isGridSnapEnabled:{get:function(){return this.j},set:function(a){this.j=a}}});qg.className="ClickCreatingTool";
function sg(){ke.call(this);this.name="DragSelecting";this.Hk=175;this.o=!1;var a=new U;a.layerName="Tool";a.selectable=!1;var b=new Bf;b.name="SHAPE";b.figure="Rectangle";b.fill=null;b.stroke="magenta";a.add(b);this.j=a}la(sg,ke);
sg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(!a.allowSelect)return!1;var b=a.lastInput;return!b.left||a.currentTool!==this&&(!this.isBeyondDragSize()||b.timestamp-a.firstInput.timestamp<this.delay||null!==a.Ol(b.documentPoint,!0))?!1:!0};sg.prototype.doActivate=function(){var a=this.diagram;this.isActive=!0;a.isMouseCaptured=!0;a.skipsUndoManager=!0;a.add(this.box);this.doMouseMove()};
sg.prototype.doDeactivate=function(){var a=this.diagram;a.ug();a.remove(this.box);a.skipsUndoManager=!1;this.isActive=a.isMouseCaptured=!1};sg.prototype.doMouseMove=function(){var a=this.diagram;if(this.isActive&&null!==this.box){var b=this.computeBoxBounds(),c=this.box.fb("SHAPE");null===c&&(c=this.box.Qb());var d=M.alloc().h(b.width,b.height);c.desiredSize=d;this.box.mm(b.x,b.y,!1);M.free(d);(a.allowHorizontalScroll||a.allowVerticalScroll)&&a.gs(a.lastInput.viewPoint)}};
sg.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram;a.remove(this.box);try{a.currentCursor="wait",a.S("ChangingSelection",a.selection),this.selectInRect(this.computeBoxBounds()),a.S("ChangedSelection",a.selection)}finally{a.currentCursor=""}}this.stopTool()};sg.prototype.computeBoxBounds=function(){var a=this.diagram;return new N(a.firstInput.documentPoint,a.lastInput.documentPoint)};
sg.prototype.selectInRect=function(a){var b=this.diagram,c=b.lastInput;a=b.vx(a,this.isPartialInclusion);if(Pa?c.meta:c.control)if(c.shift)for(a=a.iterator;a.next();)b=a.value,b.isSelected&&(b.isSelected=!1);else for(a=a.iterator;a.next();)b=a.value,b.isSelected=!b.isSelected;else if(c.shift)for(a=a.iterator;a.next();)b=a.value,b.isSelected||(b.isSelected=!0);else{c=new F;for(b=b.selection.iterator;b.next();){var d=b.value;a.contains(d)||c.add(d)}for(b=c.iterator;b.next();)b.value.isSelected=!1;for(a=
a.iterator;a.next();)b=a.value,b.isSelected||(b.isSelected=!0)}};ma.Object.defineProperties(sg.prototype,{delay:{get:function(){return this.Hk},set:function(a){this.Hk=a}},isPartialInclusion:{get:function(){return this.o},set:function(a){this.o=a}},box:{get:function(){return this.j},set:function(a){this.j=a}}});sg.className="DragSelectingTool";
function tg(){ke.call(this);this.name="Panning";this.Xt=new I;this.yy=new I;this.xg=!1;var a=this;this.Lw=function(){var b=a.diagram;null!==b&&b.removeEventListener(x.document,"scroll",a.Lw,!1);a.stopTool()}}la(tg,ke);tg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.allowHorizontalScroll&&!a.allowVerticalScroll||!a.lastInput.left||a.currentTool!==this&&!this.isBeyondDragSize()?!1:!0};
tg.prototype.doActivate=function(){var a=this.diagram;this.xg?(a.lastInput.bubbles=!0,a.addEventListener(x.document,"scroll",this.Lw,!1)):(a.currentCursor="move",a.isMouseCaptured=!0,this.Xt.assign(a.position));this.isActive=!0};tg.prototype.doDeactivate=function(){var a=this.diagram;a.currentCursor="";this.isActive=a.isMouseCaptured=!1};tg.prototype.doCancel=function(){var a=this.diagram;a.position=this.Xt;a.isMouseCaptured=!1;this.stopTool()};tg.prototype.doMouseMove=function(){this.move()};
tg.prototype.doMouseUp=function(){this.move();this.stopTool()};tg.prototype.move=function(){var a=this.diagram;if(this.isActive&&a)if(this.xg)a.lastInput.bubbles=!0;else{var b=a.position,c=a.firstInput.documentPoint,d=a.lastInput.documentPoint,e=b.x+c.x-d.x;c=b.y+c.y-d.y;a.allowHorizontalScroll||(e=b.x);a.allowVerticalScroll||(c=b.y);a.position=this.yy.h(e,c)}};
ma.Object.defineProperties(tg.prototype,{bubbles:{get:function(){return this.xg},set:function(a){this.xg=a}},originalPosition:{get:function(){return this.Xt}}});tg.className="PanningTool";Ka("clickCreatingTool",function(){return this.findTool("ClickCreating")},function(a){this.gb("ClickCreating",a,this.mouseUpTools)});Ka("clickSelectingTool",function(){return this.findTool("ClickSelecting")},function(a){this.gb("ClickSelecting",a,this.mouseUpTools)});
Ka("panningTool",function(){return this.findTool("Panning")},function(a){this.gb("Panning",a,this.mouseMoveTools)});Ka("dragSelectingTool",function(){return this.findTool("DragSelecting")},function(a){this.gb("DragSelecting",a,this.mouseMoveTools)});Ka("actionTool",function(){return this.findTool("Action")},function(a){this.gb("Action",a,this.mouseDownTools)});function Be(){this.F=this.u=this.j=this.o=null}
ma.Object.defineProperties(Be.prototype,{mainElement:{get:function(){return this.u},set:function(a){this.u=a}},show:{get:function(){return this.o},set:function(a){this.o!==a&&(this.o=a)}},hide:{get:function(){return this.j},set:function(a){this.j!==a&&(this.j=a)}},valueFunction:{get:function(){return this.F},set:function(a){this.F=a}}});Be.className="HTMLInfo";
function ug(a,b,c){this.text=a;this.ix=b;this.visible=c}ug.className="ContextMenuButtonInfo";function vg(){ke.call(this);this.name="ContextMenu";this.o=this.ht=this.j=null;this.zw=new I;this.jt=null;this.Et=!1;var a=this;this.tu=function(){a.stopTool()}}la(vg,ke);
function wg(a){var b=new Be;b.show=function(a,b,c){c.showDefaultContextMenu()};b.hide=function(a,b){b.hideDefaultContextMenu()};xg=b;a.tu=function(){a.stopTool()};b=ua("div");var c=ua("div");b.style.cssText="top: 0px;z-index:10002;position: fixed;display: none;text-align: center;left: 25%;width: 50%;background-color: #F5F5F5;padding: 16px;border: 16px solid #444;border-radius: 10px;margin-top: 10px";c.style.cssText="z-index:10001;position: fixed;display: none;top: 0;left: 0;width: 100%;height: 100%;background-color: black;opacity: 0.8;";
var d=ua("style");x.document.getElementsByTagName("head")[0].appendChild(d);d.sheet.insertRule(".goCXul { list-style: none; }",0);d.sheet.insertRule(".goCXli {font:700 1.5em Helvetica, Arial, sans-serif;position: relative;min-width: 60px; }",0);d.sheet.insertRule(".goCXa {color: #444;display: inline-block;padding: 4px;text-decoration: none;margin: 2px;border: 1px solid gray;border-radius: 10px; }",0);d=a.diagram;null!==d&&(d.addEventListener(b,"contextmenu",yg,!1),d.addEventListener(b,"selectstart",
yg,!1),d.addEventListener(c,"contextmenu",yg,!1));b.className="goCXforeground";c.className="goCXbackground";x.document.body&&(x.document.body.appendChild(b),x.document.body.appendChild(c));zg=b;Ag=c;Bg=!0}function yg(a){a.preventDefault();return!1}
vg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return this.isBeyondDragSize()||!a.lastInput.right||1<a.lastInput.clickCount?!1:a.lastInput.isTouchEvent&&null!==this.defaultTouchContextMenu||null!==this.findObjectWithContextMenu()?!0:!1};vg.prototype.doStart=function(){this.zw.set(this.diagram.firstInput.documentPoint)};vg.prototype.doStop=function(){this.hideContextMenu();this.currentObject=null};
vg.prototype.findObjectWithContextMenu=function(a){void 0===a&&(a=null);var b=this.diagram,c=b.lastInput,d=null;a instanceof Q||(a instanceof Y?d=a:d=b.jc(c.documentPoint,null,function(a){return!a.layer.isTemporary}));if(null!==d){for(a=d;null!==a;){if(null!==a.contextMenu)return a;a=a.panel}if(b.lastInput.isTouchEvent&&this.defaultTouchContextMenu)return d.part}else if(null!==b.contextMenu)return b;return null};vg.prototype.doActivate=function(){};
vg.prototype.doMouseDown=function(){ke.prototype.doMouseDown.call(this);if(this.isActive&&this.currentContextMenu instanceof pe){var a=this.diagram.toolManager.findTool("Action");null!==a&&a.canStart()&&(a.doActivate(),a.doMouseDown(),a.doDeactivate())}this.diagram.toolManager.mouseDownTools.contains(this)&&Ig(this)};
vg.prototype.doMouseUp=function(){if(this.isActive&&this.currentContextMenu instanceof pe){var a=this.diagram.toolManager.findTool("Action");null!==a&&a.canStart()&&(a.doActivate(),a.doCancel(),a.doDeactivate())}Ig(this)};
function Ig(a){var b=a.diagram;if(a.isActive){var c=a.currentContextMenu;if(null!==c){var d=null;c instanceof Be||(d=b.jc(b.lastInput.documentPoint,null,null),null!==d&&d.rg(c)&&a.standardMouseClick(null,null));a.maybeStopTool(d)}}else a.canStart()&&(Jg(a,!0),a.isActive||a.stopTool())}vg.prototype.maybeStopTool=function(){this.stopTool();this.canStart()&&(this.diagram.currentTool=this,this.doMouseUp())};
function Jg(a,b,c){void 0===c&&(c=null);if(!a.Et&&(a.Et=!0,b&&a.standardMouseSelect(),b=a.standardMouseClick(),a.Et=!1,!b)){a.isActive=!0;b=xg;null===c&&(c=a.findObjectWithContextMenu());if(null!==c){var d=c.contextMenu;null!==d?(a.currentObject=c instanceof Y?c:null,a.showContextMenu(d,a.currentObject)):null!==b&&a.showContextMenu(b,a.currentObject)}else null!==b&&a.showContextMenu(b,null);a.currentContextMenu instanceof pe&&!a.currentContextMenu.visible&&a.stopTool()}}
vg.prototype.doMouseMove=function(){var a=this.diagram.toolManager.findTool("Action");null!==a&&a.doMouseMove();this.isActive&&this.diagram.toolManager.doMouseMove()};
vg.prototype.showContextMenu=function(a,b){var c=this.diagram;a!==this.currentContextMenu&&this.hideContextMenu();if(a instanceof pe){a.layerName="Tool";a.selectable=!1;a.scale=1/c.scale;a.category=this.name;null!==a.placeholder&&(a.placeholder.scale=c.scale);var d=a.diagram;null!==d&&d!==c&&d.remove(a);c.add(a);null!==b?a.adornedObject=b:a.data=c.model;a.Va();this.positionContextMenu(a,b)}else a instanceof Be&&a.show(b,c,this);this.currentContextMenu=a};
vg.prototype.positionContextMenu=function(a){if(null===a.placeholder){var b=this.diagram,c=b.lastInput.documentPoint.copy(),d=a.measuredBounds,e=b.viewportBounds;b.lastInput.isTouchEvent&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5/b.scale);c.x<e.x&&(c.x=e.x);c.y+d.height>e.bottom&&(c.y-=d.height+5/b.scale);c.y<e.y&&(c.y=e.y);a.position=c}};
vg.prototype.hideContextMenu=function(){var a=this.diagram,b=this.currentContextMenu;null!==b&&(b instanceof pe?(a.remove(b),null!==this.ht&&this.ht.Jf(b.category),b.data=null,b.adornedObject=null):b instanceof Be&&(null!==b.hide?b.hide(a,this):null!==b.mainElement&&(b.mainElement.style.display="none")),this.currentContextMenu=null,this.standardMouseOver())};
function Kg(a){var b=new F;b.add(new ug("Copy",function(a){a.commandHandler.copySelection()},function(a){return a.commandHandler.canCopySelection()}));b.add(new ug("Cut",function(a){a.commandHandler.cutSelection()},function(a){return a.commandHandler.canCutSelection()}));b.add(new ug("Delete",function(a){a.commandHandler.deleteSelection()},function(a){return a.commandHandler.canDeleteSelection()}));b.add(new ug("Paste",function(b){b.commandHandler.pasteSelection(a.mouseDownPoint)},function(b){return b.commandHandler.canPasteSelection(a.mouseDownPoint)}));
b.add(new ug("Select All",function(a){a.commandHandler.selectAll()},function(a){return a.commandHandler.canSelectAll()}));b.add(new ug("Undo",function(a){a.commandHandler.undo()},function(a){return a.commandHandler.canUndo()}));b.add(new ug("Redo",function(a){a.commandHandler.redo()},function(a){return a.commandHandler.canRedo()}));b.add(new ug("Scroll To Part",function(a){a.commandHandler.scrollToPart()},function(a){return a.commandHandler.canScrollToPart()}));b.add(new ug("Zoom To Fit",function(a){a.commandHandler.zoomToFit()},
function(a){return a.commandHandler.canZoomToFit()}));b.add(new ug("Reset Zoom",function(a){a.commandHandler.resetZoom()},function(a){return a.commandHandler.canResetZoom()}));b.add(new ug("Group Selection",function(a){a.commandHandler.groupSelection()},function(a){return a.commandHandler.canGroupSelection()}));b.add(new ug("Ungroup Selection",function(a){a.commandHandler.ungroupSelection()},function(a){return a.commandHandler.canUngroupSelection()}));b.add(new ug("Edit Text",function(a){a.commandHandler.editTextBlock()},
function(a){return a.commandHandler.canEditTextBlock()}));return b}
vg.prototype.showDefaultContextMenu=function(){var a=this.diagram;null===this.jt&&(this.jt=Kg(this));zg.innerHTML="";Ag.addEventListener("pointerdown",this.tu,!1);var b=this,c=ua("ul");c.className="goCXul";zg.appendChild(c);c.innerHTML="";for(var d=this.jt.iterator;d.next();){var e=d.value,f=e.visible;if("function"===typeof e.ix&&("function"!==typeof f||f(a))){f=ua("li");f.className="goCXli";var g=ua("a");g.className="goCXa";g.href="#";g.ly=e.ix;g.addEventListener("pointerdown",function(c){this.ly(a);
b.stopTool();c.preventDefault();return!1},!1);g.textContent=e.text;f.appendChild(g);c.appendChild(f)}}zg.style.display="block";Ag.style.display="block"};vg.prototype.hideDefaultContextMenu=function(){if(null!==this.currentContextMenu&&this.currentContextMenu===xg){zg.style.display="none";Ag.style.display="none";var a=this.diagram;null!==a&&a.removeEventListener(Ag,"pointerdown",this.tu,!1);this.currentContextMenu=null}};
ma.Object.defineProperties(vg.prototype,{currentContextMenu:{get:function(){return this.j},set:function(a){this.j=a;this.ht=a instanceof pe?a.adornedPart:null}},defaultTouchContextMenu:{get:function(){!1===Bg&&null===xg&&Lg&&wg(this);return xg},set:function(a){null===a&&(Bg=!0);xg=a}},currentObject:{get:function(){return this.o},set:function(a){this.o=a}},mouseDownPoint:{get:function(){return this.zw}}});
var xg=null,Bg=!1,Ag=null,zg=null;vg.className="ContextMenuTool";Ka("contextMenuTool",function(){return this.findTool("ContextMenu")},function(a){this.gb("ContextMenu",a,this.mouseUpTools)});function Mg(){ke.call(this);this.name="TextEditing";this.Zh=new Ng;this.ia=null;this.V=Og;this.vj=null;this.qa=Pg;this.u=1;this.F=!0;this.o=null;this.j=new Be;this.kt=null;Qg(this,this.j)}la(Mg,ke);
function Qg(a,b){if(Lg){var c=ua("textarea");a.kt=c;c.addEventListener("input",function(){if(null!==a.textBlock){var b=a.Mx(this.value);this.style.width=20+b.measuredBounds.width*this.xA+"px";this.rows=b.lineCount}},!1);c.addEventListener("keydown",function(b){if(!b.isComposing&&null!==a.textBlock){var c=b.key;"Enter"===c?(!1===a.textBlock.isMultiline&&b.preventDefault(),a.acceptText(Rg)):"Tab"===c?(a.acceptText(Sg),b.preventDefault()):"Escape"===c&&(a.doCancel(),null!==a.diagram&&a.diagram.doFocus())}},
!1);c.addEventListener("focus",function(){if(null!==a.currentTextEditor&&a.state!==Pg){var b=a.kt;a.qa===Tg&&(a.qa=Ug);"function"===typeof b.select&&a.selectsTextOnActivate&&(b.select(),b.setSelectionRange(0,9999))}},!1);c.addEventListener("blur",function(){if(null!==a.currentTextEditor&&a.state!==Pg){var b=a.kt;"function"===typeof b.focus&&b.focus();"function"===typeof b.select&&a.selectsTextOnActivate&&(b.select(),b.setSelectionRange(0,9999))}},!1);b.valueFunction=function(){return c.value};b.mainElement=
c;b.show=function(a,b,f){if(a instanceof Ng&&f instanceof Mg)if(f.state===Vg)c.style.border="3px solid red",c.focus();else{var d=a.ga(wc),e=b.position,k=b.scale,l=a.Df()*k;l<f.minimumEditorScale&&(l=f.minimumEditorScale);var m=a.naturalBounds.width*l+6,n=a.naturalBounds.height*l+2,p=(d.x-e.x)*k;d=(d.y-e.y)*k;e=a.verticalAlignment;k=(a.lineHeight+a.spacingAbove+a.spacingBelow)*a.lineCount*l;n=e.y*n-e.y*k+e.offsetY-(.5*n-.5*k)-k/2;c.value=a.text;b.div.style.font=a.font;c.style.position="absolute";c.style.zIndex=
"100";c.style.font="inherit";c.style.fontSize=100*l+"%";c.style.lineHeight="normal";c.style.width=m+"px";c.style.left=(p-m/2|0)-1+"px";c.style.top=(d+n|0)-1+"px";c.style.textAlign=a.textAlign;c.style.margin="0";c.style.padding="1px";c.style.border="0";c.style.outline="none";c.style.whiteSpace="pre-wrap";c.style.overflow="hidden";c.rows=a.lineCount;c.xA=l;c.className="goTXarea";b.div.appendChild(c);c.focus();f.selectsTextOnActivate&&(c.select(),c.setSelectionRange(0,9999))}};b.hide=function(a){a.div.removeChild(c)}}}
Mg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(null===a||a.isReadOnly||Wg&&Wg!==this&&(Wg.acceptText(Xg),Wg&&Wg!==this)||!a.lastInput.left||this.isBeyondDragSize())return!1;var b=a.jc(a.lastInput.documentPoint);if(!(null!==b&&b instanceof Ng&&b.editable&&b.part.canEdit()))return!1;b=b.part;return null===b||this.starting===Og&&!b.isSelected||this.starting===Yg&&2>a.lastInput.clickCount?!1:!0};Mg.prototype.doStart=function(){Wg=this;null!==this.textBlock&&this.doActivate()};
Mg.prototype.doActivate=function(){if(!this.isActive){var a=this.diagram;if(null!==a){var b=this.textBlock;null===b&&(b=a.jc(a.lastInput.documentPoint));if(null!==b&&b instanceof Ng&&(this.textBlock=b,null!==b.part)){a.animationManager.Cc();this.isActive=!0;this.qa=Tg;var c=this.defaultTextEditor;null!==b.textEditor&&(c=b.textEditor);this.Zh=this.textBlock.copy();var d=new N(this.textBlock.ga(sc),this.textBlock.ga(Bc));a.uv(d);c.show(b,a,this);this.currentTextEditor=c}}}};Mg.prototype.doCancel=function(){this.stopTool()};
Mg.prototype.doMouseUp=function(){this.canStart()&&this.doActivate()};Mg.prototype.doMouseDown=function(){this.isActive&&this.acceptText(Xg)};Mg.prototype.acceptText=function(a){switch(a){case Xg:if(this.qa===Zg)this.currentTextEditor instanceof HTMLElement&&this.currentTextEditor.focus();else if(this.qa===Tg||this.qa===Vg||this.qa===Ug)this.qa=$g,ah(this);break;case bh:case Rg:case Sg:if(Rg!==a||!0!==this.textBlock.isMultiline)if(this.qa===Tg||this.qa===Vg||this.qa===Ug)this.qa=$g,ah(this)}};
function ah(a){var b=a.textBlock,c=a.diagram,d=a.currentTextEditor;if(null!==b&&null!==d){var e=b.text,f="";null!==d.valueFunction&&(f=d.valueFunction());a.isValidText(b,e,f)?(a.Ea(a.name),a.qa=Zg,a.transactionResult=a.name,b.text=f,a.doSuccess(e,f),null!==c&&c.S("TextEdited",b,e),a.stopTransaction(),a.stopTool(),null!==c&&c.doFocus()):(a.qa=Vg,a.doError(e,f))}}
Mg.prototype.doError=function(a,b){var c=this.textBlock;null!==c.errorFunction&&c.errorFunction(this,a,b);(a=this.currentTextEditor)&&a.show(c,this.diagram,this)};Mg.prototype.doSuccess=function(a,b){var c=this.textBlock;null!==c.textEdited&&c.textEdited(c,a,b)};Mg.prototype.doDeactivate=function(){var a=this.diagram;null!==a&&(this.qa=Pg,null!==this.currentTextEditor&&this.currentTextEditor.hide(a,this),this.textBlock=null,this.isActive=!1)};Mg.prototype.doStop=function(){Wg=null};
Mg.prototype.isValidText=function(a,b,c){var d=this.textValidation;if(null!==d&&!d(a,b,c))return!1;d=a.textValidation;return null===d||d(a,b,c)?!0:!1};Mg.prototype.Mx=function(a){var b=this.Zh;b.text=a;b.measure(this.textBlock.Ih,Infinity);return b};
ma.Object.defineProperties(Mg.prototype,{textBlock:{get:function(){return this.ia},set:function(a){this.ia=a}},currentTextEditor:{get:function(){return this.o},set:function(a){this.o=a}},defaultTextEditor:{get:function(){return this.j},set:function(a){this.j=a}},starting:{get:function(){return this.V},set:function(a){this.V=a}},textValidation:{get:function(){return this.vj},
set:function(a){this.vj=a}},minimumEditorScale:{get:function(){return this.u},set:function(a){this.u=a}},selectsTextOnActivate:{get:function(){return this.F},set:function(a){this.F=a}},state:{get:function(){return this.qa},set:function(a){this.qa!==a&&(this.qa=a)}}});Mg.prototype.measureTemporaryTextBlock=Mg.prototype.Mx;
var bh=new D(Mg,"LostFocus",0),Xg=new D(Mg,"MouseDown",1),Sg=new D(Mg,"Tab",2),Rg=new D(Mg,"Enter",3),ch=new D(Mg,"SingleClick",0),Og=new D(Mg,"SingleClickSelected",1),Yg=new D(Mg,"DoubleClick",2),Pg=new D(Mg,"StateNone",0),Tg=new D(Mg,"StateActive",1),Ug=new D(Mg,"StateEditing",2),$g=new D(Mg,"StateValidating",3),Vg=new D(Mg,"StateInvalid",4),Zg=new D(Mg,"StateValidated",5),Wg=null;Mg.className="TextEditingTool";Mg.LostFocus=bh;Mg.MouseDown=Xg;Mg.Tab=Sg;Mg.Enter=Rg;Mg.SingleClick=ch;
Mg.SingleClickSelected=Og;Mg.DoubleClick=Yg;Mg.StateNone=Pg;Mg.StateActive=Tg;Mg.StateEditing=Ug;Mg.StateValidating=$g;Mg.StateInvalid=Vg;Mg.StateValidated=Zg;Ka("textEditingTool",function(){return this.findTool("TextEditing")},function(a){this.gb("TextEditing",a,this.mouseUpTools)});
function dh(a){eh||(fh(),eh=!0);this.H=le;this.Nk=this.Ab=this.vq=this.cc=!1;this.Rw=!0;this.Ok=gh;this.Dm=!1;this.Gh=this.Jd=!0;this.Dg=600;this.dw=this.Pw=!1;this.Zd=new H;this.ce=new hh;this.ce.Yd=this;this.Ei=new H;this.pu=new H;this.Sr=new H;this.pr=new H;a&&Object.assign(this,a)}dh.prototype.Xe=function(a){this.H=a};dh.prototype.canStart=function(){return!0};function ih(a,b){a.Jd&&a.canStart(b)&&(a.Zd.add(b),a.defaultAnimation.isAnimating&&a.Cc(),jh(a.H),a.Ab=!0)}
function kh(a){if(a.Jd&&a.Ab){var b=a.ce,c=a.H,d=a.Zd.contains("Model");d&&(a.Nk=!0,a.Ok===gh?(b.isViewportUnconstrained=!0,b.vc.clear(),b.add(c,"position",c.position.copy().offset(0,-200),c.position),b.add(c,"opacity",0,1)):a.Ok===lh&&b.vc.clear(),a.Rw=a.Ok===mh&&c.du.D(c.pa)?!0:!1,c.S("InitialAnimationStarting",a));d&&!a.Gh||0===b.vc.count?(a.Zd.clear(),a.Ab=!1,b.vc.clear(),nh(b,c),a.Nk=!1,c.N()):(a.Zd.clear(),c.kg=!1,d=b.vc.get(c),c.autoScale!==oh&&null!==d&&(delete d.start.scale,delete d.end.scale),
x.requestAnimationFrame(function(){!1===a.Ab||b.cc||(c.Gf("temporaryPixelRatio")&&Ie(c),ph(c),a.Ab=!1,b.start(),b.cc&&(qh(a),c.Ya(),rh(b,0),yf(c,!0),sh(a),c.S("AnimationStarting",a)))}))}}function th(a){return a.Zd.contains("Trigger")&&1===a.Zd.count}function uh(a,b,c,d){a.isTicking||th(a)||b instanceof T&&(null!==b.fromNode||null!==b.toNode)||a.ce.add(b,"position",c,d,!1)}t=dh.prototype;t.gv=function(a){return this.ce.gv(a)};t.Yu=function(a){return this.ce.Yu(a)};
function vh(a,b){function c(){0<e.Sr.count&&(d.addAll(e.Sr),e.Sr.clear(),e.cc=!0);if(!1!==e.cc&&0!==d.count){e.pu.addAll(d);for(var a=e.pu.iterator;a.next();){var b=a.value;if(!1!==b.cc){a:if(0<b.yl.count)var h=!0;else{for(h=b.vc.iterator;h.next();){var k=h.key;if(k instanceof Y&&null!==k.diagram||k instanceof Q){h=!0;break a}}h=!1}h?wh(b,!1):b.Wk=!0}}e.pu.clear();!1!==e.cc&&(qh(e),yf(e.H),sh(e));x.requestAnimationFrame(c)}}var d=a.Ei,e=a;a.cc?a.Sr.add(b):(a.cc=!0,d.add(b),x.requestAnimationFrame(function(){c()}))}
function xh(a){for(a=a.Ei.iterator;a.next();)a.value.Wk=!1}function qh(a){if(!a.vq){var b=a.H;a.Pw=b.skipsUndoManager;a.dw=b.skipsModelSourceBindings;b.skipsUndoManager=!0;b.skipsModelSourceBindings=!0;a.vq=!0}}function sh(a){var b=a.H;b.skipsUndoManager=a.Pw;b.skipsModelSourceBindings=a.dw;a.vq=!1}
t.Cc=function(a){var b=this.ce;!0===this.Ab&&(this.Nk=this.Ab=!1,this.Zd.clear(),0<b.vc.count&&this.H.Rb());if(this.cc){if(b.Ml(!0),b.vc.clear(),nh(b,null),!0===a)for(a=this.Ei.Fa(),b=0;b<a.length;b++)a[b].Ml(!0)}else b.vc.clear(),nh(b,this.H)};t.Ml=function(a){a===this.defaultAnimation&&this.defaultAnimation.vc.clear();this.Ei.remove(a);0===this.Ei.count&&(this.cc=!1,this.H.Rb());a===this.defaultAnimation&&(this.defaultAnimation.vc.clear(),this.H.S("AnimationFinished",this))};
t.Cj=function(a,b){this.Ab&&(this.Zd.contains("Expand Tree")||this.Zd.contains("Expand SubGraph"))&&(this.ce.Cj(a,b),yh(this,a))};t.Aj=function(a,b){this.Ab&&(this.Zd.contains("Collapse Tree")||this.Zd.contains("Collapse SubGraph"))&&(this.ce.Aj(a,b),zh(this.ce,b,"position",b.position,b.position),yh(this,a))};function Ah(a,b,c){!a.Ab||b.D(c)||th(a)||(a.H.pi||(b=c.copy()),zh(a.ce,a.H,"position",b,c))}function Bh(a,b,c){b.fd&&(b.Tg=c,a.pr.add(b))}
function yh(a,b){for(b=b.findLinksConnected();b.next();){var c=b.value;c.fd&&(c.Tg=c.points.copy(),a.pr.add(c))}}function Ch(a,b,c,d,e){null===a&&(a=[0,0,0,0]);null===b&&(b=[0,0,0,0]);var f=a[0],g=a[1],h=a[2];a=a[3];var k=b[0],l=b[1],m=b[2];b=b[3];if(0===h||100===h)f=k,g=l;else if(0===m||100===m)k=f,l=g;180<Math.abs(k-f)&&(k>f?f+=360:k+=360);f=e(c,f,k-f,d)%360;g=e(c,g,l-g,d);h=e(c,h,m-h,d);c=e(c,a,b-a,d);return"hsla("+f+", "+g+"%, "+h+"%, "+c+")"}
function fh(){function a(a,b,e,f,g,h){a.position=new I(f(g,b.x,e.x-b.x,h),f(g,b.y,e.y-b.y,h))}var b=new ob;b.add("position:diagram",a);b.add("position",a);b.add("position:part",function(a,b,e,f,g,h){g<h?a.mm(f(g,b.x,e.x-b.x,h),f(g,b.y,e.y-b.y,h),!1):a.position=new I(f(g,b.x,e.x-b.x,h),f(g,b.y,e.y-b.y,h))});b.add("location",function(a,b,e,f,g,h){g<h?a.mm(f(g,b.x,e.x-b.x,h),f(g,b.y,e.y-b.y,h),!0):a.location=new I(f(g,b.x,e.x-b.x,h),f(g,b.y,e.y-b.y,h))});b.add("position:placeholder",function(a,b,e,f,
g,h){g<h?a.mm(f(g,b.x,e.x-b.x,h),f(g,b.y,e.y-b.y,h),!1):a.position=new I(f(g,b.x,e.x-b.x,h),f(g,b.y,e.y-b.y,h))});b.add("position:nodeCollapse",function(a,b,e,f,g,h){var c=a.actualBounds,d=e.actualBounds;e=d.x+d.width/2-c.width/2;c=d.y+d.height/2-c.height/2;g<h?a.mm(f(g,b.x,e-b.x,h),f(g,b.y,c-b.y,h),!1):a.position=new I(f(g,b.x,e-b.x,h),f(g,b.y,c-b.y,h))});b.add("desiredSize",function(a,b,e,f,g,h){a.desiredSize=new N(f(g,b.width,e.width-b.width,h),f(g,b.height,e.height-b.height,h))});b.add("width",
function(a,b,e,f,g,h){a.width=f(g,b,e-b,h)});b.add("height",function(a,b,e,f,g,h){a.height=f(g,b,e-b,h)});b.add("fill",function(a,b,e,f,g,h){a.fill=Ch(b,e,g,h,f)});b.add("stroke",function(a,b,e,f,g,h){a.stroke=Ch(b,e,g,h,f)});b.add("strokeWidth",function(a,b,e,f,g,h){a.strokeWidth=f(g,b,e-b,h)});b.add("strokeDashOffset",function(a,b,e,f,g,h){a.strokeDashOffset=f(g,b,e-b,h)});b.add("background",function(a,b,e,f,g,h){a.background=Ch(b,e,g,h,f)});b.add("areaBackground",function(a,b,e,f,g,h){a.areaBackground=
Ch(b,e,g,h,f)});b.add("opacity",function(a,b,e,f,g,h){a.opacity=f(g,b,e-b,h)});b.add("scale",function(a,b,e,f,g,h){a.scale=f(g,b,e-b,h)});b.add("angle",function(a,b,e,f,g,h){a.angle=f(g,b,e-b,h)});Dh=b}
ma.Object.defineProperties(dh.prototype,{animationReasons:{get:function(){return this.Zd}},isEnabled:{get:function(){return this.Jd},set:function(a){(this.Jd=a)&&this.Ei.each(function(a){a.isAnimating||Infinity!==a.runCount||a.start()})}},duration:{get:function(){return this.Dg},set:function(a){1>a&&xa(a,">= 1",dh,"duration");this.Dg=a}},isAnimating:{get:function(){return this.cc}},
isTicking:{get:function(){return this.vq}},isInitial:{get:function(){return this.Gh},set:function(a){this.Gh=a}},defaultAnimation:{get:function(){return this.ce}},activeAnimations:{get:function(){return this.Ei}},initialAnimationStyle:{get:function(){return this.Ok},set:function(a){this.Ok=a}}});dh.prototype.stopAnimation=dh.prototype.Cc;
var Dh=null,eh=!1,gh=new D(dh,"Default",1),mh=new D(dh,"AnimateLocations",2),lh=new D(dh,"None",3);dh.className="AnimationManager";dh.defineAnimationEffect=function(a,b){eh||(fh(),eh=!0);Dh.add(a,b)};dh.Default=gh;dh.AnimateLocations=mh;dh.None=lh;
function hh(a){this.vu=this.Ow=this.Yd=this.H=null;this.Wk=this.cc=this.j=!1;this.cn=this.Qd=0;this.Lp=this.ot=Eh;this.Vk=this.ho=!1;this.cu=1;this.au=0;this.Gd=this.Dg=NaN;this.hw=0;this.dn=null;this.o=pb;this.vc=new ob;this.Tt=new ob;this.yl=new H;this.Ut=new H;this.ew=Fh;a&&Object.assign(this,a)}hh.prototype.suspend=function(){this.Wk=!0};hh.prototype.advanceTo=function(a,b){b&&(this.Wk=!1);this.ho&&a>=this.Gd&&(this.Vk=!0,a-=this.Gd);this.hw=a;wh(this,!0);qh(this.Yd);yf(this.H);sh(this.Yd);this.H.redraw()};
function nh(a,b){a.Tt.clear();a.Vk=!1;a.au=0;a.Gd=NaN;0<a.yl.count&&a.yl.clear();0<a.Ut.count&&a.Ut.clear();if(null!==b)for(a=b.links;a.next();)a.value.Tg=null}t=hh.prototype;
t.start=function(){if(0===this.vc.count||this.cc)return this;for(var a=this.H,b=this.vc.iterator;b.next();){var c=b.key;null===a&&(c instanceof Q?a=c:c instanceof Y&&(a=c.diagram))}if(null!==a)this.H=a,this.Yd=a.animationManager;else return this;b=this.Yd;if(!1===b.isEnabled)return this;this.Gd=isNaN(this.Dg)?b.duration:this.Dg;this.Lp=this.ot;b.Nk&&b.Ok===gh&&this===b.defaultAnimation&&(this.Lp=Gh,this.Gd=isNaN(this.Dg)?600===b.duration?900:b.duration:this.Dg);this.ew=a.scrollMode;this.isViewportUnconstrained&&
(a.sj=Th);qh(b);this.yl.each(function(b){b.data=null;a.add(b)});sh(b);this.cc=!0;this.Qd=+new Date;this.cn=this.Qd+this.Gd;vh(b,this);return this};t.Jy=function(a,b){if(!a.Zc())return this;this.yl.add(a);this.H=b;return this};t.add=function(a,b,c,d,e){if("position"===b&&c.Xa(d))return this;null===this.H&&(a instanceof Q?this.H=a:a instanceof Y&&null!==a.diagram&&(this.H=a.diagram));if(a instanceof U){if(!a.isAnimated)return this;"position"===b&&(b="position:part")}zh(this,a,b,c,d,e);return this};
function zh(a,b,c,d,e,f){var g=a.vc;b instanceof Q&&"position"===c&&(c="position:diagram");if("fill"===c||"stroke"===c||"background"===c||"areaBackground"===c)Uh(d),Vh(),d=[Wh.j,Wh.u,Wh.o,Wh.F],Uh(e),Vh(),e=[Wh.j,Wh.u,Wh.o,Wh.F];if(g.contains(b)){var h=g.I(b);var k=h.start;var l=h.end;void 0===k[c]&&(k[c]=Xh(d));l[c]=Xh(e)}else k={},l={},k[c]=Xh(d),l[c]=Xh(e),h=k.position,b instanceof Y&&h instanceof I&&!h.w()&&b.diagram.animationManager.Zd.contains("Expand SubGraph")&&h.assign(l.position),h=new Yh(k,
l,f),g.add(b,h);g=k[c];g instanceof I&&!g.w()&&g.assign(a.o);f&&0===c.indexOf("position:")&&b instanceof U?h.Lu.location=Xh(b.location):f&&(h.Lu[c]=Xh(d))}function Xh(a){return a instanceof I?a.copy():a instanceof M?a.copy():a}t.gv=function(a){if(!this.cc)return!1;a=this.vc.I(a);return null!==a&&a.ov};t.Yu=function(a){if(!this.cc)return!1;a=this.vc.I(a);return null!==a&&(a.start.position||a.start.location)};
function wh(a,b){if(!a.Wk||b){var c=a.Yd;if(!1!==a.cc){var d=+new Date,e=d>a.cn?a.Gd:d-a.Qd;b&&(e=a.hw,e<a.Gd?(a.Qd=+new Date-e,a.cn=a.Qd+a.Gd):e=a.Gd);qh(c);rh(a,e);yf(a.H,!0);sh(c);d>a.cn&&(a.ho&&!a.Vk?(a.Qd=+new Date,a.cn=a.Qd+a.Gd,a.Vk=!0):a.Ml(!1))}}}
function rh(a,b){for(var c=a.Gd,d=a.vc.iterator,e=a.Vk;d.next();){var f=d.key;if(!(f instanceof Y&&null===f.diagram)){var g=d.value,h=e?g.end:g.start;g=e?g.start:g.end;var k=Dh,l;for(l in g)"position"===l&&(g["position:placeholder"]||g["position:nodeCollapse"])||null===k.get(l)||k.get(l)(f,h[l],g[l],a.Lp,b,c,a)}}}t.stop=function(){if(!this.cc)return this;this.Ml(!0);return this};
t.Ml=function(a){null!==this.vu&&this.vu.jo.remove(this.Ow);if(this.cc){var b=this.H,c=this.Yd;this.Wk=this.cc=c.Nk=!1;qh(c);for(var d=this.vc,e=this.yl.iterator;e.next();)b.remove(e.value);for(e=this.Ut.iterator;e.next();)e.value.v();e=this.ho;d=d.iterator;for(var f=Dh;d.next();){var g=d.key,h=d.value,k=e?h.end:h.start,l=e?h.start:h.end,m=h.Lu,n;for(n in l)if(null!==f.get(n)){var p=n;!h.Iu||"position:nodeCollapse"!==p&&"position:placeholder"!==p||(p="position");f.get(p)(g,k[n],void 0!==m[n]?m[n]:
h.Iu?k[n]:l[n],this.Lp,this.Gd,this.Gd,this)}h.Iu&&void 0!==m.location&&g instanceof U&&(g.location=m.location);h.ov&&g instanceof U&&g.ac(!1)}b.As.clear();Ze(b,!1);b.Ya();b.N();yf(b,!0);if(c.defaultAnimation===this){for(n=c.pr.iterator;n.next();)e=n.value,null!==e.Tg&&(e.points=e.Tg,e.Tg=null);c.pr.clear()}yf(b,!0);this.isViewportUnconstrained&&(b.scrollMode=this.ew);sh(c);this.au++;!a&&this.cu>this.au?(this.Vk=!1,this.start()):(this.dn&&this.dn(this),nh(this,null),c.Ml(this),b.Rb())}};
t.Cj=function(a,b){var c=b.actualBounds,d=null;b instanceof jf&&(d=b.placeholder);null!==d?(c=d.ga(sc),d=d.padding,c.x+=d.left,c.y+=d.top,this.add(a,"position",c,a.position,!1)):this.add(a,"position",new I(c.x+c.width/2,c.y+c.height/2),a.position,!1);this.add(a,"scale",.01,a.scale,!1);if(a instanceof jf)for(a=a.memberParts;a.next();)d=a.value,d instanceof V&&this.Cj(d,b)};
t.Aj=function(a,b){if(a.isVisible()){var c=null;b instanceof jf&&(c=b.placeholder);if(null!==c){var d=c.ga(sc);c=c.padding;d.x+=c.left;d.y+=c.top;this.add(a,"position:placeholder",a.position,d,!0)}else this.add(a,"position:nodeCollapse",a.position,b,!0);this.add(a,"scale",a.scale,.01,!0);d=this.vc;d.contains(a)&&(d.I(a).ov=!0);if(a instanceof jf)for(a=a.memberParts;a.next();)d=a.value,d instanceof V&&this.Aj(d,b)}};t.Fz=function(a){var b=this.Tt.get(a);null===b&&(b={},this.Tt.add(a,b));return b};
ma.Object.defineProperties(hh.prototype,{duration:{get:function(){return this.Dg},set:function(a){1>a&&xa(a,">= 1",hh,"duration");this.Dg=a}},reversible:{get:function(){return this.ho},set:function(a){this.ho=a}},runCount:{get:function(){return this.cu},set:function(a){0<a?this.cu=a:C("Animation.runCount value must be a positive integer.")}},finished:{get:function(){return this.dn},
set:function(a){this.dn!==a&&(this.dn=a)}},easing:{get:function(){return this.ot},set:function(a){this.ot=a}},isViewportUnconstrained:{get:function(){return this.j},set:function(a){this.j=a}},isAnimating:{get:function(){return this.cc}}});hh.prototype.getTemporaryState=hh.prototype.Fz;hh.prototype.stop=hh.prototype.stop;hh.prototype.add=hh.prototype.add;hh.prototype.addTemporaryPart=hh.prototype.Jy;
function Eh(a,b,c,d){a/=d/2;return 1>a?c/2*a*a+b:-c/2*(--a*(a-2)-1)+b}function Gh(a,b,c,d){return a===d?b+c:c*(-Math.pow(2,-10*a/d)+1)+b}hh.className="Animation";hh.EaseLinear=function(a,b,c,d){return c*a/d+b};hh.EaseInOutQuad=Eh;hh.EaseInQuad=function(a,b,c,d){return c*(a/=d)*a+b};hh.EaseOutQuad=function(a,b,c,d){return-c*(a/=d)*(a-2)+b};hh.EaseInExpo=function(a,b,c,d){return 0===a?b:c*Math.pow(2,10*(a/d-1))+b};hh.EaseOutExpo=Gh;
function Yh(a,b,c){this.start=a;this.end=b;this.Lu={};this.Iu=c;this.ov=!1}Yh.className="AnimationState";function Zh(a,b,c){this.xd=null;this.rf=a;this.so=c||$h;this.tk=null;void 0!==b&&(this.tk=b,void 0===c&&(this.so=ai))}Zh.prototype.copy=function(){var a=new Zh(this.rf);a.so=this.so;var b=this.tk;if(null!==b){var c={};void 0!==b.duration&&(c.duration=b.duration);void 0!==b.finished&&(c.finished=b.finished);void 0!==b.easing&&(c.easing=b.easing);a.tk=c}return a};
function bi(a,b){a=a.tk;null!==a&&(a.duration&&(b.duration=a.duration),a.finished&&(b.finished=a.finished),a.easing&&(b.easing=a.easing))}ma.Object.defineProperties(Zh.prototype,{propertyName:{get:function(){return this.rf},set:function(a){this.rf=a}},animationSettings:{get:function(){return this.tk},set:function(a){this.tk=a}},startCondition:{get:function(){return this.so},set:function(a){this.so=a}}});
var $h=new D(Zh,"Default",1),ai=new D(Zh,"Immediate",2),ci=new D(Zh,"Bundled",3);Zh.className="AnimationTrigger";Zh.Default=$h;Zh.Immediate=ai;Zh.Bundled=ci;function di(a){Qa(this);this.H=null;this.Pa=new F;this.xa="";this.pb=1;this.o=!1;this.j=this.Uh=this.u=this.Oi=this.Ni=this.Mi=this.Li=this.Ji=this.Ki=this.Ii=this.Qi=this.Hi=this.Pi=this.Gi=this.Fi=!0;this.Zq=[];a&&Object.assign(this,a)}t=di.prototype;
t.clear=function(){for(var a=this.Pa,b=0;b<a.length;b++){var c=a.s[b];c.Ig=null;ei(c)}a.clear();this.Zq.length=0};t.Xe=function(a){this.H=a};
t.toString=function(a){void 0===a&&(a=0);var b='Layer "'+this.name+'"';if(0>=a)return b;for(var c=0,d=0,e=0,f=0,g=0,h=this.Pa.iterator;h.next();){var k=h.value;k instanceof jf?e++:k instanceof V?d++:k instanceof T?f++:k instanceof pe?g++:c++}h="";0<c&&(h+=c+" Parts ");0<d&&(h+=d+" Nodes ");0<e&&(h+=e+" Groups ");0<f&&(h+=f+" Links ");0<g&&(h+=g+" Adornments ");if(1<a)for(a=this.Pa.iterator;a.next();)c=a.value,h+="\n    "+c.toString(),d=c.data,null!==d&&bb(d)&&(h+=" #"+bb(d)),c instanceof V?h+=" "+
Ia(d):c instanceof T&&(h+=" "+Ia(c.fromNode)+" "+Ia(c.toNode));return b+" "+this.Pa.count+": "+h};t.jc=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);if(!1===this.Uh)return null;var d=!1;null!==this.diagram&&this.diagram.viewportBounds.ca(a)&&(d=!0);for(var e=I.alloc(),f=this.Pa.s,g=f.length;g--;){var h=f[g];if((!0!==d||!1!==hg(h))&&h.isVisible()&&(e.assign(a),xb(e,h.oe),h=h.jc(e,b,c),null!==h&&(null!==b&&(h=b(h)),null!==h&&(null===c||c(h)))))return I.free(e),h}I.free(e);return null};
t.ji=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof F||d instanceof H||(d=new H);if(!1===this.Uh)return d;var e=!1;null!==this.diagram&&this.diagram.viewportBounds.ca(a)&&(e=!0);for(var f=I.alloc(),g=this.Pa.s,h=g.length;h--;){var k=g[h];if((!0!==e||!1!==hg(k))&&k.isVisible()){f.assign(a);xb(f,k.oe);var l=k;k.ji(f,b,c,d)&&(null!==b&&(l=b(l)),null===l||null!==c&&!c(l)||d.add(l))}}I.free(f);return d};
t.Cf=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof F||e instanceof H||(e=new H);if(!1===this.Uh)return e;var f=!1;null!==this.diagram&&this.diagram.viewportBounds.Re(a)&&(f=!0);for(var g=this.Pa.s,h=g.length;h--;){var k=g[h];if((!0!==f||!1!==hg(k))&&k.isVisible()){var l=k;k.Cf(a,b,c,d,e)&&(null!==b&&(l=b(l)),null===l||null!==c&&!c(l)||e.add(l))}}return e};
t.Ou=function(a,b,c,d,e,f,g){if(!1===this.Uh)return e;for(var h=this.Pa.s,k=h.length;k--;){var l=h[k];if((!0!==g||!1!==hg(l))&&f(l)&&l.isVisible()){var m=l;l.Cf(a,b,c,d,e)&&(null!==b&&(m=b(m)),null===m||null!==c&&!c(m)||e.add(m))}}return e};
t.og=function(a,b,c,d,e,f){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof F||e instanceof H)f=e;e=!0}f instanceof F||f instanceof H||(f=new H);if(!1===this.Uh)return f;var g=!1;null!==this.diagram&&this.diagram.viewportBounds.ca(a)&&(g=!0);for(var h=I.alloc(),k=I.alloc(),l=this.Pa.s,m=l.length;m--;){var n=l[m];if((!0!==g||!1!==hg(n))&&n.isVisible()){h.assign(a);xb(h,n.oe);k.h(a.x+b,a.y);xb(k,n.oe);var p=n;n.og(h,k,c,d,e,f)&&(null!==c&&(p=c(p)),null===
p||null!==d&&!d(p)||f.add(p))}}I.free(h);I.free(k);return f};
t.Fd=function(a,b){if(this.visible){var c=this.Pa.s,d=c.length;if(0!==d){a=Ea();for(var e=Ea(),f=0;f<d;f++){var g=c[f];g.ww=f;if(g instanceof T){if(!1===g.fd)continue}else if(g instanceof pe&&null!==g.adornedPart)continue;var h=g.actualBounds;g.isVisible()&&Wb(h,b)?(g.Fd(!0),a.push(g)):(g.Fd(!1),null!==g.adornments&&0<g.adornments.count&&e.push(g))}for(b=0;b<a.length;b++)for(c=a[b],fi(c),c=c.adornments;c.next();)d=c.value,d.measure(Infinity,Infinity),d.arrange(),d.Fd(!0);for(b=0;b<e.length;b++)fi(e[b]);
Ga(a);Ga(e)}}};function gi(a,b){var c=1;1!==a.pb&&(c=b.globalAlpha,b.globalAlpha=c*a.pb);return c}t.hc=function(a,b,c){if(this.visible&&0!==this.pb&&(void 0===c&&(c=!0),c||!this.isTemporary)){c=this.Pa.s;var d=c.length;if(0!==d){if(a.isTemporary)for(var e=0;e<d;e++)c[e].Fj();e=gi(this,a);var f=this.Zq;f.length=0;for(var g=b.scale,h=N.alloc(),k=0;k<d;k++)this.Nj(a,c[k],b,f,g,h,!0);N.free(h);if(a.isTemporary)for(b=0;b<d;b++)c[b].Fj();a.globalAlpha=e}}};
function hi(a,b,c,d,e){if(a.visible&&0!==a.pb&&(e||!a.isTemporary)){e=a.Pa.s;var f=e.length;if(0!==f){var g=gi(a,b),h=a.Zq;h.length=0;for(var k=c.scale,l=N.alloc(),m=0;m<f;m++){var n=e[m];d.contains(n)&&a.Nj(b,n,c,h,k,l,!1)}N.free(l);b.globalAlpha=g}}}
t.Nj=function(a,b,c,d,e,f,g){if(g&&!hg(b))a.Hs(b);else{if(null!==d&&b instanceof T&&(b.isOrthogonal&&d.push(b),!1===b.fd)){a.Hs(b);return}g=!1;for(d=b.containingGroup;null!==d;)g?null!==d.tb&&f.Fx(d.tb):null!==d.tb&&(g=!0,f.assign(d.tb)),d=d.containingGroup;var h=b.actualBounds;d=!1;if(g&&b.isVisible()){if(!f.Yc(h)){a.Hs(b);return}d=!f.Re(h)}d&&(a.save(),a instanceof ii?a.ak=f.copy():(a.beginPath(),a.rect(f.x,f.y,f.width,f.height),a.clip()));h.width*e>c.Oq||h.height*e>c.Oq?b.hc(a,c):(e=b.actualBounds,
f=b.naturalBounds,0===e.width||0===e.height||isNaN(e.x)||isNaN(e.y)||!b.isVisible()||(c=b.transform,null!==b.areaBackground&&(ji(b,a,b.areaBackground,!0,!0,f,e),a.fillRect(e.x,e.y,e.width,e.height)),null===b.areaBackground&&null===b.background&&(ji(b,a,"rgba(0,0,0,0.3)",!0,!1,f,e),a.fillRect(e.x,e.y,e.width,e.height)),null!==b.background&&(a.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy),ji(b,a,b.background,!0,!1,f,e),a.fillRect(0,0,f.width/2,f.height/2),c.ws()||(b=1/(c.m11*c.m22-c.m12*c.m21),a.transform(c.m22*
b,-c.m12*b,-c.m21*b,c.m11*b,b*(c.m21*c.dy-c.m22*c.dx),b*(c.m12*c.dx-c.m11*c.dy))))));d&&(a.restore(),a.Vc(!0))}};t.g=function(a,b,c,d,e){var f=this.diagram;null!==f&&f.cb(de,a,this,b,c,d,e)};t.oi=function(a,b,c){var d=this.Pa;b.Ig=this;null===this&&ei(b);if(a>=d.count)a=d.count;else if(d.M(a)===b)return-1;d.Hb(a,b);b.Vo(c);d=this.diagram;null!==d&&(c?d.N():d.oi(b));ki(this,a,b);return a};
t.qc=function(a,b,c){if(!c&&b.layer!==this&&null!==b.layer)return b.layer.qc(a,b,c);var d=this.Pa;if(0>a||a>=d.length){if(a=d.indexOf(b),0>a)return-1}else if(d.M(a)!==b&&(a=d.indexOf(b),0>a))return-1;b.Wo(c);d.mb(a);d=this.diagram;null!==d&&(c?d.N():d.qc(b));b.Ig=null;ei(b);return a};
function ki(a,b,c){b=li(a,b,c);if(c instanceof jf&&null!==c&&isNaN(c.zOrder)){if(0!==c.memberParts.count){for(var d=-1,e=a.Pa.s,f=e.length,g=0;g<f;g++){var h=e[g];if(h===c&&(b=g,0<=d))break;if(0>d&&h.containingGroup===c&&(d=g,0<=b))break}!(0>d)&&d<b&&(e=a.Pa,e.mb(b),e.Hb(d,c))}b=c.containingGroup;null!==b&&ki(a,-1,b)}null!==c.svg&&c.svg.remove()}
function li(a,b,c){var d=c.zOrder;if(isNaN(d))return b;a=a.Pa;var e=a.count;if(1>=e)return b;0>b&&(b=a.indexOf(c));if(0>b)return-1;for(var f=b-1,g=NaN;0<=f;){g=a.M(f).zOrder;if(!isNaN(g))break;f--}for(var h=b+1,k=NaN;h<e;){k=a.M(h).zOrder;if(!isNaN(k))break;h++}if(!isNaN(g)&&g>d)for(;;){if(-1===f||g<=d){f++;if(f===b)break;a.mb(b);a.Hb(f,c);return f}for(g=NaN;0<=--f&&(g=a.M(f).zOrder,isNaN(g)););}else if(!isNaN(k)&&k<d)for(;;){if(h===e||k>=d){h--;if(h===b)break;a.mb(b);a.Hb(h,c);return h}for(k=NaN;++h<
e&&(k=a.M(h).zOrder,isNaN(k)););}return b}
ma.Object.defineProperties(di.prototype,{parts:{get:function(){return this.Pa.iterator}},partsBackwards:{get:function(){return this.Pa.iteratorBackwards}},diagram:{get:function(){return this.H}},name:{get:function(){return this.xa},set:function(a){var b=this.xa;if(b!==a){var c=this.diagram;if(null!==c)for(""===b&&C("Cannot rename default Layer to: "+a),c=c.layers;c.next();)c.value.name===
a&&C("Layer.name is already present in this diagram: "+a);this.xa=a;this.g("name",b,a);for(a=this.Pa.iterator;a.next();)a.value.layerName=this.xa}}},opacity:{get:function(){return this.pb},set:function(a){var b=this.pb;b!==a&&((0>a||1<a)&&xa(a,"0 <= value <= 1",di,"opacity"),this.pb=a,this.g("opacity",b,a),a=this.diagram,null!==a&&a.N())}},isTemporary:{get:function(){return this.o},set:function(a){var b=this.o;if(b!==a){if(this.o=a)this.isInDocumentBounds=
!1;this.g("isTemporary",b,a)}}},visible:{get:function(){return this.u},set:function(a){var b=this.u;if(b!==a){this.u=a;this.g("visible",b,a);for(b=this.Pa.iterator;b.next();)b.value.ac(a);a=this.diagram;null!==a&&a.N()}}},pickable:{get:function(){return this.Uh},set:function(a){var b=this.Uh;b!==a&&(this.Uh=a,this.g("pickable",b,a))}},isInDocumentBounds:{get:function(){return this.j},set:function(a){this.j!==
a&&(this.j=a,null!==this.diagram&&this.diagram.Ya())}},allowCopy:{get:function(){return this.Fi},set:function(a){var b=this.Fi;b!==a&&(this.Fi=a,this.g("allowCopy",b,a))}},allowDelete:{get:function(){return this.Gi},set:function(a){var b=this.Gi;b!==a&&(this.Gi=a,this.g("allowDelete",b,a))}},allowTextEdit:{get:function(){return this.Pi},set:function(a){var b=this.Pi;b!==a&&(this.Pi=a,this.g("allowTextEdit",b,
a))}},allowGroup:{get:function(){return this.Hi},set:function(a){var b=this.Hi;b!==a&&(this.Hi=a,this.g("allowGroup",b,a))}},allowUngroup:{get:function(){return this.Qi},set:function(a){var b=this.Qi;b!==a&&(this.Qi=a,this.g("allowUngroup",b,a))}},allowLink:{get:function(){return this.Ii},set:function(a){var b=this.Ii;b!==a&&(this.Ii=a,this.g("allowLink",b,a))}},allowRelink:{get:function(){return this.Ki},
set:function(a){var b=this.Ki;b!==a&&(this.Ki=a,this.g("allowRelink",b,a))}},allowMove:{get:function(){return this.Ji},set:function(a){var b=this.Ji;b!==a&&(this.Ji=a,this.g("allowMove",b,a))}},allowReshape:{get:function(){return this.Li},set:function(a){var b=this.Li;b!==a&&(this.Li=a,this.g("allowReshape",b,a))}},allowResize:{get:function(){return this.Mi},set:function(a){var b=this.Mi;b!==a&&(this.Mi=a,this.g("allowResize",
b,a))}},allowRotate:{get:function(){return this.Ni},set:function(a){var b=this.Ni;b!==a&&(this.Ni=a,this.g("allowRotate",b,a))}},allowSelect:{get:function(){return this.Oi},set:function(a){var b=this.Oi;b!==a&&(this.Oi=a,this.g("allowSelect",b,a))}}});di.prototype.findObjectsNear=di.prototype.og;di.prototype.findObjectsIn=di.prototype.Cf;di.prototype.findObjectsAt=di.prototype.ji;di.prototype.findObjectAt=di.prototype.jc;di.className="Layer";
function Q(a,b){mi||(ni(),mi=!0);Qa(this);le=this;this.wb=!0;this.Ka=null;this.ya=this.Aa=0;this.ta=null;if(Lg){var c=this,d=function(){c.removeEventListener(x.document,"DOMContentLoaded",d,!1);c.setRTL()};null!==x.document.body?this.setRTL():c.addEventListener(x.document,"DOMContentLoaded",d,!1)}this.ol=null;oi.add("Model",pi);var e=this;this.Ib=function(a){var b=e.partManager;if(a.model===b.diagram.model&&b.diagram.aa){b.diagram.aa=!1;try{var c=a.change;""===a.modelChange&&c===de&&b.updateDataBindings(a.object,
a.propertyName)}finally{b.diagram.aa=!0}}};this.Oc=function(a){e.partManager.doModelChanged(a)};this.hu=this.ku=null;qi(this);this.model=ri();this.Wg=!0;si(this);this.layout=new ti;this.Wg=!1;this.bk=this.dk=this.ek=this.ck=this.ui=this.bA=this.Zj=this.Pv=null;this.gk=function(){};this.preventDefault=null;this.rn=!1;this.Yf=new ui;void 0!==a&&("string"===typeof a||x.Element&&a instanceof Element?vi(this,a):b=a);b&&this.nm(b);this.wb=!1;this.Rb();return this}var wi,le;
function qi(a){a.Yd=new dh;a.Yd.Xe(a);a.Eb=17;a.Hw=!1;a.tn=!1;a.bu="default";a.Wa=new F;a.pj=new ob;a.nl="default";a.Ux();a.Tx();a.pb=1;a.pa=(new I(NaN,NaN)).freeze();a.du=new I(NaN,NaN);a.Ca=1;a.Pp=1;a.zt=(new I(NaN,NaN)).freeze();a.At=NaN;a.Ot=1E-4;a.Mt=100;a.Bb=new ld;a.zu=(new I(NaN,NaN)).freeze();a.ut=(new N(NaN,NaN,NaN,NaN)).freeze();a.rj=(new cc(0,0,0,0)).freeze();a.sj=Fh;a.nu=!1;a.Zt=null;a.fu=null;a.yh=oh;a.yk=Sc;a.Fg=oh;a.pq=Sc;a.yt=sc;a.Bt=sc;a.Fc=!0;a.pn=!1;a.De=new H;a.Eh=new ob;a.Xm=
!0;a.vp=250;a.uk=-1;a.xp=(new cc(16,16,16,16)).freeze();a.kg=!1;a.Ik=!1;a.fn=!0;a.bq=new Td;a.bq.diagram=a;a.Qg=new Td;a.Qg.diagram=a;a.cj=new Td;a.cj.diagram=a;a.zg=null;a.pf=null;a.qu=!1;xi(a);a.ai=new H;a.Ht=!0;a.uu=yi;a.qw=!1;a.wu=Df;a.it="auto";a.Op="auto";a.zh=null;a.Fh=null;a.Bh=null;a.Qh=null;a.Oh=null;a.Nh=null;a.Qt=null;a.Lh=null;a.xt=!1;a.Mh=null;a.Ph=null;a.$h=null;a.Ch=null;a.Rt=!1;a.Yt={};a.oj=[null,null];a.Wg=!1;a.Jw=!1;a.ou=!1;a.lt=!1;a.sw=!0;a.ld=!1;a.$i=!1;a.Uw=!0;a.Ce=-2;a.Og=new ob;
a.Xn=new F;a.Gg=!1;a.Jd=!0;a.Us=!0;a.Fi=!0;a.Gi=!0;a.Vs=!1;a.Ws=!0;a.Pi=!0;a.Hi=!0;a.Qi=!0;a.Ys=!0;a.Ii=!0;a.Ki=!0;a.Ji=!0;a.Li=!0;a.Mi=!0;a.Ni=!0;a.Oi=!0;a.Zs=!0;a.at=!0;a.rw=!1;a.Uk=!1;a.mn=!0;a.nn=!0;a.Xs=!0;a.$s=!0;a.iu=16;a.mu=16;a.qr=!1;a.gu=!1;a.lu=0;a.ju=0;a.zb=(new cc(5)).freeze();a.Iw=(new H).freeze();a.Nt=999999999;a.ow=(new H).freeze();a.Zi=!0;a.Ee=!0;a.vd=!0;a.Be=!1;a.gf=!1;a.xh=!0;a.hf=!1;a.ty=new H;a.pw=new H;a.Xh=null;a.bw=new M(8,8);a.cw=999;a.Cw=1;a.Kw=0;a.gc={scale:1,position:new I,
bounds:new N,Qy:new M,cA:new M,Pz:!1};a.gc.canvasSize=a.gc.Qy;a.gc.newCanvasSize=a.gc.cA;a.gc.isScroll=!1;a.Sw=(new N(NaN,NaN,NaN,NaN)).freeze();a.wo=(new M(NaN,NaN)).freeze();a.Sp=(new N(NaN,NaN,NaN,NaN)).freeze();a.It=!1;a.Lg=new ob;var b=new V,c=new Ng;c.bind(new zi("text","",Ia));b.add(c);a.oy=b;a.Lg.add("",b);b=new V;c=new Ng;c.stroke="brown";c.bind(new zi("text","",Ia));b.add(c);a.Lg.add("Comment",b);b=new V;b.selectable=!1;b.avoidable=!1;c=new Bf;c.figure="Ellipse";c.fill="black";c.stroke=
null;c.desiredSize=(new M(3,3)).ja();b.add(c);a.Lg.add("LinkLabel",b);a.Yi=new ob;b=new jf;b.selectionObjectName="GROUPPANEL";b.type=W.Vertical;c=new Ng;c.font="bold 12pt sans-serif";c.bind(new zi("text","",Ia));b.add(c);c=new W(W.Auto);c.name="GROUPPANEL";var d=new Bf;d.figure="Rectangle";d.fill="rgba(128,128,128,0.2)";d.stroke="black";c.add(d);d=new ig;d.padding=(new cc(5,5,5,5)).ja();c.add(d);b.add(c);a.my=b;a.Yi.add("",b);a.Kh=new ob;b=new T;c=new Bf;c.isPanelMain=!0;b.add(c);c=new Bf;c.toArrow=
"Standard";c.fill="black";c.stroke=null;c.strokeWidth=0;b.add(c);a.ny=b;a.Kh.add("",b);b=new T;c=new Bf;c.isPanelMain=!0;c.stroke="brown";b.add(c);a.Kh.add("Comment",b);b=new pe;b.type=W.Auto;c=new Bf;c.fill=null;c.stroke="dodgerblue";c.strokeWidth=3;b.add(c);c=new ig;c.margin=(new cc(1.5,1.5,1.5,1.5)).ja();b.add(c);a.St=b;a.wt=b;b=new pe;b.type=W.Link;c=new Bf;c.isPanelMain=!0;c.fill=null;c.stroke="dodgerblue";c.strokeWidth=3;b.add(c);a.Kt=b;a.fe=null;a.mq=!1;a.Bk=null;a.partManager=new pi;a.toolManager=
new La;a.toolManager.initializeStandardTools();a.defaultTool=a.toolManager;a.currentTool=a.defaultTool;a.nt=null;a.Wm=new Ee;a.Vt=null;a.Wt=null;a.Mr=!1;a.commandHandler=Ai();a.eq=null;a.fq=tb;a.Qw=!1;a.Nb=1;a.Yh=null;a.Oq=1;a.Sq=0;a.Aw=[0,0,0,0,0];a.Tq=0;a.yq=1;a.uw=0;a.vw=new I;a.Ay=500;a.wp=new I;a.wq=!1}Q.prototype.clear=function(){this.animationManager.Cc();this.model.clear();Bi=null;Ci="";Di(this,!1);this.Xn.clear();Ei();this.Og.clear();this.Xh=null;this.Ya();this.Va();this.N()};
function Di(a,b){a.animationManager.Cc(!0);a.Iw=(new H).freeze();a.ow=(new H).freeze();var c=a.skipsUndoManager,d=null!==a.dc&&void 0!==a.dc;d&&(a.skipsUndoManager=!0);var e=null;null!==a.fe&&(e=a.fe.part,null!==e&&a.remove(e));var f=[],g=a.Wa.length;if(b){for(b=0;b<g;b++)for(var h=a.Wa.s[b].parts;h.next();){var k=h.value;k!==e&&null===k.data&&f.push(k)}for(b=0;b<f.length;b++)a.remove(f[b])}for(b=0;b<g;b++)a.Wa.s[b].clear();a.partManager.clear();a.De.clear();a.Eh.clear();a.ai.clear();a.Bk=null;a.Xn.clear();
Ei();a.Og.clear();Fa=[];null!==e&&(a.add(e),a.partManager.parts.remove(e));d&&(a.skipsUndoManager=c);return f}function Ai(){return null}Q.prototype.reset=function(){this.clear();this.wb=!0;qi(this);Fi(this);this.Wg=!0;si(this);this.layout=new ti;this.Wg=!1;this.model=ri();this.model.undoManager=new ie;this.wb=this.rn=!1;this.N()};
Q.prototype.setRTL=function(a){a=void 0===a?this.div:a;null===a&&(a=x.document.body);var b=ua("div");b.dir="rtl";b.style.cssText="font-size: 14px; width: 1px; height: 1px; position: absolute; top: -1000px; overflow: scroll;";b.textContent="A";a.appendChild(b);var c="reverse";0<b.scrollLeft?c="default":(b.scrollLeft=1,0===b.scrollLeft&&(c="negative"));a.removeChild(b);this.bu=c};
Q.prototype.setScrollWidth=function(a){a=void 0===a?this.div:a;null===a&&(a=x.document.body);var b=0;if(Lg){var c=Gi;b=wi;null===c&&(c=Gi=ua("p"),c.style.width="100%",c.style.height="200px",c.style.boxSizing="content-box",b=wi=ua("div"),b.style.position="absolute",b.style.visibility="hidden",b.style.width="200px",b.style.height="150px",b.style.boxSizing="content-box",b.appendChild(c));b.style.overflow="hidden";a.appendChild(b);var d=c.offsetWidth;b.style.overflow="scroll";c=c.offsetWidth;d===c&&(c=
b.clientWidth);a.removeChild(b);b=d-c;0!==b||Pa||(b=11)}this.Eb=b;this.Hw=!0};Q.prototype.qb=function(a){a.classType===Q&&(this.autoScale=a)};Q.prototype.toString=function(a){void 0===a&&(a=0);var b="";this.div&&this.div.id&&(b=this.div.id);b='Diagram "'+b+'"';if(0>=a)return b;for(var c=this.Wa.iterator;c.next();)b+="\n  "+c.value.toString(a-1);return b};
function vi(a,b){if(Lg){void 0!==b&&null!==b||C("Diagram setup requires an argument DIV.");null!==a.Ka&&C("Diagram has already completed setup.");"string"===typeof b?a.Ka=x.document.getElementById(b):b instanceof HTMLDivElement?a.Ka=b:C("No DIV or DIV id supplied: "+b);null===a.Ka&&C("Invalid DIV id; could not get element with id: "+b);void 0!==a.Ka.H&&C("Invalid div id; div already has a Diagram associated with it.");if(!a.ol&&x.ResizeObserver){var c=sa(function(){a.Rb()},250);a.ol=new x.ResizeObserver(function(){c()});
a.ol.observe(a.Ka)}"static"===x.getComputedStyle(a.Ka,null).position&&(a.Ka.style.position="relative");b=5;var d="rgba(2"+b+"5, 255, 255, 0)";b--;a.Ka.style["-webkit-tap-highlight-color"]=d;a.Ka.innerHTML="";a.Ka.H=a;a.Ka.goDiagram=a;a.Ka.go=x.go;var e=new Hi(a);void 0!==e.style&&(e.style.position="absolute",e.style.top="0px",e.style.left="0px","rtl"===x.getComputedStyle(a.Ka,null).getPropertyValue("direction")&&(a.tn=!0),e.style.zIndex="2",e.style.userSelect="none",e.style.MozUserSelect="none",e.style.touchAction=
"none",e.style.letterSpacing="normal !important",e.style.wordSpacing="normal !important");a.ta=e;a.ib=e.context;d=a.ib;a.Nb=a.computePixelRatio();a.viewSize.w()||(a.Aa=a.Ka.clientWidth||1,a.ya=a.Ka.clientHeight||1);Ii(a,a.Aa,a.ya);a.Ka.insertBefore(e.Oa,a.Ka.firstChild);e=new Hi(null);e.width=1;e.height=1;a.kw=e;a.py=e.context;if(Lg){e=ua("div");var f=ua("div");e.style.position="absolute";e.style.overflow="auto";e.style.width=a.Aa+"px";e.style.height=a.ya+"px";e.style.zIndex="1";f.style.position=
"absolute";f.style.width="1px";f.style.height="1px";a.Ka.appendChild(e);e.appendChild(f);e.onscroll=Ji;e.addEventListener("pointerdown",Ki);e.H=a;e.vy=!0;e.wy=!0;a.ku=e;a.hu=f}a.gk=sa(function(){a.Yh=null;a.N()},300);a.Pv=sa(function(){ph(a)},250);a.preventDefault=function(a){a.preventDefault();return!1};a.Zj=function(b){if(a.isEnabled){var c=Li(a,b,!0);c.bubbles=!0;var d=0,e=0;c.delta=0;void 0!==b.deltaX?(0!==b.deltaX&&(d=0<b.deltaX?1:-1),0!==b.deltaY&&(e=0<b.deltaY?1:-1),c.delta=Math.abs(b.deltaX)>
Math.abs(b.deltaY)?-d:-e):void 0!==b.wheelDeltaX?(0!==b.wheelDeltaX&&(d=0<b.wheelDeltaX?-1:1),0!==b.wheelDeltaY&&(e=0<b.wheelDeltaY?-1:1),c.delta=Math.abs(b.wheelDeltaX)>Math.abs(b.wheelDeltaY)?-d:-e):void 0!==b.wheelDelta&&0!==b.wheelDelta&&(c.delta=0<b.wheelDelta?1:-1);a.doMouseWheel();Mi(c,b)}};a.bA=function(b){a.isEnabled&&(a.Uk=!1,Li(a,b,!0),b=a.currentTool,b.cancelWaitAfter(),b.standardMouseOver())};a.ui=function(b){if(a.isEnabled){a.Uk=!0;var c=a.Yt;void 0===c[b.pointerId]&&(c[b.pointerId]=
b);c=a.oj;var d=!1;if(null===c[0]||c[0].pointerId!==b.pointerId&&c[0].pointerType===b.pointerType)if(null!==c[1]&&c[1].pointerId===b.pointerId)c[1]=b,d=!0;else if(null===c[0])c[0]=b;else if(null===c[1])c[1]=b,d=!0;else{b.preventDefault();return}else c[0]=b;if(c="touch"===b.pointerType||"pen"===b.pointerType)a.qu=!1,a.wq=!0;var e=d;d=Ni(a,b,!0,!1,!0,e);Oi(a,b,d);d.targetDiagram=Pi(b,b.target);d.targetObject=null;e||d.clone(a.bq);e=a.vw;c=c?25:10;b.timeStamp-a.uw<a.Ay&&!(Math.abs(e.x-b.screenX)>c||
Math.abs(e.y-b.screenY)>c)?a.yq++:a.yq=1;d.clickCount=a.yq;a.uw=b.timeStamp;a.vw.sg(b.screenX,b.screenY);a.doMouseDown();1===b.button?b.preventDefault():Mi(d,b)}};a.ck=function(b){if(a.isEnabled){a.Uk=!0;var c=a.oj;if(null===c[0]||c[0].pointerId!==b.pointerId&&c[0].pointerType===b.pointerType){if(null!==c[1]&&c[1].pointerId===b.pointerId){c[1]=b;return}if(null===c[0])c[0]=b;else return}else c[0]=b;if(c[0].pointerId===b.pointerId){c=Ni(a,b,!1,!1,!1,null!==c[1]);var d=x.document.elementFromPoint(b.clientX,
b.clientY)||null;null!==d&&void 0!==d.shadowRoot&&null!==d.shadowRoot&&(d=d.shadowRoot.elementFromPoint(b.clientX,b.clientY));var e=a,f=b;d&&d.H&&(f=b,e=d.H);c.targetDiagram=e;Oi(a,f,c);c.targetObject=null;a.doMouseMove();Mi(c,b)}}};a.ek=function(b){if(a.isEnabled){a.Uk=!0;var c="touch"===b.pointerType||"pen"===b.pointerType,d=a.Yt;if(c&&a.qu)delete d[b.pointerId],b.preventDefault();else if(d=a.oj,null===d[0]||d[0].pointerId!==b.pointerId&&d[0].pointerType===b.pointerType)null!==d[1]&&d[1].pointerId===
b.pointerId&&(d[1]=null);else{d[0]=null;d=Ni(a,b,!1,!0,!0,!1);var e=x.document.elementFromPoint(b.clientX,b.clientY)||null;null!==e&&void 0!==e.shadowRoot&&null!==e.shadowRoot&&(e=e.shadowRoot.elementFromPoint(b.clientX,b.clientY));null!==e&&e.H instanceof Q&&e.H!==a&&Oi(e.H,b,d);null===e&&(e=b.target);Oi(a,b,d);d.clickCount=a.yq;d.targetDiagram=Pi(b,e);d.targetObject=null;a.doMouseUp();Mi(d,b);c&&(a.wq=!1)}}};a.dk=function(b){if(a.isEnabled){a.Uk=!1;var c=a.Yt;c[b.pointerId]&&delete c[b.pointerId];
c=a.oj;null!==c[0]&&c[0].pointerId===b.pointerId&&(c[0]=null);null!==c[1]&&c[1].pointerId===b.pointerId&&(c[1]=null);"touch"!==b.pointerType&&"pen"!==b.pointerType&&(b=a.currentTool,b.cancelWaitAfter(),b.standardMouseOver())}};a.bk=function(b){var c=a.oj;null!==c[0]&&c[0].pointerId===b.pointerId?(c[0]=null,a.wq=!1):null!==c[1]&&c[1].pointerId===b.pointerId&&(c[1]=null)};a.Vp=d.Z["d"+Qi[1]+Qi[2]+"wI"+Ri.slice(1,5)]["b"+Si.slice(1,b)](d.Z,Ti,b,b);d.Vc(!0);Ui(a);"svg"===a.nl&&(b=a.pj.get("svg"),a.Ka.appendChild(b.Oa),
a.ib=b.context)}}Q.prototype.addEventListener=function(a,b,c,d){a.addEventListener(b,c,{capture:d,passive:!1})};Q.prototype.removeEventListener=function(a,b,c,d){a.removeEventListener(b,c,{capture:d})};
function Ui(a){var b=a.ta.Oa;b instanceof HTMLCanvasElement||(b=a.div);a.addEventListener(b,"pointerdown",a.ui,!1);a.addEventListener(b,"pointermove",a.ck,!1);a.addEventListener(b,"pointerup",a.ek,!1);a.addEventListener(b,"pointerout",a.dk,!1);a.addEventListener(b,"pointercancel",a.bk,!1);a.addEventListener(b,"pointerenter",a.cz,!1);a.addEventListener(b,"pointerleave",a.dz,!1);a.addEventListener(b,"wheel",a.Zj,!1);a.addEventListener(b,"keydown",a.Rz,!1);a.addEventListener(b,"keyup",a.Sz,!1);a.addEventListener(b,
"blur",a.Oy,!1);a.addEventListener(b,"focus",a.Py,!1);a.addEventListener(b,"selectstart",function(a){a.preventDefault();return!1},!1);a.addEventListener(b,"contextmenu",function(a){a.preventDefault();return!1},!1);a.addEventListener(x,"resize",a.Pv,!1)}function Ie(a){30<a.Sq&&(a.Yh=1)}function Ze(a,b){null!==a.Yh&&(a.Yh=null,b&&a.gk(),Wa&&Wa.ox||(a.Sq=0,a.Aw=[0,0,0,0,0],a.Tq=0))}Q.prototype.computePixelRatio=function(){return null!==this.Yh?this.Yh:x.devicePixelRatio||1};Q.prototype.doMouseMove=function(){this.currentTool.doMouseMove()};
Q.prototype.doMouseDown=function(){this.currentTool.doMouseDown()};Q.prototype.doMouseUp=function(){this.currentTool.doMouseUp()};Q.prototype.doMouseWheel=function(){this.currentTool.doMouseWheel()};Q.prototype.doKeyDown=function(){this.currentTool.doKeyDown()};Q.prototype.doKeyUp=function(){this.currentTool.doKeyUp()};Q.prototype.doFocus=function(){this.focus()};
Q.prototype.focus=function(){if(this.ta)if(this.scrollsPageOnFocus)this.ta.focus();else{var a=x.scrollX||x.pageXOffset,b=x.scrollY||x.pageYOffset;this.ta.focus();x.scrollTo(a,b)}};Q.prototype.Py=function(){this.H.S("GainedFocus")};Q.prototype.Oy=function(){this.H.S("LostFocus")};
function ph(a){if(null!==a.ta){var b=a.Ka;if(null!==b&&0!==b.clientWidth&&0!==b.clientHeight){a.Hw||a.setScrollWidth();var c=a.gf?a.Eb:0,d=a.Be?a.Eb:0,e=a.Nb;a.Nb=a.computePixelRatio();a.Nb!==e&&(a.pn=!0,a.Rb());if(b.clientWidth!==a.Aa+c||b.clientHeight!==a.ya+d)a.Ee=!0,a.Fc=!0,b=a.layout,null!==b&&b.isViewportSized&&a.autoScale===oh&&(a.Ik=!0,b.C()),a.ld||a.Rb()}}}
function si(a){var b=new di;b.name="Background";a.Al(b);b=new di;b.name="";a.Al(b);b=new di;b.name="Foreground";a.Al(b);b=new di;b.name="Adornment";b.isTemporary=!0;b.isInDocumentBounds=!1;a.Al(b);b=new di;b.name="Tool";b.isTemporary=!0;b.isInDocumentBounds=!0;a.Al(b);b=new di;b.name="Grid";b.allowSelect=!1;b.pickable=!1;b.isTemporary=!0;b.isInDocumentBounds=!1;a.Xw(b,a.Pj("Background"))}
function Vi(a){var b=new W(W.Grid);b.name="GRID";var c=new Bf;c.figure="LineH";c.stroke="lightgray";c.strokeWidth=.5;c.interval=1;b.add(c);c=new Bf;c.figure="LineH";c.stroke="gray";c.strokeWidth=.5;c.interval=5;b.add(c);c=new Bf;c.figure="LineH";c.stroke="gray";c.strokeWidth=1;c.interval=10;b.add(c);c=new Bf;c.figure="LineV";c.stroke="lightgray";c.strokeWidth=.5;c.interval=1;b.add(c);c=new Bf;c.figure="LineV";c.stroke="gray";c.strokeWidth=.5;c.interval=5;b.add(c);c=new Bf;c.figure="LineV";c.stroke=
"gray";c.strokeWidth=1;c.interval=10;b.add(c);c=new U;c.add(b);c.layerName="Grid";c.zOrder=0;c.isInDocumentBounds=!1;c.isAnimated=!1;c.pickable=!1;c.locationObjectName="GRID";a.add(c);a.partManager.parts.remove(c);b.visible=!1;return b}function Ji(){this.H.gu?this.H.gu=!1:this.H.isEnabled?this.H.lx(this):ij(this.H)}function Ki(a){this.H.isEnabled?(this.H.lu=a.target.scrollTop,this.H.ju=a.target.scrollLeft):ij(this.H)}
Q.prototype.lx=function(a){if(null!==this.ta){var b=this.ku,c=this.hu;this.qr=!0;var d=this.documentBounds,e=this.viewportBounds,f=this.rj,g=d.x-f.left,h=d.y-f.top,k=d.width+f.left+f.right,l=d.height+f.top+f.bottom,m=d.right+f.right;f=d.bottom+f.bottom;var n=e.x;d=e.y;var p=e.width,q=e.height,r=e.right,u=e.bottom;e=this.scale;var w=a.scrollLeft;if(this.tn)switch(this.bu){case "negative":w=w+a.scrollWidth-a.clientWidth;break;case "reverse":w=a.scrollWidth-w-a.clientWidth}var v=w;p<k||q<l?(w=I.allocAt(this.position.x,
this.position.y),this.allowHorizontalScroll&&this.ju!==v&&(w.x=v/e+g,this.ju=v),this.allowVerticalScroll&&this.lu!==a.scrollTop&&(w.y=a.scrollTop/e+h,this.lu=a.scrollTop),this.position=w,I.free(w),this.Ee=this.qr=!1):(w=I.alloc(),a.vy&&this.allowHorizontalScroll&&(g<n&&(this.position=w.h(v+g,this.position.y)),m>r&&(this.position=w.h(-(b.scrollWidth-this.Aa)+v-this.Aa/e+m,this.position.y))),a.wy&&this.allowVerticalScroll&&(h<d&&(this.position=w.h(this.position.x,a.scrollTop+h)),f>u&&(this.position=
w.h(this.position.x,-(b.scrollHeight-this.ya)+a.scrollTop-this.ya/e+f))),I.free(w),this.vd=!0,this.Ee=this.qr=!1,d=this.documentBounds,e=this.viewportBounds,m=d.right,r=e.right,f=d.bottom,u=e.bottom,g=d.x,n=e.x,h=d.y,d=e.y,p>=k&&g>=n&&m<=r&&(c.style.width="1px"),q>=l&&h>=d&&f<=u&&(c.style.height="1px"))}};Q.prototype.computeBounds=function(a){void 0===a&&(a=new N);jh(this);return jj(this,a)};
function jj(a,b){if(a.fixedBounds.w())return b.assign(a.fixedBounds),b.Co(a.zb),b;for(var c=!0,d=a.Wa.s,e=d.length,f=0;f<e;f++){var g=d[f];if(g.visible&&g.isInDocumentBounds){g=g.Pa.s;for(var h=g.length,k=0;k<h;k++){var l=g[k];l.isInDocumentBounds&&l.isVisible()&&(l=l.actualBounds,l.w()&&(c?(c=!1,b.assign(l)):b.ed(l)))}}}c&&b.h(0,0,0,0);b.Co(a.zb);return b}
Q.prototype.computePartsBounds=function(a,b){void 0===b&&(b=!1);var c=null;if(Aa(a))for(var d=0;d<a.length;d++){var e=a[d];!b&&e instanceof T||(e.Va(),null===c?c=e.actualBounds.copy():c.ed(e.actualBounds))}else for(a=a.iterator;a.next();)d=a.value,!b&&d instanceof T||(d.Va(),null===c?c=d.actualBounds.copy():c.ed(d.actualBounds));return null===c?new N(NaN,NaN,0,0):c};
function kj(a,b){if((b||a.hf)&&!a.wb&&null!==a.ta&&a.documentBounds.w()){a.wb=!0;if(b){var c=a.initialPosition;if(c.w()){a.wb=!1;a.position=c;return}c=I.alloc();c.xi(a.documentBounds,a.initialDocumentSpot);var d=a.viewportBounds;d=N.allocAt(0,0,d.width,d.height);var e=I.alloc();e.xi(d,a.initialViewportSpot);e.h(c.x-e.x,c.y-e.y);a.position=e;N.free(d);I.free(e);I.free(c)}c=a.yh;b&&a.Fg!==oh&&(c=a.Fg);var f=c!==oh?lj(a,c):a.scale;c=a.pa.x;d=a.pa.y;e=a.Aa/f;var g=a.ya/f,h=a.yk,k=a.pq;b&&!h.sb()&&(k.sb()||
k.Zb())&&(h=k.Zb()?wc:k);mj(a,a.documentBounds,e,g,h,b);b=a.scale;a.scale=f;a.wb=!1;f=a.viewportBounds;if(!(K.B(f.x,c)&&K.B(f.y,d)&&K.B(f.width,e)&&K.B(f.height,g)))a.onViewportBoundsChanged(new N(c,d,e,g),f,b,!1)}}
function lj(a,b){var c=a.Pp;if(null===a.ta)return c;a.Va();var d=a.documentBounds;if(!d.w())return c;var e=d.width;d=d.height;var f=a.Aa+(a.gf?a.Eb:0),g=a.ya+(a.Be?a.Eb:0),h=f/e,k=g/d;return b===nj?(b=Math.min(k,h),b>c&&(b=c),b<a.minScale&&(b=a.minScale),b>a.maxScale&&(b=a.maxScale),b):b===oj?(b=k>h?(g-a.Eb)/d:(f-a.Eb)/e,b>c&&(b=c),b<a.minScale&&(b=a.minScale),b>a.maxScale&&(b=a.maxScale),b):a.scale}
Q.prototype.zoomToFit=function(){var a=this.sj;this.sj=Fh;this.scale=lj(this,nj);a!==Fh&&(kj(this,!1),mj(this,this.documentBounds,this.Aa/this.Ca,this.ya/this.Ca,this.yk,!1));this.sj=a};t=Q.prototype;
t.CA=function(a,b){void 0===b&&(b=nj);var c=a.width,d=a.height;if(!(0===c||0===d||isNaN(c)&&isNaN(d))){var e=1;if(b===nj||b===oj)if(isNaN(c))e=this.viewportBounds.height*this.scale/d;else if(isNaN(d))e=this.viewportBounds.width*this.scale/c;else{e=this.Aa;var f=this.ya;e=b===oj?f/d>e/c?(f-(this.Be?this.Eb:0))/d:(e-(this.gf?this.Eb:0))/c:Math.min(f/d,e/c)}this.scale=e;this.position=new I(a.x,a.y)}};
t.Ky=function(a,b){this.Va();var c=this.documentBounds,d=this.viewportBounds;this.position=new I(c.x+(a.x*c.width+a.offsetX)-(b.x*d.width-b.offsetX),c.y+(a.y*c.height+a.offsetY)-(b.y*d.height-b.offsetY))};t.Az=function(a){if(a instanceof Y){this.eq=a;var b=I.alloc();this.fq=this.op(a.ga(sc,b));I.free(b)}else this.eq=null,this.fq=tb};
function mj(a,b,c,d,e,f){var g=I.allocAt(a.pa.x,a.pa.y),h=g.x,k=g.y;if(null!==a.eq){var l=I.alloc();l=a.eq.ga(sc,l);h=l.x-a.fq.x/a.scale;k=l.y-a.fq.y/a.scale;e=lc;I.free(l)}if(f||a.scrollMode===Fh)e.sb()&&(c>b.width&&(h=b.x+(e.x*b.width+e.offsetX)-(e.x*c-e.offsetX)),d>b.height&&(k=b.y+(e.y*b.height+e.offsetY)-(e.y*d-e.offsetY))),e=a.rj,f=c-b.width,c<b.width+e.left+e.right?(h=Math.min(h+c/2,b.right+Math.max(f,e.right)-c/2),h=Math.max(h,b.left-Math.max(f,e.left)+c/2),h-=c/2):h>b.left?h=b.left:h<b.right-
c&&(h=b.right-c),c=d-b.height,d<b.height+e.top+e.bottom?(k=Math.min(k+d/2,b.bottom+Math.max(c,e.bottom)-d/2),k=Math.max(k,b.top-Math.max(c,e.top)+d/2),k-=d/2):k>b.top?k=b.top:k<b.bottom-d&&(k=b.bottom-d);g.x=isFinite(h)?h:-a.zb.left;g.y=isFinite(k)?k:-a.zb.top;null!==a.positionComputation&&(b=a.positionComputation(a,g),g.x=b.x,g.y=b.y);Ah(a.Yd,a.pa,g);a.pa.h(g.x,g.y);Fi(a);I.free(g)}
t.Ol=function(a,b){void 0===b&&(b=!0);if(b){if(a=qf(this,a,function(a){return a.part},function(a){return a.canSelect()}),a instanceof U)return a}else if(a=qf(this,a,function(a){return a.part}),a instanceof U)return a;return null};t.jc=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);jh(this);for(var d=this.Wa.iteratorBackwards;d.next();){var e=d.value;if(e.visible&&(e=e.jc(a,b,c),null!==e))return e}return null};
function qf(a,b,c,d){void 0===c&&(c=null);void 0===d&&(d=null);jh(a);for(a=a.Wa.iteratorBackwards;a.next();){var e=a.value;if(e.visible&&!e.isTemporary&&(e=e.jc(b,c,d),null!==e))return e}return null}t.mz=function(a,b,c){void 0===b&&(b=!0);return pj(this,a,function(a){return a.part},b?function(a){return a instanceof U&&a.canSelect()}:null,c)};
function pj(a,b,c,d,e){void 0===c&&(c=null);void 0===d&&(d=null);e instanceof F||e instanceof H||(e=new H);jh(a);for(a=a.Wa.iteratorBackwards;a.next();){var f=a.value;f.visible&&!f.isTemporary&&f.ji(b,c,d,e)}return e}t.ji=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof F||d instanceof H||(d=new H);jh(this);for(var e=this.Wa.iteratorBackwards;e.next();){var f=e.value;f.visible&&f.ji(a,b,c,d)}return d};
t.vx=function(a,b,c,d){void 0===b&&(b=!1);void 0===c&&(c=!0);return qj(this,a,function(a){return a instanceof U&&(!c||a.canSelect())},b,d)};t.Cf=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof F||e instanceof H||(e=new H);jh(this);for(var f=this.Wa.iteratorBackwards;f.next();){var g=f.value;g.visible&&g.Cf(a,b,c,d,e)}return e};
t.Ou=function(a,b,c,d,e,f){var g=new H;jh(this);for(var h=this.Wa.iteratorBackwards;h.next();){var k=h.value;k.visible&&k.Ou(a,b,c,d,g,e,f)}return g};function qj(a,b,c,d,e){var f=null;void 0===f&&(f=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof F||e instanceof H||(e=new H);jh(a);for(a=a.Wa.iteratorBackwards;a.next();){var g=a.value;g.visible&&!g.isTemporary&&g.Cf(b,f,c,d,e)}return e}
t.nz=function(a,b,c,d,e){void 0===c&&(c=!0);void 0===d&&(d=!0);return rj(this,a,b,function(a){return a instanceof U&&(!d||a.canSelect())},c,e)};t.og=function(a,b,c,d,e,f){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof F||e instanceof H)f=e;e=!0}f instanceof F||f instanceof H||(f=new H);jh(this);for(var g=this.Wa.iteratorBackwards;g.next();){var h=g.value;h.visible&&h.og(a,b,c,d,e,f)}return f};
function rj(a,b,c,d,e,f){var g=null;void 0===g&&(g=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof F||e instanceof H)f=e;e=!0}f instanceof F||f instanceof H||(f=new H);jh(a);for(a=a.Wa.iteratorBackwards;a.next();){var h=a.value;h.visible&&!h.isTemporary&&h.og(b,c,g,d,e,f)}return f}Q.prototype.acceptEvent=function(a){return Li(this,a,a instanceof MouseEvent)};
function Li(a,b,c){var d=a.Qg;a.Qg=a.cj;a.cj=d;d.diagram=a;d.event=b;c?Oi(a,b,d):(d.viewPoint=a.Qg.viewPoint,d.documentPoint=a.Qg.documentPoint);a=0;b.ctrlKey&&(a+=1);b.altKey&&(a+=2);b.shiftKey&&(a+=4);b.metaKey&&(a+=8);d.modifiers=a;d.button=b.button;void 0!==b.buttons&&(d.buttons=b.buttons);Pa&&0===b.button&&b.ctrlKey&&(d.button=2);d.down=!1;d.up=!1;d.clickCount=1;d.delta=0;d.handled=!1;d.bubbles=!1;d.timestamp=b.timeStamp;d.isMultiTouch=!1;d.targetDiagram=Pi(b,b.target);d.targetObject=null;return d}
function Pi(a,b){if(null===b)return null;if(b=b.H)return b;(b=a.path)||"function"!==typeof a.composedPath||(b=a.composedPath());return b&&b[0]&&b[0].H?b[0].H:null}
function Ni(a,b,c,d,e,f){var g=a.Qg;a.Qg=a.cj;a.cj=g;g.diagram=a;g.clickCount=1;var h=g.delta=0;b.ctrlKey&&(h+=1);b.altKey&&(h+=2);b.shiftKey&&(h+=4);b.metaKey&&(h+=8);g.modifiers=h;g.event=b;g.timestamp=b.timeStamp;g.button=d?a.firstInput.button:b.button;g.buttons=b.buttons;Pa&&0===b.button&&b.ctrlKey&&(g.button=2);g.down=c;g.up=d;g.handled=!1;g.bubbles=e;g.isMultiTouch=f;return g}
function Mi(a,b){if(a.bubbles)return!0;void 0!==b.stopPropagation&&b.stopPropagation();!1!==b.cancelable&&b.preventDefault();b.cancelBubble=!0;return!1}
Q.prototype.Rz=function(a){var b=this.H;if(!this.H.isEnabled)return!1;var c=Li(b,a,!1);c.key=String.fromCharCode(a.which);c.down=!0;switch(a.which){case 8:c.key="Backspace";break;case 33:c.key="PageUp";break;case 34:c.key="PageDown";break;case 35:c.key="End";break;case 36:c.key="Home";break;case 37:c.key="Left";break;case 38:c.key="Up";break;case 39:c.key="Right";break;case 40:c.key="Down";break;case 45:c.key="Insert";break;case 46:c.key="Del";break;case 48:c.key="0";break;case 187:case 61:case 107:c.key=
"Add";break;case 189:case 173:case 109:c.key="Subtract";break;case 27:c.key="Esc"}b.doKeyDown();return Mi(c,a)};
Q.prototype.Sz=function(a){var b=this.H;if(!b.isEnabled)return!1;var c=Li(b,a,!1);c.key=String.fromCharCode(a.which);c.up=!0;switch(a.which){case 8:c.key="Backspace";break;case 33:c.key="PageUp";break;case 34:c.key="PageDown";break;case 35:c.key="End";break;case 36:c.key="Home";break;case 37:c.key="Left";break;case 38:c.key="Up";break;case 39:c.key="Right";break;case 40:c.key="Down";break;case 45:c.key="Insert";break;case 46:c.key="Del"}b.doKeyUp();return Mi(c,a)};
Q.prototype.cz=function(a){var b=this.H;if(!b.isEnabled)return!1;var c=Li(b,a,!0);null!==b.mouseEnter&&b.mouseEnter(c);b.currentTool.standardMouseOver();return Mi(c,a)};Q.prototype.dz=function(a){var b=this.H;if(!b.isEnabled)return!1;var c=Li(b,a,!0);null!==b.mouseLeave&&b.mouseLeave(c);return Mi(c,a)};
Q.prototype.getMouse=function(a){var b=this.ta;if(null===b)return new I(0,0);var c=b.getBoundingClientRect();b=this.Aa/c.width*(a.clientX-c.left);a=this.ya/c.height*(a.clientY-c.top);return null!==this.Bb?xb(new I(b,a),this.Bb):new I(b,a)};
function Oi(a,b,c){var d=a.ta,e=0,f=0;null!==d&&(d=d.getBoundingClientRect(),e=a.Aa/d.width*(b.clientX-d.left),f=a.ya/d.height*(b.clientY-d.top));c.viewPoint.h(e,f);null!==a.Bb?(b=I.allocAt(e,f),a.Bb.Cd(b),c.documentPoint.assign(b),I.free(b)):c.documentPoint.h(e,f)}function Ud(a,b,c){if(null!==a.oj[0]){b=a.oj[b];var d=a.ta;null!==d&&(d=d.getBoundingClientRect(),c.h(a.Aa/d.width*(b.clientX-d.left),a.ya/d.height*(b.clientY-d.top)))}}Q.prototype.Ya=function(){this.Zi||(this.Zi=!0,this.Rb(!1))};
function sj(a){a.ld||jh(a);a.Va()}Q.prototype.redraw=function(){this.wb||this.ld||(this.N(),this.bd())};t=Q.prototype;t.Qz=function(){return this.kg};t.Yy=function(a){void 0===a&&(a=null);var b=this.animationManager,c=b.isEnabled;b.Cc();b.isEnabled=!1;yf(this);this.hf=!1;this.du=new I(NaN,NaN);b.isEnabled=c;this.ld=!0;var d=this;null!==a&&ta(function(){d.ld=!1;ih(b,"Model");a(d)},1)};
t.Rb=function(a){void 0===a&&(a=!1);if(!0!==this.kg&&!(this.wb||!1===a&&this.ld)){this.kg=!0;var b=this;x.requestAnimationFrame(function(){b.kg&&b.bd()})}};t.bd=function(){if(!this.fn||this.kg)this.fn&&(this.fn=!1),yf(this)};function tj(a,b,c){!a.Ee||a.wb||a.animationManager.defaultAnimation.isAnimating||ij(a)||(b&&jh(a),c&&kj(a,!1))}
function yf(a,b){if(!a.ld&&(a.kg=!1,null!==a.Ka||a.wo.w())){a.ld=!0;var c=a.animationManager,d=a.Xn;if(!c.isAnimating&&0!==d.length){for(var e=d.s,f=e.length,g=0;g<f;g++){var h=e[g];uj(h,!1);h.v()}d.clear()}d=a.pw;0<d.count&&(d.each(function(a){a.Ov()}),d.clear());e=d=!1;c.defaultAnimation.isAnimating&&(e=!0,d=a.skipsUndoManager,a.skipsUndoManager=!0);c.Ab||ph(a);tj(a,!1,!0);null!==a.fe&&(a.fe.visible&&!a.mq&&(vj(a),a.mq=!0),!a.fe.visible&&a.mq&&(a.mq=!1));jh(a);f=!1;if(!a.hf||a.xh)a.hf?wj(a,!a.Ik):
(a.Ea("Initial Layout"),!1===c.isEnabled&&c.Cc(),wj(a,!1)),f=!0;a.Ik=!1;jh(a);a.ou||sj(a);tj(a,!0,!1);g=!1;f?(c=N.alloc(),c.assign(a.viewportBounds),a.hf||(g=a.hf=!0,a.skipsUndoManager||(a.undoManager.isPendingClear=!0),a.undoManager.isPendingUnmodified=!0,xj(a)),a.S("LayoutCompleted"),tj(a,!0,!1),N.free(c)):c.Nk&&c.Rw&&(a.Fg!==oh?a.scale=lj(a,a.Fg):a.yh!==oh?a.scale=lj(a,a.yh):(c=a.initialScale,isFinite(c)&&0<c&&(a.scale=c)),kj(a,!0));f&&g&&a.eb("Initial Layout");jh(a);b||a.hc(a.ib);e&&(a.skipsUndoManager=
d);a.ld=!1}}function xj(a){if(a.Fg!==oh)a.scale=lj(a,a.Fg);else if(a.yh!==oh)a.scale=lj(a,a.yh);else{var b=a.initialScale;isFinite(b)&&0<b&&(a.scale=b)}kj(a,!0);b=a.Wa.s;a.Fd(b,b.length,a,a.viewportBounds);a.S("InitialLayoutCompleted");a.du.assign(a.pa);vj(a)}
function jh(a){if(0!==a.De.count&&(a.ld||!a.animationManager.isTicking)){for(var b=0;23>b;b++){var c=a.De.iterator;if(null===c||0===a.De.count)break;a.De=new H;a.Ov(c,a.De)}a.nodes.each(function(a){a instanceof jf&&0!==(a.R&65536)!==!1&&(a.R=a.R^65536)})}}
t.Ov=function(a,b){for(a.reset();a.next();){var c=a.value;!c.Zc()||c instanceof jf||(c.si()?(c.measure(Infinity,Infinity),c.arrange()):b.add(c))}for(a.reset();a.next();)c=a.value,c instanceof jf&&c.isVisible()&&yj(this,c);for(a.reset();a.next();)c=a.value,c instanceof T&&c.isVisible()&&(c.si()?(c.measure(Infinity,Infinity),c.arrange()):b.add(c));for(a.reset();a.next();)c=a.value,c instanceof pe&&c.isVisible()&&(c.si()?(c.measure(Infinity,Infinity),c.arrange()):b.add(c))};
function yj(a,b){for(var c=Ea(),d=Ea(),e=b.memberParts;e.next();){var f=e.value;f.isVisible()&&(f instanceof jf?(zj(f)||Aj(f)||Bj(f))&&yj(a,f):f instanceof T?f.fromNode===b||f.toNode===b?d.push(f):c.push(f):(f.measure(Infinity,Infinity),f.arrange()))}a=c.length;for(e=0;e<a;e++)f=c[e],f.measure(Infinity,Infinity),f.arrange();Ga(c);b.measure(Infinity,Infinity);b.arrange();a=d.length;for(b=0;b<a;b++)c=d[b],c.measure(Infinity,Infinity),c.arrange();Ga(d)}
t.Fd=function(a,b,c,d){if(this.vd||this.animationManager.isAnimating)for(var e=0;e<b;e++)a[e].Fd(c,d)};
t.hc=function(a){if(null!==this.Ka)if(null===this.ta&&C("No canvas specified"),"svg"===this.nl){if(null!==this.Ka){var b=this.animationManager;if(!b.Ab&&(!b.isAnimating||b.isTicking)){Cj(this);var c=this.Aa,d=this.ya;a.Hv.resize(c,d,c,d);c=this.Wa.s;d=c.length;this.Fd(c,d,this,this.viewportBounds);if(this.Fc||b.isAnimating){b=this.pa;var e=this.Ca,f=Math.round(b.x*e)/e,g=Math.round(b.y*e)/e,h=this.Bb;h.reset();1!==e&&h.scale(e);0===b.x&&0===b.y||h.translate(-f,-g);a.ti=null;Dj(a,h.m11,h.m12,h.m21,
h.m22,h.dx,h.dy);1!==this.pb&&(a.globalAlpha=this.pb);Ej(this,a);b=this.Nb;this.ta.context.setTransform(b,0,0,b,0,0);this.ta.context.clearRect(0,0,this.Aa,this.ya);for(b=0;b<d;b++)c[b].hc(a,this);a=!1;this.Yf?Fj(this.Yf,this)&&(a=this.Vp()):a=!0;a&&(ld.prototype.Cd=ld.prototype.Fu);this.Fc=this.vd=!1}}}}else a:if(f=this.animationManager,!f.Ab&&(!f.isAnimating||f.isTicking)&&(c=new Date,Cj(this),"0"!==this.Ka.style.opacity)){d=a!==this.ib;b=this.Wa.s;e=b.length;this.Fd(b,e,this,this.viewportBounds);
if(d)a.Vc(!0),this.vd=!0;else if(!this.Fc&&!f.isAnimating)break a;f=this.Bb;f.reset();g=this.Nb;h=this.pa;var k=this.Ca,l=Math.round(h.x*k)/k,m=Math.round(h.y*k)/k;1!==k&&f.scale(k);0===h.x&&0===h.y||f.translate(-l,-m);a.setTransform(g,0,0,g,0,0);a.clearRect(0,0,this.Aa,this.ya);1!==this.pb&&(a.globalAlpha=this.pb);Ej(this,a);for(h=0;h<e;h++)a.setTransform(g,0,0,g,0,0),a.transform(f.m11,f.m12,f.m21,f.m22,f.dx,f.dy),a.yc(),b[h].hc(a,this);b=!1;this.Yf?Fj(this.Yf,this)&&(b=this.Vp()):b=!0;b&&(ld.prototype.Cd=
ld.prototype.Fu);d?(this.ib.Vc(!0),this.vd=!0):this.Fc=this.vd=!1;b=+new Date-+c;d=Wa&&Wa.ox;c=this.Aw;if(d||null===this.Yh){c[this.Tq]=b;this.Tq=(this.Tq+1)%c.length;for(e=b=0;e<c.length;e++)b+=c[e];this.Sq=b/c.length}1!==this.pb&&(a.globalAlpha=1);if(d)for(a.setTransform(1,0,0,1,0,0),d=0;d<c.length;d++)a.fillText(c[d].toString(),20,150+ +(20*d))}};
function Gj(a,b,c,d,e,f,g,h,k,l){if(null!==a.Ka){null===a.ta&&C("No canvas specified");void 0===g&&(g=null);void 0===h&&(h=null);void 0===k&&(k=!1);void 0===l&&(l=!1);Cj(a);a.ib.Vc(!0);a.vd=!0;a.$i=!0;var m=a.Ca;a.Ca=e;var n=a.Wa.s,p=n.length;try{var q=new N(f.x,f.y,d.width/e,d.height/e),r=q.copy();r.Co(c);vj(a,r);jh(a);a.Fd(n,p,a,q);b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,d.width,d.height);null!==h&&""!==h&&(b.fillStyle=h,b.px(0,0,d.width,d.height));var u=a.Nb;b.scale(u,u);var w=ld.alloc();w.reset();
w.translate(c.left,c.top);w.scale(e);0===f.x&&0===f.y||w.translate(-f.x,-f.y);b.setTransform(w.m11,w.m12,w.m21,w.m22,w.dx,w.dy);b.yc();b instanceof ii&&Dj(b,w.m11,w.m12,w.m21,w.m22,w.dx,w.dy);ld.free(w);Ej(a,b);b.globalAlpha=a.pb;var v=a.Pj("Grid");if(g){var y=new H,z=g.iterator;for(z.reset();z.next();){var A=z.value;A instanceof U&&(l||A.layer!==v)&&(A.Fj(),y.add(A))}for(l=0;l<p;l++)hi(n[l],b,a,y,k);y.each(function(a){return a.Fj()})}else for(y=0;y<p;y++){var B=n[y];B===v?l&&B.hc(b,a,!0):B.hc(b,
a,k)}a.$i=!1;a.Yf&&Fj(a.Yf,a)&&a.Vp()}finally{a.Ca=m,a.ib.Vc(!0),a.vd=!0,a.Fd(n,p,a,a.viewportBounds),vj(a),a.ib instanceof ii&&(a.ib.Mj.replaceChildren(),a.redraw())}}}t.Gf=function(a){return this.Rg[a]};t.Yx=function(a,b){"minDrawingLength"===a&&(this.Oq=b);this.Rg[a]=b;this.redraw()};
t.Ux=function(){this.Rg=new Ua;this.Rg.drawShadows=!0;this.Rg.textGreeking=!0;this.Rg.viewportOptimizations=Pa||Na?!1:!0;this.Rg.temporaryPixelRatio=!0;this.Rg.pictureRatioOptimization=!0;this.Oq=this.Rg.minDrawingLength=1};function Ej(a,b){a=a.Rg;null!==a&&(void 0!==a.imageSmoothingEnabled&&b.Xx(!!a.imageSmoothingEnabled),a=a.defaultFont,void 0!==a&&null!==a&&(b.font=a))}t.Ql=function(a){return this.on[a]};t.tA=function(a,b){this.on[a]=b};
t.Tx=function(){this.on=new Ua;this.on.extraTouchArea=10;this.on.extraTouchThreshold=10;this.on.hasGestureZoom=!0};t.set=function(a){Object.assign(this,a);return this};t.Eu=function(a){return this.nm(a)};t.nm=function(a){Hj(this,a);return this};
function Hj(a,b){var c=a instanceof W,d=a instanceof Q,e;for(e in b){""===e&&C("Setting properties requires non-empty property names");var f=a,g=e;if(c||d){var h=e.indexOf(".");if(0<h){var k=e.substring(0,h);if(c)f=a.fb(k);else if(f=a[k],void 0===f||null===f)f=a.toolManager[k];za(f)?g=e.substr(h+1):C("Unable to find object named: "+k+" in "+a.toString()+" when trying to set property: "+e)}}if("_"!==g[0]&&!Ja(f,g))if(d&&"ModelChanged"===g){a.Zw(b[g]);continue}else if(d&&"Changed"===g){a.Pe(b[g]);continue}else if(d&&
Ja(a.toolManager,g))f=a.toolManager;else if(d&&Ij(a,g)){a.Bj(g,b[g]);continue}else if(a instanceof Z&&"Changed"===g){a.Pe(b[g]);continue}else C('Trying to set undefined property "'+g+'" on object: '+f.toString());f[g]=b[e];"_"===g[0]&&f instanceof Y&&f.Ww(g)}}t.ex=function(){if(0!==this.Eh.count&&!(1<this.undoManager.transactionLevel)){for(;0<this.Eh.count;){var a=this.Eh;this.Eh=new ob;for(a=a.iterator;a.next();){var b=a.key;b.Xo(a.value);b.rc()}}this.N()}};
t.N=function(a){if(void 0===a)this.Fc=!0,this.Rb();else{var b=this.viewportBounds;a.w()&&b.Yc(a)&&(this.Fc=!0,this.Rb())}this.S("InvalidateDraw")};
Q.prototype.invalidateViewport=function(a,b){if(!0!==this.Fc)if(this.Fc=!0,"svg"===this.nl)this.bd();else{var c=!0===this.Gf("temporaryPixelRatio");if(!0===this.Gf("viewportOptimizations")&&this.scrollMode!==Th&&this.rj.hi(0,0,0,0)&&b.width===a.width&&b.height===a.height){var d=this.scale,e=Math.max(a.y,b.y),f=Math.min(a.y+a.height,b.y+b.height);if(0<Math.max(0,Math.min(a.x+a.width,b.x+b.width)-Math.max(a.x,b.x))*d&&0<Math.max(0,f-e)*d){if(!(this.ld||(this.kg=!1,null===this.Ka||(this.ld=!0,this.documentBounds.w()||
(d=N.alloc(),Jj(this,this.computeBounds(d)),N.free(d)),d=this.ta,null===d||d instanceof Kj)))){var g=this.Nb;e=this.Aa*g;var h=this.ya*g,k=this.scale*g;f=Math.round(Math.round(b.x*k)-Math.round(a.x*k));b=Math.round(Math.round(b.y*k)-Math.round(a.y*k));k=this.kw;a=this.py;k.width!==e&&(k.width=e);k.height!==h&&(k.height=h);a.clearRect(0,0,e,h);k=190*g;var l=70*g,m=Math.max(f,0),n=Math.max(b,0),p=Math.floor(e-m),q=Math.floor(h-n);a.drawImage(d.Oa,m,n,p,q,0,0,p,q);Fj(this.Yf,this)&&a.clearRect(0,0,k,
l);d=Ea();a=Ea();q=Math.abs(f);p=Math.abs(b);var r=0===m?0:e-q;m=I.allocAt(r,0);q=I.allocAt(q+r,h);a.push(new N(Math.min(m.x,q.x),Math.min(m.y,q.y),Math.abs(m.x-q.x),Math.abs(m.y-q.y)));var u=this.Bb;u.reset();u.scale(g,g);1!==this.Ca&&u.scale(this.Ca);g=this.pa;(0!==g.x||0!==g.y)&&isFinite(g.x)&&isFinite(g.y)&&u.translate(-g.x,-g.y);xb(m,u);xb(q,u);d.push(new N(Math.min(m.x,q.x),Math.min(m.y,q.y),Math.abs(m.x-q.x),Math.abs(m.y-q.y)));r=0===n?0:h-p;m.h(0,r);q.h(e,p+r);a.push(new N(Math.min(m.x,q.x),
Math.min(m.y,q.y),Math.abs(m.x-q.x),Math.abs(m.y-q.y)));xb(m,u);xb(q,u);d.push(new N(Math.min(m.x,q.x),Math.min(m.y,q.y),Math.abs(m.x-q.x),Math.abs(m.y-q.y)));Fj(this.Yf,this)&&(e=0<f?0:-f,h=0<b?0:-b,m.h(e,h),q.h(k+e,l+h),a.push(new N(Math.min(m.x,q.x),Math.min(m.y,q.y),Math.abs(m.x-q.x),Math.abs(m.y-q.y))),xb(m,u),xb(q,u),d.push(new N(Math.min(m.x,q.x),Math.min(m.y,q.y),Math.abs(m.x-q.x),Math.abs(m.y-q.y))));I.free(m);I.free(q);tj(this,!1,!0);null===this.Ka&&C("No div specified");null===this.ta&&
C("No canvas specified");if(!this.animationManager.Ab&&(e=this.ib,this.Fc)){Cj(this);h=this.Nb;e.setTransform(1,0,0,1,0,0);e.clearRect(0,0,this.Aa*h,this.ya*h);e.drawImage(this.kw.Oa,0<f?0:Math.round(-f),0<b?0:Math.round(-b));f=this.pa;g=this.Ca;k=Math.round(f.x*g)/g;l=Math.round(f.y*g)/g;b=this.Bb;b.reset();1!==g&&b.scale(g);0===f.x&&0===f.y||b.translate(-k,-l);e.save();e.beginPath();f=a.length;for(g=0;g<f;g++)k=a[g],0!==k.width&&0!==k.height&&e.rect(Math.floor(k.x),Math.floor(k.y),Math.ceil(k.width),
Math.ceil(k.height));e.clip();e.setTransform(h,0,0,h,0,0);e.transform(b.m11,b.m12,b.m21,b.m22,b.dx,b.dy);e.yc();b=this.Wa.s;f=b.length;this.Fd(b,f,this,this.viewportBounds);Ej(this,e);e.globalAlpha=this.pb;for(h=0;h<f;h++)if(g=b[h],k=d,g.visible&&0!==g.pb){l=gi(g,e);n=g.Zq;n.length=0;m=this.scale;p=N.alloc();q=g.Pa.s;u=q.length;r=k.length;for(var w=0;w<u;w++){var v=q[w],y=Lj(v,v.actualBounds);a:{for(var z=2/m,A=4/m,B=0;B<r;B++){var E=k[B];if(0!==E.width&&0!==E.height&&y.Wu(E.x-z,E.y-z,E.width+A,E.height+
A)){y=!0;break a}}y=!1}y&&g.Nj(e,v,this,n,m,p,!0)}N.free(p);e.globalAlpha=l}e.restore();e.Vc(!0);this.Yf&&Fj(this.Yf,this)&&this.Vp();this.Fc=this.vd=!1;this.gk()}Ga(d);Ga(a);this.ld=!1}}else this.bd();c&&(Ie(this),this.bd(),Ze(this,!0))}else c?(Ie(this),this.bd(),Ze(this,!0)):this.bd()}};function Cj(a){!1!==a.pn&&(a.pn=!1,Ii(a,a.Aa,a.ya))}function Ii(a,b,c){Fi(a);var d=a.Nb;a.ta.resize(b*d,c*d,b,c)&&(a.Fc=!0,a.ib.Vc(!0))}
function ij(a){var b=a.ta;if(null===b||a.wo.w())return!0;var c=a.Aa,d=a.ya,e=a.Ka,f=!1,g=a.gf?a.Eb:0,h=a.Be?a.Eb:0,k=e.clientWidth||c+g,l=e.clientHeight||d+h,m=a.viewportBounds;e=m.x;var n=m.y,p=m.width,q=m.height;if(k!==c+g||l!==d+h)a.gf=!1,a.Be=!1,h=g=0,a.Aa=k,a.ya=l,f=a.pn=!0,Fi(a);if(!a.hf||!a.viewportBounds.w())return!0;var r=a.ku,u=a.hu;a.Ee=!1;if(!(f||a.gf||a.Be||a.mn||a.nn))return!0;var w=a.documentBounds,v=0,y=0,z=0,A=0,B=m.width,E=m.height,L=a.rj;a.contentAlignment.sb()?(w.width>B&&(v=L.left,
y=L.right),w.height>E&&(z=L.top,A=L.bottom)):(v=L.left,y=L.right,z=L.top,A=L.bottom);var R=w.width+v+y,S=w.height+z+A;L=a.scale;var J=R>k/L,G=S>l/L;if(!(f||a.gf||a.Be||J||G))return!0;v=w.x-v;k=m.x;y=w.right+y;l=m.right+g;z=w.y-z;var ca=m.y;w=w.bottom+A;m=m.bottom+h;var X="1px";A="1px";a.scrollMode===Fh&&(J||G)&&(J&&a.hasHorizontalScrollbar&&a.allowHorizontalScroll&&(J=1,v+1<k&&(J=Math.max((k-v)*L+a.Aa,J)),y>l+1&&(J=Math.max((y-l)*L+a.Aa,J)),B+g+1<R&&(J=Math.max((R-B)*L+a.Aa,J)),X=J.toString()+"px"),
G&&a.hasVerticalScrollbar&&a.allowVerticalScroll&&(J=1,z+1<ca&&(J=Math.max((ca-z)*L+a.ya,J)),w>m+1&&(J=Math.max((w-m)*L+a.ya,J)),E+h+1<S&&(J=Math.max((S-E)*L+a.ya,J)),A=J.toString()+"px"));J="1px"!==X;G="1px"!==A;J&&G||!J&&!G||(G&&(l-=a.Eb),J&&(m-=a.Eb),R<B+g||!a.hasHorizontalScrollbar||!a.allowHorizontalScroll||(g=1,v+1<k&&(g=Math.max((k-v)*L+a.Aa,g)),y>l+1&&(g=Math.max((y-l)*L+a.Aa,g)),B+1<R&&(g=Math.max((R-B)*L+a.Aa,g)),X=g.toString()+"px"),J="1px"!==X,g=a.ya,J!==a.Be&&(g=J?a.ya-a.Eb:a.ya+a.Eb),
S<E+h||!a.hasVerticalScrollbar||!a.allowVerticalScroll||(h=1,z+1<ca&&(h=Math.max((ca-z)*L+g,h)),w>m+1&&(h=Math.max((w-m)*L+g,h)),E+1<S&&(h=Math.max((S-E)*L+g,h)),A=h.toString()+"px"),G="1px"!==A);if(a.qr&&J===a.Be&&G===a.gf)return c===a.Aa&&d===a.ya||a.bd(),!1;J!==a.Be&&("1px"===X?a.ya=a.ya+a.Eb:a.ya=Math.max(a.ya-a.Eb,1),f=!0);a.Be=J;u.style.width=X;G!==a.gf&&("1px"===A?a.Aa=a.Aa+a.Eb:a.Aa=Math.max(a.Aa-a.Eb,1),f=!0,a.tn&&(h=I.alloc(),G?(b.style.left=a.Eb+"px",a.position=h.h(a.pa.x+a.Eb/a.scale,
a.pa.y)):(b.style.left="0px",a.position=h.h(a.pa.x-a.Eb/a.scale,a.pa.y)),I.free(h)));f&&Fi(a);a.gf=G;u.style.height=A;a.gu=!0;f&&(a.pn=!0);b=r.scrollLeft;a.hasHorizontalScrollbar&&a.allowHorizontalScroll&&(B+1<R?b=(a.position.x-v)*L:v+1<k?b=r.scrollWidth-r.clientWidth:y>l+1&&(b=a.position.x*L));if(a.tn)switch(a.bu){case "negative":b=-(r.scrollWidth-b-r.clientWidth);break;case "reverse":b=r.scrollWidth-b-r.clientWidth}r.scrollLeft=b;a.hasVerticalScrollbar&&a.allowVerticalScroll&&(E+1<S?r.scrollTop=
(a.position.y-z)*L:z+1<ca?r.scrollTop=r.scrollHeight-r.clientHeight:w>m+1&&(r.scrollTop=a.position.y*L));k=a.Aa;l=a.ya;r.style.width=k+(a.gf?a.Eb:0)+"px";r.style.height=l+(a.Be?a.Eb:0)+"px";return c!==k||d!==l||a.animationManager.Ab?(c=N.allocAt(e,n,p,q),a.onViewportBoundsChanged(c,a.viewportBounds,L,f),N.free(c),!1):!0}t=Q.prototype;
t.add=function(a){var b=a.diagram;if(b!==this&&(null!==b&&C("Cannot add part "+a.toString()+" to "+this.toString()+". It is already a part of "+b.toString()),b=this.Pj(a.layerName),null===b&&(b=this.Pj("")),null===b&&C('Cannot add a Part when unable find a Layer named "'+a.layerName+'" and there is no default Layer'),a.layer!==b)){var c=b.oi(99999999,a,a.diagram===this);0<=c&&this.cb(fe,"parts",b,null,a,null,c);b.isTemporary||this.Ya();a.C(1);c=a.layerChanged;null!==c&&c(a,null,b)}};
t.oi=function(a){this.partManager.oi(a);var b=this;Mj(a,function(a){Nj(b,a)});(a instanceof pe||a instanceof jf&&null!==a.placeholder)&&a.v();null!==a.data&&Mj(a,function(a){Oj(b.partManager,a)});!0!==Aj(a)&&!0!==Bj(a)||this.De.add(a);Pj(a,!0,this);Qj(a)?(a.actualBounds.w()&&this.N(Lj(a,a.actualBounds)),this.Ya()):a.isVisible()&&a.actualBounds.w()&&this.N(Lj(a,a.actualBounds));this.Rb()};
t.qc=function(a){a.Dj();this.partManager.qc(a);var b=this;null!==a.data&&Mj(a,function(a){Rj(b.partManager,a,b)});this.De.remove(a);Qj(a)?(a.actualBounds.w()&&this.N(Lj(a,a.actualBounds)),this.Ya()):a.isVisible()&&a.actualBounds.w()&&this.N(Lj(a,a.actualBounds));this.Rb()};t.remove=function(a){Sj(this,a,!0)};
function Sj(a,b,c){var d=b.layer;null!==d&&d.diagram===a&&(b.isSelected=!1,b.isHighlighted=!1,b.C(2),c&&b.Kj(),c=d.qc(-1,b,!1),0<=c&&a.cb(ge,"parts",d,b,null,c,null),a=b.layerChanged,null!==a&&a(b,d,null))}t.Is=function(a,b){void 0===b&&(b=!1);if(Aa(a))for(var c=a.length,d=0;d<c;d++){var e=a[d];b&&!e.canDelete()||this.remove(e)}else for(c=new H,c.addAll(a),a=c.iterator;a.next();)c=a.value,b&&!c.canDelete()||this.remove(c)};t.Jj=function(a,b,c){void 0===c&&(c=!1);return this.partManager.Jj(a,b,c)};
Q.prototype.moveParts=function(a,b,c,d){void 0===c&&(c=!1);void 0===d&&(d=Tj(this));if(null!==this.toolManager){var e=new ob;if(null!==a)if(Aa(a))for(var f=0;f<a.length;f++)Uj(this,e,a[f],c,d);else for(a=a.iterator;a.next();)Uj(this,e,a.value,c,d);else{for(a=this.parts;a.next();)Uj(this,e,a.value,c,d);for(a=this.nodes;a.next();)Uj(this,e,a.value,c,d);for(a=this.links;a.next();)Uj(this,e,a.value,c,d)}ff(this,e,b,d,c)}};
function Uj(a,b,c,d,e,f){if(!b.contains(c)&&(void 0===f&&(f=!1),!d||f||c.canMove()||c.canCopy()))if(void 0===e&&(e=Tj(a)),c instanceof V){b.add(c,a.Pd(e,c,c.location));if(c instanceof jf&&(null!==c.placeholder||e.dragsMembers))for(f=c.memberParts;f.next();)Uj(a,b,f.value,d,e,e.groupsAlwaysMove);for(f=c.linksConnected;f.next();){var g=f.value;if(!b.contains(g)){var h=g.fromNode,k=g.toNode;null!==h&&b.contains(h)&&null!==k&&b.contains(k)&&Uj(a,b,g,d,e)}}if(e.dragsTree)for(c=c.Pu();c.next();)Uj(a,b,
c.value,d,e)}else if(c instanceof T)for(b.add(c,a.Pd(e,c)),c=c.labelNodes;c.next();)Uj(a,b,c.value,d,e);else c instanceof pe||b.add(c,a.Pd(e,c,c.location))}
function ff(a,b,c,d,e){if(null!==b&&0!==b.count){var f=I.alloc(),g=I.alloc();g.assign(c);isNaN(g.x)&&(g.x=0);isNaN(g.y)&&(g.y=0);(c=a.Mr)||Je(a,b);for(var h=Ea(),k=Ea(),l=b.iterator,m=I.alloc();l.next();){var n=l.key,p=l.value;if(n.Zc()){var q=Vj(a,n,b);if(null!==q)h.push(new Wj(n,p,q));else if(!e||n.canMove())q=p.point,f.assign(q),a.computeMove(n,f.add(g),d,m),n.location=m,void 0===p.shifted&&(p.shifted=new I),p.shifted.assign(m.re(q))}else l.key instanceof T&&k.push(l.sa)}I.free(m);e=h.length;for(l=
0;l<e;l++)n=h[l],f.assign(n.info.point),void 0===n.Tu.shifted&&(n.Tu.shifted=new I),n.node.location=f.add(n.Tu.shifted);e=I.alloc();l=I.alloc();n=k.length;for(p=0;p<n;p++){var r=k[p];q=r.key;if(q instanceof T)if(q.suspendsRouting){q.Tg=null;m=q.fromNode;var u=q.toNode;if(null!==a.draggedLink&&d.dragsLink)if(u=r.value.point,null===q.dragComputation)b.add(q,a.Pd(d,q,g)),df(q,g.x-u.x,g.y-u.y);else{r=I.allocAt(0,0);(m=q.i(0))&&m.w()&&r.assign(m);var w=m=I.alloc().assign(r).add(g);d.isGridSnapEnabled&&
(d.isGridSnapRealtime||a.lastInput.up)&&(w=I.alloc(),rg(a,q,m,w,d));m.assign(q.dragComputation(q,m,w)).re(r);b.add(q,a.Pd(d,q,m));df(q,m.x-u.x,m.y-u.y);I.free(r);I.free(m);w!==m&&I.free(w)}else null!==m&&(e.assign(m.location),w=b.I(m),null!==w&&e.re(w.point)),null!==u&&(l.assign(u.location),w=b.I(u),null!==w&&l.re(w.point)),null!==m&&null!==u?e.Xa(l)?(m=r.value.point,u=f,u.assign(e),u.re(m),b.add(q,a.Pd(d,q,e)),df(q,u.x,u.y)):(q.suspendsRouting=!1,q.bb()):(r=r.value.point,m=null!==m?e:null!==u?l:
g,b.add(q,a.Pd(d,q,m)),df(q,m.x-r.x,m.y-r.y))}else if(null===q.fromNode||null===q.toNode)m=r.value.point,b.add(q,a.Pd(d,q,g)),df(q,g.x-m.x,g.y-m.y)}I.free(f);I.free(g);I.free(e);I.free(l);Ga(h);Ga(k);c||(jh(a),Oe(a,b))}}
Q.prototype.computeMove=function(a,b,c,d){void 0===d&&(d=new I);d.assign(b);if(null===a)return d;var e=b,f=c.isGridSnapEnabled;f&&(c.isGridSnapRealtime||this.lastInput.up)&&(e=I.alloc(),rg(this,a,b,e,c));c=null!==a.dragComputation?a.dragComputation(a,b,e):e;var g=a.minLocation,h=g.x;isNaN(h)&&(h=f?Math.round(1E3*a.location.x)/1E3:a.location.x);g=g.y;isNaN(g)&&(g=f?Math.round(1E3*a.location.y)/1E3:a.location.y);var k=a.maxLocation,l=k.x;isNaN(l)&&(l=f?Math.round(1E3*a.location.x)/1E3:a.location.x);
k=k.y;isNaN(k)&&(k=f?Math.round(1E3*a.location.y)/1E3:a.location.y);d.h(Math.max(h,Math.min(c.x,l)),Math.max(g,Math.min(c.y,k)));e!==b&&I.free(e);return d};function Tj(a){var b=a.toolManager.findTool("Dragging");return null!==b?b.dragOptions:a.Wm}
function rg(a,b,c,d,e){void 0===e&&(e=Tj(a));d.assign(c);if(null!==b){var f=a.grid;b=e.gridSnapCellSize;a=b.width;b=b.height;var g=e.gridSnapOrigin,h=g.x;g=g.y;e=e.gridSnapCellSpot;if(null!==f){var k=f.gridCellSize;isNaN(a)&&(a=k.width);isNaN(b)&&(b=k.height);f=f.gridOrigin;isNaN(h)&&(h=f.x);isNaN(g)&&(g=f.y)}f=I.allocAt(0,0);f.yi(0,0,a,b,e);K.No(c.x,c.y,h+f.x,g+f.y,a,b,d);I.free(f)}}function Je(a,b){if(null!==b)for(a.Mr=!0,a=b.iterator;a.next();)b=a.key,b instanceof T&&(b.suspendsRouting=!0)}
function Oe(a,b){if(null!==b){for(b=b.iterator;b.next();){var c=b.key;c instanceof T&&(c.suspendsRouting=!1,Xj(c)&&c.bb())}a.Mr=!1}}function Vj(a,b,c){b=b.containingGroup;if(null!==b){a=Vj(a,b,c);if(null!==a)return a;a=c.I(b);if(null!==a)return a}return null}t=Q.prototype;t.Pd=function(a,b,c){if(void 0===c)return new Me(pb);var d=a.isGridSnapEnabled;a.groupsSnapMembers||null===b.containingGroup||(d=!1);return d?new Me(new I(Math.round(1E3*c.x)/1E3,Math.round(1E3*c.y)/1E3)):new Me(c.copy())};
function Yj(a,b,c){null!==b.diagram&&b.diagram!==a&&C("Cannot share a Layer with another Diagram: "+b+" of "+b.diagram);null===c?null!==b.diagram&&C("Cannot add an existing Layer to this Diagram again: "+b):(c.diagram!==a&&C("Existing Layer must be in this Diagram: "+c+" not in "+c.diagram),b===c&&C("Cannot move a Layer before or after itself: "+b));if(b.diagram!==a){b=b.name;a=a.Wa;c=a.count;for(var d=0;d<c;d++)a.M(d).name===b&&C("Cannot add Layer with the name '"+b+"'; a Layer with the same name is already present in this Diagram.")}}
t.Al=function(a){Yj(this,a,null);a.Xe(this);var b=this.Wa,c=b.count-1;if(!a.isTemporary)for(;0<=c&&b.M(c).isTemporary;)c--;b.Hb(c+1,a);null!==this.dc&&this.cb(fe,"layers",this,null,a,null,c+1);this.N();this.Ya();return this};
t.Xw=function(a,b){Yj(this,a,b);a.Xe(this);var c=this.Wa,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.dc&&this.cb(ge,"layers",this,a,null,d,null));var e=c.count,f;for(f=0;f<e;f++)if(c.M(f)===b){c.Hb(f,a);break}null!==this.dc&&this.cb(fe,"layers",this,null,a,null,f);this.N();0>d&&this.Ya();return this};
t.Dy=function(a,b){Yj(this,a,b);a.Xe(this);var c=this.Wa,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.dc&&this.cb(ge,"layers",this,a,null,d,null));var e=c.count,f;for(f=0;f<e;f++)if(c.M(f)===b){c.Hb(f+1,a);break}null!==this.dc&&this.cb(fe,"layers",this,null,a,null,f+1);this.N();0>d&&this.Ya();return this};
t.lA=function(a){a.diagram!==this&&C("Cannot remove a Layer from another Diagram: "+a+" of "+a.diagram);if(""!==a.name){var b=this.Wa,c=b.indexOf(a);if(b.remove(a)){for(b=a.Pa.copy().iterator;b.next();){var d=b.value,e=d.layerName;e!==a.name?d.layerName=e:d.layerName=""}null!==this.dc&&this.cb(ge,"layers",this,a,null,c,null);this.N();this.Ya()}}};t.Pj=function(a){for(var b=this.layers;b.next();){var c=b.value;if(c.name===a)return c}return null};
t.Zw=function(a){null===this.pf&&(this.pf=new F);this.pf.add(a);this.model.Pe(a);return this};t.nA=function(a){null!==this.pf&&(this.pf.remove(a),0===this.pf.count&&(this.pf=null));this.model.hk(a)};t.Pe=function(a){null===this.zg&&(this.zg=new F);this.zg.add(a);return this};t.hk=function(a){null!==this.zg&&(this.zg.remove(a),0===this.zg.count&&(this.zg=null))};
t.ds=function(a){this.skipsUndoManager||this.model.skipsUndoManager||this.model.undoManager.Uu(a);a.change!==ee&&(this.isModified=!0);if(null!==this.zg)for(var b=this.zg,c=b.length,d=0;d<c;d++)b.M(d)(a)};t.cb=function(a,b,c,d,e,f,g){void 0===f&&(f=null);void 0===g&&(g=null);var h=new ce;h.diagram=this;h.change=a;h.propertyName=b;h.object=c;h.oldValue=d;h.oldParam=f;h.newValue=e;h.newParam=g;this.ds(h)};t.g=function(a,b,c,d,e){this.cb(de,a,this,b,c,d,e)};
Q.prototype.changeState=function(a,b){if(null!==a&&a.diagram===this){var c=this.skipsModelSourceBindings;try{this.skipsModelSourceBindings=!0;var d=a.change;if(d===de){var e=a.object;Zj(e,a.propertyName,a.I(b));if(e instanceof Y){var f=e.part;null!==f&&f.Yb()}this.isModified=!0}else if(d===fe){var g=a.object,h=a.newParam,k=a.newValue;if(g instanceof W)if("number"===typeof h&&k instanceof Y){b?g.qc(h):g.Hb(h,k);var l=g.part;null!==l&&l.Yb()}else"number"===typeof h&&k instanceof ak&&(k.isRow?bk(g,h,
b?void 0:k):ck(g,h,b?void 0:k));else if(g instanceof di){var m=!0===a.oldParam;"number"===typeof h&&k instanceof U&&(b?(k.isSelected=!1,k.isHighlighted=!1,k.Yb(),g.qc(m?h:-1,k,m)):g.oi(h,k,m))}else g instanceof Q?"number"===typeof h&&k instanceof di&&(b?this.Wa.mb(h):(k.Xe(this),this.Wa.Hb(h,k))):C("unknown ChangedEvent.Insert object: "+a.toString());this.isModified=!0}else if(d===ge){var n=a.object,p=a.oldParam,q=a.oldValue;if(n instanceof W)"number"===typeof p&&q instanceof Y?b?n.Hb(p,q):n.qc(p):
"number"===typeof p&&q instanceof ak&&(q.isRow?bk(n,p,b?q:void 0):ck(n,p,b?q:void 0));else if(n instanceof di){var r=!0===a.newParam;"number"===typeof p&&q instanceof U&&(b?0>n.Pa.indexOf(q)&&n.oi(p,q,r):(q.isSelected=!1,q.isHighlighted=!1,q.Yb(),n.qc(r?p:-1,q,r)))}else n instanceof Q?"number"===typeof p&&q instanceof di&&(b?(q.Xe(this),this.Wa.Hb(p,q)):this.Wa.mb(p)):C("unknown ChangedEvent.Remove object: "+a.toString());this.isModified=!0}else d!==ee&&C("unknown ChangedEvent: "+a.toString())}finally{this.skipsModelSourceBindings=
c}}};Q.prototype.Ea=function(a){return this.undoManager.Ea(a)};Q.prototype.eb=function(a){return this.undoManager.eb(a)};Q.prototype.Kf=function(){return this.undoManager.Kf()};Q.prototype.commit=function(a,b){void 0===b&&(b="");var c=this.skipsUndoManager;null===b&&(this.skipsUndoManager=!0,b="");this.undoManager.Ea(b);var d=!1;try{a(this),d=!0}finally{d?this.undoManager.eb(b):this.undoManager.Kf(),this.skipsUndoManager=c}};Q.prototype.updateAllTargetBindings=function(a){this.partManager.updateAllTargetBindings(a)};
Q.prototype.rp=function(){this.partManager.rp()};
function dk(a,b,c){var d=a.animationManager;if(a.wb||a.ld)a.Ca=c,Fi(a),a.N(),a.Ee=!0,d.Ab&&(th(d)||d.ce.add(d.H,"scale",b,a.Ca));else if(null===a.ta)a.Ca=c,Fi(a);else{a.wb=!0;var e=a.viewportBounds.copy(),f=a.Aa,g=a.ya,h=a.zoomPoint.x,k=a.zoomPoint.y,l=a.contentAlignment;isNaN(h)&&(l.$c()?l.Hf(Dc)?h=0:l.Hf(Ec)&&(h=f-1):h=l.sb()?l.x*(f-1):f/2);isNaN(k)&&(l.$c()?l.Hf(Cc)?k=0:l.Hf(Fc)&&(k=g-1):k=l.sb()?l.y*(g-1):g/2);null===a.scaleComputation||a.animationManager.defaultAnimation.isAnimating||(c=a.scaleComputation(a,
c));c<a.minScale&&(c=a.minScale);c>a.maxScale&&(c=a.maxScale);f=I.allocAt(a.pa.x+h/b-h/c,a.pa.y+k/b-k/c);a.position=f;I.free(f);a.Ca=c;Fi(a);a.wb=!1;a.onViewportBoundsChanged(e,a.viewportBounds,b,!1);kj(a,!1);d.Ab&&(th(d)||d.ce.add(d.H,"scale",b,a.Ca))}}
Q.prototype.onViewportBoundsChanged=function(a,b,c,d){if(!a.D(b)){void 0===d&&(d=!1);d||(this.Ee=!0);this.vd=!0;var e=this.layout;null===e||!e.isViewportSized||this.autoScale!==oh||a.width===b.width&&a.height===b.height||e.C();e=this.currentTool;!0===this.Uk&&e instanceof La&&(this.lastInput.documentPoint=this.Ps(this.lastInput.viewPoint),Ae(e,this));this.wb||this.invalidateViewport(a,b);vj(this);this.gc.scale=c;this.gc.position.x=a.x;this.gc.position.y=a.y;this.gc.bounds.assign(a);this.gc.canvasSize.width=
Math.round(a.width*c);this.gc.canvasSize.height=Math.round(a.height*c);this.gc.newCanvasSize.width=this.Aa;this.gc.newCanvasSize.height=this.ya;this.gc.isScroll=d;this.S("ViewportBoundsChanged",this.gc,a);this.isVirtualized&&this.links.each(function(a){a.isAvoiding&&a.actualBounds.Yc(b)&&a.bb()})}};
function vj(a,b){var c=a.fe;if(null!==c&&c.visible){for(var d=M.alloc(),e=1,f=1,g=c.O.s,h=g.length,k=0;k<h;k++){var l=g[k],m=Math.abs(l.interval);2>m||(ek(l.figure)?f=f*m/K.wx(f,m):e=e*m/K.wx(e,m))}g=c.gridCellSize;d.h(f*g.width,e*g.height);if(void 0!==b)e=b.width,f=b.height,a=b.x,g=b.y;else{b=N.alloc();a=a.viewportBounds;b.h(a.x,a.y,a.width,a.height);if(!b.w()){N.free(b);return}e=b.width;f=b.height;a=b.x;g=b.y;N.free(b)}c.width=e+2*d.width;c.height=f+2*d.height;b=I.alloc();K.No(a,g,0,0,d.width,d.height,
b);b.offset(-d.width,-d.height);M.free(d);c.part.location=b;I.free(b)}}Q.prototype.clearSelection=function(a){void 0===a&&(a=!1);var b=this.selection;if(0!==b.count){a||this.S("ChangingSelection",b);for(var c=b.Fa(),d=c.length,e=0;e<d;e++)c[e].isSelected=!1;b.ka();b.clear();b.freeze();a||this.S("ChangedSelection",b)}};
Q.prototype.select=function(a){null!==a&&a.diagram===this&&(!a.isSelected||1<this.selection.count)&&(this.S("ChangingSelection",this.selection),this.clearSelection(!0),a.isSelected=!0,this.S("ChangedSelection",this.selection))};
Q.prototype.sA=function(a){this.S("ChangingSelection",this.selection);this.clearSelection(!0);if(Aa(a))for(var b=a.length,c=0;c<b;c++){var d=a[c];d instanceof U||C("Diagram.selectCollection given something that is not a Part: "+d);d.isSelected=!0}else for(a=a.iterator;a.next();)b=a.value,b instanceof U||C("Diagram.selectCollection given something that is not a Part: "+b),b.isSelected=!0;this.S("ChangedSelection",this.selection)};
Q.prototype.clearHighlighteds=function(){var a=this.highlighteds;if(0<a.count){for(var b=a.Fa(),c=b.length,d=0;d<c;d++)b[d].isHighlighted=!1;a.ka();a.clear();a.freeze()}};t=Q.prototype;t.Jz=function(a){null!==a&&a.diagram===this&&(!a.isHighlighted||1<this.highlighteds.count)&&(this.clearHighlighteds(),a.isHighlighted=!0)};
t.Kz=function(a){a=(new H).addAll(a);for(var b=this.highlighteds.copy().hp(a).iterator;b.next();)b.value.isHighlighted=!1;for(a=a.iterator;a.next();)b=a.value,b instanceof U||C("Diagram.highlightCollection given something that is not a Part: "+b),b.isHighlighted=!0};
t.scroll=function(a,b,c){void 0===c&&(c=1);var d="up"===b||"down"===b,e=0,f=I.alloc();if("pixel"===a)e=c;else if("line"===a)e=c*(d?this.scrollVerticalLineChange:this.scrollHorizontalLineChange);else if("page"===a)a=d?this.viewportBounds.height:this.viewportBounds.width,a*=this.scale,0!==a&&(e=c*Math.max(a-(d?this.scrollVerticalLineChange:this.scrollHorizontalLineChange),0));else{if("document"===a){e=this.documentBounds;c=this.viewportBounds;"up"===b?this.position=f.h(c.x,e.y):"left"===b?this.position=
f.h(e.x,c.y):"down"===b?this.position=f.h(c.x,e.bottom-c.height):"right"===b&&(this.position=f.h(e.right-c.width,c.y));I.free(f);return}C("scrolling unit must be 'pixel', 'line', 'page', or 'document', not: "+a)}e/=this.scale;c=this.position;"up"===b?this.position=f.h(c.x,c.y-e):"down"===b?this.position=f.h(c.x,c.y+e):"left"===b?this.position=f.h(c.x-e,c.y):"right"===b?this.position=f.h(c.x+e,c.y):C("scrolling direction must be 'up', 'down', 'left', or 'right', not: "+b);I.free(f)};
t.uv=function(a){var b=this.viewportBounds;b.Re(a)||(a=a.center,a.x-=b.width/2,a.y-=b.height/2,this.position=a)};t.es=function(a){var b=this.viewportBounds;a=a.center;a.x-=b.width/2;a.y-=b.height/2;this.position=a};t.op=function(a){var b=this.Bb;b.reset();1!==this.Ca&&b.scale(this.Ca);var c=this.pa;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return a.copy().transform(this.Bb)};
t.AA=function(a){var b=this.Bb,c=a.x,d=a.y,e=c+a.width,f=d+a.height,g=b.m11,h=b.m12,k=b.m21,l=b.m22,m=b.dx,n=b.dy,p=c*g+d*k+m;b=c*h+d*l+n;var q=e*g+d*k+m;a=e*h+d*l+n;d=c*g+f*k+m;c=c*h+f*l+n;g=e*g+f*k+m;e=e*h+f*l+n;f=Math.min(p,q);p=Math.max(p,q);q=Math.min(b,a);b=Math.max(b,a);f=Math.min(f,d);p=Math.max(p,d);q=Math.min(q,c);b=Math.max(b,c);f=Math.min(f,g);p=Math.max(p,g);q=Math.min(q,e);b=Math.max(b,e);return new N(f,q,p-f,b-q)};
t.Ps=function(a){var b=this.Bb;b.reset();1!==this.Ca&&b.scale(this.Ca);var c=this.pa;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return xb(a.copy(),this.Bb)};function fk(a){var b=a.isModified;a.Uw!==b&&(a.Uw=b,a.S("Modified"))}function gk(a){a=oi.get(a);return null!==a?new a:new pi}
Q.prototype.doModelChanged=function(a){var b=this;if(a.model===this.model){var c=a.change,d=a.propertyName;if(c===ee&&"S"===d[0])if("StartingFirstTransaction"===d){var e=this;a=this.toolManager;a.mouseDownTools.each(function(a){return a.diagram=e});a.mouseMoveTools.each(function(a){return a.diagram=e});a.mouseUpTools.each(function(a){return a.diagram=e});this.ld||this.hf||(this.Ik=!0,this.fn&&(this.kg=!0))}else"StartingUndo"===d||"StartingRedo"===d?(a=this.animationManager,a.defaultAnimation.isAnimating&&
!this.skipsUndoManager&&a.Cc(),this.S("ChangingSelection",this.selection)):"StartedTransaction"===d&&(a=this.animationManager,a.defaultAnimation.isAnimating&&!this.skipsUndoManager&&a.Cc());else if(this.aa){this.aa=!1;try{if(""===a.modelChange&&c===ee){if("FinishedUndo"===d||"FinishedRedo"===d)this.S("ChangedSelection",this.selection),jh(this);var f=this.animationManager;"RolledBackTransaction"===d&&f.Cc();this.ex();this.Ik=!0;this.bd();0!==this.undoManager.transactionLevel&&1!==this.undoManager.transactionLevel||
kh(f);"CommittedTransaction"===d&&this.undoManager.isJustDiscarded&&(this.Ce=Math.min(this.Ce,this.undoManager.historyIndex-1));"CommittedTransaction"!==d&&"RolledBackTransaction"!==d||!this.undoManager.isPendingUnmodified||setTimeout(function(){b.isModified=!1},1);var g=a.isTransactionFinished;g&&(fk(this),this.As.clear(),xh(this.animationManager));if(!this.Rt&&g&&this.lastInput.targetDiagram){this.Rt=!0;var h=this;ta(function(){h.currentTool.standardMouseOver();h.Rt=!1},10)}}}finally{this.aa=!0}}}};
function Nj(a,b){b=b.O.s;for(var c=b.length,d=0;d<c;d++)hk(a,b[d])}function hk(a,b){if(b instanceof ik){var c=b.element;if(null!==c&&c instanceof HTMLImageElement){c.Bg instanceof H&&c.Bg.add(a);var d=b.Eg;null!==d&&!0===d.sq&&null!==b.diagram&&b.diagram.Xn.add(b);if(c.hn)if(c=c.getAttribute("src")||"",d=a.Og.I(c),null===d)d=[],d.push(b),a.Og.add(c,d);else{for(a=0;a<d.length;a++)if(d[a]===b)return;d.push(b)}}}}
function jk(a,b){if(b instanceof ik){var c=b.element;if(null!==c&&c instanceof HTMLImageElement&&c.hn){var d=c.getAttribute("src")||"",e=a.Og.I(d);if(null!==e)for(var f=0;f<e.length;f++)if(e[f]===b){e.splice(f,1);0===e.length&&(a.Og.remove(d),c.Fq&&c.removeEventListener("load",c.Fq),c.Yp&&c.removeEventListener("error",c.Yp),delete c.Fq,delete c.Yp,c.Bg instanceof H&&c.Bg.remove(a),Ei(d));break}}}}Q.prototype.Wd=function(){this.partManager.Wd()};Q.prototype.Aj=function(a,b){this.Yd.Aj(a,b)};
Q.prototype.Cj=function(a,b){this.Yd.Cj(a,b)};Q.prototype.findPartForKey=function(a){return this.partManager.findPartForKey(a)};Q.prototype.Xb=function(a){return this.partManager.Xb(a)};Q.prototype.findLinkForKey=function(a){return this.partManager.findLinkForKey(a)};t=Q.prototype;t.Lc=function(a){return this.partManager.Lc(a)};t.ii=function(a){return this.partManager.ii(a)};t.Kc=function(a){return this.partManager.Kc(a)};
t.ms=function(a){for(var b=[],c=0;c<arguments.length;++c)b[c]=arguments[c];return this.partManager.ms.apply(this.partManager,b instanceof Array?b:da(ba(b)))};t.ls=function(a){for(var b=[],c=0;c<arguments.length;++c)b[c]=arguments[c];return this.partManager.ls.apply(this.partManager,b instanceof Array?b:da(ba(b)))};function Fi(a){var b=a.pa,c=a.Ca;a.Sw.h(b.x,b.y,Math.max(a.Aa,0)/c,Math.max(a.ya,0)/c);a.vd=!0}
function Jj(a,b){a.Zi=!1;var c=a.Sp;c.D(b)||(c=c.copy(),a.Sp.assign(b),kj(a,!1),a.S("DocumentBoundsChanged",void 0,c),a.Ee=!0,a.N())}t.Va=function(){if(this.Zi){var a=N.alloc();Jj(this,this.computeBounds(a));N.free(a)}};t.sz=function(){for(var a=new H,b=this.nodes;b.next();){var c=b.value;c.isTopLevel&&a.add(c)}for(b=this.links;b.next();)c=b.value,c.isTopLevel&&a.add(c);return a.iterator};t.rz=function(){return this.ai.iterator};
t.Vz=function(a){jh(this);a&&kk(this,!0);this.ld?wj(this,!1):(this.Ik=!0,yf(this))};function kk(a,b){for(var c=a.ai.iterator;c.next();)lk(a,c.value,b);a.layout&&(b?a.layout.isValidLayout=!1:a.layout.C())}function lk(a,b,c){if(null!==b){for(var d=b.fl.iterator;d.next();)lk(a,d.value,c);null!==b.layout&&(c?b.layout.isValidLayout=!1:b.layout.C())}}
function wj(a,b){if(a.xh&&!a.lt){var c=a.aa;a.aa=!0;var d=a.undoManager.transactionLevel,e=a.layout,f=a.animationManager;try{0===d&&(a.undoManager.isInternalTransaction=!0,a.Ea("Layout"));1>=d&&!f.defaultAnimation.isAnimating&&!f.Ab&&(b||ih(f,"Layout"));a.xh=!1;for(var g=a.ai.iterator;g.next();)mk(a,g.value,b,d);e.isValidLayout||(!b||e.isRealtime||null===e.isRealtime||0===d?(e.doLayout(a),jh(a),e.isValidLayout=!0):a.xh=!0)}finally{b=a.undoManager.isInternalTransaction,0===d&&(a.eb("Layout"),a.undoManager.isInternalTransaction=
!1),!b||0!==d&&1!==d||kh(f),a.aa=c}}}function mk(a,b,c,d){if(null!==b){for(var e=b.fl.iterator;e.next();)mk(a,e.value,c,d);e=b.layout;null===e||e.isValidLayout||(!c||e.isRealtime||0===d?(b.rh=!b.location.w(),e.doLayout(b),b.C(32),yj(a,b),e.isValidLayout=!0):a.xh=!0)}}t.zz=function(){for(var a=new F,b=this.nodes;b.next();){var c=b.value;c.isTopLevel&&null===c.kh()&&a.add(c)}return a.iterator};
function xi(a){function b(a){var b=a.toLowerCase(),e=new F;c.add(a,e);c.add(b,e);d.add(a,a);d.add(b,a)}var c=new ob,d=new ob;b("InitialAnimationStarting");b("AnimationStarting");b("AnimationFinished");b("BackgroundSingleClicked");b("BackgroundDoubleClicked");b("BackgroundContextClicked");b("ClipboardChanged");b("ClipboardPasted");b("DocumentBoundsChanged");b("ExternalObjectsDropped");b("GainedFocus");b("InitialLayoutCompleted");b("LayoutCompleted");b("LinkDrawn");b("LinkRelinked");b("LinkReshaped");
b("LostFocus");b("Modified");b("ObjectSingleClicked");b("ObjectDoubleClicked");b("ObjectContextClicked");b("PartCreated");b("PartResized");b("PartRotated");b("SelectionMoved");b("SelectionCopied");b("SelectionDeleting");b("SelectionDeleted");b("SelectionGrouped");b("SelectionUngrouped");b("ChangingSelection");b("ChangedSelection");b("SubGraphCollapsed");b("SubGraphExpanded");b("TextEdited");b("TreeCollapsed");b("TreeExpanded");b("ViewportBoundsChanged");b("InvalidateDraw");a.jw=c;a.iw=d}
function Ij(a,b){var c=a.iw.I(b);return null!==c?c:a.iw.I(b.toLowerCase())}function nk(a,b){var c=a.jw.I(b);if(null!==c)return c;c=a.jw.I(b.toLowerCase());if(null!==c)return c;C("Unknown DiagramEvent name: "+b)}t.Bj=function(a,b){a=nk(this,a);null!==a&&a.add(b);return this};t.am=function(a,b){a=nk(this,a);null!==a&&a.remove(b)};
t.S=function(a,b,c){var d=nk(this,a),e=new Vd;e.diagram=this;a=Ij(this,a);null!==a&&(e.name=a);void 0!==b&&(e.subject=b);void 0!==c&&(e.parameter=c);b=d.length;if(1===b)d.M(0)(e);else if(0!==b)for(d=d.Fa(),c=0;c<b;c++)(0,d[c])(e)};function ok(a){if(a.animationManager.isTicking)return!1;var b=a.currentTool;return b===a.toolManager.findTool("Dragging")?!a.Mr||b.isComplexRoutingRealtime:!0}t.Xj=function(a,b){return pk(this,!1,null,b||null).Xj(a.x,a.y,a.width,a.height)};
Q.prototype.computeOccupiedArea=function(a){return this.isVirtualized?this.viewportBounds.copy():this.Zi?jj(this,a):a.assign(this.documentBounds)};
function pk(a,b,c,d){var e=a.Xh;null===e&&(a.Xh=e=new qk(a.avoidanceCellSize));if(e.vs||e.group!==c||e.Zx!==d){e.Ix=Math.max(e.Os+1,Math.floor(a.avoidanceLimit/a.avoidanceCellSize.width));e.Jx=Math.max(e.Os+1,Math.floor(a.avoidanceLimit/a.avoidanceCellSize.height));null!==c&&(c=rk(a,c));if(null===c){b=N.alloc();a.computeOccupiedArea(b);b.Bc(100,100);e.initialize(b);for(var f=a.nodes;f.next();){var g=f.value,h=g.layer;null!==h&&h.visible&&!h.isTemporary&&sk(a,g,d,b)}N.free(b)}else{0<c.memberParts.count&&
(b=a.computePartsBounds(c.memberParts,!1),b.Bc(20,20),e.initialize(b));b=N.alloc();for(f=c.memberParts;f.next();)g=f.value,g instanceof V&&sk(a,g,d,b);N.free(b)}e.group=c;e.Zx=d;e.vs=!1}else b&&tk(e);return e}function rk(a,b){return b.avoidable&&!b.isLinkLabel?b:null!==b.containingGroup?rk(a,b.containingGroup):null}
function sk(a,b,c,d){if(b!==c&&b.isVisible()){var e=b.avoidable&&!b.isLinkLabel;if(e){var f=b.getAvoidableRect(d),g=a.Xh,h=f.x,k=f.y;if(uk(g,h,k)){var l=(k-g.Od)/g.tc|0,m=Math.min((h+f.width-g.Nd)/g.sc|0,g.di);f=Math.min((k+f.height-g.Od)/g.tc|0,g.ei);for(h=(h-g.Nd)/g.sc|0;h<=m;h++)if(k=g.bc[h],k.fill)k.fill(vk,l,f+1);else for(var n=l;n<=f;n++)k[n]=vk}}if(b instanceof jf&&(!e||b.avoidableMembers))for(b=b.memberParts;b.next();)e=b.value,e instanceof V&&sk(a,e,c,d)}}
function wk(a,b){null===a.Xh||a.Xh.vs||void 0!==b&&(!b.avoidable||b.isLinkLabel)||(a.Xh.vs=!0)}Q.prototype.gs=function(a){this.wp.assign(a);this.computeAutoScrollPosition(this.wp).Xa(this.position)?this.ug():xk(this)};
function xk(a){-1===a.uk&&(a.uk=ta(function(){if(-1!==a.uk&&(a.ug(),null!==a.lastInput.event)){var b=a.computeAutoScrollPosition(a.wp);b.Xa(a.position)||(a.position=b,a.lastInput.documentPoint=a.Ps(a.wp),a.doMouseMove(),a.Zi=!0,b=N.alloc(),a.computeBounds(b),b.ed(a.documentBounds),Jj(a,b),N.free(b),a.Fc=!0,a.bd(),xk(a))}},a.vp))}Q.prototype.ug=function(){-1!==this.uk&&(x.clearTimeout(this.uk),this.uk=-1)};
Q.prototype.computeAutoScrollPosition=function(a){var b=this.position,c=this.xp;if(0>=c.top&&0>=c.left&&0>=c.right&&0>=c.bottom)return b;var d=this.viewportBounds,e=this.scale;d=N.allocAt(0,0,d.width*e,d.height*e);var f=I.allocAt(0,0);if(a.x>=d.x&&a.x<d.x+c.left&&this.allowHorizontalScroll){var g=Math.max(this.scrollHorizontalLineChange,1);g|=0;f.x-=g;a.x<d.x+c.left/2&&(f.x-=g);a.x<d.x+c.left/4&&(f.x-=4*g)}else a.x<=d.x+d.width&&a.x>d.x+d.width-c.right&&this.allowHorizontalScroll&&(g=Math.max(this.scrollHorizontalLineChange,
1),g|=0,f.x+=g,a.x>d.x+d.width-c.right/2&&(f.x+=g),a.x>d.x+d.width-c.right/4&&(f.x+=4*g));a.y>=d.y&&a.y<d.y+c.top&&this.allowVerticalScroll?(g=Math.max(this.scrollVerticalLineChange,1),g|=0,f.y-=g,a.y<d.y+c.top/2&&(f.y-=g),a.y<d.y+c.top/4&&(f.y-=4*g)):a.y<=d.y+d.height&&a.y>d.y+d.height-c.bottom&&this.allowVerticalScroll&&(g=Math.max(this.scrollVerticalLineChange,1),g|=0,f.y+=g,a.y>d.y+d.height-c.bottom/2&&(f.y+=g),a.y>d.y+d.height-c.bottom/4&&(f.y+=4*g));f.Xa(pb)||(b=new I(b.x+f.x/e,b.y+f.y/e));
N.free(d);I.free(f);return b};t=Q.prototype;t.Cs=function(){return null};t.bv=function(){return null};t.bs=function(a,b){this.pj.add(a,b)};t.Gu=function(a){(this.Qw=a)||this.nodes.each(function(a){a instanceof jf&&(a.$p=null)})};
function yk(a,b,c){function d(){var a=+new Date;f=!0;for(g.reset();g.next();)if(!g.value[0].$k){f=!1;break}f||a-l>k?b(c,e,h):x.requestAnimationFrame(d)}for(var e=c.callback,f=!0,g=a.Og.iterator;g.next();)if(!g.value[0].$k){f=!1;break}if("function"!==typeof e||f)return b(c,e,a);var h=a,k=c.callbackTimeout||300,l=+new Date;x.requestAnimationFrame(function(){d()});return null}t.Xz=function(a){if(!Lg)return null;void 0===a&&(a={});a.returnType="Image";return this.Kx(a)};
t.Kx=function(a){void 0===a&&(a={});return yk(this,this.Yz,a)};
t.Yz=function(a,b,c){var d=zk(c,a,"canvas",null);if(null===d)return null;c=d.Z.canvas;var e=null;if(null!==c)switch(e=a.returnType,void 0===e?e="string":e=e.toLowerCase(),e){case Ri+"data":e=d.getImageData(0,0,c.width,c.height);break;case Ri:d=(a.document||document).createElement("img");d.src=c.toDataURL(a.type,a.details);e=d;break;case "blob":"function"!==typeof b&&C('Error: Diagram.makeImageData called with "returnType: toBlob", but no required "callback" function property defined.');if("function"===
typeof c.toBlob)return c.toBlob(b,a.type,a.details),"toBlob";b(null);return null;default:e=c.toDataURL(a.type,a.details)}return"function"===typeof b?(b(e),null):e};
function zk(a,b,c,d){a.animationManager.Cc();a.bd();if(null===a.ta)return null;"object"!==typeof b&&C("properties argument must be an Object.");var e=b.size||null,f=b.scale||null;void 0!==b.scale&&isNaN(b.scale)&&(f="NaN");var g=b.maxSize;void 0===b.maxSize&&(g="SVG"===c?new M(Infinity,Infinity):new M(2E3,2E3));var h=b.position||null,k=b.parts||null,l=void 0===b.padding?1:b.padding,m=b.background||null,n=b.omitTemporary;void 0===n&&(n=!0);var p=b.document||document,q=b.elementFinished||null,r=b.showTemporary;
void 0===r&&(r=!n);b=b.showGrid;void 0===b&&(b=r);null!==e&&isNaN(e.width)&&isNaN(e.height)&&(e=null);"number"===typeof l?l=new cc(l):l instanceof cc||C("MakeImage padding must be a Margin or a number.");l.left=Math.max(l.left,0);l.right=Math.max(l.right,0);l.top=Math.max(l.top,0);l.bottom=Math.max(l.bottom,0);a.ib.Vc(!0);n=new Hi(null,p,void 0,!0);var u=n.context;if(!(e||f||k||h)){n.width=a.Aa+Math.ceil(l.left+l.right);n.height=a.ya+Math.ceil(l.top+l.bottom);if("SVG"===c){if(null===d)return null;
d.resize(n.width,n.height,n.width,n.height);d.ownerDocument=p;d.hs=q;Gj(a,d.context,l,new M(n.width,n.height),a.Ca,a.pa,k,m,r,b);return d.context}a.Xm=!1;Gj(a,u,l,new M(n.width,n.height),a.Ca,a.pa,k,m,r,b);a.Xm=!0;return n.context}var w=a.Pp,v=a.documentBounds.copy();v.Gv(a.zb);if(r)for(var y=a.Wa.s,z=y.length,A=0;A<z;A++){var B=y[A];if(B.visible&&B.isTemporary){B=B.Pa.s;for(var E=B.length,L=0;L<E;L++){var R=B[L];R.isInDocumentBounds&&R.isVisible()&&(R=R.actualBounds,R.w()&&v.ed(R))}}}y=new I(v.x,
v.y);if(null!==k){z=new N(0,0,0,0);A=!0;B=k.iterator;for(B.reset();B.next();)E=B.value,E instanceof U&&(L=E.layer,null!==L&&!L.visible||null!==L&&!r&&L.isTemporary||!E.isVisible()||(E=E.actualBounds,E.w()&&(A?(A=!1,z.assign(E)):z.ed(E))));v.width=z.width;v.height=z.height;y.x=z.x;y.y=z.y}null!==h&&h.w()&&(y=h,f||(f=w));z=h=0;null!==l&&(h=l.left+l.right,z=l.top+l.bottom);A=B=0;null!==e&&(B=e.width,A=e.height,isFinite(B)&&(B=Math.max(0,B-h)),isFinite(A)&&(A=Math.max(0,A-z)));null!==e&&null!==f?("NaN"===
f&&(f=w),e.w()?(e=B,v=A):isNaN(A)?(e=B,v=v.height*f):(e=v.width*f,v=A)):null!==e?e.w()?(f=Math.min(B/v.width,A/v.height),e=B,v=A):isNaN(A)?(f=B/v.width,e=B,v=v.height*f):(f=A/v.height,e=v.width*f,v=A):null!==f?"NaN"===f&&g.w()?(f=Math.min((g.width-h)/v.width,(g.height-z)/v.height),f>w?(f=w,e=v.width,v=v.height):(e=g.width,v=g.height)):(e=v.width*f,v=v.height*f):(f=w,e=v.width,v=v.height);null!==l?(e+=h,v+=z):l=new cc(0);null!==g&&(w=g.width,g=g.height,isNaN(w)&&(w=2E3),isNaN(g)&&(g=2E3),isFinite(w)&&
(e=Math.min(e,w)),isFinite(g)&&(v=Math.min(v,g)));n.width=Math.ceil(e);n.height=Math.ceil(v);if("SVG"===c){if(null===d)return null;d.resize(n.width,n.height,n.width,n.height);d.ownerDocument=p;d.hs=q;Gj(a,d.context,l,new M(Math.ceil(e),Math.ceil(v)),f,y,k,m,r,b);return d.context}a.Xm=!1;Gj(a,u,l,new M(Math.ceil(e),Math.ceil(v)),f,y,k,m,r,b);a.Xm=!0;return n.context}
ma.Object.defineProperties(Q.prototype,{renderer:{get:function(){return this.nl},set:function(a){this instanceof Ak||(""===a&&(a="default"),a=a.toLowerCase(),a!==this.nl&&(this.nl=a,"default"===a||"canvas"===a?(a=this.pj.get("svg"),null!==a&&a.Oa.remove(),this.ta&&(this.ib=this.ta.context)):"svg"===a?(this.pj.contains("svg")?a=this.pj.get("svg"):(a=new Kj(this,x.document),this.bs("svg",a)),null!==this.div&&this.div.appendChild(a.Oa),this.ib=a.context,this.ta&&(this.ta.context.setTransform(this.Nb,
0,0,this.Nb,0,0),this.ta.context.clearRect(0,0,this.Aa,this.ya))):"debug"!==a||this.pj.contains("SVG")||(a=new Kj(this,x.document),this.bs("SVG",a),a.Oa.style.backgroundColor="whitesmoke",null!==this.div&&this.div.after(a.Oa)),this.gk()))}},div:{get:function(){return this.Ka},set:function(a){if(this.Ka!==a){var b=this.Ka;null!==b?(b.H=void 0,b.goDiagram=void 0,b.go=void 0,b.innerHTML="",null!==this.ta&&(b=this.ta.Oa,this.removeEventListener(b,"pointermove",this.ck,!1),
this.removeEventListener(b,"pointerdown",this.ui,!1),this.removeEventListener(b,"pointerup",this.ek,!1),this.removeEventListener(b,"pointerout",this.dk,!1),this.removeEventListener(b,"pointercancel",this.bk,!1),this.ta.nx()),this.ol&&(this.ol.disconnect(),this.ol=null),b=this.toolManager,null!==b&&(b.mouseDownTools.each(function(a){a.cancelWaitAfter()}),b.mouseMoveTools.each(function(a){a.cancelWaitAfter()}),b.mouseUpTools.each(function(a){a.cancelWaitAfter()})),b.cancelWaitAfter(),this.currentTool.doCancel(),
this.ta=null,this.removeEventListener(x,"resize",this.Pv,!1),this.removeEventListener(x,"wheel",this.Zj,!0),le===this&&(le=null)):null===this.ui&&(this.hf=!1);this.Ka=null;if(null!==a){if(b=a.H)b.div=null;vi(this,a);this.gk()}}}},ax:{get:function(){return this.Sq}},pi:{get:function(){return this.hf}},draggedLink:{get:function(){return this.nt},set:function(a){this.nt!==a&&(this.nt=a,null!==a&&(this.Vt=a.fromPort,
this.Wt=a.toPort))}},Nx:{get:function(){return this.Vt},set:function(a){this.Vt=a}},Ox:{get:function(){return this.Wt},set:function(a){this.Wt=a}},animationManager:{get:function(){return this.Yd}},undoManager:{get:function(){return this.dc.undoManager}},skipsUndoManager:{get:function(){return this.Wg},set:function(a){this.Wg=a;this.dc.skipsUndoManager=
a}},delaysLayout:{get:function(){return this.lt},set:function(a){this.lt=a}},opacity:{get:function(){return this.pb},set:function(a){var b=this.pb;b!==a&&((0>a||1<a)&&xa(a,"0 <= value <= 1",Q,"opacity"),this.pb=a,this.g("opacity",b,a),this.N())}},validCycle:{get:function(){return this.wu},set:function(a){var b=this.wu;b!==a&&(this.wu=a,this.g("validCycle",b,a))}},layers:{get:function(){return this.Wa.iterator}},
isModelReadOnly:{get:function(){var a=this.dc;return null===a?!1:a.isReadOnly},set:function(a){var b=this.dc;null!==b&&(b.isReadOnly=a)}},isReadOnly:{get:function(){return this.Gg},set:function(a){var b=this.Gg;b!==a&&(this.Gg=a,this.g("isReadOnly",b,a))}},isEnabled:{get:function(){return this.Jd},set:function(a){var b=this.Jd;b!==a&&(this.Jd=a,this.g("isEnabled",b,a))}},allowClipboard:{
get:function(){return this.Us},set:function(a){var b=this.Us;b!==a&&(this.Us=a,this.g("allowClipboard",b,a))}},allowCopy:{get:function(){return this.Fi},set:function(a){var b=this.Fi;b!==a&&(this.Fi=a,this.g("allowCopy",b,a))}},allowDelete:{get:function(){return this.Gi},set:function(a){var b=this.Gi;b!==a&&(this.Gi=a,this.g("allowDelete",b,a))}},allowDragOut:{get:function(){return this.Vs},set:function(a){var b=
this.Vs;b!==a&&(this.Vs=a,this.g("allowDragOut",b,a))}},allowDrop:{get:function(){return this.Ws},set:function(a){var b=this.Ws;b!==a&&(this.Ws=a,this.g("allowDrop",b,a))}},allowTextEdit:{get:function(){return this.Pi},set:function(a){var b=this.Pi;b!==a&&(this.Pi=a,this.g("allowTextEdit",b,a))}},allowGroup:{get:function(){return this.Hi},set:function(a){var b=this.Hi;b!==a&&(this.Hi=a,this.g("allowGroup",b,
a))}},allowUngroup:{get:function(){return this.Qi},set:function(a){var b=this.Qi;b!==a&&(this.Qi=a,this.g("allowUngroup",b,a))}},allowInsert:{get:function(){return this.Ys},set:function(a){var b=this.Ys;b!==a&&(this.Ys=a,this.g("allowInsert",b,a))}},allowLink:{get:function(){return this.Ii},set:function(a){var b=this.Ii;b!==a&&(this.Ii=a,this.g("allowLink",b,a))}},allowRelink:{get:function(){return this.Ki},
set:function(a){var b=this.Ki;b!==a&&(this.Ki=a,this.g("allowRelink",b,a))}},allowMove:{get:function(){return this.Ji},set:function(a){var b=this.Ji;b!==a&&(this.Ji=a,this.g("allowMove",b,a))}},allowReshape:{get:function(){return this.Li},set:function(a){var b=this.Li;b!==a&&(this.Li=a,this.g("allowReshape",b,a))}},allowResize:{get:function(){return this.Mi},set:function(a){var b=this.Mi;b!==a&&(this.Mi=a,this.g("allowResize",
b,a))}},allowRotate:{get:function(){return this.Ni},set:function(a){var b=this.Ni;b!==a&&(this.Ni=a,this.g("allowRotate",b,a))}},allowSelect:{get:function(){return this.Oi},set:function(a){var b=this.Oi;b!==a&&(this.Oi=a,this.g("allowSelect",b,a))}},allowUndo:{get:function(){return this.Zs},set:function(a){var b=this.Zs;b!==a&&(this.Zs=a,this.g("allowUndo",b,a))}},allowZoom:{get:function(){return this.at},
set:function(a){var b=this.at;b!==a&&(this.at=a,this.g("allowZoom",b,a))}},hasVerticalScrollbar:{get:function(){return this.nn},set:function(a){var b=this.nn;b!==a&&(this.nn=a,this.Ee=!0,this.N(),this.g("hasVerticalScrollbar",b,a),kj(this,!1))}},hasHorizontalScrollbar:{get:function(){return this.mn},set:function(a){var b=this.mn;b!==a&&(this.mn=a,this.Ee=!0,this.N(),this.g("hasHorizontalScrollbar",b,a),kj(this,!1))}},allowHorizontalScroll:{
get:function(){return this.Xs},set:function(a){var b=this.Xs;b!==a&&(this.Xs=a,this.g("allowHorizontalScroll",b,a),kj(this,!1))}},allowVerticalScroll:{get:function(){return this.$s},set:function(a){var b=this.$s;b!==a&&(this.$s=a,this.g("allowVerticalScroll",b,a),kj(this,!1))}},scrollHorizontalLineChange:{get:function(){return this.iu},set:function(a){var b=this.iu;b!==a&&(0>a&&xa(a,">= 0",Q,"scrollHorizontalLineChange"),this.iu=
a,this.g("scrollHorizontalLineChange",b,a))}},scrollVerticalLineChange:{get:function(){return this.mu},set:function(a){var b=this.mu;b!==a&&(0>a&&xa(a,">= 0",Q,"scrollVerticalLineChange"),this.mu=a,this.g("scrollVerticalLineChange",b,a))}},lastInput:{get:function(){return this.cj},set:function(a){this.cj=a}},previousInput:{get:function(){return this.Qg},set:function(a){this.Qg=a}},firstInput:{
get:function(){return this.bq},set:function(a){this.bq=a}},currentCursor:{get:function(){return this.it},set:function(a){""===a&&(a=this.Op);if(this.it!==a){var b=this.ta,c=this.Ka;if(null!==b&&null!==c){this.it=a;var d=b.style.cursor;b.style.cursor=a;c.style.cursor=a;b.style.cursor===d&&(b.style.cursor="-webkit-"+a,c.style.cursor="-webkit-"+a,b.style.cursor===d&&(b.style.cursor="-moz-"+a,c.style.cursor="-moz-"+a,b.style.cursor===d&&(b.style.cursor=a,c.style.cursor=
a)))}}}},defaultCursor:{get:function(){return this.Op},set:function(a){""===a&&(a="auto");var b=this.Op;b!==a&&(this.Op=a,this.g("defaultCursor",b,a))}},click:{get:function(){return this.zh},set:function(a){var b=this.zh;b!==a&&(this.zh=a,this.g("click",b,a))}},doubleClick:{get:function(){return this.Fh},set:function(a){var b=this.Fh;b!==a&&(this.Fh=a,this.g("doubleClick",b,a))}},contextClick:{
get:function(){return this.Bh},set:function(a){var b=this.Bh;b!==a&&(this.Bh=a,this.g("contextClick",b,a))}},mouseOver:{get:function(){return this.Qh},set:function(a){var b=this.Qh;b!==a&&(this.Qh=a,this.g("mouseOver",b,a))}},mouseHover:{get:function(){return this.Oh},set:function(a){var b=this.Oh;b!==a&&(this.Oh=a,this.g("mouseHover",b,a))}},mouseHold:{get:function(){return this.Nh},set:function(a){var b=
this.Nh;b!==a&&(this.Nh=a,this.g("mouseHold",b,a))}},mouseDragOver:{get:function(){return this.Qt},set:function(a){var b=this.Qt;b!==a&&(this.Qt=a,this.g("mouseDragOver",b,a))}},mouseDrop:{get:function(){return this.Lh},set:function(a){var b=this.Lh;b!==a&&(this.Lh=a,this.g("mouseDrop",b,a))}},handlesDragDropForTopLevelParts:{get:function(){return this.xt},set:function(a){var b=this.xt;b!==a&&(this.xt=a,this.g("handlesDragDropForTopLevelParts",
b,a))}},mouseEnter:{get:function(){return this.Mh},set:function(a){var b=this.Mh;b!==a&&(this.Mh=a,this.g("mouseEnter",b,a))}},mouseLeave:{get:function(){return this.Ph},set:function(a){var b=this.Ph;b!==a&&(this.Ph=a,this.g("mouseLeave",b,a))}},toolTip:{get:function(){return this.$h},set:function(a){var b=this.$h;b!==a&&(this.$h=a,this.g("toolTip",b,a))}},contextMenu:{get:function(){return this.Ch},
set:function(a){var b=this.Ch;b!==a&&(this.Ch=a,this.g("contextMenu",b,a))}},commandHandler:{get:function(){return this.nb},set:function(a){this.nb!==a&&(this.nb=a,a.Xe(this))}},toolManager:{get:function(){return this.te},set:function(a){this.te!==a&&(this.te=a,a.diagram=this)}},defaultTool:{get:function(){return this.ob},set:function(a){var b=this.ob;b!==a&&(this.ob=a,a.diagram=this,this.currentTool===b&&(this.currentTool=
a))}},currentTool:{get:function(){return this.hb},set:function(a){var b=this.hb;b&&(b.isActive&&b.doDeactivate(),b.cancelWaitAfter(),b.doStop());null===a&&(a=this.defaultTool);null!==a&&(this.hb=a,a.diagram=this,a.doStart())}},selection:{get:function(){return this.Iw}},maxSelectionCount:{get:function(){return this.Nt},set:function(a){var b=this.Nt;if(b!==a)if(0<=a&&!isNaN(a)){if(this.Nt=a,this.g("maxSelectionCount",
b,a),!this.undoManager.isUndoingRedoing&&(a=this.selection.count-a,0<a)){this.S("ChangingSelection",this.selection);b=this.selection.Fa();for(var c=0;c<a;c++)b[c].isSelected=!1;this.S("ChangedSelection",this.selection)}}else xa(a,">= 0",Q,"maxSelectionCount")}},nodeSelectionAdornmentTemplate:{get:function(){return this.St},set:function(a){var b=this.St;b!==a&&(this.St=a,this.g("nodeSelectionAdornmentTemplate",b,a))}},groupSelectionAdornmentTemplate:{
get:function(){return this.wt},set:function(a){var b=this.wt;b!==a&&(this.wt=a,this.g("groupSelectionAdornmentTemplate",b,a))}},linkSelectionAdornmentTemplate:{get:function(){return this.Kt},set:function(a){var b=this.Kt;b!==a&&(this.Kt=a,this.g("linkSelectionAdornmentTemplate",b,a))}},highlighteds:{get:function(){return this.ow}},isModified:{get:function(){var a=this.undoManager;return a.isEnabled?null!==a.currentTransaction?
!0:this.rn&&this.Ce!==a.historyIndex:this.rn},set:function(a){if(this.rn!==a){this.rn=a;var b=this.undoManager;!a&&b.isEnabled&&(this.Ce=b.historyIndex);a||fk(this)}}},model:{get:function(){return this.dc},set:function(a){var b=this.dc;if(b!==a){this.currentTool.doCancel();b&&b.undoManager!==a.undoManager&&b.undoManager.isInTransaction&&C("Do not replace a Diagram.model while a transaction is in progress.");this.animationManager.Cc(!0);var c=Di(this,!0);this.hf=!1;this.fn=
!0;this.Ce=-2;this.kg=!1;var d=this.wb;this.wb=!0;ih(this.animationManager,"Model");b&&(null!==this.pf&&this.pf.each(function(a){return b.hk(a)}),b.hk(this.Oc));this.dc=a;this.partManager=gk(this.dc.type);for(var e=0;e<c.length;e++)this.add(c[e]);a.Pe(this.Ib);this.partManager.addAllModeledParts();a.hk(this.Ib);a.Pe(this.Oc);null!==this.pf&&this.pf.each(function(b){return a.Pe(b)});this.wb=d;this.Ya();this.wb||this.N();b&&a.undoManager.copyProperties(b.undoManager)}}},aa:{
get:function(){return this.sw},set:function(a){this.sw=a}},As:{get:function(){return this.ty}},skipsModelSourceBindings:{get:function(){return this.Jw},set:function(a){this.Jw=a}},Ns:{get:function(){return this.ou},set:function(a){this.ou=a}},nodeTemplate:{get:function(){return this.Lg.I("")},set:function(a){var b=this.Lg.I("");b!==a&&(this.Lg.add("",a),this.g("nodeTemplate",b,a),
this.undoManager.isUndoingRedoing||this.Wd())}},nodeTemplateMap:{get:function(){return this.Lg},set:function(a){var b=this.Lg;b!==a&&(this.Lg=a,this.g("nodeTemplateMap",b,a),this.undoManager.isUndoingRedoing||this.Wd())}},groupTemplate:{get:function(){return this.Yi.I("")},set:function(a){var b=this.Yi.I("");b!==a&&(this.Yi.add("",a),this.g("groupTemplate",b,a),this.undoManager.isUndoingRedoing||this.Wd())}},groupTemplateMap:{
get:function(){return this.Yi},set:function(a){var b=this.Yi;b!==a&&(this.Yi=a,this.g("groupTemplateMap",b,a),this.undoManager.isUndoingRedoing||this.Wd())}},linkTemplate:{get:function(){return this.Kh.I("")},set:function(a){var b=this.Kh.I("");b!==a&&(this.Kh.add("",a),this.g("linkTemplate",b,a),this.undoManager.isUndoingRedoing||this.Wd())}},linkTemplateMap:{get:function(){return this.Kh},set:function(a){var b=this.Kh;b!==
a&&(this.Kh=a,this.g("linkTemplateMap",b,a),this.undoManager.isUndoingRedoing||this.Wd())}},isMouseCaptured:{get:function(){return this.rw},set:function(a){var b=this.ta;null!==b&&(b=b.Oa,b instanceof SVGElement||(a?(this.lastInput.bubbles=!1,this.removeEventListener(b,"pointermove",this.ck,!1),this.removeEventListener(b,"pointerdown",this.ui,!1),this.removeEventListener(b,"pointerup",this.ek,!1),this.removeEventListener(b,"pointerout",this.dk,!1),this.removeEventListener(b,
"pointercancel",this.bk,!1),this.addEventListener(x,"pointermove",this.ck,!0),this.addEventListener(x,"pointerdown",this.ui,!0),this.addEventListener(x,"pointerup",this.ek,!0),this.addEventListener(x,"pointerout",this.dk,!0),this.addEventListener(x,"pointercancel",this.bk,!0),this.removeEventListener(b,"wheel",this.Zj,!1),this.addEventListener(x,"wheel",this.Zj,!0),this.addEventListener(x,"selectstart",this.preventDefault,!1)):(this.removeEventListener(x,"pointermove",this.ck,!0),this.removeEventListener(x,
"pointerdown",this.ui,!0),this.removeEventListener(x,"pointerup",this.ek,!0),this.removeEventListener(x,"pointerout",this.dk,!0),this.removeEventListener(x,"pointercancel",this.bk,!0),this.addEventListener(b,"pointermove",this.ck,!1),this.addEventListener(b,"pointerdown",this.ui,!1),this.addEventListener(b,"pointerup",this.ek,!1),this.addEventListener(b,"pointerout",this.dk,!1),this.addEventListener(b,"pointercancel",this.bk,!1),this.removeEventListener(x,"wheel",this.Zj,!0),this.removeEventListener(x,
"selectstart",this.preventDefault,!1),this.addEventListener(b,"wheel",this.Zj,!1)),this.rw=a))}},position:{get:function(){return this.pa},set:function(a){var b=I.alloc().assign(this.pa);if(!b.D(a)){var c=this.viewportBounds.copy();this.pa.assign(a);Ah(this.animationManager,b,this.pa);this.wb||null===this.ta&&!this.wo.w()?Fi(this):(this.wb=!0,a=this.scale,mj(this,this.Sp,this.Aa/a,this.ya/a,this.yk,!1),this.wb=!1);if(!this.wb)this.onViewportBoundsChanged(c,this.viewportBounds,
this.Ca,!1)}I.free(b)}},initialPosition:{get:function(){return this.zt},set:function(a){this.zt.D(a)||(this.zt=a.J())}},initialScale:{get:function(){return this.At},set:function(a){this.At!==a&&(this.At=a)}},grid:{get:function(){null===this.fe&&(this.fe=Vi(this));return this.fe},set:function(a){var b=this.fe;if(b!==a){null===b&&(b=this.fe=Vi(this));a.type!==W.Grid&&C("Diagram.grid must be a Panel of type Panel.Grid");
var c=b.panel;null!==c&&c.remove(b);this.fe=a;a.name="GRID";null!==c&&c.add(a);vj(this);this.N();this.g("grid",b,a)}}},viewportBounds:{get:function(){return this.Sw}},viewSize:{get:function(){return this.wo},set:function(a){var b=this.viewSize;b.D(a)||(this.wo=a=a.J(),this.ya=this.Aa=0,a.w()?(this.Aa=a.width,this.ya=a.height):null!==this.Ka&&(this.Aa=this.Ka.clientWidth||1,this.ya=this.Ka.clientHeight||1),Fi(this),this.Ya(),this.g("viewSize",
b,a),this.N())}},fixedBounds:{get:function(){return this.ut},set:function(a){var b=this.ut;b.D(a)||(-Infinity!==a.width&&Infinity!==a.height&&-Infinity!==a.height||C("fixedBounds width/height must not be Infinity"),this.ut=a=a.J(),this.Ya(),this.g("fixedBounds",b,a))}},scrollMargin:{get:function(){return this.rj},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.rj;b.D(a)||(this.rj=a=a.J(),this.g("scrollMargin",b,a),this.gk())}},
scrollMode:{get:function(){return this.sj},set:function(a){var b=this.sj;b!==a&&(this.sj=a,a===Fh&&kj(this,!1),this.g("scrollMode",b,a),this.Ee=!0,this.N())}},scrollsPageOnFocus:{get:function(){return this.nu},set:function(a){var b=this.nu;b!==a&&(this.nu=a,this.g("scrollsPageOnFocus",b,a))}},positionComputation:{get:function(){return this.Zt},set:function(a){var b=this.Zt;b!==a&&(this.Zt=a,kj(this,!1),this.g("positionComputation",
b,a))}},scaleComputation:{get:function(){return this.fu},set:function(a){var b=this.fu;b!==a&&(this.fu=a,dk(this,this.scale,this.scale),this.g("scaleComputation",b,a))}},documentBounds:{get:function(){return this.Sp}},isVirtualized:{get:function(){return this.It},set:function(a){var b=this.It;b!==a&&(this.It=a,this.g("isVirtualized",b,a))}},scale:{get:function(){return this.Ca},
set:function(a){var b=this.Ca;b!==a&&dk(this,b,a)}},defaultScale:{get:function(){return this.Pp},set:function(a){this.Pp=a}},autoScale:{get:function(){return this.yh},set:function(a){var b=this.yh;b!==a&&(this.yh=a,this.g("autoScale",b,a),a!==oh&&kj(this,!1))}},initialAutoScale:{get:function(){return this.Fg},set:function(a){var b=this.Fg;b!==a&&(this.Fg=a,this.g("initialAutoScale",b,a))}},initialViewportSpot:{
get:function(){return this.Bt},set:function(a){var b=this.Bt;b!==a&&(a.sb()||C("initialViewportSpot must be a specific Spot: "+a),this.Bt=a,this.g("initialViewportSpot",b,a))}},initialDocumentSpot:{get:function(){return this.yt},set:function(a){var b=this.yt;b!==a&&(a.sb()||C("initialViewportSpot must be a specific Spot: "+a),this.yt=a,this.g("initialDocumentSpot",b,a))}},minScale:{get:function(){return this.Ot},set:function(a){var b=
this.Ot;b!==a&&(0<a?(this.Ot=a,this.g("minScale",b,a),a>this.scale&&(this.scale=a)):xa(a,"> 0",Q,"minScale"))}},maxScale:{get:function(){return this.Mt},set:function(a){var b=this.Mt;b!==a&&(0<a?(this.Mt=a,this.g("maxScale",b,a),a<this.scale&&(this.scale=a)):xa(a,"> 0",Q,"maxScale"))}},zoomPoint:{get:function(){return this.zu},set:function(a){this.zu.D(a)||(this.zu=a=a.J())}},contentAlignment:{get:function(){return this.yk},
set:function(a){var b=this.yk;b.D(a)||(this.yk=a=a.J(),this.g("contentAlignment",b,a),kj(this,!1))}},initialContentAlignment:{get:function(){return this.pq},set:function(a){var b=this.pq;b.D(a)||(this.pq=a=a.J(),this.g("initialContentAlignment",b,a))}},padding:{get:function(){return this.zb},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.zb;b.D(a)||(this.zb=a=a.J(),this.Ya(),this.g("padding",b,a))}},partManager:{
get:function(){return this.Pc},set:function(a){var b=this.Pc;b!==a&&(null!==a.diagram&&C("Cannot share PartManagers between Diagrams: "+a.toString()),b&&b.Xe(null),this.Pc=a,a.Xe(this))}},nodes:{get:function(){return this.partManager.nodes.iterator}},links:{get:function(){return this.partManager.links.iterator}},parts:{get:function(){return this.partManager.parts.iterator}},layout:{
get:function(){return this.Sc},set:function(a){var b=this.Sc;b!==a&&(this.Sc=a,a.diagram=this,a.group=null,this.xh=!0,this.g("layout",b,a),this.Rb())}},isTreePathToChildren:{get:function(){return this.Ht},set:function(a){var b=this.Ht;if(b!==a&&(this.Ht=a,this.g("isTreePathToChildren",b,a),!this.undoManager.isUndoingRedoing))for(a=this.nodes;a.next();)Bk(a.value)}},treeCollapsePolicy:{get:function(){return this.uu},set:function(a){var b=
this.uu;b!==a&&(a!==yi&&a!==Ck&&a!==Dk&&C("Unknown Diagram.treeCollapsePolicy: "+a),this.uu=a,this.g("treeCollapsePolicy",b,a))}},Ue:{get:function(){return this.qw},set:function(a){this.qw=a}},avoidanceCellSize:{get:function(){return this.bw},set:function(a){this.Xh=null;this.bw=new M(Math.max(1,a.width),Math.max(1,a.height))}},avoidanceLimit:{get:function(){return this.cw},set:function(a){this.cw=Math.max(1,
a)}},autoScrollInterval:{get:function(){return this.vp},set:function(a){var b=this.vp;b!==a&&(this.vp=a,this.g("autoScrollInterval",b,a))}},autoScrollRegion:{get:function(){return this.xp},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.xp;b.D(a)||(this.xp=a=a.J(),this.Ya(),this.g("autoScrollRegion",b,a))}}});
ma.Object.defineProperties(Q,{licenseKey:{get:function(){return Ek.kc()},set:function(a){Ek.add(a.replace(/\s/g,""))}},version:{get:function(){return Fk}}});Q.prototype.makeImageData=Q.prototype.Kx;Q.prototype.makeImage=Q.prototype.Xz;Q.prototype.cacheGroupExternalLinks=Q.prototype.Gu;Q.prototype.addRenderer=Q.prototype.bs;Q.prototype.makeSVG=Q.prototype.bv;Q.prototype.makeSvg=Q.prototype.Cs;Q.prototype.stopAutoScroll=Q.prototype.ug;
Q.prototype.doAutoScroll=Q.prototype.gs;Q.prototype.isUnoccupied=Q.prototype.Xj;Q.prototype.raiseDiagramEvent=Q.prototype.S;Q.prototype.removeDiagramListener=Q.prototype.am;Q.prototype.addDiagramListener=Q.prototype.Bj;Q.prototype.findTreeRoots=Q.prototype.zz;Q.prototype.layoutDiagram=Q.prototype.Vz;Q.prototype.findTopLevelGroups=Q.prototype.rz;Q.prototype.findTopLevelNodesAndLinks=Q.prototype.sz;Q.prototype.ensureBounds=Q.prototype.Va;Q.prototype.findLinksByExample=Q.prototype.ls;
Q.prototype.findNodesByExample=Q.prototype.ms;Q.prototype.findLinkForData=Q.prototype.Kc;Q.prototype.findNodeForData=Q.prototype.ii;Q.prototype.findPartForData=Q.prototype.Lc;Q.prototype.findLinkForKey=Q.prototype.findLinkForKey;Q.prototype.findNodeForKey=Q.prototype.Xb;Q.prototype.findPartForKey=Q.prototype.findPartForKey;Q.prototype.rebuildParts=Q.prototype.Wd;Q.prototype.transformViewToDoc=Q.prototype.Ps;Q.prototype.transformRectDocToView=Q.prototype.AA;Q.prototype.transformDocToView=Q.prototype.op;
Q.prototype.centerRect=Q.prototype.es;Q.prototype.scrollToRect=Q.prototype.uv;Q.prototype.scroll=Q.prototype.scroll;Q.prototype.highlightCollection=Q.prototype.Kz;Q.prototype.highlight=Q.prototype.Jz;Q.prototype.selectCollection=Q.prototype.sA;Q.prototype.select=Q.prototype.select;Q.prototype.updateAllRelationshipsFromData=Q.prototype.rp;Q.prototype.updateAllTargetBindings=Q.prototype.updateAllTargetBindings;Q.prototype.commit=Q.prototype.commit;Q.prototype.rollbackTransaction=Q.prototype.Kf;
Q.prototype.commitTransaction=Q.prototype.eb;Q.prototype.startTransaction=Q.prototype.Ea;Q.prototype.raiseChanged=Q.prototype.g;Q.prototype.raiseChangedEvent=Q.prototype.cb;Q.prototype.removeChangedListener=Q.prototype.hk;Q.prototype.addChangedListener=Q.prototype.Pe;Q.prototype.removeModelChangedListener=Q.prototype.nA;Q.prototype.addModelChangedListener=Q.prototype.Zw;Q.prototype.findLayer=Q.prototype.Pj;Q.prototype.removeLayer=Q.prototype.lA;Q.prototype.addLayerAfter=Q.prototype.Dy;
Q.prototype.addLayerBefore=Q.prototype.Xw;Q.prototype.addLayer=Q.prototype.Al;Q.prototype.moveParts=Q.prototype.moveParts;Q.prototype.copyParts=Q.prototype.Jj;Q.prototype.removeParts=Q.prototype.Is;Q.prototype.remove=Q.prototype.remove;Q.prototype.add=Q.prototype.add;Q.prototype.clearDelayedGeometries=Q.prototype.ex;Q.prototype.setProperties=Q.prototype.nm;Q.prototype.attach=Q.prototype.Eu;Q.prototype.set=Q.prototype.set;Q.prototype.resetInputOptions=Q.prototype.Tx;Q.prototype.setInputOption=Q.prototype.tA;
Q.prototype.getInputOption=Q.prototype.Ql;Q.prototype.resetRenderingHints=Q.prototype.Ux;Q.prototype.setRenderingHint=Q.prototype.Yx;Q.prototype.getRenderingHint=Q.prototype.Gf;Q.prototype.maybeUpdate=Q.prototype.bd;Q.prototype.requestUpdate=Q.prototype.Rb;Q.prototype.delayInitialization=Q.prototype.Yy;Q.prototype.isUpdateRequested=Q.prototype.Qz;Q.prototype.invalidateDocumentBounds=Q.prototype.Ya;Q.prototype.findObjectsNear=Q.prototype.og;Q.prototype.findPartsNear=Q.prototype.nz;
Q.prototype.findObjectsIn=Q.prototype.Cf;Q.prototype.findPartsIn=Q.prototype.vx;Q.prototype.findObjectsAt=Q.prototype.ji;Q.prototype.findPartsAt=Q.prototype.mz;Q.prototype.findObjectAt=Q.prototype.jc;Q.prototype.findPartAt=Q.prototype.Ol;Q.prototype.focusObject=Q.prototype.Az;Q.prototype.alignDocument=Q.prototype.Ky;Q.prototype.zoomToRect=Q.prototype.CA;Q.prototype.zoomToFit=Q.prototype.zoomToFit;Q.prototype.diagramScroll=Q.prototype.lx;Q.prototype.focus=Q.prototype.focus;
var oi=new ob,Gi=null,Lg=void 0!==x.document,Bi=null,Ci="",oh=new D(Q,"None",0),nj=new D(Q,"Uniform",1),oj=new D(Q,"UniformToFill",2),Df=new D(Q,"CycleAll",10),Hf=new D(Q,"CycleNotDirected",11),Jf=new D(Q,"CycleNotDirectedFast",12),Kf=new D(Q,"CycleNotUndirected",13),Ef=new D(Q,"CycleDestinationTree",14),Gf=new D(Q,"CycleSourceTree",15),Fh=new D(Q,"DocumentScroll",1),Th=new D(Q,"InfiniteScroll",2),yi=new D(Q,"TreeParentCollapsed",21),Ck=new D(Q,"AllParentsCollapsed",22),Dk=new D(Q,"AnyParentsCollapsed",
23),Ek=new F,Fk="2.3.15",Ri="image",Ti=null,mi=!1;
function ni(){if(Lg){var a=x.document.createElement("canvas"),b=a.getContext("2d"),c=Ma("7ca11abfd022028846");b[c]=Ma("398c3597c01238");for(var d=["5da73c80a36455d7038e4972187c3cae51fd22",ra.Dx+"4ae6247590da4bb21c324ba3a84e385776",ld.xF+"fb236cdfda5de14c134ba1a95a2d4c7cc6f93c1387",K.za],e=1;5>e;e++)b[Ma("7ca11abfd7330390")](Ma(d[e-1]),10,15*e);b[c]=Ma("39f046ebb36e4b");for(c=1;5>c;c++)b[Ma("7ca11abfd7330390")](Ma(d[c-1]),10,15*c);if(4!==d.length||"5"!==d[0][0]||"7"!==d[3][0])ld.prototype.Cd=ld.prototype.Fu;
Ti=a}}Q.className="Diagram";Q.fromDiv=function(a){var b=a;"string"===typeof a&&(b=x.document.getElementById(a));return b instanceof HTMLDivElement&&b.H instanceof Q?b.H:null};Q.inherit=function(a,b){function c(){}if(Object.getPrototypeOf(a).prototype)throw Error("Used go.Diagram.inherit defining already defined class \n"+a);c.prototype=b.prototype;a.prototype=new c;a.prototype.constructor=a};Q.useDOM=function(a){Lg=a?void 0!==x.document:!1};Q.isUsingDOM=function(){return Lg};Q.None=oh;Q.Uniform=nj;
Q.UniformToFill=oj;Q.CycleAll=Df;Q.CycleNotDirected=Hf;Q.CycleNotDirectedFast=Jf;Q.CycleNotUndirected=Kf;Q.CycleDestinationTree=Ef;Q.CycleSourceTree=Gf;Q.DocumentScroll=Fh;Q.InfiniteScroll=Th;Q.TreeParentCollapsed=yi;Q.AllParentsCollapsed=Ck;Q.AnyParentsCollapsed=Dk;function ui(){this.xy=null;this.j="zz@orderNum";"63ad05bbe23a1786468a4c741b6d2"===this._tk?this.Rc=this.j=!0:this.Rc=null}
function Fj(a,b){b.ib.setTransform(b.Nb,0,0,b.Nb,0,0);b.ib.yc();if(null===a.Rc)a:{b="f";var c=x[Ma("76a715b2f73f148a")][Ma("72ba13b5")]||"";a.Rc=!0;if(Lg){var d=Q[Ma("76a115b6ed251eaf4692")];if(d)for(var e=Ek.iterator;e.next();){d=e.value;d=Ma(d).split(Ma("39e9"));if(6>d.length)break;var f=Ma(d[1]).split(".");if("7da71ca0"!==d[4])break;var g=Ma(ra[Ma("6cae19")]).split(".");if(f[0]>g[0]||f[0]===g[0]&&f[1]>=g[1]){var h=x[Ma("74a900bae4370f8b51")][Ma("6fbb13a1c2311e8a57")].toLowerCase();g=h[Ma("73a612b6fb191d")](Ma("3aad1ab6e022098b4dc4"));
f=Ma(d[2]);if(-1<g&&(h=h[Ma("73a612b6fb191d")](f.toLowerCase()+"/"),a.Rc=!(-1<h&&h<g),!a.Rc))break;g=c[Ma("76ad18b4f73e")];for(h=c[Ma("73a612b6fb191d")](Ma("35e7"))+2;h<g;h++)b+=c[h];g=b[Ma("73a612b6fb191d")](f);0>g&&f!==Ma("7da71ca0ad381e90")&&(g=b[Ma("73a612b6fb191d")](Ma("76a715b2ef3e149757")));0>g&&(g=b[Ma("73a612b6fb191d")](Ma("76a715b2ef3e149757")));0>g&&(g=b[Ma("73a612b6fb191d")](Ma("2bfa41fdb3784bca12")));0>g&&(g=c[Ma("73a612b6fb191d")](Ma("7baa19a6f76c1988428554")));a.Rc=!(0<=g&&g<b[Ma("73a612b6fb191d")](Ma("35"))||
-1===b[Ma("73a612b6fb191d")](Ma("35")));if(!a.Rc)break;if(c[Ma("73a612b6fb191d")](Ma("7fb002b6ed25128b4da25b2e"))&&(a.Rc=!(-1<c[Ma("73a612b6fb191d")](f)),!a.Rc))break;g=x[Ma("76a715b2f73f148a")][Ma("69ad17a1e03e")];h=g[Ma("73a612b6fb191d")](Ma("7fb002b6ed25128b4da25b2e"));if(-1<h&&(a.Rc=!(g[Ma("73a612b6fb191d")](f)>h),!a.Rc))break;if("#"===f[0]){g=x.document.createElement("div");for(var k=d[0].replace(/[A-Za-z]/g,"");4>k.length;)k+="9";h=k;k=k.substr(k.length-4);d="";d+=["gsh","gsf"][parseInt(k.substr(0,
1),10)%2];d+=["Header","Background","Display","Feedback"][parseInt(k.substr(0,1),10)%4];g[Ma("79a417a0f0181a8946")]=d;if(x.document[Ma("78a712aa")]){x.document[Ma("78a712aa")][Ma("7bb806b6ed32388c4a875b")](g);k=x.getComputedStyle(g).getPropertyValue(Ma("78a704b7e62456904c9b12701b6532a8"));var l=x.getComputedStyle(g).getPropertyValue(Ma("75b817b0ea2202"));x.document[Ma("78a712aa")][Ma("68ad1bbcf533388c4a875b")](g);if(k)if(g=x[Ma("77a902b0eb1b1e804a8a")],l==="0."+parseInt(h,10)%100||-1!==k.indexOf(parseInt(f[1]+
f[2],16))&&-1!==k.indexOf(parseInt(f[3]+f[4],16))){a.Rc=!1;break}else if(g&&g(Ma("32ae19a1e0331fc94084537c067a67fa59f1386b89d006")).matches)for(d="."+d,f=0;f<document.styleSheets.length;f++){g=document.styleSheets[f].rules||document.styleSheets[f].cssRules;for(var m in g)if(d===g[m].selectorText){a.Rc=!1;break a}}}else a.Rc=null,a.Rc=!1}}}else{m=c[Ma("76ad18b4f73e")];for(e=c[Ma("73a612b6fb191d")](Ma("35e7"))+2;e<m;e++)b+=c[e];c=b[Ma("73a612b6fb191d")](Ma("7da71ca0ad381e90"));a.Rc=!(0<=c&&c<b[Ma("73a612b6fb191d")](Ma("35")))}}}return 0<
a.Rc&&a!==a.xy?!0:!1}ui.className="DiagramHelper";function Me(a){this.j=void 0===a?new I:a;this.o=new I}ma.Object.defineProperties(Me.prototype,{point:{get:function(){return this.j},set:function(a){this.j=a}},shifted:{get:function(){return this.o},set:function(a){this.o=a}}});var Qi="DraggingInfo";Me.className="DraggingInfo";function Wj(a,b,c){this.node=a;this.info=b;this.Tu=c}Wj.className="DraggingNodeInfoPair";
function Ee(){this.reset()}Ee.prototype.reset=function(){this.isGridSnapEnabled=!1;this.isGridSnapRealtime=!0;this.gridSnapCellSize=(new M(NaN,NaN)).freeze();this.gridSnapCellSpot=sc;this.gridSnapOrigin=(new I(NaN,NaN)).freeze();this.groupsSnapMembers=this.dragsTree=this.dragsLink=!1;this.dragsMembers=this.groupsAlwaysMove=!0};Ee.className="DraggingOptions";
function Gk(a,b){if("string"===typeof a||x.Element&&a instanceof Element)var c=Q.call(this,a)||this;else c=Q.call(this)||this,b=a;Hk(c);b&&c.nm(b);return c}la(Gk,Q);function Hk(a){a.allowDragOut=!0;a.allowMove=!1;a.isReadOnly=!0;a.contentAlignment=tc;a.layout=new Ik}Gk.prototype.reset=function(){Q.prototype.reset.call(this);Hk(this)};Gk.className="Palette";
function Ak(a,b){if("string"===typeof a||x.Element&&a instanceof Element)var c=Q.call(this,a)||this;else c=Q.call(this)||this,b=a;var d=c;c.animationManager.isEnabled=!1;c.wb=!0;c.V=null;c.mn=!1;c.nn=!1;c.F=!0;c.u=!0;c.ia=0;c.o=!1;c.ql=null;c.Yx("drawShadows",!1);a=new U;var e=new Bf;e.stroke="magenta";e.strokeWidth=2;e.fill="transparent";e.name="BOXSHAPE";a.selectable=!0;a.selectionAdorned=!1;a.selectionObjectName="BOXSHAPE";a.locationObjectName="BOXSHAPE";a.resizeObjectName="BOXSHAPE";a.cursor=
"move";a.add(e);c.j=a;c.allowCopy=!1;c.allowDelete=!1;c.allowSelect=!0;c.autoScrollRegion=new cc(0,0,0,0);c.pa.h(0,0);c.toolManager.gb("Dragging",new Jk,c.toolManager.mouseMoveTools);c.click=function(){var a=d.observed;if(null!==a){var b=a.viewportBounds,c=d.lastInput.documentPoint;a.position=new I(c.x-b.width/2,c.y-b.height/2)}};c.Pf=function(){d.Ya();Kk(d)};c.Xd=function(){null!==d.observed&&(d.Ya(),d.N())};c.Ha=function(){1>d.updateDelay?d.N():d.o||(d.o=!0,setTimeout(function(){return d.redraw()},
d.updateDelay))};c.Of=function(){null!==d.observed&&Kk(d)};c.autoScale=nj;c.wb=!1;b&&Object.assign(c,b);return c}la(Ak,Q);Ak.prototype.computePixelRatio=function(){return 1};
Ak.prototype.redraw=function(){if(this.o&&1<=this.updateDelay){this.o=!1;var a=this.ta,b=this.ib;if(null!==a&&null!==b){Cj(this);if(null===this.ql){var c=new Hi(null);c.width=a.width;c.height=a.height;this.ql=c}try{this.ta=this.ql,this.ib=this.ta.context,this.ib.Vc(!0),this.ib.setTransform(1,0,0,1,0,0),this.ib.clearRect(0,0,this.ta.width,this.ta.height),Lk(this)}finally{this.ta=a,this.ib=b}}}Q.prototype.redraw.call(this)};
Ak.prototype.hc=function(){null===this.Ka&&C("No div specified");null===this.ta&&C("No canvas specified");if(!(this.ta instanceof Kj)&&(fi(this.box),this.Fc)){var a=this.observed;if(null!==a&&!a.animationManager.defaultAnimation.isAnimating&&a.pi){Cj(this);var b=this.ta;a=this.ib;a.Vc(!0);a.setTransform(1,0,0,1,0,0);a.clearRect(0,0,b.width,b.height);1>this.updateDelay?Lk(this):null!==this.ql&&(a.drawImage(this.ql.Oa,0,0),b=this.Bb,b.reset(),1!==this.scale&&b.scale(this.scale),0===this.position.x&&
0===this.position.y||b.translate(-this.position.x,-this.position.y),a.scale(this.Nb,this.Nb),a.transform(b.m11,b.m12,b.m21,b.m22,b.dx,b.dy),a.yc());b=this.Wa.s;for(var c=b.length,d=0;d<c;d++)b[d].hc(a,this);this.Fc=this.vd=!1}}};
function Lk(a){var b=a.observed;if(null!==b){var c=a.drawsTemporaryLayers,d=a.drawsGrid&&c,e=b.grid;d&&null!==e&&e.visible&&!isNaN(e.width)&&!isNaN(e.height)&&(e=N.alloc().assign(a.viewportBounds).ed(b.viewportBounds),vj(b,e),N.free(e),jh(b));var f=a.Bb;f.reset();1!==a.scale&&f.scale(a.scale);0===a.position.x&&0===a.position.y||f.translate(-a.position.x,-a.position.y);e=a.ib;e.scale(a.Nb,a.Nb);e.transform(f.m11,f.m12,f.m21,f.m22,f.dx,f.dy);e.yc();b=b.Wa.s;f=b.length;for(var g=0;g<f;g++){var h=b[g],
k=a;if(h.visible&&0!==h.opacity){var l=h.diagram.grid.part;if(!c&&h.isTemporary)d&&l.layer===h&&(h=gi(h,e),l.hc(e,k),e.globalAlpha=h);else{for(var m=gi(h,e),n=k.scale,p=N.alloc(),q=h.Pa.s,r=q.length,u=0;u<r;u++){var w=q[u];(d||w!==l)&&h.Nj(e,w,k,null,n,p,!1)}N.free(p);e.globalAlpha=m}}}}}
function Kk(a){var b=a.box;if(null!==b){var c=a.observed;if(null!==c){a.Fc=!0;c=c.viewportBounds;var d=b.selectionObject,e=M.alloc();e.h(c.width,c.height);d.desiredSize=e;M.free(e);a=2/a.scale;d instanceof Bf&&(d.strokeWidth=a);b.location=new I(c.x-a/2,c.y-a/2);b.isSelected=!0}}}Ak.prototype.computeBounds=function(){var a=this.observed;if(null===a)return ac;var b=a.documentBounds.copy();b.ed(a.viewportBounds);return b};Ak.prototype.invalidateViewport=function(){!0!==this.Fc&&(this.Fc=!0,this.Rb())};
Ak.prototype.onViewportBoundsChanged=function(a,b,c,d){this.wb||(this.vd=!0,this.N(),this.Ee=!0,this.Ya(),Kk(this),this.gc.scale=c,this.gc.position.x=a.x,this.gc.position.y=a.y,this.gc.bounds.assign(a),this.gc.Pz=d,this.S("ViewportBoundsChanged",this.gc,a))};
ma.Object.defineProperties(Ak.prototype,{observed:{get:function(){return this.V},set:function(a){var b=this.V;a instanceof Ak&&C("Overview.observed Diagram may not be an Overview itself: "+a);if(b!==a){null!==b&&(this.remove(this.box),b.am("ViewportBoundsChanged",this.Pf),b.am("DocumentBoundsChanged",this.Xd),b.am("InvalidateDraw",this.Ha),b.am("AnimationFinished",this.Of));this.V=a;null!==a&&(a.Bj("ViewportBoundsChanged",this.Pf),a.Bj("DocumentBoundsChanged",this.Xd),
a.Bj("InvalidateDraw",this.Ha),a.Bj("AnimationFinished",this.Of),this.add(this.box));this.Ya();if(null===a){this.ql=null;var c=this.ta,d=this.ib;c&&d&&(d.setTransform(1,0,0,1,0,0),d.clearRect(0,0,c.width,c.height))}else this.Ha(null),Kk(this),this.N();this.g("observed",b,a)}}},box:{get:function(){return this.j},set:function(a){var b=this.j;b!==a&&(this.j=a,this.remove(b),this.add(this.j),Kk(this),this.g("box",b,a))}},drawsTemporaryLayers:{
get:function(){return this.F},set:function(a){this.F!==a&&(this.F=a,this.redraw())}},drawsGrid:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a,this.redraw())}},updateDelay:{get:function(){return this.ia},set:function(a){0>a&&(a=0);this.ia!==a&&(this.ia=a)}}});Ak.className="Overview";function Jk(){De.call(this);this.j=null}la(Jk,De);
Jk.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(null===a||!a.allowMove||!a.allowSelect)return!1;var b=a.observed;if(null===b)return!1;var c=a.lastInput;if(!c.left||a.currentTool!==this&&(!this.isBeyondDragSize()||c.isTouchEvent&&c.timestamp-a.firstInput.timestamp<this.delay))return!1;null===this.findDraggablePart()&&(c=b.viewportBounds,this.j=new I(c.width/2,c.height/2),a=a.firstInput.documentPoint,b.position=new I(a.x-this.j.x,a.y-this.j.y));return!0};
Jk.prototype.doActivate=function(){this.j=null;De.prototype.doActivate.call(this)};Jk.prototype.doUpdateCursor=function(){var a=this.diagram,b=a.box;null!==b&&b.cursor&&(a.currentCursor=b.cursor)};Jk.prototype.moveParts=function(){var a=this.diagram,b=a.observed;if(null!==b){var c=a.box;if(null!==c){if(null===this.j){var d=a.firstInput.documentPoint;c=c.location;this.j=new I(d.x-c.x,d.y-c.y)}a=a.lastInput.documentPoint;b.position=new I(a.x-this.j.x,a.y-this.j.y)}}};Jk.className="OverviewDraggingTool";
function Mk(){Qa(this);this.H=le;this.Ha=this.u=this.o=!0;this.F=this.V=this.nb=this.ia=!1;this.ij=this.j=null;this.Ib=1.05;this.Jt=NaN;this.tw=null;this.Au=NaN;this.yu=ac;this.hb=!0;this.cg=null;this.ob=200}Mk.prototype.toString=function(){return"CommandHandler"};Mk.prototype.Xe=function(a){this.H=a};
Mk.prototype.doKeyDown=function(){var a=this.diagram,b=a.lastInput,c=Pa?b.meta:b.control,d=b.shift,e=b.alt,f=b.key;!c||"C"!==f&&"Insert"!==f?c&&"X"===f||d&&"Del"===f?this.canCutSelection()&&this.cutSelection():c&&"V"===f||d&&"Insert"===f?this.canPasteSelection()&&this.pasteSelection():c&&"Y"===f||e&&d&&"Backspace"===f?this.canRedo()&&this.redo():c&&"Z"===f||e&&"Backspace"===f?this.canUndo()&&this.undo():"Del"===f||"Backspace"===f?this.canDeleteSelection()&&this.deleteSelection():c&&"A"===f?this.canSelectAll()&&
this.selectAll():"Esc"===f?this.canStopCommand()&&this.stopCommand():"Up"===f?a.allowVerticalScroll&&(c?a.scroll("pixel","up"):a.scroll("line","up")):"Down"===f?a.allowVerticalScroll&&(c?a.scroll("pixel","down"):a.scroll("line","down")):"Left"===f?a.allowHorizontalScroll&&(c?a.scroll("pixel","left"):a.scroll("line","left")):"Right"===f?a.allowHorizontalScroll&&(c?a.scroll("pixel","right"):a.scroll("line","right")):"PageUp"===f?d&&a.allowHorizontalScroll?a.scroll("page","left"):a.allowVerticalScroll&&
a.scroll("page","up"):"PageDown"===f?d&&a.allowHorizontalScroll?a.scroll("page","right"):a.allowVerticalScroll&&a.scroll("page","down"):"Home"===f?c&&a.allowVerticalScroll?a.scroll("document","up"):!c&&a.allowHorizontalScroll&&a.scroll("document","left"):"End"===f?c&&a.allowVerticalScroll?a.scroll("document","down"):!c&&a.allowHorizontalScroll&&a.scroll("document","right"):" "===f?this.canScrollToPart()&&this.scrollToPart():"Subtract"===f?this.canDecreaseZoom()&&this.decreaseZoom():"Add"===f?this.canIncreaseZoom()&&
this.increaseZoom():c&&"0"===f?this.canResetZoom()&&this.resetZoom():d&&"Z"===f?this.canZoomToFit()&&this.zoomToFit():c&&!d&&"G"===f?this.canGroupSelection()&&this.groupSelection():c&&d&&"G"===f?this.canUngroupSelection()&&this.ungroupSelection():"F2"===f||b.event&&"F2"===b.event.key?this.canEditTextBlock()&&this.editTextBlock():"ContextMenu"===f||b.event&&"ContextMenu"===b.event.key?this.canShowContextMenu()&&this.showContextMenu():b.bubbles=!0:this.canCopySelection()&&this.copySelection()};
Mk.prototype.doKeyUp=function(){this.diagram.lastInput.bubbles=!0};Mk.prototype.stopCommand=function(){var a=this.diagram,b=a.currentTool;b instanceof La&&a.allowSelect&&a.clearSelection();null!==b&&b.doCancel()};Mk.prototype.canStopCommand=function(){return!0};
Mk.prototype.selectAll=function(){var a=this.diagram;a.N();try{a.currentCursor="wait";a.S("ChangingSelection",a.selection);for(var b=a.parts;b.next();)b.value.isSelected=!0;for(var c=a.nodes;c.next();)c.value.isSelected=!0;for(var d=a.links;d.next();)d.value.isSelected=!0}finally{a.S("ChangedSelection",a.selection),a.currentCursor=""}};Mk.prototype.canSelectAll=function(){return this.diagram.allowSelect};
Mk.prototype.deleteSelection=function(){var a=this.diagram;try{a.currentCursor="wait";a.S("ChangingSelection",a.selection);a.Ea("Delete");a.S("SelectionDeleting",a.selection);for(var b=new H,c=a.selection.iterator;c.next();)Nk(b,c.value,!0,this.deletesTree?Infinity:0,this.deletesConnectedLinks?null:!1,function(a){return a.canDelete()});a.Is(b,!0);a.S("SelectionDeleted",b)}finally{a.eb("Delete"),a.S("ChangedSelection",a.selection),a.currentCursor=""}};
Mk.prototype.canDeleteSelection=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly||!a.allowDelete||0===a.selection.count?!1:!0};Mk.prototype.copySelection=function(){var a=this.diagram,b=new H;for(a=a.selection.iterator;a.next();)Nk(b,a.value,!0,this.copiesTree?Infinity:0,this.copiesConnectedLinks,function(a){return a.canCopy()});this.copyToClipboard(b)};Mk.prototype.canCopySelection=function(){var a=this.diagram;return a.allowCopy&&a.allowClipboard&&0!==a.selection.count?!0:!1};
Mk.prototype.cutSelection=function(){this.copySelection();this.deleteSelection()};Mk.prototype.canCutSelection=function(){var a=this.diagram;return!a.isReadOnly&&!a.isModelReadOnly&&a.allowCopy&&a.allowDelete&&a.allowClipboard&&0!==a.selection.count?!0:!1};
Mk.prototype.copyToClipboard=function(a){var b=this.diagram,c=null;if(null===a)Bi=null,Ci="";else{c=b.model;var d=!1,e=!1,f=null;try{c.Tl()&&(d=c.Ij,c.Ij=this.copiesParentKey),c.Vj()&&(e=c.Hj,c.Hj=this.copiesGroupKey),f=b.Jj(a,null,!0)}finally{c.Tl()&&(c.Ij=d),c.Vj()&&(c.Hj=e),c=new F,c.addAll(f),Bi=c,Ci=b.model.dataFormat}}b.S("ClipboardChanged",c)};
Mk.prototype.pasteFromClipboard=function(){var a=new H,b=Bi;if(null===b)return a;var c=this.diagram;if(Ci!==c.model.dataFormat)return a;var d=c.model,e=!1,f=!1,g=null;try{d.Tl()&&(e=d.Ij,d.Ij=this.copiesParentKey),d.Vj()&&(f=d.Hj,d.Hj=this.copiesGroupKey),g=c.Jj(b,c,!1)}finally{for(d.Tl()&&(d.Ij=e),d.Vj()&&(d.Hj=f),b=g.iterator;b.next();)c=b.value,d=b.key,c.location.w()||(d.location.w()?c.location=d.location:!c.position.w()&&d.position.w()&&(c.position=d.position)),a.add(c)}return a};
Mk.prototype.pasteSelection=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.currentCursor="wait";b.S("ChangingSelection",b.selection);b.Ea("Paste");var c=this.pasteFromClipboard();0<c.count&&b.clearSelection(!0);for(var d=c.iterator;d.next();)d.value.isSelected=!0;if(null!==a){var e=b.computePartsBounds(b.selection);if(e.w()){var f=this.computeEffectiveCollection(b.selection,b.Wm);ff(b,f,new I(a.x-e.centerX,a.y-e.centerY),b.Wm,!1)}}b.S("ClipboardPasted",c)}finally{b.eb("Paste"),b.S("ChangedSelection",
b.selection),b.currentCursor=""}};Mk.prototype.canPasteSelection=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.allowClipboard||null===Bi||0===Bi.count||Ci!==a.model.dataFormat?!1:!0};Mk.prototype.undo=function(){this.diagram.undoManager.undo()};Mk.prototype.canUndo=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly?!1:a.allowUndo&&a.undoManager.canUndo()};Mk.prototype.redo=function(){this.diagram.undoManager.redo()};
Mk.prototype.canRedo=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly?!1:a.allowUndo&&a.undoManager.canRedo()};Mk.prototype.decreaseZoom=function(a){void 0===a&&(a=1/this.zoomFactor);var b=this.diagram;b.autoScale===oh&&(a=b.scale*a,a<b.minScale||a>b.maxScale||(b.scale=a))};Mk.prototype.canDecreaseZoom=function(a){void 0===a&&(a=1/this.zoomFactor);var b=this.diagram;if(b.autoScale!==oh)return!1;a=b.scale*a;return a<b.minScale||a>b.maxScale?!1:b.allowZoom};
Mk.prototype.increaseZoom=function(a){void 0===a&&(a=this.zoomFactor);var b=this.diagram;b.autoScale===oh&&(a=b.scale*a,a<b.minScale||a>b.maxScale||(b.scale=a))};Mk.prototype.canIncreaseZoom=function(a){void 0===a&&(a=this.zoomFactor);var b=this.diagram;if(b.autoScale!==oh)return!1;a=b.scale*a;return a<b.minScale||a>b.maxScale?!1:b.allowZoom};Mk.prototype.resetZoom=function(a){void 0===a&&(a=this.defaultScale);var b=this.diagram;a<b.minScale||a>b.maxScale||(b.scale=a)};
Mk.prototype.canResetZoom=function(a){void 0===a&&(a=this.defaultScale);var b=this.diagram;return a<b.minScale||a>b.maxScale?!1:b.allowZoom};
Mk.prototype.zoomToFit=function(){var a=this.diagram,b=a.animationManager;b.Cc();a.redraw();var c=a.position,d=a.scale;ih(b,"Zoom To Fit");this.isZoomToFitRestoreEnabled&&d===this.Au&&!isNaN(this.Jt)&&a.documentBounds.D(this.yu)?(a.scale=this.Jt,a.position=this.tw,this.Au=NaN,this.yu=ac):(this.Jt=d,this.tw=c.copy(),a.zoomToFit(),this.Au=a.scale,this.yu=a.documentBounds.copy());kh(b)};Mk.prototype.canZoomToFit=function(){return this.diagram.allowZoom};
Mk.prototype.scrollToPart=function(a){void 0===a&&(a=null);var b=this.diagram;b.Va();if(null===a){try{null!==this.cg&&(this.cg.next()?a=this.cg.value:this.cg=null)}catch(k){this.cg=null}null===a&&(0<b.highlighteds.count?this.cg=b.highlighteds.iterator:0<b.selection.count&&(this.cg=b.selection.iterator),null!==this.cg&&this.cg.next()&&(a=this.cg.value))}if(null!==a){var c=b.animationManager;ih(c,"Scroll To Part");var d=this.scrollToPartPause;if(0<d){var e=Ok(this,a,[a]);if(1===e.length)b.Ea(),b.es(a.actualBounds),
b.eb("Scroll To Part");else{var f=function(){b.Ea();for(var a=e.pop();0<e.length&&a instanceof V&&a.isTreeExpanded&&(!(a instanceof jf)||a.isSubGraphExpanded);)a=e.pop();0<e.length?(a instanceof U&&b.uv(a.actualBounds),a instanceof V&&!a.isTreeExpanded&&(a.isTreeExpanded=!0),a instanceof jf&&!a.isSubGraphExpanded&&(a.isSubGraphExpanded=!0)):(a instanceof U&&b.es(a.actualBounds),b.am("LayoutCompleted",g));b.eb("Scroll To Part")},g=function(){ta(f,(c.isEnabled?c.duration:0)+d)};b.Bj("LayoutCompleted",
g);f()}}else{var h=b.position.copy();b.es(a.actualBounds);h.Xa(b.position)&&c.Cc()}}};
function Ok(a,b,c){if(b.isVisible())return c;if(b instanceof pe)Ok(a,b.adornedPart,c);else if(b instanceof T){var d=b.fromNode;null!==d&&Ok(a,d,c);b=b.toNode;null!==b&&Ok(a,b,c)}else b instanceof V&&(d=b.labeledLink,null!==d&&Ok(a,d,c),d=b.pg(),null!==d&&(d.isTreeExpanded||d.wasTreeExpanded||c.push(d),Ok(a,d,c))),b=b.containingGroup,null!==b&&(b.isSubGraphExpanded||b.wasSubGraphExpanded||c.push(b),Ok(a,b,c));return c}
Mk.prototype.canScrollToPart=function(a){void 0===a&&(a=null);if(null!==a&&!(a instanceof U))return!1;a=this.diagram;return 0===a.selection.count&&0===a.highlighteds.count?!1:a.allowHorizontalScroll&&a.allowVerticalScroll};
Mk.prototype.collapseTree=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Ea("Collapse Tree");ih(b.animationManager,"Collapse Tree");var c=new F;if(null!==a&&a.isTreeExpanded)a.collapseTree(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof V&&e.isTreeExpanded&&(e.collapseTree(),c.add(e))}b.S("TreeCollapsed",c)}finally{b.eb("Collapse Tree")}};
Mk.prototype.canCollapseTree=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a){if(!(a instanceof V&&a.isTreeExpanded))return!1;if(0<a.Qo().count)return!0}else for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof V&&b.isTreeExpanded&&0<b.Qo().count)return!0;return!1};
Mk.prototype.expandTree=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Ea("Expand Tree");ih(b.animationManager,"Expand Tree");var c=new F;if(null!==a&&!a.isTreeExpanded)a.expandTree(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof V&&!e.isTreeExpanded&&(e.expandTree(),c.add(e))}b.S("TreeExpanded",c)}finally{b.eb("Expand Tree")}};
Mk.prototype.canExpandTree=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a){if(!(a instanceof V)||a.isTreeExpanded)return!1;if(0<a.Qo().count)return!0}else for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof V&&!b.isTreeExpanded&&0<b.Qo().count)return!0;return!1};
Mk.prototype.groupSelection=function(){var a=this.diagram,b=a.model;if(b.Wj()){var c=this.archetypeGroupData;if(null!==c){var d=null;try{a.currentCursor="wait";a.S("ChangingSelection",a.selection);a.Ea("Group");for(var e=new F,f=a.selection.iterator;f.next();){var g=f.value;g.Zc()&&g.canGroup()&&e.add(g)}for(var h=new F,k=e.iterator;k.next();){var l=k.value;f=!1;for(var m=e.iterator;m.next();)if(l.Td(m.value)){f=!0;break}f||h.add(l)}if(0<h.count){var n=h.first().containingGroup;if(null!==n)for(;null!==
n;){e=!1;for(var p=h.iterator;p.next();)if(!p.value.Td(n)){e=!0;break}if(e)n=n.containingGroup;else break}if(c instanceof jf)jg(c),d=c.copy(),null!==d&&a.add(d);else if(b.Zu(c)){var q=b.copyNodeData(c);za(q)&&(b.xf(q),d=a.ii(q))}if(null!==d){null!==n&&this.isValidMember(n,d)&&(d.containingGroup=n);for(var r=h.iterator;r.next();){var u=r.value;this.isValidMember(d,u)&&(u.containingGroup=d)}a.clearSelection(!0);d.isSelected=!0}}a.S("SelectionGrouped",d)}finally{a.eb("Group"),a.S("ChangedSelection",
a.selection),a.currentCursor=""}}}};Mk.prototype.canGroupSelection=function(){var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.allowGroup||!a.model.Wj()||null===this.archetypeGroupData)return!1;for(a=a.selection.iterator;a.next();){var b=a.value;if(b.Zc()&&b.canGroup())return!0}return!1};
function Pk(a){var b=Ea();for(a=a.iterator;a.next();){var c=a.value;c instanceof T||b.push(c)}a=new H;c=b.length;for(var d=0;d<c;d++){for(var e=b[d],f=!0,g=0;g<c;g++)if(e.Td(b[g])){f=!1;break}f&&a.add(e)}Ga(b);return a}
Mk.prototype.isValidMember=function(a,b){if(null===b||a===b||b instanceof T)return!1;if(null!==a){if(a===b||a.Td(b))return!1;var c=a.memberValidation;if(null!==c&&!c(a,b)||null===a.data&&null!==b.data||null!==a.data&&null===b.data)return!1}c=this.memberValidation;return null!==c?c(a,b):!0};
Mk.prototype.ungroupSelection=function(a){void 0===a&&(a=null);var b=this.diagram,c=b.model;if(c.Wj())try{b.currentCursor="wait";b.S("ChangingSelection",b.selection);b.Ea("Ungroup");var d=new F;if(null!==a)d.add(a);else for(var e=b.selection.iterator;e.next();){var f=e.value;f instanceof jf&&f.canUngroup()&&d.add(f)}var g=new F;if(0<d.count){b.clearSelection(!0);for(var h=d.iterator;h.next();){var k=h.value;k.expandSubGraph();var l=k.containingGroup,m=null!==l&&null!==l.data?c.wa(l.data):void 0;g.addAll(k.memberParts);
for(var n=g.iterator;n.next();){var p=n.value;p.isSelected=!0;if(!(p instanceof T)){var q=p.data;null!==q?c.mp(q,m):p.containingGroup=l}}b.remove(k)}}b.S("SelectionUngrouped",d,g)}finally{b.eb("Ungroup"),b.S("ChangedSelection",b.selection),b.currentCursor=""}};
Mk.prototype.canUngroupSelection=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly||b.isModelReadOnly||!b.allowDelete||!b.allowUngroup||!b.model.Wj())return!1;if(null!==a){if(!(a instanceof jf))return!1;if(a.canUngroup())return!0}else for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof jf&&b.canUngroup())return!0;return!1};
Mk.prototype.addTopLevelParts=function(a,b){var c=!0;for(a=Pk(a).iterator;a.next();){var d=a.value;null!==d.containingGroup&&(!b||this.isValidMember(null,d)?d.containingGroup=null:c=!1)}return c};
Mk.prototype.collapseSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Ea("Collapse SubGraph");ih(b.animationManager,"Collapse SubGraph");var c=new F;if(null!==a&&a.isSubGraphExpanded)a.collapseSubGraph(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof jf&&e.isSubGraphExpanded&&(e.collapseSubGraph(),c.add(e))}b.S("SubGraphCollapsed",c)}finally{b.eb("Collapse SubGraph")}};
Mk.prototype.canCollapseSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a)return a instanceof jf&&a.isSubGraphExpanded?!0:!1;for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof jf&&b.isSubGraphExpanded)return!0;return!1};
Mk.prototype.expandSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Ea("Expand SubGraph");ih(b.animationManager,"Expand SubGraph");var c=new F;if(null!==a&&!a.isSubGraphExpanded)a.expandSubGraph(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof jf&&!e.isSubGraphExpanded&&(e.expandSubGraph(),c.add(e))}b.S("SubGraphExpanded",c)}finally{b.eb("Expand SubGraph")}};
Mk.prototype.canExpandSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a)return a instanceof jf&&!a.isSubGraphExpanded?!0:!1;for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof jf&&!b.isSubGraphExpanded)return!0;return!1};
Mk.prototype.editTextBlock=function(a){void 0===a&&(a=null);var b=this.diagram,c=b.toolManager.findTool("TextEditing");if(null!==c){if(null===a){a=null;for(var d=b.selection.iterator;d.next();){var e=d.value;if(e.canEdit()){a=e;break}}if(null===a)return;a=a.Nl(function(a){return a instanceof Ng&&a.editable})}null!==a&&(b.currentTool=null,c.textBlock=a,b.currentTool=c)}};
Mk.prototype.canEditTextBlock=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly||b.isModelReadOnly||!b.allowTextEdit||null===b.toolManager.findTool("TextEditing"))return!1;if(null!==a){if(!(a instanceof Ng))return!1;a=a.part;if(null!==a&&a.canEdit())return!0}else for(b=b.selection.iterator;b.next();)if(a=b.value,a.canEdit()&&(a=a.Nl(function(a){return a instanceof Ng&&a.editable}),null!==a))return!0;return!1};
Mk.prototype.showContextMenu=function(a){var b=this.diagram,c=b.toolManager.findTool("ContextMenu");if(null!==c&&(void 0===a&&(a=0<b.selection.count?b.selection.first():b),a=c.findObjectWithContextMenu(a),null!==a)){var d=b.lastInput,e=null;a instanceof Y?e=a.ga(wc):b.viewportBounds.ca(d.documentPoint)||(e=b.viewportBounds,e=new I(e.x+e.width/2,e.y+e.height/2));null!==e&&(d.viewPoint=b.op(e),d.documentPoint=e,d.left=!1,d.right=!0,d.up=!0);b.currentTool=c;Jg(c,!1,a)}};
Mk.prototype.canShowContextMenu=function(a){var b=this.diagram,c=b.toolManager.findTool("ContextMenu");if(null===c)return!1;void 0===a&&(a=0<b.selection.count?b.selection.first():b);return null===c.findObjectWithContextMenu(a)?!1:!0};
Mk.prototype.computeEffectiveCollection=function(a,b){var c=this.diagram,d=c.toolManager.findTool("Dragging"),e=c.currentTool===d;void 0===b&&(b=e?d.dragOptions:c.Wm);d=new ob;if(null===a)return d;for(var f=a.iterator;f.next();)Uj(c,d,f.value,e,b);if(null!==c.draggedLink&&b.dragsLink)return d;for(f=a.iterator;f.next();)a=f.value,a instanceof T&&(b=a.fromNode,null===b||d.contains(b)?(b=a.toNode,null===b||d.contains(b)||d.remove(a)):d.remove(a));return d};
ma.Object.defineProperties(Mk.prototype,{diagram:{get:function(){return this.H}},copiesClipboardData:{get:function(){return this.o},set:function(a){this.o=a}},copiesConnectedLinks:{get:function(){return this.u},set:function(a){this.u=a}},deletesConnectedLinks:{get:function(){return this.Ha},set:function(a){this.Ha=a}},copiesTree:{get:function(){return this.ia},
set:function(a){this.ia=a}},deletesTree:{get:function(){return this.nb},set:function(a){this.nb=a}},copiesParentKey:{get:function(){return this.V},set:function(a){this.V=a}},copiesGroupKey:{get:function(){return this.F},set:function(a){this.F=a}},archetypeGroupData:{get:function(){return this.j},set:function(a){this.j=a}},memberValidation:{get:function(){return this.ij},
set:function(a){this.ij=a}},defaultScale:{get:function(){return this.diagram.defaultScale},set:function(a){this.diagram.defaultScale=a}},zoomFactor:{get:function(){return this.Ib},set:function(a){1<a||C("zoomFactor must be larger than 1.0, not: "+a);this.Ib=a}},isZoomToFitRestoreEnabled:{get:function(){return this.hb},set:function(a){this.hb=a}},scrollToPartPause:{get:function(){return this.ob},
set:function(a){this.ob=a}}});Mk.className="CommandHandler";Ai=function(){return new Mk};
function Y(){Qa(this);this.l=30723;this.pb=1;this.bn="";this.Ma=this.Me=null;this.pa=(new I(NaN,NaN)).freeze();this.jd=Lb;this.Bb=new ld;this.wh=new ld;this.Mb=null;this.Ca=this.Jk=1;this.Lb=0;this.Kg=jc;this.uc=(new N(NaN,NaN,NaN,NaN)).freeze();this.Jb=(new N(NaN,NaN,NaN,NaN)).freeze();this.ec=(new N(0,0,NaN,NaN)).freeze();this.ba=this.Yn=this.Zn=null;this.rk=this.Kb=Sc;this.Ag=this.io=0;this.V=this.tb=this.Dh=this.A=this.Ua=null;this.Uc=!1;this.jo=this.Ng=null}var nd,kg,mg,Qk,Rk,Sk,Tk,Uk,Vk,Wk;
Y.prototype.cloneProtected=function(a){a.l=this.l|6144;a.pb=this.pb;a.bn=this.bn;a.Ma=this.Ma;a.pa.assign(this.pa);a.jd=this.jd.J();null!==this.Mb?a.Mb=this.Mb.copy():a.Mb=null;a.Ca=this.Ca;a.Lb=this.Lb;a.Kg=this.Kg.J();a.uc.assign(this.uc);a.Jb.assign(this.Jb);a.ec.assign(this.ec);a.Yn=this.Yn;null!==this.ba&&(a.ba=this.ba.copy());a.Kb=this.Kb.J();a.rk=this.rk.J();a.io=this.io;a.Ag=this.Ag;null!==this.Ua&&(a.Ua=this.Ua.copy());null!==this.A&&(a.A=this.A.oc?this.A:this.A.copy());null!==this.Dh&&(a.Dh=
Ba(this.Dh));null!==this.tb&&(a.tb=this.tb.copy())};Y.prototype.Ww=function(a){var b=this.Dh;if(Aa(b))for(var c=0;c<b.length;c++){if(b[c]===a)return}else this.Dh=b=[];b.push(a)};Y.prototype.Af=function(a){a.Zn=null;a.Ng=null;a.v()};Y.prototype.clone=function(){var a=new this.constructor;this.cloneProtected(a);if(null!==this.Dh)for(var b=0;b<this.Dh.length;b++){var c=this.Dh[b];a[c]=this[c]}return a};Y.prototype.copy=function(){return this.clone()};t=Y.prototype;
t.qb=function(a){a.classType===T?0===a.name.indexOf("Orient")?this.segmentOrientation=a:C("Unknown Link enum value for GraphObject.segmentOrientation property: "+a):a.classType===Y&&(this.stretch=a)};t.toString=function(){return Ha(this.constructor)+"#"+bb(this)};t.th=function(){null!==this.A&&(this.A.oc=!0)};function Xk(a){null===a.A?a.A=new Yk:a.A.oc&&(a.A=a.A.copy())}t.Xc=function(){if(null===this.ba){var a=new Zk;a.Xi=lc;a.yj=lc;a.Vi=10;a.wj=10;a.Wi=0;a.xj=0;this.ba=a}};
t.cb=function(a,b,c,d,e,f,g){var h=this.part;if(null!==h&&(h.fk(a,b,c,d,e,f,g),c===this&&a===de&&$k(this)&&al(this,h,b),f=this.diagram,null===this.animationTriggers||null===f||!f.pi||f.undoManager.isUndoingRedoing||f.animationManager.Dm||(a=this.animationTriggers.get(b),null!==a&&f.animationManager.isEnabled&&!f.animationManager.isTicking&&(null===this.jo&&(this.jo=new ob),g=0===f.undoManager.transactionLevel,a.startCondition===ai?g=!0:a.startCondition===ci&&(g=!1),g?(f=new hh,bi(a,f),g=this.jo.get(a),
null!==g&&g.stop(),this.jo.add(a,f),f.vu=this,f.Ow=a,f.add(this,b,d,e),f.start()):(ih(f.animationManager,"Trigger"),f.animationManager.defaultAnimation.add(this,b,d,e)))),this instanceof W&&c===h&&0!==(h.l&16777216)&&null!==h.data))for(c=this.O.s,d=c.length,e=0;e<d;e++)h=c[e],h instanceof W&&Mj(h,function(a){null!==a.data&&0!==(a.l&16777216)&&a.La(b)})};
function al(a,b,c){var d=a.ih();if(null!==d)for(var e=a.localBindings.iterator;e.next();){var f=e.value,g=null;if(null!==f.sourceName){g=bl(f,d,a);if(null===g)continue;f.sp(a,g,c,null)}else if(f.isToModel){var h=b.diagram;null===h||h.skipsModelSourceBindings||f.sp(a,h.model.modelData,c,d)}else{h=d.data;if(null===h)continue;var k=b.diagram;null===k||k.skipsModelSourceBindings||f.sp(a,h,c,d)}g===a&&(h=d.ks(f.Ai),null!==h&&f.Nv(h,g,c))}}t.ks=function(a){return this.bindingId===a?this:null};
t.g=function(a,b,c){this.cb(de,a,this,b,c)};t.Ge=function(a,b,c,d){var e=this.uc;e.h(a,b,c,d);if(1!==this.Ca||0!==this.Lb){null===this.Mb&&(this.Mb=new ld);var f=this.Mb;f.reset();this.no(f,a,b,c,d);f.pp(e)}};function cl(a,b,c,d){if(!1===a.pickable)return!1;d.multiply(a.transform);return c?a.Yc(b,d):a.fh(b,d)}
t.ux=function(a,b,c){if(!1===this.pickable)return!1;var d=this.naturalBounds;b=a.Bf(b);return c?yb(a.x,a.y,0,0,0,d.height)<=b||yb(a.x,a.y,0,d.height,d.width,d.height)<=b||yb(a.x,a.y,d.width,d.height,d.width,0)<=b||yb(a.x,a.y,d.width,0,0,0)<=b:a.zd(0,0)<=b&&a.zd(0,d.height)<=b&&a.zd(d.width,0)<=b&&a.zd(d.width,d.height)<=b};t.se=function(){return!0};
t.ca=function(a){var b=I.alloc();b.assign(a);this.transform.Ga(b);var c=this.actualBounds;if(!c.w())return I.free(b),!1;var d=this.diagram;if(null!==d&&d.wq){var e=d.Ql("extraTouchThreshold"),f=d.Ql("extraTouchArea"),g=f/2,h=this.naturalBounds;d=this.Df()*d.scale;var k=1/d;if(h.width*d<e&&h.height*d<e)return a=Yb(c.x-g*k,c.y-g*k,c.width+f*k,c.height+f*k,b.x,b.y),I.free(b),a}e=!1;if(this instanceof pe||this instanceof Bf?Yb(c.x-5,c.y-5,c.width+10,c.height+10,b.x,b.y):c.ca(b))this.tb&&!this.tb.ca(b)?
e=!1:null!==this.areaBackground&&c.ca(b)?e=!0:null!==this.Ma&&this.ec.ca(a)?e=!0:e=this.gh(a);I.free(b);return e};t.gh=function(a){var b=this.naturalBounds;return Yb(0,0,b.width,b.height,a.x,a.y)};
t.Re=function(a){if(0===this.angle)return this.actualBounds.Re(a);var b=this.naturalBounds;b=N.allocAt(0,0,b.width,b.height);var c=this.transform,d=!1,e=I.allocAt(a.x,a.y);b.ca(c.Cd(e))&&(e.h(a.x,a.bottom),b.ca(c.Cd(e))&&(e.h(a.right,a.bottom),b.ca(c.Cd(e))&&(e.h(a.right,a.y),b.ca(c.Cd(e))&&(d=!0))));I.free(e);N.free(b);return d};
t.fh=function(a,b){if(void 0===b)return a.Re(this.actualBounds);var c=this.naturalBounds,d=!1,e=I.allocAt(0,0);a.ca(b.Ga(e))&&(e.h(0,c.height),a.ca(b.Ga(e))&&(e.h(c.width,c.height),a.ca(b.Ga(e))&&(e.h(c.width,0),a.ca(b.Ga(e))&&(d=!0))));I.free(e);return d};
t.Yc=function(a,b){if(void 0===b&&(b=this.transform,0===this.angle))return a.Yc(this.actualBounds);var c=this.naturalBounds,d=I.allocAt(0,0),e=I.allocAt(0,c.height),f=I.allocAt(c.width,c.height),g=I.allocAt(c.width,0),h=!1;if(a.ca(b.Ga(d))||a.ca(b.Ga(e))||a.ca(b.Ga(f))||a.ca(b.Ga(g)))h=!0;else{c=N.allocAt(0,0,c.width,c.height);var k=I.allocAt(a.x,a.y);c.ca(b.Cd(k))?h=!0:(k.h(a.x,a.bottom),c.ca(b.Cd(k))?h=!0:(k.h(a.right,a.bottom),c.ca(b.Cd(k))?h=!0:(k.h(a.right,a.y),c.ca(b.Cd(k))&&(h=!0))));I.free(k);
N.free(c);!h&&(K.us(a,d,e)||K.us(a,e,f)||K.us(a,f,g)||K.us(a,g,d))&&(h=!0)}I.free(d);I.free(e);I.free(f);I.free(g);return h};t.ga=function(a,b){void 0===b&&(b=new I);if(a instanceof O){var c=this.naturalBounds;b.h(a.x*c.width+a.offsetX,a.y*c.height+a.offsetY)}else b.set(a);this.oe.Ga(b);return b};
t.mi=function(a){void 0===a&&(a=new N);var b=this.naturalBounds,c=this.oe,d=I.allocAt(0,0).transform(c);a.h(d.x,d.y,0,0);d.h(b.width,0).transform(c);Xb(a,d.x,d.y,0,0);d.h(b.width,b.height).transform(c);Xb(a,d.x,d.y,0,0);d.h(0,b.height).transform(c);Xb(a,d.x,d.y,0,0);I.free(d);return a};t.li=function(){var a=this.oe;1===a.m11&&0===a.m12?a=0:(a=180*Math.atan2(a.m12,a.m11)/Math.PI,0>a&&(a+=360));return a};
t.Df=function(){if(0!==(this.l&4096)===!1)return this.Jk;var a=this.Ca;return null!==this.panel?a*this.panel.Df():a};t.qs=function(a,b){void 0===b&&(b=new I);b.assign(a);this.oe.Cd(b);return b};t.rs=function(a,b,c){return this.Rj(a.x,a.y,b.x,b.y,c)};
t.Rj=function(a,b,c,d,e){var f=this.transform,g=1/(f.m11*f.m22-f.m12*f.m21),h=f.m22*g,k=-f.m12*g,l=-f.m21*g,m=f.m11*g,n=g*(f.m21*f.dy-f.m22*f.dx),p=g*(f.m12*f.dx-f.m11*f.dy);if(null!==this.areaBackground)return f=this.actualBounds,K.Ff(f.left,f.top,f.right,f.bottom,a,b,c,d,e);g=a*h+b*l+n;a=a*k+b*m+p;b=c*h+d*l+n;c=c*k+d*m+p;e.h(0,0);d=this.naturalBounds;c=K.Ff(0,0,d.width,d.height,g,a,b,c,e);e.transform(f);return c};
Y.prototype.measure=function(a,b,c,d){if(!1!==zj(this)){var e=this.Kg,f=e.right+e.left;e=e.top+e.bottom;a=Math.max(a-f,0);b=Math.max(b-e,0);c=Math.max((c||0)-f,0);d=Math.max((d||0)-e,0);f=this.angle;e=this.desiredSize;var g=this.kq();90===f||270===f?(a=isFinite(e.height)?e.height+g:a,b=isFinite(e.width)?e.width+g:b):(a=isFinite(e.width)?e.width+g:a,b=isFinite(e.height)?e.height+g:b);e=c||0;g=d||0;var h=this instanceof W;switch(kl(this,!0)){case kg:g=e=0;h&&(b=a=Infinity);break;case nd:isFinite(a)&&
a>c&&(e=a);isFinite(b)&&b>d&&(g=b);break;case Qk:isFinite(a)&&a>c&&(e=a);g=0;h&&(b=Infinity);break;case Rk:isFinite(b)&&b>d&&(g=b),e=0,h&&(a=Infinity)}h=this.maxSize;var k=this.minSize;e>h.width&&k.width<h.width&&(e=h.width);g>h.height&&k.height<h.height&&(g=h.height);c=Math.max(e,k.width);d=Math.max(g,k.height);h.width<c&&(c=Math.min(k.width,c));h.height<d&&(d=Math.min(k.height,d));a=Math.min(h.width,a);b=Math.min(h.height,b);a=Math.max(c,a);b=Math.max(d,b);if(90===f||270===f)f=a,a=b,b=f,f=c,c=d,
d=f;this.uc.ka();this.Wl(a,b,c,d);this.uc.freeze();this.uc.w()||C("Non-real measuredBounds has been set. Object "+this+", measuredBounds: "+this.uc.toString());uj(this,!1)}};Y.prototype.Wl=function(){};Y.prototype.Dd=function(){return!1};
Y.prototype.arrange=function(a,b,c,d,e){this.Tk();var f=N.alloc();f.assign(this.Jb);this.Jb.ka();!1===Aj(this)?this.Jb.h(a,b,c,d):this.eh(a,b,c,d);this.Jb.freeze();void 0===e?this.tb=null:this.tb=e;c=!1;if(void 0!==e)c=!0;else if(e=this.panel,null!==e&&ol(e)&&(e=e.panel),null!==e&&(e=e.ec,d=this.measuredBounds,null!==this.areaBackground&&(d=this.Jb),c=b+d.height,d=a+d.width,c=!(0<=a+.05&&d<=e.width+.05&&0<=b+.05&&c<=e.height+.05),this instanceof Ng&&(a=this.naturalBounds,this.Lq>a.height||this.Db>
a.width)))c=!0;this.l=c?this.l|256:this.l&-257;this.Jb.w()||C("Non-real actualBounds has been set. Object "+this+", actualBounds: "+this.Jb.toString());this.Xl(f,this.Jb);pl(this,!1);N.free(f)};t=Y.prototype;t.eh=function(){};
function ql(a,b,c,d,e){a.Jb.h(b,c,d,e);if(!a.desiredSize.w()){var f=a.uc;c=a.Kg;b=c.right+c.left;var g=c.top+c.bottom;c=f.width+b;f=f.height+g;d+=b;e+=g;b=kl(a,!0);c===d&&f===e&&(b=kg);switch(b){case kg:if(c>d||f>e)uj(a,!0),a.measure(c>d?d:c,f>e?e:f,0,0);break;case nd:uj(a,!0);a.measure(d,e,0,0);break;case Qk:uj(a,!0);a.measure(d,f,0,0);break;case Rk:uj(a,!0),a.measure(c,e,0,0)}}}
t.Xl=function(a,b){var c=this.part;null!==c&&null!==c.diagram&&(c.selectionObject!==this&&c.resizeObject!==this&&c.rotateObject!==this||rl(c,!0),this.N(),Sb(a,b)||(c.oh(),this.Pn(c)))};t.Pn=function(a){null!==this.portId&&(rl(a,!0),a instanceof V&&sl(a,this))};
t.hc=function(a,b){if(!tl(this,a,b)&&this.visible){var c=this.opacity;if(0!==c){var d=1;1!==c&&(d=a.globalAlpha,a.globalAlpha=d*c);var e="";""!==this.filter&&(e=a.filter,a.filter=this.filter);var f=this.part,g=this.panel,h=!1,k=0;if(f&&b.Gf("drawShadows")&&(h=f.isShadowed)){var l=f.shadowOffset;k=Math.max(l.y,l.x)*b.scale*b.Nb}l=this.Dd();var m=this instanceof W&&ol(this),n=this.Jb;if(m){ul(this,h,a,g);var p=this.Jb,q=this.ec,r=this.areaBackground,u=this.Ma;null===r&&null===u||a.yc();null!==r&&(ji(this,
a,r,!0,!0,q,p),r instanceof vl&&r.type===wl?(a.beginPath(),a.rect(p.x,p.y,p.width,p.height),a.ng(r)):a.fillRect(p.x,p.y,p.width,p.height));null!==u&&(ji(this,a,u,!0,!1,q,p),u instanceof vl&&u.type===wl?(a.beginPath(),a.rect(p.x,p.y,p.width,p.height),a.ng(u)):a.fillRect(p.x,p.y,p.width,p.height));this.hh(a,b);h&&0!==(this.l&512)===!0&&xl(a)}if(!m&&(m||0!==n.width&&0!==n.height&&!isNaN(n.x)&&!isNaN(n.y))){m=this.transform;0!==(this.l&4096)===!0&&yl(this);if(!(p=b.$i||!l)){var w=this.naturalBounds;p=
this.wh;var v=p.m11,y=p.m21,z=p.dx,A=p.m12,B=p.m22,E=p.dy,L,R=L=0;p=L*v+R*y+z;q=L*A+R*B+E;L=w.width+k;R=0;var S=L*v+R*y+z;L=L*A+R*B+E;R=Math.min(p,S);var J=Math.min(q,L);r=Math.max(p+0,S)-R;u=Math.max(q+0,L)-J;p=R;q=J;L=w.width+k;R=w.height+k;S=L*v+R*y+z;L=L*A+R*B+E;R=Math.min(p,S);J=Math.min(q,L);r=Math.max(p+r,S)-R;u=Math.max(q+u,L)-J;p=R;q=J;L=0;R=w.height+k;S=L*v+R*y+z;L=L*A+R*B+E;R=Math.min(p,S);J=Math.min(q,L);r=Math.max(p+r,S)-R;u=Math.max(q+u,L)-J;p=R;q=J;k=b.viewportBounds;w=k.x;v=k.y;p=
!(p>k.width+w||w>r+p||q>k.height+v||v>u+q)}if(p){k=0!==(this.l&256);a.clipInsteadOfFill&&(k=!1);this instanceof Ng&&(a.font=this.font);if(k){p=g.se()?g.naturalBounds:g.actualBounds;q=Math.max(n.x,p.x);r=Math.max(n.y,p.y);u=Math.min(n.right,p.right)-q;w=Math.min(n.bottom,p.bottom)-r;v=this.tb;null!==v&&(q=v.x,r=v.y,u=v.width,w=v.height);if(q>n.width+n.x||n.x>p.width+p.x){1!==c&&(a.globalAlpha=d);""!==this.filter&&(a.filter=e);return}a.save();a.beginPath();a.rect(q,r,u,w);a.clip()}if(l){if(!f.isVisible()){1!==
c&&(a.globalAlpha=d);""!==this.filter&&(a.filter=e);return}h&&(p=f.shadowOffset,a.Fv(p.x*b.scale*this.scale*b.Nb,p.y*b.scale*this.scale*b.Nb,f.shadowBlur),xl(a),a.shadowColor=f.shadowColor)}ul(this,h,a,g);f=this.naturalBounds;null!==this.areaBackground&&(a.yc(),zl(this,a,f,n));a.transform(m.m11,m.m12,m.m21,m.m22,m.dx,m.dy);null!==this.Ma&&(a.yc(),Al(this,a,f,n));this.hh(a,b);(b=h)&&0!==(this.l&512)===!0&&xl(a);l&&b&&Bl(a);h&&0!==(this.l&512)===!0&&xl(a);l&&h&&Bl(a);k&&(a.restore(),a.Vc(this instanceof
W));m.ws()||(h=1/(m.m11*m.m22-m.m12*m.m21),a.transform(m.m22*h,-m.m12*h,-m.m21*h,m.m11*h,h*(m.m21*m.dy-m.m22*m.dx),h*(m.m12*m.dx-m.m11*m.dy)))}}1!==c&&(a.globalAlpha=d);""!==this.filter&&(a.filter=e)}}};function ul(a,b,c,d){b&&!0===a.shadowVisible?xl(c):b&&!1===a.shadowVisible&&Bl(c);b&&Cl(a,d)&&null===a.shadowVisible&&Bl(c);b&&(null!==a.Ma||Cl(a,d))?(Dl(a,!0),null===a.shadowVisible&&Bl(c)):Dl(a,!1)}
function Cl(a,b){if(null===b)return!1;ol(b)&&(b=b.panel);return 0!==(b.l&512)||(b.type===W.Auto||b.type===W.Spot)&&b.Qb()!==a}t.Ev=function(){};function zl(a,b,c,d){ji(a,b,a.areaBackground,!0,!0,c,d);a.areaBackground instanceof vl&&a.areaBackground.type===wl?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),b.ng(a.areaBackground)):b.fillRect(d.x,d.y,d.width,d.height)}
function Al(a,b,c,d){var e=a.kq(),f=c.x,g=c.y,h=c.width+e,k=c.height+e;ji(a,b,a.Ma,!0,!1,c,d);a.Ma instanceof vl&&a.Ma.type===wl?(b.beginPath(),b.rect(f-e/2,g-e/2,h,k),b.ng(a.Ma)):b.fillRect(f-e/2,g-e/2,h,k)}
function El(a,b,c){for(var d=b.O.s,e=d.length,f=-1,g=0;g<e;g++)if(d[g]===a){f=g;break}a=f;if(-1!==a)if(0===a)b.svg.gd?b.svg.gd.after(c):b.svg.Ma?b.svg.Ma.after(c):b.type===W.Table?(b=b.svg.getElementsByClassName("gojs-ts"),b=0!==b.length?b[0]:null,null!==b&&b.after(c)):b.svg.prepend(c);else{for(;0!==a&&!b.M(a-1).Ed();)a--;0===a?b.svg.prepend(c):(a=b.M(a-1).svg,null!==a?a.after(c):b.svg.appendChild(c))}}
function tl(a,b,c){if(!(b instanceof ii))return!1;if(!a.visible||0===a.opacity||a.Dd()&&!a.isVisible())return null!==a.svg&&null!==a.svg.parentNode&&a.svg.remove(),!0;var d=a.panel,e=a.mk(b);if(a.jk(b,c,e)){Fl(a,a.svg);if(null!==a.svg.parentElement)return a.Dd()&&(b.ti=a.svg),!0;a.Dd()?(null!==b.ti?b.ti.after(a.svg):b.Mj.prepend(a.svg),b.ti=a.svg):d&&El(a,d,a.svg);return!0}if(a.Dd()&&a.isShadowed){var f=a.shadowOffset;b.Fv(f.x*c.scale*a.scale*c.Nb,f.y*c.scale*a.scale*c.Nb,a.shadowBlur);xl(b);b.shadowColor=
a.shadowColor}a instanceof Ng&&(b.font=a.font);b.yd=null;var g=a.transform;f=a instanceof W;var h=a instanceof W?ol(a):!1;0!==(a.l&4096)===!0&&yl(a);var k=a.Jb;b.rb.ha=[1,0,0,1,0,0];b.o=!1;e&&(b.o=!0,f&&h?(Gl(b,1,0,0,1,0,0),a.svg=b.ad):f?(Gl(b,g.m11,g.m12,g.m21,g.m22,g.dx,g.dy),a.svg=b.ad):Gl(b,1,0,0,1,0,0));e=a.naturalBounds;if(null!==a.areaBackground){var l=g.copy().Uj();b.rb.ha=[l.m11,l.m12,l.m21,l.m22,l.dx,l.dy];zl(a,b,e,k);b.rb.ha=[1,0,0,1,0,0];b.yd.gd=b.ad;b.yd.prepend(b.ad)}f||(b.rb.ha=[g.m11,
g.m12,g.m21,g.m22,g.dx,g.dy]);null!==a.background&&(f?(h&&(b.rb.ha=[g.m11,g.m12,g.m21,g.m22,g.dx,g.dy]),Al(a,b,e,k),h&&(b.rb.ha=[1,0,0,1,0,0])):(b.rb.ha=[g.m11,g.m12,g.m21,g.m22,g.dx,g.dy],Al(a,b,e,k)),b.yd.Ma=b.ad,b.yd.prepend(b.ad));g=b.yd;e=a.part;h=!1;e&&c.Gf("drawShadows")&&(h=e.isShadowed);b.clipInsteadOfFill&&(h=!1);a.Dd()&&h&&xl(b);h&&!0===a.shadowVisible?xl(b):h&&!1===a.shadowVisible&&Bl(b);e=a.panel;h&&Cl(a,e)&&null===a.shadowVisible&&Bl(b);b.Il&&(l=c.computePixelRatio(),l="drop-shadow("+
b.shadowOffsetX/l+"px "+b.shadowOffsetY/l+"px "+b.shadowBlur/l/2+"px "+b.shadowColor+")",null!==a.background&&g.Ma.setAttributeNS(null,"filter",l),null!==a.areaBackground&&g.gd.setAttributeNS(null,"filter",l));h&&(null!==a.Ma||null!==a.areaBackground||Cl(a,e))?(Dl(a,!0),null===a.shadowVisible&&Bl(b)):Dl(a,!1);e=h;a.hh(b,c);f&&b.nh.pop();b.o&&b.nh.pop();g=b.Hv.hs;h=f?a.svg:b.yd;l=a.svg;if(!f&&null!==l&&h!==l&&l.parentNode===a.panel.svg)return a.svg.replaceWith(h),a.svg=h,null!==h&&a.$o(b,d,k,h),Hl(b,
l),!0;a.svg=h;null!==g&&null===h&&(Il(b),b.nh.pop());if(null===h)return!0;a.$o(b,d,k,h);b.Il&&(c=c.computePixelRatio(),a.Ev(h,"drop-shadow("+b.shadowOffsetX/c+"px "+b.shadowOffsetY/c+"px "+b.shadowBlur/c/2+"px "+b.shadowColor+")"));e&&0!==(a.l&512)===!0&&xl(b);a.Dd()&&Bl(b);c=a.Dd()?a.opacity*b.globalAlpha:a.opacity;1!==c&&h.setAttributeNS(null,"opacity",c.toString());""!==a.filter&&h.setAttributeNS(null,"filter",a.filter);a.Dd()?(b.ti?b.ti.after(h):b.Mj.prepend(h),b.ti=h):null!==d&&El(a,d,h);null!==
g&&g(a,h);Fl(a,h);return!0}
function Fl(a,b){b=b.dataset;a instanceof Y&&(null!==a.portId&&(b.portId=a.portId),a instanceof W&&(isNaN(a.itemIndex)||(b.itemIndex=a.itemIndex.toString()),a instanceof U&&(b.className=Ha(a),void 0!==a.key&&(b.key=a.key.toString()),a instanceof T&&(null!==a.fromNode&&void 0!==a.fromNode.key&&(b.fromKey=a.fromNode.key.toString()),""!==a.fromPortId&&(b.fromPortId=a.fromPortId),null!==a.toNode&&void 0!==a.toNode.key&&(b.toKey=a.toNode.key.toString()),""!==a.toPortId&&(b.toPortId=a.toPortId)))))}
t.$o=function(a,b,c,d){var e=0!==(this.l&256);a.clipInsteadOfFill&&(e=!1);if(e){var f=b.se()?b.naturalBounds:b.actualBounds;b=Math.max(c.x,f.x);e=Math.max(c.y,f.y);var g=Math.min(c.right,f.right)-b;c=Math.min(c.bottom,f.bottom)-e;f=this.tb;null!==f&&(b=f.x,e=f.y,g=f.width,c=f.height);f=d.getAttribute("clip-path");var h=null;null!==f&&(h=a.svg.getElementById(f.split("#")[1].slice(0,-1)));null===h?(f="CLIP"+ib++,h=a.Ud("clipPath",{id:f}),b=a.Ud("rect",{x:b,y:e,width:g,height:c}),this instanceof W&&
(e=this.transform.copy().Uj(),b.setAttribute("transform","matrix("+e.m11+", "+e.m12+", "+e.m21+", "+e.m22+", "+e.dx+", "+e.dy+")")),h.appendChild(b),a.svg.appendChild(h),d.setAttributeNS(null,"clip-path","url(#"+f+")")):(a=h.firstElementChild,a.setAttribute("x",b.toString()),a.setAttribute("y",e.toString()),a.setAttribute("width",g.toString()),a.setAttribute("height",c.toString()))}else d.removeAttributeNS(null,"clip-path"),Hl(a,d)};
function Hl(a,b){b=b.getAttribute("clip-path");null!==b&&(a=a.svg.getElementById(b.split("#")[1].slice(0,-1)),null!==a&&a.remove())}function ei(a){if(null!==a.svg){var b=a.svg.innerHTML.match(/url\(#((CLIP|GRAD).+?)\)/g),c=a.svg.ownerDocument;if(null!==b){b=ba(b);for(var d=b.next();!d.done;d=b.next())d=c.getElementById(d.value.split("#")[1].slice(0,-1)),null!==d&&d.remove()}a.svg.remove()}}t.hh=function(){};t.mv=function(){this.Uc=!0};
t.jk=function(a,b,c){if(this.Uc)return this.mv(),this.Uc=!1;b=this.svg;if(null===b)return!1;if(!this.visible)return b.remove(),!1;if("g"!==b.nodeName&&c||"g"===b.nodeName&&!c)return!1;var d=this.transform,e=this instanceof W?ol(this):!1;if(!(this instanceof W&&e))if(this instanceof ik){var f=this.bt;a.rb.ha=[d.m11,d.m12,d.m21,d.m22,d.dx,d.dy];null!==f&&Jl(a,f[0],f[1],f[2],f[3],f[4],f[5],f[6],f[7]);c?b.lastElementChild.setAttributeNS(null,"transform",Kl(a)):b.setAttributeNS(null,"transform",Kl(a))}else if(!c||
this instanceof W)b.setAttributeNS(null,"transform","matrix("+d.m11+", "+d.m12+", "+d.m21+", "+d.m22+", "+d.dx+", "+d.dy+")");else if(this instanceof Bf){f=b.getElementsByTagName("path");for(var g=0;g<f.length;g++)f[g].setAttributeNS(null,"transform","matrix("+d.m11+", "+d.m12+", "+d.m21+", "+d.m22+", "+d.dx+", "+d.dy+")")}else(b.lastElementChild||b).setAttributeNS(null,"transform","matrix("+d.m11+", "+d.m12+", "+d.m21+", "+d.m22+", "+d.dx+", "+d.dy+")");f=this.Dd()?this.opacity*a.globalAlpha:this.opacity;
1===f?b.removeAttribute("opacity"):b.setAttributeNS(null,"opacity",f.toString());a.rb.ha=[1,0,0,1,0,0];if(null!==this.areaBackground||void 0!==b.gd)f=b.gd,null===this.areaBackground?(b.gd.remove(),b.gd=void 0):(g=d.copy().Uj(),a.rb.ha=[g.m11,g.m12,g.m21,g.m22,g.dx,g.dy],zl(this,a,this.naturalBounds,this.actualBounds),a.rb.ha=[1,0,0,1,0,0],b.gd?(g=b.gd.getAttribute("filter"),null!==g&&a.ad.setAttribute("filter",g),b.gd.replaceWith(a.ad)):b.prepend(a.ad),b.gd=a.ad),f&&Ll(a,f);if(null!==this.background||
void 0!==b.Ma){f=b.Ma;if(null===this.background)Ll(a,b.Ma),b.Ma.remove(),b.Ma=void 0;else{if(e||c&&!(this instanceof W))a.rb.ha=[d.m11,d.m12,d.m21,d.m22,d.dx,d.dy];Al(this,a,this.naturalBounds,this.actualBounds);b.Ma?(Ll(a,b.Ma),c=b.Ma.getAttribute("filter"),null!==c&&a.ad.setAttribute("filter",c),b.Ma.replaceWith(a.ad)):b.prepend(a.ad);b.Ma=a.ad}f&&Ll(a,f)}this.$o(a,this.panel,this.actualBounds,b);return!0};
t.mk=function(a){if(null!==this.background||null!==this.areaBackground)return!0;var b=0!==(this.l&256);a.clipInsteadOfFill&&(b=!1);return b?!0:!1};
function ji(a,b,c,d,e,f,g){if(null!==c){var h=1,k=1;if("string"===typeof c)d?b.fillStyle=c:b.strokeStyle=c;else if(c.type===Ml)d?b.fillStyle=c.color:b.strokeStyle=c.color;else{var l=a.kq();h=f.width;k=f.height;e?(h=g.width,k=g.height):d||(h+=l,k+=l);if((f=b instanceof Nl)&&c.xe&&(c.type===Ol||c.xk===h&&c.ft===k))var m=c.xe;else{var n=0,p=0,q=0,r=0,u=0,w=0;w=u=0;e?(u=g.x,w=g.y):d||(u-=l/2,w-=l/2);n=c.start.x*h+c.start.offsetX;p=c.start.y*k+c.start.offsetY;q=c.end.x*h+c.end.offsetX;r=c.end.y*k+c.end.offsetY;
n+=u;q+=u;p+=w;r+=w;if(c.type===Pl)m=b.createLinearGradient(n,p,q,r);else if(c.type===wl)w=isNaN(c.endRadius)?Math.max(h,k)/2:c.endRadius,isNaN(c.startRadius)?(u=0,w=Math.max(h,k)/2):u=c.startRadius,m=b.createRadialGradient(n,p,u,q,r,w);else if(c.type===Ol)try{m=b.createPattern(c.pattern,"repeat")}catch(y){m=null}if(c.type!==Ol&&(e=c.colorStops,null!==e))for(e=e.iterator;e.next();)m.addColorStop(e.key,e.value);if(f&&(c.xe=m,null!==m&&(c.xk=h,c.ft=k),null===m&&c.type===Ol&&-1!==c.xk)){c.xk=-1;var v=
a.diagram;null!==v&&-1===c.xk&&ta(function(){v.redraw()},600)}}d?b.fillStyle=m:b.strokeStyle=m}}}t.rg=function(a){if(a instanceof W)a:{if(this!==a&&null!==a)for(var b=this.panel;null!==b;){if(b===a){a=!0;break a}b=b.panel}a=!1}else a=!1;return a};t.Ed=function(){if(!this.visible)return!1;var a=this.panel;return null!==a?a.Ed():!0};t.If=function(){for(var a=this instanceof W?this:this.panel;null!==a&&a.isEnabled;)a=a.panel;return null===a};
function yl(a){if(0!==(a.l&2048)===!0){var b=a.Bb;b.reset();if(!a.Jb.w()||!a.uc.w()){Ql(a,!1);return}b.translate(a.Jb.x-a.uc.x,a.Jb.y-a.uc.y);if(1!==a.scale||0!==a.angle){var c=a.naturalBounds;a.no(b,c.x,c.y,c.width,c.height)}Ql(a,!1);Rl(a,!0)}0!==(a.l&4096)===!0&&(b=a.panel,null===b?(a.wh.set(a.Bb),a.Jk=a.scale,Rl(a,!1)):null!==b.oe&&(c=a.wh,c.reset(),b.se()?c.multiply(b.wh):null!==b.panel&&c.multiply(b.panel.wh),c.multiply(a.Bb),a.Jk=a.scale*b.Jk,Rl(a,!1)))}
t.no=function(a,b,c,d,e){1!==this.Ca&&a.scale(this.Ca);if(0!==this.Lb){var f=I.alloc();f.yi(b,c,d,e,wc);a.rotate(this.Lb,f.x,f.y);I.free(f)}};t.v=function(a){if(!zj(this)){void 0===a&&(a=!1);uj(this,!0);pl(this,!0);var b=this.panel;null===b||a||b.v()}};t.Tj=function(){zj(this)||(uj(this,!0),pl(this,!0))};function Sl(a){if(!Aj(a)){var b=a.panel;null!==b?b.v():a.Dd()&&(b=a.diagram,null!==b&&(b.De.add(a),a instanceof V&&a.Bd(),b.Rb()));pl(a,!0)}}
t.Tk=function(){0!==(this.l&2048)===!1&&(Ql(this,!0),Rl(this,!0))};t.Xu=function(){Rl(this,!0)};t.N=function(){var a=this.part;null!==a&&a.N()};function kl(a,b){var c=a.stretch,d=a.panel;if(null!==d&&d.type===W.Table)return Tl(a,d.Gb(a.row),d.Fb(a.column),b);if(null!==d&&d.type===W.Auto&&d.Qb()===a)return Ul(a,nd,b);if(c===Sk){if(null!==d){if(d.type===W.Spot&&d.Qb()===a)return Ul(a,nd,b);c=d.defaultStretch;return c===Sk?Ul(a,kg,b):Ul(a,c,b)}return Ul(a,kg,b)}return Ul(a,c,b)}
function Tl(a,b,c,d){var e=a.stretch;if(e!==Sk)return Ul(a,e,d);var f=e=null;switch(b.stretch){case Rk:f=!0;break;case nd:f=!0}switch(c.stretch){case Qk:e=!0;break;case nd:e=!0}b=a.panel.defaultStretch;null===e&&(e=b===Qk||b===nd);null===f&&(f=b===Rk||b===nd);return!0===e&&!0===f?Ul(a,nd,d):!0===e?Ul(a,Qk,d):!0===f?Ul(a,Rk,d):Ul(a,kg,d)}
function Ul(a,b,c){if(c)return b;if(b===kg)return kg;c=a.desiredSize;if(c.w())return kg;a=a.angle;if(!isNaN(c.width))if(90!==a&&270!==a){if(b===Qk)return kg;if(b===nd)return Rk}else{if(b===Rk)return kg;if(b===nd)return Qk}if(!isNaN(c.height))if(90!==a&&270!==a){if(b===Rk)return kg;if(b===nd)return Qk}else{if(b===Qk)return kg;if(b===nd)return Rk}return b}function Dl(a,b){a.l=b?a.l|512:a.l&-513}function $k(a){return 0!==(a.l&1024)}function Vl(a,b){a.l=b?a.l|1024:a.l&-1025}
function Ql(a,b){a.l=b?a.l|2048:a.l&-2049}function Rl(a,b){a.l=b?a.l|4096:a.l&-4097}function zj(a){return 0!==(a.l&8192)}function uj(a,b){a.l=b?a.l|8192:a.l&-8193}function Aj(a){return 0!==(a.l&16384)}function pl(a,b){a.l=b?a.l|16384:a.l&-16385}t.Fj=function(){this.svg=null};t.wi=function(a){this.Me=a;null!==this.svg&&this.svg.remove()};t.Bv=function(){};t.Av=function(a){this.pa=a;Sl(this);return!0};t.mm=function(a,b){if(this.pa.x!==a||this.pa.y!==b)this.pa.h(a,b),this.Tk()};t.kq=function(){return 0};
function Wl(a){var b=a.part;if(b instanceof V&&(null!==a.portId||a===b.port)){var c=b.diagram;null===c||c.undoManager.isUndoingRedoing||sl(b,a)}}function Xl(a){var b=a.diagram;null===b||b.undoManager.isUndoingRedoing||(a instanceof W?a instanceof V?a.Bd():a.qm(a,function(a){Wl(a)}):Wl(a))}
t.bind=function(a,b,c,d){var e=null;"string"===typeof a?e=new zi(a,b,c,d):e=a;e.xd=this;a=this.ih();null!==a&&Yl(a)&&C("Cannot add a Binding to a template that has already been copied: "+e);null===this.localBindings&&(this.localBindings=new F);this.localBindings.add(e);return this};t.set=function(a){Object.assign(this,a);return this};t.Eu=function(a){Zl(this,a);return this};t.apply=function(a){a(this);return this};t.nm=function(a){Hj(this,a);return this};t.qz=function(){return this.ih()};
t.ih=function(){for(var a=this instanceof W?this:this.panel;null!==a;){if(null!==a.allBindings)return a;a=a.panel}return null};t.Kv=function(a){a.xd=this;null===this.animationTriggers&&(this.animationTriggers=new ob);this.animationTriggers.add(a.propertyName,a);return this};
function $l(a,b){for(var c=1;c<arguments.length;++c);c=arguments;var d=null,e=null;if("function"===typeof a)e=a;else if("string"===typeof a){var f=am.I(a);"function"===typeof f?(c=Ba(arguments),d=f(c),za(d)||C('GraphObject.make invoked object builder "'+a+'", but it did not return an Object')):e=x.go[a]}null===d&&(void 0!==e&&null!==e&&e.constructor||C("GraphObject.make requires a class function or GoJS class name or name of an object builder, not: "+a),d=new e);e=1;if(d instanceof Q&&1<c.length){f=
d;var g=c[1];if("string"===typeof g||g instanceof HTMLDivElement)vi(f,g),e++}for(;e<c.length;e++)f=c[e],void 0===f?C("Undefined value at argument "+e+" for object being constructed by GraphObject.make: "+d):Zl(d,f);return d}
function Zl(a,b){if("string"===typeof b)if(a instanceof Ng)a.text=b;else if(a instanceof Bf)a.figure=b;else if(a instanceof ik)a.source=b;else if(a instanceof W)b=bm.I(b),null!==b&&(a.type=b);else if(a instanceof vl){var c=Ra(vl,b);null!==c?a.type=c:C("Unknown Brush type as an argument to GraphObject.make: "+b)}else a instanceof P?(b=Ra(P,b),null!==b&&(a.type=b)):a instanceof Rd&&(b=Ra(Rd,b),null!==b&&(a.type=b));else if(b instanceof Y)a instanceof W||C("A GraphObject can only be added to a Panel, not to: "+
a),a.add(b);else if(b instanceof ak){var d;b.isRow&&"function"===typeof a.getRowDefinition?d=a.getRowDefinition(b.index):b.isRow||"function"!==typeof a.getColumnDefinition||(d=a.getColumnDefinition(b.index));d instanceof ak?cm(d,b):C("A RowColumnDefinition can only be added to an object that implements getRowDefinition/getColumnDefinition, not to: "+a)}else if(b instanceof D)"function"===typeof a.qb&&a.qb(b);else if(b instanceof dm)a.type=b;else if(b instanceof zi)a instanceof Y?a.bind(b):a instanceof
ak?a.bind(b):C("A Binding can only be applied to a GraphObject or RowColumnDefinition, not to: "+a);else if(b instanceof Zh)a instanceof Y?a.Kv(b):C("An AnimationTrigger can only be applied to a GraphObject, not to: "+a);else if(b instanceof Qd)a instanceof P&&a.type===P.j?a.figures.add(b):C("A PathFigure can only be added to a Path Geometry, not to: "+a);else if(b instanceof Rd)a instanceof Qd?a.segments.add(b):C("A PathSegment can only be added to a PathFigure, not to: "+a);else if(b instanceof
ti)a instanceof Q?a.layout=b:a instanceof jf?a.layout=b:C("A Layout can only be assigned to a Diagram or a Group, not to: "+a);else if(Array.isArray(b))for(c=0;c<b.length;c++)Zl(a,b[c]);else if("object"===typeof b&&null!==b)if(a instanceof vl){c=new Ua;for(var e in b)d=parseFloat(e),isNaN(d)?c[e]=b[e]:a.addColorStop(d,b[e]);Hj(a,c)}else if(a instanceof ak){void 0!==b.row?(e=b.row,(void 0===e||null===e||Infinity===e||isNaN(e)||0>e)&&C("Must specify non-negative integer row for RowColumnDefinition "+
b+", not: "+e),a.isRow=!0,a.index=e):void 0!==b.column&&(e=b.column,(void 0===e||null===e||Infinity===e||isNaN(e)||0>e)&&C("Must specify non-negative integer column for RowColumnDefinition "+b+", not: "+e),a.isRow=!1,a.index=e);e=new Ua;for(c in b)"row"!==c&&"column"!==c&&(e[c]=b[c]);Hj(a,e)}else Hj(a,b);else C('Unknown initializer "'+b+'" for object being constructed by GraphObject.make: '+a)}function em(a,b){am.add(a,b)}
function fm(a,b,c){void 0===c&&(c=null);var d=a[1];if("function"===typeof c?c(d):"string"===typeof d)return a.splice(1,1),d;if(void 0===b)throw Error("no "+("function"===typeof c?"satisfactory":"string")+" argument for GraphObject builder "+a[0]);return b}
ma.Object.defineProperties(Y.prototype,{bindingId:{get:function(){return null!==this.A?this.A.zp:-1},set:function(a){this.bindingId!==a&&(null===this.A&&(this.A=new Yk),this.A.zp=a)}},localBindings:{get:function(){return null!==this.A?this.A.te:null},set:function(a){this.localBindings!==a&&(null===this.A&&(this.A=new Yk),this.A.te=a)}},srcBindings:{get:function(){return null!==this.A?this.A.wm:null},set:function(a){this.srcBindings!==
a&&(null===this.A&&(this.A=new Yk),this.A.wm=a)}},shadowVisible:{get:function(){return null!==this.A?this.A.Ci:null},set:function(a){var b=this.shadowVisible;b!==a&&(Xk(this),this.A.Ci=a,this.Uc=!0,this.N(),this.g("shadowVisible",b,a))}},enabledChanged:{get:function(){return null!==this.A?this.A.Ib:null},set:function(a){var b=this.enabledChanged;b!==a&&(Xk(this),this.A.Ib=a,this.g("enabledChanged",b,a))}},segmentOrientation:{
get:function(){return null!==this.Ua?this.Ua.rr:Zf},set:function(a){var b=this.segmentOrientation;b!==a&&(null===this.Ua&&(this.Ua=new gm),this.Ua.rr=a,this.v(),this.g("segmentOrientation",b,a),a===Zf&&(this.angle=0))}},segmentIndex:{get:function(){return null!==this.Ua?this.Ua.u:-Infinity},set:function(a){a=Math.round(a);var b=this.segmentIndex;b!==a&&(null===this.Ua&&(this.Ua=new gm),this.Ua.u=a,this.v(),this.g("segmentIndex",b,a))}},segmentFraction:{
get:function(){return null!==this.Ua?this.Ua.o:0},set:function(a){isNaN(a)?a=0:0>a?a=0:1<a&&(a=1);var b=this.segmentFraction;b!==a&&(null===this.Ua&&(this.Ua=new gm),this.Ua.o=a,this.v(),this.g("segmentFraction",b,a))}},segmentOffset:{get:function(){return null!==this.Ua?this.Ua.F:pb},set:function(a){var b=this.segmentOffset;b.D(a)||(a=a.J(),null===this.Ua&&(this.Ua=new gm),this.Ua.F=a,this.v(),this.g("segmentOffset",b,a))}},stretch:{
get:function(){return null!==this.A?this.A.hg:Sk},set:function(a){var b=this.stretch;b!==a&&(Xk(this),this.A.hg=a,this.v(),this.g("stretch",b,a))}},name:{get:function(){return null!==this.A?this.A.xa:""},set:function(a){var b=this.name;b!==a&&(Xk(this),this.A.xa=a,null!==this.part&&(this.part.Rh=null),this.g("name",b,a))}},opacity:{get:function(){return this.pb},set:function(a){var b=this.pb;b!==a&&((0>a||1<a)&&xa(a,"0 <= value <= 1",Y,"opacity"),
this.pb=a,this.g("opacity",b,a),a=this.diagram,b=this.part,null!==a&&null!==b&&a.N(Lj(b,b.actualBounds)))}},filter:{get:function(){return this.bn},set:function(a){var b=this.bn;b!==a&&(this.bn=a,this.Uc=!0,this.g("filter",b,a),a=this.diagram,b=this.part,null!==a&&null!==b&&a.N(Lj(b,b.actualBounds)))}},visible:{get:function(){return 0!==(this.l&1)},set:function(a){var b=0!==(this.l&1);b!==a&&(this.l^=1,this.g("visible",b,a),b=this.panel,null!==
b?b.v():this.Dd()&&this.ac(a),this.N(),Xl(this))}},pickable:{get:function(){return 0!==(this.l&2)},set:function(a){var b=0!==(this.l&2);b!==a&&(this.l^=2,this.g("pickable",b,a))}},fromLinkableDuplicates:{get:function(){return 0!==(this.l&4)},set:function(a){var b=0!==(this.l&4);b!==a&&(this.l^=4,this.g("fromLinkableDuplicates",b,a))}},fromLinkableSelfNode:{get:function(){return 0!==(this.l&8)},set:function(a){var b=
0!==(this.l&8);b!==a&&(this.l^=8,this.g("fromLinkableSelfNode",b,a))}},toLinkableDuplicates:{get:function(){return 0!==(this.l&16)},set:function(a){var b=0!==(this.l&16);b!==a&&(this.l^=16,this.g("toLinkableDuplicates",b,a))}},toLinkableSelfNode:{get:function(){return 0!==(this.l&32)},set:function(a){var b=0!==(this.l&32);b!==a&&(this.l^=32,this.g("toLinkableSelfNode",b,a))}},isPanelMain:{get:function(){return 0!==
(this.l&64)},set:function(a){var b=0!==(this.l&64);b!==a&&(this.l^=64,this.v(),this.g("isPanelMain",b,a))}},isActionable:{get:function(){return 0!==(this.l&128)},set:function(a){var b=0!==(this.l&128);b!==a&&(this.l^=128,this.g("isActionable",b,a))}},areaBackground:{get:function(){return null!==this.A?this.A.gd:null},set:function(a){var b=this.areaBackground;b!==a&&(null!==a&&hm(a,"GraphObject.areaBackground"),a instanceof vl&&a.freeze(),
Xk(this),this.A.gd=a,this.N(),this.g("areaBackground",b,a))}},background:{get:function(){return this.Ma},set:function(a){var b=this.Ma;b!==a&&(null!==a&&hm(a,"GraphObject.background"),a instanceof vl&&a.freeze(),this.Ma=a,this.N(),this.g("background",b,a))}},part:{get:function(){if(this.Dd())return this;if(null!==this.Ng)return this.Ng;var a;for(a=this.panel;a;){if(a instanceof U)return this.Ng=a;a=a.panel}return null}},svg:{
get:function(){return this.V},set:function(a){this.V=a}},panel:{get:function(){return this.Me}},layer:{get:function(){var a=this.part;return null!==a?a.layer:null}},diagram:{get:function(){var a=this.part;return null!==a?a.diagram:null}},position:{get:function(){return this.pa},set:function(a){var b=a.x,c=a.y,d=this.pa,e=d.x;d=d.y;(e===b||isNaN(e)&&isNaN(b))&&(d===
c||isNaN(d)&&isNaN(c))?this.Bv():(a=a.copy(),this.Av(a,e,d)&&this.g("position",new I(e,d),a))}},actualBounds:{get:function(){return this.Jb}},scale:{get:function(){return this.Ca},set:function(a){var b=this.Ca;b!==a&&(0>=a&&C("GraphObject.scale for "+this+" must be greater than zero, not: "+a),this.Ca=a,this.v(),this.g("scale",b,a))}},angle:{get:function(){return this.Lb},set:function(a){var b=this.Lb;b!==a&&
(a%=360,0>a&&(a+=360),b!==a&&(this.Lb=a,Xl(this),this.v(),this.g("angle",b,a)))}},desiredSize:{get:function(){return this.jd},set:function(a){var b=a.width,c=a.height,d=this.jd,e=d.width,f=d.height;(e===b||isNaN(e)&&isNaN(b))&&(f===c||isNaN(f)&&isNaN(c))||(this.jd=a=a.J(),this.v(),this instanceof Bf&&this.rc(),this.g("desiredSize",d,a),$k(this)&&(a=this.part,null!==a&&(al(this,a,"width"),al(this,a,"height"))))}},width:{get:function(){return this.jd.width},
set:function(a){var b=this.jd.width;b===a||isNaN(b)&&isNaN(a)||(b=this.jd,this.jd=a=(new M(a,this.jd.height)).freeze(),this.v(),this instanceof Bf&&this.rc(),this.g("desiredSize",b,a),$k(this)&&(a=this.part,null!==a&&al(this,a,"width")))}},height:{get:function(){return this.jd.height},set:function(a){var b=this.jd.height;b===a||isNaN(b)&&isNaN(a)||(b=this.jd,this.jd=a=(new M(this.jd.width,a)).freeze(),this.v(),this instanceof Bf&&this.rc(),this.g("desiredSize",b,a),$k(this)&&
(a=this.part,null!==a&&al(this,a,"height")))}},minSize:{get:function(){return null!==this.A?this.A.u:Cb},set:function(a){var b=this.minSize;b.D(a)||(a=a.copy(),isNaN(a.width)&&(a.width=0),isNaN(a.height)&&(a.height=0),a.freeze(),Xk(this),this.A.u=a,this.v(),this.g("minSize",b,a))}},maxSize:{get:function(){return null!==this.A?this.A.o:Kb},set:function(a){var b=this.maxSize;b.D(a)||(a=a.copy(),isNaN(a.width)&&(a.width=Infinity),isNaN(a.height)&&
(a.height=Infinity),a.freeze(),Xk(this),this.A.o=a,this.v(),this.g("maxSize",b,a))}},measuredBounds:{get:function(){return this.uc}},naturalBounds:{get:function(){return this.ec}},margin:{get:function(){return this.Kg},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.Kg;b.D(a)||(this.Kg=a=a.J(),this.v(),this.g("margin",b,a))}},transform:{get:function(){0!==(this.l&2048)===
!0&&yl(this);return this.Bb}},oe:{get:function(){0!==(this.l&4096)===!0&&yl(this);return this.wh}},animationTriggers:{get:function(){return null!==this.A?this.A.hb:null},set:function(a){this.animationTriggers!==a&&(Xk(this),this.A.hb=a)}},reshapingBehavior:{get:function(){return null!==this.A?this.A.Pf:kg},set:function(a){this.reshapingBehavior!==a&&(Xk(this),this.A.Pf=a)}},alignment:{
get:function(){return this.Kb},set:function(a){var b=this.Kb;b.D(a)||(a.Mc()&&!a.Zb()&&C("GraphObject.alignment for "+this+" must be a real Spot or Spot.Default, not: "+a),this.Kb=a=a.J(),Sl(this),this.g("alignment",b,a))}},column:{get:function(){return this.Ag},set:function(a){a=Math.round(a);var b=this.Ag;b!==a&&(0>a&&xa(a,">= 0",Y,"column"),this.Ag=a,this.v(),this.g("column",b,a),null!==this.svg&&this.svg.remove())}},columnSpan:{get:function(){return null!==
this.A?this.A.ob:1},set:function(a){a=Math.round(a);var b=this.columnSpan;b!==a&&(1>a&&xa(a,">= 1",Y,"columnSpan"),Xk(this),this.A.ob=a,this.v(),this.g("columnSpan",b,a))}},row:{get:function(){return this.io},set:function(a){a=Math.round(a);var b=this.io;b!==a&&(0>a&&xa(a,">= 0",Y,"row"),this.io=a,this.v(),this.g("row",b,a),null!==this.svg&&this.svg.remove())}},rowSpan:{get:function(){return null!==this.A?this.A.Bi:1},set:function(a){a=Math.round(a);
var b=this.rowSpan;b!==a&&(1>a&&xa(a,">= 1",Y,"rowSpan"),Xk(this),this.A.Bi=a,this.v(),this.g("rowSpan",b,a))}},spanAllocation:{get:function(){return null!==this.A?this.A.Di:null},set:function(a){var b=this.spanAllocation;b!==a&&(Xk(this),this.A.Di=a,this.v(),this.g("spanAllocation",b,a))}},alignmentFocus:{get:function(){return this.rk},set:function(a){var b=this.rk;b.D(a)||(this.rk=a=a.J(),this.v(),this.g("alignmentFocus",b,a))}},portId:{
get:function(){return this.Yn},set:function(a){var b=this.Yn;if(b!==a){var c=this.part;null===c||c instanceof V||C("Cannot set portID on a Link: "+a);null!==b&&null!==c&&im(c,this);this.Yn=a;null!==a&&null!==c&&(c.Te=!0,jm(c,this));this.g("portId",b,a)}}},toSpot:{get:function(){return null!==this.ba?this.ba.yj:lc},set:function(a){var b=this.toSpot;b.D(a)||(a=a.J(),this.Xc(),this.ba.yj=a,this.g("toSpot",b,a),Wl(this))}},toEndSegmentLength:{
get:function(){return null!==this.ba?this.ba.wj:10},set:function(a){var b=this.toEndSegmentLength;b!==a&&(0>a&&xa(a,">= 0",Y,"toEndSegmentLength"),this.Xc(),this.ba.wj=a,this.g("toEndSegmentLength",b,a),Wl(this))}},toShortLength:{get:function(){return null!==this.ba?this.ba.xj:0},set:function(a){var b=this.toShortLength;b!==a&&(this.Xc(),this.ba.xj=a,this.g("toShortLength",b,a),Wl(this))}},toLinkable:{get:function(){return null!==
this.ba?this.ba.Tr:null},set:function(a){var b=this.toLinkable;b!==a&&(this.Xc(),this.ba.Tr=a,this.g("toLinkable",b,a))}},toMaxLinks:{get:function(){return null!==this.ba?this.ba.Ur:Infinity},set:function(a){var b=this.toMaxLinks;b!==a&&(0>a&&xa(a,">= 0",Y,"toMaxLinks"),this.Xc(),this.ba.Ur=a,this.g("toMaxLinks",b,a))}},fromSpot:{get:function(){return null!==this.ba?this.ba.Xi:lc},set:function(a){var b=this.fromSpot;b.D(a)||(a=a.J(),this.Xc(),
this.ba.Xi=a,this.g("fromSpot",b,a),Wl(this))}},fromEndSegmentLength:{get:function(){return null!==this.ba?this.ba.Vi:10},set:function(a){var b=this.fromEndSegmentLength;b!==a&&(0>a&&xa(a,">= 0",Y,"fromEndSegmentLength"),this.Xc(),this.ba.Vi=a,this.g("fromEndSegmentLength",b,a),Wl(this))}},fromShortLength:{get:function(){return null!==this.ba?this.ba.Wi:0},set:function(a){var b=this.fromShortLength;b!==a&&(this.Xc(),this.ba.Wi=a,this.g("fromShortLength",
b,a),Wl(this))}},fromLinkable:{get:function(){return null!==this.ba?this.ba.hq:null},set:function(a){var b=this.fromLinkable;b!==a&&(this.Xc(),this.ba.hq=a,this.g("fromLinkable",b,a))}},fromMaxLinks:{get:function(){return null!==this.ba?this.ba.iq:Infinity},set:function(a){var b=this.fromMaxLinks;b!==a&&(0>a&&xa(a,">= 0",Y,"fromMaxLinks"),this.Xc(),this.ba.iq=a,this.g("fromMaxLinks",b,a))}},cursor:{get:function(){return null!==
this.A?this.A.j:""},set:function(a){var b=this.cursor;b!==a&&(Xk(this),this.A.j=a,this.g("cursor",b,a))}},click:{get:function(){return null!==this.A?this.A.zh:null},set:function(a){var b=this.click;b!==a&&(Xk(this),this.A.zh=a,this.g("click",b,a))}},doubleClick:{get:function(){return null!==this.A?this.A.Fh:null},set:function(a){var b=this.doubleClick;b!==a&&(Xk(this),this.A.Fh=a,this.g("doubleClick",b,a))}},contextClick:{
get:function(){return null!==this.A?this.A.Bh:null},set:function(a){var b=this.contextClick;b!==a&&(Xk(this),this.A.Bh=a,this.g("contextClick",b,a))}},mouseEnter:{get:function(){return null!==this.A?this.A.Mh:null},set:function(a){var b=this.mouseEnter;b!==a&&(Xk(this),this.A.Mh=a,this.g("mouseEnter",b,a))}},mouseLeave:{get:function(){return null!==this.A?this.A.Ph:null},set:function(a){var b=this.mouseLeave;b!==a&&(Xk(this),
this.A.Ph=a,this.g("mouseLeave",b,a))}},mouseOver:{get:function(){return null!==this.A?this.A.Qh:null},set:function(a){var b=this.mouseOver;b!==a&&(Xk(this),this.A.Qh=a,this.g("mouseOver",b,a))}},mouseHover:{get:function(){return null!==this.A?this.A.Oh:null},set:function(a){var b=this.mouseHover;b!==a&&(Xk(this),this.A.Oh=a,this.g("mouseHover",b,a))}},mouseHold:{get:function(){return null!==this.A?this.A.Nh:
null},set:function(a){var b=this.mouseHold;b!==a&&(Xk(this),this.A.Nh=a,this.g("mouseHold",b,a))}},mouseDragEnter:{get:function(){return null!==this.A?this.A.Of:null},set:function(a){var b=this.mouseDragEnter;b!==a&&(Xk(this),this.A.Of=a,this.g("mouseDragEnter",b,a))}},mouseDragLeave:{get:function(){return null!==this.A?this.A.Xd:null},set:function(a){var b=this.mouseDragLeave;b!==a&&(Xk(this),this.A.Xd=a,this.g("mouseDragLeave",b,a))}},
mouseDrop:{get:function(){return null!==this.A?this.A.Lh:null},set:function(a){var b=this.mouseDrop;b!==a&&(Xk(this),this.A.Lh=a,this.g("mouseDrop",b,a))}},actionDown:{get:function(){return null!==this.A?this.A.V:null},set:function(a){var b=this.actionDown;b!==a&&(Xk(this),this.A.V=a,this.g("actionDown",b,a))}},actionMove:{get:function(){return null!==this.A?this.A.ia:null},set:function(a){var b=this.actionMove;
b!==a&&(Xk(this),this.A.ia=a,this.g("actionMove",b,a))}},actionUp:{get:function(){return null!==this.A?this.A.Ha:null},set:function(a){var b=this.actionUp;b!==a&&(Xk(this),this.A.Ha=a,this.g("actionUp",b,a))}},actionCancel:{get:function(){return null!==this.A?this.A.F:null},set:function(a){var b=this.actionCancel;b!==a&&(Xk(this),this.A.F=a,this.g("actionCancel",b,a))}},toolTip:{get:function(){return null!==
this.A?this.A.$h:null},set:function(a){var b=this.toolTip;b!==a&&(Xk(this),this.A.$h=a,this.g("toolTip",b,a))}},contextMenu:{get:function(){return null!==this.A?this.A.Ch:null},set:function(a){var b=this.contextMenu;b!==a&&(Xk(this),this.A.Ch=a,this.g("contextMenu",b,a))}}});Y.prototype.trigger=Y.prototype.Kv;Y.prototype.findBindingPanel=Y.prototype.ih;Y.prototype.findTemplateBinder=Y.prototype.qz;Y.prototype.setProperties=Y.prototype.nm;Y.prototype.apply=Y.prototype.apply;
Y.prototype.attach=Y.prototype.Eu;Y.prototype.set=Y.prototype.set;Y.prototype.isEnabledObject=Y.prototype.If;Y.prototype.isVisibleObject=Y.prototype.Ed;Y.prototype.isContainedBy=Y.prototype.rg;Y.prototype.getNearestIntersectionPoint=Y.prototype.rs;Y.prototype.getLocalPoint=Y.prototype.qs;Y.prototype.getDocumentScale=Y.prototype.Df;Y.prototype.getDocumentAngle=Y.prototype.li;Y.prototype.getDocumentBounds=Y.prototype.mi;Y.prototype.getDocumentPoint=Y.prototype.ga;Y.prototype.intersectsRect=Y.prototype.Yc;
Y.prototype.containedInRect=Y.prototype.fh;Y.prototype.containsRect=Y.prototype.Re;Y.prototype.containsPoint=Y.prototype.ca;Y.prototype.raiseChanged=Y.prototype.g;Y.prototype.raiseChangedEvent=Y.prototype.cb;Y.prototype.addCopyProperty=Y.prototype.Ww;var am=null;Y.className="GraphObject";am=new ob;
em("Button",function(){function a(a,b){return null!==a.diagram.jc(a.documentPoint,function(a){for(;null!==a.panel&&!a.isActionable;)a=a.panel;return a},function(a){return a===b})}var b=$l(W,W.Auto,{isActionable:!0,enabledChanged:function(a,b){if(a instanceof W){var c=a.fb("ButtonBorder");null!==c&&(c.fill=b?a._buttonFillNormal:a._buttonFillDisabled)}},cursor:"pointer",_buttonFillNormal:"#F5F5F5",_buttonStrokeNormal:"#BDBDBD",_buttonFillOver:"#E0E0E0",_buttonStrokeOver:"#9E9E9E",_buttonFillPressed:"#BDBDBD",
_buttonStrokePressed:"#9E9E9E",_buttonFillDisabled:"#E5E5E5"},$l(Bf,{name:"ButtonBorder",figure:"RoundedRectangle",spot1:new O(0,0,2.76142374915397,2.761423749153969),spot2:new O(1,1,-2.76142374915397,-2.761423749153969),parameter1:2,parameter2:2,fill:"#F5F5F5",stroke:"#BDBDBD"}));b.mouseEnter=function(a,b){if(b.If()&&b instanceof W&&(a=b.fb("ButtonBorder"),a instanceof Bf)){var c=b._buttonFillOver;b._buttonFillNormal=a.fill;a.fill=c;c=b._buttonStrokeOver;b._buttonStrokeNormal=a.stroke;a.stroke=c}};
b.mouseLeave=function(a,b){b.If()&&b instanceof W&&(a=b.fb("ButtonBorder"),a instanceof Bf&&(a.fill=b._buttonFillNormal,a.stroke=b._buttonStrokeNormal))};b.actionDown=function(a,b){if(b.If()&&b instanceof W&&null!==b._buttonFillPressed&&0===a.button){var c=b.fb("ButtonBorder");if(c instanceof Bf){a=a.diagram;var d=a.skipsUndoManager;a.skipsUndoManager=!0;var g=b._buttonFillPressed;b._buttonFillOver=c.fill;c.fill=g;g=b._buttonStrokePressed;b._buttonStrokeOver=c.stroke;c.stroke=g;a.skipsUndoManager=
d}}};b.actionUp=function(b,d){if(d.If()&&d instanceof W&&null!==d._buttonFillPressed&&0===b.button){var c=d.fb("ButtonBorder");if(c instanceof Bf){var f=b.diagram,g=f.skipsUndoManager;f.skipsUndoManager=!0;a(b,d)?(c.fill=d._buttonFillOver,c.stroke=d._buttonStrokeOver):(c.fill=d._buttonFillNormal,c.stroke=d._buttonStrokeNormal);f.skipsUndoManager=g}}};b.actionCancel=function(b,d){if(d.If()&&d instanceof W&&null!==d._buttonFillPressed){var c=d.fb("ButtonBorder");if(c instanceof Bf){var f=b.diagram,
g=f.skipsUndoManager;f.skipsUndoManager=!0;a(b,d)?(c.fill=d._buttonFillOver,c.stroke=d._buttonStrokeOver):(c.fill=d._buttonFillNormal,c.stroke=d._buttonStrokeNormal);f.skipsUndoManager=g}}};b.actionMove=function(b,d){if(d.If()&&d instanceof W&&null!==d._buttonFillPressed){var c=b.diagram;if(0===c.firstInput.button&&(c.currentTool.standardMouseOver(),a(b,d)&&(b=d.fb("ButtonBorder"),b instanceof Bf))){var f=c.skipsUndoManager;c.skipsUndoManager=!0;var g=d._buttonFillPressed;b.fill!==g&&(b.fill=g);g=
d._buttonStrokePressed;b.stroke!==g&&(b.stroke=g);c.skipsUndoManager=f}}};return b});
em("TreeExpanderButton",function(){var a=$l("Button",{_treeExpandedFigure:"MinusLine",_treeCollapsedFigure:"PlusLine"},$l(Bf,{name:"ButtonIcon",figure:"MinusLine",stroke:"#424242",strokeWidth:2,desiredSize:Ib},(new zi("figure","isTreeExpanded",function(a,c){c=c.panel;return a?c._treeExpandedFigure:c._treeCollapsedFigure})).cp()),{visible:!1},(new zi("visible","isTreeLeaf",function(a){return!a})).cp());a.click=function(a,c){c=c.part;c instanceof pe&&(c=c.adornedPart);if(c instanceof V){var b=c.diagram;
if(null!==b){b=b.commandHandler;if(c.isTreeExpanded){if(!b.canCollapseTree(c))return}else if(!b.canExpandTree(c))return;a.handled=!0;c.isTreeExpanded?b.collapseTree(c):b.expandTree(c)}}};return a});
em("SubGraphExpanderButton",function(){var a=$l("Button",{_subGraphExpandedFigure:"MinusLine",_subGraphCollapsedFigure:"PlusLine"},$l(Bf,{name:"ButtonIcon",figure:"MinusLine",stroke:"#424242",strokeWidth:2,desiredSize:Ib},(new zi("figure","isSubGraphExpanded",function(a,c){c=c.panel;return a?c._subGraphExpandedFigure:c._subGraphCollapsedFigure})).cp()));a.click=function(a,c){c=c.part;c instanceof pe&&(c=c.adornedPart);if(c instanceof jf){var b=c.diagram;if(null!==b){b=b.commandHandler;if(c.isSubGraphExpanded){if(!b.canCollapseSubGraph(c))return}else if(!b.canExpandSubGraph(c))return;
a.handled=!0;c.isSubGraphExpanded?b.collapseSubGraph(c):b.expandSubGraph(c)}}};return a});em("ToolTip",function(){return $l(pe,W.Auto,{isShadowed:!0,shadowColor:"rgba(0, 0, 0, .4)",shadowOffset:new I(0,3),shadowBlur:5},$l(Bf,{name:"Border",figure:"RoundedRectangle",parameter1:1,parameter2:1,fill:"#F5F5F5",stroke:"#F0F0F0",spot1:new O(0,0,4,6),spot2:new O(1,1,-4,-4)}))});
em("ContextMenu",function(){return $l(pe,W.Vertical,{background:"#F5F5F5",isShadowed:!0,shadowColor:"rgba(0, 0, 0, .4)",shadowOffset:new I(0,3),shadowBlur:5},new zi("background","",function(a){return null!==a.adornedPart&&null!==a.placeholder?null:"#F5F5F5"}))});em("ContextMenuButton",function(){var a=$l("Button");a.stretch=Qk;var b=a.fb("ButtonBorder");b instanceof Bf&&(b.figure="Rectangle",b.strokeWidth=0,b.spot1=new O(0,0,2,3),b.spot2=new O(1,1,-2,-2));return a});
em("PanelExpanderButton",function(a){var b=fm(a,"COLLAPSIBLE"),c=$l("Button",{_buttonExpandedFigure:"M0 0 M0 6 L4 2 8 6 M8 8",_buttonCollapsedFigure:"M0 0 M0 2 L4 6 8 2 M8 8",_buttonFillNormal:"rgba(0, 0, 0, 0)",_buttonStrokeNormal:null,_buttonFillOver:"rgba(0, 0, 0, .2)",_buttonStrokeOver:null,_buttonFillPressed:"rgba(0, 0, 0, .4)",_buttonStrokePressed:null},$l(Bf,{name:"ButtonIcon",strokeWidth:2},(new zi("geometryString","visible",function(a){return a?c._buttonExpandedFigure:c._buttonCollapsedFigure})).cp(b)));
a=c.fb("ButtonBorder");a instanceof Bf&&(a.stroke=null,a.fill="rgba(0, 0, 0, 0)");c.click=function(a,c){var d=c.diagram;if(null!==d&&!d.isReadOnly){var e=c.ih();null===e&&(e=c.part);null!==e&&(c=e.fb(b),null!==c&&(a.handled=!0,d.Ea("Collapse/Expand Panel"),c.visible=!c.visible,d.eb("Collapse/Expand Panel")))}};return c});
em("CheckBoxButton",function(a){var b=fm(a);a=$l("Button",{desiredSize:new M(14,14)},$l(Bf,{name:"ButtonIcon",geometryString:"M0 0 M0 8.85 L4.9 13.75 16.2 2.45 M16.2 16.2",strokeWidth:2,stretch:nd,geometryStretch:mg,visible:!1},""!==b?(new zi("visible",b)).Lx():[]));a.click=function(a,d){if(d instanceof W){var c=a.diagram;if(!(null===c||c.isReadOnly||""!==b&&c.model.isReadOnly)){a.handled=!0;var f=d.fb("ButtonIcon");c.Ea("checkbox");f.visible=!f.visible;"function"===typeof d._doClick&&d._doClick(a,
d);c.eb("checkbox")}}};return a});
em("CheckBox",function(a){a=fm(a);a=$l("CheckBoxButton",a,{name:"Button",isActionable:!1,margin:new cc(0,1,0,0)});var b=$l(W,"Horizontal",a,{isActionable:!0,cursor:a.cursor,margin:new cc(1),_buttonFillNormal:a._buttonFillNormal,_buttonStrokeNormal:a._buttonStrokeNormal,_buttonFillOver:a._buttonFillOver,_buttonStrokeOver:a._buttonStrokeOver,_buttonFillPressed:a._buttonFillPressed,_buttonStrokePressed:a._buttonStrokePressed,_buttonFillDisabled:a._buttonFillDisabled,mouseEnter:a.mouseEnter,mouseLeave:a.mouseLeave,
actionDown:a.actionDown,actionUp:a.actionUp,actionCancel:a.actionCancel,actionMove:a.actionMove,click:a.click,_buttonClick:a.click});a.mouseEnter=null;a.mouseLeave=null;a.actionDown=null;a.actionUp=null;a.actionCancel=null;a.actionMove=null;a.click=null;return b});Y.None=kg=new D(Y,"None",0);Y.Default=Sk=new D(Y,"Default",0);Y.Vertical=Rk=new D(Y,"Vertical",4);Y.Horizontal=Qk=new D(Y,"Horizontal",5);Y.Fill=nd=new D(Y,"Fill",3);Y.Uniform=mg=new D(Y,"Uniform",1);
Y.UniformToFill=Tk=new D(Y,"UniformToFill",2);Y.FlipVertical=Uk=new D(Y,"FlipVertical",1);Y.FlipHorizontal=Vk=new D(Y,"FlipHorizontal",2);Y.FlipBoth=Wk=new D(Y,"FlipBoth",3);Y.make=$l;Y.getBuilders=function(){var a=new ob,b;for(b in am)if(b!==b.toLowerCase()){var c=am.I(b);"function"===typeof c&&a.add(b,c)}a.freeze();return a};Y.defineBuilder=em;
Y.build=function(a,b,c){for(var d=[],e=2;e<arguments.length;++e)d[e-2]=arguments[e];e=am.I(a);if("function"===typeof e&&(d=e([a].concat(d)),d instanceof Y))return b&&d.set(b),d;C('GraphObject.build invoked object builder "'+a+'", but it did not return an Object')};Y.takeBuilderArgument=fm;
function Yk(){this.oc=!1;this.xa="";this.zp=-1;this.nb=this.wm=this.te=null;this.j="";this.hb=this.Di=this.Ib=this.Ch=this.$h=this.F=this.Ha=this.ia=this.V=this.Lh=this.Xd=this.Of=this.Nh=this.Oh=this.Qh=this.Ph=this.Mh=this.Bh=this.Fh=this.zh=null;this.u=Cb;this.o=Kb;this.hg=Sk;this.ob=this.Bi=1;this.Pf=kg;this.Pc=this.Ci=this.gd=null;this.Oc="category"}
Yk.prototype.copy=function(){var a=new Yk;a.xa=this.xa;a.zp=this.zp;a.te=this.te;a.nb=this.nb;a.j=this.j;a.zh=this.zh;a.Fh=this.Fh;a.Bh=this.Bh;a.Mh=this.Mh;a.Ph=this.Ph;a.Qh=this.Qh;a.Oh=this.Oh;a.Nh=this.Nh;a.Of=this.Of;a.Xd=this.Xd;a.Lh=this.Lh;a.V=this.V;a.ia=this.ia;a.Ha=this.Ha;a.F=this.F;a.$h=this.$h;a.Ch=this.Ch;a.Ib=this.Ib;a.Di=this.Di;a.hb=this.hb;a.u=this.u.J();a.o=this.o.J();a.hg=this.hg;a.Bi=this.Bi;a.ob=this.ob;a.Pf=this.Pf;a.gd=this.gd;a.Ci=this.Ci;a.Pc=this.Pc;a.Oc=this.Oc;return a};
function gm(){this.u=-Infinity;this.o=0;this.F=pb;this.rr=Zf;this.j=this.V="None"}gm.prototype.copy=function(){var a=new gm;a.u=this.u;a.o=this.o;a.F=this.F.J();a.rr=this.rr;a.V=this.V;a.j=this.j;return a};gm.className="LinkElementSettings";function Zk(){this.yj=this.Xi=Sc;this.xj=this.Wi=this.wj=this.Vi=NaN;this.Tr=this.hq=null;this.Ur=this.iq=Infinity}
Zk.prototype.copy=function(){var a=new Zk;a.Xi=this.Xi.J();a.yj=this.yj.J();a.Vi=this.Vi;a.wj=this.wj;a.Wi=this.Wi;a.xj=this.xj;a.hq=this.hq;a.Tr=this.Tr;a.iq=this.iq;a.Ur=this.Ur;return a};Zk.className="LinkSettings";function km(){this.ha=[1,0,0,1,0,0]}km.prototype.copy=function(){var a=new km;a.ha[0]=this.ha[0];a.ha[1]=this.ha[1];a.ha[2]=this.ha[2];a.ha[3]=this.ha[3];a.ha[4]=this.ha[4];a.ha[5]=this.ha[5];return a};
km.prototype.translate=function(a,b){this.ha[4]+=this.ha[0]*a+this.ha[2]*b;this.ha[5]+=this.ha[1]*a+this.ha[3]*b};km.prototype.scale=function(a,b){this.ha[0]*=a;this.ha[1]*=a;this.ha[2]*=b;this.ha[3]*=b};
km.prototype.rotate=function(a,b,c){360<=a?a-=360:0>a&&(a+=360);if(0!==a){this.translate(b,c);if(90===a){a=0;var d=1}else 180===a?(a=-1,d=0):270===a?(a=0,d=-1):(d=a*Math.PI/180,a=Math.cos(d),d=Math.sin(d));var e=this.ha[1]*a+this.ha[3]*d,f=this.ha[0]*-d+this.ha[2]*a,g=this.ha[1]*-d+this.ha[3]*a;this.ha[0]=this.ha[0]*a+this.ha[2]*d;this.ha[1]=e;this.ha[2]=f;this.ha[3]=g;this.translate(-b,-c)}};km.className="STransform";
function lm(a){this.type=a;this.r2=this.y2=this.x2=this.r1=this.y1=this.x1=0;this.hx=[];this.pattern=null}lm.prototype.addColorStop=function(a,b){this.hx.push({offset:a,color:b})};lm.className="SGradient";
function Kj(a,b,c){this.ownerDocument=a=void 0===b?x.document:b;this.dA="http://www.w3.org/2000/svg";void 0!==a&&(this.Oa=this.Ud("svg",{width:"1px",height:"1px",viewBox:"0 0 1 1"}),this.style.display="block",this.style.letterSpacing="normal",this.style.wordSpacing="normal",this.Oa.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns","http://www.w3.org/2000/svg"),this.Oa.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:xlink","http://www.w3.org/1999/xlink"));this.hs=null;this.context=new ii(this,
c)}Kj.prototype.resize=function(a,b,c,d){return this.width!==a||this.height!==b?(this.width=a,this.height=b,this.style.width=c+"px",this.style.height=d+"px",this.Oa.setAttributeNS(null,"width",c+"px"),this.Oa.setAttributeNS(null,"height",d+"px"),this.Oa.setAttributeNS(null,"viewBox","0 0 "+c+" "+d),this.context.xw.firstElementChild.setAttributeNS(null,"width",c+"px"),this.context.xw.firstElementChild.setAttributeNS(null,"height",d+"px"),!0):!1};
Kj.prototype.Ud=function(a,b,c){a=this.ownerDocument.createElementNS(this.dA,a);if(za(b))for(var d in b)a.setAttributeNS("href"===d?"http://www.w3.org/1999/xlink":"",d,b[d]);void 0!==c&&(a.textContent=c);return a};Kj.prototype.getBoundingClientRect=function(){return this.Oa.getBoundingClientRect()};Kj.prototype.focus=function(){this.Oa.focus()};Kj.prototype.nx=function(){this.ownerDocument=null};
ma.Object.defineProperties(Kj.prototype,{width:{get:function(){return this.Oa.width.baseVal.value},set:function(a){this.Oa.setAttribute("width",a+"px")}},height:{get:function(){return this.Oa.height.baseVal.value},set:function(a){this.Oa.setAttribute("height",a+"px")}},style:{get:function(){return this.Oa.style}}});Kj.className="SVGSurface";
function ii(a,b){this.Hv=a;this.svg=a.Oa;this.stack=[];this.nh=[];this.fillStyle="#000000";this.font="10px sans-serif";this.globalAlpha=1;this.lineCap="butt";this.lineDashOffset=0;this.lineJoin="miter";this.lineWidth=1;this.miterLimit=10;this.shadowBlur=0;this.shadowColor="rgba(0, 0, 0, 0)";this.shadowOffsetY=this.shadowOffsetX=0;this.Il=!1;this.strokeStyle="#000000";this.textAlign="start";this.clipInsteadOfFill=!1;this.Vg=this.Br=this.Ar=0;this.yd=this.ad=null;this.path=[];this.Yl={};this.Ct=!1;
this.Yg=null;this.uj=0;this.rb=new km;this.j=Il(this);this.Mj=Il(this,"gojs-diagram");this.Hl=this.ti=null;var c=ib++,d=this.Ud("clipPath",{id:"mainClip"+c});d.appendChild(this.Ud("rect",{x:0,y:0,width:a.width,height:a.height}));this.xw=d;this.svg.appendChild(this.j);this.j.appendChild(this.Mj);this.svg.appendChild(d);this.j.setAttributeNS(null,"clip-path","url(#mainClip"+c+")");this.fA={};this.isTemporary=!!b;this.filter="";this.ak=null}
function Dj(a,b,c,d,e,f,g){void 0===a.Mj?Gl(a,b,c,d,e,f,g):a.Mj.setAttribute("transform","matrix("+b+", "+c+", "+d+", "+e+", "+f+", "+g+")")}t=ii.prototype;
t.arc=function(a,b,c,d,e,f,g,h){var k=2*Math.PI,l=k-1E-6,m=c*Math.cos(d),n=c*Math.sin(d),p=a+m,q=b+n,r=f?0:1;d=f?d-e:e-d;(1E-6<Math.abs(g-p)||1E-6<Math.abs(h-q))&&this.path.push(["L",p,+q]);0>d&&(d=d%k+k);d>l?(this.path.push(["A",c,c,0,1,r,a-m,b-n]),this.path.push(["A",c,c,0,1,r,p,q])):1E-6<d&&this.path.push(["A",c,c,0,+(d>=Math.PI),r,a+c*Math.cos(e),b+c*Math.sin(e)])};t.beginPath=function(){this.path=[];this.Yl={stroke:"none",fill:"none"}};t.Se=function(a){this.addPath(this.path,this.rb,a||0)};
t.bezierCurveTo=function(a,b,c,d,e,f){this.path.push(["C",a,b,c,d,e,f])};t.clearRect=function(){};t.clip=function(){this.Yl.clipPath=!0};t.closePath=function(){this.path.push(["z"])};t.createLinearGradient=function(a,b,c,d){var e=new lm("linear");e.x1=a;e.y1=b;e.x2=c;e.y2=d;return e};
t.createPattern=function(a){var b="";a instanceof HTMLCanvasElement&&(b=a.toDataURL());a instanceof HTMLImageElement&&(b=a.getAttribute("src"));var c=this.fA;if(c[b])return"url(#"+c[b]+")";var d="PATTERN"+ib++,e={x:0,y:0,width:a.width,height:a.height,href:b};a=this.Ud("pattern",{width:a.width,height:a.height,id:d,patternUnits:"userSpaceOnUse"});a.appendChild(this.Ud(Ri,e));this.svg.appendChild(a);c[b]=d;return"url(#"+d+")"};
t.createRadialGradient=function(a,b,c,d,e,f){var g=new lm("radial");g.x1=a;g.y1=b;g.r1=c;g.x2=d;g.y2=e;g.r2=f;return g};function Jl(a,b,c,d,e,f,g,h,k){d=h/d;e=k/e;0===f&&0===g||a.rb.translate(f,g);1===d&&1===e||a.rb.scale(d,e);0===b&&0===c||a.rb.translate(-b,-c)}
t.drawImage=function(a,b,c,d,e,f,g,h,k){var l="";a instanceof HTMLCanvasElement&&(l=a.toDataURL());a instanceof HTMLImageElement&&(l=a.getAttribute("src"));var m=a instanceof HTMLImageElement?a.naturalWidth:a.width;a=a instanceof HTMLImageElement?a.naturalHeight:a.height;void 0===d&&(f=b,g=c,h=d=m,k=e=a);d=d||0;e=e||0;h=h||0;k=k||0;Jl(this,b,c,d,e,f||0,g||0,h,k);f={x:0,y:0,width:m||d,height:a||e,href:l};K.$(d,h)&&K.$(e,k)?-1===l.toLowerCase().indexOf(".svg")&&-1===l.toLowerCase().indexOf("data:image/svg")&&
(f.preserveAspectRatio="xMidYMid slice"):f.preserveAspectRatio="none";if(0!==b||0!==c||d!==m||e!==a)d+=b,e+=c,f["clip-path"]="path('M "+b+","+c+" L "+d+" "+c+" L "+d+" "+e+" L "+b+" "+e+" z')";mm(this,Ri,f,this.rb);this.addElement(Ri,f)};t.fill=function(a){a&&(this.Yl["fill-rule"]="evenodd");nm(this,this.Yl)};t.ng=function(a,b){this.clipInsteadOfFill?this.clip():this.fill(b)};
t.fillRect=function(a,b,c,d){a=[a,b,c,d];a={x:a[0],y:a[1],width:a[2],height:a[3]};mm(this,"fill",a,this.rb);this.addElement("rect",a)};t.px=function(a,b,c,d){a={x:a,y:b,width:c,height:d};mm(this,"fill",a,this.rb);nm(this,a);this.svg.prepend(this.Ud("rect",a))};t.fillText=function(a,b,c){a=[a,b,c];b=this.textAlign;"left"===b?b="start":"right"===b?b="end":"center"===b&&(b="middle");b={x:a[1],y:a[2],style:"font: "+this.font,"text-anchor":b};mm(this,"fill",b,this.rb);this.addElement("text",b,a[0])};
t.lineTo=function(a,b){this.path.push(["L",a,b])};t.moveTo=function(a,b){this.path.push(["M",a,b])};t.quadraticCurveTo=function(a,b,c,d){this.path.push(["Q",a,b,c,d])};t.rect=function(a,b,c,d){this.path.push(["M",a,b],["L",a+c,b],["L",a+c,b+d],["L",a,b+d],["z"])};
t.restore=function(){this.rb=this.stack.pop();this.path=this.stack.pop();var a=this.stack.pop();this.fillStyle=a.fillStyle;this.font=a.font;this.globalAlpha=a.globalAlpha;this.lineCap=a.lineCap;this.lineDashOffset=a.lineDashOffset;this.lineJoin=a.lineJoin;this.lineWidth=a.lineWidth;this.miterLimit=a.miterLimit;this.shadowBlur=a.shadowBlur;this.shadowColor=a.shadowColor;this.shadowOffsetX=a.shadowOffsetX;this.shadowOffsetY=a.shadowOffsetY;this.strokeStyle=a.strokeStyle;this.textAlign=a.textAlign;this.ak=
a.ak};
t.save=function(){this.stack.push({fillStyle:this.fillStyle,font:this.font,globalAlpha:this.globalAlpha,lineCap:this.lineCap,lineDashOffset:this.lineDashOffset,lineJoin:this.lineJoin,lineWidth:this.lineWidth,miterLimit:this.miterLimit,shadowBlur:this.shadowBlur,shadowColor:this.shadowColor,shadowOffsetX:this.shadowOffsetX,shadowOffsetY:this.shadowOffsetY,strokeStyle:this.strokeStyle,textAlign:this.textAlign,ak:this.ak});for(var a=[],b=0;b<this.path.length;b++)a.push(this.path[b]);this.stack.push(a);this.stack.push(this.rb.copy())};
t.setTransform=function(a,b,c,d,e,f){1===a&&0===b&&0===c&&1===d&&0===e&&0===f||Gl(this,a,b,c,d,e,f)};function Kl(a){a=a.rb.ha;return"matrix("+a[0]+", "+a[1]+", "+a[2]+", "+a[3]+", "+a[4]+", "+a[5]+")"}t.scale=function(a,b){this.rb.scale(a,b)};t.translate=function(a,b){this.rb.translate(a,b)};t.transform=function(){};t.yc=function(){};t.stroke=function(){om(this,this.Yl)};t.ik=function(){this.clipInsteadOfFill||this.stroke()};t.Ud=function(a,b,c){return this.Hv.Ud(a,b,c)};
t.addElement=function(a,b,c){a=this.Ud(a,b,c);null!==this.yd?this.yd.appendChild(a):this.yd=a;return this.ad=a};function mm(a,b,c,d){"fill"===b?nm(a,c):"stroke"===b&&om(a,c);null!==d&&(a=d.ha,c.transform="matrix("+a[0]+", "+a[1]+", "+a[2]+", "+a[3]+", "+a[4]+", "+a[5]+")")}
function nm(a,b){a.fillStyle instanceof lm?b.fill=pm(a,a.fillStyle,!0):/^rgba\(/.test(a.fillStyle)?(a=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle),b.fill="rgb("+a[1]+","+a[2]+","+a[3]+")",b["fill-opacity"]=a[4]):b.fill=a.fillStyle}
function qm(a,b,c){c=c?"fill":"stroke";a.fillStyle instanceof lm?b.setAttributeNS(null,"fill",pm(a,a.fillStyle,!0)):/^rgba\(/.test(a.fillStyle)?(a=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle),b.setAttributeNS(null,c,"rgb("+a[1]+","+a[2]+","+a[3]+")"),b.setAttributeNS(null,c+"-opacity",a[4])):b.setAttributeNS(null,c,a.fillStyle)}
function om(a,b){if(a.strokeStyle instanceof lm)b.stroke=pm(a,a.strokeStyle,!1);else if(/^rgba\(/.test(a.strokeStyle)){var c=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.strokeStyle);b.stroke="rgb("+c[1]+","+c[2]+","+c[3]+")";b["stroke-opacity"]=c[4]}else b.stroke=a.strokeStyle;b["stroke-width"]=a.lineWidth;b["stroke-linecap"]=a.lineCap;b["stroke-linejoin"]=a.lineJoin;b["stroke-miterlimit"]=a.miterLimit;a.Ct&&(b["stroke-dasharray"]=a.Yg.toString(),b["stroke-dashoffset"]=
a.uj)}
function pm(a,b,c){var d="";d=null!==a.Hl?a.Hl.getAttribute(c?"fill":"stroke"):null;null!==d&&-1!==d.indexOf("GRAD")?(d=d.split("#")[1].slice(0,-1),c=a.svg.getElementById(d),null!==c&&c.remove()):d="GRAD"+ib++;if("linear"===b.type)c=a.Ud("linearGradient",{x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,id:d,gradientUnits:"userSpaceOnUse"});else if("radial"===b.type)c=a.Ud("radialGradient",{x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,r1:b.r1,r2:b.r2,id:d});else throw Error("invalid gradient");var e=b.hx;b=e.length;for(var f=[],
g=0;g<b;g++){var h=e[g],k=h.color;h={offset:h.offset,"stop-color":k};/^rgba\(/.test(k)&&(k=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(k),h["stop-color"]="rgb("+k[1]+","+k[2]+","+k[3]+")",h["stop-opacity"]=k[4]);f.push(h)}f.sort(function(a,b){return a.offset>b.offset?1:-1});for(e=0;e<b;e++)c.appendChild(a.Ud("stop",f[e]));a.svg.appendChild(c);return"url(#"+d+")"}
t.addPath=function(a,b,c){var d=[];for(var e=0;e<a.length;e++){var f=Ba(a[e]),g=[f.shift()];if("A"===g[0])g.push(f.shift()+","+f.shift(),f.shift(),f.shift()+","+f.shift(),f.shift()+","+f.shift());else for(;f.length;)g.push(f.shift()+","+f.shift());d.push(g.join(" "))}d=d.join(" ");a=this.Yl;if(a.clipPath)0<this.nh.length&&this.nh[this.nh.length-1].setAttributeNS(null,"clip-path",'path("'+d+'")');else if(a.d=d,mm(this,"",a,b),null!==this.Hl)if(b=this.Hl,"g"!==b.nodeName)for(var h in a)"transform"!==
h&&b.setAttributeNS(null,h,a[h]);else{h=b.getElementsByTagName("path");for(var k in a)"transform"!==k&&h[c].setAttributeNS(null,k,a[k])}else this.addElement("path",a)};function Gl(a,b,c,d,e,f,g){var h=new km;h.ha=[b,c,d,e,f,g];b={};mm(a,"g",b,h);h=a.addElement("g",b);a.nh.push(h)}function Il(a,b){var c={};b&&(c["class"]=b);b=a.addElement("g",c);a.nh.push(b);return b}t.Fv=function(a,b,c){this.Ar=a;this.Br=b;this.Vg=c};function Bl(a){a.Il=!1;a.shadowOffsetX=0;a.shadowOffsetY=0;a.shadowBlur=0}
function xl(a){a.Il=!0;a.shadowOffsetX=a.Ar;a.shadowOffsetY=a.Br;a.shadowBlur=a.Vg}t.js=function(a,b){this.Ct=!0;this.Yg=a;this.uj=b};t.fs=function(){this.Ct=!1};t.Vc=function(){};t.Xx=function(){};ii.prototype.rotate=function(a){this.rb.rotate(57.2958*a,0,0)};ii.prototype.getImageData=function(){return null};ii.prototype.measureText=function(){return null};
function Ll(a,b){b=b.getAttribute("fill");null!==b&&(b=b.split("#"),1!==b.length&&(b=a.svg.getElementById(b[1].slice(0,-1)),null!==b&&1===a.svg.innerHTML.match(new RegExp(b.id,"g")).length&&b.remove()))}ii.prototype.Hs=function(a){null!==a.svg&&a.svg.parentNode===this.Mj&&a.svg.remove()};ii.className="SVGContext";
Q.prototype.Cs=function(a){this.pj.contains("SVG")||this.bs("SVG",new Kj(this,x.document));var b=new Kj(this,x.document,!0);void 0===a&&(a=new Ua);var c=this;return yk(this,function(a,e){a=zk(c,a,"SVG",b);a=null!==a?a.svg:null;return"function"===typeof e?(e(a),null):a},a)};Q.prototype.makeSvg=Q.prototype.Cs;Q.prototype.bv=function(a){return this.Cs(a)};Q.prototype.makeSVG=Q.prototype.bv;
function Hi(a,b,c,d){this.ownerDocument=b=void 0===b?x.document:b;this.hs=null;b=b.createElement("canvas");b.tabIndex=0;this.Oa=b;this.context=new Nl(b,c,d);b.H=a}Hi.prototype.resize=function(a,b,c,d){return this.width!==a||this.height!==b?(this.width=a,this.height=b,this.style.width=c+"px",this.style.height=d+"px",!0):!1};Hi.prototype.toDataURL=function(a,b){return this.Oa.toDataURL(a,b)};Hi.prototype.getBoundingClientRect=function(){return this.Oa.getBoundingClientRect()};Hi.prototype.focus=function(){this.Oa.focus()};
Hi.prototype.nx=function(){this.ownerDocument=this.Oa.H=null};ma.Object.defineProperties(Hi.prototype,{width:{get:function(){return this.Oa.width},set:function(a){this.Oa.width=a}},height:{get:function(){return this.Oa.height},set:function(a){this.Oa.height=a}},style:{get:function(){return this.Oa.style}}});Hi.className="CanvasSurface";
function Nl(a,b,c){this.Z=a.getContext?a.getContext("2d",b):null;null===this.Z&&C("Browser does not support HTML Canvas Element");this.ct=this.et=this.dt="";this.Hm=!1;this.Vg=this.Br=this.Ar=0;this.Il=!1;this.El=new ld;this.isTemporary=!!c}t=Nl.prototype;t.Xx=function(a){this.Z.imageSmoothingEnabled=a};t.arc=function(a,b,c,d,e,f){this.Z.arc(a,b,c,d,e,f)};t.beginPath=function(){this.Z.beginPath()};t.Se=function(){};t.bezierCurveTo=function(a,b,c,d,e,f){this.Z.bezierCurveTo(a,b,c,d,e,f)};
t.clearRect=function(a,b,c,d){this.yc();this.Z.clearRect(a,b,c,d)};t.clip=function(){this.Z.clip()};t.closePath=function(){this.Z.closePath()};t.createLinearGradient=function(a,b,c,d){return this.Z.createLinearGradient(a,b,c,d)};t.createPattern=function(a,b){return this.Z.createPattern(a,b)};t.createRadialGradient=function(a,b,c,d,e,f){return this.Z.createRadialGradient(a,b,c,d,e,f)};t.drawImage=function(a,b,c,d,e,f,g,h,k){void 0===d?this.Z.drawImage(a,b,c):this.Z.drawImage(a,b,c,d,e,f,g,h,k)};
t.fill=function(a){this.Z.fill(a?"evenodd":"nonzero")};t.fillRect=function(a,b,c,d){this.Z.fillRect(a,b,c,d)};t.px=function(a,b,c,d){this.Z.fillRect(a,b,c,d)};t.fillText=function(a,b,c){this.Z.fillText(a,b,c)};t.getImageData=function(a,b,c,d){return this.Z.getImageData(a,b,c,d)};t.lineTo=function(a,b){this.Z.lineTo(a,b)};t.measureText=function(a){return this.Z.measureText(a)};t.moveTo=function(a,b){this.Z.moveTo(a,b)};t.quadraticCurveTo=function(a,b,c,d){this.Z.quadraticCurveTo(a,b,c,d)};
t.rect=function(a,b,c,d){this.Z.rect(a,b,c,d)};t.restore=function(){this.Z.restore()};t.save=function(){this.yc();this.Z.save()};Nl.prototype.rotate=function(a){this.El.rotate(57.2958*a,0,0)};t=Nl.prototype;t.setTransform=function(a,b,c,d,e,f){this.El.setTransform(a,b,c,d,e,f)};t.scale=function(a,b){this.El.scale(a,b)};t.translate=function(a,b){this.El.translate(a,b)};t.stroke=function(){this.Z.stroke()};
t.transform=function(a,b,c,d,e,f){if(1!==a||0!==b||0!==c||1!==d||0!==e||0!==f){var g=this.El,h=g.m11*a+g.m21*b;a=g.m12*a+g.m22*b;b=g.m11*c+g.m21*d;c=g.m12*c+g.m22*d;g.dx=g.m11*e+g.m21*f+g.dx;g.dy=g.m12*e+g.m22*f+g.dy;g.m11=h;g.m12=a;g.m21=b;g.m22=c}};t.yc=function(){var a=this.El;this.Z.setTransform(a.m11,a.m12,a.m21,a.m22,a.dx,a.dy)};
t.ng=function(a,b){if(a instanceof vl&&a.type===wl){var c=a.xk;a=a.ft;a>c?(this.scale(c/a,1),this.translate((a-c)/2,0)):c>a&&(this.scale(1,a/c),this.translate(0,(c-a)/2));this.Hm?this.clip():this.fill(b);a>c?(this.translate(-(a-c)/2,0),this.scale(1/(c/a),1)):c>a&&(this.translate(0,-(c-a)/2),this.scale(1,1/(a/c)))}else this.Hm?this.clip():this.fill(b)};t.ik=function(){this.Hm||this.stroke()};t.Fv=function(a,b,c){this.Ar=a;this.Br=b;this.Vg=c};
t.js=function(a,b){var c=this.Z;void 0!==c.setLineDash&&(c.setLineDash(a),c.lineDashOffset=b)};t.fs=function(){var a=this.Z;void 0!==a.setLineDash&&(a.setLineDash(rm),a.lineDashOffset=0)};t.Vc=function(a){a&&(this.dt="");this.ct=this.et=""};t.Hs=function(){};
ma.Object.defineProperties(Nl.prototype,{fillStyle:{get:function(){return this.Z.fillStyle},set:function(a){this.ct!==a&&(this.ct=this.Z.fillStyle=a)}},font:{get:function(){return this.Z.font},set:function(a){this.dt!==a&&(this.dt=this.Z.font=a)}},globalAlpha:{get:function(){return this.Z.globalAlpha},set:function(a){this.Z.globalAlpha=a}},lineCap:{get:function(){return this.Z.lineCap},
set:function(a){this.Z.lineCap=a}},lineDashOffset:{get:function(){return this.Z.lineDashOffset},set:function(a){this.Z.lineDashOffset=a}},lineJoin:{get:function(){return this.Z.lineJoin},set:function(a){this.Z.lineJoin=a}},lineWidth:{get:function(){return this.Z.lineWidth},set:function(a){this.Z.lineWidth=a}},miterLimit:{get:function(){return this.Z.miterLimit},set:function(a){this.Z.miterLimit=
a}},shadowBlur:{get:function(){return this.Z.shadowBlur},set:function(a){this.Z.shadowBlur=a}},shadowColor:{get:function(){return this.Z.shadowColor},set:function(a){this.Z.shadowColor=a}},shadowOffsetX:{get:function(){return this.Z.shadowOffsetX},set:function(a){this.Z.shadowOffsetX=a}},shadowOffsetY:{get:function(){return this.Z.shadowOffsetY},set:function(a){this.Z.shadowOffsetY=
a}},strokeStyle:{get:function(){return this.Z.strokeStyle},set:function(a){this.et!==a&&(this.et=this.Z.strokeStyle=a)}},textAlign:{get:function(){return this.Z.textAlign},set:function(a){this.Z.textAlign=a}},imageSmoothingEnabled:{get:function(){return this.Z.imageSmoothingEnabled},set:function(a){this.Z.imageSmoothingEnabled=a}},filter:{get:function(){return this.Z.filter},set:function(a){this.Z.filter=
""===a?"none":a}},clipInsteadOfFill:{get:function(){return this.Hm},set:function(a){this.Hm=a}}});var rm=Object.freeze([]);Nl.className="CanvasSurfaceContext";function sm(){this.F=this.o=this.u=this.j=0}sm.className="ColorNumbers";
function vl(a,b){tm||(um(),tm=!0);Qa(this);this._isFrozen=!1;void 0===a?(this.na=Ml,this.wk="black"):"string"===typeof a?(this.na=Ml,this.wk=a):(this.na=a,this.wk="black");a=this.na;a===Pl?(this.Qd=tc,this.Kk=zc):this.Kk=a===wl?this.Qd=wc:this.Qd=lc;this.Gr=0;this.Xp=NaN;this.xe=this.cr=this.we=null;this.ft=this.xk=0;b&&Object.assign(this,b)}
vl.prototype.copy=function(){var a=new vl;a.na=this.na;a.wk=this.wk;a.Qd=this.Qd.J();a.Kk=this.Kk.J();a.Gr=this.Gr;a.Xp=this.Xp;null!==this.we&&(a.we=this.we.copy());a.cr=this.cr;return a};t=vl.prototype;t.freeze=function(){this._isFrozen=!0;null!==this.we&&this.we.freeze();return this};t.ka=function(){Object.isFrozen(this)&&C("cannot thaw constant: "+this);this._isFrozen=!1;null!==this.we&&this.we.ka();return this};t.qb=function(a){a.classType===vl&&(this.type=a)};
t.toString=function(){var a="Brush(";if(this.type===Ml)a+=this.color;else if(a=this.type===Pl?a+"Linear ":this.type===wl?a+"Radial ":this.type===Ol?a+"Pattern ":a+"(unknown) ",a+=this.start+" "+this.end,null!==this.colorStops)for(var b=this.colorStops.iterator;b.next();)a+=" "+b.key+":"+b.value;return a+")"};
t.addColorStop=function(a,b){this._isFrozen&&va(this);("number"!==typeof a||!isFinite(a)||1<a||0>a)&&xa(a,"0 <= loc <= 1",vl,"addColorStop:loc");null===this.we&&(this.we=new ob);this.we.add(a,b);this.na===Ml&&(this.type=Pl);this.xe=null;return this};
t.Wz=function(a,b){this._isFrozen&&va(this);a=void 0===a||"number"!==typeof a?.2:a;b=void 0===b?vm:b;if(this.type===Ml)Uh(this.color),this.color=wm(a,b);else if((this.type===Pl||this.type===wl)&&null!==this.colorStops)for(var c=this.colorStops.iterator;c.next();)Uh(c.value),this.addColorStop(c.key,wm(a,b));return this};function xm(a,b,c){b=void 0===b||"number"!==typeof b?.2:b;c=void 0===c?vm:c;Uh(a);return wm(b,c)}
t.Xy=function(a,b){this._isFrozen&&va(this);a=void 0===a||"number"!==typeof a?.2:a;b=void 0===b?vm:b;if(this.type===Ml)Uh(this.color),this.color=wm(-a,b);else if((this.type===Pl||this.type===wl)&&null!==this.colorStops)for(var c=this.colorStops.iterator;c.next();)Uh(c.value),this.addColorStop(c.key,wm(-a,b));return this};function ym(a,b,c){b=void 0===b||"number"!==typeof b?.2:b;c=void 0===c?vm:c;Uh(a);return wm(-b,c)}
function zm(a,b,c){Uh(a);a=Am.j;var d=Am.u,e=Am.o,f=Am.F;Uh(b);void 0===c&&(c=.5);return"rgba("+Math.round((Am.j-a)*c+a)+", "+Math.round((Am.u-d)*c+d)+", "+Math.round((Am.o-e)*c+e)+", "+Math.round((Am.F-f)*c+f)+")"}
t.Hx=function(){if(this.type===Ml)return Bm(this.color);if((this.type===Pl||this.type===wl)&&null!==this.colorStops){var a=this.colorStops;if(this.type===wl)return Bm(a.first().value);if(null!==a.get(.5))return Bm(a.get(.5));if(2===a.count)return a=a.Fa(),Bm(zm(a[0].value,a[1].value));for(var b=a.iterator,c=-1,d=-1,e=1,f=1;b.next();){var g=b.key,h=Math.abs(.5-b.key);e>f&&h<e?(c=g,e=h):f>=e&&h<f&&(d=g,f=h)}c>d&&(f=c,c=d,d=f,f=e);e=d-c;return Bm(zm(a.get(c),a.get(d),1-f/e))}return!1};
function Bm(a){if(!a)return!1;if(a instanceof vl)return a.Hx();Uh(a);return 128>(299*Am.j+587*Am.u+114*Am.o)/1E3}
function wm(a,b){switch(b){case vm:b=100*Cm(Am.j);var c=100*Cm(Am.u),d=100*Cm(Am.o);Dm.j=.4124564*b+.3575761*c+.1804375*d;Dm.u=.2126729*b+.7151522*c+.072175*d;Dm.o=.0193339*b+.119192*c+.9503041*d;Dm.F=Am.F;b=Em(Dm.j/Fm[0]);c=Em(Dm.u/Fm[1]);d=Em(Dm.o/Fm[2]);Gm.j=116*c-16;Gm.u=500*(b-c);Gm.o=200*(c-d);Gm.F=Dm.F;Gm.j=Math.min(100,Math.max(0,Gm.j+100*a));a=(Gm.j+16)/116;b=a-Gm.o/200;Dm.j=Fm[0]*Hm(Gm.u/500+a);Dm.u=Fm[1]*(Gm.j>Im*Jm?Math.pow(a,3):Gm.j/Im);Dm.o=Fm[2]*Hm(b);Dm.F=Gm.F;a=-.969266*Dm.j+1.8760108*
Dm.u+.041556*Dm.o;b=.0556434*Dm.j+-.2040259*Dm.u+1.0572252*Dm.o;Am.j=255*Km((3.2404542*Dm.j+-1.5371385*Dm.u+-.4985314*Dm.o)/100);Am.u=255*Km(a/100);Am.o=255*Km(b/100);Am.F=Dm.F;Am.j=Math.round(Am.j);255<Am.j?Am.j=255:0>Am.j&&(Am.j=0);Am.u=Math.round(Am.u);255<Am.u?Am.u=255:0>Am.u&&(Am.u=0);Am.o=Math.round(Am.o);255<Am.o?Am.o=255:0>Am.o&&(Am.o=0);return"rgba("+Am.j+", "+Am.u+", "+Am.o+", "+Am.F+")";case Lm:return Vh(),Wh.o=Math.min(100,Math.max(0,Wh.o+100*a)),"hsla("+Wh.j+", "+Wh.u+"%, "+Wh.o+"%, "+
Wh.F+")";default:C("Unknown color space: "+b)}}function Uh(a){tm||(um(),tm=!0);var b=Mm;if(null!==b){b.clearRect(0,0,1,1);b.fillStyle="#000000";var c=b.fillStyle;b.fillStyle=a;b.fillStyle!==c?(b.fillRect(0,0,1,1),a=b.getImageData(0,0,1,1).data,Am.j=a[0],Am.u=a[1],Am.o=a[2],Am.F=a[3]/255):(b.fillStyle="#FFFFFF",b.fillStyle=a,Am.j=0,Am.u=0,Am.o=0,Am.F=1)}}
function Vh(){var a=Am.j/255,b=Am.u/255,c=Am.o/255,d=Math.max(a,b,c),e=Math.min(a,b,c),f=d-e;e=(d+e)/2;if(0===f)var g=a=0;else{switch(d){case a:g=(b-c)/f%6;break;case b:g=(c-a)/f+2;break;case c:g=(a-b)/f+4}g*=60;0>g&&(g+=360);a=f/(1-Math.abs(2*e-1))}Wh.j=Math.round(g);Wh.u=Math.round(100*a);Wh.o=Math.round(100*e);Wh.F=Am.F}function Cm(a){a/=255;return.04045>=a?a/12.92:Math.pow((a+.055)/1.055,2.4)}function Km(a){return.0031308>=a?12.92*a:1.055*Math.pow(a,1/2.4)-.055}
function Em(a){return a>Jm?Math.pow(a,1/3):(Im*a+16)/116}function Hm(a){var b=a*a*a;return b>Jm?b:(116*a-16)/Im}function hm(a,b){"string"!==typeof a&&(a instanceof vl||C("Value for "+b+" must be a color string or a Brush, not "+a))}function um(){Mm=Lg?(new Hi(null,void 0,{willReadFrequently:!0})).context:null}
ma.Object.defineProperties(vl.prototype,{type:{get:function(){return this.na},set:function(a){this._isFrozen&&va(this,a);this.na=a;this.start.Mc()&&(a===Pl?this.start=tc:a===wl&&(this.start=wc));this.end.Mc()&&(a===Pl?this.end=zc:a===wl&&(this.end=wc));this.xe=null}},color:{get:function(){return this.wk},set:function(a){this._isFrozen&&va(this,a);this.wk=a;this.xe=null}},start:{get:function(){return this.Qd},
set:function(a){this._isFrozen&&va(this,a);this.Qd=a.J();this.xe=null}},end:{get:function(){return this.Kk},set:function(a){this._isFrozen&&va(this,a);this.Kk=a.J();this.xe=null}},startRadius:{get:function(){return this.Gr},set:function(a){this._isFrozen&&va(this,a);0>a&&xa(a,">= zero",vl,"startRadius");this.Gr=a;this.xe=null}},endRadius:{get:function(){return this.Xp},set:function(a){this._isFrozen&&va(this,
a);0>a&&xa(a,">= zero",vl,"endRadius");this.Xp=a;this.xe=null}},colorStops:{get:function(){return this.we},set:function(a){this._isFrozen&&va(this,a);this.we=a;this.xe=null}},pattern:{get:function(){return this.cr},set:function(a){this._isFrozen&&va(this,a);this.cr=a;this.xe=null}}});vl.prototype.isDark=vl.prototype.Hx;vl.prototype.darkenBy=vl.prototype.Xy;vl.prototype.lightenBy=vl.prototype.Wz;vl.prototype.addColorStop=vl.prototype.addColorStop;
var Jm=216/24389,Im=24389/27,Fm=[95.047,100,108.883],Mm=null,Am=new sm,Wh=new sm,Dm=new sm,Gm=new sm,tm=!1;vl.className="Brush";var Ml;vl.Solid=Ml=new D(vl,"Solid",0);var Pl;vl.Linear=Pl=new D(vl,"Linear",1);var wl;vl.Radial=wl=new D(vl,"Radial",2);var Ol;vl.Pattern=Ol=new D(vl,"Pattern",4);var vm;vl.Lab=vm=new D(vl,"Lab",5);var Lm;vl.HSL=Lm=new D(vl,"HSL",6);
vl.randomColor=function(a,b){void 0===a&&(a=128);void 0===b&&(b=Math.max(a,255));var c=Math.abs(b-a);b=Math.floor(a+Math.random()*c).toString(16);var d=Math.floor(a+Math.random()*c).toString(16);a=Math.floor(a+Math.random()*c).toString(16);2>b.length&&(b="0"+b);2>d.length&&(d="0"+d);2>a.length&&(a="0"+a);return"#"+b+d+a};
vl.isValidColor=function(a){if("black"===a)return!0;if(""===a)return!1;tm||(um(),tm=!0);var b=Mm;if(null===b)return!0;b.fillStyle="#000000";var c=b.fillStyle;b.fillStyle=a;if(b.fillStyle!==c)return!0;b.fillStyle="#FFFFFF";c=b.fillStyle;b.fillStyle=a;return b.fillStyle!==c};vl.lighten=function(a){return xm(a)};vl.lightenBy=xm;vl.darken=function(a){return ym(a)};vl.darkenBy=ym;vl.mix=zm;vl.isDark=Bm;function dm(){this.xa="Base"}dm.prototype.measure=function(){};
dm.prototype.Zz=function(a,b,c,d,e){a.measure(b,c,d,e)};dm.prototype.arrange=function(){};dm.prototype.My=function(a,b,c,d,e,f){a.arrange(b,c,d,e,f)};dm.prototype.jA=function(a){a.Tj()};dm.prototype.hc=function(){};ma.Object.defineProperties(dm.prototype,{name:{get:function(){return this.xa},set:function(a){this.xa=a}},classType:{get:function(){return W}}});dm.prototype.remeasureObject=dm.prototype.jA;dm.prototype.arrangeElement=dm.prototype.My;
dm.prototype.measureElement=dm.prototype.Zz;dm.className="PanelLayout";function Nm(){this.xa="Base"}la(Nm,dm);
Nm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length;a=Om(a);for(var k=0;k<h;k++){var l=d[k];if(l.visible||l===a){var m=l.margin,n=m.right+m.left;m=m.top+m.bottom;l.measure(b,c,f,g);var p=l.measuredBounds;n=Math.max(p.width+n,0);m=Math.max(p.height+m,0);p=l.position.x;var q=l.position.y;isFinite(p)||(p=0);isFinite(q)||(q=0);l instanceof Bf&&l.isGeometryPositioned&&(l=l.strokeWidth/2,p-=l,q-=l);Xb(e,p,q,n,m)}}};
Nm.prototype.arrange=function(a,b,c){var d=b.length,e=a.padding;a=c.x-e.left;c=c.y-e.top;for(e=0;e<d;e++){var f=b[e],g=f.measuredBounds,h=f.margin,k=f.position.x,l=f.position.y;k=isNaN(k)?-a:k-a;l=isNaN(l)?-c:l-c;if(f instanceof Bf&&f.isGeometryPositioned){var m=f.strokeWidth/2;k-=m;l-=m}f.visible&&f.arrange(k+h.left,l+h.top,g.width,g.height)}};function Pm(){this.xa="Base"}la(Pm,dm);
Pm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length;b=Ea();f=Om(a);for(var k=0;k<h;k++){var l=d[k];if(l.visible||l===f){var m=kl(l,!1);if(m!==kg&&m!==Qk)b.push(l);else{l.measure(Infinity,c,0,g);m=l.margin;l=l.measuredBounds;var n=Math.max(l.height+m.top+m.bottom,0);e.width+=Math.max(l.width+m.right+m.left,0);e.height=Math.max(e.height,n)}}}d=b.length;a.desiredSize.height?c=Math.min(a.desiredSize.height,a.maxSize.height):0!==e.height&&(c=Math.min(e.height,a.maxSize.height));for(a=0;a<d;a++)if(k=
b[a],k.visible||k===f)m=k.margin,h=m.right+m.left,m=m.top+m.bottom,k.measure(Infinity,c,0,g),k=k.measuredBounds,m=Math.max(k.height+m,0),e.width+=Math.max(k.width+h,0),e.height=Math.max(e.height,m);Ga(b)};
Pm.prototype.arrange=function(a,b,c){for(var d=b.length,e=a.padding,f=e.top,g=a.isOpposite,h=g?c.width:e.left,k=0;k<d;k++){var l=f,m=b[k];if(m.visible){var n=m.measuredBounds,p=m.margin,q=p.top+p.bottom,r=f+e.bottom,u=n.height,w=kl(m,!1);if(isNaN(m.desiredSize.height)&&w===nd||w===Rk)u=Math.max(c.height-q-r,0);q=u+q+r;r=m.alignment;r.Zb()&&(r=a.defaultAlignment);r.sb()||(r=wc);g&&(h-=n.width+p.left+p.right);m.arrange(h+r.offsetX+p.left,l+r.offsetY+p.top+(c.height*r.y-q*r.y),n.width,u);g||(h+=n.width+
p.left+p.right)}}};function Qm(){this.xa="Base"}la(Qm,dm);
Qm.prototype.measure=function(a,b,c,d,e,f){var g=d.length;c=Ea();for(var h=Om(a),k=0;k<g;k++){var l=d[k];if(l.visible||l===h){var m=kl(l,!1);if(m!==kg&&m!==Rk)c.push(l);else{var n=l.margin;m=n.right+n.left;n=n.top+n.bottom;l.measure(b,Infinity,f,0);l=l.measuredBounds;Pb(e,Math.max(e.width,Math.max(l.width+m,0)),e.height+Math.max(l.height+n,0))}}}d=c.length;if(0!==d){a.desiredSize.width?b=Math.min(a.desiredSize.width,a.maxSize.width):0!==e.width&&(b=Math.min(e.width,a.maxSize.width));for(a=0;a<d;a++)if(k=
c[a],k.visible||k===h)l=k.margin,g=l.right+l.left,l=l.top+l.bottom,k.measure(b,Infinity,f,0),k=k.measuredBounds,l=Math.max(k.height+l,0),e.width=Math.max(e.width,Math.max(k.width+g,0)),e.height+=l;Ga(c)}};
Qm.prototype.arrange=function(a,b,c){for(var d=b.length,e=a.padding,f=e.left,g=a.isOpposite,h=g?c.height:e.top,k=0;k<d;k++){var l=f,m=b[k];if(m.visible){var n=m.measuredBounds,p=m.margin,q=p.left+p.right,r=f+e.right,u=n.width,w=kl(m,!1);if(isNaN(m.desiredSize.width)&&w===nd||w===Qk)u=Math.max(c.width-q-r,0);q=u+q+r;r=m.alignment;r.Zb()&&(r=a.defaultAlignment);r.sb()||(r=wc);g&&(h-=n.height+p.bottom+p.top);m.arrange(l+r.offsetX+p.left+(c.width*r.x-q*r.x),h+r.offsetY+p.top,u,n.height);g||(h+=n.height+
p.bottom+p.top)}}};function Rm(){this.xa="Base"}la(Rm,dm);
Rm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length,k=a.Qb(),l=k.margin,m=l.right+l.left,n=l.top+l.bottom;k.measure(b,c,f,g);var p=k.measuredBounds;f=p.width;g=p.height;var q=Math.max(f+m,0);var r=Math.max(g+n,0);var u=a.isClipping,w=N.allocAt(-l.left,-l.top,q,r),v=N.allocAt(0,0,f,g);Sm(k,v,!0);f=v.width;g=v.height;for(var y=!0,z=Om(a),A=0;A<h;A++){var B=d[A];p=b;var E=c;if(B!==k&&(B.visible||B===z)){l=B.margin;var L=l.left,R=l.top;q=l.right+l.left;r=l.top+l.bottom;l=kl(B,!1);switch(l){case nd:p=
f;E=g;R=L=r=q=0;break;case Qk:p=f;L=q=0;break;case Rk:E=g,R=r=0}B.measure(p,E,0,0);p=B.measuredBounds;q=Math.max(p.width+q,0);r=Math.max(p.height+r,0);l=B.alignment;l.Zb()&&(l=a.defaultAlignment);l.sb()||(l=wc);var S=B.alignmentFocus;S.Zb()&&(S=wc);E=null;B instanceof W&&""!==B.$e&&(E=B.fb(B.$e),E===B&&(E=null));L=v.x-L;R=v.y-R;if(null!==E){B.arrange(0,0,p.width,p.height);p=E.actualBounds;p=I.allocAt(p.x+(S.x*p.width-S.offsetX),p.y+(S.y*p.height-S.offsetY));for(E=E.panel;E!==B;)E.transform.Ga(p),
E=E.panel;L+=l.x*f+l.offsetX-p.x;R+=l.y*g+l.offsetY-p.y;I.free(p)}else L+=l.x*f+l.offsetX-(S.x*p.width+S.offsetX),R+=l.y*g+l.offsetY-(S.y*p.height+S.offsetY);y?(y=!1,e.h(L,R,q,r)):Xb(e,L,R,q,r)}}y?e.assign(w):u?e.Vu(v.x,v.y,v.width,v.height):Xb(e,w.x,w.y,w.width,w.height);N.free(w);N.free(v);l=k.stretch;l===Sk&&(l=kl(k,!1));switch(l){case kg:return;case nd:if(!isFinite(b)&&!isFinite(c))return;break;case Qk:if(!isFinite(b))return;break;case Rk:if(!isFinite(c))return}p=k.measuredBounds;f=p.width;g=
p.height;q=Math.max(f+m,0);r=Math.max(g+n,0);l=k.margin;v=N.allocAt(-l.left,-l.top,q,r);Sm(k,v,!0);f=v.width;g=v.height;for(b=0;b<h;b++)c=d[b],c===k||!c.visible&&c!==z||(l=c.margin,q=l.right+l.left,r=l.top+l.bottom,p=c.measuredBounds,q=Math.max(p.width+q,0),r=Math.max(p.height+r,0),m=c.alignment,m.Zb()&&(m=a.defaultAlignment),m.sb()||(m=wc),c=c.alignmentFocus,c.Zb()&&(c=wc),y?(y=!1,e.h(m.x*f+m.offsetX-(c.x*p.width+c.offsetX)-l.left,m.y*g+m.offsetY-(c.y*p.height+c.offsetY)-l.top,q,r)):Xb(e,m.x*f+m.offsetX-
(c.x*p.width+c.offsetX)-l.left,m.y*g+m.offsetY-(c.y*p.height+c.offsetY)-l.top,q,r));y?e.assign(w):u?e.Vu(v.x,v.y,v.width,v.height):Xb(e,w.x,w.y,w.width,w.height);N.free(v)};
Rm.prototype.arrange=function(a,b,c){var d=b.length,e=a.Qb(),f=e.measuredBounds,g=f.width,h=f.height,k=a.padding;f=k.left;k=k.top;var l=f-c.x,m=k-c.y;e.arrange(l,m,g,h);g=N.allocAt(0,0,g,h);Sm(e,g,!1);for(h=0;h<d;h++){var n=b[h];if(n!==e){l=n.measuredBounds;var p=l.width,q=l.height;m=n.alignment;m.Zb()&&(m=a.defaultAlignment);m.sb()||(m=wc);var r=n.alignmentFocus;r.Zb()&&(r=wc);l=null;n instanceof W&&""!==n.$e&&(l=n.fb(n.$e),l===n&&(l=null));if(null!==l){var u=l.actualBounds;r=I.allocAt(u.x+(r.x*
u.width-r.offsetX),u.y+(r.y*u.height-r.offsetY));for(l=l.panel;l!==n;)l.transform.Ga(r),l=l.panel;l=m.x*g.width+m.offsetX-r.x;m=m.y*g.height+m.offsetY-r.y;I.free(r)}else l=m.x*g.width+m.offsetX-(r.x*p+r.offsetX),m=m.y*g.height+m.offsetY-(r.y*q+r.offsetY);l+=g.x-c.x;m+=g.y-c.y;n.visible&&n.arrange(f+l,k+m,p,q)}}N.free(g)};
function Sm(a,b,c){var d=null;a instanceof W&&""!==a.$e&&(d=a.fb(a.$e),d===a&&(d=null));if(null!==d)for(c&&a.arrange(0,0,b.width,b.height),b.assign(d.actualBounds),d=d.panel;d!==a;)d.transform.pp(b),d=d.panel}function Tm(){this.xa="Base"}la(Tm,dm);
Tm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length,k=a.Qb(),l=k.margin,m=b,n=c,p=l.right+l.left,q=l.top+l.bottom;k.measure(b,c,f,g);l=k.measuredBounds;var r=0,u=null;k instanceof Bf&&(u=k,r=u.strokeWidth*u.scale);var w=Math.max(l.width+p,0);l=Math.max(l.height+q,0);var v=Um(k),y=v.x*w+v.offsetX;v=v.y*l+v.offsetY;var z=Vm(k),A=z.x*w+z.offsetX;z=z.y*l+z.offsetY;isFinite(b)&&(m=Math.max(Math.abs(y-A)-r,0));isFinite(c)&&(n=Math.max(Math.abs(v-z)-r,0));r=M.alloc();r.h(0,0);a=Om(a);for(z=0;z<h;z++)v=
d[z],v===k||!v.visible&&v!==a||(l=v.margin,w=l.right+l.left,y=l.top+l.bottom,v.measure(m,n,0,0),l=v.measuredBounds,w=Math.max(l.width+w,0),l=Math.max(l.height+y,0),r.h(Math.max(w,r.width),Math.max(l,r.height)));if(1===h)e.width=w,e.height=l,M.free(r);else{v=Um(k);z=Vm(k);h=d=0;z.x!==v.x&&z.y!==v.y&&(d=r.width/Math.abs(z.x-v.x),h=r.height/Math.abs(z.y-v.y));M.free(r);r=0;null!==u&&(r=u.strokeWidth*u.scale,lg(u)===mg&&(d=h=Math.max(d,h)));d+=Math.abs(v.offsetX)+Math.abs(z.offsetX)+r;h+=Math.abs(v.offsetY)+
Math.abs(z.offsetY)+r;u=k.stretch;u===Sk&&(u=kl(k,!1));switch(u){case kg:g=f=0;break;case nd:isFinite(b)&&(d=b);isFinite(c)&&(h=c);break;case Qk:isFinite(b)&&(d=b);g=0;break;case Rk:f=0,isFinite(c)&&(h=c)}k.Tj();k.measure(d,h,f,g);e.width=k.measuredBounds.width+p;e.height=k.measuredBounds.height+q}};
Tm.prototype.arrange=function(a,b){var c=b.length,d=a.Qb(),e=d.measuredBounds,f=N.alloc();f.h(0,0,1,1);var g=d.margin,h=g.left;g=g.top;var k=a.padding,l=k.left;k=k.top;d.arrange(l+h,k+g,e.width,e.height);var m=Um(d),n=Vm(d),p=m.y*e.height+m.offsetY,q=n.x*e.width+n.offsetX;n=n.y*e.height+n.offsetY;f.x=m.x*e.width+m.offsetX;f.y=p;Xb(f,q,n,0,0);f.x+=h+l;f.y+=g+k;for(e=0;e<c;e++)h=b[e],h!==d&&(l=h.measuredBounds,g=h.margin,k=Math.max(l.width+g.right+g.left,0),m=Math.max(l.height+g.top+g.bottom,0),p=h.alignment,
p.Zb()&&(p=a.defaultAlignment),p.sb()||(p=wc),k=f.width*p.x+p.offsetX-k*p.x+g.left+f.x,g=f.height*p.y+p.offsetY-m*p.y+g.top+f.y,h.visible&&(Yb(f.x,f.y,f.width,f.height,k,g,l.width,l.height)?h.arrange(k,g,l.width,l.height):h.arrange(k,g,l.width,l.height,new N(f.x,f.y,f.width,f.height))));N.free(f)};function Wm(){this.xa="Base";this.name="Table"}la(Wm,dm);
Wm.prototype.measure=function(a,b,c,d,e){for(var f=d.length,g=Ea(),h=Ea(),k=0;k<f;k++){var l=d[k],m=l instanceof W?l:null;if(null!==m&&ol(m)&&l.visible){h.push(m);for(var n=m.O.s,p=n.length,q=0;q<p;q++){var r=n[q];m.type===W.TableRow?r.row=l.row:m.type===W.TableColumn&&(r.column=l.column);g.push(r)}}else g.push(l)}f=g.length;0===f&&(a.Gb(0),a.Fb(0));for(var u=[],w=0;w<f;w++){var v=g[w];v.visible&&(uj(v,!0),pl(v,!0),u[v.row]||(u[v.row]=[]),u[v.row][v.column]||(u[v.row][v.column]=[]),u[v.row][v.column].push(v))}Ga(g);
var y=Ea(),z=Ea(),A=Ea(),B={count:0},E={count:0},L=b,R=c,S=a.rowDefs,J=a.colDefs,G=null;f=S.length;for(var ca=0;ca<f;ca++)G=S[ca],void 0!==G&&(G.actual=0);f=J.length;for(var X=0;X<f;X++)G=J[X],void 0!==G&&(G.actual=0);for(var na=u.length,wa=0,eb=0;eb<na;eb++)u[eb]&&(wa=Math.max(wa,u[eb].length));var gb=Math.min(a.topIndex,na-1),Eb=Math.min(a.leftIndex,wa-1),fb=0;na=u.length;for(var Qe=Om(a),Va=0;Va<na;Va++)if(u[Va]){wa=u[Va].length;var ud=a.Gb(Va);ud.actual=0;for(var Fb=ud.pc=0;Fb<wa;Fb++)if(u[Va][Fb]){var Re=
a.Fb(Fb);void 0===y[Fb]&&(Re.actual=0,Re.pc=0,y[Fb]=!0);for(var Ac=u[Va][Fb],Wc=Ac.length,mc=0;mc<Wc;mc++){var dc=Ac[mc];if(dc.visible||dc===Qe){var Se=1<dc.rowSpan,vd=1<dc.columnSpan;if(Se||vd)Va<gb||Fb<Eb||z.push(dc);var kf=dc.margin,nc=kf.right+kf.left,dl=kf.top+kf.bottom;var Gb=Tl(dc,ud,Re,!1);var Nf=dc.desiredSize,Ed=!isNaN(Nf.height),oc=!isNaN(Nf.width)&&Ed;Gb===kg||oc||Va<gb||Fb<Eb||(vd||void 0!==B[Fb]||Gb!==nd&&Gb!==Qk||(B[Fb]=-1,B.count++),Se||void 0!==E[Va]||Gb!==nd&&Gb!==Rk||(E[Va]=-1,
E.count++),Se&&vd||A.push(dc));dc.measure(Infinity,Infinity,0,0);if(!(Va<gb||Fb<Eb)){var lf=dc.measuredBounds,pc=Math.max(lf.width+nc,0),Cg=Math.max(lf.height+dl,0);if(1===dc.rowSpan&&(Gb===kg||Gb===Qk)){G=a.Gb(Va);var Hh=G.Wc(),el=0===G.oa;fb=Math.max(Cg-G.actual,0);fb+(el?Hh:0)>R&&(fb=Math.max(R-Hh,0));G.pc=G.pc+fb;G.actual=G.oa+fb;R=Math.max(R-(fb+(el?Hh:0)),0)}if(1===dc.columnSpan&&(Gb===kg||Gb===Rk)){G=a.Fb(Fb);var Wi=G.Wc(),Xi=0===G.oa;fb=Math.max(pc-G.actual,0);fb+(Xi?Wi:0)>L&&(fb=Math.max(L-
Wi,0));G.pc=G.pc+fb;G.actual=G.oa+fb;L=Math.max(L-(fb+(Xi?Wi:0)),0)}(Se||vd)&&dc.Tj()}}}}}Ga(y);var re=0,Fd=0;f=a.columnCount;for(var Ih=0;Ih<f;Ih++){var mf=J[Ih];void 0!==mf&&(re+=isNaN(mf.Hc)?mf.pc:mf.Hc,0!==mf.pc&&(re+=mf.Wc()))}f=a.rowCount;for(var Jh=0;Jh<f;Jh++){var kd=S[Jh];void 0!==kd&&(Fd+=isNaN(kd.Hc)?kd.pc:kd.Hc,0!==kd.pc&&(Fd+=kd.Wc()))}L=Math.max(b-re,0);var Te=R=Math.max(c-Fd,0),ec=L;f=A.length;for(var Kh=0;Kh<f;Kh++){var Gd=A[Kh],se=a.Gb(Gd.row),Yi=a.Fb(Gd.column),Wd=Gd.measuredBounds,
te=Gd.margin,Hd=te.right+te.left,Zi=te.top+te.bottom;B[Gd.column]=0===Yi.pc&&void 0!==B[Gd.column]?Math.max(Wd.width+Hd,B[Gd.column]):null;E[Gd.row]=0===se.pc&&void 0!==E[Gd.row]?Math.max(Wd.height+Zi,E[Gd.row]):null}var Lh=0,Xd=0,nf;for(nf in E)"count"!==nf&&(Lh+=E[nf]);for(nf in B)"count"!==nf&&(Xd+=B[nf]);for(var jb=M.alloc(),Of=0;Of<f;Of++){var Sa=A[Of];if(Sa.visible||Sa===Qe){var Tb=a.Gb(Sa.row),qc=a.Fb(Sa.column),Ue=0;isFinite(qc.width)?Ue=qc.width:(isFinite(L)&&null!==B[Sa.column]?0===Xd?Ue=
qc.actual+L:Ue=B[Sa.column]/Xd*ec:null!==B[Sa.column]?Ue=L:Ue=qc.actual||L,Ue=Math.max(0,Ue-qc.Wc()));var Yd=0;isFinite(Tb.height)?Yd=Tb.height:(isFinite(R)&&null!==E[Sa.row]?0===Lh?Yd=Tb.actual+R:Yd=E[Sa.row]/Lh*Te:null!==E[Sa.row]?Yd=R:Yd=Tb.actual||R,Yd=Math.max(0,Yd-Tb.Wc()));jb.h(Math.max(qc.minimum,Math.min(Ue,qc.maximum)),Math.max(Tb.minimum,Math.min(Yd,Tb.maximum)));Gb=Tl(Sa,Tb,qc,!1);switch(Gb){case Qk:jb.height=Math.max(jb.height,Tb.actual+R);break;case Rk:jb.width=Math.max(jb.width,qc.actual+
L)}var Mh=Sa.margin,fl=Mh.right+Mh.left,$i=Mh.top+Mh.bottom;Sa.Tj();Sa.measure(jb.width,jb.height,qc.minimum,Tb.minimum);var Nh=Sa.measuredBounds,Dg=Math.max(Nh.width+fl,0),fc=Math.max(Nh.height+$i,0);isFinite(L)&&(Dg=Math.min(Dg,jb.width));isFinite(R)&&(fc=Math.min(fc,jb.height));var of=0;of=Tb.actual;Tb.actual=Math.max(Tb.actual,fc);Tb.pc=Math.max(Tb.pc,fc);fb=Tb.actual-of;R=Math.max(R-fb,0);null===E[Sa.row]&&(Te=Math.max(Te-fb,0));of=qc.actual;qc.actual=Math.max(qc.actual,Dg);qc.pc=Math.max(qc.pc,
Dg);fb=qc.actual-of;L=Math.max(L-fb,0);null===B[Sa.column]&&(ec=Math.max(ec-fb,0))}}Ga(A);var Id=M.alloc(),Ic=Ea(),ue=Ea();f=z.length;if(0!==f)for(var Zd=0;Zd<na;Zd++)if(u[Zd]){wa=u[Zd].length;var aj=a.Gb(Zd);Ic[Zd]=aj.actual;for(var Pf=0;Pf<wa;Pf++)if(u[Zd][Pf]){var gl=a.Fb(Pf);ue[Pf]=gl.actual}}for(var Oh=0;Oh<f;Oh++){var Oa=z[Oh];if(Oa.visible||Oa===Qe){var Xc=a.Gb(Oa.row),$d=a.Fb(Oa.column);jb.h(Math.max($d.minimum,Math.min(b,$d.maximum)),Math.max(Xc.minimum,Math.min(c,Xc.maximum)));Gb=Tl(Oa,
Xc,$d,!1);switch(Gb){case nd:0!==ue[$d.index]&&(jb.width=Math.min(jb.width,ue[$d.index]));0!==Ic[Xc.index]&&(jb.height=Math.min(jb.height,Ic[Xc.index]));break;case Qk:0!==ue[$d.index]&&(jb.width=Math.min(jb.width,ue[$d.index]));break;case Rk:0!==Ic[Xc.index]&&(jb.height=Math.min(jb.height,Ic[Xc.index]))}isFinite($d.width)&&(jb.width=$d.width);isFinite(Xc.height)&&(jb.height=Xc.height);Id.h(0,0);for(var rc=$d.minimum,Ph=Xc.minimum,Yc=1;Yc<Oa.rowSpan&&!(Oa.row+Yc>=a.rowCount);Yc++)G=a.Gb(Oa.row+Yc),
fb=0,fb=Gb===nd||Gb===Rk?Math.max(G.minimum,0===Ic[Oa.row+Yc]?G.maximum:Math.min(Ic[Oa.row+Yc],G.maximum)):Math.max(G.minimum,isNaN(G.Hc)?G.maximum:Math.min(G.Hc,G.maximum)),Id.height+=fb,Ph+=G.minimum;for(var Qf=1;Qf<Oa.columnSpan&&!(Oa.column+Qf>=a.columnCount);Qf++)G=a.Fb(Oa.column+Qf),fb=0,fb=Gb===nd||Gb===Qk?Math.max(G.minimum,0===ue[Oa.column+Qf]?G.maximum:Math.min(ue[Oa.column+Qf],G.maximum)):Math.max(G.minimum,isNaN(G.Hc)?G.maximum:Math.min(G.Hc,G.maximum)),Id.width+=fb,rc+=G.minimum;jb.width+=
Id.width;jb.height+=Id.height;var ae=Oa.margin,bj=ae.right+ae.left,cj=ae.top+ae.bottom;Oa.measure(jb.width,jb.height,rc,Ph);for(var be=Oa.measuredBounds,Rf=Math.max(be.width+bj,0),Sf=Math.max(be.height+cj,0),Qh=0,pf=0;pf<Oa.rowSpan&&!(Oa.row+pf>=a.rowCount);pf++)G=a.Gb(Oa.row+pf),Qh+=G.total||0;if(Qh<Sf){var Zc=Sf-Qh,Ve=Sf-Qh;if(null!==Oa.spanAllocation)for(var Tf=Oa.spanAllocation,ve=0;ve<Oa.rowSpan&&!(0>=Zc)&&!(Oa.row+ve>=a.rowCount);ve++){G=a.Gb(Oa.row+ve);var We=G.oa||0,we=Tf(Oa,G,Ve);G.actual=
Math.min(G.maximum,We+we);G.oa!==We&&(Zc-=G.oa-We)}for(;0<Zc;){var Eg=G.oa||0;isNaN(G.height)&&G.maximum>Eg&&(G.actual=Math.min(G.maximum,Eg+Zc),G.oa!==Eg&&(Zc-=G.oa-Eg));if(0===G.index)break;G=a.Gb(G.index-1)}}for(var Xe=0,Rh=0;Rh<Oa.columnSpan&&!(Oa.column+Rh>=a.columnCount);Rh++)G=a.Fb(Oa.column+Rh),Xe+=G.total||0;if(Xe<Rf){var Fg=Rf-Xe,xe=Rf-Xe;if(null!==Oa.spanAllocation)for(var dj=Oa.spanAllocation,Sh=0;Sh<Oa.columnSpan&&!(0>=Fg)&&!(Oa.column+Sh>=a.columnCount);Sh++){G=a.Fb(Oa.column+Sh);var ej=
G.oa||0,xu=dj(Oa,G,xe);G.actual=Math.min(G.maximum,ej+xu);G.oa!==ej&&(Fg-=G.oa-ej)}for(;0<Fg;){var hl=G.oa||0;isNaN(G.width)&&G.maximum>hl&&(G.actual=Math.min(G.maximum,hl+Fg),G.oa!==hl&&(Fg-=G.oa-hl));if(0===G.index)break;G=a.Fb(G.index-1)}}}}Ga(z);M.free(Id);M.free(jb);void 0!==Ic&&Ga(Ic);void 0!==ue&&Ga(ue);var Gg=0,Hg=0,il=a.desiredSize,Mr=a.maxSize;Gb=kl(a,!0);var fj=Fd=re=0,gj=0;f=a.columnCount;for(var jl=0;jl<f;jl++)void 0!==J[jl]&&(G=a.Fb(jl),isFinite(G.width)?(fj+=G.width,fj+=G.Wc()):Xm(G)===
Ym?(fj+=G.oa,fj+=G.Wc()):0!==G.oa&&(re+=G.oa,re+=G.Wc()));isFinite(il.width)?Gg=Math.min(il.width,Mr.width):Gg=Gb!==kg&&isFinite(b)?b:re;Gg=Math.max(Gg,a.minSize.width);Gg=Math.max(Gg-fj,0);for(var yu=0===re?1:Math.max(Gg/re,1),ll=0;ll<f;ll++)void 0!==J[ll]&&(G=a.Fb(ll),isFinite(G.width)||Xm(G)===Ym||(G.actual=G.oa*yu),G.position=e.width,0!==G.oa&&(e.width+=G.oa,e.width+=G.Wc()));f=a.rowCount;for(var ml=0;ml<f;ml++)void 0!==S[ml]&&(G=a.Gb(ml),isFinite(G.height)?(gj+=G.height,gj+=G.Wc()):Xm(G)===Ym?
(gj+=G.oa,gj+=G.Wc()):0!==G.oa&&(Fd+=G.oa,Fd+=G.Wc()));isFinite(il.height)?Hg=Math.min(il.height,Mr.height):Hg=Gb!==kg&&isFinite(c)?c:Fd;Hg=Math.max(Hg,a.minSize.height);Hg=Math.max(Hg-gj,0);for(var zu=0===Fd?1:Math.max(Hg/Fd,1),nl=0;nl<f;nl++)void 0!==S[nl]&&(G=a.Gb(nl),isFinite(G.height)||Xm(G)===Ym||(G.actual=G.oa*zu),G.position=e.height,0!==G.oa&&(e.height+=G.oa,e.height+=G.Wc()));f=h.length;for(var Co=0;Co<f;Co++){var hj=h[Co],Do=0,Eo=0;hj.type===W.TableRow?(Do=e.width,G=a.Gb(hj.row),Eo=G.actual):
(G=a.Fb(hj.column),Do=G.actual,Eo=e.height);hj.measuredBounds.h(0,0,Do,Eo);uj(hj,!1)}Ga(h);a.inRowsCols=u};
Wm.prototype.arrange=function(a,b,c){var d=b.length,e=a.padding,f=e.left;e=e.top;var g=a.inRowsCols;if(null!==g){for(var h,k,l=g.length,m=0,n=0;n<l;n++)g[n]&&(m=Math.max(m,g[n].length));k=a.rowDefs;h=a.colDefs;var p=n=0,q=Math.min(a.topIndex,l-1);if(0<k.length){for(;q!==l&&(void 0===k[q]||0===k[q].oa);)q++;q=Math.max(Math.min(q,l-1),0);n=-k[q].position}var r=Math.min(a.leftIndex,m-1);if(0<h.length){for(;r!==m&&(void 0===h[r]||0===h[r].oa);)r++;r=Math.max(Math.min(r,m-1),0);p=-h[r].position}for(var u=
0,w=0;u!==l&&void 0===k[u];)u++;for(;w!==l&&void 0===h[w];)w++;m=a.part;a.Gb(u);a.Fb(w);for(var v=0;v<b.length;v++){var y=b[v];if(y instanceof W&&y.visible&&ol(y)){if(y.type===W.TableRow){h=a.Gb(y.row);var z=a.Fb(w)}else h=a.Gb(u),z=a.Fb(y.column);k=h.position+n+e;0!==h.oa&&(k+=h.Io(Math.max(u,q)));h=z.position+p+f;0!==z.oa&&(h+=z.Io(Math.max(w,r)));z=y.measuredBounds;y.Tk();y.actualBounds.ka();var A=y.actualBounds,B=N.allocAt(A.x,A.y,A.width,A.height);A.x=y.type===W.TableRow?f:h;A.y=y.type===W.TableColumn?
e:k;A.width=z.width;A.height=z.height;y.actualBounds.freeze();pl(y,!1);Sb(B,A)||null===m||(m.oh(),y.Pn(m));N.free(B)}}v=M.alloc();for(y=0;y<l;y++)if(g[y])for(m=g[y].length,z=a.Gb(y),k=z.position+n+e,0!==z.oa&&(k+=z.Io(Math.max(u,q))),A=0;A<m;A++)if(g[y][A]){B=a.Fb(A);h=B.position+p+f;0!==B.oa&&(h+=B.Io(Math.max(w,r)));for(var E=g[y][A],L=E.length,R=0;R<L;R++){var S=E[R],J=S.measuredBounds;v.h(0,0);for(var G=1;G<S.rowSpan&&!(y+G>=a.rowCount);G++){var ca=a.Gb(y+G);0!==ca.oa&&(v.height+=ca.total)}for(G=
1;G<S.columnSpan&&!(A+G>=a.columnCount);G++)ca=a.Fb(A+G),0!==ca.oa&&(v.width+=ca.total);var X=B.oa+v.width,na=z.oa+v.height;G=h;ca=k;var wa=X,eb=na,gb=h,Eb=k,fb=X,Qe=na;h+X>c.width&&(fb=Math.max(c.width-h,0));k+na>c.height&&(Qe=Math.max(c.height-k,0));var Va=S.alignment;if(Va.Zb()){Va=a.defaultAlignment;Va.sb()||(Va=wc);var ud=Va.x;var Fb=Va.y;var Re=Va.offsetX;Va=Va.offsetY;var Ac=B.alignment,Wc=z.alignment;Ac.sb()&&(ud=Ac.x,Re=Ac.offsetX);Wc.sb()&&(Fb=Wc.y,Va=Wc.offsetY)}else ud=Va.x,Fb=Va.y,Re=
Va.offsetX,Va=Va.offsetY;if(isNaN(ud)||isNaN(Fb))Fb=ud=.5,Va=Re=0;Ac=J.width;Wc=J.height;var mc=S.margin,dc=mc.left+mc.right,Se=mc.top+mc.bottom,vd=Tl(S,z,B,!1);!isNaN(S.desiredSize.width)||vd!==nd&&vd!==Qk||(Ac=Math.max(X-dc,0));!isNaN(S.desiredSize.height)||vd!==nd&&vd!==Rk||(Wc=Math.max(na-Se,0));X=S.maxSize;na=S.minSize;Ac=Math.min(X.width,Ac);Wc=Math.min(X.height,Wc);Ac=Math.max(na.width,Ac);Wc=Math.max(na.height,Wc);X=Wc+Se;G+=wa*ud-(Ac+dc)*ud+Re+mc.left;ca+=eb*Fb-X*Fb+Va+mc.top;S.visible&&
(Yb(gb,Eb,fb,Qe,G,ca,J.width,J.height)?S.arrange(G,ca,Ac,Wc):S.arrange(G,ca,Ac,Wc,new N(gb,Eb,fb,Qe)))}}M.free(v);for(a=0;a<d;a++)c=b[a],f=c instanceof W?c:null,null!==f&&ol(f)&&(f=c.actualBounds,c.naturalBounds.ka(),c.naturalBounds.h(0,0,f.width,f.height),c.naturalBounds.freeze())}};function Zm(){this.xa="Base"}la(Zm,dm);Zm.prototype.measure=function(){};Zm.prototype.arrange=function(){};function $m(){this.xa="Base"}la($m,dm);$m.prototype.measure=function(){};$m.prototype.arrange=function(){};
function an(){this.xa="Base"}la(an,dm);
an.prototype.measure=function(a,b,c,d,e,f,g){1<d.length&&C("Viewbox Panel cannot contain more than one GraphObject.");d=d[0];d.Ca=1;d.Tj();d.measure(Infinity,Infinity,f,g);var h=d.measuredBounds,k=d.margin,l=k.right+k.left;k=k.top+k.bottom;if(isFinite(b)||isFinite(c)){var m=d.scale,n=h.width;h=h.height;var p=Math.max(b-l,0),q=Math.max(c-k,0),r=1;a.viewboxStretch===mg?0!==n&&0!==h&&(r=Math.min(p/n,q/h)):0!==n&&0!==h&&(r=Math.max(p/n,q/h));0===r&&(r=1E-4);d.Ca*=r;m!==d.scale&&(uj(d,!0),d.measure(Infinity,
Infinity,f,g))}h=d.measuredBounds;e.width=isFinite(b)?b:Math.max(h.width+l,0);e.height=isFinite(c)?c:Math.max(h.height+k,0)};an.prototype.arrange=function(a,b,c){b=b[0];var d=b.measuredBounds,e=b.margin,f=Math.max(d.width+(e.right+e.left),0);e=Math.max(d.height+(e.top+e.bottom),0);var g=b.alignment;g.Zb()&&(g=a.defaultAlignment);g.sb()||(g=wc);b.arrange(c.width*g.x-f*g.x+g.offsetX,c.height*g.y-e*g.y+g.offsetY,d.width,d.height)};function bn(){this.xa="Base"}la(bn,dm);bn.prototype.measure=function(){};
bn.prototype.arrange=function(){};
bn.prototype.hc=function(a,b,c){c=a.Df()*c.scale;0>=c&&(c=1);var d=a.gridCellSize,e=d.width;d=d.height;var f=a.naturalBounds,g=a.actualBounds,h=f.width,k=f.height,l=Math.ceil(h/e),m=Math.ceil(k/d),n=a.gridOrigin,p=n.x;n=n.y;b.save();b.beginPath();b.rect(0,0,h,k);b.clip();b.Se();for(var q=[],r=a.O.s,u=r.length,w=0;w<u;w++){var v=r[w],y=[];q.push(y);if(v.visible){var z=v.interval;if(!(0>z)){v=ek(v.figure);for(var A=0;A<u;A++)if(A!==w){var B=r[A];B.visible&&ek(B.figure)===v&&(B=B.interval,B>z&&y.push(B))}}}}r=
a.O.s;u=r.length;for(w=0;w<u;w++){var E=r[w];if(E.visible&&(y=E.interval||1,v=Math.abs(y),!(2>e*v*c))){z=E.segmentIndex;if(!isFinite(z)||isNaN(z))z=0;0>z&&(z=v-Math.min(-z,v));z=Math.round(z%v);v=E.opacity;A=1;if(1!==v){if(0===v)continue;A=b.globalAlpha;b.globalAlpha=A*v}B=q[w];var L=!1,R=E.strokeDashArray;null!==R&&(L=!0,b.js(R,E.strokeDashOffset));if("LineV"===E.figure&&null!==E.stroke&&0<E.strokeWidth){b.lineWidth=E.strokeWidth;ji(a,b,E.stroke,!1,!1,f,g);b.beginPath();for(R=E=Math.floor((p-e)/
e);R<=E+l;R++){var S=R*e+p;0<=S&&S<=h&&cn(R,y,z,B)&&(b.moveTo(S,0),b.lineTo(S,k))}b.stroke();b.Se()}else if("LineH"===E.figure&&null!==E.stroke&&0<E.strokeWidth){b.lineWidth=E.strokeWidth;ji(a,b,E.stroke,!1,!1,f,g);b.beginPath();for(R=E=Math.floor((n-d)/d);R<=E+m;R++)S=R*d+n,0<=S&&S<=k&&cn(R,y,z,B)&&(b.moveTo(0,S),b.lineTo(h,S));b.stroke();b.Se()}else if("BarV"===E.figure&&null!==E.fill)for(ji(a,b,E.fill,!0,!1,f,g),E=E.width,isNaN(E)&&(E=e),S=R=Math.floor((p-e)/e);S<=R+l;S++){var J=S*e+p;0<=J+E&&
J<=h&&cn(S,y,z,B)&&b.fillRect(J,0,E,k)}else if("BarH"===E.figure&&null!==E.fill)for(ji(a,b,E.fill,!0,!1,f,g),E=E.height,isNaN(E)&&(E=d),S=R=Math.floor((n-d)/d);S<=R+m;S++)J=S*d+n,0<=J+E&&J<=k&&cn(S,y,z,B)&&b.fillRect(0,J,h,E);L&&b.fs();1!==v&&(b.globalAlpha=A)}}b.restore();b.Vc(!1)};function cn(a,b,c,d){if(0>b)return a%b===c;if(a%b!==c)return!1;b=d.length;for(var e=0;e<b;e++)if(a%d[e]===c)return!1;return!0}function dn(){this.xa="Base"}la(dn,dm);
dn.prototype.measure=function(a,b,c,d,e){var f=d.length;if(a instanceof pe||a instanceof T){var g=null,h=null;a instanceof T&&(g=a);a instanceof pe&&(h=a,g=h.adornedPart,null===a.j&&(a.j=[]));if(g instanceof T){var k=g;if(0===f)Pb(a.naturalBounds,0,0),a.measuredBounds.h(0,0,0,0);else{var l=a instanceof pe?null:g.path;b=g.routeBounds;c=a.jg;c.h(0,0,b.width,b.height);var m=k.points,n=g.pointsCount;null!==h?h.Ul(!1):null!==g&&g.Ul(!1);h=b.width;var p=b.height,q=a.j;q.length=0;if(null!==l){en(a,h,p,l);
var r=l.measuredBounds;c.ed(r);q.push(r)}r=ld.alloc();for(var u=I.alloc(),w=I.alloc(),v=0;v<f;v++){var y=d[v];if(y!==l)if(y.isPanelMain&&y instanceof Bf){en(a,h,p,y);var z=y.measuredBounds;c.ed(z);q.push(z)}else if(2>n)y.measure(Infinity,Infinity,0,0),z=y.measuredBounds,c.ed(z),q.push(z);else{var A=y.segmentIndex,B=y.segmentFraction,E=y.alignmentFocus;E.Mc()&&(E=wc);var L=y.segmentOrientation,R=y.segmentOffset;if(isNaN(A)){var S=k.ua;z=Ea();S.Ru(B,z);var J=I.allocAt(z[0],z[1]);J.offset(b.x,b.y);S=
z[2];if(L!==Zf){var G=k.computeAngle(y,L,S);y.Lb=G}G=J.x-b.x;var ca=J.y-b.y;I.free(J);Ga(z)}else if(A<-n||A>=n)z=I.alloc(),k.vg(),k.computeMidPoint(z),S=k.midAngle,L!==Zf&&(G=k.computeAngle(y,L,S),y.Lb=G),G=z.x-b.x,ca=z.y-b.y,I.free(z);else{G=0;0<=A?(ca=m.M(A),z=A<n-1?m.M(A+1):ca):(G=n+A,ca=m.M(G),z=0<G?m.M(G-1):ca);if(ca.Xa(z)){0<=A?(S=0<A?m.M(A-1):ca,G=A<n-2?m.M(A+2):z):(S=G<n-1?m.M(G+1):ca,G=1<G?m.M(G-2):z);J=S.Bf(ca);var X=z.Bf(G);S=J>X+10?0<=A?S.ab(ca):ca.ab(S):X>J+10?0<=A?z.ab(G):G.ab(z):0<=
A?S.ab(G):G.ab(S)}else S=0<=A?ca.ab(z):z.ab(ca);L!==Zf&&(G=k.computeAngle(y,L,S),y.Lb=G);G=ca.x+(z.x-ca.x)*B-b.x;ca=ca.y+(z.y-ca.y)*B-b.y}y.measure(Infinity,Infinity,0,0);z=y.measuredBounds;J=y.naturalBounds;var na=0;y instanceof Bf&&(na=y.strokeWidth);X=J.width+na;var wa=J.height+na;r.reset();r.translate(-z.x,-z.y);r.scale(y.scale,y.scale);r.rotate(L===Zf?y.angle:S,X/2,wa/2);L!==fn&&L!==gn||r.rotate(90,X/2,wa/2);L!==hn&&L!==jn||r.rotate(-90,X/2,wa/2);L===kn&&(45<S&&135>S||225<S&&315>S)&&r.rotate(-S,
X/2,wa/2);J=new N(0,0,X,wa);u.xi(J,E);r.Ga(u);E=-u.x+na/2*y.scale;y=-u.y+na/2*y.scale;w.assign(R);na=isNaN(R.x);var eb=isNaN(R.y);if(na||eb){X=X/2+3;wa=wa/2+3;var gb=45<=S&&135>=S,Eb=225<=S&&315>=S;L===Zf&&(gb||Eb)?(w.x=eb?X:R.y,w.y=na?wa:R.x,gb?0<=A||isNaN(A)&&.5>B||!na||(w.y=-wa):Eb&&((0<=A||isNaN(A)&&.5>B)&&na&&(w.y=-wa),eb&&(w.x=-X))):(na&&(w.x=0<=A||isNaN(A)&&.5>B?X:-X),eb&&(w.y=-wa),w.rotate(S))}else w.rotate(S);G+=w.x;ca+=w.y;J.set(z);J.h(G+E,ca+y,z.width,z.height);q.push(J);c.ed(J)}}if(null!==
g)for(d=g.labelNodes;d.next();)d.value.measure(Infinity,Infinity);a.jg=c;a.position.h(b.x+c.x,b.y+c.y);Pb(e,c.width||0,c.height||0);ld.free(r);I.free(u);I.free(w)}}}};
dn.prototype.arrange=function(a,b){var c=b.length;if(a instanceof pe||a instanceof T){var d=a,e=null;a instanceof pe&&(e=a,d=e.adornedPart);var f=a instanceof pe?null:d.path,g=a.j;if(0!==g.length){var h=0;if(null!==f&&h<g.length){var k=g[h];h++;f.arrange(k.x-a.jg.x,k.y-a.jg.y,k.width,k.height)}for(k=0;k<c;k++){var l=b[k];if(l!==f&&h<g.length){var m=g[h];h++;l.arrange(m.x-a.jg.x,m.y-a.jg.y,m.width,m.height)}}}b=d.points;c=b.count;if(2<=c&&a instanceof T)for(f=a.labelNodes;f.next();){var n=a;g=f.value;
h=g.segmentIndex;k=g.segmentFraction;var p=g.alignmentFocus;l=g.segmentOrientation;m=g.segmentOffset;if(isNaN(h)){var q=n.ua;var r=Ea();q.Ru(k,r);var u=I.allocAt(r[0],r[1]);u.add(n.i(0));q.type===P.o?u.offset(-q.startX,-q.startY):(q=q.figures.first(),u.offset(-q.startX,-q.startY));q=r[2];l!==Zf&&(n=n.computeAngle(g,l,q),g.angle=n);n=u.x;var w=u.y;I.free(u);Ga(r)}else if(h<-c||h>=c)r=I.alloc(),q=n,w=r,q.vg(),q.computeMidPoint(w),q=n.midAngle,l!==Zf&&(n=n.computeAngle(g,l,q),g.angle=n),n=r.x,w=r.y,
I.free(r);else{u=0;0<=h?(r=b.s[h],w=h<c-1?b.s[h+1]:r):(u=c+h,r=b.s[u],w=0<u?b.s[u-1]:r);if(r.Xa(w)){0<=h?(q=0<h?b.s[h-1]:r,u=h<c-2?b.s[h+2]:w):(q=u<c-1?b.s[u+1]:r,u=1<u?b.s[u-2]:w);var v=q.Bf(r),y=w.Bf(u);q=v>y+10?0<=h?q.ab(r):r.ab(q):y>v+10?0<=h?w.ab(u):u.ab(w):0<=h?q.ab(u):u.ab(q)}else q=0<=h?r.ab(w):w.ab(r);l!==Zf&&(n=n.computeAngle(g,l,q),g.angle=n);n=r.x+(w.x-r.x)*k;w=r.y+(w.y-r.y)*k}if(p.ys())g.location=new I(n,w);else{p.Mc()&&(p=wc);r=ld.alloc();r.reset();r.scale(g.scale,g.scale);r.rotate(g.angle,
0,0);var z=g.naturalBounds;u=N.allocAt(0,0,z.width,z.height);v=I.alloc();v.xi(u,p);r.Ga(v);p=-v.x;y=-v.y;var A=z.width,B=z.height;z=I.alloc();z.assign(m);var E=isNaN(m.x),L=isNaN(m.y);if(E||L){A=A/2+3;B=B/2+3;var R=45<=q&&135>=q,S=225<=q&&315>=q;l===Zf&&(R||S)?(z.x=L?A:m.y,z.y=E?B:m.x,R?0<=h||isNaN(h)&&.5>k||!E||(z.y=-B):S&&((0<=h||isNaN(h)&&.5>k)&&E&&(z.y=-B),L&&(z.x=-A))):(E&&(z.x=0<=h||isNaN(h)&&.5>k?A:-A),L&&(z.y=-B),z.rotate(q))}else z.rotate(q);n+=z.x;w+=z.y;r.pp(u);p+=u.x;y+=u.y;h=I.allocAt(n+
p,w+y);g.move(h);I.free(h);I.free(z);I.free(v);N.free(u);ld.free(r)}}null!==e?e.Ul(!1):d.Ul(!1);d=ln(a);a.location.h(a.position.x+d.x,a.position.y+d.y);I.free(d)}};
function en(a,b,c,d){if(!1!==zj(d)){var e=d.strokeWidth;0===e&&a instanceof pe&&a.type===W.Link&&a.adornedObject instanceof Bf&&(e=a.adornedObject.strokeWidth);e*=d.Ca;a instanceof T&&null!==a.ua?(a=a.geometry.bounds,d.Ge(a.x-e/2,a.y-e/2,a.width+e,a.height+e)):a instanceof pe&&null!==a.adornedPart.ua?(a=a.adornedPart.ua.bounds,d.Ge(a.x-e/2,a.y-e/2,a.width+e,a.height+e)):d.Ge(-(e/2),-(e/2),b+e,c+e);e=d.desiredSize;e.w()||null===d.ua?d.ec.h(0,0,e.width,e.height):d.ec.assign(d.ua.bounds);uj(d,!1)}}
function mn(){this.xa="Base"}la(mn,dm);
mn.prototype.measure=function(a,b,c,d,e,f,g){var h=a.Qb();a.graduatedBounds=[];var k=h.margin,l=k.right+k.left,m=k.top+k.bottom;h.measure(b,c,f,g);var n=h.measuredBounds,p=new N(-k.left,-k.top,Math.max(n.width+l,0),Math.max(n.height+m,0));a.graduatedBounds.push(p);e.assign(p);for(var q=h.geometry,r=h.strokeWidth,u=q.flattenedSegments,w=q.flattenedLengths,v=q.flattenedTotalLength,y=u.length,z=0,A=0,B=Ea(),E=0;E<y;E++){var L=u[E],R=[];A=z=0;for(var S=L.length,J=0;J<S;J+=2){var G=L[J],ca=L[J+1];if(0!==
J){var X=180*Math.atan2(ca-A,G-z)/Math.PI;0>X&&(X+=360);R.push(X)}z=G;A=ca}B.push(R)}if(null===a.graduatedIntervals){for(var na=[],wa=a.O.s,eb=wa.length,gb=0;gb<eb;gb++){var Eb=wa[gb],fb=[];na.push(fb);if(Eb.visible){var Qe=Eb.interval;if(!(0>Qe))for(var Va=0;Va<eb;Va++)if(Va!==gb){var ud=wa[Va];if(ud.visible&&Eb.constructor===ud.constructor){var Fb=ud.interval;Fb>Qe&&fb.push(Fb)}}}}a.graduatedIntervals=na}var Re=a.graduatedIntervals;var Ac=a.O.s,Wc=Ac.length,mc=0,dc=0,Se=v;a.graduatedMarks=[];for(var vd,
kf=0;kf<Wc;kf++){var nc=Ac[kf];vd=[];if(nc.visible&&nc!==h){var dl=Math.abs(nc.interval),Gb=a.graduatedTickUnit;if(!(2>Gb*dl*v/a.graduatedRange&&null===nc.graduatedSkip)){var Nf=w[0][0],Ed=0,oc=0;dc=v*nc.graduatedStart-1E-4;Se=v*nc.graduatedEnd+1E-4;var lf=Gb*dl,pc=a.graduatedTickBase;if(pc<a.graduatedMin){var Cg=(a.graduatedMin-pc)/lf;Cg=0===Cg%1?Cg:Math.floor(Cg+1);pc+=Cg*lf}else pc>a.graduatedMin+lf&&(pc-=Math.floor((pc-a.graduatedMin)/lf)*lf);for(var Hh=Re[kf],el=pc,Wi=1E-6*a.graduatedMax,Xi=
1;pc<=a.graduatedMax+Wi;Xi++){a:{for(var re=Hh.length,Fd=0;Fd<re;Fd++){var Ih=(pc-a.graduatedTickBase)%(Hh[Fd]*a.graduatedTickUnit),mf=1E-6*a.graduatedTickUnit;if(Ih<mf&&Ih>-mf){var Jh=!1;break a}}Jh=!0}if(Jh&&(pc>a.graduatedMax&&(pc=a.graduatedMax),null===nc.graduatedSkip||nc instanceof Ng&&!nc.graduatedSkip(pc,nc)||nc instanceof Bf&&!nc.graduatedSkip(pc,nc))&&(mc=(pc-a.graduatedMin)*v/a.graduatedRange,mc>v&&(mc=v),dc<=mc&&mc<=Se)){for(var kd=B[Ed][oc],Te=w[Ed][oc];Ed<w.length;){for(;mc>Nf&&oc<w[Ed].length-
1;)oc++,kd=B[Ed][oc],Te=w[Ed][oc],Nf+=Te;if(mc<=Nf)break;Ed++;oc=0;kd=B[Ed][oc];Te=w[Ed][oc];Nf+=Te}var ec=u[Ed],Kh=ec[2*oc],Gd=ec[2*oc+1],se=(mc-(Nf-Te))/Te,Yi=new I(Kh+(ec[2*oc+2]-Kh)*se+r/2-q.bounds.x,Gd+(ec[2*oc+3]-Gd)*se+r/2-q.bounds.y);Yi.scale(h.scale,h.scale);a:{var Wd=kd,te=B[Ed];if(!(.5005>se&&.4995<se)){var Hd=Wd;.5>se?0<oc?Hd=te[oc-1]:K.$(ec[0],ec[ec.length-2])&&K.$(ec[1],ec[ec.length-1])&&(Hd=te[te.length-1]):.5<se&&(oc+1<te.length?Hd=te[oc+1]:K.$(ec[0],ec[ec.length-2])&&K.$(ec[1],ec[ec.length-
1])&&(Hd=te[0]));if(Wd!==Hd){var Zi=Math.abs(Wd-Hd);180<Zi&&(Wd<Hd?Wd+=360:Hd+=360,Zi=Math.abs(Wd-Hd));if(5E-4>se||.9995<se){kd=(Wd+Hd)/2%360;break a}if(10>Zi){var Lh=1-Math.abs(.5-se);kd=(Wd*Lh+Hd*(1-Lh))%360;break a}}}kd=Wd}if(nc instanceof Ng){var Xd="";null!==nc.graduatedFunction?(Xd=nc.graduatedFunction(pc,nc),Xd=null!==Xd&&void 0!==Xd?Xd.toString():""):Xd=(+pc.toFixed(2)).toString();""!==Xd&&vd.push({$l:Yi,angle:kd,text:Xd})}else vd.push({$l:Yi,angle:kd})}pc=el+Xi*lf}}}a.graduatedMarks.push(vd)}Ga(B);
var nf=a.graduatedMarks;if(null!==nf)for(var jb=d.length,Of=0;Of<jb;Of++){var Sa=d[Of],Tb=nf[Of];if(Sa.visible&&Sa!==h&&0!==Tb.length){if(Sa instanceof Bf){var qc=a,Ue=e,Yd=Sa.alignmentFocus;Yd.Mc()&&(Yd=tc);var Mh=Sa.angle;Sa.Lb=0;Sa.measure(Infinity,Infinity,0,0);Sa.Lb=Mh;var fl=Sa.measuredBounds,$i=fl.width,Nh=fl.height,Dg=N.allocAt(0,0,$i,Nh),fc=I.alloc();fc.xi(Dg,Yd);N.free(Dg);for(var of=-fc.x,Id=-fc.y,Ic=new N,ue=Tb.length,Zd=0;Zd<ue;Zd++)for(var aj=Tb[Zd],Pf=aj.$l.x,gl=aj.$l.y,Oh=aj.angle,
Oa=0;4>Oa;Oa++){switch(Oa){case 0:fc.h(of,Id);break;case 1:fc.h(of+$i,Id);break;case 2:fc.h(of,Id+Nh);break;case 3:fc.h(of+$i,Id+Nh)}fc.rotate(Oh+Sa.angle);fc.offset(Pf,gl);0===Zd&&0===Oa?Ic.h(fc.x,fc.y,0,0):Ic.Ye(fc);fc.offset(-Pf,-gl);fc.rotate(-Oh-Sa.angle)}I.free(fc);null!==qc.graduatedBounds&&qc.graduatedBounds.push(Ic);Xb(Ue,Ic.x,Ic.y,Ic.width,Ic.height)}else if(Sa instanceof Ng){var Xc=a,$d=e;null===Xc.temporaryTextBlock&&(Xc.temporaryTextBlock=new Ng);var rc=Xc.temporaryTextBlock;nn(rc,Sa);
var Ph=Sa.alignmentFocus;Ph.Mc()&&(Ph=tc);for(var Yc=Sa.segmentOrientation,Qf=Sa.segmentOffset,ae=new N,bj=0,cj=0,be=0,Rf=0,Sf=0,Qh=Tb.length,pf=0;pf<Qh;pf++){var Zc=Tb[pf];bj=Zc.$l.x;cj=Zc.$l.y;be=Zc.angle;Sf=Rf=Sa.angle;Yc!==Zf&&(Sf=Yc===on||Yc===pn?be+Sa.angle:be,Rf=T.computeAngle(Yc,Sf));rc.Lb=Rf;rc.text=Zc.text||"";rc.measure(Infinity,Infinity,0,0);var Ve=rc.measuredBounds,Tf=rc.naturalBounds,ve=Tf.width,We=Tf.height,we=ld.alloc();we.reset();we.translate(-Ve.x,-Ve.y);we.scale(rc.scale,rc.scale);
we.rotate(Sf,ve/2,We/2);Yc!==fn&&Yc!==gn||we.rotate(90,ve/2,We/2);Yc!==hn&&Yc!==jn||we.rotate(-90,ve/2,We/2);Yc===kn&&(45<be&&135>be||225<be&&315>be)&&we.rotate(-be,ve/2,We/2);var Eg=N.allocAt(0,0,ve,We),Xe=I.alloc();Xe.xi(Eg,Ph);we.Ga(Xe);var Rh=-Xe.x,Fg=-Xe.y,xe=I.alloc();xe.assign(Qf);isNaN(xe.x)&&(xe.x=ve/2+3);isNaN(xe.y)&&(xe.y=-(We/2+3));xe.rotate(be);bj+=xe.x+Rh;cj+=xe.y+Fg;var dj=new N(bj,cj,Ve.width,Ve.height),Sh=new N(Ve.x,Ve.y,Ve.width,Ve.height),ej=new N(Tf.x,Tf.y,Tf.width,Tf.height);
Zc.Tz=Rf;Zc.lineCount=rc.lineCount;Zc.lines=[rc.Db,rc.Ih,rc.df,rc.Le,rc.Qc,rc.Sb,rc.ze];Zc.actualBounds=dj;Zc.measuredBounds=Sh;Zc.naturalBounds=ej;0===pf?ae.assign(dj):ae.ed(dj);I.free(xe);I.free(Xe);N.free(Eg);ld.free(we)}null!==Xc.graduatedBounds&&Xc.graduatedBounds.push(ae);Xb($d,ae.x,ae.y,ae.width,ae.height)}uj(Sa,!1)}}};
mn.prototype.arrange=function(a,b,c){if(null!==a.graduatedBounds){var d=a.Qb(),e=a.graduatedMarks;if(null!==e){var f=a.graduatedBounds,g=0,h=f[g];g++;null!==d&&d.arrange(h.x-c.x,h.y-c.y,h.width,h.height);for(var k=b.length,l=0;l<k;l++){var m=b[l];h=e[l];m.visible&&m!==d&&0!==h.length&&(h=f[g],g++,m.arrange(h.x-c.x,h.y-c.y,h.width,h.height))}a.graduatedBounds=null}}};
mn.prototype.hc=function(a,b,c){var d=c.$i;c.$i=!0;var e=a.naturalBounds,f=e.width,g=e.height;e=b instanceof ii;b.save();b.beginPath();b.rect(-1,-1,f+1,g+1);b.clip();b.Se();f=a.Qb();f.hc(b,c);g=a.Df()*c.scale;0>=g&&(g=1);for(var h=f.actualBounds,k=a.O.s,l=a.graduatedMarks,m=k.length,n=0;n<m;n++){var p=k[n],q=l[n],r=q.length;if(p.visible&&p!==f&&0!==q.length)if(p instanceof Bf){if(!(2>a.graduatedTickUnit*p.interval*f.geometry.flattenedTotalLength/a.graduatedRange*g&&null===p.graduatedSkip)){var u=
p.measuredBounds,w=p.strokeWidth*p.scale,v=p.alignmentFocus;v.Mc()&&(v=tc);for(var y=0;y<r;y++){var z=q[y].$l,A=q[y].angle,B=v,E=p.Bb;E.reset();E.translate(z.x+h.x,z.y+h.y);E.rotate(A+p.angle,0,0);E.translate(-u.width*B.x+B.offsetX+w/2,-u.height*B.y+B.offsetY+w/2);E.scale(p.scale,p.scale);Ql(p,!1);p.wh.set(p.Bb);p.Jk=p.scale;Rl(p,!1);p.hc(b,c);e&&(a.svg.appendChild(p.svg),p.svg=null);p.Bb.reset()}}}else if(p instanceof Ng)for(null===a.temporaryTextBlock&&(a.temporaryTextBlock=new Ng),u=a.temporaryTextBlock,
nn(u,p),w=0;w<r;w++)v=q[w],v.actualBounds&&v.measuredBounds&&v.naturalBounds&&(u.fc=v.text||"",u.Lb=v.Tz||0,u.Gc=v.lineCount||0,y=v.lines,void 0!==y&&(u.Db=y[0],u.Ih=y[1],u.df=y[2],u.Le=y[3],u.Qc=y[4],u.Sb=y[5],u.ze=y[6]),v.naturalBounds&&(u.ec=v.naturalBounds),v.actualBounds&&(y=v.actualBounds,u.arrange(y.x,y.y,y.width,y.height)),y=v.actualBounds,u.arrange(y.x,y.y,y.width,y.height),z=v.measuredBounds,v=v.naturalBounds,A=u.Bb,A.reset(),A.translate(y.x+h.x,y.y+h.y),A.translate(-z.x,-z.y),u.no(A,v.x,
v.y,v.width,v.height),Ql(u,!1),u.wh.set(u.Bb),u.Jk=u.scale,Rl(u,!1),u.Ng=p.part,u.Me=p.panel,u.hc(b,c),u.Me=null,u.Ng=null,e&&(a.svg.appendChild(u.svg),u.svg=null))}c.$i=d;b.restore();b.Vc(!0)};
function W(a,b){Y.call(this);this.na=null;if(void 0===a)this.na=W.Position;else if("string"===typeof a){var c=bm.I(a);null!==c&&(this.na=c)}else a instanceof dm?this.na=a:a&&(this.na=W.Position,b=a);null===this.na&&C("Panel type not specified or PanelLayout not loaded: "+a);this.l=this.l|4194304|(this.na===W.Grid?1048576:0);this.O=new F;this.zb=jc;this.Tm=Sc;this.Uf=Sk;this.$g=null;this.$e="";this.jg=new N(NaN,NaN,NaN,NaN);this.U=this.F=null;this.xo=mg;this.jn=Jb;this.kn=pb;this.ub=this.ra=null;this.zn=
NaN;this.ge=this.Hh=null;b&&Object.assign(this,b)}la(W,Y);W.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.na=this.na;a.zb=this.zb.J();a.Tm=this.Tm.J();a.Uf=this.Uf;a.$e=this.$e;a.jg.assign(this.jg);null!==this.U&&(a.U=this.U.copy(a));a.xo=this.xo;a.jn=this.jn.J();a.kn=this.kn.J();null!==this.ra&&(a.ra=this.ra.copy());a.ub=this.ub;a.zn=this.zn;a.Hh=this.Hh};
W.prototype.Af=function(a){Y.prototype.Af.call(this,a);a.O=this.O;for(var b=a.O.s,c=b.length,d=0;d<c;d++)b[d].Me=a;a.$g=null};W.prototype.copy=function(){var a=Y.prototype.copy.call(this);if(null!==a){for(var b=this.O.s,c=b.length,d=0;d<c;d++){var e=b[d].copy();e.wi(a);e.Ng=null;var f=a.O,g=f.count;f.Hb(g,e);f=a.part;if(null!==f){f.Rh=null;null!==e.portId&&f instanceof V&&(f.Te=!0);var h=a.diagram;null!==h&&h.undoManager.isUndoingRedoing||f.cb(fe,"elements",a,null,e,null,g)}}return a}return null};
t=W.prototype;t.toString=function(){return"Panel("+this.type.name+")#"+bb(this)};t.Pn=function(a){Y.prototype.Pn.call(this,a);for(var b=this.O.s,c=b.length,d=0;d<c;d++)b[d].Pn(a)};
t.hh=function(a,b){if(this.na===W.Grid)a.yc(),this.na.hc(this,a,b);else if(this.na===W.Graduated)a.yc(),this.na.hc(this,a,b);else{if(this.na===W.Table)if(a.yc(),a instanceof ii){var c=a.yd;a.yd=Il(a,"gojs-ts");qn(this,a);a.nh.pop();a.yd=c}else qn(this,a);(c=this.isClipping&&this.na===W.Spot)&&a.save();for(var d=this.Qb(),e=this.O.s,f=e.length,g=0;g<f;g++){var h=e[g];c&&h===d&&(a.clipInsteadOfFill=!0);h.hc(a,b);c&&h===d&&(a.clipInsteadOfFill=!1)}c&&(a.restore(),a.Vc(!0))}};
function qn(a,b){b.lineCap="butt";var c=0<a.rowCount?a.rowDefs:null,d=0<a.columnCount?a.colDefs:null;null!==c&&rn(a,b,!0,c,!0);null!==d&&rn(a,b,!1,d,!0);null!==c&&sn(a,b,!0,c);null!==d&&sn(a,b,!1,d);null!==c&&rn(a,b,!0,c,!1);null!==d&&rn(a,b,!1,d,!1)}
function sn(a,b,c,d){for(var e=d.length,f=a.actualBounds,g=a.naturalBounds,h=!0,k=0;k<e;k++){var l=d[k];if(void 0!==l&&0!==l.actual)if(h)h=!1;else{if(c){if(l.position>g.height)continue}else if(l.position>g.width)continue;var m=l.separatorStrokeWidth;isNaN(m)&&(m=c?a.defaultRowSeparatorStrokeWidth:a.defaultColumnSeparatorStrokeWidth);var n=l.separatorStroke;null===n&&(n=c?a.defaultRowSeparatorStroke:a.defaultColumnSeparatorStroke);if(0!==m&&null!==n){ji(a,b,n,!1,!1,g,f);n=!1;var p=l.separatorDashArray;
null===p&&(p=c?a.defaultRowSeparatorDashArray:a.defaultColumnSeparatorDashArray);null!==p&&(n=!0,b.js(p,0));b.beginPath();p=l.position+m;c?p>g.height&&(m-=p-g.height):p>g.width&&(m-=p-g.width);l=l.position+m/2;b.lineWidth=m;m=a.zb;c?(l+=m.top,p=g.width-m.right,b.moveTo(m.left,l),b.lineTo(p,l)):(l+=m.left,p=g.height-m.bottom,b.moveTo(l,m.top),b.lineTo(l,p));b.stroke();b.Se();n&&b.fs()}}}}
function rn(a,b,c,d,e){for(var f=d.length,g=a.actualBounds,h=a.naturalBounds,k=tn(a,!0),l=tn(a,!1),m=0;m<f;m++){var n=d[m];if(void 0!==n&&null!==n.background&&n.coversSeparators!==e&&0!==n.actual){var p=c?g.height:g.width;if(!(n.position>p)){var q=n.Wc(),r=n.separatorStrokeWidth;isNaN(r)&&(r=c?a.defaultRowSeparatorStrokeWidth:a.defaultColumnSeparatorStrokeWidth);var u=n.separatorStroke;null===u&&(u=c?a.defaultRowSeparatorStroke:a.defaultColumnSeparatorStroke);if(null===u||n.index===(c?k:l))r=0;q-=
r;r=n.position+r;q+=n.actual;r+q>p&&(q=p-r);0>=q||(p=a.zb,ji(a,b,n.background,!0,!1,h,g),c?b.fillRect(p.left,r+p.top,g.width-(p.left+p.right),q):b.fillRect(r+p.left,p.top,q,g.height-(p.top+p.bottom)))}}}}function tn(a,b){for(var c=b?a.rowCount:a.columnCount,d=0;d<c;d++){var e=b?a.rowDefs[d]:a.colDefs[d];if(void 0!==e)return e.index}return 0}function ek(a){return"LineV"===a||"BarV"===a}t.mv=function(){for(var a=this.O.s,b=a.length,c=0;c<b;c++)a[c].mv();this.Uc=!0};
t.jk=function(a,b,c){if(!Y.prototype.jk.call(this,a,b,c))return!1;if(this.type===W.Table)c=this.svg.getElementsByClassName("gojs-ts"),c=0!==c.length?c[0]:null,null!==c&&(c.innerHTML=""),a.yd=c,qn(this,a),a.yd=null;else if(this.na===W.Grid||this.na===W.Graduated)return this.svg.innerHTML="",this.svg.remove(),!1;c=this.O.s;for(var d=c.length,e=0;e<d;e++)c[e].hc(a,b);return!0};t.mk=function(){return!0};
t.$o=function(a,b,c,d){if(!this.isClipping)if(this.Dd()&&null!==a.ak){a=a.ak;b=this.O.s;for(var e=0,f=0,g=0;g<b.length;g++)if(b[g].Ed()){var h=b[g].actualBounds;e=0===g?h.x:Math.min(h.x,e);f=0===g?h.y:Math.min(h.y,f)}d.setAttributeNS(null,"clip-path","path('M "+(a.x-c.x-e)+","+(a.y-c.y-f)+" l "+a.width+" 0 l 0 "+a.height+" l -"+a.width+" 0 z')")}else Y.prototype.$o.call(this,a,b,c,d)};
t.Rj=function(a,b,c,d,e){var f=this.se(),g=this.transform,h=1/(g.m11*g.m22-g.m12*g.m21),k=g.m22*h,l=-g.m12*h,m=-g.m21*h,n=g.m11*h,p=h*(g.m21*g.dy-g.m22*g.dx),q=h*(g.m12*g.dx-g.m11*g.dy);if(null!==this.areaBackground)return g=this.actualBounds,K.Ff(g.left,g.top,g.right,g.bottom,a,b,c,d,e);if(null!==this.background)return f=a*k+b*m+p,h=a*l+b*n+q,a=c*k+d*m+p,k=c*l+d*n+q,e.h(0,0),c=this.naturalBounds,f=K.Ff(0,0,c.width,c.height,f,h,a,k,e),e.transform(g),f;f||(k=1,m=l=0,n=1,q=p=0);h=a*k+b*m+p;a=a*l+b*
n+q;k=c*k+d*m+p;c=c*l+d*n+q;e.h(k,c);d=(k-h)*(k-h)+(c-a)*(c-a);l=!1;n=this.O.s;q=n.length;m=I.alloc();p=null;b=Infinity;var r=null,u=this.isClipping&&this.na===W.Spot;u&&(r=I.alloc(),p=this.Qb(),(l=p.Rj(h,a,k,c,r))&&(b=(h-r.x)*(h-r.x)+(a-r.y)*(a-r.y)));for(var w=0;w<q;w++){var v=n[w];v.visible&&v!==p&&v.Rj(h,a,k,c,m)&&(l=!0,v=(h-m.x)*(h-m.x)+(a-m.y)*(a-m.y),v<d&&(d=v,e.set(m)))}u&&(b>d&&e.set(r),I.free(r));I.free(m);f&&e.transform(g);return l};
t.Fj=function(){for(var a=this.O.s,b=a.length,c=0;c<b;c++)a[c].Fj();Y.prototype.Fj.call(this)};t.v=function(a){if(!zj(this)){Y.prototype.v.call(this,a);a=null;if(this.na===W.Auto||this.na===W.Link)a=this.Qb();for(var b=this.O.s,c=b.length,d=0;d<c;d++){var e=b[d];(e===a||e.isPanelMain)&&e.v(!0);if(!e.desiredSize.w()){var f=kl(e,!1);(e instanceof ig||e instanceof W||e instanceof Ng||f!==kg)&&e.v(!0)}}}};t.Tj=function(){if(!zj(this)){uj(this,!0);pl(this,!0);for(var a=this.O.s,b=a.length,c=0;c<b;c++)a[c].Tj()}};
t.Tk=function(){if(0!==(this.l&2048)===!1){Ql(this,!0);Rl(this,!0);for(var a=this.O.s,b=a.length,c=0;c<b;c++)a[c].Xu()}};t.Xu=function(){Rl(this,!0);for(var a=this.O.s,b=a.length,c=0;c<b;c++)a[c].Xu()};
t.Wl=function(a,b,c,d){var e=this.jg;e.h(0,0,0,0);var f=this.desiredSize,g=this.minSize;void 0===c&&(c=g.width,d=g.height);c=Math.max(c,g.width);d=Math.max(d,g.height);var h=this.maxSize;isNaN(f.width)||(a=Math.min(f.width,h.width));isNaN(f.height)||(b=Math.min(f.height,h.height));a=Math.max(c,a);b=Math.max(d,b);var k=this.zb;a=Math.max(a-k.left-k.right,0);b=Math.max(b-k.top-k.bottom,0);var l=this.O.s;0!==l.length&&this.na.measure(this,a,b,l,e,c,d);a=e.width+k.left+k.right;k=e.height+k.top+k.bottom;
isFinite(f.width)&&(a=f.width);isFinite(f.height)&&(k=f.height);a=Math.min(h.width,a);k=Math.min(h.height,k);a=Math.max(g.width,a);k=Math.max(g.height,k);a=Math.max(c,a);k=Math.max(d,k);Pb(e,a,k);Pb(this.naturalBounds,a,k);this.Ge(0,0,a,k)};t.Qb=function(){if(null===this.$g){var a=this.O.s,b=a.length;if(0===b)return null;for(var c=0;c<b;c++){var d=a[c];if(!0===d.isPanelMain)return this.$g=d}this.$g=a[0]}return this.$g};function Om(a){return null!==a.part?a.part.locationObject:null}
t.eh=function(a,b,c,d){var e=this.O.s;this.actualBounds.h(a,b,c,d);if(0!==e.length){if(!this.desiredSize.w()){a=kl(this,!0);var f=this.measuredBounds;b=f.width;f=f.height;var g=this.Kg,h=g.left+g.right;g=g.top+g.bottom;b===c&&f===d&&(a=kg);switch(a){case kg:if(b>c||f>d)this.v(),this.measure(b>c?c:b,f>d?d:f,0,0);break;case nd:this.v(!0);this.measure(c+h,d+g,0,0);break;case Qk:this.v(!0);this.measure(c+h,f+g,0,0);break;case Rk:this.v(!0),this.measure(b+h,d+g,0,0)}}this.na.arrange(this,e,this.jg)}};
t.gh=function(a){var b=this.naturalBounds,c=Om(this);if(Yb(0,0,b.width,b.height,a.x,a.y)){b=this.O.s;for(var d=b.length,e=I.allocAt(0,0);d--;){var f=b[d];if(f.visible||f===c)if(xb(e.set(a),f.transform),f.ca(e))return I.free(e),!0}I.free(e);return null===this.Ma&&null===this.areaBackground?!1:!0}return!1};t.ks=function(a){if(this.bindingId===a)return this;for(var b=this.O.s,c=b.length,d=0;d<c;d++){var e=b[d].ks(a);if(null!==e)return e}return null};
t.qm=function(a,b){b(this,a);if(a instanceof W){a=a.O.s;for(var c=a.length,d=0;d<c;d++)this.qm(a[d],b)}};function Mj(a,b){un(a,a,b)}function un(a,b,c){c(b);b=b.O.s;for(var d=b.length,e=0;e<d;e++){var f=b[e];f instanceof W&&un(a,f,c)}}function vn(a,b){wn(a,a,b)}function wn(a,b,c){c(b);if(b instanceof W){b=b.O.s;for(var d=b.length,e=0;e<d;e++)wn(a,b[e],c)}}t.Nl=function(a){return xn(this,this,a)};
function xn(a,b,c){if(c(b))return b;if(b instanceof W){b=b.O.s;for(var d=b.length,e=0;e<d;e++){var f=xn(a,b[e],c);if(null!==f)return f}}return null}t.fb=function(a){if(this.name===a)return this;var b=this.O.s,c=b.length;null===this.Hh&&null===this.itemTemplateMap||(c=yn(this));for(var d=0;d<c;d++){var e=b[d];if(e instanceof W){var f=e.fb(a);if(null!==f)return f}if(e.name===a)return e}return null};
function zn(a){a=a.O.s;for(var b=a.length,c=0,d=0;d<b;d++){var e=a[d];e instanceof W?c=Math.max(c,zn(e)):e instanceof Bf&&(c=Math.max(c,e.ig/2*e.strokeMiterLimit*e.Df()))}return c}t.se=function(){return!(this.type===W.TableRow||this.type===W.TableColumn)};function ol(a){return a.type===W.TableRow||a.type===W.TableColumn}
t.jc=function(a,b,c){if(!1===this.pickable)return null;void 0===b&&(b=null);void 0===c&&(c=null);if(Aj(this))return null;var d=this.naturalBounds,e=1/this.Df(),f=this.se(),g=f?a:xb(I.allocAt(a.x,a.y),this.transform),h=this.diagram,k=10,l=5;null!==h&&(k=h.Ql("extraTouchArea"),l=k/2);if(Yb(-(l*e),-(l*e),d.width+k*e,d.height+k*e,g.x,g.y)){if(!this.isAtomic){e=this.O.s;var m=e.length;h=I.alloc();l=(k=this.isClipping&&this.na===W.Spot)?this.Qb():null;if(k&&(l.se()?xb(h.set(a),l.transform):h.set(a),!l.ca(h)))return I.free(h),
f||I.free(g),null;for(var n=Om(this);m--;){var p=e[m];if(p.visible||p===n)if(p.se()?xb(h.set(a),p.transform):h.set(a),!k||p!==l){var q=null;p instanceof W?q=p.jc(h,b,c):!0===p.pickable&&p.ca(h)&&(q=p);if(null!==q&&(null!==b&&(q=b(q)),null!==q&&(null===c||c(q))))return I.free(h),f||I.free(g),q}}I.free(h)}if(null===this.background&&null===this.areaBackground)return f||I.free(g),null;a=Yb(0,0,d.width,d.height,g.x,g.y)?this:null;f||I.free(g);return a}f||I.free(g);return null};
t.ji=function(a,b,c,d){if(!1===this.pickable)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var e=this.naturalBounds,f=this.se(),g=f?a:xb(I.allocAt(a.x,a.y),this.transform);e=Yb(0,0,e.width,e.height,g.x,g.y);if(ol(this)||e){if(!this.isAtomic){for(var h=this.O.s,k=h.length,l=I.alloc(),m=Om(this);k--;){var n=h[k];if(n.visible||n===m){n.se()?xb(l.set(a),n.transform):l.set(a);var p=n;n=n instanceof W?n:null;(null!==n?n.ji(l,b,c,d):p.ca(l))&&!1!==p.pickable&&(null!==b&&(p=b(p)),null===p||null!==c&&
!c(p)||d.add(p))}}I.free(l)}f||I.free(g);return e&&(null!==this.background||null!==this.areaBackground)}f||I.free(g);return!1};
t.Cf=function(a,b,c,d,e,f){if(!1===this.pickable)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var g=f;void 0===f&&(g=ld.alloc(),g.reset());g.multiply(this.transform);if(this.fh(a,g))return An(this,b,c,e),void 0===f&&ld.free(g),!0;if(this.Yc(a,g)){if(!this.isAtomic)for(var h=Om(this),k=this.O.s,l=k.length;l--;){var m=k[l];if(m.visible||m===h){var n=m.actualBounds,p=this.naturalBounds;if(!(n.x>p.width||n.y>p.height||0>n.x+n.width||0>n.y+n.height)){n=m;m=m instanceof W?m:null;p=ld.alloc();p.set(g);
if(null!==m?m.Cf(a,b,c,d,e,p):cl(n,a,d,p))null!==b&&(n=b(n)),null===n||null!==c&&!c(n)||e.add(n);ld.free(p)}}}void 0===f&&ld.free(g);return d}void 0===f&&ld.free(g);return!1};function An(a,b,c,d){for(var e=a.O.s,f=e.length;f--;){var g=e[f];if(g.visible){var h=g.actualBounds,k=a.naturalBounds;h.x>k.width||h.y>k.height||0>h.x+h.width||0>h.y+h.height||(g instanceof W&&An(g,b,c,d),null!==b&&(g=b(g)),null===g||null!==c&&!c(g)||d.add(g))}}}
t.og=function(a,b,c,d,e,f){if(!1===this.pickable)return!1;void 0===c&&(c=null);void 0===d&&(d=null);var g=this.naturalBounds,h=this.se(),k=h?a:xb(I.allocAt(a.x,a.y),this.transform),l=h?b:xb(I.allocAt(b.x,b.y),this.transform),m=k.Bf(l),n=0<k.x&&k.x<g.width&&0<k.y&&k.y<g.height||yb(k.x,k.y,0,0,0,g.height)<=m||yb(k.x,k.y,0,g.height,g.width,g.height)<=m||yb(k.x,k.y,g.width,g.height,g.width,0)<=m||yb(k.x,k.y,g.width,0,0,0)<=m;g=k.zd(0,0)<=m&&k.zd(0,g.height)<=m&&k.zd(g.width,0)<=m&&k.zd(g.width,g.height)<=
m;h||(I.free(k),I.free(l));if(n){if(!this.isAtomic){k=I.alloc();l=I.alloc();m=Om(this);for(var p=this.O.s,q=p.length;q--;){var r=p[q];if(r.visible||r===m){var u=r.actualBounds,w=this.naturalBounds;if(!h||!(u.x>w.width||u.y>w.height||0>u.x+u.width||0>u.y+u.height))if(r.se()?(u=r.transform,xb(k.set(a),u),xb(l.set(b),u)):(k.set(a),l.set(b)),u=r,r=r instanceof W?r:null,null!==r?r.og(k,l,c,d,e,f):u.ux(k,l,e))null!==c&&(u=c(u)),null===u||null!==d&&!d(u)||f.add(u)}}I.free(k);I.free(l)}return e?n:g}return!1};
function Um(a){var b=null;a instanceof Bf&&(b=a.spot1,b===Sc&&(b=null),a=a.geometry,null!==a&&null===b&&(b=a.spot1));null===b&&(b=sc);return b}function Vm(a){var b=null;a instanceof Bf&&(b=a.spot2,b===Sc&&(b=null),a=a.geometry,null!==a&&null===b&&(b=a.spot2));null===b&&(b=Bc);return b}t.add=function(a){for(var b=[],c=0;c<arguments.length;++c)b[c]=arguments[c];for(c=0;c<b.length;c++)this.Hb(this.O.count,b[c]);return this};t.M=function(a){return this.O.M(a)};
t.Hb=function(a,b){b instanceof U&&C("Cannot add a Part to a Panel: "+b+"; use a Panel instead");if(this===b||this.rg(b))this===b&&C("Cannot make a Panel contain itself: "+this.toString()),C("Cannot make a Panel indirectly contain itself: "+this.toString()+" already contains "+b.toString());var c=b.panel;null!==c&&c!==this&&C("Cannot add a GraphObject that already belongs to another Panel to this Panel: "+b.toString()+", already contained by "+c.toString()+", cannot be shared by this Panel: "+this.toString());
this.na!==W.Grid||b instanceof Bf||C("Can only add Shapes to a Grid Panel, not: "+b);this.na!==W.Graduated||b instanceof Bf||b instanceof Ng||C("Can only add Shapes or TextBlocks to a Graduated Panel, not: "+b);b.wi(this);b.Ng=null;if(null!==this.itemArray){var d=b.data;null!==d&&"object"===typeof d&&(null===this.ge&&(this.ge=new ob),this.ge.add(d,b))}var e=this.O;d=-1;if(c===this){for(var f=-1,g=this.O.s,h=g.length,k=0;k<h;k++)if(g[k]===b){f=k;break}if(-1!==f){if(f===a||f+1>=e.count&&a>=e.count)return;
e.mb(f);d=f}else C("element "+b.toString()+" has panel "+c.toString()+" but is not contained by it.")}if(0>a||a>e.count)a=e.count;e.Hb(a,b);if(0===a||b.isPanelMain)this.$g=null;zj(this)||this.v();b.v(!1);null!==b.portId?this.Te=!0:b instanceof W&&!0===b.Te&&(this.Te=!0);this.graduatedIntervals=null;this.If()||Bn(this,b,!1);c=this.part;null!==c&&(c.Rh=null,c.ag=NaN,this.Te&&c instanceof V&&(c.Te=!0),c.Te&&c instanceof V&&(c.wc=null),e=this.diagram,null!==e&&e.undoManager.isUndoingRedoing||(-1!==d&&
c.cb(ge,"elements",this,b,null,d,null),c.cb(fe,"elements",this,null,b,null,a)))};function Cn(a,b){a.l=b?a.l|16777216:a.l&-16777217}t.remove=function(a){for(var b=this.O.s,c=b.length,d=-1,e=0;e<c;e++)if(b[e]===a){d=e;break}-1!==d&&this.qc(d,!0)};t.mb=function(a){0<=a&&this.qc(a,!0)};
t.qc=function(a,b){var c=this.O,d=c.M(a);d.Ng=null;d.wi(null);if(null!==this.ge){var e=d.data;"object"===typeof e&&this.ge.remove(e)}c.mb(a);uj(this,!1);this.v();this.$g===d&&(this.$g=null);this.graduatedIntervals=null;var f=this.part;null!==f&&(f.Rh=null,f.ag=NaN,f.Yb(),f instanceof V&&(d instanceof W?d.qm(d,function(a,c){im(f,c,b)}):im(f,d,b)),c=this.diagram,null!==c&&c.undoManager.isUndoingRedoing||f.cb(ge,"elements",this,d,null,a,null))};
t.Gb=function(a){0>a&&xa(a,">= 0",W,"getRowDefinition:idx");a=Math.round(a);var b=this.rowDefs;if(void 0===b[a]){var c=new ak;c.wi(this);c.isRow=!0;c.index=a;b[a]=c;this.cb(fe,"rowdefs",this,null,c,null,a)}return b[a]};t.pA=function(a){0>a&&xa(a,">= 0",W,"removeRowDefinition:idx");if(0!==this.rowCount){a=Math.round(a);var b=this.rowDefs,c=b[a];if(c){b[a]=void 0;for(var d=b.length-1;0<=d&&void 0===b[d--];)b.length--;this.cb(ge,"rowdefs",this,c,null,a,null);this.v()}}};
function bk(a,b,c){var d=a.rowDefs;if(!(0>b)){d[b]=c instanceof ak?c:void 0;if(!c)for(b=d.length-1;0<=b&&void 0===d[b--];)d.length--;a.v()}}t.Fb=function(a){0>a&&xa(a,">= 0",W,"getColumnDefinition:idx");a=Math.round(a);var b=this.colDefs;if(void 0===b[a]){var c=new ak;c.wi(this);c.isRow=!1;c.index=a;b[a]=c;this.cb(fe,"coldefs",this,null,c,null,a)}return b[a]};
t.kA=function(a){0>a&&xa(a,">= 0",W,"removeColumnDefinition:idx");if(0!==this.columnCount){a=Math.round(a);var b=this.colDefs,c=b[a];if(c){b[a]=void 0;for(var d=b.length-1;0<=d&&void 0===b[d--];)b.length--;this.cb(ge,"coldefs",this,c,null,a,null);this.v()}}};function ck(a,b,c){var d=a.colDefs;if(!(0>b)){d[b]=c instanceof ak?c:void 0;if(!c)for(b=d.length-1;0<=b&&void 0===d[b--];)d.length--;a.v()}}t.Hy=function(a){var b=null;a.isRow?b=this.Gb(a.index):a.isRow||(b=this.Fb(a.index));b&&cm(b,a);return this};
t.Iy=function(a,b){(a=this.Gb(a))&&cm(a,new ak(b));return this};t.Cy=function(a,b){(a=this.Fb(a))&&cm(a,new ak(b));return this};t.oz=function(a){if(0>a||this.type!==W.Table||0===this.rowCount)return-1;for(var b=0,c=this.rowDefs,d=c.length,e=this.topIndex;e<d;e++){var f=c[e];if(void 0!==f&&(b+=f.total,a<b))break}return e};
t.gz=function(a){if(0>a||this.type!==W.Table||0===this.columnCount)return-1;for(var b=0,c=this.colDefs,d=c.length,e=this.leftIndex;e<d;e++){var f=c[e];if(void 0!==f&&(b+=f.total,a<b))break}return e};t.Gz=function(a,b){void 0===b&&(b=new I(NaN,NaN));if(this.type!==W.Graduated)return b.h(NaN,NaN),b;a=Math.min(Math.max(a,this.graduatedMin),this.graduatedMax);var c=this.Qb();c.geometry.Qu((a-this.graduatedMin)/this.graduatedRange,b);return c.transform.Ga(b)};
t.Hz=function(a){if(this.type!==W.Graduated)return NaN;var b=this.Qb();b.transform.Cd(a);return b.geometry.yx(a)*this.graduatedRange+this.graduatedMin};function Yl(a){a=a.allBindings;return null!==a&&a._isFrozen}
function jg(a){var b=a.allBindings;if(null===b)null!==a.data&&C("Template cannot have .data be non-null: "+a),a.allBindings=b=new F;else if(b._isFrozen)return;var c=new F;Cn(a,!1);a.qm(a,function(a,d){d.th();var e=d.localBindings;if(null!==e)for(Vl(d,!1),e=e.iterator;e.next();){var f=e.value;f.mode===Dn&&Vl(d,!0);var g=f.sourceName;null!==g&&("/"===g&&Cn(a,!0),g=bl(f,a,d),null!==g&&(c.add(g),null===g.srcBindings&&(g.srcBindings=new F),g.srcBindings.add(f)));b.add(f)}if(d instanceof W&&d.type===W.Table){if(0<
d.rowCount)for(a=d.rowDefs,e=a.length,f=0;f<e;f++)if(g=a[f],void 0!==g&&null!==g.be)for(var h=g.be.iterator;h.next();){var k=h.value;k.xd=g;k.to=2;k.wl=g.index;b.add(k)}if(0<d.columnCount)for(d=d.colDefs,a=d.length,e=0;e<a;e++)if(f=d[e],void 0!==f&&null!==f.be)for(g=f.be.iterator;g.next();)h=g.value,h.xd=f,h.to=1,h.wl=f.index,b.add(h)}});for(var d=c.iterator;d.next();){var e=d.value;if(null!==e.srcBindings){Vl(e,!0);for(var f=e.srcBindings.iterator;f.next();){var g=f.value;null===e.localBindings&&
(e.localBindings=new F);e.localBindings.add(g)}}e.srcBindings=null}for(d=b.iterator;d.next();)if(e=d.value,f=e.xd,null!==f){e.xd=null;var h=e.targetProperty,k=h.indexOf(".");0<k&&f instanceof W&&(g=h.substring(0,k),h=h.substr(k+1),k=f.fb(g),null!==k?(f=k,e.targetProperty=h):ya('Warning: unable to find GraphObject named "'+g+'" for Binding: '+e.toString()));f instanceof ak?(g=bb(f.panel),e.Ai=void 0===g?-1:g,f.panel.bindingId=e.Ai):f instanceof Y?(g=bb(f),e.Ai=void 0===g?-1:g,f.bindingId=e.Ai):C("Unknown type of binding target: "+
f)}b.freeze();a instanceof U&&a.Zc()&&a.Va()}t.Vy=function(a){void 0===a&&(a=!1);var b=this.copy();vn(b,function(a){a instanceof W&&(a.allBindings=null,a.ub=null);var b=a.localBindings;null!==b&&(a.localBindings=null,b.each(function(b){a.bind(b.copy())}));b=a.animationTriggers;null!==b&&(a.animationTriggers=null,b.each(function(b){a.Kv(b.value.copy())}))});a&&jg(b);return b};
t.La=function(a){var b=this.allBindings;if(null!==b)for(void 0===a&&(a=""),b=b.iterator;b.next();){var c=b.value,d=c.sourceProperty;if(""===a||""===d||d===a)if(d=c.targetProperty,null!==c.converter||""!==d){d=this.data;var e=c.sourceName;if(null!==e)if(""===e)d=this;else if("/"===e)d=this;else if("."===e)d=this;else if(".."===e)d=this;else{if(d=this.fb(e),null===d)continue}else if(c.isToModel)if(d=this.diagram,null!==d)d=d.model.modelData;else continue;var f=this,g=c.Ai;if(-1!==g){if(f=this.ks(g),
null===f)continue}else null!==c.xd&&(f=c.xd);"/"===e?d=f.part:"."===e?d=f:".."===e&&(d=f.panel);e=c.to;if(0!==e){if(!(f instanceof W))continue;1===e?f=f.Fb(c.wl):2===e&&(f=f.Gb(c.wl))}void 0!==f&&c.Nv(f,d)}}};t.tx=function(a){return void 0===a||null===a||null===this.ge?null:this.ge.I(a)};
function En(a,b){a=a.O.s;for(var c=a.length,d=b.length,e=0,f=null;e<c&&!(f=a[e],f instanceof W&&null!==f.data);)e++,f=a[e];if(c-e!==d)return!0;if(null===f)return 0<d;for(var g=0;e<c&&g<d;){f=a[e];if(!(f instanceof W)||f.data!==b[g])return!0;e++;g++}return!1}
function yn(a){if(a.type===W.Spot||a.type===W.Auto)return Math.min(a.O.length,1);if(a.type===W.Link){a=a.O;for(var b=a.length,c=0;c<b;c++){var d=a.M(c);if(!(d instanceof Bf&&d.isPanelMain))break}return c}return a.type===W.Table&&0<a.O.length&&(a=a.O.M(0),a.isPanelMain&&a instanceof W&&ol(a))?1:0}t.nv=function(){for(var a=yn(this);this.O.length>a;)this.qc(this.O.length-1,!1);Fn(this,this.itemArray)};
function Fn(a,b){var c=yn(a);if(null===b||0===b.length)for(;a.O.length>c;)a.qc(a.O.length-1,!1);else if(a.O.length<=c){c=b.length;for(var d=0;d<c;d++)Gn(a,b[d],d,!0)}else{d=c;for(var e=0;e<b.length;e++,d++){var f=b[e],g=d<a.O.length?a.O.M(d):null;if(g instanceof W){if(g.data!==f){var h=Hn(a,g.data),k=Hn(a,f),l=k!==h;l||(h=In(a,h),l=In(a,k)!==h);l?(a.qc(d,!1),Gn(a,f,e,!1)):g.data=f}}else Gn(a,f,e,!1)}for(;d<a.O.length;)a.qc(d,!1);a.$g=null;Jn(a,c,0);zj(a)||a.v();a.graduatedIntervals=null;a.If()||Bn(a,
a,!1);b=a.part;null!==b&&(b.Rh=null,b.ag=NaN,a.Te&&b instanceof V&&(b.Te=!0),b.Te&&b instanceof V&&(b.wc=null))}}function Gn(a,b,c,d){if(!(void 0===b||null===b||0>c)){var e=Hn(a,b);e=In(a,e);if(null!==e){jg(e);e=e.copy();if(0!==(e.l&16777216)){var f=a.ih();null!==f&&Cn(f,!0)}"object"===typeof b&&(null===a.ge&&(a.ge=new ob),a.ge.add(b,e));f=c+yn(a);a.Hb(f,e);d&&(e.ub=b,Jn(a,f,c),e.ub=null);e.data=b}}}
function Jn(a,b,c){for(a=a.O;b<a.length;){var d=a.M(b);if(d instanceof W){var e=b,f=c;d.type===W.TableRow?d.row=e:d.type===W.TableColumn&&(d.column=e);d.itemIndex=f}b++;c++}}function Hn(a,b){if(null===b)return"";a=a.itemCategoryProperty;if("function"===typeof a)a=a(b);else if("string"===typeof a&&"object"===typeof b){if(""===a)return"";a=Kn(b,a)}else return"";if(void 0===a)return"";if("string"===typeof a)return a;C("Panel.getCategoryForItemData found a non-string category for "+b+": "+a)}
function In(a,b){var c=a.itemTemplateMap,d=null;null!==c&&(d=c.I(b));null===d&&(null!==c&&(d=c.I("")),null===d&&(Ln||(Ln=!0,ya('No item template Panel found for category "'+b+'" on '+a),ya("  Using default item template."),a=new W,b=new Ng,b.bind(new zi("text","",Ia)),a.add(b),Mn=a),d=Mn));return d}function Bn(a,b,c){var d=b.enabledChanged;null!==d&&d(b,c);if(b instanceof W){b=b.O.s;d=b.length;for(var e=0;e<d;e++){var f=b[e];c&&f instanceof W&&!f.isEnabled||Bn(a,f,c)}}}
function Nn(a,b){b.name=a;bm.add(a,b)}
ma.Object.defineProperties(W.prototype,{type:{get:function(){return this.na},set:function(a){var b=this.na;b!==a&&(this.na=a,this.isAtomic=this.na===W.Grid,this.v(),this.g("type",b,a))}},elements:{get:function(){return this.O.iterator}},naturalBounds:{get:function(){return this.ec}},padding:{get:function(){return this.zb},set:function(a){"number"===typeof a?(0>a&&xa(a,">= 0",W,"padding"),
a=new cc(a)):(0>a.left&&xa(a.left,">= 0",W,"padding:value.left"),0>a.right&&xa(a.right,">= 0",W,"padding:value.right"),0>a.top&&xa(a.top,">= 0",W,"padding:value.top"),0>a.bottom&&xa(a.bottom,">= 0",W,"padding:value.bottom"));var b=this.zb;b.D(a)||(this.zb=a=a.J(),this.v(),this.g("padding",b,a))}},defaultAlignment:{get:function(){return this.Tm},set:function(a){var b=this.Tm;b.D(a)||(this.Tm=a=a.J(),this.v(),this.g("defaultAlignment",b,a))}},defaultStretch:{
get:function(){return this.Uf},set:function(a){var b=this.Uf;b!==a&&(this.Uf=a,this.v(),this.g("defaultStretch",b,a))}},defaultSeparatorPadding:{get:function(){return null!==this.U?this.U.Qp:jc},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.defaultSeparatorPadding;b.D(a)||(a=a.J(),null===this.U&&(this.U=new On),this.U.Qp=a,this.v(),this.g("defaultSeparatorPadding",b,a))}},defaultRowSeparatorStroke:{get:function(){return null!==
this.U?this.U.ia:null},set:function(a){var b=this.defaultRowSeparatorStroke;b!==a&&(null===a||"string"===typeof a||a instanceof vl)&&(a instanceof vl&&a.freeze(),null===this.U&&(this.U=new On),this.U.ia=a,this.N(),this.g("defaultRowSeparatorStroke",b,a))}},defaultRowSeparatorStrokeWidth:{get:function(){return null!==this.U?this.U.Ha:1},set:function(a){var b=this.defaultRowSeparatorStrokeWidth;b!==a&&isFinite(a)&&0<=a&&(null===this.U&&(this.U=new On),this.U.Ha=a,this.v(),
this.g("defaultRowSeparatorStrokeWidth",b,a))}},defaultRowSeparatorDashArray:{get:function(){return null!==this.U?this.U.V:null},set:function(a){var b=this.defaultRowSeparatorDashArray;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||C("defaultRowSeparatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}null===this.U&&(this.U=new On);this.U.V=
a;this.N();this.g("defaultRowSeparatorDashArray",b,a)}}},defaultColumnSeparatorStroke:{get:function(){return null!==this.U?this.U.u:null},set:function(a){var b=this.defaultColumnSeparatorStroke;b!==a&&(null===a||"string"===typeof a||a instanceof vl)&&(a instanceof vl&&a.freeze(),null===this.U&&(this.U=new On),this.U.u=a,this.N(),this.g("defaultColumnSeparatorStroke",b,a))}},defaultColumnSeparatorStrokeWidth:{get:function(){return null!==
this.U?this.U.F:1},set:function(a){var b=this.defaultColumnSeparatorStrokeWidth;b!==a&&isFinite(a)&&0<=a&&(null===this.U&&(this.U=new On),this.U.F=a,this.v(),this.g("defaultColumnSeparatorStrokeWidth",b,a))}},defaultColumnSeparatorDashArray:{get:function(){return null!==this.U?this.U.o:null},set:function(a){var b=this.defaultColumnSeparatorDashArray;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||C("defaultColumnSeparatorDashArray value "+
f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}null===this.U&&(this.U=new On);this.U.o=a;this.N();this.g("defaultColumnSeparatorDashArray",b,a)}}},inRowsCols:{get:function(){return null!==this.U?this.U.nb:null},set:function(a){null===this.U&&(this.U=new On);this.U.nb=a}},viewboxStretch:{get:function(){return this.xo},set:function(a){var b=this.xo;b!==a&&(this.xo=a,this.v(),this.g("viewboxStretch",
b,a))}},gridCellSize:{get:function(){return this.jn},set:function(a){var b=this.jn;if(!b.D(a)){a.w()&&0!==a.width&&0!==a.height||C("Invalid Panel.gridCellSize: "+a);this.jn=a.J();var c=this.diagram;null!==c&&this===c.grid&&vj(c);this.N();this.g("gridCellSize",b,a)}}},gridOrigin:{get:function(){return this.kn},set:function(a){var b=this.kn;if(!b.D(a)){a.w()||C("Invalid Panel.gridOrigin: "+a);this.kn=a.J();var c=this.diagram;null!==c&&this===
c.grid&&vj(c);this.N();this.g("gridOrigin",b,a)}}},graduatedMin:{get:function(){return null!==this.ra?this.ra.lq:0},set:function(a){var b=this.graduatedMin;b!==a&&(null===this.ra&&(this.ra=new Pn),this.ra.lq=a,this.v(),this.g("graduatedMin",b,a),$k(this)&&(a=this.part,null!==a&&al(this,a,"graduatedRange")))}},graduatedMax:{get:function(){return null!==this.ra?this.ra.F:100},set:function(a){var b=this.graduatedMax;b!==a&&(null===this.ra&&
(this.ra=new Pn),this.ra.F=a,this.v(),this.g("graduatedMax",b,a),$k(this)&&(a=this.part,null!==a&&al(this,a,"graduatedRange")))}},graduatedRange:{get:function(){return this.graduatedMax-this.graduatedMin}},graduatedTickUnit:{get:function(){return null!==this.ra?this.ra.ia:10},set:function(a){var b=this.graduatedTickUnit;b!==a&&0<a&&(null===this.ra&&(this.ra=new Pn),this.ra.ia=a,this.v(),this.g("graduatedTickUnit",b,a))}},graduatedTickBase:{
get:function(){return null!==this.ra?this.ra.V:0},set:function(a){var b=this.graduatedTickBase;b!==a&&(null===this.ra&&(this.ra=new Pn),this.ra.V=a,this.v(),this.g("graduatedTickBase",b,a))}},graduatedMarks:{get:function(){return null!==this.ra?this.ra.u:null},set:function(a){null!==this.ra?this.ra.u=a:null!==a&&(this.ra=new Pn,this.ra.u=a)}},graduatedBounds:{get:function(){return null!==this.ra?this.ra.j:null},set:function(a){null!==
this.ra?this.ra.j=a:null!==a&&(this.ra=new Pn,this.ra.j=a)}},graduatedIntervals:{get:function(){return null!==this.ra?this.ra.o:null},set:function(a){null!==this.ra?this.ra.o=a:null!==a&&(this.ra=new Pn,this.ra.o=a)}},temporaryTextBlock:{get:function(){return null!==this.ra?this.ra.Zh:null},set:function(a){null!==this.ra?this.ra.Zh=a:null!==a&&(this.ra=new Pn,this.ra.Zh=a)}},panelLayoutState:{get:function(){return this.F},
set:function(a){this.F=a}},Te:{get:function(){return 0!==(this.l&8388608)},set:function(a){0!==(this.l&8388608)!==a&&(this.l^=8388608)}},rowDefs:{get:function(){null===this.U&&(this.U=new On);return this.U.Pc}},rowCount:{get:function(){return null===this.U?0:this.rowDefs.length}},colDefs:{get:function(){null===this.U&&(this.U=new On);return this.U.Oc}},columnCount:{
get:function(){return null===this.U?0:this.colDefs.length}},rowSizing:{get:function(){return null!==this.U?this.U.ob:Qn},set:function(a){var b=this.rowSizing;b!==a&&(null===this.U&&(this.U=new On),this.U.ob=a,this.v(),this.g("rowSizing",b,a))}},columnSizing:{get:function(){return null!==this.U?this.U.j:Qn},set:function(a){var b=this.columnSizing;b!==a&&(null===this.U&&(this.U=new On),this.U.j=a,this.v(),this.g("columnSizing",
b,a))}},topIndex:{get:function(){return null!==this.U?this.U.Ib:0},set:function(a){var b=this.topIndex;b!==a&&((!isFinite(a)||0>a)&&C("Panel.topIndex must be greater than zero and a real number, not: "+a),null===this.U&&(this.U=new On),this.U.Ib=a,this.v(),this.g("topIndex",b,a))}},leftIndex:{get:function(){return null!==this.U?this.U.hb:0},set:function(a){var b=this.leftIndex;b!==a&&((!isFinite(a)||0>a)&&C("Panel.leftIndex must be greater than zero and a real number, not: "+
a),null===this.U&&(this.U=new On),this.U.hb=a,this.v(),this.g("leftIndex",b,a))}},data:{get:function(){return this.ub},set:function(a){var b=this.ub;if(b!==a){var c=this instanceof U&&!(this instanceof pe);jg(this);this.ub=a;var d=this.diagram;null!==d&&(c?(c=d.partManager,this instanceof T?(null!==b&&c.bf.remove(b),null!==a&&c.bf.add(a,this)):this instanceof U&&(null!==b&&c.cf.remove(b),null!==a&&c.cf.add(a,this))):(c=this.panel,null!==c&&null!==c.ge&&(null!==b&&c.ge.remove(b),
null!==a&&c.ge.add(a,this))));this.g("data",b,a);null!==d&&d.undoManager.isUndoingRedoing||null!==a&&this.La()}}},itemIndex:{get:function(){return this.zn},set:function(a){var b=this.zn;b!==a&&(this.zn=a,this.g("itemIndex",b,a))}},allBindings:{get:function(){return null!==this.A?this.A.nb:null},set:function(a){this.localBindings!==a&&(null===this.A&&(this.A=new Yk),this.A.nb=a)}},itemArray:{get:function(){return this.Hh},
set:function(a){var b=this.Hh;if(b!==a||null!==a&&En(this,a)){var c=this.diagram;b!==a&&(null!==c&&null!==b&&Rj(c.partManager,this,c),this.Hh=a,null!==c&&null!==a&&Oj(c.partManager,this));this.g("itemArray",b,a);null!==c&&c.undoManager.isUndoingRedoing||Fn(this,a)}}},itemTemplate:{get:function(){return null===this.itemTemplateMap?null:this.itemTemplateMap.I("")},set:function(a){if(null===this.itemTemplateMap){if(null===a)return;this.itemTemplateMap=new ob}var b=this.itemTemplateMap.I("");
b!==a&&((a instanceof U||a.isPanelMain)&&C("Panel.itemTemplate must not be a Part or be Panel.isPanelMain: "+a),this.itemTemplateMap.add("",a),this.g("itemTemplate",b,a),a=this.diagram,null!==a&&a.undoManager.isUndoingRedoing||this.nv())}},itemTemplateMap:{get:function(){return null!==this.A?this.A.Pc:null},set:function(a){var b=this.itemTemplateMap;if(b!==a){for(var c=a.iterator;c.next(););Xk(this);this.A.Pc=a;this.g("itemTemplateMap",b,a);a=this.diagram;null!==a&&a.undoManager.isUndoingRedoing||
this.nv()}}},itemCategoryProperty:{get:function(){return null!==this.A?this.A.Oc:"category"},set:function(a){var b=this.itemCategoryProperty;b!==a&&(Xk(this),this.A.Oc=a,this.g("itemCategoryProperty",b,a))}},isAtomic:{get:function(){return 0!==(this.l&1048576)},set:function(a){var b=0!==(this.l&1048576);b!==a&&(this.l^=1048576,this.g("isAtomic",b,a))}},isClipping:{get:function(){return 0!==(this.l&2097152)},
set:function(a){var b=0!==(this.l&2097152);b!==a&&(this.l^=2097152,this instanceof jf&&(this.tb=null),this.v(),this.g("isClipping",b,a))}},isOpposite:{get:function(){return 0!==(this.l&33554432)},set:function(a){var b=0!==(this.l&33554432);b!==a&&(this.l^=33554432,this.v(),this.g("isOpposite",b,a))}},isEnabled:{get:function(){return 0!==(this.l&4194304)},set:function(a){var b=0!==(this.l&4194304);if(b!==a){var c=null===this.panel||this.panel.If();
this.l^=4194304;this.g("isEnabled",b,a);b=this.diagram;null!==b&&b.undoManager.isUndoingRedoing||c&&Bn(this,this,a)}}},alignmentFocusName:{get:function(){return this.$e},set:function(a){var b=this.$e;b!==a&&(this.$e=a,this.v(),this.g("alignmentFocusName",b,a))}}});
ma.Object.defineProperties(W,{Position:{get:function(){return bm.I("Position")}},Horizontal:{get:function(){return bm.I("Horizontal")}},Vertical:{get:function(){return bm.I("Vertical")}},Spot:{get:function(){return bm.I("Spot")}},Auto:{get:function(){return bm.I("Auto")}},Table:{get:function(){return bm.I("Table")}},Viewbox:{
get:function(){return bm.I("Viewbox")}},TableRow:{get:function(){return bm.I("TableRow")}},TableColumn:{get:function(){return bm.I("TableColumn")}},Link:{get:function(){return bm.I("Link")}},Grid:{get:function(){return bm.I("Grid")}},Graduated:{get:function(){return bm.I("Graduated")}}});W.prototype.rebuildItemElements=W.prototype.nv;
W.prototype.findItemPanelForData=W.prototype.tx;W.prototype.updateTargetBindings=W.prototype.La;W.prototype.copyTemplate=W.prototype.Vy;W.prototype.graduatedValueForPoint=W.prototype.Hz;W.prototype.graduatedPointForValue=W.prototype.Gz;W.prototype.findColumnForLocalX=W.prototype.gz;W.prototype.findRowForLocalY=W.prototype.oz;W.prototype.addColumnDefinition=W.prototype.Cy;W.prototype.addRowDefinition=W.prototype.Iy;W.prototype.addRowColumnDefinition=W.prototype.Hy;
W.prototype.removeColumnDefinition=W.prototype.kA;W.prototype.getColumnDefinition=W.prototype.Fb;W.prototype.removeRowDefinition=W.prototype.pA;W.prototype.getRowDefinition=W.prototype.Gb;W.prototype.removeAt=W.prototype.mb;W.prototype.remove=W.prototype.remove;W.prototype.insertAt=W.prototype.Hb;W.prototype.elt=W.prototype.M;W.prototype.add=W.prototype.add;W.prototype.findObject=W.prototype.fb;W.prototype.findInVisualTree=W.prototype.Nl;W.prototype.walkVisualTreeFrom=W.prototype.qm;
W.prototype.findMainElement=W.prototype.Qb;var Ln=!1,Mn=null,bm=new ob;W.className="Panel";W.definePanelLayout=Nn;Nn("Position",new Nm);Nn("Vertical",new Qm);Nn("Auto",new Tm);Nn("Link",new dn);function Pn(){this.lq=0;this.F=100;this.ia=10;this.V=0;this.Zh=this.o=this.j=this.u=null}Pn.prototype.copy=function(){var a=new Pn;a.lq=this.lq;a.F=this.F;a.ia=this.ia;a.V=this.V;a.u=this.u;a.j=this.j;a.o=this.o;a.Zh=this.Zh;return a};Pn.className="GradPanelSettings";
function On(){this.Pc=[];this.Oc=[];this.j=this.ob=Qn;this.hb=this.Ib=0;this.Qp=jc;this.Ha=1;this.V=this.ia=null;this.F=1;this.nb=this.o=this.u=null}
On.prototype.copy=function(a){for(var b=new On,c=this.Pc,d=c.length,e=b.Pc,f=0;f<d;f++)if(void 0!==c[f]){var g=c[f].copy();g.wi(a);e[f]=g}c=this.Oc;d=c.length;e=b.Oc;for(f=0;f<d;f++)void 0!==c[f]&&(g=c[f].copy(),g.wi(a),e[f]=g);b.ob=this.ob;b.j=this.j;b.Ib=this.Ib;b.hb=this.hb;b.Qp=this.Qp.J();b.Ha=this.Ha;b.ia=this.ia;b.V=this.V;b.F=this.F;b.u=this.u;b.o=this.o;b.nb=this.nb;return b};On.className="TablePanelSettings";
function ak(a){Qa(this);this.Me=null;this.uq=!0;this.Za=0;this.Hc=NaN;this.jj=0;this.hj=Infinity;this.Kb=Sc;this.pa=this.pc=this.oa=0;this.be=null;this.oo=Rn;this.hg=Sk;this.lo=this.dg=null;this.mo=NaN;this.Ma=this.tj=null;this.Pm=!1;a&&(Object.assign(this,a),void 0!==a.column?(this.isRow=!1,this.index=a.column):void 0!==a.row&&(this.isRow=!0,this.index=a.row))}
ak.prototype.copy=function(){var a=new ak;a.uq=this.uq;a.Za=this.Za;a.Hc=this.Hc;a.jj=this.jj;a.hj=this.hj;a.Kb=this.Kb;a.oa=this.oa;a.pc=this.pc;a.pa=this.pa;a.hg=this.hg;a.oo=this.oo;null===this.dg?a.dg=null:a.dg=this.dg.J();a.lo=this.lo;a.mo=this.mo;a.tj=null;null!==this.tj&&(a.separatorDashArray=Ba(this.separatorDashArray));a.Ma=this.Ma;a.Pm=this.Pm;a.be=this.be;return a};
function cm(a,b){b.isRow?a.height=b.height:a.width=b.width;a.minimum=b.minimum;a.maximum=b.maximum;a.alignment=b.alignment;a.stretch=b.stretch;a.sizing=b.sizing;a.dg=null===b.separatorPadding?null:b.separatorPadding.J();a.separatorStroke=b.separatorStroke;a.separatorStrokeWidth=b.separatorStrokeWidth;a.tj=null;b.separatorDashArray&&(a.tj=Ba(b.separatorDashArray));a.background=b.background;a.coversSeparators=b.coversSeparators;a.be=b.be}t=ak.prototype;
t.qb=function(a){a.classType===ak&&(this.sizing=a)};t.toString=function(){return"RowColumnDefinition "+(this.isRow?"(Row ":"(Column ")+this.index+") #"+bb(this)};t.wi=function(a){this.Me=a};
t.Io=function(a){var b=0,c=this.Me;this.index!==a&&(a=this.separatorStroke,null===a&&null!==c&&(a=this.isRow?c.defaultRowSeparatorStroke:c.defaultColumnSeparatorStroke),null!==a&&(b=this.separatorStrokeWidth,isNaN(b)&&(null!==c?b=this.isRow?c.defaultRowSeparatorStrokeWidth:c.defaultColumnSeparatorStrokeWidth:b=0)));a=this.dg;if(null===a)if(null!==c)a=c.defaultSeparatorPadding;else return b;return b+(this.isRow?a.top:a.left)};
t.Wc=function(){var a=0,b=this.Me,c=0,d=this.isRow;if(null!==b&&b.type===W.Table)for(var e=d?b.rowDefs.length:b.colDefs.length,f=0;f<e;f++){var g=d?b.rowDefs[f]:b.colDefs[f];if(void 0!==g&&0!==g.oa){c=g.index;break}}this.index!==c&&(c=this.separatorStroke,null===c&&null!==b&&(c=d?b.defaultRowSeparatorStroke:b.defaultColumnSeparatorStroke),null!==c&&(a=this.separatorStrokeWidth,isNaN(a)&&(null!==b?a=d?b.defaultRowSeparatorStrokeWidth:b.defaultColumnSeparatorStrokeWidth:a=0)));d=this.dg;if(null===d)if(null!==
b)d=b.defaultSeparatorPadding;else return a;return a+(this.isRow?d.top+d.bottom:d.left+d.right)};t.Pb=function(a,b,c){var d=this.Me;if(null!==d&&(d.cb(de,a,this,b,c,void 0,void 0),null!==this.be&&(b=d.diagram,null!==b&&!b.skipsModelSourceBindings&&(d=d.ih(),null!==d&&(b=d.data,null!==b)))))for(c=this.be.iterator;c.next();)c.value.sp(this,b,a,d)};function Xm(a){if(a.sizing===Rn){var b=a.Me;return a.isRow?b.rowSizing:b.columnSizing}return a.sizing}
t.bind=function(a,b,c,d){var e=null;"string"===typeof a?e=new zi(a,b,c,d):e=a;e.xd=this;a=this.panel;null!==a&&(b=a.ih(),null!==b&&Yl(b)&&C("Cannot add a Binding to a RowColumnDefinition that is already frozen: "+e+" on "+a));null===this.be&&(this.be=new F);this.be.add(e);return this};
ma.Object.defineProperties(ak.prototype,{panel:{get:function(){return this.Me}},isRow:{get:function(){return this.uq},set:function(a){this.uq=a}},index:{get:function(){return this.Za},set:function(a){this.Za=a}},height:{get:function(){return this.Hc},set:function(a){var b=this.Hc;b!==a&&(0>a&&xa(a,">= 0",ak,"height"),this.Hc=a,this.actual=this.oa,null!==this.panel&&this.panel.v(),
this.Pb("height",b,a))}},width:{get:function(){return this.Hc},set:function(a){var b=this.Hc;b!==a&&(0>a&&xa(a,">= 0",ak,"width"),this.Hc=a,this.actual=this.oa,null!==this.panel&&this.panel.v(),this.Pb("width",b,a))}},minimum:{get:function(){return this.jj},set:function(a){var b=this.jj;b!==a&&((0>a||!isFinite(a))&&xa(a,">= 0",ak,"minimum"),this.jj=a,this.actual=this.oa,null!==this.panel&&this.panel.v(),this.Pb("minimum",b,a))}},maximum:{
get:function(){return this.hj},set:function(a){var b=this.hj;b!==a&&(0>a&&xa(a,">= 0",ak,"maximum"),this.hj=a,this.actual=this.oa,null!==this.panel&&this.panel.v(),this.Pb("maximum",b,a))}},alignment:{get:function(){return this.Kb},set:function(a){var b=this.Kb;b.D(a)||(this.Kb=a.J(),null!==this.panel&&this.panel.v(),this.Pb("alignment",b,a))}},stretch:{get:function(){return this.hg},set:function(a){var b=this.hg;b!==a&&(this.hg=
a,null!==this.panel&&this.panel.v(),this.Pb("stretch",b,a))}},separatorPadding:{get:function(){return this.dg},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.dg;null!==a&&null!==b&&b.D(a)||(null!==a&&(a=a.J()),this.dg=a,null!==this.panel&&this.panel.v(),this.Pb("separatorPadding",b,a))}},separatorStroke:{get:function(){return this.lo},set:function(a){var b=this.lo;b!==a&&(null!==a&&hm(a,"RowColumnDefinition.separatorStroke"),
a instanceof vl&&a.freeze(),this.lo=a,null!==this.panel&&this.panel.v(),this.Pb("separatorStroke",b,a))}},separatorStrokeWidth:{get:function(){return this.mo},set:function(a){var b=this.mo;b!==a&&(this.mo=a,null!==this.panel&&this.panel.v(),this.Pb("separatorStrokeWidth",b,a))}},separatorDashArray:{get:function(){return this.tj},set:function(a){var b=this.tj;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===
typeof f&&0<=f&&isFinite(f)||C("separatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.tj=a;null!==this.panel&&this.panel.N();this.Pb("separatorDashArray",b,a)}}},background:{get:function(){return this.Ma},set:function(a){var b=this.Ma;b!==a&&(null!==a&&hm(a,"RowColumnDefinition.background"),a instanceof vl&&a.freeze(),this.Ma=a,null!==this.panel&&this.panel.N(),this.Pb("background",b,a))}},coversSeparators:{
get:function(){return this.Pm},set:function(a){var b=this.Pm;b!==a&&(this.Pm=a,null!==this.panel&&this.panel.N(),this.Pb("coversSeparators",b,a))}},sizing:{get:function(){return this.oo},set:function(a){var b=this.oo;b!==a&&(this.oo=a,null!==this.panel&&this.panel.v(),this.Pb("sizing",b,a))}},actual:{get:function(){return this.oa},set:function(a){this.oa=isNaN(this.Hc)?Math.max(Math.min(this.hj,a),this.jj):Math.max(Math.min(this.hj,
this.Hc),this.jj)}},measured:{get:function(){return this.pc},set:function(a){this.pc=a}},total:{get:function(){return this.oa+this.Wc()}},position:{get:function(){return this.pa},set:function(a){this.pa=a}}});ak.prototype.computeEffectiveSpacing=ak.prototype.Wc;ak.prototype.computeEffectiveSpacingTop=ak.prototype.Io;var Rn=new D(ak,"Default",0),Ym=new D(ak,"None",1),Qn=new D(ak,"ProportionalExtra",2);
ak.className="RowColumnDefinition";ak.Default=Rn;ak.None=Ym;ak.ProportionalExtra=Qn;function Bf(a,b){Y.call(this);this.Rd=this.ua=null;this.an="None";this.xc=this.Ui="black";this.ig=1;this.Na=this.Yg=null;this.Vn=this.Un=NaN;this.fa=null;"string"===typeof a?this.figure=a:a&&Object.assign(this,a);b&&Object.assign(this,b)}la(Bf,Y);
Bf.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.ua=this.ua;a.Rd=this.Rd;a.an=this.an;a.Ui=this.Ui;a.xc=this.xc;a.ig=this.ig;null!==this.Yg&&(a.Yg=Ba(this.Yg));null!==this.Na&&(a.Na=this.Na.oc?this.Na:this.Na.copy());a.Un=this.Un;a.Vn=this.Vn;null!==this.fa&&(a.fa=this.fa.copy())};function Sn(a){null===a.Na?a.Na=new Tn:a.Na.oc&&(a.Na=a.Na.copy())}t=Bf.prototype;t.th=function(){Y.prototype.th.call(this);null!==this.Na&&(this.Na.oc=!0)};
t.qb=function(a){a===kg||a===mg||a===Tk||a===Sk?this.geometryStretch=a:Y.prototype.qb.call(this,a)};t.toString=function(){return"Shape("+("None"!==this.figure?this.figure:"None"!==this.toArrow?this.toArrow:this.fromArrow)+")#"+bb(this)};
function Un(a,b,c,d){var e=c.length;if(!(4>e)){var f=d.measuredBounds,g=Math.max(1,f.width);f=f.height;for(var h=c[0],k=c[1],l,m,n,p,q,r,u=0,w=Ea(),v=2;v<e;v+=2)l=c[v],m=c[v+1],n=l-h,h=m-k,0===n&&(n=.001),p=h/n,q=Math.atan2(h,n),r=Math.sqrt(n*n+h*h),w.push([n,q,p,r]),u+=r,h=l,k=m;h=c[0];k=c[1];n=d.measuredBounds.width;d instanceof Bf&&(n-=d.strokeWidth);1>n&&(n=1);e=c=n;l=g/2;m=0===l?!1:!0;v=0;r=w[v];n=r[0];q=r[1];p=r[2];r=r[3];for(var y=0;.1<=u;){0===y&&(m?(e=c,e-=l,u-=l,m=!1):e=c,0===e&&(e=1));
if(e>u){Ga(w);return}e>r?(y=e-r,e=r):y=0;var z=Math.sqrt(e*e/(1+p*p));0>n&&(z=-z);h+=z;k+=p*z;a.translate(h,k);a.rotate(q);a.translate(-(g/2),-(f/2));0===y&&d.hh(a,b);a.translate(g/2,f/2);a.rotate(-q);a.translate(-h,-k);u-=e;r-=e;if(0!==y){v++;if(v===w.length){Ga(w);return}r=w[v];n=r[0];q=r[1];p=r[2];r=r[3];e=y}}Ga(w)}}
t.hh=function(a,b){var c=this.xc,d=this.Ui;if(null!==c||null!==d){var e=this.ua;if(null!==e){a.yc();var f=this.actualBounds,g=this.naturalBounds;null!==d&&ji(this,a,d,!0,!1,g,f);var h=this.part,k=this.ig;null===c||0!==k||null===h||!this.isPanelMain&&h.Qb()!==this||(k=h.type===W.Link&&h instanceof pe&&"Selection"===h.category&&h.adornedObject instanceof Bf&&h.adornedPart.Qb()===h.adornedObject?h.adornedObject.strokeWidth:0);0===k&&(c=null);null!==c&&0!==k&&(ji(this,a,c,!1,!1,g,f),a.lineWidth=k,a.lineJoin=
this.strokeJoin,a.lineCap=this.strokeCap,a.miterLimit=this.strokeMiterLimit);f=!1;h&&b.Gf("drawShadows")&&(f=h.isShadowed);!1===a.Il&&(f=!1);h=!0;null===c||null!==d&&"transparent"!==d||(h=!1);g=!1;k=this.strokeDashArray;null!==k&&(g=!0,a.js(k,this.strokeDashOffset));if(e.type===P.o)a.beginPath(),a.moveTo(e.startX,e.startY),a.lineTo(e.endX,e.endY),null!==c&&a.ik(),a.Se();else if(e.type===P.u){var l=e.startX;k=e.startY;var m=e.endX,n=e.endY;e=Math.min(l,m);var p=Math.min(k,n);l=Math.abs(m-l);k=Math.abs(n-
k);a.beginPath();a.rect(e,p,l,k);null!==d&&a.ng(d);null!==c&&(h&&f&&Bl(a),a.ik(),h&&f&&xl(a));a.Se()}else if(e.type===P.F)l=e.startX,k=e.startY,m=e.endX,n=e.endY,e=Math.abs(m-l)/2,p=Math.abs(n-k)/2,l=Math.min(l,m)+e,k=Math.min(k,n)+p,a.beginPath(),a.moveTo(l,k-p),a.bezierCurveTo(l+K.wg*e,k-p,l+e,k-K.wg*p,l+e,k),a.bezierCurveTo(l+e,k+K.wg*p,l+K.wg*e,k+p,l,k+p),a.bezierCurveTo(l-K.wg*e,k+p,l-e,k+K.wg*p,l-e,k),a.bezierCurveTo(l-e,k-K.wg*p,l-K.wg*e,k-p,l,k-p),a.closePath(),null!==d&&a.ng(d),null!==c&&
(h&&f&&Bl(a),a.ik(),h&&f&&xl(a)),a.Se();else if(e.type===P.j)for(e=e.figures,k=e.length,p=0;p<k;p++){l=e.s[p];a.beginPath();a.moveTo(l.startX,l.startY);m=l.segments.s;n=m.length;for(var q=null,r=0;r<n;r++){var u=m[r];switch(u.type){case Ad:a.moveTo(u.endX,u.endY);break;case pd:a.lineTo(u.endX,u.endY);break;case Bd:a.bezierCurveTo(u.point1X,u.point1Y,u.point2X,u.point2Y,u.endX,u.endY);break;case Cd:a.quadraticCurveTo(u.point1X,u.point1Y,u.endX,u.endY);break;case Dd:if(u.radiusX===u.radiusY){var w=
Math.PI/180;a.arc(u.point1X,u.point1Y,u.radiusX,u.startAngle*w,(u.startAngle+u.sweepAngle)*w,0>u.sweepAngle,null!==q?q.endX:l.startX,null!==q?q.endY:l.startY)}else if(q=Kd(u,l),w=q.length,0===w)a.lineTo(u.centerX,u.centerY);else for(var v=0;v<w;v++){var y=q[v];0===v&&a.lineTo(y[0],y[1]);a.bezierCurveTo(y[2],y[3],y[4],y[5],y[6],y[7])}break;case Jd:v=w=0;if(null!==q&&q.type===Dd){q=Kd(q,l);y=q.length;if(0===y){a.lineTo(u.endX,u.endY);break}q=q[y-1]||null;null!==q&&(w=q[6],v=q[7])}else w=null!==q?q.endX:
l.startX,v=null!==q?q.endY:l.startY;q=Ld(u,l,w,v);w=q.length;if(0===w){a.lineTo(u.endX,u.endY);break}for(v=0;v<w;v++)y=q[v],a.bezierCurveTo(y[2],y[3],y[4],y[5],y[6],y[7]);break;default:C("Segment not of valid type: "+u.type)}u.isClosed&&a.closePath();q=u}m=l.isFilled;f?l.isShadowed?(!0===m&&"transparent"!==d&&null!==d&&a.ng(d,l.isEvenOdd),null!==c&&(m&&h&&Bl(a),a.ik(),m&&h&&xl(a))):(Bl(a),m&&"transparent"!==d&&null!==d&&a.ng(d,l.isEvenOdd),null!==c&&a.ik(),xl(a)):(m&&null!==d&&a.ng(d,l.isEvenOdd),
null!==c&&a.ik());a.Se(p)}g&&a.fs();if(null!==this.pathPattern){c=this.pathPattern;c.measure(Infinity,Infinity);d=c.measuredBounds;c.arrange(0,0,d.width,d.height);f=this.geometry;a.save();a.beginPath();d=Ea();if(f.type===P.o)d.push(f.startX),d.push(f.startY),d.push(f.endX),d.push(f.endY),Un(a,b,d,c);else if(f.type===P.j)for(f=f.figures.iterator;f.next();){h=f.value;d.length=0;d.push(h.startX);d.push(h.startY);g=h.startX;e=h.startY;k=g;p=e;l=h.segments.s;m=l.length;for(n=0;n<m;n++){r=l[n];switch(r.type){case Ad:Un(a,
b,d,c);d.length=0;d.push(r.endX);d.push(r.endY);g=r.endX;e=r.endY;k=g;p=e;break;case pd:d.push(r.endX);d.push(r.endY);g=r.endX;e=r.endY;break;case Bd:K.Qe(g,e,r.point1X,r.point1Y,r.point2X,r.point2Y,r.endX,r.endY,.5,d);g=r.endX;e=r.endY;break;case Cd:K.ep(g,e,r.point1X,r.point1Y,r.endX,r.endY,.5,d);g=r.endX;e=r.endY;break;case Dd:u=Kd(r,h);q=u.length;if(0===q){d.push(r.centerX);d.push(r.centerY);g=r.centerX;e=r.centerY;break}for(w=0;w<q;w++)v=u[w],K.Qe(g,e,v[2],v[3],v[4],v[5],v[6],v[7],.5,d),g=v[6],
e=v[7];break;case Jd:u=Ld(r,h,g,e);q=u.length;if(0===q){d.push(r.endX);d.push(r.endY);g=r.endX;e=r.endY;break}for(w=0;w<q;w++)v=u[w],K.Qe(g,e,v[2],v[3],v[4],v[5],v[6],v[7],.5,d),g=v[6],e=v[7];break;default:C("Segment not of valid type: "+r.type)}r.isClosed&&(d.push(k),d.push(p),Un(a,b,d,c))}Un(a,b,d,c)}else if(f.type===P.u)d.push(f.startX),d.push(f.startY),d.push(f.endX),d.push(f.startY),d.push(f.endX),d.push(f.endY),d.push(f.startX),d.push(f.endY),d.push(f.startX),d.push(f.startY),Un(a,b,d,c);else if(f.type===
P.F){e=new Qd;e.startX=f.endX;e.startY=(f.startY+f.endY)/2;g=new Rd(Dd);g.startAngle=0;g.sweepAngle=360;g.centerX=(f.startX+f.endX)/2;g.centerY=(f.startY+f.endY)/2;g.radiusX=Math.abs(f.startX-f.endX)/2;g.radiusY=Math.abs(f.startY-f.endY)/2;e.add(g);f=Kd(g,e);h=f.length;if(0===h)d.push(g.centerX),d.push(g.centerY);else for(g=e.startX,e=e.startY,k=0;k<h;k++)p=f[k],K.Qe(g,e,p[2],p[3],p[4],p[5],p[6],p[7],.5,d),g=p[6],e=p[7];Un(a,b,d,c)}Ga(d);a.restore();a.Vc(!1)}}}};
t.jk=function(a,b,c){if(!Y.prototype.jk.call(this,a,b,c)||null===this.xc&&null===this.Ui||null===this.ua||null!==this.pathPattern||this.geometry.figures.length!==this.svg.getElementsByTagName("path").length)return!1;a.Hl=this.svg;this.hh(a,b);a.Hl=null;return!0};t.mk=function(a){return null!==this.geometry&&1<this.geometry.figures.length||null!==this.pathPattern?!0:Y.prototype.mk.call(this,a)};
t.Ev=function(a,b){if("g"!==a.nodeName)a.setAttributeNS(null,"filter",b);else{a=a.getElementsByTagName("path");for(var c=0;c<a.length;c++)a[c].setAttributeNS(null,"filter",b)}};t.ga=function(a,b){void 0===b&&(b=new I);if(a instanceof O){a.Mc()&&C("getDocumentPoint Spot must be a real, specific Spot, not: "+a.toString());var c=this.naturalBounds,d=this.strokeWidth;b.h(a.x*(c.width+d)-d/2+c.x+a.offsetX,a.y*(c.height+d)-d/2+c.y+a.offsetY)}else b.set(a);this.oe.Ga(b);return b};
t.mi=function(a){void 0===a&&(a=new N);var b=this.naturalBounds,c=this.oe;b=N.allocAt(b.x,b.y,b.width,b.height);var d=this.strokeWidth;b.Bc(d/2,d/2);d=I.allocAt(b.x,b.y).transform(c);a.h(d.x,d.y,0,0);d.h(b.right,b.y).transform(c);Xb(a,d.x,d.y,0,0);d.h(b.right,b.bottom).transform(c);Xb(a,d.x,d.y,0,0);d.h(b.x,b.bottom).transform(c);Xb(a,d.x,d.y,0,0);N.free(b);I.free(d);return a};
t.gh=function(a,b){var c=this.geometry;if(null===c||null===this.fill&&null===this.stroke)return!1;var d=c.bounds,e=this.strokeWidth/2;c.type!==P.o||b||(e+=2);var f=N.alloc();f.assign(d);f.Bc(e+2,e+2);if(!f.ca(a))return N.free(f),!1;d=e+1E-4;if(c.type===P.o){if(null===this.stroke)return!1;d=(c.endX-c.startX)*(a.x-c.startX)+(c.endY-c.startY)*(a.y-c.startY);if(0>(c.startX-c.endX)*(a.x-c.endX)+(c.startY-c.endY)*(a.y-c.endY)||0>d)return!1;N.free(f);return K.$b(c.startX,c.startY,c.endX,c.endY,e,a.x,a.y)}if(c.type===
P.u){b=c.startX;var g=c.startY,h=c.endX;c=c.endY;f.x=Math.min(b,h);f.y=Math.min(g,c);f.width=Math.abs(h-b);f.height=Math.abs(c-g);if(null===this.fill){f.Bc(-d,-d);if(f.ca(a))return N.free(f),!1;f.Bc(d,d)}null!==this.stroke&&f.Bc(e,e);a=f.ca(a);N.free(f);return a}if(c.type===P.F){g=c.startX;e=c.startY;h=c.endX;var k=c.endY;c=Math.min(g,h);b=Math.min(e,k);g=Math.abs(h-g)/2;e=Math.abs(k-e)/2;c=a.x-(c+g);b=a.y-(b+e);if(null===this.fill){g-=d;e-=d;if(0>=g||0>=e||1>=c*c/(g*g)+b*b/(e*e))return N.free(f),
!1;g+=d;e+=d}null!==this.stroke&&(g+=d,e+=d);N.free(f);return 0>=g||0>=e?!1:1>=c*c/(g*g)+b*b/(e*e)}if(c.type===P.j)return N.free(f),null===this.fill?Od(c,a.x,a.y,e):Md(c,a,e,1<this.strokeWidth,b);C("Unknown Geometry type: "+c.type)};
t.Wl=function(a,b,c,d){var e=this.desiredSize,f=this.ig;a=Math.max(a,0);b=Math.max(b,0);if(null!==this.Rd)var g=this.geometry.bounds;else{var h=this.figure,k=Vn[h];if(void 0===k){var l=K.Ze[h];"string"===typeof l&&(l=K.Ze[l]);"function"===typeof l?(k=l(null,100,100),Vn[h]=k):C("Unsupported Figure: "+h)}g=k.bounds}h=g.width;k=g.height;l=g.width;var m=g.height;switch(kl(this,!0)){case kg:d=c=0;break;case nd:l=Math.max(a-f,0);m=Math.max(b-f,0);break;case Qk:l=Math.max(a-f,0);d=0;break;case Rk:c=0,m=
Math.max(b-f,0)}isFinite(e.width)&&(l=e.width);isFinite(e.height)&&(m=e.height);var n=this.maxSize,p=this.minSize;c=Math.max(c-f,p.width);d=Math.max(d-f,p.height);l=Math.min(n.width,l);m=Math.min(n.height,m);l=isFinite(l)?Math.max(c,l):Math.max(h,c);m=isFinite(m)?Math.max(d,m):Math.max(k,d);c=lg(this);switch(c){case kg:break;case nd:h=l;k=m;break;case mg:c=Math.min(l/h,m/k);isFinite(c)||(c=1);h*=c;k*=c;break;default:C(c+" is not a valid geometryStretch.")}null!==this.Rd?(0===h&&(h=.001),0===k&&(k=
.001),c=null!==this.Rd?this.Rd:this.ua,g=c.bounds,d=h/g.width,g=k/g.height,isFinite(d)||(d=1),isFinite(g)||(g=1),this.ua=1===d&&1===g?c:c.copy().scale(d,g)):null!==this.ua&&K.$(this.ua.Rk,a-f)&&K.$(this.ua.Qk,b-f)||(this.ua=Bf.makeGeometry(this,h,k));g=this.ua.bounds;Infinity===a||Infinity===b?this.Ge(g.x-f/2,g.y-f/2,0===a&&0===h?0:g.width+f,0===b&&0===k?0:g.height+f):this.Ge(-(f/2),-(f/2),l+f,m+f);e.w()?(l=e.width,m=e.height,l=Math.min(n.width,l),m=Math.min(n.height,m),l=Math.max(p.width,l),m=Math.max(p.height,
m),this.ec.h(0,0,l,m)):this.ec.assign(g)};function lg(a){var b=a.geometryStretch;return null!==a.Rd?b===Sk?nd:b:b===Sk?Vn[a.figure].defaultStretch:b}t.eh=function(a,b,c,d){ql(this,a,b,c,d)};t.rs=function(a,b,c){return this.Rj(a.x,a.y,b.x,b.y,c)};
t.Rj=function(a,b,c,d,e){var f=this.transform,g=1/(f.m11*f.m22-f.m12*f.m21),h=f.m22*g,k=-f.m12*g,l=-f.m21*g,m=f.m11*g,n=g*(f.m21*f.dy-f.m22*f.dx),p=g*(f.m12*f.dx-f.m11*f.dy);f=a*h+b*l+n;g=a*k+b*m+p;h=c*h+d*l+n;k=c*k+d*m+p;n=this.ig/2;l=this.ua;null===l&&(this.measure(Infinity,Infinity),l=this.ua);var q=l.bounds;m=!1;if(l.type===P.o)if(1.5>=this.strokeWidth)m=K.Ve(l.startX,l.startY,l.endX,l.endY,f,g,h,k,e);else{l.startX===l.endX?(d=n,m=0):(b=(l.endY-l.startY)/(l.endX-l.startX),m=n/Math.sqrt(1+b*b),
d=m*b);b=Ea();a=new I;K.Ve(l.startX+d,l.startY+m,l.endX+d,l.endY+m,f,g,h,k,a)&&b.push(a);a=new I;K.Ve(l.startX-d,l.startY-m,l.endX-d,l.endY-m,f,g,h,k,a)&&b.push(a);a=new I;K.Ve(l.startX+d,l.startY+m,l.startX-d,l.startY-m,f,g,h,k,a)&&b.push(a);a=new I;K.Ve(l.endX+d,l.endY+m,l.endX-d,l.endY-m,f,g,h,k,a)&&b.push(a);h=b.length;if(0===h)return Ga(b),!1;m=!0;k=Infinity;for(d=0;d<h;d++)a=b[d],c=(a.x-f)*(a.x-f)+(a.y-g)*(a.y-g),c<k&&(k=c,e.x=a.x,e.y=a.y);Ga(b)}else if(l.type===P.u)m=K.Ff(q.x-n,q.y-n,q.x+q.width+
n,q.y+q.height+n,f,g,h,k,e);else if(l.type===P.F){b=N.allocAt(q.x,q.y,q.width,q.height).Bc(n,n);a:if(0===b.width)m=K.Ve(b.x,b.y,b.x,b.y+b.height,f,g,h,k,e);else if(0===b.height)m=K.Ve(b.x,b.y,b.x+b.width,b.y,f,g,h,k,e);else{a=b.width/2;l=b.height/2;d=b.x+a;m=b.y+l;c=9999;f!==h&&(c=(g-k)/(f-h));if(9999>Math.abs(c)){k=g-m-c*(f-d);if(0>a*a*c*c+l*l-k*k){e.x=NaN;e.y=NaN;m=!1;break a}n=Math.sqrt(a*a*c*c+l*l-k*k);h=(-(a*a*c*k)+a*l*n)/(l*l+a*a*c*c)+d;a=(-(a*a*c*k)-a*l*n)/(l*l+a*a*c*c)+d;l=c*(h-d)+k+m;k=c*
(a-d)+k+m;Math.abs((f-h)*(f-h))+Math.abs((g-l)*(g-l))<Math.abs((f-a)*(f-a))+Math.abs((g-k)*(g-k))?(e.x=h,e.y=l):(e.x=a,e.y=k)}else{h=l*l;k=f-d;h-=h/(a*a)*k*k;if(0>h){e.x=NaN;e.y=NaN;m=!1;break a}k=Math.sqrt(h);h=m+k;k=m-k;Math.abs(h-g)<Math.abs(k-g)?(e.x=f,e.y=h):(e.x=f,e.y=k)}m=!0}N.free(b)}else if(l.type===P.j){p=I.alloc();var r=h-f;var u=k-g,w=r*r+u*u,v=f,y=g;if(0<w&&q.contains(v,y)&&(p.x=v,p.y=y,this.ca(p)))for(0!==r&&.5>Math.abs(r)?(u*=.5/r,r=.5):0!==u&&.5>Math.abs(u)&&(r*=.5/u,u=.5),v-=r,y-=
u;q.contains(v,y);)v-=r,y-=u;e.x=h;e.y=k;for(u=0;u<l.figures.count;u++){var z=l.figures.s[u],A=z.isFilled?v:f,B=z.isFilled?y:g,E=z.segments;q=z.startX;r=z.startY;for(var L=q,R=r,S=0;S<E.count;S++){var J=E.s[S],G=J.type;var ca=J.endX;var X=J.endY;var na=!1;switch(G){case Ad:L=ca;R=X;break;case pd:na=Wn(q,r,ca,X,A,B,h,k,p);break;case Bd:na=K.Eo(q,r,J.point1X,J.point1Y,J.point2X,J.point2Y,ca,X,A,B,h,k,.6,p);break;case Cd:na=K.Eo(q,r,q+2/3*(J.point1X-q),r+2/3*(J.point1Y-r),ca+2/3*(J.point1X-ca),X+2/3*
(J.point1Y-X),ca,X,A,B,h,k,.6,p);break;case Dd:case Jd:G=J.type===Dd?Kd(J,z):Ld(J,z,q,r);var wa=G.length;if(0===wa){na=Wn(q,r,J.type===Dd?J.centerX:J.endX,J.type===Dd?J.centerY:J.endY,A,B,h,k,p);break}X=null;for(ca=0;ca<wa;ca++){X=G[ca];if(0===ca&&Wn(q,r,X[0],X[1],A,B,h,k,p)){var eb=Xn(A,B,p,w,e);eb<w&&(w=eb,m=!0)}K.Eo(X[0],X[1],X[2],X[3],X[4],X[5],X[6],X[7],A,B,h,k,.6,p)&&(eb=Xn(A,B,p,w,e),eb<w&&(w=eb,m=!0))}ca=X[6];X=X[7];break;default:C("Unknown Segment type: "+G)}q=ca;r=X;na&&(na=Xn(A,B,p,w,e),
na<w&&(w=na,m=!0));J.isClosed&&(ca=L,X=R,Wn(q,r,ca,X,A,B,h,k,p)&&(J=Xn(A,B,p,w,e),J<w&&(w=J,m=!0)))}}f=c-a;g=d-b;h=Math.sqrt(f*f+g*g);0!==h&&(f/=h,g/=h);e.x-=f*n;e.y-=g*n;I.free(p)}else C("Unknown Geometry type: "+l.type);if(!m)return!1;this.transform.Ga(e);return!0};function Xn(a,b,c,d,e){a=c.x-a;b=c.y-b;b=a*a+b*b;return b<d?(e.x=c.x,e.y=c.y,b):d}
function Wn(a,b,c,d,e,f,g,h,k){if(K.$(a,c)&&K.$(b,d)||K.$(e,g)&&K.$(f,h))return!1;var l=!1,m=(e-g)*(b-d)-(f-h)*(a-c);if(0===m)return!1;k.x=((e*h-f*g)*(a-c)-(e-g)*(a*d-b*c))/m;k.y=((e*h-f*g)*(b-d)-(f-h)*(a*d-b*c))/m;(a>c?a-c:c-a)<(b>d?b-d:d-b)?(a=b<d?b:d,b=b<d?d:b,(k.y>a||K.$(k.y,a))&&(k.y<b||K.$(k.y,b))&&(l=!0)):(b=a<c?a:c,a=a<c?c:a,(k.x>b||K.$(k.x,b))&&(k.x<a||K.$(k.x,a))&&(l=!0));return l}
t.fh=function(a,b){if(void 0===b)return a.Re(this.actualBounds);var c=this.ua;null===c&&(this.measure(Infinity,Infinity),c=this.ua);c=c.bounds;var d=this.strokeWidth/2,e=!1,f=I.alloc();f.h(c.x-d,c.y-d);a.ca(b.Ga(f))&&(f.h(c.x-d,c.bottom+d),a.ca(b.Ga(f))&&(f.h(c.right+d,c.bottom+d),a.ca(b.Ga(f))&&(f.h(c.right+d,c.y-d),a.ca(b.Ga(f))&&(e=!0))));I.free(f);return e};
t.Yc=function(a,b){if(this.fh(a,b)||void 0===b&&(b=this.transform,a.Re(this.actualBounds)))return!0;var c=ld.alloc();c.set(b);c.Uj();var d=a.left,e=a.right,f=a.top;a=a.bottom;var g=I.alloc();g.h(d,f);c.Ga(g);if(this.gh(g,!0))return I.free(g),!0;g.h(e,f);c.Ga(g);if(this.gh(g,!0))return I.free(g),!0;g.h(d,a);c.Ga(g);if(this.gh(g,!0))return I.free(g),!0;g.h(e,a);c.Ga(g);if(this.gh(g,!0))return I.free(g),!0;var h=I.alloc(),k=I.alloc();c.set(b);c.cv(this.transform);c.Uj();h.x=e;h.y=f;h.transform(c);g.x=
d;g.y=f;g.transform(c);b=!1;Yn(this,g,h,k)?b=!0:(g.x=e,g.y=a,g.transform(c),Yn(this,g,h,k)?b=!0:(h.x=d,h.y=a,h.transform(c),Yn(this,g,h,k)?b=!0:(g.x=d,g.y=f,g.transform(c),Yn(this,g,h,k)&&(b=!0))));I.free(g);ld.free(c);I.free(h);I.free(k);return b};function Yn(a,b,c,d){if(!a.rs(b,c,d))return!1;a=b.x;b=b.y;var e=c.x,f=c.y;c=d.x;d=d.y;if(a===e)return b<f?(a=b,b=f):a=f,d>=a&&d<=b;a<e?(d=a,a=e):d=e;return c>=d&&c<=a}
t.ux=function(a,b,c){function d(a,b){for(var c=a.length,d=0;d<c;d+=2)if(b.zd(a[d],a[d+1])>e)return!0;return!1}if(c&&null!==this.fill&&this.gh(a,!0))return!0;var e=a.Bf(b),f=e;1.5<this.strokeWidth&&(e=this.strokeWidth/2+Math.sqrt(e),e*=e);b=this.ua;if(null===b&&(this.measure(Infinity,Infinity),b=this.ua,null===b))return!1;if(!c){var g=b.bounds,h=g.x,k=g.y,l=g.x+g.width;g=g.y+g.height;if(zb(a.x,a.y,h,k)<=e&&zb(a.x,a.y,l,k)<=e&&zb(a.x,a.y,h,g)<=e&&zb(a.x,a.y,l,g)<=e)return!0}h=b.startX;k=b.startY;l=
b.endX;g=b.endY;if(b.type===P.o){if(c=(h-l)*(a.x-l)+(k-g)*(a.y-g),yb(a.x,a.y,h,k,l,g)<=(0<=(l-h)*(a.x-h)+(g-k)*(a.y-k)&&0<=c?e:f))return!0}else{if(b.type===P.u)return b=!1,c&&(b=yb(a.x,a.y,h,k,h,g)<=e||yb(a.x,a.y,h,k,l,k)<=e||yb(a.x,a.y,l,k,l,g)<=e||yb(a.x,a.y,h,g,l,g)<=e),b;if(b.type===P.F){b=a.x-(h+l)/2;f=a.y-(k+g)/2;var m=Math.abs(l-h)/2,n=Math.abs(g-k)/2;if(0===m||0===n)return yb(a.x,a.y,h,k,l,g)<=e?!0:!1;if(c){if(a=K.az(m,n,b,f),a*a<=e)return!0}else return zb(b,f,-m,0)>=e||zb(b,f,0,-n)>=e||zb(b,
f,0,n)>=e||zb(b,f,m,0)>=e?!1:!0}else if(b.type===P.j){l=b.bounds;f=l.x;h=l.y;k=l.x+l.width;l=l.y+l.height;if(a.x>k&&a.x<f&&a.y>l&&a.y<h&&yb(a.x,a.y,f,h,f,l)>e&&yb(a.x,a.y,f,h,k,h)>e&&yb(a.x,a.y,k,l,f,l)>e&&yb(a.x,a.y,k,l,k,h)>e)return!1;f=Math.sqrt(e);if(c){if(null===this.fill?Od(b,a.x,a.y,f):Md(b,a,f,!0,!1))return!0}else{c=b.figures;for(b=0;b<c.count;b++){f=c.s[b];g=f.startX;m=f.startY;if(a.zd(g,m)>e)return!1;h=f.segments.s;k=h.length;for(l=0;l<k;l++)switch(n=h[l],n.type){case Ad:case pd:g=n.endX;
m=n.endY;if(a.zd(g,m)>e)return!1;break;case Bd:var p=Ea();K.Qe(g,m,n.point1X,n.point1Y,n.point2X,n.point2Y,n.endX,n.endY,.8,p);g=d(p,a);Ga(p);if(g)return!1;g=n.endX;m=n.endY;if(a.zd(g,m)>e)return!1;break;case Cd:p=Ea();K.ep(g,m,n.point1X,n.point1Y,n.endX,n.endY,.8,p);g=d(p,a);Ga(p);if(g)return!1;g=n.endX;m=n.endY;if(a.zd(g,m)>e)return!1;break;case Dd:case Jd:p=n.type===Dd?Kd(n,f):Ld(n,f,g,m);var q=p.length;if(0===q){g=n.type===Dd?n.centerX:n.endX;m=n.type===Dd?n.centerY:n.endY;if(a.zd(g,m)>e)return!1;
break}n=null;for(var r=Ea(),u=0;u<q;u++)if(n=p[u],r.length=0,K.Qe(n[0],n[1],n[2],n[3],n[4],n[5],n[6],n[7],.8,r),d(r,a))return Ga(r),!1;Ga(r);null!==n&&(g=n[6],m=n[7]);break;default:C("Unknown Segment type: "+n.type)}}return!0}}}return!1};t.rc=function(){this.ua=null};t.kq=function(){return this.ig};
function Zn(a){var b=a.diagram;null!==b&&b.undoManager.isUndoingRedoing||(a.segmentOrientation=on,"None"!==a.toArrow?(a.segmentIndex=-1,a.alignmentFocus=cd):"None"!==a.fromArrow&&(a.segmentIndex=0,a.alignmentFocus=new O(1-cd.x,cd.y)))}
Bf.makeGeometry=function(a,b,c){if("None"!==a.toArrow)var d=$n[a.toArrow];else"None"!==a.fromArrow?d=$n[a.fromArrow]:(d=K.Ze[a.figure],"string"===typeof d&&(d=K.Ze[d]),void 0===d&&C("Unknown Shape.figure: "+a.figure),d=d(a,b,c),d.Rk=b,d.Qk=c);if(null===d){var e=K.Ze.Rectangle;"function"===typeof e&&(d=e(a,b,c))}return d};
function ao(a){var b=$n[a];if(void 0===b){var c=a.toLowerCase();if("none"===c)return"None";b=$n[c];if(void 0===b){var d=null,e;for(e in K.um)if(e.toLowerCase()===c){d=e;break}if(null!==d)return a=P.parse(K.um[d],!1),$n[d]=a,c!==d&&($n[c]=d),d}}return"string"===typeof b?b:b instanceof P?a:null}
ma.Object.defineProperties(Bf.prototype,{geometry:{get:function(){return null!==this.ua?this.ua:this.Rd},set:function(a){var b=this.ua,c=this.Rd;if(b!==a){null!==a?this.Rd=this.ua=a.freeze():this.Rd=this.ua=null;var d=this.part;null!==d&&(d.ag=NaN);this.v();this.g("geometry",b||c,a);$k(this)&&(a=this.part,null!==a&&al(this,a,"geometryString"))}}},geometryString:{get:function(){return null===this.geometry?"":this.geometry.toString()},set:function(a){a=
P.parse(a);var b=a.normalize();this.geometry=a;this.position=a=I.allocAt(-b.x,-b.y);I.free(a)}},isGeometryPositioned:{get:function(){return 0!==(this.l&1048576)},set:function(a){var b=0!==(this.l&1048576);b!==a&&(this.l^=1048576,this.v(),this.g("isGeometryPositioned",b,a))}},fill:{get:function(){return this.Ui},set:function(a){var b=this.Ui;b!==a&&(null!==a&&hm(a,"Shape.fill"),a instanceof vl&&a.freeze(),this.Ui=a,this.N(),this.g("fill",
b,a))}},stroke:{get:function(){return this.xc},set:function(a){var b=this.xc;b!==a&&(null!==a&&hm(a,"Shape.stroke"),a instanceof vl&&a.freeze(),this.xc=a,this.N(),this.g("stroke",b,a))}},strokeWidth:{get:function(){return this.ig},set:function(a){var b=this.ig;if(b!==a)if(0<=a){this.ig=a;this.v();var c=this.part;null!==c&&(c.ag=NaN);this.g("strokeWidth",b,a)}else xa(a,"value >= 0",Bf,"strokeWidth:value")}},strokeCap:{
get:function(){return null!==this.Na?this.Na.Hr:"butt"},set:function(a){var b=this.strokeCap;b!==a&&("string"!==typeof a||"butt"!==a&&"round"!==a&&"square"!==a?xa(a,'"butt", "round", or "square"',Bf,"strokeCap"):(Sn(this),this.Na.Hr=a,this.N(),this.g("strokeCap",b,a)))}},strokeJoin:{get:function(){return null!==this.Na?this.Na.Ir:"miter"},set:function(a){var b=this.strokeJoin;b!==a&&("string"!==typeof a||"miter"!==a&&"bevel"!==a&&"round"!==a?xa(a,'"miter", "bevel", or "round"',
Bf,"strokeJoin"):(Sn(this),this.Na.Ir=a,this.N(),this.g("strokeJoin",b,a)))}},strokeMiterLimit:{get:function(){return null!==this.Na?this.Na.Jr:10},set:function(a){var b=this.strokeMiterLimit;if(b!==a&&1<=a){Sn(this);this.Na.Jr=a;this.N();var c=this.part;null!==c&&(c.ag=NaN);this.g("strokeMiterLimit",b,a)}}},strokeDashArray:{get:function(){return this.Yg},set:function(a){var b=this.Yg;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=
a[e];0<=f&&isFinite(f)||C("strokeDashArray:value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Yg=a;this.N();this.g("strokeDashArray",b,a)}}},strokeDashOffset:{get:function(){return null!==this.Na?this.Na.uj:0},set:function(a){var b=this.strokeDashOffset;b!==a&&0<=a&&(Sn(this),this.Na.uj=a,this.N(),this.g("strokeDashOffset",b,a))}},figure:{get:function(){return this.an},set:function(a){var b=
this.an;if(b!==a){var c=K.Ze[a];"function"===typeof c?c=a:(c=K.Ze[a.toLowerCase()])||C("Unknown Shape.figure: "+a);b!==c&&(a=this.part,null!==a&&(a.ag=NaN),this.an=c,this.Rd=null,this.rc(),this.v(),this.g("figure",b,c))}}},toArrow:{get:function(){return null!==this.Ua?this.Ua.V:"None"},set:function(a){var b=this.toArrow;!0===a?a="Standard":!1===a&&(a="");if(b!==a){var c=ao(a);null===c?C("Unknown Shape.toArrow: "+a):b!==c&&(null===this.Ua&&(this.Ua=new gm),this.Ua.V=c,
this.Rd=null,this.rc(),this.v(),Zn(this),this.g("toArrow",b,c))}}},fromArrow:{get:function(){return null!==this.Ua?this.Ua.j:"None"},set:function(a){var b=this.fromArrow;!0===a?a="Standard":!1===a&&(a="");if(b!==a){var c=ao(a);null===c?C("Unknown Shape.fromArrow: "+a):b!==c&&(null===this.Ua&&(this.Ua=new gm),this.Ua.j=c,this.Rd=null,this.rc(),this.v(),Zn(this),this.g("fromArrow",b,c))}}},spot1:{get:function(){return null!==this.Na?this.Na.fg:
Sc},set:function(a){var b=this.spot1;b.D(a)||(a=a.J(),Sn(this),this.Na.fg=a,this.v(),this.g("spot1",b,a))}},spot2:{get:function(){return null!==this.Na?this.Na.gg:Sc},set:function(a){var b=this.spot2;b.D(a)||(a=a.J(),Sn(this),this.Na.gg=a,this.v(),this.g("spot2",b,a))}},parameter1:{get:function(){return this.Un},set:function(a){var b=this.Un;b!==a&&(this.Un=a,this.rc(),this.v(),this.g("parameter1",b,a))}},parameter2:{
get:function(){return this.Vn},set:function(a){var b=this.Vn;b!==a&&(this.Vn=a,this.rc(),this.v(),this.g("parameter2",b,a))}},naturalBounds:{get:function(){return this.ec}},pathPattern:{get:function(){return null!==this.Na?this.Na.br:null},set:function(a){var b=this.pathPattern;b!==a&&(Sn(this),this.Na.br=a,this.N(),this.g("pathPattern",b,a))}},geometryStretch:{get:function(){return null!==this.Na?this.Na.jq:
Sk},set:function(a){var b=this.geometryStretch;b!==a&&(Sn(this),this.Na.jq=a,this.v(),this.g("geometryStretch",b,a))}},interval:{get:function(){return null!==this.fa?this.fa.Sk:1},set:function(a){var b=this.interval;a=Math.round(a);if(b!==a&&0!==a&&isFinite(a)){null===this.fa&&(this.fa=new bo);this.fa.Sk=a;var c=this.diagram;null!==c&&this.panel===c.grid&&vj(c);this.v();c=this.panel;null!==c&&(c.graduatedIntervals=null);this.g("interval",b,a)}}},graduatedStart:{
get:function(){return null!==this.fa?this.fa.u:0},set:function(a){var b=this.graduatedStart;b!==a&&(0>a?a=0:1<a&&(a=1),null===this.fa&&(this.fa=new bo),this.fa.u=a,this.v(),this.g("graduatedStart",b,a))}},graduatedEnd:{get:function(){return null!==this.fa?this.fa.j:1},set:function(a){var b=this.graduatedEnd;b!==a&&(0>a?a=0:1<a&&(a=1),null===this.fa&&(this.fa=new bo),this.fa.j=a,this.v(),this.g("graduatedEnd",b,a))}},graduatedSkip:{
get:function(){return null!==this.fa?this.fa.o:null},set:function(a){var b=this.graduatedSkip;b!==a&&(null===this.fa&&(this.fa=new bo),this.fa.o=a,this.v(),this.g("graduatedSkip",b,a))}}});Bf.prototype.intersectsRect=Bf.prototype.Yc;Bf.prototype.containedInRect=Bf.prototype.fh;Bf.prototype.getNearestIntersectionPoint=Bf.prototype.rs;Bf.prototype.getDocumentBounds=Bf.prototype.mi;Bf.prototype.getDocumentPoint=Bf.prototype.ga;var $n=new Ua,Vn=new Ua;Bf.className="Shape";
Bf.getFigureGenerators=function(){var a=new ob,b;for(b in K.Ze)b!==b.toLowerCase()&&a.add(b,K.Ze[b]);a.freeze();return a};Bf.defineFigureGenerator=function(a,b){var c=a.toLowerCase(),d=K.Ze;d[a]=b;d[c]=a};Bf.getArrowheadGeometries=function(){var a=new ob;for(d in K.um)if(void 0===$n[d]){var b=P.parse(K.um[d],!1);$n[d]=b;b=d.toLowerCase();b!==d&&($n[b]=d)}for(var c in $n)if(c!==c.toLowerCase()){var d=$n[c];d instanceof P&&a.add(c,d)}a.freeze();return a};
Bf.defineArrowheadGeometry=function(a,b){var c=null;"string"===typeof b?c=P.parse(b,!1):c=b;b=a.toLowerCase();"none"!==b&&a!==b||C("Shape.defineArrowheadGeometry name must not be empty or None or all-lower-case: "+a);var d=$n;d[a]=c;d[b]=a};function Tn(){this.oc=!1;this.Hr="butt";this.Ir="miter";this.Jr=10;this.uj=0;this.br=null;this.jq=Sk;this.gg=this.fg=Sc}
Tn.prototype.copy=function(){var a=new Tn;a.Hr=this.Hr;a.Ir=this.Ir;a.Jr=this.Jr;a.uj=this.uj;a.br=this.br;a.jq=this.jq;a.fg=this.fg.J();a.gg=this.gg.J();return a};
function Ng(a,b){Y.call(this);co||(eo=Lg?(new Hi(null)).context:null,co=!0);this.l|=2097152;this.fc="";this.xc="black";this.Ae="13px sans-serif";this.Lq=this.Db=this.Gc=0;this.da=this.fa=this.Gm=null;this.Ih=0;this.df=null;this.Le=0;this.ze=this.Sb=this.Qc=null;"string"===typeof a?this.text=a:a&&Object.assign(this,a);b&&Object.assign(this,b);return this}la(Ng,Y);
Ng.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.fc=this.fc;a.xc=this.xc;a.Ae=this.Ae;a.Gc=this.Gc;a.Db=this.Db;a.Lq=this.Lq;a.Gm=this.Gm;null!==this.fa&&(a.fa=this.fa.copy());null!==this.da&&(a.da=this.da.oc?this.da:this.da.copy());a.Ih=this.Ih;a.df=this.df;a.Le=this.Le;a.Qc=this.Qc;a.Sb=this.Sb;a.ze=this.ze};function fo(a){null===a.da?a.da=new go:a.da.oc&&(a.da=a.da.copy())}t=Ng.prototype;t.th=function(){Y.prototype.th.call(this);null!==this.da&&(this.da.oc=!0)};
function nn(a,b){a.l=b.l|6144;a.pb=b.opacity;a.Ma=b.background;a.areaBackground=b.areaBackground;a.jd=b.desiredSize.J();a.minSize=b.minSize.J();a.maxSize=b.maxSize.J();null!==b.Mb?a.Mb=b.Mb.copy():a.Mb=null;a.Ca=b.scale;a.Lb=b.angle;a.stretch=b.stretch;a.Kg=b.margin.J();a.Kb=b.alignment.J();a.rk=b.alignmentFocus.J();a.segmentFraction=b.segmentFraction;a.segmentOffset=b.segmentOffset.J();a.segmentOrientation=b.segmentOrientation;null!==b.tb&&(a.tb=b.tb.copy());a.shadowVisible=b.shadowVisible;a.fc=
b.fc;a.xc=b.xc;a.Ae=b.Ae;null!==a.fa&&(a.fa=b.fa.copy());null!==b.da&&(a.da=b.da.oc?b.da:b.da.copy());a.Ih=b.Ih}t.qb=function(a){a.classType===Ng?this.wrap=a:Y.prototype.qb.call(this,a)};t.toString=function(){return 22<this.fc.length?'TextBlock("'+this.fc.substring(0,20)+'"...)':'TextBlock("'+this.fc+'")'};t.v=function(){Y.prototype.v.call(this)};
t.hh=function(a,b){if(null!==this.xc&&0!==this.fc.length&&null!==this.Ae){var c=this.naturalBounds,d=this.actualBounds,e=c.width,f=c.height,g=ho(this),h=a.textAlign=this.textAlign,k=b.tn;"start"===h?h=k?"right":"left":"end"===h&&(h=k?"left":"right");k=this.isUnderline;var l=this.isStrikethrough;ji(this,a,this.xc,!0,!1,c,d);(k||l)&&ji(this,a,this.xc,!1,!1,c,d);d=0;c=!1;var m=this.oe,n=m.dx-(g*m.m21+m.dx);m=m.dy-(g*m.m22+m.dy);var p=b.scale;8>(n*n+m*m)*p*p&&(c=!0);b.ib!==a&&(c=!1);b.ib instanceof ii&&
(c=!1);!1===b.Gf("textGreeking")&&(c=!1);b=this.spacingAbove;n=this.spacingBelow;switch(this.flip){case Vk:a.translate(e,0);a.scale(-1,1);break;case Uk:a.translate(0,f);a.scale(1,-1);break;case Wk:a.translate(e,f),a.scale(-1,-1)}a.yc();m=this.Gc;p=(b+g+n)*m;f>p&&(d=this.verticalAlignment,d=d.y*f-d.y*p+d.offsetY);if(1===m&&null!==this.ze)n=this.Db,n>e&&(n=e),this.Nj(this.ze,a,0,d+b,e,g,n,c,h,k,l);else if(null!==this.Qc&&null!==this.Sb)for(p=0;p<m;p++){var q=this.Qc[p];q>e&&(q=e);d+=b;this.Nj(this.Sb[p],
a,0,d,e,g,q,c,h,k,l);d+=g+n}switch(this.flip){case Vk:a.scale(-1,1);a.translate(-e,0);break;case Uk:a.scale(1,-1);a.translate(0,-f);break;case Wk:a.scale(-1,-1),a.translate(-e,-f)}}};
t.Nj=function(a,b,c,d,e,f,g,h,k,l,m){var n=0;if(h)"left"===k?n=0:"right"===k?n=e-g:"center"===k&&(n=(e-g)/2),b.fillRect(c+n,d+.25*f,g,1);else{"left"===k?n=0:"right"===k?n=e:"center"===k&&(n=e/2);e=null!==io?io(this,f):.75*f;b.fillText(a,c+n,d+e);a=f/20|0;0===a&&(a=1);"right"===k?n-=g:"center"===k&&(n-=g/2);if(l){h=null!==jo?jo(this,f):.8*f;k=c+n;l=d+h;e=c+n+g;h=d+h;var p=a;b.beginPath();b.lineWidth=p;b.moveTo(k,l);b.lineTo(e,h);b.stroke();b.Se();b instanceof ii&&b.ad.classList.add("gojs-td")}m&&(m=
d+f-f/2.2|0,0!==a%2&&(m+=.5),d=c+n,f=m,c=c+n+g,g=m,n=a,b.beginPath(),b.lineWidth=n,b.moveTo(d,f),b.lineTo(c,g),b.stroke(),b.Se(),b instanceof ii&&b.ad.classList.add("gojs-td"))}};
t.jk=function(a,b,c){if(!Y.prototype.jk.call(this,a,b,c))return!1;ji(this,a,this.xc,!0,!1,this.naturalBounds,this.actualBounds);if(c){if(0===this.svg.getElementsByTagName("text").length)return!0;b=this.svg.getElementsByClassName("gojs-td");for(c=0;c<b.length;c++)qm(a,b[c],!1)}else b=this.svg,b.innerHTML=this.text,qm(a,b,!0);return!0};t.mk=function(a){return 1<this.lineCount||this.isUnderline||this.isStrikethrough?!0:Y.prototype.mk.call(this,a)};
t.Ev=function(a,b){if("g"!==a.nodeName)a.setAttributeNS(null,"filter",b);else{a=a.getElementsByTagName("text");for(var c=0;c<a.length;c++)a[c].setAttributeNS(null,"filter",b)}};
t.Wl=function(a,b,c,d){var e=this.Gc;this.Ih=a;this.l&=-16777217;var f=this.Ae;null!==eo&&ko!==f&&(ko=eo.font=f);this.Db=this.Le=0;this.ze=this.Sb=this.Qc=this.df=null;var g;if(isNaN(this.desiredSize.width)){f=this.fc.replace(/\r\n/g,"\n").replace(/\r/g,"\n");if(0===f.length)f=8;else if(this.isMultiline){for(var h=g=0,k=!1;!k;){var l=f.indexOf("\n",h);-1===l&&(l=f.length,k=!0);h=f.substr(h,l-h);this.formatting===lo&&(h=h.trim());g=Math.max(g,mo(h));h=l+1}f=g}else g=f.indexOf("\n",0),0<=g&&(f=f.substr(0,
g)),f=mo(f);f=Math.min(f,a/this.scale)}else f=this.desiredSize.width;null!==this.panel&&(f=Math.min(f,this.panel.maxSize.width));g=no(this,f);isNaN(this.desiredSize.height)?g=Math.min(g,b/this.scale):g=this.desiredSize.height;k=g;if(0!==this.Db&&null!==this.Qc&&null!==this.Sb&&this.overflow===oo&&(b=this.Ae,b=this.overflow===oo?po(b):0,l=this.spacingAbove+this.spacingBelow,l=Math.max(0,ho(this)+l),k=Math.min(this.maxLines-1,Math.max(Math.floor(k/l+.01)-1,0)),!(k+1>=this.Sb.length))){this.l|=16777216;
l=this.Sb[k];for(b=Math.max(1,a-b);mo(l)>b&&1<l.length;)l=l.substr(0,l.length-1);l+=qo;b=mo(l);this.Sb[k]=l;this.Sb=this.Sb.slice(0,k+1);this.Qc[k]=b;this.Qc=this.Qc.slice(0,k+1);this.Le=this.Sb.length;this.Db=Math.max(this.Db,b);this.Gc=this.Le;1===this.Gc&&(this.ze=this.Sb[0])}if(this.wrap===ro||isNaN(this.desiredSize.width))f=isNaN(a)?this.Db:Math.min(a,this.Db);f=Math.max(c,f);g=Math.max(d,g);Pb(this.ec,f,g);this.Ge(0,0,f,g);null!==this.svg&&e!==this.Gc&&(this.Uc=!0)};
t.eh=function(a,b,c,d){ql(this,a,b,c,d)};function so(a,b,c){if(null===a.ze)a.ze=b,a.Db=c;else{if(null===a.Sb||null===a.Qc)a.Sb=[],a.Qc=[],a.Sb.push(a.ze),a.Qc.push(a.Db);a.Sb.push(b);a.Qc.push(c);a.Sb.length>a.maxLines&&(a.l|=16777216)}}
function to(a,b,c,d){var e=a.formatting===lo;e&&(b=b.trim());var f=0,g=a.Ae,h=a.spacingAbove+a.spacingBelow;h=Math.max(0,ho(a)+h);var k=a.overflow===oo?po(g):0;if(a.Gc>=a.maxLines)null!==d&&d.h(0,h);else if(g=b,a.wrap===uo){a.Le=1;var l=mo(b);if(0===k||l<=c)a.Db=Math.max(a.Db,l),so(a,b,a.Db),null!==d&&d.h(l,h);else{f=vo(a,g);g=g.substr(f.length);b=vo(a,g);for(l=mo(f+b);0<b.length&&l<=c;)f+=b,g=g.substr(b.length),b=vo(a,g),l=f+b,e&&(l=l.trim()),l=mo(l);e&&(b=b.trim());f+=b;for(c=Math.max(1,c-k);mo(f)>
c&&1<f.length;)f=f.substr(0,f.length-1);a.l|=16777216;f+=qo;var m=mo(f);a.Db=m;so(a,f,m);null!==d&&d.h(m,h)}}else{k=0;0===g.length&&(k=1,so(a,g,0));for(;0<g.length;){b=vo(a,g);for(g=g.substr(b.length);mo(b)>c;){var n=1;l=mo(b.substr(0,n));for(m=0;l<=c;)n++,m=l,l=mo(b.substr(0,n));if(1===n){var p=l;f=Math.max(f,l)}else p=m,f=Math.max(f,m);n--;1>n&&(n=1);so(a,b.substr(0,n),p);k++;b=b.substr(n);if(a.Gc+k>a.maxLines)break}m=vo(a,g);for(l=mo(b+m);0<m.length&&l<=c;)b+=m,g=g.substr(m.length),m=vo(a,g),l=
b+m,e&&(l=l.trim()),l=mo(l);e&&(b=b.trim());if(""!==b&&("\u00ad"===b[b.length-1]&&(b=b.substring(0,b.length-1)+"\u2010"),0===m.length?(n=l,f=Math.max(f,l)):(n=m=mo(b),f=Math.max(f,m)),so(a,b,n),k++,a.Gc+k>a.maxLines))break}a.Le=Math.min(a.maxLines,k);a.Db=Math.max(a.Db,f);null!==d&&d.h(a.Db,h*a.Le)}}
function vo(a,b){if(a.wrap===wo)return b.substr(0,1);for(var c=b.length,d=0,e=xo;d<c&&!e.test(b.charAt(d));)d++;for(a.formatting===yo&&(c=Math.min(c,d+1));d<c&&e.test(b.charAt(d));)d++;return d>=b.length?b:b.substr(0,d)}function mo(a){return null===eo?8*a.length:eo.measureText(a).width}function ho(a){if(null!==a.df)return a.df;var b=a.Ae;if(null===eo){var c=16;return a.df=c}void 0!==zo[b]&&5E3>Ao?c=zo[b]:(c=1.3*eo.measureText("M").width,zo[b]=c,Ao++);return a.df=c}
function po(a){if(null===eo)return 6;if(void 0!==Bo[a]&&5E3>Fo)var b=Bo[a];else b=eo.measureText(qo).width,Bo[a]=b,Fo++;return b}
function no(a,b){var c=a.fc.replace(/\r\n/g,"\n").replace(/\r/g,"\n"),d=a.spacingAbove+a.spacingBelow;d=Math.max(0,ho(a)+d);if(0===c.length)return a.Db=0,a.Gc=1,d;if(!a.isMultiline){var e=c.indexOf("\n",0);0<=e&&(c=c.substr(0,e))}e=0;for(var f=a.Gc=0,g,h=!1;!h;){g=c.indexOf("\n",f);-1===g&&(g=c.length,h=!0);if(f<=g){f=c.substr(f,g-f);if(a.wrap!==uo){a.Le=0;var k=M.alloc();to(a,f,b,k);e+=k.height;M.free(k);a.Gc+=a.Le}else to(a,f,b,null),e+=d,a.Gc++;a.Gc===a.maxLines&&(h=!0)}f=g+1}return a.Lq=e}
ma.Object.defineProperties(Ng.prototype,{font:{get:function(){return this.Ae},set:function(a){var b=this.Ae;b!==a&&(this.Ae=a,this.df=null,this.v(),this.g("font",b,a))}},text:{get:function(){return this.fc},set:function(a){var b=this.fc;null!==a&&void 0!==a?a=a.toString():a="";b!==a&&(this.fc=a,this.v(),this.g("text",b,a),this.Uc=!0)}},textAlign:{get:function(){return null!==this.da?this.da.Or:"start"},set:function(a){var b=
this.textAlign;b===a||"start"!==a&&"end"!==a&&"left"!==a&&"right"!==a&&"center"!==a||(fo(this),this.da.Or=a,this.N(),this.g("textAlign",b,a))}},flip:{get:function(){return null!==this.da?this.da.Xf:kg},set:function(a){var b=this.flip;b!==a&&(fo(this),this.da.Xf=a,this.N(),this.g("flip",b,a))}},verticalAlignment:{get:function(){return null!==this.da?this.da.Yr:Vc},set:function(a){var b=this.verticalAlignment;b.D(a)||(a=a.J(),fo(this),this.da.Yr=
a,Sl(this),this.g("verticalAlignment",b,a))}},naturalBounds:{get:function(){if(!this.ec.w()){var a=M.alloc();to(this,this.fc,999999,a);var b=a.width;M.free(a);a=no(this,b);var c=this.desiredSize;isNaN(c.width)||(b=c.width);isNaN(c.height)||(a=c.height);Pb(this.ec,b,a)}return this.ec}},isMultiline:{get:function(){return 0!==(this.l&2097152)},set:function(a){var b=0!==(this.l&2097152);b!==a&&(this.l^=2097152,this.v(),this.g("isMultiline",b,
a))}},isUnderline:{get:function(){return 0!==(this.l&4194304)},set:function(a){var b=0!==(this.l&4194304);b!==a&&(this.l^=4194304,this.Uc=!0,this.N(),this.g("isUnderline",b,a))}},isStrikethrough:{get:function(){return 0!==(this.l&8388608)},set:function(a){var b=0!==(this.l&8388608);b!==a&&(this.l^=8388608,this.Uc=!0,this.N(),this.g("isStrikethrough",b,a))}},wrap:{get:function(){return null!==this.da?this.da.$r:
Go},set:function(a){var b=this.wrap;b!==a&&(fo(this),this.da.$r=a,this.v(),this.g("wrap",b,a))}},overflow:{get:function(){return null!==this.da?this.da.$q:Ho},set:function(a){var b=this.overflow;b!==a&&(fo(this),this.da.$q=a,this.v(),this.g("overflow",b,a))}},isOverflowed:{get:function(){return 0!==(this.l&16777216)}},stroke:{get:function(){return this.xc},set:function(a){var b=this.xc;b!==a&&(null!==a&&hm(a,
"TextBlock.stroke"),a instanceof vl&&a.freeze(),this.xc=a,this.N(),this.g("stroke",b,a))}},lineCount:{get:function(){return this.Gc}},lineHeight:{get:function(){return ho(this)}},editable:{get:function(){return 0!==(this.l&1048576)},set:function(a){var b=0!==(this.l&1048576);b!==a&&(this.l^=1048576,this.g("editable",b,a))}},textEditor:{get:function(){return null!==this.da?this.da.Qr:
null},set:function(a){var b=this.textEditor;b!==a&&(fo(this),this.da.Qr=a,this.g("textEditor",b,a))}},errorFunction:{get:function(){return null!==this.da?this.da.Hd:null},set:function(a){var b=this.errorFunction;b!==a&&(fo(this),this.da.Hd=a,this.g("errorFunction",b,a))}},interval:{get:function(){return null!==this.fa?this.fa.Sk:1},set:function(a){var b=this.interval;a=Math.round(a);if(b!==a&&0!==a&&isFinite(a)){null===this.fa&&(this.fa=
new bo);this.fa.Sk=a;this.v();var c=this.panel;null!==c&&(c.graduatedIntervals=null);this.g("interval",b,a)}}},graduatedStart:{get:function(){return null!==this.fa?this.fa.u:0},set:function(a){var b=this.graduatedStart;b!==a&&(0>a?a=0:1<a&&(a=1),null===this.fa&&(this.fa=new bo),this.fa.u=a,this.v(),this.g("graduatedStart",b,a))}},graduatedEnd:{get:function(){return null!==this.fa?this.fa.j:1},set:function(a){var b=this.graduatedEnd;b!==a&&
(0>a?a=0:1<a&&(a=1),null===this.fa&&(this.fa=new bo),this.fa.j=a,this.v(),this.g("graduatedEnd",b,a))}},graduatedFunction:{get:function(){return null!==this.fa?this.fa.F:null},set:function(a){var b=this.graduatedFunction;b!==a&&(null===this.fa&&(this.fa=new bo),this.fa.F=a,this.v(),this.g("graduatedFunction",b,a))}},graduatedSkip:{get:function(){return null!==this.fa?this.fa.o:null},set:function(a){var b=this.graduatedSkip;b!==a&&(null===
this.fa&&(this.fa=new bo),this.fa.o=a,this.v(),this.g("graduatedSkip",b,a))}},textValidation:{get:function(){return null!==this.da?this.da.vj:null},set:function(a){var b=this.textValidation;b!==a&&(fo(this),this.da.vj=a,this.g("textValidation",b,a))}},textEdited:{get:function(){return null!==this.da?this.da.Pr:null},set:function(a){var b=this.textEdited;b!==a&&(fo(this),this.da.Pr=a,this.g("textEdited",b,a))}},spacingAbove:{
get:function(){return null!==this.da?this.da.Dr:0},set:function(a){var b=this.spacingAbove;b!==a&&(fo(this),this.da.Dr=a,this.v(),this.g("spacingAbove",b,a))}},spacingBelow:{get:function(){return null!==this.da?this.da.Er:0},set:function(a){var b=this.spacingBelow;b!==a&&(fo(this),this.da.Er=a,this.v(),this.g("spacingBelow",b,a))}},formatting:{get:function(){return null!==this.da?this.da.gq:lo},set:function(a){var b=this.formatting;
b!==a&&(fo(this),this.da.gq=a,this.v(),this.g("formatting",b,a))}},maxLines:{get:function(){return null!==this.da?this.da.Jq:Infinity},set:function(a){var b=this.maxLines;b!==a&&(a=Math.floor(a),0>=a&&xa(a,"> 0",Ng,"maxLines"),fo(this),this.da.Jq=a,this.v(),this.g("maxLines",b,a))}},metrics:{get:function(){return{arrSize:null!==this.Qc?this.Qc:[this.Db],arrText:null!==this.Sb?this.Sb:[this.ze],maxLineWidth:this.Db,fontHeight:this.df}}},choices:{
get:function(){return this.Gm},set:function(a){var b=this.Gm;b!==a&&(this.Gm=a,this.g("choices",b,a))}}});var io=null,jo=null,uo=new D(Ng,"None",0),ro=new D(Ng,"WrapFit",1),Go=new D(Ng,"WrapDesiredSize",2),wo=new D(Ng,"WrapBreakAll",3),Ho=new D(Ng,"OverflowClip",0),oo=new D(Ng,"OverflowEllipsis",1),lo=new D(Ng,"FormatTrim",0),yo=new D(Ng,"FormatNone",0),xo=/[ \u200b\u00ad]/,zo=new Ua,Ao=0,Bo=new Ua,Fo=0,qo="...",ko="",eo=null,co=!1;Ng.className="TextBlock";
function go(){this.oc=!1;this.Hd=null;this.Xf=kg;this.gq=lo;this.Jq=Infinity;this.$q=Ho;this.Er=this.Dr=0;this.Or="start";this.vj=this.Qr=this.Pr=null;this.Yr=Vc;this.$r=Go}go.prototype.copy=function(){var a=new go;a.Hd=this.Hd;a.Xf=this.Xf;a.gq=this.gq;a.Jq=this.Jq;a.$q=this.$q;a.Dr=this.Dr;a.Er=this.Er;a.Or=this.Or;a.Pr=this.Pr;a.Qr=this.Qr;a.vj=this.vj;a.Yr=this.Yr;a.$r=this.$r;return a};Ng.getEllipsis=function(){return qo};Ng.setEllipsis=function(a){qo=a;Bo=new Ua;Fo=0};Ng.getBaseline=function(){return io};
Ng.setBaseline=function(a){io=a};Ng.getUnderline=function(){return jo};Ng.setUnderline=function(a){jo=a};Ng.isValidFont=function(a){co||(eo=Lg?(new Hi(null)).context:null,co=!0);if(null===eo)return!0;var b=eo.font;if(a===b||"10px sans-serif"===a)return!0;eo.font="10px sans-serif";eo.font=a;var c=eo.font;if("10px sans-serif"!==c)return eo.font=b,!0;eo.font="19px serif";var d=eo.font;eo.font=a;c=eo.font;eo.font=b;return c!==d};Ng.FormatNone=yo;Ng.FormatTrim=lo;Ng.None=uo;Ng.OverflowClip=Ho;
Ng.OverflowEllipsis=oo;Ng.WrapBreakAll=wo;Ng.WrapDesiredSize=Go;Ng.WrapFit=ro;function bo(){this.Sk=1;this.u=0;this.j=1;this.o=this.F=null}bo.prototype.copy=function(){var a=new bo;a.Sk=this.Sk;a.u=this.u;a.j=this.j;a.F=this.F;a.o=this.o;return a};bo.className="GradElementSettings";
function ik(a,b){Y.call(this);this.Eg=null;this.qo="";this.Xg=bc;this.Mk=nd;this.Zg=this.Hd=null;this.Lk=wc;this.Xf=kg;this.vl=null;this.qn=!1;this.en=!0;this.$k=!1;this.bt=this.rl=null;"string"===typeof a?this.source=a:a&&Object.assign(this,a);b&&Object.assign(this,b)}var Io;la(ik,Y);
ik.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.qo=this.qo;a.element=this.Eg;a.Xg=this.Xg.J();a.Mk=this.Mk;a.Xf=this.Xf;a.Hd=this.Hd;a.Zg=this.Zg;a.Lk=this.Lk.J();a.en=this.en;a.rl=this.rl};ik.prototype.qb=function(a){a===kg||a===mg||a===Tk?this.imageStretch=a:Y.prototype.qb.call(this,a)};ik.prototype.toString=function(){return"Picture("+this.source+")#"+bb(this)};function Ei(a){void 0===a&&(a="");""!==a?Io[a]&&(delete Io[a],Jo--):(Io=new Ua,Jo=0)}
function Ko(a,b){b.sy||(b.Fq=function(c){b.sq=!0;b.ln=!1;var d=b.Bg.copy();if(null!==d&&void 0!==d){for(d=d.iterator;d.next();){var e=d.value;e.N();var f=b.getAttribute("src")||"";var g=e.Og.I(f);if(null!==g)for(var h=g.length,k=0;k<h;k++)f=g[k],e.Xn.add(f),f.Uc=!0,e.Rb(),b.hn&&(void 0===b.eu&&(b.eu=c),null!==f.Zg&&f.Zg(f,c))}b.Bg.clear()}a.desiredSize.w()||(uj(a,!1),a.v())},b.Yp=function(a){b.ln=a;var c=b.Bg.copy();if(null!==c&&void 0!==c){for(c=c.iterator;c.next();){var e=c.value,f=b.getAttribute("src")||
"";var g=e.Og.I(f);if(null!==g){e=g.length;f=Ea();for(var h=0;h<e;h++)f.push(g[h]);if(b.hn)for(h=0;h<e;h++)g=f[h],null!==g.Hd&&g.Hd(g,a);Ga(f)}}b.Bg.clear()}},b.addEventListener("load",b.Fq),b.addEventListener("error",b.Yp),b.sy=!0)}ik.prototype.iA=function(){if(""!==this.source){Ei(this.source);var a=this.source;this.source="";this.source=a}};ik.prototype.redraw=function(){this.N();this.Uc=!0};
ik.prototype.hh=function(a,b){var c=this.Eg;if(null!==c){var d=c.getAttribute("src");if(!(c instanceof HTMLImageElement&&(null===d||""===d)||c.ln instanceof Event&&a instanceof Nl)){var e=a instanceof ii;d=this.naturalBounds;var f=0,g=0,h=this.qn,k=h?+c.width:c.naturalWidth;h=h?+c.height:c.naturalHeight;void 0===k&&c.videoWidth&&(k=c.videoWidth);void 0===h&&c.videoHeight&&(h=c.videoHeight);k=k||d.width;h=h||d.height;if(0!==k&&0!==h){var l=k,m=h;this.sourceRect.w()&&(f=this.Xg.x,g=this.Xg.y,k=this.Xg.width,
h=this.Xg.height);var n=k,p=h,q=this.Mk,r=this.Lk;switch(q){case kg:if(this.sourceRect.w())break;n>=d.width&&(f=f+r.offsetX+(n*r.x-d.width*r.x));p>=d.height&&(g=g+r.offsetY+(p*r.y-d.height*r.y));k=Math.min(d.width,n);h=Math.min(d.height,p);break;case nd:n=d.width;p=d.height;break;case mg:case Tk:q===mg?(q=Math.min(d.height/p,d.width/n),n*=q,p*=q):q===Tk&&(q=Math.max(d.height/p,d.width/n),n*=q,p*=q,n>=d.width&&(f=(f+r.offsetX+(n*r.x-d.width*r.x)/n)*k),p>=d.height&&(g=(g+r.offsetY+(p*r.y-d.height*r.y)/
p)*h),k*=1/(n/d.width),h*=1/(p/d.height),n=d.width,p=d.height)}q=this.Df()*b.scale;var u=n*q*p*q,w=k*h/u,v=c.__goCache;q=null;var y=Lo;if(!e){if(c.sq&&void 0!==v&&4<u&&w>y*y)for(null===v.gi&&(Mo(v,4,l,m,c),Mo(v,16,l,m,c)),e=v.gi,l=e.length,m=0;m<l;m++)if(e[m].ratio*e[m].ratio<w)q=e[m];else break;if(!b.Xm){if(null===this.vl)if(null===this.Eg)this.vl=!1;else{e=(new Hi(null)).context;try{e.drawImage(this.Eg,0,0)}catch(z){this.en=!1}try{e.getImageData(0,0,1,1).data[3]&&(this.vl=!1),this.vl=!1}catch(z){this.vl=
!0}}if(this.vl)return}}e=n<d.width?r.offsetX+(d.width*r.x-n*r.x):0;r=p<d.height?r.offsetY+(d.height*r.y-p*r.y):0;switch(this.flip){case Vk:a.translate(Math.min(d.width,n),0);a.scale(-1,1);break;case Uk:a.translate(0,Math.min(d.height,p));a.scale(1,-1);break;case Wk:a.translate(Math.min(d.width,n),Math.min(d.height,p)),a.scale(-1,-1)}a.yc();if(b.Gf("pictureRatioOptimization")&&!b.$i&&void 0!==v&&null!==q&&1!==q.ratio){a.save();b=q.ratio;try{a.drawImage(q.source,f/b,g/b,Math.min(q.source.width,k/b),
Math.min(q.source.height,h/b),e,r,Math.min(d.width,n),Math.min(d.height,p)),a instanceof ii&&(this.bt=[f/b,g/b,Math.min(q.source.width,k/b),Math.min(q.source.height,h/b),e,r,Math.min(d.width,n),Math.min(d.height,p)])}catch(z){this.en=!1}a.restore()}else try{a.drawImage(c,f,g,k,h,e,r,Math.min(d.width,n),Math.min(d.height,p)),a instanceof ii&&(this.bt=[f,g,k,h,e,r,Math.min(d.width,n),Math.min(d.height,p)])}catch(z){this.en=!1}switch(this.flip){case Vk:a.scale(-1,1);a.translate(-Math.min(d.width,n),
0);break;case Uk:a.scale(1,-1);a.translate(0,-Math.min(d.height,p));break;case Wk:a.scale(-1,-1),a.translate(-Math.min(d.width,n),-Math.min(d.height,p))}}}}};
ik.prototype.Wl=function(a,b,c,d){var e=this.desiredSize,f=kl(this,!0),g=this.Eg,h=this.qn,k=0,l=0;if(h||!this.$k&&g&&g.complete)this.$k=!0;null===g?(isFinite(e.width)||(a=0),isFinite(e.height)||(b=0)):(k=h?+g.width:g.naturalWidth,l=h?+g.height:g.naturalHeight);isFinite(e.width)||f===nd||f===Qk?(isFinite(a)||(this.sourceRect.w()?a=this.sourceRect.width:null!==g&&(a=k)),c=0):null!==g&&!1!==this.$k&&(a=this.sourceRect.w()?this.sourceRect.width:k);isFinite(e.height)||f===nd||f===Rk?(isFinite(b)||(this.sourceRect.w()?
b=this.sourceRect.height:null!==g&&(b=l)),d=0):null!==g&&!1!==this.$k&&(b=this.sourceRect.w()?this.sourceRect.height:l);isFinite(e.width)&&(a=e.width);isFinite(e.height)&&(b=e.height);e=this.maxSize;f=this.minSize;c=Math.max(c||0,f.width);d=Math.max(d||0,f.height);a=Math.min(e.width,a);b=Math.min(e.height,b);a=Math.max(c,a);b=Math.max(d,b);null===g||g.complete||(isFinite(a)||(a=0),isFinite(b)||(b=0));Pb(this.ec,a,b);this.Ge(0,0,a,b)};ik.prototype.eh=function(a,b,c,d){ql(this,a,b,c,d)};
ma.Object.defineProperties(ik.prototype,{element:{get:function(){return this.Eg},set:function(a){var b=this.Eg;this.Uc=!0;if(b!==a){this.qn=a instanceof HTMLCanvasElement;var c=a instanceof HTMLImageElement;null===a||c||a instanceof HTMLVideoElement||this.qn||C("Picture.element must be an instance of Image, Canvas, or Video, not: "+a);var d=this.diagram;null!==d&&jk(d,this);this.Eg=a;null!==a&&c&&void 0===a.Bg&&(a.Bg=new H);null!==d&&c&&hk(d,this);null!==a&&(this.qn||
!0===a.complete?(a.hn&&(a.ln instanceof Event&&null!==this.Hd?this.Hd(this,a.ln):!0===a.sq&&!a.ln&&null!==this.Zg&&a.eu&&this.Zg(this,a.eu)),a.sq=!0,this.desiredSize.w()||(uj(this,!1),this.v())):c&&Ko(this,a));this.g("element",b,a);this.N()}}},source:{get:function(){return this.qo},set:function(a){var b=this.qo;if(b!==a){this.Uc=!0;this.qo=a;var c=Io;if(Lg){var d=null;if(void 0!==c[a])d=c[a];else if(""!==a){30<Jo&&(Ei(),c=Io);d=ua("img");d.hn=!0;Ko(this,d);var e=this.rl;
null!==e&&(d.crossOrigin=e(this));d.src=a;c[a]=d;Jo++}this.element=d;null!==d&&void 0===d.__goCache&&(d.__goCache=new No);this.v();this.N()}this.g("source",b,a)}}},sourceCrossOrigin:{get:function(){return this.rl},set:function(a){if(this.rl!==a&&(this.rl=a,null!==this.element)){var b=this.element.getAttribute("src");null===a&&"string"===typeof b?this.element.crossOrigin=null:null!==a&&(this.element.crossOrigin=a(this));this.element.src=b}}},sourceRect:{
get:function(){return this.Xg},set:function(a){var b=this.Xg;b.D(a)||(this.Xg=a=a.J(),this.N(),this.g("sourceRect",b,a))}},imageStretch:{get:function(){return this.Mk},set:function(a){var b=this.Mk;b!==a&&(this.Mk=a,this.N(),this.g("imageStretch",b,a))}},flip:{get:function(){return this.Xf},set:function(a){var b=this.Xf;b!==a&&(this.Xf=a,this.N(),this.g("flip",b,a))}},imageAlignment:{get:function(){return this.Lk},
set:function(a){var b=this.Lk;b.D(a)||(this.Lk=a=a.J(),this.N(),this.g("imageAlignment",b,a))}},errorFunction:{get:function(){return this.Hd},set:function(a){var b=this.Hd;b!==a&&(this.Hd=a,this.g("errorFunction",b,a))}},successFunction:{get:function(){return this.Zg},set:function(a){var b=this.Zg;b!==a&&(this.Zg=a,this.g("successFunction",b,a))}},naturalBounds:{get:function(){return this.ec}}});
ik.prototype.redraw=ik.prototype.redraw;ik.prototype.reloadSource=ik.prototype.iA;var Jo=0,Lo=4;ik.className="Picture";Io=new Ua;ik.clearCache=Ei;function No(){this.gi=null}function Mo(a,b,c,d,e){null===a.gi&&(a.gi=[]);var f=new Hi(null),g=f.context,h=1/b;f.width=c/b;f.height=d/b;0!==f.width&&0!==f.height&&(b=new Oo(f.Oa,b),c=1,0<a.gi.length&&(c=a.gi[a.gi.length-1],e=c.source,c=c.ratio),g.setTransform(h*c,0,0,h*c,0,0),g.yc(),g.drawImage(e,0,0),a.gi.push(b))}No.className="PictureCacheArray";
function Oo(a,b){this.source=a;this.ratio=b}Oo.className="PictureCacheInstance";function Po(){this.os=new P;this.nc=null}Po.prototype.reset=function(a){null!==a?(a.ka(),this.os=a,a.figures.clear()):this.os=new P;this.nc=null};function rd(a,b,c,d,e,f){a.nc=new Qd;a.nc.startX=b;a.nc.startY=c;a.nc.isFilled=d;a.nc.isEvenOdd=f||!1;a.os.figures.add(a.nc);void 0!==e&&(a.nc.isShadowed=e)}function xd(a){var b=a.nc.segments.length;0<b&&a.nc.segments.M(b-1).close()}
Po.prototype.moveTo=function(a,b,c){void 0===c&&(c=!1);var d=new Rd(Ad);d.endX=a;d.endY=b;c&&d.close();this.nc.segments.add(d)};Po.prototype.lineTo=function(a,b,c){void 0===c&&(c=!1);var d=new Rd(pd);d.endX=a;d.endY=b;c&&d.close();this.nc.segments.add(d)};function sd(a,b,c,d,e,f,g){var h;void 0===h&&(h=!1);var k=new Rd(Bd);k.point1X=b;k.point1Y=c;k.point2X=d;k.point2Y=e;k.endX=f;k.endY=g;h&&k.close();a.nc.segments.add(k)}
function td(a,b,c,d,e){var f;void 0===f&&(f=!1);var g=new Rd(Cd);g.point1X=b;g.point1Y=c;g.endX=d;g.endY=e;f&&g.close();a.nc.segments.add(g)}Po.prototype.arcTo=function(a,b,c,d,e,f,g){void 0===f&&(f=0);void 0===g&&(g=!1);var h=new Rd(Dd);h.startAngle=a;h.sweepAngle=b;h.centerX=c;h.centerY=d;h.radiusX=e;h.radiusY=0!==f?f:e;g&&h.close();this.nc.segments.add(h)};function wd(a,b,c,d,e,f,g,h){var k;void 0===k&&(k=!1);b=new Rd(Jd,g,h,b,c,d,e,f);k&&b.close();a.nc.segments.add(b)}
function qd(a){var b=yd;if(null!==b)return yd=null,b.reset(a),b;b=new Po;b.reset(a);return b}var yd=null;Po.className="StreamGeometryContext";function Qo(a,b){var c=a.toLowerCase(),d=K.Ze;d[a]=b;d[c]=a}Qo("Rectangle",function(a,b,c){a="r"+b+","+c;var d=K.Mf[a];if(void 0!==d)return d;d=new P(P.u);d.endX=b;d.endY=c;K.Nf<K.vm&&(K.Mf[a]=d,K.Nf++);return d});
Qo("Square",function(a,b,c){a="s"+b+","+c;var d=K.Mf[a];if(void 0!==d)return d;d=new P(P.u);d.endX=b;d.endY=c;d.defaultStretch=mg;K.Nf<K.vm&&(K.Mf[a]=d,K.Nf++);return d});
Qo("RoundedRectangle",function(a,b,c){a=a?a.parameter1:NaN;if(isNaN(a)||0>a)a=5;a=Math.min(a,b/3);a=Math.min(a,c/3);var d="rr"+b+","+c+","+a,e=K.Mf[d];if(void 0!==e)return e;var f=a*K.wg;e=(new P).add((new Qd(a,0,!0)).add(new Rd(pd,b-a,0)).add(new Rd(Bd,b,a,b-f,0,b,f)).add(new Rd(pd,b,c-a)).add(new Rd(Bd,b-a,c,b,c-f,b-f,c)).add(new Rd(pd,a,c)).add(new Rd(Bd,0,c-a,f,c,0,c-f)).add(new Rd(pd,0,a)).add((new Rd(Bd,a,0,0,f,f,0)).close()));1<f&&(e.spot1=new O(0,0,f,f),e.spot2=new O(1,1,-f,-f));K.Nf<K.vm&&
(K.Mf[d]=e,K.Nf++);return e});Qo("Border","RoundedRectangle");Qo("Ellipse",function(a,b,c){a="e"+b+","+c;var d=K.Mf[a];if(void 0!==d)return d;d=new P(P.F);d.endX=b;d.endY=c;d.spot1=ed;d.spot2=fd;K.Nf<K.vm&&(K.Mf[a]=d,K.Nf++);return d});Qo("Circle",function(a,b,c){a="c"+b+","+c;var d=K.Mf[a];if(void 0!==d)return d;d=new P(P.F);d.endX=b;d.endY=c;d.spot1=ed;d.spot2=fd;d.defaultStretch=mg;K.Nf<K.vm&&(K.Mf[a]=d,K.Nf++);return d});
Qo("TriangleRight",function(a,b,c){return(new P).add((new Qd(0,0)).add(new Rd(pd,b,.5*c)).add((new Rd(pd,0,c)).close())).pm(0,.25,.5,.75)});Qo("TriangleDown",function(a,b,c){return(new P).add((new Qd(0,0)).add(new Rd(pd,b,0)).add((new Rd(pd,.5*b,c)).close())).pm(.25,0,.75,.5)});Qo("TriangleLeft",function(a,b,c){return(new P).add((new Qd(b,c)).add(new Rd(pd,0,.5*c)).add((new Rd(pd,b,0)).close())).pm(.5,.25,1,.75)});
Qo("TriangleUp",function(a,b,c){return(new P).add((new Qd(b,c)).add(new Rd(pd,0,c)).add((new Rd(pd,.5*b,0)).close())).pm(.25,.5,.75,1)});Qo("Triangle","TriangleUp");Qo("Diamond",function(a,b,c){return(new P).add((new Qd(.5*b,0)).add(new Rd(pd,0,.5*c)).add(new Rd(pd,.5*b,c)).add((new Rd(pd,b,.5*c)).close())).pm(.25,.25,.75,.75)});Qo("LineH",function(a,b,c){a=new P(P.o);a.startX=0;a.startY=c/2;a.endX=b;a.endY=c/2;return a});
Qo("LineV",function(a,b,c){a=new P(P.o);a.startX=b/2;a.startY=0;a.endX=b/2;a.endY=c;return a});Qo("None","Rectangle");Qo("BarH","Rectangle");Qo("BarV","Rectangle");Qo("MinusLine","LineH");Qo("PlusLine",function(a,b,c){return(new P).add((new Qd(0,c/2,!1)).add(new Rd(pd,b,c/2)).add(new Rd(Ad,b/2,0)).add(new Rd(pd,b/2,c)))});Qo("XLine",function(a,b,c){return(new P).add((new Qd(0,c,!1)).add(new Rd(pd,b,0)).add(new Rd(Ad,0,0)).add(new Rd(pd,b,c)))});
Qo("LineRight",function(a,b,c){return(new P).add((new Qd(.25*b,0,!1)).add(new Rd(pd,.75*b,.5*c)).add(new Rd(pd,.25*b,c)))});Qo("LineDown",function(a,b,c){return(new P).add((new Qd(0,.25*c,!1)).add(new Rd(pd,.5*b,.75*c)).add(new Rd(pd,b,.25*c)))});Qo("LineLeft",function(a,b,c){return(new P).add((new Qd(.75*b,0,!1)).add(new Rd(pd,.25*b,.5*c)).add(new Rd(pd,.75*b,c)))});Qo("LineUp",function(a,b,c){return(new P).add((new Qd(0,.75*c,!1)).add(new Rd(pd,.5*b,.25*c)).add(new Rd(pd,b,.75*c)))});
K.um={"":"",Standard:"F1 m 0,0 l 8,4 -8,4 2,-4 z",Backward:"F1 m 8,0 l -2,4 2,4 -8,-4 z",Triangle:"F1 m 0,0 l 8,4.62 -8,4.62 z",BackwardTriangle:"F1 m 8,4 l 0,4 -8,-4 8,-4 0,4 z",Boomerang:"F1 m 0,0 l 8,4 -8,4 4,-4 -4,-4 z",BackwardBoomerang:"F1 m 8,0 l -8,4 8,4 -4,-4 4,-4 z",SidewaysV:"m 0,0 l 8,4 -8,4 0,-1 6,-3 -6,-3 0,-1 z",BackwardV:"m 8,0 l -8,4 8,4 0,-1 -6,-3 6,-3 0,-1 z",OpenTriangle:"m 0,0 l 8,4 -8,4",BackwardOpenTriangle:"m 8,0 l -8,4 8,4",OpenTriangleLine:"m 0,0 l 8,4 -8,4 m 8.5,0 l 0,-8",
BackwardOpenTriangleLine:"m 8,0 l  -8,4 8,4 m -8.5,0 l 0,-8",OpenTriangleTop:"m 0,0 l 8,4 m 0,4",BackwardOpenTriangleTop:"m 8,0 l -8,4 m 0,4",OpenTriangleBottom:"m 0,8 l 8,-4",BackwardOpenTriangleBottom:"m 0,4 l 8,4",HalfTriangleTop:"F1 m 0,0 l 0,4 8,0 z m 0,8",BackwardHalfTriangleTop:"F1 m 8,0 l 0,4 -8,0 z m 0,8",HalfTriangleBottom:"F1 m 0,4 l 0,4 8,-4 z",BackwardHalfTriangleBottom:"F1 m 8,4 l 0,4 -8,-4 z",ForwardSemiCircle:"m 4,0 b 270 180 0 4 4",BackwardSemiCircle:"m 4,8 b 90 180 0 -4 4",Feather:"m 0,0 l 3,4 -3,4",
BackwardFeather:"m 3,0 l -3,4 3,4",DoubleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardDoubleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4",TripleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardTripleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4 m 3,-8 l -3,4 3,4",ForwardSlash:"m 0,8 l 5,-8",BackSlash:"m 0,0 l 5,8",DoubleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8",DoubleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8",TripleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8 m -2,8 l 4,-8",
TripleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8 m -2,-8 l 4,8",Fork:"m 0,4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4",LineFork:"m 0,0 l 0,8 m 0,-4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardLineFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4 m 8,-8 l 0,8",CircleFork:"F1 m 6,4 b 0 360 -3 0 3 z m 0,0 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",BackwardCircleFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 6,0 b 0 360 -3 0 3",CircleLineFork:"F1 m 6,4 b 0 360 -3 0 3 z m 1,-4 l 0,8 m 0,-4 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",
BackwardCircleLineFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 0,-4 l 0,8 m 7,-4 b 0 360 -3 0 3",Circle:"F1 m 8,4 b 0 360 -4 0 4 z",Block:"F1 m 0,0 l 0,8 8,0 0,-8 z",StretchedDiamond:"F1 m 0,3 l 5,-3 5,3 -5,3 -5,-3 z",Diamond:"F1 m 0,4 l 4,-4 4,4 -4,4 -4,-4 z",Chevron:"F1 m 0,0 l 5,0 3,4 -3,4 -5,0 3,-4 -3,-4 z",StretchedChevron:"F1 m 0,0 l 8,0 3,4 -3,4 -8,0 3,-4 -3,-4 z",NormalArrow:"F1 m 0,2 l 4,0 0,-2 4,4 -4,4 0,-2 -4,0 z",X:"m 0,0 l 8,8 m 0,-8 l -8,8",TailedNormalArrow:"F1 m 0,0 l 2,0 1,2 3,0 0,-2 2,4 -2,4 0,-2 -3,0 -1,2 -2,0 1,-4 -1,-4 z",
DoubleTriangle:"F1 m 0,0 l 4,4 -4,4 0,-8 z  m 4,0 l 4,4 -4,4 0,-8 z",BigEndArrow:"F1 m 0,0 l 5,2 0,-2 3,4 -3,4 0,-2 -5,2 0,-8 z",ConcaveTailArrow:"F1 m 0,2 h 4 v -2 l 4,4 -4,4 v -2 h -4 l 2,-2 -2,-2 z",RoundedTriangle:"F1 m 0,1 a 1,1 0 0 1 1,-1 l 7,3 a 0.5,1 0 0 1 0,2 l -7,3 a 1,1 0 0 1 -1,-1 l 0,-6 z",SimpleArrow:"F1 m 1,2 l -1,-2 2,0 1,2 -1,2 -2,0 1,-2 5,0 0,-2 2,2 -2,2 0,-2 z",AccelerationArrow:"F1 m 0,0 l 0,8 0.2,0 0,-8 -0.2,0 z m 2,0 l 0,8 1,0 0,-8 -1,0 z m 3,0 l 2,0 2,4 -2,4 -2,0 0,-8 z",BoxArrow:"F1 m 0,0 l 4,0 0,2 2,0 0,-2 2,4 -2,4 0,-2 -2,0 0,2 -4,0 0,-8 z",
TriangleLine:"F1 m 8,4 l -8,-4 0,8 8,-4 z m 0.5,4 l 0,-8",CircleEndedArrow:"F1 m 10,4 l -2,-3 0,2 -2,0 0,2 2,0 0,2 2,-3 z m -4,0 b 0 360 -3 0 3 z",DynamicWidthArrow:"F1 m 0,3 l 2,0 2,-1 2,-2 2,4 -2,4 -2,-2 -2,-1 -2,0 0,-2 z",EquilibriumArrow:"m 0,3 l 8,0 -3,-3 m 3,5 l -8,0 3,3",FastForward:"F1 m 0,0 l 3.5,4 0,-4 3.5,4 0,-4 1,0 0,8 -1,0 0,-4 -3.5,4 0,-4 -3.5,4 0,-8 z",Kite:"F1 m 0,4 l 2,-4 6,4 -6,4 -2,-4 z",HalfArrowTop:"F1 m 0,0 l 4,4 4,0 -8,-4 z m 0,8",HalfArrowBottom:"F1 m 0,8 l 4,-4 4,0 -8,4 z",
OpposingDirectionDoubleArrow:"F1 m 0,4 l 2,-4 0,2 4,0 0,-2 2,4 -2,4 0,-2 -4,0 0,2 -2,-4 z",PartialDoubleTriangle:"F1 m 0,0 4,3 0,-3 4,4 -4,4 0,-3 -4,3 0,-8 z",LineCircle:"F1 m 0,0 l 0,8 m 7 -4 b 0 360 -3 0 3 z",DoubleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",TripleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",CircleLine:"F1 m 6 4 b 0 360 -3 0 3 z m 1,-4 l 0,8",DiamondCircle:"F1 m 8,4 l -4,4 -4,-4 4,-4 4,4 m 8,0 b 0 360 -4 0 4 z",PlusCircle:"F1 m 8,4 b 0 360 -4 0 4 l -8 0 z m -4 -4 l 0 8",
OpenRightTriangleTop:"m 8,0 l 0,4 -8,0 m 0,4",OpenRightTriangleBottom:"m 8,8 l 0,-4 -8,0",Line:"m 0,0 l 0,8",DoubleLine:"m 0,0 l 0,8 m 2,0 l 0,-8",TripleLine:"m 0,0 l 0,8 m 2,0 l 0,-8 m 2,0 l 0,8",PentagonArrow:"F1 m 8,4 l -4,-4 -4,0 0,8 4,0 4,-4 z"};
function Ro(){this.oc=!1;this.tr=this.Kp=this.zq=null;this.vr="";this.fr=this.oq=this.ur=null;this.hr="";this.gr=Lb;this.ir=null;this.jr="";this.lr=Sc;this.Gq="";this.Hq=sc;this.Pq=qb;this.Kq=rb;this.Up=null;this.Cq=127;this.zr=sb;this.yr="gray";this.Vg=4}
Ro.prototype.copy=function(){var a=new Ro;a.zq=this.zq;a.Kp=this.Kp;a.tr=this.tr;a.vr=this.vr;a.ur=this.ur;a.oq=this.oq;a.fr=this.fr;a.hr=this.hr;a.gr=this.gr.J();a.ir=this.ir;a.jr=this.jr;a.lr=this.lr.J();a.Gq=this.Gq;a.Hq=this.Hq.J();a.Pq=this.Pq.J();a.Kq=this.Kq.J();a.Up=this.Up;a.Cq=this.Cq;a.zr=this.zr.J();a.yr=this.yr;a.Vg=this.Vg;return a};
function U(a,b){void 0===a||a instanceof dm||"string"===typeof a?W.call(this,a):(W.call(this),a&&(b=a));this.G=2408959;this.Tf="";this.P=null;this.Jg="";this.Qf=this.Ug=null;this.fc="";this.Ah=this.Ig=null;this.ke=(new I(NaN,NaN)).freeze();this.nf=null;this.Ao=NaN;this.ww=-1;this.qy=new N;this.Rh=null;this.ag=NaN;b&&Object.assign(this,b)}la(U,W);
U.prototype.cloneProtected=function(a){W.prototype.cloneProtected.call(this,a);a.G=this.G&-4097|49152;a.Tf=this.Tf;null!==this.P&&(a.P=this.P.oc?this.P:this.P.copy());a.Jg=this.Jg;a.Qf=null;a.fc=this.fc;a.ke.assign(this.ke);a.Ao=this.Ao};t=U.prototype;t.Af=function(a){W.prototype.Af.call(this,a);a.oh();a.Ug=null;a.nf=null;a.Rh=null};t.toString=function(){var a=Ha(this.constructor)+"#"+bb(this);null!==this.data&&(a+="("+Ia(this.data)+")");return a};
function So(a){null===a.P?a.P=new Ro:a.P.oc&&(a.P=a.P.copy())}t.th=function(){W.prototype.th.call(this);null!==this.P&&(this.P.oc=!0)};t.fk=function(a,b,c,d,e,f,g){var h=this.diagram;null!==h&&(a===fe&&"elements"===b?e instanceof W?Mj(e,function(a){Oj(h.partManager,a);Nj(h,a)}):hk(h,e):a===ge&&"elements"===b&&(e instanceof W?Mj(e,function(a){Rj(h.partManager,a,h)}):jk(h,e)),h.cb(a,b,c,d,e,f,g))};
t.La=function(a){W.prototype.La.call(this,a);if(null!==this.data){for(var b=this.O.s,c=b.length,d=0;d<c;d++){var e=b[d];e instanceof W&&Mj(e,function(a){null!==a.data&&a.La()})}for(b=this.adornments;b.next();)b.value.La(a)}};U.prototype.updateRelationshipsFromData=function(){var a;null!==this.data&&(null===(a=this.diagram)||void 0===a?void 0:a.partManager.updateRelationshipsFromData(this))};U.prototype.Oj=function(a){var b=this.Qf;return null===b?null:b.I(a)};
U.prototype.bh=function(a,b){if(null!==b){var c=null,d=this.Qf;null!==d&&(c=d.I(a));if(c!==b){if(null!==c){var e=c.diagram;null!==e&&e.remove(c)}null===d&&(this.Qf=d=new ob);b.Tf!==a&&(b.category=a);d.add(a,b);a=this.diagram;null!==a&&(a.add(b),a=b.adornedObject,null!==a&&(a=a.ih(),null!==a&&(b.data=a.data)))}}};U.prototype.Jf=function(a){var b=this.Qf;if(null!==b){var c=b.I(a);if(null!==c){var d=c.diagram;null!==d&&d.remove(c)}b.remove(a);0===b.count&&(this.Qf=null)}};
U.prototype.Dj=function(){var a=this.Qf;if(null!==a){var b=Ea();for(a=a.iterator;a.next();)b.push(a.key);a=b.length;for(var c=0;c<a;c++)this.Jf(b[c]);Ga(b)}};
U.prototype.updateAdornments=function(){var a=this.diagram;if(null!==a){for(var b=this.adornments;b.next();){var c=b.value;c.v();c.placeholder&&c.placeholder.v()}a:{if(this.isSelected&&this.selectionAdorned&&(b=this.selectionObject,null!==b&&this.actualBounds.w()&&this.isVisible()&&b.Ed()&&b.actualBounds.w())){c=this.Oj("Selection");if(null===c){c=this.selectionAdornmentTemplate;null===c&&(c=this.ph()?a.linkSelectionAdornmentTemplate:this instanceof jf?a.groupSelectionAdornmentTemplate:a.nodeSelectionAdornmentTemplate);
if(!(c instanceof pe))break a;jg(c);c=c.copy();null!==c&&(this.ph()&&this.selectionObject===this.path&&(c.type=W.Link),c.adornedObject=b)}if(null!==c){c.type===W.Link&&c.v();this.bh("Selection",c);break a}}this.Jf("Selection")}To(this,a);for(b=this.adornments;b.next();)b.value.La()}};U.prototype.Yb=function(){var a=this.diagram;null!==a&&(a.vd=!0,0!==(this.G&16384)!==!0&&(this.G|=16384))};function fi(a){0!==(a.G&16384)!==!1&&(a.updateAdornments(),a.G&=-16385)}
function To(a,b){b.toolManager.mouseDownTools.each(function(b){b.isEnabled&&b.updateAdornments(a)});b.toolManager.updateAdornments(a)}function Uo(a){if(!1===Bj(a)){Vo(a,!0);a.Tk();var b=a.diagram;null!==b&&(b.De.add(a),b.Rb())}}function Wo(a){a.G|=2097152;if(!1!==Bj(a)){var b=a.position,c=a.location;c.w()&&b.w()||Xo(a,b,c);c=a.Jb;var d=N.alloc().assign(c);c.ka();c.x=b.x;c.y=b.y;c.freeze();a.Xl(d,c);N.free(d);Vo(a,!1)}}
U.prototype.Ge=function(a,b,c,d){var e=this.uc;e.h(a,b,c,d);null===this.Mb&&(this.Mb=new ld);var f=this.Mb;f.reset();this.no(f,a,b,c,d);this.Mb=f;f.ws()||f.pp(e)};
U.prototype.no=function(a,b,c,d,e){1!==this.Ca&&a.scale(this.Ca);if(0!==this.Lb){var f=wc;this.locationSpot.sb()&&(f=this.locationSpot);var g=I.alloc();if(this.locationObject!==this)for(b=this.locationObject,c=b.naturalBounds,g.yi(c.x,c.y,c.width,c.height,f),null!==b.Mb&&b.Mb.Ga(g),g.offset(-b.measuredBounds.x,-b.measuredBounds.y),f=b.panel;null!==f&&f!==this;)null!==f.Mb&&f.Mb.Ga(g),g.offset(-f.measuredBounds.x,-f.measuredBounds.y),f=f.panel;else g.yi(b,c,d,e,f);a.rotate(this.Lb,g.x,g.y);I.free(g)}};
U.prototype.move=function(a,b){!0===b?this.location=a:this.position=a};U.prototype.moveTo=function(a,b,c){a=I.allocAt(a,b);this.move(a,c);I.free(a)};U.prototype.isVisible=function(){if(!this.visible)return!1;var a=this.layer;if(null!==a){if(!a.visible)return!1;a=a.diagram;if(null!==a&&a.animationManager.gv(this))return!0}a=this.containingGroup;return null===a||a.isSubGraphExpanded&&a.isVisible()?!0:!1};
U.prototype.ac=function(a){var b=this.diagram;a?(this.C(4),this.Yb(),null!==b&&b.De.add(this)):(this.C(8),this.Dj());this.oh();null!==b&&(b.Ya(),b.N())};U.prototype.fb=function(a){if(this.name===a)return this;var b=this.Rh;null===b&&(this.Rh=b=new ob);if(null!==b.I(a))return b.I(a);var c=W.prototype.fb.call(this,a);if(null!==c)return b.set(a,c),c;b.set(a,null);return null};
function Yo(a,b,c,d){void 0===d&&(d=new I);c=c.Mc()?wc:c;var e=b.naturalBounds;d.h(e.width*c.x+c.offsetX,e.height*c.y+c.offsetY);if(null===b||b===a)return d;b.transform.Ga(d);for(b=b.panel;null!==b&&b!==a;)b.transform.Ga(d),b=b.panel;null!==a.Mb&&a.Mb.Ga(d);d.offset(-a.uc.x,-a.uc.y);return d}U.prototype.mi=function(a){void 0===a&&(a=new N);return a.assign(this.actualBounds)};U.prototype.Va=function(){this.measure(Infinity,Infinity);this.arrange()};
function ln(a){var b=I.alloc(),c=a.locationObject,d=c.naturalBounds,e=c instanceof Bf?c.strokeWidth:0;b.yi(0,0,d.width+e,d.height+e,a.locationSpot);if(c!==a)for(b.offset(-e/2,-e/2),c.transform.Ga(b),c=c.panel;null!==c&&c!==a;)c.transform.Ga(b),c=c.panel;null!==a.Mb&&a.Mb.Ga(b);b.offset(-a.uc.x,-a.uc.y);return b}
function Lj(a,b){var c=a.qy;isNaN(a.ag)&&(a.ag=zn(a));var d=a.ag;var e=2*d;if(!a.isShadowed)return c.h(b.x-1-d,b.y-1-d,b.width+2+e,b.height+2+e),c;d=b.x;e=b.y;var f=b.width;b=b.height;var g=a.shadowBlur;a=a.shadowOffset;f+=g;b+=g;d-=g/2;e-=g/2;0<a.x?f+=a.x:(d+=a.x,f-=a.x);0<a.y?b+=a.y:(e+=a.y,b-=a.y);c.h(d-1,e-1,f+2,b+2);return c}
U.prototype.arrange=function(){if(!1===Aj(this))Wo(this);else{pl(this,!1);var a=this.Jb,b=N.alloc();b.assign(a);var c=hg(this);this.eh(0,0,this.uc.width,this.uc.height);var d=this.position;Xo(this,d,this.location);a.ka();a.x=d.x;a.y=d.y;a.freeze();this.Xl(b,a);b.D(a)?this.Fd(c):!this.Zc()||K.B(b.width,a.width)&&K.B(b.height,a.height)||0<=this.ww&&this.C(16);N.free(b);Vo(this,!1)}};t=U.prototype;
t.Xl=function(a,b){var c=this.diagram;if(null!==c){var d=!1;if(!1===c.Zi&&a.w()){var e=N.alloc();e.assign(c.documentBounds);e.Gv(c.padding);a.x>e.x&&a.y>e.y&&a.right<e.right&&a.bottom<e.bottom&&b.x>e.x&&b.y>e.y&&b.right<e.right&&b.bottom<e.bottom&&(d=!0);N.free(e)}0!==(this.G&65536)!==!0&&a.D(b)||Pj(this,d,c);c.N();!a.w()&&!b.w()||Sb(a,b)||(this instanceof V&&!c.undoManager.isUndoingRedoing&&this.Bd(),this.oh())}};
t.Av=function(a,b,c){if(this.ph()||!a.w())return!1;var d=this.diagram;null!==d&&Zo(this,d,a,b,c);this.pa=a;this.G&=-2097153;!this.ke.w()||null!==d&&!d.animationManager.isTicking&&d.undoManager.isUndoingRedoing||(d=this.ke,this.ke=new I(d.x+(a.x-b),d.y+(a.y-c)),this.g("location",d,this.ke.copy()));!1===Bj(this)&&!1===Aj(this)&&(Uo(this),Wo(this));return!0};function Zo(a,b,c,d,e){null===b||a instanceof pe||(b=b.animationManager,b.Ab&&uh(b,a,new I(d,e),c))}
t.mm=function(a,b,c){var d=this.ke,e=this.pa;if(c){if(d.x===a&&d.y===b)return;Bj(this)||Aj(this)?e.h(NaN,NaN):e.h(e.x+a-d.x,e.y+b-d.y);d.h(a,b)}else{if(e.x===a&&e.y===b)return;d.h(d.x+a-e.x,d.y+b-e.y);e.h(a,b)}Uo(this)};t.Bv=function(){this.G&=-2097153;Uo(this)};function Xo(a,b,c){var d=ln(a),e=a.diagram,f=c.w(),g=b.w();f&&g?0!==(a.G&2097152)?$o(a,b,c,e,d):ap(a,b,c,e,d):f?$o(a,b,c,e,d):g&&ap(a,b,c,e,d);a.G|=2097152;I.free(d);a.Tk()}
function $o(a,b,c,d,e){var f=b.x,g=b.y;b.h(c.x-e.x,c.y-e.y);null!==d&&(c=d.animationManager,(d=c.defaultAnimation.isAnimating)||!c.Ab||a instanceof pe||uh(c,a,new I(f,g),b),d||b.x===f&&b.y===g||a.g("position",new I(f,g),b.copy()))}function ap(a,b,c,d,e){var f=c.copy();c.h(b.x+e.x,b.y+e.y);c.D(f)||null===d||a.g("location",f,c.copy())}
function Pj(a,b,c){rl(a,!1);a instanceof V&&wk(c,a);a.layer.isTemporary||b||c.Ya();b=a.Jb;var d=c.viewportBounds;d.w()?hg(a)?(Wb(b,d)||a.Fd(!1),a.updateAdornments()):a.isVisible()&&b.Yc(d)?(a.Fd(!0),a.updateAdornments()):a.Yb():c.vd=!0}t.si=function(){return!0};t.Zc=function(){return!0};t.ph=function(){return!1};t.Dd=function(){return!0};
function bp(a,b,c,d){b.constructor===a.constructor||cp||(cp=!0,ya('Should not change the class of the Part when changing category from "'+c+'" to "'+d+'"'),ya("  Old class: "+Ha(a.constructor)+", new class: "+Ha(b.constructor)+", part: "+a.toString()));a.Dj();var e=a.data;c=a.layerName;var f=a.isSelected,g=a.isHighlighted,h=!0,k=!0,l=!1;a instanceof V&&(h=a.isTreeLeaf,k=a.isTreeExpanded,l=a.wasTreeExpanded);b.Af(a);b.cloneProtected(a);a.Tf=d;null!==a.svg&&(a.svg.remove(),a.svg=null,a.Uc=!0);a.v();
a.N();b=a.diagram;d=!0;null!==b&&(d=b.skipsUndoManager,b.skipsUndoManager=!0);a.ub=e;a.G=f?a.G|4096:a.G&-4097;a.G=g?a.G|524288:a.G&-524289;a instanceof V&&(a.R=h?a.R|4:a.R&-5,a.R=k?a.R|1:a.R&-2,a.R=l?a.R|2:a.R&-3);null!==e&&a.La();e=a.layerName;e!==c&&(a.Jg=c,a.layerName=e);null!==b&&(b.skipsUndoManager=d);a.Zc()&&a.C(64)}U.prototype.canCopy=function(){if(!this.copyable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowCopy)return!1;a=a.diagram;return null===a?!0:a.allowCopy?!0:!1};
U.prototype.canDelete=function(){if(!this.deletable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowDelete)return!1;a=a.diagram;return null===a?!0:a.allowDelete?!0:!1};U.prototype.canEdit=function(){if(!this.textEditable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowTextEdit)return!1;a=a.diagram;return null===a?!0:a.allowTextEdit?!0:!1};
U.prototype.canGroup=function(){if(!this.groupable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowGroup)return!1;a=a.diagram;return null===a?!0:a.allowGroup?!0:!1};U.prototype.canMove=function(){if(!this.movable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowMove)return!1;a=a.diagram;return null===a?!0:a.allowMove?!0:!1};
U.prototype.canReshape=function(){if(!this.reshapable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowReshape)return!1;a=a.diagram;return null===a?!0:a.allowReshape?!0:!1};U.prototype.canResize=function(){if(!this.resizable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowResize)return!1;a=a.diagram;return null===a?!0:a.allowResize?!0:!1};
U.prototype.canRotate=function(){if(!this.rotatable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowRotate)return!1;a=a.diagram;return null===a?!0:a.allowRotate?!0:!1};U.prototype.canSelect=function(){if(!this.selectable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowSelect)return!1;a=a.diagram;return null===a?!0:a.allowSelect?!0:!1};function Bj(a){return 0!==(a.G&32768)}function Vo(a,b){a.G=b?a.G|32768:a.G&-32769}function rl(a,b){a.G=b?a.G|65536:a.G&-65537}
function hg(a){return 0!==(a.G&131072)}t=U.prototype;t.Fd=function(a){this.G=a?this.G|131072:this.G&-131073};function dp(a,b){a.G=b?a.G|1048576:a.G&-1048577}t.oh=function(){var a=this.containingGroup;null!==a&&(a.v(),null!==a.placeholder&&a.placeholder.v(),a.Bd())};t.N=function(){var a=this.diagram;null!==a&&!Aj(this)&&!Bj(this)&&this.isVisible()&&this.Jb.w()&&a.N(Lj(this,this.Jb))};
t.v=function(){W.prototype.v.call(this);var a=this.diagram;null!==a&&(a.De.add(this),this instanceof V&&null!==this.labeledLink&&Sl(this.labeledLink),a.Rb(!0))};t.Vo=function(a){a||(a=this.Ah,null!==a&&ep(a,this))};t.Wo=function(a){a||(a=this.Ah,null!==a&&fp(a,this))};t.Kj=function(){var a=this.data;if(null!==a){var b=this.diagram;null!==b&&(b=b.model,null!==b&&b.cm(a))}};t.pz=function(){return gp(this,this)};
function gp(a,b){var c=b.containingGroup;return null!==c?1+gp(a,c):b instanceof V&&(b=b.labeledLink,null!==b)?gp(a,b):0}t.uz=function(){return hp(this,this)};function hp(a,b){var c=b.containingGroup;return null!==c||b instanceof V&&(c=b.labeledLink,null!==c)?hp(a,c):b}t.Td=function(a){return a instanceof jf?ip(this,this,a):!1};function ip(a,b,c){if(b===c||null===c)return!1;var d=b.containingGroup;return null===d||d!==c&&!ip(a,d,c)?b instanceof V&&(b=b.labeledLink,null!==b)?ip(a,b,c):!1:!0}
t.qx=function(a){if(null===a)return null;if(this===a)return this.containingGroup;for(var b=this;null!==b;){b instanceof jf&&dp(b,!0);if(b instanceof V){var c=b.labeledLink;null!==c&&(b=c)}b=b.containingGroup}c=null;for(b=a;null!==b;){if(0!==(b.G&1048576)){c=b;break}b instanceof V&&(a=b.labeledLink,null!==a&&(b=a));b=b.containingGroup}for(b=this;null!==b;)b instanceof jf&&dp(b,!1),b instanceof V&&(a=b.labeledLink,null!==a&&(b=a)),b=b.containingGroup;return c};
U.prototype.canLayout=function(){if(!this.isLayoutPositioned||!this.isVisible())return!1;var a=this.layer;return null!==a&&a.isTemporary||this instanceof V&&this.isLinkLabel?!1:!0};
U.prototype.C=function(a){void 0===a&&(a=16777215);if(this.isLayoutPositioned&&0!==(a&this.layoutConditions)){var b=this.layer;null!==b&&b.isTemporary||this instanceof V&&this.isLinkLabel?b=!1:(b=this.diagram,b=null!==b&&b.undoManager.isUndoingRedoing?!1:!0)}else b=!1;if(b)if(b=this.Ah,null!==b){var c=b.layout;null!==c?c.C():b.C(a)}else a=this.diagram,null!==a&&(a=a.layout,null!==a&&a.C())};function Qj(a){if(!a.isVisible())return!1;a=a.layer;return null!==a&&a.isTemporary?!1:!0}
function Nk(a,b,c,d,e,f){void 0===f&&(f=null);if(!(a.contains(b)||null!==f&&!f(b)||b instanceof pe))if(a.add(b),b instanceof V){if(c&&b instanceof jf)for(var g=b.memberParts;g.next();)Nk(a,g.value,c,d,e,f);if(!1!==e)for(g=b.linksConnected;g.next();){var h=g.value;if(!a.contains(h)){var k=h.fromNode,l=h.toNode;k=null===k||a.contains(k);l=null===l||a.contains(l);(e?k&&l:k||l)&&Nk(a,h,c,d,e,f)}}if(1<d)for(b=b.Pu();b.next();)Nk(a,b.value,c,d-1,e,f)}else if(b instanceof T)for(b=b.labelNodes;b.next();)Nk(a,
b.value,c,d,e,f)}
ma.Object.defineProperties(U.prototype,{key:{get:function(){var a=this.diagram;if(null!==a)return a.model.wa(this.data)}},adornments:{get:function(){return null===this.Qf?Ya:this.Qf.iteratorValues}},layer:{get:function(){return this.Ig}},diagram:{get:function(){var a=this.Ig;return null===a||void 0===a?null:a.diagram}},layerName:{get:function(){return this.Jg},set:function(a){var b=
this.Jg;if(b!==a){var c=this.diagram;if(null===c||null!==c.Pj(a)&&!c.partManager.addsToTemporaryLayer)if(this.Jg=a,null!==c&&c.Ya(),this.g("layerName",b,a),b=this.layer,null!==b&&b.name!==a&&(c=b.diagram,null!==c&&(a=c.Pj(a),null!==a&&a!==b))){var d=b.qc(-1,this,!0);0<=d&&c.cb(ge,"parts",b,this,null,d,!0);d=a.oi(99999999,this,!0);b.visible!==a.visible&&this.ac(a.visible);0<=d&&c.cb(fe,"parts",a,null,this,!0,d);d=this.layerChanged;if(null!==d){var e=c.aa;c.aa=!0;d(this,b,a);c.aa=e}}}}},layerChanged:{
get:function(){return null!==this.P?this.P.zq:null},set:function(a){var b=this.layerChanged;b!==a&&(So(this),this.P.zq=a,this.g("layerChanged",b,a))}},zOrder:{get:function(){return this.Ao},set:function(a){var b=this.Ao;if(b!==a){this.Ao=a;var c=this.layer;null!==c&&ki(c,-1,this);this.g("zOrder",b,a);a=this.diagram;null!==a&&a.N()}}},locationObject:{get:function(){if(null===this.nf){var a=this.locationObjectName;""!==a?(a=this.fb(a),
null!==a?this.nf=a:this.nf=this):this instanceof pe?this.type!==W.Link&&null!==this.placeholder?this.nf=this.placeholder:this.nf=this:this.nf=this}return this.nf.visible?this.nf:this}},minLocation:{get:function(){return null!==this.P?this.P.Pq:qb},set:function(a){var b=this.minLocation;b.D(a)||(a=a.J(),So(this),this.P.Pq=a,this.g("minLocation",b,a))}},maxLocation:{get:function(){return null!==this.P?this.P.Kq:rb},set:function(a){var b=this.maxLocation;
b.D(a)||(a=a.J(),So(this),this.P.Kq=a,this.g("maxLocation",b,a))}},locationObjectName:{get:function(){return null!==this.P?this.P.Gq:""},set:function(a){var b=this.locationObjectName;b!==a&&(So(this),this.P.Gq=a,this.nf=null,this.v(),this.g("locationObjectName",b,a))}},locationSpot:{get:function(){return null!==this.P?this.P.Hq:sc},set:function(a){var b=this.locationSpot;b.D(a)||(a=a.J(),So(this),this.P.Hq=a,this.v(),this.g("locationSpot",
b,a))}},location:{get:function(){return this.ke},set:function(a){var b=a.x,c=a.y,d=this.ke;if(!(d.x===b||isNaN(d.x)&&isNaN(b))||!(d.y===c||isNaN(d.y)&&isNaN(c))){a=a.copy();b=a;if(this.ph())b=!1;else{this.ke=b;this.G|=2097152;if(!1===Aj(this)&&(Uo(this),c=this.pa,c.w())){var e=this.diagram;if(null===e||e.animationManager.isTicking||!e.undoManager.isUndoingRedoing){var f=c.copy();c.h(c.x+(b.x-d.x),c.y+(b.y-d.y));Zo(this,e,c,f.x,f.y);this.g("position",f,c.copy())}}b=!0}b&&
this.g("location",d,a.copy())}}},category:{get:function(){return this.Tf},set:function(a){var b=this.Tf;if(b!==a){null!==this.svg&&(this.svg.remove(),this.Uc=!0);var c=this.diagram,d=this.data,e=null;if(null!==c&&null!==d&&!(this instanceof pe)){var f=c.model.undoManager;f.isEnabled&&!f.isUndoingRedoing&&(e=this.clone(),e.O.addAll(this.O))}this.Tf=a;this.g("category",b,a);null===c||null===d||this instanceof pe?this instanceof pe&&(e=this.adornedPart,null!==e&&(a=e.Qf,
null!==a&&a.remove(b),e.bh(this.category,this))):(f=c.model,f.undoManager.isUndoingRedoing||(this.ph()?(c.partManager.setLinkCategoryForData(d,a),c=c.partManager.findLinkTemplateForCategory(a),null!==c&&(jg(c),c=c.copy(),null!==c&&bp(this,c,b,a))):(null!==f&&f.jm(d,a),c=jp(c.partManager,d,a),null!==c&&(jg(c),c=c.copy(),null===c||c instanceof T||(d=this.location.copy(),bp(this,c,b,a),this.location.w()||(this.location=d)))),null!==e&&(b=this.clone(),b.O.addAll(this.O),this.g("self",e,b))))}}},self:{
get:function(){return this},set:function(a){bp(this,a,this.category,a.category)}},copyable:{get:function(){return 0!==(this.G&1)},set:function(a){var b=0!==(this.G&1);b!==a&&(this.G^=1,this.g("copyable",b,a))}},deletable:{get:function(){return 0!==(this.G&2)},set:function(a){var b=0!==(this.G&2);b!==a&&(this.G^=2,this.g("deletable",b,a))}},textEditable:{get:function(){return 0!==(this.G&4)},set:function(a){var b=
0!==(this.G&4);b!==a&&(this.G^=4,this.g("textEditable",b,a),this.Yb())}},groupable:{get:function(){return 0!==(this.G&8)},set:function(a){var b=0!==(this.G&8);b!==a&&(this.G^=8,this.g("groupable",b,a))}},movable:{get:function(){return 0!==(this.G&16)},set:function(a){var b=0!==(this.G&16);b!==a&&(this.G^=16,this.g("movable",b,a))}},selectionAdorned:{get:function(){return 0!==(this.G&32)},set:function(a){var b=
0!==(this.G&32);b!==a&&(this.G^=32,this.g("selectionAdorned",b,a),this.Yb())}},isInDocumentBounds:{get:function(){return 0!==(this.G&64)},set:function(a){var b=0!==(this.G&64);if(b!==a){this.G^=64;var c=this.diagram;null!==c&&c.Ya();this.g("isInDocumentBounds",b,a)}}},isLayoutPositioned:{get:function(){return 0!==(this.G&128)},set:function(a){var b=0!==(this.G&128);b!==a&&(this.G^=128,this.g("isLayoutPositioned",b,a),this.C(a?4:8))}},selectable:{
get:function(){return 0!==(this.G&256)},set:function(a){var b=0!==(this.G&256);b!==a&&(this.G^=256,this.g("selectable",b,a),this.Yb())}},reshapable:{get:function(){return 0!==(this.G&512)},set:function(a){var b=0!==(this.G&512);b!==a&&(this.G^=512,this.g("reshapable",b,a),this.Yb())}},resizable:{get:function(){return 0!==(this.G&1024)},set:function(a){var b=0!==(this.G&1024);b!==a&&(this.G^=1024,this.g("resizable",b,a),this.Yb())}},
rotatable:{get:function(){return 0!==(this.G&2048)},set:function(a){var b=0!==(this.G&2048);b!==a&&(this.G^=2048,this.g("rotatable",b,a),this.Yb())}},isSelected:{get:function(){return 0!==(this.G&4096)},set:function(a){var b=0!==(this.G&4096);if(b!==a){var c=this.diagram;if(!a||this.canSelect()&&!(null!==c&&c.selection.count>=c.maxSelectionCount)){this.G^=4096;var d=!1;if(null!==c){d=c.skipsUndoManager;c.skipsUndoManager=!0;var e=c.selection;
e.ka();a?e.add(this):e.remove(this);e.freeze()}this.g("isSelected",b,a);this.Yb();a=this.selectionChanged;null!==a&&a(this);null!==c&&(c.Rb(),c.skipsUndoManager=d)}}}},isHighlighted:{get:function(){return 0!==(this.G&524288)},set:function(a){var b=0!==(this.G&524288);if(b!==a){this.G^=524288;var c=this.diagram;null!==c&&(c=c.highlighteds,c.ka(),a?c.add(this):c.remove(this),c.freeze());this.g("isHighlighted",b,a);this.N();a=this.highlightedChanged;null!==a&&a(this)}}},
isShadowed:{get:function(){return 0!==(this.G&8192)},set:function(a){var b=0!==(this.G&8192);b!==a&&(this.G^=8192,this.Uc=!0,this.g("isShadowed",b,a),this.N())}},isAnimated:{get:function(){return 0!==(this.G&262144)},set:function(a){var b=0!==(this.G&262144);b!==a&&(this.G^=262144,this.g("isAnimated",b,a))}},highlightedChanged:{get:function(){return null!==this.P?this.P.oq:null},set:function(a){var b=this.highlightedChanged;
b!==a&&(So(this),this.P.oq=a,this.g("highlightedChanged",b,a))}},selectionObjectName:{get:function(){return null!==this.P?this.P.vr:""},set:function(a){var b=this.selectionObjectName;b!==a&&(So(this),this.P.vr=a,this.Ug=null,this.g("selectionObjectName",b,a))}},selectionAdornmentTemplate:{get:function(){return null!==this.P?this.P.tr:null},set:function(a){var b=this.selectionAdornmentTemplate;b!==a&&(So(this),this.P.tr=a,this.g("selectionAdornmentTemplate",
b,a))}},selectionObject:{get:function(){if(null===this.Ug){var a=this.selectionObjectName;null!==a&&""!==a?(a=this.fb(a),null!==a?this.Ug=a:this.Ug=this):this instanceof T?(a=this.path,null!==a?this.Ug=a:this.Ug=this):this.Ug=this}return this.Ug}},selectionChanged:{get:function(){return null!==this.P?this.P.ur:null},set:function(a){var b=this.selectionChanged;b!==a&&(So(this),this.P.ur=a,this.g("selectionChanged",b,a))}},resizeAdornmentTemplate:{
get:function(){return null!==this.P?this.P.fr:null},set:function(a){var b=this.resizeAdornmentTemplate;b!==a&&(So(this),this.P.fr=a,this.g("resizeAdornmentTemplate",b,a))}},resizeObjectName:{get:function(){return null!==this.P?this.P.hr:""},set:function(a){var b=this.resizeObjectName;b!==a&&(So(this),this.P.hr=a,this.g("resizeObjectName",b,a))}},resizeObject:{get:function(){var a=this.resizeObjectName;return""!==a&&(a=this.fb(a),
null!==a)?a:this}},resizeCellSize:{get:function(){return null!==this.P?this.P.gr:Lb},set:function(a){var b=this.resizeCellSize;b.D(a)||(a=a.J(),So(this),this.P.gr=a,this.g("resizeCellSize",b,a))}},rotateAdornmentTemplate:{get:function(){return null!==this.P?this.P.ir:null},set:function(a){var b=this.rotateAdornmentTemplate;b!==a&&(So(this),this.P.ir=a,this.g("rotateAdornmentTemplate",b,a))}},rotateObjectName:{
get:function(){return null!==this.P?this.P.jr:""},set:function(a){var b=this.rotateObjectName;b!==a&&(So(this),this.P.jr=a,this.g("rotateObjectName",b,a))}},rotateObject:{get:function(){var a=this.rotateObjectName;return""!==a&&(a=this.fb(a),null!==a)?a:this}},rotationSpot:{get:function(){return null!==this.P?this.P.lr:Sc},set:function(a){var b=this.rotationSpot;b.D(a)||(a=a.J(),So(this),this.P.lr=a,this.g("rotationSpot",b,a))}},text:{
get:function(){return this.fc},set:function(a){var b=this.fc;b!==a&&(this.fc=a,this.g("text",b,a))}},containingGroup:{get:function(){return this.Ah},set:function(a){if(this.Zc()){var b=this.Ah;if(b!==a){null===a||this!==a&&!a.Td(this)||(this===a&&C("Cannot make a Group a member of itself: "+this.toString()),C("Cannot make a Group indirectly contain itself: "+this.toString()+" already contains "+a.toString()));this.C(2);var c=this.diagram;null!==b?fp(b,this):
this instanceof jf&&null!==c&&c.ai.remove(this);this.Ah=a;null!==a?ep(a,this):this instanceof jf&&null!==c&&c.ai.add(this);this.C(1);if(null!==c&&c.aa){var d=this.data,e=c.model;if(null!==d&&e.Wj()){var f=e.wa(null!==a?a.data:null);e.mp(d,f)}}d=this.containingGroupChanged;null!==d&&(e=!0,null!==c&&(e=c.aa,c.aa=!0),d(this,b,a),null!==c&&(c.aa=e));if(this instanceof jf)for(c=new H,Nk(c,this,!0,0,!0),c=c.iterator;c.next();)if(d=c.value,d instanceof V)for(d=d.linksConnected;d.next();)kp(d.value);if(this instanceof
V){for(c=this.linksConnected;c.next();)kp(c.value);c=this.labeledLink;null!==c&&kp(c)}this.g("containingGroup",b,a);null!==a&&(b=a.layer,null!==b&&ki(b,-1,a))}}else C("cannot set the Part.containingGroup of a Link or Adornment")}},containingGroupChanged:{get:function(){return null!==this.P?this.P.Kp:null},set:function(a){var b=this.containingGroupChanged;b!==a&&(So(this),this.P.Kp=a,this.g("containingGroupChanged",b,a))}},isTopLevel:{get:function(){return null!==
this.containingGroup||this instanceof V&&null!==this.labeledLink?!1:!0}},layoutConditions:{get:function(){return null!==this.P?this.P.Cq:127},set:function(a){var b=this.layoutConditions;b!==a&&(So(this),this.P.Cq=a,this.g("layoutConditions",b,a))}},dragComputation:{get:function(){return null!==this.P?this.P.Up:null},set:function(a){var b=this.dragComputation;b!==a&&(So(this),this.P.Up=a,this.g("dragComputation",b,a))}},shadowOffset:{
get:function(){return null!==this.P?this.P.zr:sb},set:function(a){var b=this.shadowOffset;b.D(a)||(a=a.J(),So(this),this.P.zr=a,this.N(),this.g("shadowOffset",b,a))}},shadowColor:{get:function(){return null!==this.P?this.P.yr:"gray"},set:function(a){var b=this.shadowColor;b!==a&&(So(this),this.P.yr=a,this.N(),this.g("shadowColor",b,a))}},shadowBlur:{get:function(){return null!==this.P?this.P.Vg:4},set:function(a){var b=this.shadowBlur;
b!==a&&(So(this),this.P.Vg=a,this.N(),this.g("shadowBlur",b,a))}}});U.prototype.invalidateLayout=U.prototype.C;U.prototype.findCommonContainingGroup=U.prototype.qx;U.prototype.isMemberOf=U.prototype.Td;U.prototype.findTopLevelPart=U.prototype.uz;U.prototype.findSubGraphLevel=U.prototype.pz;U.prototype.ensureBounds=U.prototype.Va;U.prototype.getDocumentBounds=U.prototype.mi;U.prototype.findObject=U.prototype.fb;U.prototype.moveTo=U.prototype.moveTo;U.prototype.invalidateAdornments=U.prototype.Yb;
U.prototype.clearAdornments=U.prototype.Dj;U.prototype.removeAdornment=U.prototype.Jf;U.prototype.addAdornment=U.prototype.bh;U.prototype.findAdornment=U.prototype.Oj;U.prototype.updateTargetBindings=U.prototype.La;var cp=!1;U.className="Part";U.LayoutNone=0;U.LayoutAdded=1;U.LayoutRemoved=2;U.LayoutShown=4;U.LayoutHidden=8;U.LayoutNodeSized=16;U.LayoutGroupLayout=32;U.LayoutNodeReplaced=64;U.LayoutStandard=127;U.LayoutAll=16777215;
function pe(a,b){void 0===a||a instanceof dm||"string"===typeof a?U.call(this,a):(U.call(this),a&&(b=a));this.G&=-257;this.Jg="Adornment";this.ue=null;this.Bw=0;this.Vw=!1;this.j=this.$a=null;b&&Object.assign(this,b)}la(pe,U);pe.prototype.toString=function(){var a=this.adornedPart;return"Adornment("+this.category+")"+(null!==a?a.toString():"")};pe.prototype.updateRelationshipsFromData=function(){};
pe.prototype.Ul=function(a){var b=this.adornedObject,c=null===b||void 0===b?void 0:b.part;if(c instanceof T&&b instanceof Bf){b=c.path;c.Ul(a);a=b.geometry;c=this.O.s;b=c.length;for(var d=0;d<b;d++){var e=c[d];e.isPanelMain&&e instanceof Bf&&(e.ua=a)}}};pe.prototype.si=function(){var a=this.ue;if(null===a)return!0;a=a.part;return null===a||!Aj(a)};pe.prototype.Zc=function(){return!1};
pe.prototype.fk=function(a,b,c,d,e,f,g){if(a===fe&&"elements"===b)if(e instanceof ig)null===this.$a&&(this.$a=e);else{if(e instanceof W){var h=e.Nl(function(a){return a instanceof ig});h instanceof ig&&null===this.$a&&(this.$a=h)}}else a===ge&&"elements"===b&&null!==this.$a&&(d===this.$a?this.$a=null:d instanceof W&&this.$a.rg(d)&&(this.$a=null));U.prototype.fk.call(this,a,b,c,d,e,f,g)};pe.prototype.updateAdornments=function(){};pe.prototype.Kj=function(){};
ma.Object.defineProperties(pe.prototype,{placeholder:{get:function(){return this.$a}},adornedObject:{get:function(){return this.ue},set:function(a){var b=this.adornedPart,c=null;null!==a&&(c=a.part);null===b||null!==a&&b===c||b.Jf(this.category);this.ue=a;null!==c&&c.bh(this.category,this)}},adornedPart:{get:function(){var a=this.ue;return null!==a?a.part:null}},containingGroup:{
get:function(){return null}}});pe.className="Adornment";function lp(){this.oc=!1;this.Vr=this.gj=this.Eq=this.Dq=null;this.yp=kc;this.er=mp;this.Kr=this.ij=this.Nq=this.Mq=null}lp.prototype.copy=function(){var a=new lp;a.Dq=this.Dq;a.Eq=this.Eq;a.gj=this.gj;a.Vr=this.Vr;a.yp=this.yp.J();a.er=this.er;a.Mq=this.Mq;a.Nq=this.Nq;a.ij=this.ij;a.Kr=this.Kr;return a};
function V(a,b){void 0===a||a instanceof dm||"string"===typeof a?U.call(this,a):(U.call(this),a&&(b=a));this.R=13;this.Ba=null;this.jb=new F;this.wc=this.af=this.Xk=null;this.ci=!1;b&&Object.assign(this,b)}la(V,U);V.prototype.cloneProtected=function(a){U.prototype.cloneProtected.call(this,a);a.R=this.R;null!==this.Ba&&(a.Ba=this.Ba.oc?this.Ba:this.Ba.copy())};t=V.prototype;t.Af=function(a){U.prototype.Af.call(this,a);a.Bd();a.af=this.af;a.wc=null};
function np(a){null===a.Ba?a.Ba=new lp:a.Ba.oc&&(a.Ba=a.Ba.copy())}t.th=function(){U.prototype.th.call(this);null!==this.Ba&&(this.Ba.oc=!0)};function op(a,b){null!==b&&(null===a.af&&(a.af=new H),a.af.add(b))}function pp(a,b,c,d){if(null===b||null===a.af)return null;for(var e=a.af.iterator;e.next();){var f=e.value;if(f.Ds===a&&f.dv===b&&f.Px===c&&f.Qx===d||f.Ds===b&&f.dv===a&&f.Px===d&&f.Qx===c)return f}return null}
t.Mz=function(a,b,c){if(void 0===b||null===b)b="";if(void 0===c||null===c)c="";a=pp(this,a,b,c);null!==a&&a.Sl()};t.fk=function(a,b,c,d,e,f,g){a===fe&&"elements"===b?this.wc=null:a===ge&&"elements"===b&&(this.wc=null);U.prototype.fk.call(this,a,b,c,d,e,f,g)};t.Bd=function(a){for(var b=this.linksConnected;b.next();){var c=b.value;void 0!==a&&a.contains(c)||(qp(c.fromPort),qp(c.toPort),c.bb())}};
function sl(a,b){for(a=a.linksConnected;a.next();){var c=a.value;if(c.fromPort===b||c.toPort===b)qp(c.fromPort),qp(c.toPort),c.bb()}}function qp(a){if(null!==a){var b=a.Zn;null!==b&&b.Sl();a=a.part;b=a.containingGroup;null===b||a.isVisible()||qp(b.port)}}t.si=function(){return!0};V.prototype.getAvoidableRect=function(a){a.set(this.actualBounds);a.Co(this.avoidableMargin);return a};V.prototype.findVisibleNode=function(){for(var a=this;null!==a&&!a.isVisible();)a=a.containingGroup;return a};
V.prototype.isVisible=function(){if(!U.prototype.isVisible.call(this))return!1;var a=!0,b=yi,c=this.diagram;null!==c&&(a=c.isTreePathToChildren,b=c.treeCollapsePolicy);if(b===yi){if(a=this.pg(),null!==a&&!a.isTreeExpanded)return!1}else if(b===Ck){if(a=a?this.Oo():this.Po(),0<a.count&&a.all(function(a){return!a.isTreeExpanded}))return!1}else if(b===Dk&&(a=a?this.Oo():this.Po(),0<a.count&&a.any(function(a){return!a.isTreeExpanded})))return!1;a=this.labeledLink;return null!==a?a.isVisible():!0};
V.prototype.ac=function(a){U.prototype.ac.call(this,a);for(var b=this.linksConnected;b.next();){var c=b.value;if(a&&null!==this.containingGroup){var d=c.To(this);null===d||d.Td(this.containingGroup)||c.bb()}c.ac(a)}};V.prototype.sx=function(){var a=new H,b=new H;rp(this,this,a,b);return b.iterator};
function rp(a,b,c,d){if(null!==b&&!c.has(b)){c.add(b);var e=!0,f=a.diagram;null!==f&&(e=f.isTreePathToChildren);b.linksConnected.each(function(f){f.isTreeLink?(e?f.fromNode===b:f.toNode===b)&&rp(a,e?f.toNode:f.fromNode,c,d):d.add(f)})}}V.prototype.findLinksConnected=function(a){void 0===a&&(a=null);if(null===a)return this.jb.iterator;var b=new $a(this.jb),c=this;b.predicate=function(b){return b.fromNode===c&&b.fromPortId===a||b.toNode===c&&b.toPortId===a};return b};t=V.prototype;
t.Mo=function(a){void 0===a&&(a=null);var b=new $a(this.jb),c=this;b.predicate=function(b){return b.fromNode!==c?!1:null===a?!0:b.fromPortId===a};return b};t.Sd=function(a){void 0===a&&(a=null);var b=new $a(this.jb),c=this;b.predicate=function(b){return b.toNode!==c?!1:null===a?!0:b.toPortId===a};return b};
t.Nu=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.jb.iterator;d.next();){var e=d.value;if(e.fromNode===this){if(null===a||e.fromPortId===a)e=e.toNode,null!==b?b.add(e):null!==c&&c!==e?(b=new H,b.add(c),b.add(e)):c=e}else e.toNode!==this||null!==a&&e.toPortId!==a||(e=e.fromNode,null!==b?b.add(e):null!==c&&c!==e?(b=new H,b.add(c),b.add(e)):c=e)}return null!==b?b.iterator:null!==c?new Za(c):Ya};
t.Po=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.jb.iterator;d.next();){var e=d.value;e.fromNode!==this||null!==a&&e.fromPortId!==a||(e=e.toNode,null!==b?b.add(e):null!==c&&c!==e?(b=new H,b.add(c),b.add(e)):c=e)}return null!==b?b.iterator:null!==c?new Za(c):Ya};
t.Oo=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.jb.iterator;d.next();){var e=d.value;e.toNode!==this||null!==a&&e.toPortId!==a||(e=e.fromNode,null!==b?b.add(e):null!==c&&c!==e?(b=new H,b.add(c),b.add(e)):c=e)}return null!==b?b.iterator:null!==c?new Za(c):Ya};
t.kz=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);var d=new $a(this.jb),e=this;d.predicate=function(d){return(d.fromNode!==e||d.toNode!==a||null!==b&&d.fromPortId!==b||null!==c&&d.toPortId!==c)&&(d.fromNode!==a||d.toNode!==e||null!==c&&d.fromPortId!==c||null!==b&&d.toPortId!==b)?!1:!0};return d};
t.lz=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);var d=new $a(this.jb),e=this;d.predicate=function(d){return d.fromNode!==e||d.toNode!==a||null!==b&&d.fromPortId!==b||null!==c&&d.toPortId!==c?!1:!0};return d};
function sp(a,b,c){qp(c);var d=a.jb.contains(b);d||a.jb.add(b);if(!d||b.fromNode===b.toNode){var e=a.linkConnected;if(null!==e){var f=!0,g=a.diagram;null!==g&&(f=g.aa,g.aa=!0);e(a,b,c);null!==g&&(g.aa=f)}}!d&&b.isTreeLink&&(c=b.fromNode,b=b.toNode,null!==c&&null!==b&&c!==b&&(d=!0,a=a.diagram,null!==a&&(d=a.isTreePathToChildren),e=d?b:c,f=d?c:b,e.ci||(e.ci=f),!f.isTreeLeaf||null!==a&&a.undoManager.isUndoingRedoing||(d?c===f&&(f.isTreeLeaf=!1):b===f&&(f.isTreeLeaf=!1))))}
function tp(a,b,c){qp(c);var d=a.jb.remove(b),e=null;if(d||b.toNode===b.fromNode){var f=a.linkDisconnected;e=a.diagram;if(null!==f){var g=!0;null!==e&&(g=e.aa,e.aa=!0);f(a,b,c);null!==e&&(e.aa=g)}}d&&b.isTreeLink&&(c=!0,null!==e&&(c=e.isTreePathToChildren),a=c?b.toNode:b.fromNode,b=c?b.fromNode:b.toNode,null!==a&&(a.ci=!1),null===b||b.isTreeLeaf||(0===b.jb.count?(b.ci=null,null!==e&&e.undoManager.isUndoingRedoing||(b.isTreeLeaf=!0)):Bk(b)))}
function Bk(a){a.ci=!1;if(0!==a.jb.count){var b=!0,c=a.diagram;if(null===c||!c.undoManager.isUndoingRedoing){null!==c&&(b=c.isTreePathToChildren);for(c=a.jb.iterator;c.next();){var d=c.value;if(d.isTreeLink)if(b){if(d.fromNode===a){a.isTreeLeaf=!1;return}}else if(d.toNode===a){a.isTreeLeaf=!1;return}}a.isTreeLeaf=!0}}}V.prototype.updateRelationshipsFromData=function(){var a=this.diagram;null!==a&&a.partManager.updateRelationshipsFromData(this)};t=V.prototype;
t.Vo=function(a){U.prototype.Vo.call(this,a);a||(Bk(this),a=this.Xk,null!==a&&up(a,this))};t.Wo=function(a){U.prototype.Wo.call(this,a);a||(a=this.Xk,null!==a&&null!==a.wd&&(a.wd.remove(this),a.v()))};
t.Kj=function(){if(0<this.jb.count){var a=this.diagram;if(null!==a)for(var b=null!==a.commandHandler?a.commandHandler.deletesConnectedLinks:!0,c=this.jb.copy().iterator;c.next();){var d=c.value;b?a.remove(d):(d.fromNode===this&&(d.fromNode=null),d.toNode===this&&(d.toNode=null))}}this.labeledLink=null;U.prototype.Kj.call(this)};t.ns=function(a){if(null===this.wc){if(""===a&&!1===this.Te)return this;vp(this)}var b=this.wc.I(a);return null!==b||""!==a&&(b=this.wc.I(""),null!==b)?b:this};
function vp(a){null===a.wc?a.wc=new ob:a.wc.clear();a.qm(a,function(a,c){jm(a,c)});0===a.wc.count&&a.wc.add("",a)}function jm(a,b){var c=b.portId;null!==c&&null!==a.wc&&a.wc.add(c,b)}function im(a,b,c){var d=b.portId;if(null!==d&&(null!==a.wc&&a.wc.remove(d),b=a.diagram,null!==b&&c)){c=null;for(a=a.findLinksConnected(d);a.next();)d=a.value,null===c&&(c=Ea()),c.push(d);if(null!==c){for(a=0;a<c.length;a++)b.remove(c[a]);Ga(c)}}}
t.Oz=function(a){if(null===a||a===this)return!1;var b=!0,c=this.diagram;null!==c&&(b=c.isTreePathToChildren);c=this;if(b)for(;c!==a;){b=null;for(var d=c.jb.iterator;d.next();){var e=d.value;if(e.isTreeLink&&(b=e.fromNode,b!==c&&b!==this))break}if(b===this||null===b||b===c)return!1;c=b}else for(;c!==a;){b=null;for(d=c.jb.iterator;d.next()&&(e=d.value,!e.isTreeLink||(b=e.toNode,b===c||b===this)););if(b===this||null===b||b===c)return!1;c=b}return!0};
t.yz=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);b=this;if(a)for(;;){a=null;for(var c=b.jb.iterator;c.next();){var d=c.value;if(d.isTreeLink&&(a=d.fromNode,a!==b&&a!==this))break}if(a===this)return this;if(null===a||a===b)return b;b=a}else for(;;){a=null;for(c=b.jb.iterator;c.next()&&(d=c.value,!d.isTreeLink||(a=d.toNode,a===b||a===this)););if(a===this)return this;if(null===a||a===b)return b;b=a}};
t.hz=function(a){if(null===a)return null;if(this===a)return this;for(var b=this;null!==b;)dp(b,!0),b=b.pg();var c=null;for(b=a;null!==b;){if(0!==(b.G&1048576)){c=b;break}b=b.pg()}for(b=this;null!==b;)dp(b,!1),b=b.pg();return c};t.kh=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);b=this.jb.iterator;if(a)for(;b.next();){if(a=b.value,a.isTreeLink&&a.fromNode!==this)return a}else for(;b.next();)if(a=b.value,a.isTreeLink&&a.toNode!==this)return a;return null};
t.pg=function(){var a=this.ci;if(null===a)return null;if(a instanceof V)return a;a=this.diagram;a=null!==a?a.isTreePathToChildren:!0;for(var b=this.jb.iterator;b.next();){var c=b.value;if(c.isTreeLink&&(c=a?c.fromNode:c.toNode,c!==this))return this.ci=c}return this.ci=null};t.wz=function(){function a(b,d){d.add(b);var c=b.kh();null!==c&&(d.add(c),b=b.pg(),null!==b&&a(b,d))}var b=new H;a(this,b);return b};t.vz=function(){return wp(this,this)};function wp(a,b){b=b.pg();return null===b?0:1+wp(a,b)}
t.Qo=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);b=new $a(this.jb);var c=this;b.predicate=a?function(a){return a.isTreeLink&&a.fromNode===c?!0:!1}:function(a){return a.isTreeLink&&a.toNode===c?!0:!1};return b};
t.Pu=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);var c=b=null,d=this.jb.iterator;if(a)for(;d.next();)a=d.value,a.isTreeLink&&a.fromNode===this&&(a=a.toNode,null!==b?b.add(a):null!==c&&c!==a?(b=new F,b.add(c),b.add(a)):c=a);else for(;d.next();)a=d.value,a.isTreeLink&&a.toNode===this&&(a=a.fromNode,null!==b?b.add(a):null!==c&&c!==a?(b=new F,b.add(c),b.add(a)):c=a);return null!==b?b.iterator:null!==c?new Za(c):Ya};
t.xz=function(a){void 0===a&&(a=Infinity);var b=new H;Nk(b,this,!1,a,!0);return b};V.prototype.collapseTree=function(a){void 0===a&&(a=1);1>a&&(a=1);var b=this.diagram;if(null!==b&&!b.Ue){b.Ue=!0;var c=new H;c.add(this);xp(this,c,b.isTreePathToChildren,a,b,this,b.treeCollapsePolicy===yi);b.Ue=!1}};function xp(a,b,c,d,e,f,g){if(1<d)for(var h=c?a.Mo():a.Sd();h.next();){var k=h.value;k.isTreeLink&&(k=k.To(a),null===k||k===a||b.contains(k)||(b.add(k),xp(k,b,c,d-1,e,f,g)))}else yp(a,b,c,e,f,g)}
function yp(a,b,c,d,e,f){for(var g=e===a?!0:a.isTreeExpanded,h=c?a.Mo():a.Sd(),k={};h.next();){var l=h.value;if(l.isTreeLink&&(l=l.To(a),null!==l&&l!==a)){var m=b.contains(l);m||b.add(l);if(g){var n=c?l.Oo():l.Po();k.vv=a;n.all(function(a){return function(b){return b===a.vv||!b.isTreeExpanded}}(k))&&d.Aj(l,e);l.oh();l.ac(!1)}l.isTreeExpanded&&(l.wasTreeExpanded=l.isTreeExpanded,m||yp(l,b,c,d,e,f))}k={vv:k.vv}}a.isTreeExpanded=!1}
V.prototype.expandTree=function(a){void 0===a&&(a=2);2>a&&(a=2);var b=this.diagram;if(null!==b&&!b.Ue){b.Ue=!0;var c=new H;c.add(this);zp(this,c,b.isTreePathToChildren,a,b,this,b.treeCollapsePolicy===yi);b.Ue=!1}};
function zp(a,b,c,d,e,f,g){for(var h=f===a?!1:a.isTreeExpanded,k=c?a.Mo():a.Sd(),l={};k.next();){var m=k.value;if(m.isTreeLink&&(h||m.fd||m.bb(),m=m.To(a),null!==m&&m!==a&&!b.contains(m))){b.add(m);if(!h){m.ac(!0);m.oh();var n=c?m.Oo():m.Po();l.wv=a;n.all(function(a){return function(b){return b===a.wv||!b.isTreeExpanded}}(l))&&e.Cj(m,f)}if(2<d||m.wasTreeExpanded)m.wasTreeExpanded=!1,zp(m,b,c,d-1,e,f,g)}l={wv:l.wv}}a.isTreeExpanded=!0}
ma.Object.defineProperties(V.prototype,{portSpreading:{get:function(){return null!==this.Ba?this.Ba.er:mp},set:function(a){var b=this.portSpreading;b!==a&&(np(this),this.Ba.er=a,this.g("portSpreading",b,a),a=this.diagram,null!==a&&a.undoManager.isUndoingRedoing||this.Bd())}},avoidable:{get:function(){return 0!==(this.R&8)},set:function(a){var b=0!==(this.R&8);if(b!==a){this.R^=8;var c=this.diagram;null!==c&&wk(c,this);this.g("avoidable",
b,a)}}},avoidableMargin:{get:function(){return null!==this.Ba?this.Ba.yp:kc},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.avoidableMargin;if(!b.D(a)){a=a.J();np(this);this.Ba.yp=a;var c=this.diagram;null!==c&&wk(c,this);this.g("avoidableMargin",b,a)}}},linksConnected:{get:function(){return this.jb.iterator}},linkConnected:{get:function(){return null!==this.Ba?this.Ba.Dq:null},set:function(a){var b=
this.linkConnected;b!==a&&(np(this),this.Ba.Dq=a,this.g("linkConnected",b,a))}},linkDisconnected:{get:function(){return null!==this.Ba?this.Ba.Eq:null},set:function(a){var b=this.linkDisconnected;b!==a&&(np(this),this.Ba.Eq=a,this.g("linkDisconnected",b,a))}},linkValidation:{get:function(){return null!==this.Ba?this.Ba.gj:null},set:function(a){var b=this.linkValidation;b!==a&&(np(this),this.Ba.gj=a,this.g("linkValidation",b,a))}},isLinkLabel:{
get:function(){return null!==this.Xk}},labeledLink:{get:function(){return this.Xk},set:function(a){var b=this.Xk;if(b!==a){var c=this.diagram,d=this.data;if(null!==b){null!==b.wd&&(b.wd.remove(this),b.v());if(null!==c&&null!==d&&!c.undoManager.isUndoingRedoing){var e=b.data,f=c.model;if(null!==e&&f.ri()){var g=f.wa(d);void 0!==g&&f.Rx(e,g)}}this.containingGroup=null}this.Xk=a;null!==a&&(up(a,this),null===c||null===d||c.undoManager.isUndoingRedoing||(e=a.data,
c=c.model,null!==e&&c.ri()&&(d=c.wa(d),void 0!==d&&c.Bu(e,d))),this.containingGroup=a.containingGroup);Sl(this);this.g("labeledLink",b,a)}}},port:{get:function(){return this.ns("")}},ports:{get:function(){null===this.wc&&vp(this);return this.wc.iteratorValues}},isTreeExpanded:{get:function(){return 0!==(this.R&1)},set:function(a){var b=0!==(this.R&1);if(b!==a){this.R^=1;var c=this.diagram;this.g("isTreeExpanded",
b,a);b=this.treeExpandedChanged;if(null!==b){var d=!0;null!==c&&(d=c.aa,c.aa=!0);b(this);null!==c&&(c.aa=d)}null!==c&&c.undoManager.isUndoingRedoing?this.ac(a):a?this.expandTree():this.collapseTree()}}},wasTreeExpanded:{get:function(){return 0!==(this.R&2)},set:function(a){var b=0!==(this.R&2);b!==a&&(this.R^=2,this.g("wasTreeExpanded",b,a))}},treeExpandedChanged:{get:function(){return null!==this.Ba?this.Ba.Vr:null},set:function(a){var b=
this.treeExpandedChanged;b!==a&&(np(this),this.Ba.Vr=a,this.g("treeExpandedChanged",b,a))}},isTreeLeaf:{get:function(){return 0!==(this.R&4)},set:function(a){var b=0!==(this.R&4);b!==a&&(this.R^=4,this.g("isTreeLeaf",b,a))}}});V.prototype.expandTree=V.prototype.expandTree;V.prototype.collapseTree=V.prototype.collapseTree;V.prototype.findTreeParts=V.prototype.xz;V.prototype.findTreeChildrenNodes=V.prototype.Pu;V.prototype.findTreeChildrenLinks=V.prototype.Qo;
V.prototype.findTreeLevel=V.prototype.vz;V.prototype.findTreeParentChain=V.prototype.wz;V.prototype.findTreeParentNode=V.prototype.pg;V.prototype.findTreeParentLink=V.prototype.kh;V.prototype.findCommonTreeParent=V.prototype.hz;V.prototype.findTreeRoot=V.prototype.yz;V.prototype.isInTreeOf=V.prototype.Oz;V.prototype.findPort=V.prototype.ns;V.prototype.findLinksTo=V.prototype.lz;V.prototype.findLinksBetween=V.prototype.kz;V.prototype.findNodesInto=V.prototype.Oo;V.prototype.findNodesOutOf=V.prototype.Po;
V.prototype.findNodesConnected=V.prototype.Nu;V.prototype.findLinksInto=V.prototype.Sd;V.prototype.findLinksOutOf=V.prototype.Mo;V.prototype.findExternalTreeLinksConnected=V.prototype.sx;V.prototype.invalidateConnectedLinks=V.prototype.Bd;V.prototype.invalidateLinkBundle=V.prototype.Mz;var Ap=new D(V,"SpreadingNone",10),mp=new D(V,"SpreadingEvenly",11),Bp=new D(V,"SpreadingPacked",12);V.className="Node";V.SpreadingNone=Ap;V.SpreadingEvenly=mp;V.SpreadingPacked=Bp;
function jf(a,b){void 0===a||a instanceof dm||"string"===typeof a?V.call(this,a):(V.call(this),a&&(b=a));this.R|=4608;this.Gn=new H;this.$a=null;this.Sc=new ti;this.Sc.group=this;this.fl=new H;this.tb=this.$p=null;b&&Object.assign(this,b)}la(jf,V);
jf.prototype.cloneProtected=function(a){V.prototype.cloneProtected.call(this,a);this.R=this.R&-32769;var b=a.Nl(function(a){return a instanceof ig});b instanceof ig?a.$a=b:a.$a=null;null!==this.Sc?(a.Sc=this.Sc.copy(),a.Sc.group=a):(null!==a.Sc&&(a.Sc.group=null),a.Sc=null);null!==this.tb&&(a.tb=this.tb.copy())};t=jf.prototype;
t.Af=function(a){V.prototype.Af.call(this,a);var b=a.Qj();for(a=a.memberParts;a.next();){var c=a.value;c.v();c.C(8);c.Dj();if(c instanceof V)c.Bd(b);else if(c instanceof T)for(c=c.labelNodes;c.next();)c.value.Bd(b)}};
t.fk=function(a,b,c,d,e,f,g){if(a===fe&&"elements"===b)if(e instanceof ig)null===this.$a?this.$a=e:this.$a!==e&&C("Cannot insert a second Placeholder into the visual tree of a Group.");else{if(e instanceof W){var h=e.Nl(function(a){return a instanceof ig});h instanceof ig&&(null===this.$a?this.$a=h:this.$a!==h&&C("Cannot insert a second Placeholder into the visual tree of a Group."))}}else a===ge&&"elements"===b&&null!==this.$a&&(d===this.$a?this.$a=null:d instanceof W&&this.$a.rg(d)&&(this.$a=null));
V.prototype.fk.call(this,a,b,c,d,e,f,g)};t.Xl=function(a,b){V.prototype.Xl.call(this,a,b);this.isClipping&&this.type!==W.Spot&&this.isSubGraphExpanded&&(a=this.resizeObject,a instanceof W&&(a=a.Qb()),null===this.tb&&(this.tb=new N),a.mi(this.tb),a instanceof Bf&&this.tb.Bc(-a.strokeWidth,-a.strokeWidth))};t.eh=function(a,b,c,d){this.nf=this.$a;V.prototype.eh.call(this,a,b,c,d)};
t.Va=function(){var a=this;a.isSubGraphExpanded&&this.memberParts.each(function(b){!a.computesBoundsIncludingLinks&&b instanceof T||b.Va()});V.prototype.Va.call(this)};t.si=function(){if(!V.prototype.si.call(this))return!1;for(var a=this.memberParts;a.next();){var b=a.value;if(b instanceof V){if(b.isVisible()&&Aj(b))return!1}else if(b instanceof T&&b.isVisible()&&Aj(b)&&b.fromNode!==this&&b.toNode!==this)return!1}return!0};
function ep(a,b){if(a.Gn.add(b)){b instanceof jf&&a.fl.add(b);var c=a.memberAdded;if(null!==c){var d=!0,e=a.diagram;null!==e&&(d=e.aa,e.aa=!0);c(a,b);null!==e&&(e.aa=d)}a.isVisible()&&a.isSubGraphExpanded||b.ac(!1)}b instanceof T&&!a.computesBoundsIncludingLinks||(b=a.$a,null===b&&(b=a),b.v())}
function fp(a,b){if(a.Gn.remove(b)){b instanceof jf&&a.fl.remove(b);var c=a.memberRemoved;if(null!==c){var d=!0,e=a.diagram;null!==e&&(d=e.aa,e.aa=!0);c(a,b);null!==e&&(e.aa=d)}a.isVisible()&&a.isSubGraphExpanded||b.ac(!0)}b instanceof T&&!a.computesBoundsIncludingLinks||(b=a.$a,null===b&&(b=a),b.v())}t.Kj=function(){if(0<this.Gn.count){var a=this.diagram;if(null!==a)for(var b=this.Gn.copy().iterator;b.next();)a.remove(b.value)}V.prototype.Kj.call(this)};
jf.prototype.canAddMembers=function(a){var b=this.diagram;if(null===b)return!1;b=b.commandHandler;for(a=Pk(a).iterator;a.next();)if(!b.isValidMember(this,a.value))return!1;return!0};jf.prototype.addMembers=function(a,b){var c=this.diagram;if(null===c)return!1;c=c.commandHandler;var d=!0;for(a=Pk(a).iterator;a.next();){var e=a.value;!b||c.isValidMember(this,e)?e.containingGroup=this:d=!1}return d};
jf.prototype.canUngroup=function(){if(!this.ungroupable)return!1;var a=this.layer;if(null===a||!a.allowUngroup)return!1;a=a.diagram;return null===a||a.allowUngroup?!0:!1};t=jf.prototype;
t.Bd=function(a){var b=0!==(this.R&65536);V.prototype.Bd.call(this,a);if(!b)for(0!==(this.R&65536)!==!0&&(this.R=this.R^65536),b=this.Mu();b.next();){var c=b.value;if(void 0===a||!a.contains(c)){var d=c.fromNode;null!==d&&d!==this&&d.Td(this)&&!d.isVisible()?(qp(c.fromPort),qp(c.toPort),c.bb()):(d=c.toNode,null!==d&&d!==this&&d.Td(this)&&!d.isVisible()&&(qp(c.fromPort),qp(c.toPort),c.bb()))}}};
t.Mu=function(){var a=null!==this.diagram&&this.diagram.Qw;if(a&&null!==this.$p)return this.$p.iterator;var b=this.Qj();b.add(this);for(var c=new H,d=b.iterator;d.next();){var e=d.value;if(e instanceof V)for(e=e.linksConnected;e.next();){var f=e.value;b.contains(f)||c.add(f)}}a&&(this.$p=c);return c.iterator};
t.jz=function(){var a=this.Qj();a.add(this);for(var b=new H,c=a.iterator;c.next();){var d=c.value;if(d instanceof V)for(d=d.linksConnected;d.next();){var e=d.value,f=e.fromNode;null===f||a.contains(f)&&f!==this||b.add(f);e=e.toNode;null===e||a.contains(e)&&e!==this||b.add(e)}}return b.iterator};t.iz=function(){function a(b,d){null!==b&&(d.add(b),a(b.containingGroup,d))}var b=new H;a(this,b);return b};t.Qj=function(){var a=new H;Nk(a,this,!0,0,!0);a.remove(this);return a};
t.ac=function(a){V.prototype.ac.call(this,a);for(var b=this.memberParts;b.next();)b.value.ac(a)};jf.prototype.collapseSubGraph=function(){var a=this.diagram;if(null!==a&&!a.Ue){a.Ue=!0;var b=this.Qj();Cp(this,b,a,this);a.Ue=!1}};
function Cp(a,b,c,d){for(var e=a.memberParts;e.next();){var f=e.value;f.ac(!1);f instanceof jf&&f.isSubGraphExpanded&&(f.wasSubGraphExpanded=f.isSubGraphExpanded,Cp(f,b,c,d));if(f instanceof V)f.Bd(b),c.Aj(f,d);else if(f instanceof T)for(f=f.labelNodes;f.next();)f.value.Bd(b)}a.isSubGraphExpanded=!1}jf.prototype.expandSubGraph=function(){var a=this.diagram;if(null!==a&&!a.Ue){a.Ue=!0;var b=this.Qj();Dp(this,b,a,this);a.Ue=!1}};
function Dp(a,b,c,d){for(var e=a.memberParts;e.next();){var f=e.value;f.ac(!0);f instanceof jf&&f.wasSubGraphExpanded&&(f.wasSubGraphExpanded=!1,Dp(f,b,c,d));if(f instanceof V)f.Bd(b),c.Cj(f,d);else if(f instanceof T)for(f=f.labelNodes;f.next();)f.value.Bd(b)}a.isSubGraphExpanded=!0}
jf.prototype.move=function(a,b){void 0===b&&(b=!1);var c=b?this.location:this.position,d=c.x;c=c.y;var e=a.x,f=a.y;if(!(d===e||isNaN(d)&&isNaN(e))||!(c===f||isNaN(c)&&isNaN(f))){d=e-(isNaN(d)?0:d);c=f-(isNaN(c)?0:c);f=I.alloc();V.prototype.move.call(this,a,b);a=new H;for(b=this.Qj().iterator;b.next();)if(e=b.value,e instanceof T&&(e.suspendsRouting&&a.add(e),e.fd||e.fromNode!==this&&e.toNode!==this))e.suspendsRouting=!0;for(b.reset();b.next();)if(e=b.value,!(e.ph()||e instanceof V&&e.isLinkLabel)){var g=
e.position,h=e.location;g.w()?(f.x=g.x+d,f.y=g.y+c,e.position=f):h.w()&&(f.x=h.x+d,f.y=h.y+c,e.location=f)}for(b.reset();b.next();)if(e=b.value,e instanceof T&&(e.suspendsRouting=a.contains(e),e.fd||e.fromNode!==this&&e.toNode!==this))g=e.position,f.x=g.x+d,f.y=g.y+c,f.w()?e.move(f):e.bb();I.free(f)}};
ma.Object.defineProperties(jf.prototype,{placeholder:{get:function(){return this.$a}},computesBoundsAfterDrag:{get:function(){return 0!==(this.R&2048)},set:function(a){var b=0!==(this.R&2048);b!==a&&(this.R^=2048,this.g("computesBoundsAfterDrag",b,a))}},computesBoundsIncludingLinks:{get:function(){return 0!==(this.R&4096)},set:function(a){var b=0!==(this.R&4096);b!==a&&(this.R^=4096,this.g("computesBoundsIncludingLinks",
b,a))}},computesBoundsIncludingLocation:{get:function(){return 0!==(this.R&8192)},set:function(a){var b=0!==(this.R&8192);b!==a&&(this.R^=8192,this.g("computesBoundsIncludingLocation",b,a))}},handlesDragDropForMembers:{get:function(){return 0!==(this.R&16384)},set:function(a){var b=0!==(this.R&16384);b!==a&&(this.R^=16384,this.g("handlesDragDropForMembers",b,a))}},avoidableMembers:{get:function(){return 0!==
(this.R&131072)},set:function(a){var b=0!==(this.R&131072);b!==a&&(this.R^=131072,this.g("avoidableMembers",b,a))}},memberParts:{get:function(){return this.Gn.iterator}},layout:{get:function(){return this.Sc},set:function(a){var b=this.Sc;if(b!==a){null!==a&&null!==a.diagram&&a.diagram.layout===a&&C("A layout cannot be both the Diagram.layout and a Group.layout: "+a);null!==b&&(b.diagram=null,b.group=null);this.Sc=a;var c=this.diagram;null!==
a&&(a.diagram=c,a.group=this);null!==c&&(c.xh=!0);this.g("layout",b,a);null!==c&&c.Rb()}}},memberAdded:{get:function(){return null!==this.Ba?this.Ba.Mq:null},set:function(a){var b=this.memberAdded;b!==a&&(np(this),this.Ba.Mq=a,this.g("memberAdded",b,a))}},memberRemoved:{get:function(){return null!==this.Ba?this.Ba.Nq:null},set:function(a){var b=this.memberRemoved;b!==a&&(np(this),this.Ba.Nq=a,this.g("memberRemoved",b,a))}},memberValidation:{
get:function(){return null!==this.Ba?this.Ba.ij:null},set:function(a){var b=this.memberValidation;b!==a&&(np(this),this.Ba.ij=a,this.g("memberValidation",b,a))}},ungroupable:{get:function(){return 0!==(this.R&256)},set:function(a){var b=0!==(this.R&256);b!==a&&(this.R^=256,this.g("ungroupable",b,a))}},isSubGraphExpanded:{get:function(){return 0!==(this.R&512)},set:function(a){var b=0!==(this.R&512);if(b!==a){this.R^=512;var c=
this.diagram;this.g("isSubGraphExpanded",b,a);b=this.subGraphExpandedChanged;if(null!==b){var d=!0;null!==c&&(d=c.aa,c.aa=!0);b(this);null!==c&&(c.aa=d)}null!==c&&c.undoManager.isUndoingRedoing?(null!==this.$a&&this.$a.v(),this.memberParts.each(function(a){a.updateAdornments()})):a?this.expandSubGraph():this.collapseSubGraph()}}},wasSubGraphExpanded:{get:function(){return 0!==(this.R&1024)},set:function(a){var b=0!==(this.R&1024);b!==a&&(this.R^=1024,this.g("wasSubGraphExpanded",
b,a))}},subGraphExpandedChanged:{get:function(){return null!==this.Ba?this.Ba.Kr:null},set:function(a){var b=this.subGraphExpandedChanged;b!==a&&(np(this),this.Ba.Kr=a,this.g("subGraphExpandedChanged",b,a))}},rh:{get:function(){return 0!==(this.R&32768)},set:function(a){0!==(this.R&32768)!==a&&(this.R^=32768)}}});jf.prototype.expandSubGraph=jf.prototype.expandSubGraph;jf.prototype.collapseSubGraph=jf.prototype.collapseSubGraph;
jf.prototype.findSubGraphParts=jf.prototype.Qj;jf.prototype.findContainingGroupChain=jf.prototype.iz;jf.prototype.findExternalNodesConnected=jf.prototype.jz;jf.prototype.findExternalLinksConnected=jf.prototype.Mu;jf.prototype.ensureBounds=jf.prototype.Va;jf.className="Group";function ig(a){Y.call(this);this.zb=jc;this.ko=new N(NaN,NaN,NaN,NaN);a&&Object.assign(this,a)}la(ig,Y);ig.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.zb=this.zb.J();a.ko=this.ko.copy()};
ig.prototype.gh=function(a){if(null===this.background&&null===this.areaBackground)return!1;var b=this.naturalBounds;return Yb(0,0,b.width,b.height,a.x,a.y)};
ig.prototype.Wl=function(){var a=this.part;null!==a&&(a instanceof jf||a instanceof pe)||C("Placeholder is not inside a Group or Adornment.");if(a instanceof jf){var b=this.computeBorder(this.ko),c=this.minSize,d=this.ec;Pb(d,(isFinite(c.width)?Math.max(c.width,b.width):b.width)||0,(isFinite(c.height)?Math.max(c.height,b.height):b.height)||0);this.Ge(0,0,d.width,d.height);d=a.memberParts;for(c=!1;d.next();)if(d.value.isVisible()){c=!0;break}d=a.diagram;!c||null===d||d.animationManager.Yu(a)||isNaN(b.x)||
isNaN(b.y)||(c=I.alloc(),c.xi(b,a.locationSpot),c.D(a.location)||(a.location=c),I.free(c))}else{b=this.ec;c=this.zb;d=c.left+c.right;var e=c.top+c.bottom,f=a.adornedObject;a.angle=f.li();var g=0;f instanceof Bf&&(g=f.strokeWidth);var h=f.Df(),k=f.naturalBounds,l=(k.width+g)*h;g=(k.height+g)*h;a.type!==W.Link&&(h=f.ga("Selection"===a.category?sc:a.locationSpot,I.alloc()),a.location=h,I.free(h));isNaN(l)||isNaN(g)?(a=f.ga(sc,I.alloc()),l=N.allocAt(a.x,a.y,0,0),l.Ye(f.ga(Bc,a)),l.Ye(f.ga(uc,a)),l.Ye(f.ga(yc,
a)),Pb(b,l.width+d||0,l.height+e||0),this.Ge(-c.left,-c.top,b.width,b.height),I.free(a),N.free(l)):(Pb(b,l+d||0,g+e||0),this.Ge(-c.left,-c.top,b.width,b.height))}};ig.prototype.eh=function(a,b,c,d){this.actualBounds.h(a,b,c,d)};
ig.prototype.computeBorder=function(a){var b=this.part,c=b.diagram,d=null===c||!c.animationManager.isAnimating;if(null!==c&&b instanceof jf&&!b.layer.isTemporary&&b.computesBoundsAfterDrag&&this.ko.w()){var e=c.toolManager.findTool("Dragging");if(e===c.currentTool&&(c=e.computeBorder(b,this.ko,a),null!==c))return c}c=N.alloc();e=this.computeMemberBounds(c);var f=this.zb;b instanceof jf&&!b.isSubGraphExpanded?a.h(e.x-f.left,e.y-f.top,0,0):a.h(e.x-f.left,e.y-f.top,Math.max(e.width+f.left+f.right,0),
Math.max(e.height+f.top+f.bottom,0));N.free(c);d&&b instanceof jf&&b.computesBoundsIncludingLocation&&b.location.w()&&a.Ye(b.location);return a};
ig.prototype.computeMemberBounds=function(a){if(!(this.part instanceof jf))return a.h(0,0,0,0),a;for(var b=this.part,c=Infinity,d=Infinity,e=-Infinity,f=-Infinity,g=b.memberParts;g.next();){var h=g.value;if(h.isVisible()){if(h instanceof T){if(!b.computesBoundsIncludingLinks)continue;if(zj(h))continue;if(h.fromNode===b||h.toNode===b)continue}h=h.actualBounds;h.left<c&&(c=h.left);h.top<d&&(d=h.top);h.right>e&&(e=h.right);h.bottom>f&&(f=h.bottom)}}isFinite(c)&&isFinite(d)?a.h(c,d,e-c,f-d):(b=b.location,
a.h(b.x,b.y,0,0));return a};ma.Object.defineProperties(ig.prototype,{padding:{get:function(){return this.zb},set:function(a){"number"===typeof a&&(a=new cc(a));var b=this.zb;b.D(a)||(this.zb=a=a.J(),this.g("padding",b,a),this.v())}}});ig.className="Placeholder";
function T(a){U.call(this,W.Link);this.Qa=8;this.ef=null;this.ff="";this.uf=this.gn=null;this.vf="";this.uo=null;this.pk=Zf;this.Om=0;this.Qm=Zf;this.Rm=NaN;this.qj=Ep;this.po=.5;this.wd=null;this.Ob=(new F).freeze();this.Tg=this.Mg=null;this.ml=new N;this.ua=new P;this.u=this.o=this.Sf=this.Wf=null;this.su=new I;this.cq=this.Nw=this.Mw=null;this.vt=NaN;this.ba=null;this.j=[];a&&Object.assign(this,a)}la(T,U);
T.prototype.cloneProtected=function(a){U.prototype.cloneProtected.call(this,a);a.Qa=this.Qa&-113;a.ff=this.ff;a.gn=this.gn;a.vf=this.vf;a.uo=this.uo;a.pk=this.pk;a.Om=this.Om;a.Qm=this.Qm;a.Rm=this.Rm;a.qj=this.qj;a.po=this.po;null!==this.ba&&(a.ba=this.ba.copy())};t=T.prototype;t.Af=function(a){U.prototype.Af.call(this,a);this.ff=a.ff;this.vf=a.vf;a.Mg=null;a.bb();a.Sf=this.Sf;var b=a.fromPort;null!==b&&qp(b);a=a.toPort;null!==a&&qp(a)};
t.qb=function(a){a.classType===T?2===(a.value&2)?this.routing=a:a===bg||a===Yf||a===Xf?this.curve=a:a===Fp||a===Gp||a===Hp?this.adjusting=a:a!==Ep&&a!==Zf&&C("Unknown Link enum value for a Link property: "+a):U.prototype.qb.call(this,a)};t.Xc=function(){null===this.ba&&(this.ba=new Zk)};t.si=function(){var a=this.fromNode;if(null!==a){var b=a.findVisibleNode();null!==b&&(a=b);if(Aj(a)||Bj(a))return!1}a=this.toNode;return null!==a&&(b=a.findVisibleNode(),null!==b&&(a=b),Aj(a)||Bj(a))?!1:!0};t.Av=function(){return!1};
t.Bv=function(){};t.Zc=function(){return!1};T.prototype.computeAngle=function(a,b,c){return T.computeAngle(b,c)};T.computeAngle=function(a,b){switch(a){default:case Zf:a=0;break;case on:a=b;break;case fn:a=b+90;break;case hn:a=b-90;break;case Ip:a=b+180;break;case pn:a=K.bp(b);90<a&&270>a&&(a-=180);break;case gn:a=K.bp(b+90);90<a&&270>a&&(a-=180);break;case jn:a=K.bp(b-90);90<a&&270>a&&(a-=180);break;case kn:a=K.bp(b);if(45<a&&135>a||225<a&&315>a)return 0;90<a&&270>a&&(a-=180)}return K.bp(a)};
function kp(a){var b=a.fromNode,c=a.toNode,d=null;null!==b?d=null!==c?b.qx(c):b.containingGroup:null!==c?d=c.containingGroup:d=null;b=d;c=a.Ah;if(c!==b){null!==c&&fp(c,a);a.Ah=b;null!==b&&ep(b,a);var e=a.containingGroupChanged;if(null!==e){var f=!0,g=a.diagram;null!==g&&(f=g.aa,g.aa=!0);e(a,c,b);null!==g&&(g.aa=f)}!a.fd||a.Mw!==c&&a.Nw!==c||a.bb()}if(a.isLabeledLink)for(a=a.labelNodes;a.next();)a.value.containingGroup=d}t=T.prototype;
t.oh=function(){var a=this.containingGroup;null!==a&&this.fromNode!==a&&this.toNode!==a&&a.computesBoundsIncludingLinks&&U.prototype.oh.call(this)};t.To=function(a){var b=this.fromNode;return a===b?this.toNode:b};t.Dz=function(a){var b=this.fromPort;return a===b?this.toPort:b};function up(a,b){null===a.wd&&(a.wd=new H);a.wd.add(b);a.v()}
t.Vo=function(a){U.prototype.Vo.call(this,a);Jp(this)&&this.Xo(this.actualBounds);if(!a){a=this.ef;var b=null;null!==a&&(b=this.fromPort,sp(a,this,b));var c=this.uf;if(null!==c){var d=this.toPort;c===a&&d===b||sp(c,this,d)}Kp(this)}};t.Wo=function(a){U.prototype.Wo.call(this,a);Jp(this)&&this.Xo(this.actualBounds);if(!a){a=this.ef;var b=null;null!==a&&(b=this.fromPort,tp(a,this,b));var c=this.uf;if(null!==c){var d=this.toPort;c===a&&d===b||tp(c,this,d)}Lp(this)}};
t.Kj=function(){this.fd=!0;if(null!==this.wd){var a=this.diagram;if(null!==a)for(var b=this.wd.copy().iterator;b.next();)a.remove(b.value)}null!==this.data&&(a=this.diagram,null!==a&&a.partManager.removeDataForLink(this))};T.prototype.updateRelationshipsFromData=function(){if(null!==this.data){var a=this.diagram;null!==a&&a.partManager.updateRelationshipsFromData(this)}};
T.prototype.move=function(a,b){var c=b?this.location:this.position,d=c.x;isNaN(d)&&(d=0);var e=c.y;isNaN(e)&&(e=0);d=a.x-d;e=a.y-e;!0===b?U.prototype.move.call(this,a,!1):(a=I.allocAt(c.x+d,c.y+e),U.prototype.move.call(this,a,!1),I.free(a));df(this,d,e);for(a=this.labelNodes;a.next();)b=a.value,c=b.position,b.moveTo(c.x+d,c.y+e)};
T.prototype.canRelinkFrom=function(){if(!this.relinkableFrom)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowRelink)return!1;a=a.diagram;return null===a||a.allowRelink?!0:!1};T.prototype.canRelinkTo=function(){if(!this.relinkableTo)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowRelink)return!1;a=a.diagram;return null===a||a.allowRelink?!0:!1};
T.prototype.computeMidPoint=function(a){var b=this.pointsCount;if(0===b)return a.assign(tb),a;if(1===b)return a.assign(this.i(0)),a;if(2===b){var c=this.i(0),d=this.i(1);a.h((c.x+d.x)/2,(c.y+d.y)/2);return a}if(this.isOrthogonal&&(15<=this.computeCorner()||this.computeCurve()===bg))return this.ua.Qu(.5,a),a.add(this.i(0)),c=this.ua.figures.first(),a.offset(-c.startX,-c.startY),a;if(this.computeCurve()===bg){if(3===b){c=this.i(0);d=this.i(1);var e=this.i(2);K.bx(c.x,c.y,d.x,d.y,d.x,d.y,e.x,e.y,a);
return a}d=(b-1)/3|0;c=3*(d/2|0);if(1===d%2){d=this.i(c);e=this.i(c+1);var f=this.i(c+2);c=this.i(c+3);K.bx(d.x,d.y,e.x,e.y,f.x,f.y,c.x,c.y,a)}else a.assign(this.i(c));return a}var g=this.flattenedLengths;c=this.flattenedTotalLength;for(e=f=d=0;d<c/2&&f<b;){e=g[f];if(d+e>c/2)break;d+=e;f++}b=this.i(f);f=this.i(f+1);1>Math.abs(b.x-f.x)?b.y>f.y?a.h(b.x,b.y-(c/2-d)):a.h(b.x,b.y+(c/2-d)):1>Math.abs(b.y-f.y)?b.x>f.x?a.h(b.x-(c/2-d),b.y):a.h(b.x+(c/2-d),b.y):(c=(c/2-d)/e,a.h(b.x+c*(f.x-b.x),b.y+c*(f.y-
b.y)));return a};
T.prototype.computeMidAngle=function(){var a=this.pointsCount;if(2>a)return NaN;if(2===a)return this.i(0).ab(this.i(1));if(this.isOrthogonal&&(15<=this.computeCorner()||this.computeCurve()===bg))return this.ua.xx(.5);if(this.computeCurve()===bg&&4<=a){var b=(a-1)/3|0,c=3*(b/2|0);if(1===b%2){c=Math.floor(c);a=this.i(c);b=this.i(c+1);var d=this.i(c+2);c=this.i(c+3);return K.Ny(a.x,a.y,b.x,b.y,d.x,d.y,c.x,c.y)}if(0<c&&c+1<a)return this.i(c-1).ab(this.i(c+1))}b=this.flattenedLengths;d=this.flattenedTotalLength;
var e=0;c=0;for(var f;e<d/2&&c<a;){f=b[c];if(e+f>d/2)break;e+=f;c++}b=this.i(c);d=this.i(c+1);if(1>Math.abs(b.x-d.x)&&1>Math.abs(b.y-d.y)){if(0<c&&c+2<a)return this.i(c-1).ab(this.i(c+2))}else{if(1>Math.abs(b.x-d.x))return b.y>d.y?270:90;if(1>Math.abs(b.y-d.y))return b.x>d.x?180:0}return b.ab(d)};t=T.prototype;t.i=function(a){return this.Ob.s[a]};t.dd=function(a,b){this.Ob.cd(a,b)};t.L=function(a,b,c){this.Ob.cd(a,new I(b,c))};t.Lz=function(a,b){this.Ob.Hb(a,b)};
t.m=function(a,b,c){this.Ob.Hb(a,new I(b,c))};t.ne=function(a){this.Ob.add(a)};t.yf=function(a,b){this.Ob.add(new I(a,b))};t.qv=function(a){this.Ob.mb(a)};t.Ej=function(){this.Ob.clear()};
function df(a,b,c){if(0!==b||0!==c)if(0===a.pointsCount)a.defaultFromPoint&&a.defaultFromPoint.offset(b,c),a.defaultToPoint&&a.defaultToPoint.offset(b,c);else{for(var d=a.fd,e=new F,f=a.Ob.iterator;f.next();){var g=f.value;e.add((new I(g.x+b,g.y+c)).freeze())}e.freeze();f=a.Ob;a.Ob=e;g=a.diagram;isNaN(b)||isNaN(c)||null!==g&&g.animationManager.Ab?a.v():(a.ke.h(a.ke.x+b,a.ke.y+c),a.pa.h(a.pa.x+b,a.pa.y+c),Sl(a));d?Mp(a):(a.defaultFromPoint&&(a.defaultFromPoint=a.i(0)),a.defaultToPoint&&(a.defaultToPoint=
a.i(a.pointsCount-1)));null!==g&&g.animationManager.Ab&&Bh(g.animationManager,a,e);a.g("points",f,e)}}t.uh=function(){null===this.Mg&&(this.Mg=this.Ob,this.Ob=this.Ob.copy())};
t.zf=function(){if(null!==this.Mg){for(var a=this.Mg,b=this.Ob,c=Infinity,d=Infinity,e=a.s,f=e.length,g=0;g<f;g++){var h=e[g];c=Math.min(h.x,c);d=Math.min(h.y,d)}h=g=Infinity;for(var k=b.s,l=k.length,m=0;m<l;m++){var n=k[m];g=Math.min(n.x,g);h=Math.min(n.y,h);n.freeze()}b.freeze();if(l===f)for(f=0;f<l;f++){if(m=e[f],n=k[f],m.x-c!==n.x-g||m.y-d!==n.y-h){this.rc();break}}else this.rc();this.Mg=null;c=this.diagram;null!==c&&c.animationManager.Ab&&Bh(c.animationManager,this,b);Mp(this);this.g("points",
a,b)}};t.sv=function(){null!==this.Mg&&(this.Ob=this.Mg,this.Mg=null)};function Mp(a){0===a.Ob.count?a.fd=!1:(a.fd=!0,a.cq=null,a.vt=NaN,a.defaultFromPoint=a.i(0),a.defaultToPoint=a.i(a.pointsCount-1),Np(a,!1))}t.bb=function(){if(!this.suspendsRouting){var a=this.diagram;if(a){if(a.As.contains(this)||a.undoManager.isUndoingRedoing)return;a=a.animationManager;if(a.isTicking&&!a.isAnimating)return;null===this.Tg||a.isTicking||(this.Tg=null)}a=this.path;null!==a&&(this.fd=!1,this.v(),a.v())}};
t.vg=function(){if(!this.fd&&!this.computingPoints){var a=!0;try{this.computingPoints=!0,this.uh(),a=this.computePoints()}catch(b){this.computingPoints=!1,this.sv()}finally{this.computingPoints=!1,a?this.zf():this.sv()}}};
T.prototype.computePoints=function(){if(null===this.diagram)return!1;var a=this.fromNode,b=null;null===a?(Op||(Op=new V,Op.desiredSize=Cb,Op.Va()),this.defaultFromPoint&&(Op.location=this.defaultFromPoint,Op.Va(),b=a=Op)):b=this.fromPort;if(null!==b&&!a.isVisible()){var c=a.findVisibleNode();null!==c&&c!==a?(a=c,b=c.port):a=c}this.Mw=a;if(null===a||!a.location.w())return!1;for(;!(null===b||b.actualBounds.w()&&b.Ed());)b=b.panel;if(null===b)return!1;var d=this.toNode,e=null;null===d?(Pp||(Pp=new V,
Pp.desiredSize=Cb,Pp.Va()),this.defaultToPoint&&(Pp.location=this.defaultToPoint,Pp.Va(),e=d=Pp)):e=this.toPort;null===e||d.isVisible()||(c=d.findVisibleNode(),null!==c&&c!==d?(d=c,e=c.port):d=c);this.Nw=d;if(null===d||!d.location.w())return!1;for(;!(null===e||e.actualBounds.w()&&e.Ed());)e=e.panel;if(null===e)return!1;var f=this.pointsCount,g=this.computeSpot(!0,b);c=this.computeSpot(!1,e);var h=Qp(g),k=Qp(c),l=b===e&&null!==b,m=this.isOrthogonal,n=this.curve===bg;this.Wf=l&&!m?n=!0:!1;var p=this.computeAdjusting()===
Zf||l;if(!m&&!l&&h&&k){if(h=!1,!p&&3<=f&&(p=this.getLinkPoint(a,b,g,!0,!1,d,e),k=this.getLinkPoint(d,e,c,!1,!1,a,b),h=this.adjustPoints(0,p,f-1,k))&&(p=this.getLinkPoint(a,b,g,!0,!1,d,e),k=this.getLinkPoint(d,e,c,!1,!1,a,b),this.adjustPoints(0,p,f-1,k)),!h)if(this.Ej(),n){f=this.getLinkPoint(a,b,g,!0,!1,d,e);p=this.getLinkPoint(d,e,c,!1,!1,a,b);h=p.x-f.x;k=p.y-f.y;l=this.computeCurviness();n=m=0;var q=f.x+h/3,r=f.y+k/3,u=q,w=r;K.B(k,0)?w=0<h?w-l:w+l:(m=-h/k,n=Math.sqrt(l*l/(m*m+1)),0>l&&(n=-n),u=
(0>k?-1:1)*n+q,w=m*(u-q)+r);q=f.x+2*h/3;r=f.y+2*k/3;var v=q,y=r;K.B(k,0)?y=0<h?y-l:y+l:(v=(0>k?-1:1)*n+q,y=m*(v-q)+r);this.Ej();this.ne(f);this.yf(u,w);this.yf(v,y);this.ne(p);this.dd(0,this.getLinkPoint(a,b,g,!0,!1,d,e));this.dd(3,this.getLinkPoint(d,e,c,!1,!1,a,b))}else if(f=d,p=e,d=this.getLinkPoint(a,b,g,!0,!1,f,p),e=this.getLinkPoint(f,p,c,!1,!1,a,b),this.hasCurviness())p=e.x-d.x,c=e.y-d.y,g=this.computeCurviness(),a=d.x+p/2,b=d.y+c/2,f=a,h=b,K.B(c,0)?h=0<p?h-g:h+g:(p=-p/c,f=Math.sqrt(g*g/(p*
p+1)),0>g&&(f=-f),f=(0>c?-1:1)*f+a,h=p*(f-a)+b),this.ne(d),this.yf(f,h),this.ne(e);else{this.ne(d);this.ne(e);h=N.alloc();b.mi(h);k=h.ca(e);p.mi(h);l=h.ca(d);if(k||l)g=b.ga(Rp(g),new I),this.dd(0,this.getLinkPointFromPoint(a,b,g,e,!0,g)),c=p.ga(Rp(c),new I),this.dd(1,this.getLinkPointFromPoint(f,p,c,d,!1,c));N.free(h)}}else{n=this.isAvoiding;p&&(m&&n||l)&&this.Ej();var z=l?this.computeCurviness():0;n=this.getLinkPoint(a,b,g,!0,m,d,e);q=u=r=0;if(m||!h||l)w=this.computeEndSegmentLength(a,b,g,!0),q=
this.getLinkDirection(a,b,n,g,!0,m,d,e),l&&(h||g.D(c)||!m&&1===g.x+c.x&&1===g.y+c.y)&&(q-=m?90:30,0>z&&(q-=180)),0>q?q+=360:360<=q&&(q-=360),l&&(w+=Math.abs(z)*(m?1:2)),0===q?r=w:90===q?u=w:180===q?r=-w:270===q?u=-w:(r=w*Math.cos(q*Math.PI/180),u=w*Math.sin(q*Math.PI/180)),g.Mc()&&l&&(w=b.ga(wc,I.alloc()),v=I.allocAt(w.x+1E3*r,w.y+1E3*u),this.getLinkPointFromPoint(a,b,w,v,!0,n),I.free(w),I.free(v));w=this.getLinkPoint(d,e,c,!1,m,a,b);var A=y=v=0;if(m||!k||l){var B=this.computeEndSegmentLength(d,e,
c,!1);A=this.getLinkDirection(d,e,w,c,!1,m,a,b);l&&(k||g.D(c)||!m&&1===g.x+c.x&&1===g.y+c.y)&&(A+=m?0:30,0>z&&(A+=180));0>A?A+=360:360<=A&&(A-=360);l&&(B+=Math.abs(z)*(m?1:2));0===A?v=B:90===A?y=B:180===A?v=-B:270===A?y=-B:(v=B*Math.cos(A*Math.PI/180),y=B*Math.sin(A*Math.PI/180));c.Mc()&&l&&(c=e.ga(wc,I.alloc()),g=I.allocAt(c.x+1E3*v,c.y+1E3*y),this.getLinkPointFromPoint(d,e,c,g,!1,w),I.free(c),I.free(g))}c=n;if(m||!h||l)c=new I(n.x+r,n.y+u);g=w;if(m||!k||l)g=new I(w.x+v,w.y+y);!p&&!m&&h&&3<f&&this.adjustPoints(0,
n,f-2,g)?this.dd(f-1,w):!p&&!m&&k&&3<f&&this.adjustPoints(1,c,f-1,w)?this.dd(0,n):!p&&(m?6<=f:4<f)&&this.adjustPoints(1,c,f-2,g)?(this.dd(0,n),this.dd(f-1,w)):(this.Ej(),this.ne(n),(m||!h||l)&&this.ne(c),m&&this.addOrthoPoints(c,q,g,A,a,d),(m||!k||l)&&this.ne(g),this.ne(w))}return!0};function Sp(a,b){Math.abs(b.x-a.x)>Math.abs(b.y-a.y)?(b.x>=a.x?b.x=a.x+9E9:b.x=a.x-9E9,b.y=a.y):(b.y>=a.y?b.y=a.y+9E9:b.y=a.y-9E9,b.x=a.x);return b}
T.prototype.getLinkPointFromPoint=function(a,b,c,d,e,f){void 0===f&&(f=new I);if(null===a||null===b)return f.assign(c),f;a.isVisible()||(e=a.findVisibleNode(),null!==e&&e!==a&&(b=e.port));a=null;e=b.panel;null===e||e.se()||(e=e.panel);if(null===e){e=d.x;d=d.y;var g=c.x;c=c.y}else{a=e.oe;e=1/(a.m11*a.m22-a.m12*a.m21);g=a.m22*e;var h=-a.m12*e,k=-a.m21*e,l=a.m11*e,m=e*(a.m21*a.dy-a.m22*a.dx),n=e*(a.m12*a.dx-a.m11*a.dy);e=d.x*g+d.y*k+m;d=d.x*h+d.y*l+n;g=c.x*g+c.y*k+m;c=c.x*h+c.y*l+n}b.Rj(e,d,g,c,f);null!==
a&&f.transform(a);return f};function Tp(a,b){var c=b.Zn;null===c&&(c=new Up,c.port=b,c.node=b.part,b.Zn=c);return Vp(c,a)}
T.prototype.getLinkPoint=function(a,b,c,d,e,f,g,h){void 0===h&&(h=new I);if(c.sb()&&!Qp(c))return b.ga(c,h),h;if(c.$c()){var k=Tp(this,b);if(null!==k){h.assign(k.Zo);if(e&&this.routing===Wp){var l=Tp(this,g);if(null!==l&&k.Gl<l.Gl){k=I.alloc();l=I.alloc();var m=new N(b.ga(sc,k),b.ga(Bc,l)),n=this.computeSpot(!d,g);a=this.getLinkPoint(f,g,n,!d,e,a,b,l);(c.Hf(Dc)||c.Hf(Ec))&&a.y>=m.y&&a.y<=m.y+m.height?h.y=a.y:(c.Hf(Cc)||c.Hf(Fc))&&a.x>=m.x&&a.x<=m.x+m.width&&(h.x=a.x);I.free(k);I.free(l)}}return h}}c=
b.ga(Rp(c),I.alloc());this.pointsCount>(e?6:2)?(g=d?this.i(1):this.i(this.pointsCount-2),e&&(g=Sp(c,g.copy()))):(k=this.computeSpot(!d,g),f=I.alloc(),g=g.ga(Rp(k),f),e&&(g=Sp(c,g)),I.free(f));this.getLinkPointFromPoint(a,b,c,g,d,h);I.free(c);return h};
T.prototype.getLinkDirection=function(a,b,c,d,e,f,g,h){a:if(d.sb())var k=d.x>d.y?d.x>1-d.y?0:d.x<1-d.y?270:315:d.x<d.y?d.x>1-d.y?90:d.x<1-d.y?180:135:.5>d.x?225:.5<d.x?45:0;else{if(d.$c()&&(k=Tp(this,b),null!==k))switch(k.Nc){case 1:k=270;break a;case 2:k=180;break a;default:case 4:k=0;break a;case 8:k=90;break a}k=b.ga(wc,I.alloc());this.pointsCount>(f?6:2)?(h=e?this.i(1):this.i(this.pointsCount-2),h=f?Sp(k,h.copy()):c):(c=I.alloc(),h=h.ga(wc,c),I.free(c));c=Math.abs(h.x-k.x)>Math.abs(h.y-k.y)?h.x>=
k.x?0:180:h.y>=k.y?90:270;I.free(k);k=c}g&&d.Mc()&&g.Td(a)&&(k+=180,360<=k&&(k-=360));if(Qp(d))return k;a=b.li();if(0===a)return k;45<=a&&135>a?k+=90:135<=a&&225>a?k+=180:225<=a&&315>a&&(k+=270);360<=k&&(k-=360);return k};T.prototype.computeEndSegmentLength=function(a,b,c,d){if(null!==b&&c.$c()&&(a=Tp(this,b),null!==a))return a.Ku;a=d?this.fromEndSegmentLength:this.toEndSegmentLength;null!==b&&isNaN(a)&&(a=d?b.fromEndSegmentLength:b.toEndSegmentLength);isNaN(a)&&(a=10);return a};
T.prototype.computeSpot=function(a,b){void 0===b&&(b=null);a?(a=b?b:this.fromPort,null===a?a=wc:(b=this.fromSpot,b.Zb()&&(b=a.fromSpot),a=b===Sc?lc:b)):(a=b?b:this.toPort,null===a?a=wc:(b=this.toSpot,b.Zb()&&(b=a.toSpot),a=b===Sc?lc:b));return a};function Qp(a){return a===lc||.5===a.x&&.5===a.y}function Rp(a){return.5===a.x&&.5===a.y?a:wc}
T.prototype.computeOtherPoint=function(a,b){if(this.computeAdjusting()!==Zf&&4<this.pointsCount)return this.computeMidPoint(new I);a=b.Zn;a=null!==a?Vp(a,this):null;return null!==a?a.Zo:b.ga(wc)};T.prototype.computeShortLength=function(a){if(a){a=this.fromShortLength;if(isNaN(a)){var b=this.fromPort;null!==b&&(a=b.fromShortLength)}return isNaN(a)?0:a}a=this.toShortLength;isNaN(a)&&(b=this.toPort,null!==b&&(a=b.toShortLength));return isNaN(a)?0:a};
T.prototype.Cf=function(a,b,c,d,e,f){if(!1===this.pickable)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var g=f;void 0===f&&(g=ld.alloc(),g.reset());g.multiply(this.transform);if(this.fh(a,g))return An(this,b,c,e),void 0===f&&ld.free(g),!0;if(this.Yc(a,g)){var h=!1;if(!this.isAtomic)for(var k=this.O.s,l=k.length;l--;){var m=k[l];if(m.visible||m===this.locationObject){var n=m.actualBounds,p=this.naturalBounds;if(!(n.x>p.width||n.y>p.height||0>n.x+n.width||0>n.y+n.height)){n=ld.alloc();n.set(g);
if(m instanceof W)h=m.Cf(a,b,c,d,e,n);else if(this.path===m){if(m instanceof Bf)if(h=a,p=d,!1===m.pickable)h=!1;else if(n.multiply(m.transform),p)b:{var q=h,r=n;if(m.fh(q,r))h=!0;else{if(void 0===r&&(r=m.transform,q.Re(m.actualBounds))){h=!0;break b}h=q.left;p=q.right;var u=q.top;q=q.bottom;var w=I.alloc(),v=I.alloc(),y=I.alloc(),z=ld.alloc();z.set(r);z.cv(m.transform);z.Uj();v.x=p;v.y=u;v.transform(z);w.x=h;w.y=u;w.transform(z);r=!1;Yn(m,w,v,y)?r=!0:(w.x=p,w.y=q,w.transform(z),Yn(m,w,v,y)?r=!0:(v.x=
h,v.y=q,v.transform(z),Yn(m,w,v,y)?r=!0:(w.x=h,w.y=u,w.transform(z),Yn(m,w,v,y)&&(r=!0))));ld.free(z);I.free(w);I.free(v);I.free(y);h=r}}else h=m.fh(h,n)}else h=cl(m,a,d,n);h&&(p=m,null!==b&&(p=b(m)),p&&(null===c||c(p))&&e.add(p));ld.free(n)}}}void 0===f&&ld.free(g);return h||null!==this.background||null!==this.areaBackground}void 0===f&&ld.free(g);return!1};
T.prototype.computeCurve=function(){if(null===this.Wf){var a=this.fromPort,b=this.isOrthogonal;this.Wf=null!==a&&a===this.toPort&&!b}return this.Wf?bg:this.curve};T.prototype.computeCorner=function(){if(this.curve===bg)return 0;var a=this.corner;if(isNaN(a)||0>a)a=10;return a};
T.prototype.findMidLabel=function(){for(var a=this.path,b=this.O.s,c=b.length,d=0;d<c;d++){var e=b[d];if(e!==a&&!e.isPanelMain&&(-Infinity===e.segmentIndex||isNaN(e.segmentIndex)))return e}for(a=this.labelNodes;a.next();)if(b=a.value,-Infinity===b.segmentIndex||isNaN(b.segmentIndex))return b;return null};
T.prototype.computeSpacing=function(){if(!this.isVisible())return 0;var a=Xp;a=Math.max(a,this.computeThickness());var b=this.fromPort,c=this.toPort;if(null!==b&&null!==c){var d=this.findMidLabel();if(null!==d){var e=d.naturalBounds,f=d.margin,g=isNaN(e.width)?30:e.width*d.scale+f.left+f.right;e=isNaN(e.height)?14:e.height*d.scale+f.top+f.bottom;d=d.segmentOrientation;d===on||d===pn||d===Ip?a=Math.max(a,e):d===hn||d===jn||d===fn||d===gn?a=Math.max(a,g):(b=b.ga(wc).ab(c.ga(wc))/180*Math.PI,a=Math.max(a,
Math.abs(Math.sin(b)*g)+Math.abs(Math.cos(b)*e)+1));this.curve===bg&&(a*=1.333)}}return a};T.prototype.arrangeBundledLinks=function(a,b){if(b)for(b=0;b<a.length;b++){var c=a[b];c.computeAdjusting()===Zf&&c.bb()}};
T.prototype.computeCurviness=function(){var a=this.curviness;if(isNaN(a)){a=Yp;var b=this.Sf;if(null!==b){for(var c=Ea(),d=0,e=b.links,f=0;f<e.length;f++){var g=e[f].computeSpacing();c.push(g);d+=g}d=-d/2;for(f=0;f<e.length;f++){if(e[f]===this){a=d+c[f]/2;break}d+=c[f]}b.Ds===this.fromNode&&(a=-a);Ga(c)}}return a};T.prototype.computeThickness=function(){if(!this.isVisible())return 0;var a=this.path;return null!==a?Math.max(a.strokeWidth,1):1};
T.prototype.hasCurviness=function(){return!isNaN(this.curviness)||null!==this.Sf};
T.prototype.adjustPoints=function(a,b,c,d){var e=this.computeAdjusting();if(this.isOrthogonal){if(e===Gp)return!1;e===Hp&&(e=Fp)}switch(e){case Gp:var f=this.i(a),g=this.i(c);if(!f.Xa(b)||!g.Xa(d)){e=f.x;f=f.y;var h=g.x-e,k=g.y-f,l=Math.sqrt(h*h+k*k);if(!K.$(l,0)){if(K.$(h,0))var m=0>k?-Math.PI/2:Math.PI/2;else m=Math.atan(k/Math.abs(h)),0>h&&(m=Math.PI-m);g=b.x;var n=b.y;h=d.x-g;var p=d.y-n;k=Math.sqrt(h*h+p*p);K.$(h,0)?p=0>p?-Math.PI/2:Math.PI/2:(p=Math.atan(p/Math.abs(h)),0>h&&(p=Math.PI-p));l=
k/l;m=p-m;this.dd(a,b);for(a+=1;a<c;a++)b=this.i(a),h=b.x-e,k=b.y-f,b=Math.sqrt(h*h+k*k),K.$(b,0)||(K.$(h,0)?k=0>k?-Math.PI/2:Math.PI/2:(k=Math.atan(k/Math.abs(h)),0>h&&(k=Math.PI-k)),h=k+m,b*=l,this.L(a,g+b*Math.cos(h),n+b*Math.sin(h)));this.dd(c,d)}}return!0;case Hp:f=this.i(a);n=this.i(c);if(!f.Xa(b)||!n.Xa(d)){e=f.x;f=f.y;g=n.x;n=n.y;l=(g-e)*(g-e)+(n-f)*(n-f);h=b.x;m=b.y;k=d.x;p=d.y;var q=1;if(0!==k-h){var r=(p-m)/(k-h);q=Math.sqrt(1+1/(r*r))}else r=9E9;this.dd(a,b);for(a+=1;a<c;a++){b=this.i(a);
var u=b.x,w=b.y,v=.5;0!==l&&(v=((e-u)*(e-g)+(f-w)*(f-n))/l);var y=e+v*(g-e),z=f+v*(n-f);b=Math.sqrt((u-y)*(u-y)+(w-z)*(w-z));w<r*(u-y)+z&&(b=-b);0<r&&(b=-b);u=h+v*(k-h);v=m+v*(p-m);0!==r?(b=u+b/q,this.L(a,b,v-(b-u)/r)):this.L(a,u,v+b)}this.dd(c,d)}return!0;case Fp:a:{if(this.isOrthogonal&&(e=this.i(a),f=this.i(a+1),g=this.i(a+2),h=f.x,m=f.y,n=h,l=m,K.B(e.y,f.y)?K.B(f.x,g.x)?m=b.y:K.B(f.y,g.y)&&(h=b.x):K.B(e.x,f.x)&&(K.B(f.y,g.y)?h=b.x:K.B(f.x,g.x)&&(m=b.y)),this.L(a+1,h,m),e=this.i(c),f=this.i(c-
1),g=this.i(c-2),h=f.x,m=f.y,k=h,p=m,K.B(e.y,f.y)?K.B(f.x,g.x)?m=d.y:K.B(f.y,g.y)&&(h=d.x):K.B(e.x,f.x)&&(K.B(f.y,g.y)?h=d.x:K.B(f.x,g.x)&&(m=d.y)),this.L(c-1,h,m),Xj(this))){this.L(a+1,n,l);this.L(c-1,k,p);c=!1;break a}this.dd(a,b);this.dd(c,d);c=!0}return c;default:return!1}};
T.prototype.addOrthoPoints=function(a,b,c,d,e,f){0>b?b+=360:360<=b&&(b-=360);0>d?d+=360:360<=d&&(d-=360);var g=d;b=315<=b||45>b?0:45<=b&&135>b?90:135<=b&&225>b?180:270;d=315<=d||45>d?0:45<=d&&135>d?90:135<=d&&225>d?180:270;var h=e.actualBounds.copy(),k=f.actualBounds.copy();if(h.w()&&k.w()){h.Bc(Zp,Zp);k.Bc(Zp,Zp);h.Ye(a);k.Ye(c);var l=I.alloc(),m=I.alloc();if(0===b)if(c.x>a.x||270===d&&c.y<a.y&&k.right>a.x||90===d&&c.y>a.y&&k.right>a.x)l.h(c.x,a.y),m.h(c.x,(a.y+c.y)/2),180===d?(l.x=this.computeMidOrthoPosition(a.x,
a.y,c.x,c.y,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x<k.left?this.computeMidOrthoPosition(a.x,a.y,k.left,c.y,!1):a.x<k.right&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1):k.right,m.x=l.x,m.y=c.y):0===d&&a.x<k.left&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l.h(a.x,c.y);m.h((a.x+c.x)/2,c.y);if(180===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)180===d&&(k.ca(a)||h.ca(c))?
l.y=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):c.y<a.y&&(180===d||90===d)?l.y=this.computeMidOrthoPosition(a.x,h.top,c.x,Math.max(c.y,k.bottom),!0):c.y>a.y&&(180===d||270===d)&&(l.y=this.computeMidOrthoPosition(a.x,h.bottom,c.x,Math.min(c.y,k.top),!0)),m.x=c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x>=h.left&&c.x<=a.x||a.x<=k.right&&a.x>=c.x){if(90===d||270===d)l.h(Math.max((a.x+c.x)/2,a.x),a.y),m.h(l.x,c.y)}else l.y=270===d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,0===d?h.top:Math.min(h.top,
k.top)):Math.max(c.y,0===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(180===b)if(c.x<a.x||270===d&&c.y<a.y&&k.left<a.x||90===d&&c.y>a.y&&k.left<a.x)l.h(c.x,a.y),m.h(c.x,(a.y+c.y)/2),0===d?(l.x=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x>k.right?this.computeMidOrthoPosition(a.x,a.y,k.right,c.y,!1):a.x>k.left&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1):k.left,m.x=l.x,
m.y=c.y):180===d&&a.x>k.right&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l.h(a.x,c.y);m.h((a.x+c.x)/2,c.y);if(0===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)0===d&&(k.ca(a)||h.ca(c))?l.y=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):c.y<a.y&&(0===d||90===d)?l.y=this.computeMidOrthoPosition(a.x,h.top,c.x,Math.max(c.y,k.bottom),!0):c.y>a.y&&(0===d||270===d)&&(l.y=this.computeMidOrthoPosition(a.x,h.bottom,c.x,Math.min(c.y,k.top),!0)),m.x=
c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x<=h.right&&c.x>=a.x||a.x>=k.left&&a.x<=c.x){if(90===d||270===d)l.h(Math.min((a.x+c.x)/2,a.x),a.y),m.h(l.x,c.y)}else l.y=270===d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,180===d?h.top:Math.min(h.top,k.top)):Math.max(c.y,180===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(90===b)if(c.y>a.y||180===d&&c.x<a.x&&k.bottom>a.y||0===d&&c.x>a.x&&k.bottom>a.y)l.h(a.x,c.y),m.h((a.x+c.x)/2,c.y),270===d?(l.y=this.computeMidOrthoPosition(a.x,a.y,c.x,
c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>a.x?(l.y=a.y<k.top?this.computeMidOrthoPosition(a.x,a.y,c.x,k.top,!0):a.y<k.bottom&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):k.bottom,m.x=c.x,m.y=l.y):90===d&&a.y<k.top&&a.x>k.left&&a.x<k.right&&(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l.h(c.x,a.y);m.h(c.x,(a.y+c.y)/2);if(270===d||0===d&&c.x<h.left||180===d&&c.x>h.right)270===d&&(k.ca(a)||h.ca(c))?l.x=this.computeMidOrthoPosition(a.x,
a.y,c.x,c.y,!1):c.x<a.x&&(270===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,a.y,Math.max(c.x,k.right),c.y,!1):c.x>a.x&&(270===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,a.y,Math.min(c.x,k.left),c.y,!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y>=h.top&&c.y<=a.y||a.y<=k.bottom&&a.y>=c.y){if(0===d||180===d)l.h(a.x,Math.max((a.y+c.y)/2,a.y)),m.h(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,90===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,90===d?h.right:
Math.max(h.right,k.right)),m.x=l.x,m.y=c.y}else if(c.y<a.y||180===d&&c.x<a.x&&k.top<a.y||0===d&&c.x>a.x&&k.top<a.y)l.h(a.x,c.y),m.h((a.x+c.x)/2,c.y),90===d?(l.y=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>=a.x?(l.y=a.y>k.bottom?this.computeMidOrthoPosition(a.x,a.y,c.x,k.bottom,!0):a.y>k.top&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):k.top,m.x=c.x,m.y=l.y):270===d&&a.y>k.bottom&&a.x>k.left&&a.x<k.right&&
(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l.h(c.x,a.y);m.h(c.x,(a.y+c.y)/2);if(90===d||0===d&&c.x<h.left||180===d&&c.x>h.right)90===d&&(k.ca(a)||h.ca(c))?l.x=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1):c.x<a.x&&(90===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,a.y,Math.max(c.x,k.right),c.y,!1):c.x>a.x&&(90===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,a.y,Math.min(c.x,k.left),c.y,!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y<=h.bottom&&
c.y>=a.y||a.y>=k.top&&a.y<=c.y){if(0===d||180===d)l.h(a.x,Math.min((a.y+c.y)/2,a.y)),m.h(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,270===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,270===d?h.right:Math.max(h.right,k.right)),m.x=l.x,m.y=c.y}var n=c;if(this.isAvoiding){var p=this.diagram;if(null===p||e===f||this.layer.isTemporary||!ok(p)||h.ca(n)&&!f.Td(e)||k.ca(a)&&!e.Td(f))b=!1;else{var q=pk(p,!0,this.containingGroup,null);if(17!==this.segmentIndex&&q.Xj(Math.min(a.x,l.x),
Math.min(a.y,l.y),Math.abs(a.x-l.x),Math.abs(a.y-l.y))&&q.Xj(Math.min(l.x,m.x),Math.min(l.y,m.y),Math.abs(l.x-m.x),Math.abs(l.y-m.y))&&q.Xj(Math.min(m.x,n.x),Math.min(m.y,n.y),Math.abs(m.x-n.x),Math.abs(m.y-n.y)))b=!1;else{e=a;f=n;var r=c=null;if(p.isVirtualized){p=q.bounds.copy();p.Bc(-q.Ho,-q.Go);var u=I.alloc();uk(q,a.x,a.y)||(K.Ff(p.x,p.y,p.x+p.width,p.y+p.height,a.x,a.y,l.x,l.y,u)?(c=a=u.copy(),b=u.ab(l)):K.Ff(p.x,p.y,p.x+p.width,p.y+p.height,l.x,l.y,m.x,m.y,u)?(c=a=u.copy(),b=u.ab(m)):K.Ff(p.x,
p.y,p.x+p.width,p.y+p.height,m.x,m.y,n.x,n.y,u)&&(c=a=u.copy(),b=u.ab(n)));uk(q,n.x,n.y)||(K.Ff(p.x,p.y,p.x+p.width,p.y+p.height,n.x,n.y,m.x,m.y,u)?(r=n=u.copy(),d=m.ab(u)):K.Ff(p.x,p.y,p.x+p.width,p.y+p.height,m.x,m.y,l.x,l.y,u)?(r=n=u.copy(),d=l.ab(u)):K.Ff(p.x,p.y,p.x+p.width,p.y+p.height,l.x,l.y,a.x,a.y,u)&&(r=n=u.copy(),d=a.ab(u)));I.free(u)}h=h.copy().ed(k);h.Bc(q.Ho*q.Os,q.Go*q.Os);$p(q,a,b,n,d,h,!0);k=aq(q,n.x,n.y);!q.abort&&k>=bq&&(tk(q),h.Bc(q.Ho*q.Ix,q.Go*q.Jx),$p(q,a,b,n,d,h,!1),k=aq(q,
n.x,n.y));if(!q.abort&&k<bq&&aq(q,n.x,n.y)!==vk){cq(this,q,n.x,n.y,d,!0,g);h=this.i(2);if(4>this.pointsCount)0===b||180===b?(h.x=a.x,h.y=n.y):(h.x=n.x,h.y=a.y),this.L(2,h.x,h.y),this.m(3,h.x,h.y);else if(g=this.i(3),0===b||180===b)K.B(h.x,g.x)?(h=0===b?Math.max(h.x,a.x):Math.min(h.x,a.x),this.L(2,h,a.y),this.L(3,h,g.y)):K.B(h.y,g.y)?(Math.abs(a.y-h.y)<=q.Go/2&&(this.L(2,h.x,a.y),this.L(3,g.x,a.y)),this.m(2,h.x,a.y)):this.L(2,a.x,h.y);else if(90===b||270===b)K.B(h.y,g.y)?(h=90===b?Math.max(h.y,a.y):
Math.min(h.y,a.y),this.L(2,a.x,h),this.L(3,g.x,h)):K.B(h.x,g.x)?(Math.abs(a.x-h.x)<=q.Ho/2&&(this.L(2,a.x,h.y),this.L(3,a.x,g.y)),this.m(2,a.x,h.y)):this.L(2,h.x,a.y);null!==c&&(a=this.i(1),g=this.i(2),a.x!==g.x&&a.y!==g.y?0===b||180===b?this.m(2,a.x,g.y):this.m(2,g.x,a.y):0===b||180===b?this.m(2,e.x,c.y):this.m(2,c.x,e.y));null!==r&&(0===d||180===d?this.yf(f.x,r.y):this.yf(r.x,f.y));b=!0}else b=!1}}}else b=!1;b||(this.ne(l.copy()),this.ne(m.copy()));I.free(l);I.free(m)}};
T.prototype.computeMidOrthoPosition=function(a,b,c,d,e){var f=0;!this.hasCurviness()||this.computeSpot(!0,this.fromPort).$c()||this.computeSpot(!1,this.toPort).$c()||(f=this.computeCurviness());return e?(b+d)/2+f:(a+c)/2+f};
function Xj(a){if(null===a.diagram||!a.isAvoiding||!ok(a.diagram))return!1;var b=a.points.s,c=b.length;if(4>c)return!1;a=pk(a.diagram,!0,a.containingGroup,null);for(var d=1;d<c-2;d++){var e=b[d],f=b[d+1];if(!a.Xj(Math.min(e.x,f.x),Math.min(e.y,f.y),Math.abs(e.x-f.x),Math.abs(e.y-f.y)))return!0}return!1}
function cq(a,b,c,d,e,f,g){var h=b.Ho,k=b.Go,l=aq(b,c,d),m=c,n=d;0===e?m+=h:90===e?n+=k:180===e?m-=h:n-=k;e!==g&&l>dq&&aq(b,m,n)!==l-1&&(aq(b,c-h,d)===l-1?(e=180,m=c-h,n=d):aq(b,c+h,d)===l-1?(e=0,m=c+h,n=d):aq(b,c,d-k)===l-1?(e=270,m=c,n=d-k):aq(b,c,d+k)===l-1&&(e=90,m=c,n=d+k));for(;l>dq&&aq(b,m,n)===l-1;)c=m,d=n,0===e?m+=h:90===e?n+=k:180===e?m-=h:n-=k,--l;if(f){if(l>dq)if(180===e||0===e)c=Math.floor(c/h)*h+h/2;else if(90===e||270===e)d=Math.floor(d/k)*k+k/2}else c=Math.floor(c/h)*h+h/2,d=Math.floor(d/
k)*k+k/2;l>dq&&(f=e,g=c,m=d,0===e?(f=90,m+=k):90===e?(f=180,g-=h):180===e?(f=270,m-=k):270===e&&(f=0,g+=h),aq(b,g,m)===l-1?cq(a,b,g,m,f,!1,f):(g=c,m=d,0===e?(f=270,m-=k):90===e?(f=0,g+=h):180===e?(f=90,m+=k):270===e&&(f=180,g-=h),aq(b,g,m)===l-1&&cq(a,b,g,m,f,!1,f)));a.yf(c,d)}T.prototype.fz=function(a){var b=a.x;a=a.y;for(var c=this.i(0),d=this.i(1),e=yb(b,a,c.x,c.y,d.x,d.y),f=0,g=1;g<this.pointsCount-1;g++){c=this.i(g+1);var h=yb(b,a,d.x,d.y,c.x,c.y);d=c;h<e&&(f=g,e=h)}return f};
T.prototype.rc=function(){this.kk=!1;this.v()};T.prototype.Ul=function(a){if(!a){if(!1===this.fd)return;a=this.Qb();if(this.kk&&(null===a||null!==a.geometry))return}2>this.pointsCount?this.kk=!0:this.ua=this.makeGeometry();a=this.path;if(null!==a){a.ua=this.ua;for(var b=this.O.s,c=b.length,d=0;d<c;d++){var e=b[d];e!==a&&e.isPanelMain&&e instanceof Bf&&(e.ua=this.ua)}}};
T.prototype.makeGeometry=function(){var a=this.ua,b=this.pointsCount,c=!1,d=this.diagram;null!==d&&Jp(this)&&d.Eh.contains(this)&&(0!==this.ml.width||0!==this.ml.height)&&(d.animationManager.isAnimating||d.currentTool!==d.toolManager)&&(c=!0);var e=this.i(0).copy(),f=e.copy();d=this.Ob.s;var g=this.computeCurve();if(g===bg&&3<=b&&!K.$(this.smoothness,0))if(3===b){var h=this.i(1);d=Math.min(e.x,h.x);var k=Math.min(e.y,h.y);h=this.i(2);d=Math.min(d,h.x);k=Math.min(k,h.y)}else{if(this.isOrthogonal)for(k=
0;k<b;k++)h=d[k],f.x=Math.min(h.x,f.x),f.y=Math.min(h.y,f.y);else for(d=3;d<b;d+=3)d+3>=b&&(d=b-1),k=this.i(d),f.x=Math.min(k.x,f.x),f.y=Math.min(k.y,f.y);d=f.x;k=f.y}else{for(k=0;k<b;k++)h=d[k],f.x=Math.min(h.x,f.x),f.y=Math.min(h.y,f.y);d=f.x;k=f.y}d-=this.su.x;k-=this.su.y;e.x-=d;e.y-=k;if(2!==b||Jp(this)){a.type=P.j;h=qd(a);0!==this.computeShortLength(!0)&&(e=eq(this,e,!0,f));rd(h,e.x,e.y,!1);if(g===bg&&3<=b&&!K.$(this.smoothness,0))if(3===b)c=this.i(1),b=c.x-d,c=c.y-k,e=this.i(2).copy(),e.x-=
d,e.y-=k,0!==this.computeShortLength(!1)&&(e=eq(this,e,!1,f)),sd(h,b,c,b,c,e.x,e.y);else if(this.isOrthogonal){b=I.allocAt(d,k);c=I.alloc();c.assign(this.i(1));f=I.allocAt(d,k);e=I.allocAt(d,k);g=this.i(0);for(var l,m=this.smoothness/3,n=1;n<this.pointsCount-1;n++){l=this.i(n);var p=g,q=l,r=this.i(fq(this,l,n,!1));if(!K.$(p.x,q.x)||!K.$(q.x,r.x))if(!K.$(p.y,q.y)||!K.$(q.y,r.y)){var u=m;isNaN(u)&&(u=this.smoothness/3);var w=p.x;p=p.y;var v=q.x;q=q.y;var y=r.x;r=r.y;var z=u*gq(w,p,v,q);u*=gq(v,q,y,
r);K.$(p,q)&&K.$(v,y)&&(v>w?r>q?(f.x=v-z,f.y=q-z,e.x=v+u,e.y=q+u):(f.x=v-z,f.y=q+z,e.x=v+u,e.y=q-u):r>q?(f.x=v+z,f.y=q-z,e.x=v-u,e.y=q+u):(f.x=v+z,f.y=q+z,e.x=v-u,e.y=q-u));K.$(w,v)&&K.$(q,r)&&(q>p?(y>v?(f.x=v-z,f.y=q-z,e.x=v+u):(f.x=v+z,f.y=q-z,e.x=v-u),e.y=q+u):(y>v?(f.x=v-z,f.y=q+z,e.x=v+u):(f.x=v+z,f.y=q+z,e.x=v-u),e.y=q-u));if(K.$(w,v)&&K.$(v,y)||K.$(p,q)&&K.$(q,r))w=.5*(w+y),p=.5*(p+r),f.x=w,f.y=p,e.x=w,e.y=p;1===n?(c.x=.5*(g.x+l.x),c.y=.5*(g.y+l.y)):2===n&&K.$(g.x,this.i(0).x)&&K.$(g.y,this.i(0).y)&&
(c.x=.5*(g.x+l.x),c.y=.5*(g.y+l.y));sd(h,c.x-d,c.y-k,f.x-d,f.y-k,l.x-d,l.y-k);b.set(f);c.set(e);g=l}}l=g.x;g=g.y;m=this.i(this.pointsCount-1);0!==this.computeShortLength(!1)&&(m=eq(this,m.copy(),!1,pb));l=.5*(l+m.x);g=.5*(g+m.y);sd(h,e.x-d,e.y-k,l-d,g-k,m.x-d,m.y-k);I.free(b);I.free(c);I.free(f);I.free(e)}else for(c=3;c<b;c+=3)f=this.i(c-2),c+3>=b&&(c=b-1),e=this.i(c-1),g=this.i(c),c===b-1&&0!==this.computeShortLength(!1)&&(g=eq(this,g.copy(),!1,pb)),sd(h,f.x-d,f.y-k,e.x-d,e.y-k,g.x-d,g.y-k);else{f=
I.alloc();f.assign(this.i(0));e=I.alloc();e.assign(f);g=this.computeCorner();n=1;for(l=0;n<b;){n=fq(this,f,n,1<n);m=this.i(n);if(n>=b-1){if(!f.D(m))0!==this.computeShortLength(!1)&&(m=eq(this,m.copy(),!1,pb)),hq(this,h,-d,-k,f,m,c);else if(0===l)for(n=1;n<b;)e.assign(f),m=this.i(n++),hq(this,h,-d,-k,f,m,c),f.assign(m);break}l=fq(this,m,n+1,n<b-3);n=-d;w=-k;p=m;v=this.i(l);u=c;if(K.B(f.y,p.y)&&K.B(p.x,v.x))if(r=Math.min(g,Math.abs(p.x-e.x)/2),r=z=Math.min(r,Math.abs(v.y-p.y)/2),K.B(r,0))hq(this,h,
n,w,f,p,u),f.assign(p);else{var A=p.x,B=p.y;q=A;y=B;p.x>f.x?A=p.x-r:A=p.x+r;v.y>p.y?y=p.y+z:y=p.y-z;v=I.allocAt(A,B);hq(this,h,n,w,f,v,u);I.free(v);td(h,p.x+n,p.y+w,q+n,y+w);f.h(q,y)}else K.B(f.x,p.x)&&K.B(p.y,v.y)?(r=Math.min(g,Math.abs(p.y-e.y)/2),r=z=Math.min(r,Math.abs(v.x-p.x)/2),K.B(z,0)?(hq(this,h,n,w,f,p,u),f.assign(p)):(A=p.x,B=p.y,q=A,y=B,p.y>f.y?B=p.y-r:B=p.y+r,v.x>p.x?q=p.x+z:q=p.x-z,v=I.allocAt(A,B),hq(this,h,n,w,f,v,u),I.free(v),td(h,p.x+n,p.y+w,q+n,y+w),f.h(q,y))):(hq(this,h,n,w,f,
p,u),f.assign(p));e.assign(m);n=l}I.free(f)}yd=h}else h=this.i(1).copy(),h.x-=d,h.y-=k,0!==this.computeShortLength(!0)&&(e=eq(this,e,!0,f)),0!==this.computeShortLength(!1)&&(h=eq(this,h,!1,f)),a.type=P.o,a.startX=e.x,a.startY=e.y,a.endX=h.x,a.endY=h.y;this.kk=!0;return a};function gq(a,b,c,d){a=c-a;if(isNaN(a)||Infinity===a||-Infinity===a)return NaN;0>a&&(a=-a);b=d-b;if(isNaN(b)||Infinity===b||-Infinity===b)return NaN;0>b&&(b=-b);return K.$(a,0)?b:K.$(b,0)?a:Math.sqrt(a*a+b*b)}
function eq(a,b,c,d){var e=a.pointsCount;if(2>e)return b;if(c){var f=a.i(1);c=f.x-d.x;f=f.y-d.y;d=gq(b.x,b.y,c,f);if(0===d)return b;e=2===e?.5*d:d;a=a.computeShortLength(!0);a>e&&(a=e);e=a*(f-b.y)/d;b.x+=a*(c-b.x)/d;b.y+=e}else{f=a.i(e-2);c=f.x-d.x;f=f.y-d.y;d=gq(b.x,b.y,c,f);if(0===d)return b;e=2===e?.5*d:d;a=a.computeShortLength(!1);a>e&&(a=e);e=a*(b.y-f)/d;b.x-=a*(b.x-c)/d;b.y-=e}return b}
function fq(a,b,c,d){for(var e=a.pointsCount,f=b;K.$(b.x,f.x)&&K.$(b.y,f.y);){if(c>=e)return e-1;f=a.i(c++)}if(!K.$(b.x,f.x)&&!K.$(b.y,f.y))return c-1;for(var g=f;K.$(b.x,f.x)&&K.$(f.x,g.x)&&(!d||(b.y>=f.y?f.y>=g.y:f.y<=g.y))||K.$(b.y,f.y)&&K.$(f.y,g.y)&&(!d||(b.x>=f.x?f.x>=g.x:f.x<=g.x));){if(c>=e)return e-1;g=a.i(c++)}return c-2}T.prototype.computeJumpLength=function(){return 10};
function hq(a,b,c,d,e,f,g){if(!g&&Jp(a)){g=a.computeJumpLength();var h=g/2,k=[],l=0;a.isVisible()&&(l=iq(a,e,f,k));if(0<l)if(K.B(e.y,f.y))if(e.x<f.x)for(var m=0;m<l;){var n=Math.max(e.x,Math.min(k[m++]-h,f.x-g));b.lineTo(n+c,f.y+d);var p=n+c;for(var q=Math.min(n+g,f.x);m<l;)if(n=k[m],n<q+g)m++,q=Math.min(n+h,f.x);else break;n=f.y-g+d;q+=c;var r=f.y+d;a.curve===Yf?rd(b,q,r,!1):sd(b,p,n,q,n,q,r)}else for(--l;0<=l;){m=Math.min(e.x,Math.max(k[l--]+h,f.x+g));b.lineTo(m+c,f.y+d);p=m+c;for(n=Math.max(m-
g,f.x);0<=l;)if(m=k[l],m>n-g)l--,n=Math.max(m-h,f.x);else break;m=f.y-g+d;n+=c;q=f.y+d;a.curve===Yf?rd(b,n,q,!1):sd(b,p,m,n,m,n,q)}else if(K.B(e.x,f.x))if(e.y<f.y)for(m=0;m<l;){n=Math.max(e.y,Math.min(k[m++]-h,f.y-g));b.lineTo(f.x+c,n+d);p=n+d;for(n=Math.min(n+g,f.y);m<l;)if(q=k[m],q<n+g)m++,n=Math.min(q+h,f.y);else break;q=f.x-g+c;r=f.x+c;n+=d;a.curve===Yf?rd(b,r,n,!1):sd(b,q,p,q,n,r,n)}else for(--l;0<=l;){m=Math.min(e.y,Math.max(k[l--]+h,f.y+g));b.lineTo(f.x+c,m+d);p=m+d;for(m=Math.max(m-g,f.y);0<=
l;)if(n=k[l],n>m-g)l--,m=Math.max(n-h,f.y);else break;n=f.x-g+c;q=f.x+c;m+=d;a.curve===Yf?rd(b,q,m,!1):sd(b,n,p,n,m,q,m)}}b.lineTo(f.x+c,f.y+d)}
function iq(a,b,c,d){var e=a.diagram;if(null===e||b.D(c))return 0;for(e=e.layers;e.next();){var f=e.value;if(null!==f&&f.visible){f=f.Pa.s;for(var g=f.length,h=0;h<g;h++){var k=f[h];if(k instanceof T){if(k===a)return 0<d.length&&d.sort(function(a,b){return a-b}),d.length;if(k.isVisible()&&Jp(k)){var l=k.routeBounds;l.w()&&a.routeBounds.Yc(l)&&!a.usesSamePort(k)&&(l=k.path,null!==l&&l.Ed()&&jq(b,c,d,k))}}}}}0<d.length&&d.sort(function(a,b){return a-b});return d.length}
function jq(a,b,c,d){for(var e=K.B(a.y,b.y),f=d.pointsCount,g=d.i(0),h=I.alloc(),k=1;k<f;k++){var l=d.i(k);if(k<f-1){var m=d.i(k+1);if(g.y===l.y&&l.y===m.y){if(l.x>g.x&&m.x>=l.x||l.x<g.x&&m.x<=l.x)continue}else if(g.x===l.x&&l.x===m.x&&(l.y>g.y&&m.y>=l.y||l.y<g.y&&m.y<=l.y))continue}a:{m=a.x;var n=a.y,p=b.x,q=b.y,r=g.x;g=g.y;var u=l.x,w=l.y;if(!K.B(m,p)){if(K.B(n,q)&&K.B(r,u)&&Math.min(m,p)<r&&Math.max(m,p)>r&&Math.min(g,w)<n&&Math.max(g,w)>n&&!K.B(g,w)){h.x=r;h.y=n;m=!0;break a}}else if(!K.B(n,q)&&
K.B(g,w)&&Math.min(n,q)<g&&Math.max(n,q)>g&&Math.min(r,u)<m&&Math.max(r,u)>m&&!K.B(r,u)){h.x=m;h.y=g;m=!0;break a}h.x=0;h.y=0;m=!1}m&&(e?c.push(h.x):c.push(h.y));g=l}I.free(h)}function Jp(a){a=a.curve;return a===Xf||a===Yf}function Np(a,b){if(b||Jp(a))b=a.diagram,null===b||b.animationManager.isTicking||b.Eh.contains(a)||0===a.ml.width&&0===a.ml.height||b.Eh.add(a,a.ml.copy())}
T.prototype.Xo=function(a){var b=this.layer;if(null!==b&&b.visible&&!b.isTemporary){var c=b.diagram;if(null!==c&&!c.animationManager.isTicking){var d=!1;for(c=c.layers;c.next();){var e=c.value;if(e.visible)if(e===b){d=!0;var f=!1;e=e.Pa.s;for(var g=e.length,h=0;h<g;h++){var k=e[h];k instanceof T&&(k===this?f=!0:f&&kq(this,k,a))}}else if(d)for(f=e.Pa.s,e=f.length,g=0;g<e;g++)h=f[g],h instanceof T&&kq(this,h,a)}}}};
function kq(a,b,c){if(null!==b&&b.kk&&b.fd&&Jp(b)){var d=b.routeBounds;d.w()&&(a.routeBounds.Yc(d)||c.Yc(d))&&(a.usesSamePort(b)||b.rc())}}T.prototype.usesSamePort=function(a){var b=this.pointsCount,c=a.pointsCount;if(0<b&&0<c){var d=this.i(0),e=a.i(0);if(d.Xa(e))return!0;b=this.i(b-1);a=a.i(c-1);if(b.Xa(a)||d.Xa(a)||b.Xa(e))return!0}else if(this.fromNode===a.fromNode||this.toNode===a.toNode||this.fromNode===a.toNode||this.toNode===a.fromNode)return!0;return!1};
T.prototype.isVisible=function(){if(!U.prototype.isVisible.call(this))return!1;var a=this.containingGroup,b=!0,c=this.diagram;null!==c&&(b=c.isTreePathToChildren);c=this.fromNode;if(null!==c){if(this.isTreeLink&&b&&!c.isTreeExpanded)return!1;if(c===a)return!0;for(var d=c;null!==d;){if(d.labeledLink===this)return!0;d=d.containingGroup}c=c.findVisibleNode();if(null===c||c===a)return!1}c=this.toNode;if(null!==c){if(this.isTreeLink&&!b&&!c.isTreeExpanded)return!1;if(c===a)return!0;for(b=c;null!==b;){if(b.labeledLink===
this)return!0;b=b.containingGroup}b=c.findVisibleNode();if(null===b||b===a)return!1}return!0};T.prototype.ac=function(a){U.prototype.ac.call(this,a);a&&Np(this,!1);null!==this.Sf&&this.Sf.Sl();if(null!==this.wd)for(var b=this.wd.iterator;b.next();)b.value.ac(a)};T.prototype.computeAdjusting=function(){return this.isAvoiding&&null!==this.diagram&&this.diagram.animationManager.defaultAnimation.isAnimating?Fp:this.pk};
function Kp(a){var b=a.ef;if(null!==b){var c=a.uf;if(null!==c&&isNaN(a.curviness)){for(var d=a.ff,e=a.vf,f=a=null,g=b.jb.s,h=g.length,k=0;k<h;k++){var l=g[k];if(l.ef===b&&l.ff===d&&l.uf===c&&l.vf===e||l.ef===c&&l.ff===e&&l.uf===b&&l.vf===d)null===f?f=l:(null===a&&(a=[],a.push(f)),a.push(l))}if(null!==a){f=pp(b,c,d,e);null===f&&(f=new lq(b,d,c,e),op(b,f),op(c,f));f.links=a;for(b=0;b<a.length;b++)a[b].Sf=f;f.Sl()}}}}
function Lp(a){var b=a.Sf;null!==b&&isNaN(a.curviness)&&(a.Sf=null,a=b.links.indexOf(a),0<=a&&(Da(b.links,a),b.Sl()))}T.prototype.ph=function(){return!0};
ma.Object.defineProperties(T.prototype,{fromNode:{get:function(){return this.ef},set:function(a){var b=this.ef;if(b!==a){var c=this.fromPort;null!==b&&(this.uf!==b&&tp(b,this,c),Lp(this),this.C(2));this.ef=a;null!==a&&this.ac(a.isVisible());this.Wf=null;this.bb();var d=this.diagram;null!==d&&d.aa&&d.partManager.setFromNodeForLink(this,a,b);var e=this.fromPort,f=this.fromPortChanged;if(null!==f){var g=!0;null!==d&&(g=d.aa,d.aa=!0);f(this,c,e);null!==d&&(d.aa=g)}null!==
a&&(this.uf!==a&&sp(a,this,e),Kp(this),this.C(1));this.g("fromNode",b,a);kp(this)}}},fromPortId:{get:function(){return this.ff},set:function(a){var b=this.ff;if(b!==a){var c=this.fromPort;null!==c&&qp(c);Lp(this);this.ff=a;var d=this.fromPort;null!==d&&qp(d);var e=this.diagram;if(null!==e){var f=this.data,g=e.model;null!==f&&g.ri()&&g.yv(f,a)}c!==d&&(this.Wf=null,this.bb(),f=this.fromPortChanged,null!==f&&(g=!0,null!==e&&(g=e.aa,e.aa=!0),f(this,c,d),null!==e&&(e.aa=g)));
Kp(this);this.g("fromPortId",b,a)}}},fromPort:{get:function(){var a=this.ef;return null===a?null:a.ns(this.ff)}},fromPortChanged:{get:function(){return this.gn},set:function(a){var b=this.gn;b!==a&&(this.gn=a,this.g("fromPortChanged",b,a))}},toNode:{get:function(){return this.uf},set:function(a){var b=this.uf;if(b!==a){var c=this.toPort;null!==b&&(this.ef!==b&&tp(b,this,c),Lp(this),this.C(2));this.uf=a;null!==
a&&this.ac(a.isVisible());this.Wf=null;this.bb();var d=this.diagram;null!==d&&d.aa&&d.partManager.setToNodeForLink(this,a,b);var e=this.toPort,f=this.toPortChanged;if(null!==f){var g=!0;null!==d&&(g=d.aa,d.aa=!0);f(this,c,e);null!==d&&(d.aa=g)}null!==a&&(this.ef!==a&&sp(a,this,e),Kp(this),this.C(1));this.g("toNode",b,a);kp(this)}}},toPortId:{get:function(){return this.vf},set:function(a){var b=this.vf;if(b!==a){var c=this.toPort;null!==c&&qp(c);Lp(this);this.vf=a;var d=
this.toPort;null!==d&&qp(d);var e=this.diagram;if(null!==e){var f=this.data,g=e.model;null!==f&&g.ri()&&g.Dv(f,a)}c!==d&&(this.Wf=null,this.bb(),f=this.toPortChanged,null!==f&&(g=!0,null!==e&&(g=e.aa,e.aa=!0),f(this,c,d),null!==e&&(e.aa=g)));Kp(this);this.g("toPortId",b,a)}}},toPort:{get:function(){var a=this.uf;return null===a?null:a.ns(this.vf)}},toPortChanged:{get:function(){return this.uo},set:function(a){var b=this.uo;b!==a&&(this.uo=
a,this.g("toPortChanged",b,a))}},fromSpot:{get:function(){return null!==this.ba?this.ba.Xi:Sc},set:function(a){var b=this.fromSpot;b.D(a)||(a=a.J(),this.Xc(),this.ba.Xi=a,this.g("fromSpot",b,a),(b.$c()||a.$c())&&null!==this.fromNode&&qp(this.fromPort),this.bb())}},fromEndSegmentLength:{get:function(){return null!==this.ba?this.ba.Vi:NaN},set:function(a){var b=this.fromEndSegmentLength;b!==a&&(0>a&&xa(a,">= 0",T,"fromEndSegmentLength"),this.Xc(),
this.ba.Vi=a,this.g("fromEndSegmentLength",b,a),this.bb())}},fromShortLength:{get:function(){return null!==this.ba?this.ba.Wi:NaN},set:function(a){var b=this.fromShortLength;b!==a&&(this.Xc(),this.ba.Wi=a,this.rc(),this.g("fromShortLength",b,a))}},toSpot:{get:function(){return null!==this.ba?this.ba.yj:Sc},set:function(a){var b=this.toSpot;b.D(a)||(a=a.J(),this.Xc(),this.ba.yj=a,this.g("toSpot",b,a),(b.$c()||a.$c())&&null!==this.toNode&&
qp(this.toPort),this.bb())}},toEndSegmentLength:{get:function(){return null!==this.ba?this.ba.wj:NaN},set:function(a){var b=this.toEndSegmentLength;b!==a&&(0>a&&xa(a,">= 0",T,"toEndSegmentLength"),this.Xc(),this.ba.wj=a,this.g("toEndSegmentLength",b,a),this.bb())}},toShortLength:{get:function(){return null!==this.ba?this.ba.xj:NaN},set:function(a){var b=this.toShortLength;b!==a&&(this.Xc(),this.ba.xj=a,this.rc(),this.g("toShortLength",b,
a))}},isLabeledLink:{get:function(){return null===this.wd?!1:0<this.wd.count}},labelNodes:{get:function(){return null===this.wd?Ya:this.wd.iterator}},relinkableFrom:{get:function(){return 0!==(this.Qa&1)},set:function(a){var b=0!==(this.Qa&1);b!==a&&(this.Qa^=1,this.g("relinkableFrom",b,a),this.Yb())}},relinkableTo:{get:function(){return 0!==(this.Qa&2)},set:function(a){var b=0!==
(this.Qa&2);b!==a&&(this.Qa^=2,this.g("relinkableTo",b,a),this.Yb())}},resegmentable:{get:function(){return 0!==(this.Qa&4)},set:function(a){var b=0!==(this.Qa&4);b!==a&&(this.Qa^=4,this.g("resegmentable",b,a),this.Yb())}},isTreeLink:{get:function(){return 0!==(this.Qa&8)},set:function(a){var b=0!==(this.Qa&8);b!==a&&(this.Qa^=8,this.g("isTreeLink",b,a),null!==this.fromNode&&Bk(this.fromNode),null!==this.toNode&&Bk(this.toNode))}},path:{
get:function(){var a=this.Qb();return a instanceof Bf?a:null}},routeBounds:{get:function(){this.vg();var a=this.ml,b=Infinity,c=Infinity,d=this.pointsCount;if(0===d)a.h(NaN,NaN,0,0);else{if(1===d)d=this.i(0),b=Math.min(d.x,b),c=Math.min(d.y,c),a.h(d.x,d.y,0,0);else if(2===d){d=this.i(0);var e=this.i(1);b=Math.min(d.x,e.x);c=Math.min(d.y,e.y);a.h(d.x,d.y,0,0);a.Ye(e)}else if(this.computeCurve()===bg&&3<=d&&!this.isOrthogonal)if(e=this.i(0),b=e.x,c=e.y,a.h(b,
c,0,0),3===d){d=this.i(1);b=Math.min(d.x,b);c=Math.min(d.y,c);var f=this.i(2);b=Math.min(f.x,b);c=Math.min(f.y,c);K.Dl(e.x,e.y,d.x,d.y,d.x,d.y,f.x,f.y,.5,a)}else for(f=3;f<d;f+=3){var g=this.i(f-2);f+3>=d&&(f=d-1);var h=this.i(f-1),k=this.i(f);K.Dl(e.x,e.y,g.x,g.y,h.x,h.y,k.x,k.y,.5,a);b=Math.min(k.x,b);c=Math.min(k.y,c);e=k}else for(e=this.i(0),f=this.i(1),b=Math.min(e.x,f.x),c=Math.min(e.y,f.y),a.h(e.x,e.y,0,0),a.Ye(f),e=2;e<d;e++)f=this.i(e),b=Math.min(f.x,b),c=Math.min(f.y,c),a.Ye(f);this.su.h(b-
a.x,c-a.y)}return a}},midPoint:{get:function(){this.vg();return this.computeMidPoint(new I)}},midAngle:{get:function(){this.vg();return this.computeMidAngle()}},flattenedLengths:{get:function(){if(null===this.cq){this.fd||Mp(this);for(var a=this.cq=[],b=this.pointsCount,c=0;c<b-1;c++){var d=this.i(c);var e=this.i(c+1);K.$(d.x,e.x)?(d=e.y-d.y,0>d&&(d=-d)):K.$(d.y,e.y)?(d=e.x-d.x,0>d&&(d=-d)):d=Math.sqrt(d.Bf(e));
a.push(d)}}return this.cq}},flattenedTotalLength:{get:function(){var a=this.vt;if(isNaN(a)){for(var b=this.flattenedLengths,c=b.length,d=a=0;d<c;d++)a+=b[d];this.vt=a}return a}},points:{get:function(){return this.Ob},set:function(a){var b=this.Ob;if(b!==a){var c=null;if(Array.isArray(a)){var d=0===a.length%2;if(d)for(var e=0;e<a.length;e++)if("number"!==typeof a[e]||isNaN(a[e])){d=!1;break}if(d)for(c=new F,d=0;d<a.length/2;d++)e=(new I(a[2*
d],a[2*d+1])).freeze(),c.add(e);else{d=!0;for(e=0;e<a.length;e++){var f=a[e];if(!za(f)||"number"!==typeof f.x||isNaN(f.x)||"number"!==typeof f.y||isNaN(f.y)){d=!1;break}}if(d)for(c=new F,d=0;d<a.length;d++)e=a[d],c.add((new I(e.x,e.y)).freeze())}}else if(a instanceof F)for(c=a.copy(),a=c.iterator;a.next();)a.value.freeze();else C("Link.points value is not an instance of List or Array: "+a);c.freeze();this.Ob=c;this.rc();Mp(this);a=this.diagram;null!==a&&(a.pi||a.undoManager.isUndoingRedoing||a.As.add(this),
a.animationManager.Ab&&Bh(a.animationManager,this,c));this.g("points",b,c)}}},pointsCount:{get:function(){return this.Ob.count}},fd:{get:function(){return 0!==(this.Qa&16)},set:function(a){0!==(this.Qa&16)!==a&&(this.Qa^=16)}},suspendsRouting:{get:function(){return 0!==(this.Qa&32)},set:function(a){0!==(this.Qa&32)!==a&&(this.Qa^=32)}},computingPoints:{get:function(){return 0!==
(this.Qa&64)},set:function(a){0!==(this.Qa&64)!==a&&(this.Qa^=64)}},defaultFromPoint:{get:function(){return this.o},set:function(a){this.o=a.copy()}},defaultToPoint:{get:function(){return this.u},set:function(a){this.u=a.copy()}},isOrthogonal:{get:function(){return 2===(this.qj.value&2)}},isAvoiding:{get:function(){return 4===(this.qj.value&4)}},kk:{
get:function(){return 0!==(this.Qa&128)},set:function(a){0!==(this.Qa&128)!==a&&(this.Qa^=128)}},geometry:{get:function(){if(!this.kk){this.vg();if(2>this.pointsCount)return this.kk=!0,this.ua;this.ua=this.makeGeometry()}return this.ua}},firstPickIndex:{get:function(){return 2>=this.pointsCount?0:this.isOrthogonal||!Qp(this.computeSpot(!0))?1:0}},lastPickIndex:{get:function(){var a=this.pointsCount;return 0===
a?0:2>=a?a-1:this.isOrthogonal||!Qp(this.computeSpot(!1))?a-2:a-1}},adjusting:{get:function(){return this.pk},set:function(a){var b=this.pk;b!==a&&(this.pk=a,this.g("adjusting",b,a))}},corner:{get:function(){return this.Om},set:function(a){var b=this.Om;b!==a&&(this.Om=a,this.rc(),this.g("corner",b,a))}},curve:{get:function(){return this.Qm},set:function(a){var b=this.Qm;b!==a&&(this.Qm=a,this.bb(),this.rc(),
Np(this,b===Yf||b===Xf||a===Yf||a===Xf),this.g("curve",b,a))}},curviness:{get:function(){return this.Rm},set:function(a){var b=this.Rm;b!==a&&(this.Rm=a,this.bb(),this.rc(),this.g("curviness",b,a))}},routing:{get:function(){return this.qj},set:function(a){var b=this.qj;b!==a&&(this.qj=a,this.Wf=null,this.bb(),Np(this,2===(b.value&2)||2===(a.value&2)),this.g("routing",b,a))}},smoothness:{get:function(){return this.po},
set:function(a){var b=this.po;b!==a&&(this.po=a,this.rc(),this.g("smoothness",b,a))}},key:{get:function(){var a=this.diagram;if(null!==a&&a.model.ri())return a.model.Ac(this.data)}}});T.prototype.invalidateOtherJumpOvers=T.prototype.Xo;T.prototype.findClosestSegment=T.prototype.fz;T.prototype.updateRoute=T.prototype.vg;T.prototype.invalidateRoute=T.prototype.bb;T.prototype.rollbackRoute=T.prototype.sv;T.prototype.commitRoute=T.prototype.zf;T.prototype.startRoute=T.prototype.uh;
T.prototype.clearPoints=T.prototype.Ej;T.prototype.removePoint=T.prototype.qv;T.prototype.addPointAt=T.prototype.yf;T.prototype.addPoint=T.prototype.ne;T.prototype.insertPointAt=T.prototype.m;T.prototype.insertPoint=T.prototype.Lz;T.prototype.setPointAt=T.prototype.L;T.prototype.setPoint=T.prototype.dd;T.prototype.getPoint=T.prototype.i;T.prototype.getOtherPort=T.prototype.Dz;T.prototype.getOtherNode=T.prototype.To;
var Op=null,Pp=null,Ep=new D(T,"Normal",1),mq=new D(T,"Orthogonal",2),nq=new D(T,"AvoidsNodes",6),Wp=new D(T,"AvoidsNodesStraight",7),Zf=new D(T,"None",0),bg=new D(T,"Bezier",9),Yf=new D(T,"JumpGap",10),Xf=new D(T,"JumpOver",11),Fp=new D(T,"End",17),Gp=new D(T,"Scale",18),Hp=new D(T,"Stretch",19),on=new D(T,"OrientAlong",21),fn=new D(T,"OrientPlus90",22),hn=new D(T,"OrientMinus90",23),Ip=new D(T,"OrientOpposite",24),pn=new D(T,"OrientUpright",25),gn=new D(T,"OrientPlus90Upright",26),jn=new D(T,"OrientMinus90Upright",
27),kn=new D(T,"OrientUpright45",28),Yp=16,Xp=14,Zp=8;T.className="Link";T.Normal=Ep;T.Orthogonal=mq;T.AvoidsNodes=nq;T.AvoidsNodesStraight=Wp;T.None=Zf;T.Bezier=bg;T.JumpGap=Yf;T.JumpOver=Xf;T.End=Fp;T.Scale=Gp;T.Stretch=Hp;T.OrientAlong=on;T.OrientPlus90=fn;T.OrientMinus90=hn;T.OrientOpposite=Ip;T.OrientUpright=pn;T.OrientPlus90Upright=gn;T.OrientMinus90Upright=jn;T.OrientUpright45=kn;T.EndSegmentStep=8;T.CurvinessStart=Yp;T.SpacingMin=Xp;T.OrthoShortCut=Zp;
function lq(a,b,c,d){Qa(this);this.Fe=this.qq=!1;this.Ds=a;this.Px=b;this.dv=c;this.Qx=d;this.links=[]}lq.prototype.Sl=function(){if(!this.qq){var a=this.links;0<a.length&&(a=a[0].diagram,null!==a&&(a.pw.add(this),this.Fe=a.undoManager.isUndoingRedoing))}this.qq=!0};
lq.prototype.Ov=function(){if(this.qq){this.qq=!1;var a=this.links;if(0<a.length){var b=a[0],c=b.diagram;c=null===c||c.pi&&!this.Fe;this.Fe=!1;b.arrangeBundledLinks(a,c);1===a.length&&(b.Sf=null,a.length=0)}0===a.length&&(a=this.Ds,null!==this&&null!==a.af&&a.af.remove(this),a=this.dv,null!==this&&null!==a.af&&a.af.remove(this))}};lq.className="LinkBundle";
function qk(a){Qa(this);this.Zx=this.group=null;this.vs=!0;this.abort=!1;this.Od=this.Nd=1;this.Fn=this.En=-1;this.sc=a.width;this.tc=a.height;this.bc=null;this.ei=this.di=0;this.Os=11;this.Jx=this.Ix=22}
qk.prototype.initialize=function(a){if(!(0>=a.width||0>=a.height)){var b=a.y,c=a.x+a.width,d=a.y+a.height;this.Nd=Math.floor((a.x-this.sc)/this.sc)*this.sc;this.Od=Math.floor((b-this.tc)/this.tc)*this.tc;this.En=Math.ceil((c+2*this.sc)/this.sc)*this.sc;this.Fn=Math.ceil((d+2*this.tc)/this.tc)*this.tc;a=1+(Math.ceil((this.En-this.Nd)/this.sc)|0);b=1+(Math.ceil((this.Fn-this.Od)/this.tc)|0);if(null===this.bc||this.di<a-1||this.ei<b-1){c=[];for(d=0;d<=a;d++)c[d]=new Uint32Array(b);this.bc=c;this.di=
a-1;this.ei=b-1}a=oq;if(null!==this.bc)for(b=0;b<=this.di;b++)if(c=this.bc[b],c.fill)c.fill(a);else for(d=0;d<=this.ei;d++)c[d]=a}};function uk(a,b,c){return a.Nd<=b&&b<=a.En&&a.Od<=c&&c<=a.Fn}function aq(a,b,c){if(!uk(a,b,c))return oq;b-=a.Nd;b/=a.sc;c-=a.Od;c/=a.tc;return a.bc[b|0][c|0]}function tk(a){if(null!==a.bc)for(var b=0;b<=a.di;b++)for(var c=a.bc[b],d=0;d<=a.ei;d++)c[d]>=dq&&(c[d]=oq)}
qk.prototype.Xj=function(a,b,c,d){if(a>this.En||a+c<this.Nd||b>this.Fn||b+d<this.Od)return!0;a=(a-this.Nd)/this.sc|0;b=(b-this.Od)/this.tc|0;c=Math.max(0,c)/this.sc+1|0;var e=Math.max(0,d)/this.tc+1|0;0>a&&(c+=a,a=0);0>b&&(e+=b,b=0);if(0>c||0>e)return!0;d=Math.min(a+c-1,this.di)|0;for(c=Math.min(b+e-1,this.ei)|0;a<=d;a++){e=this.bc[a];for(var f=b;f<=c;f++)if(e[f]===vk)return!1}return!0};
function pq(a,b,c,d,e,f,g,h,k){if(!(b<f||b>g||c<h||c>k)){var l=b|0;var m=c|0;var n=a.bc[l][m];if(n>=dq&&n<bq)for(e?m+=d:l+=d,n+=1;f<=l&&l<=g&&h<=m&&m<=k&&!(n>=a.bc[l][m]);)a.bc[l][m]=n,n+=1,e?m+=d:l+=d;l=e?m:l;if(e)if(0<d)for(c+=d;c<l;c+=d)pq(a,b,c,1,!e,f,g,h,k),pq(a,b,c,-1,!e,f,g,h,k);else for(c+=d;c>l;c+=d)pq(a,b,c,1,!e,f,g,h,k),pq(a,b,c,-1,!e,f,g,h,k);else if(0<d)for(b+=d;b<l;b+=d)pq(a,b,c,1,!e,f,g,h,k),pq(a,b,c,-1,!e,f,g,h,k);else for(b+=d;b>l;b+=d)pq(a,b,c,1,!e,f,g,h,k),pq(a,b,c,-1,!e,f,g,h,
k)}}function qq(a,b,c,d,e,f,g,h,k){b|=0;c|=0;var l=vk,m=dq;for(a.bc[b][c]=m;l===vk&&b>f&&b<g&&c>h&&c<k;)m+=1,a.bc[b][c]=m,e?c+=d:b+=d,l=a.bc[b][c]}function rq(a,b,c,d,e,f,g,h,k){b|=0;c|=0;var l=vk,m=bq;for(a.bc[b][c]=m;l===vk&&b>f&&b<g&&c>h&&c<k;)a.bc[b][c]=m,e?c+=d:b+=d,l=a.bc[b][c]}
function $p(a,b,c,d,e,f,g){if(null!==a.bc){a.abort=!1;var h=b.x,k=b.y;if(uk(a,h,k)){h-=a.Nd;h/=a.sc;k-=a.Od;k/=a.tc;var l=d.x,m=d.y;if(uk(a,l,m))if(l-=a.Nd,l/=a.sc,m-=a.Od,m/=a.tc,1>=Math.abs(h-l)&&1>=Math.abs(k-m))a.abort=!0;else{var n=f.x;d=f.y;b=f.x+f.width;var p=f.y+f.height;n-=a.Nd;n/=a.sc;d-=a.Od;d/=a.tc;b-=a.Nd;b/=a.sc;p-=a.Od;p/=a.tc;f=Math.max(0,Math.min(a.di,n|0));b=Math.min(a.di,Math.max(0,b|0));n=Math.max(0,Math.min(a.ei,d|0));p=Math.min(a.ei,Math.max(0,p|0));d=a.bc;h|=0;k|=0;l|=0;m|=
0;var q=0===c||90===c?1:-1;c=90===c||270===c;d[h][k]===vk?(qq(a,h,k,q,c,f,b,n,p),qq(a,h,k,1,!c,f,b,n,p),qq(a,h,k,-1,!c,f,b,n,p)):qq(a,h,k,q,c,h,k,h,k);d[l][m]===vk?(rq(a,l,m,0===e||90===e?1:-1,90===e||270===e,f,b,n,p),rq(a,l,m,1,!(90===e||270===e),f,b,n,p),rq(a,l,m,-1,!(90===e||270===e),f,b,n,p)):rq(a,l,m,q,c,l,m,l,m);c=Ea();if(g&&sq(a,l,m,e,h,k,c)){if(c[0]!==l||c[1]!==m)c.unshift(m),c.unshift(l);if(c[c.length-2]!==h||c[c.length-1]!==k)c.push(h),c.push(k);a=dq;e=c[c.length-2];g=c[c.length-1];d[e][g]=
a++;for(f=c.length-4;0<=f;f-=2)for(e=c[f+2],g=c[f+3],b=c[f],l=c[f+1];b>e?e++:b<e?e--:l>g?g++:l<g&&g--,d[e][g]=a++,e!==b||g!==l;);}else pq(a,h,k,1,!1,f,b,n,p),pq(a,h,k,-1,!1,f,b,n,p),pq(a,h,k,1,!0,f,b,n,p),pq(a,h,k,-1,!0,f,b,n,p);Ga(c)}}}}
function sq(a,b,c,d,e,f,g){for(var h=a.bc,k=b,l=c;;){if(k===e&&l===f)return!0;var m=k,n=l;if(0===d)if(m<e)m++;else break;else if(90===d)if(n<f)n++;else break;else if(180===d)if(m>e)m--;else break;else if(n>f)n--;else break;var p=h[m][n];if(0===p||p===tq)break;k=m;l=n}for(;;){m=d;n=k;p=l;0===d?p<f?(m=90,p++):p>f&&(m=270,p--):90===d?n<e?(m=0,n++):n>e&&(m=180,n--):180===d?p<f?(m=90,p++):p>f&&(m=270,p--):n<e?(m=0,n++):n>e&&(m=180,n--);n=h[n][p];if(m!==d&&0!==n&&n!==tq){g.push(k);g.push(l);if(sq(a,k,l,
m,e,f,g))return!0;g.pop();g.pop()}h[k][l]=tq;if(0===d){if(k===b)return!1;k--}else if(90===d){if(l===c)return!1;l--}else if(180===d){if(k===b)return!1;k++}else{if(l===c)return!1;l++}}}
ma.Object.defineProperties(qk.prototype,{bounds:{get:function(){return new N(this.Nd,this.Od,this.En-this.Nd,this.Fn-this.Od)}},Ho:{get:function(){return this.sc},set:function(a){0<a&&a!==this.sc&&(this.sc=a,this.initialize(this.bounds))}},Go:{get:function(){return this.tc},set:function(a){0<a&&a!==this.tc&&(this.tc=a,this.initialize(this.bounds))}}});var vk=0,dq=1,bq=999999,oq=bq+1,tq=bq+2;qk.className="PositionArray";
function Up(){Qa(this);this.port=this.node=null;this.pe=[];this.ap=!1}Up.prototype.toString=function(){for(var a=this.pe,b=this.node.toString()+" "+a.length.toString()+":",c=0;c<a.length;c++){var d=a[c];null!==d&&(b+="\n  "+d.toString())}return b};
function uq(a,b,c,d){b=b.offsetY;switch(b){case 8:return 90;case 2:return 180;case 1:return 270;case 4:return 0}switch(b){case 9:return 180<c?270:90;case 6:return 90<c&&270>=c?180:0}a=180*Math.atan2(a.height,a.width)/Math.PI;switch(b){case 3:return c>a&&c<=180+a?180:270;case 5:return c>180-a&&c<=360-a?270:0;case 12:return c>a&&c<=180+a?90:0;case 10:return c>180-a&&c<=360-a?180:90;case 7:return 90<c&&c<=180+a?180:c>180+a&&c<=360-a?270:0;case 13:return 180<c&&c<=360-a?270:c>a&&180>=c?90:0;case 14:return c>
a&&c<=180-a?90:c>180-a&&270>=c?180:0;case 11:return c>180-a&&c<=180+a?180:c>180+a?270:90}d&&15!==b&&(c-=15,0>c&&(c+=360));return c>a&&c<180-a?90:c>=180-a&&c<=180+a?180:c>180+a&&c<360-a?270:0}Up.prototype.Sl=function(){this.pe.length=0};
function Vp(a,b){var c=a.pe;if(0===c.length){a:if(!a.ap){c=a.ap;a.ap=!0;var d=null,e=a.node,f=e instanceof jf?e:null;if(null===f||f.isSubGraphExpanded)var g=e.isTreeExpanded?e.findLinksConnected(a.port.portId):e.sx();else{if(!f.actualBounds.w()){a.ap=c;break a}d=f;g=d.Mu()}f=a.pe.length=0;var h=a.port.ga(sc,I.alloc()),k=a.port.ga(Bc,I.alloc());e=N.allocAt(h.x,h.y,0,0);e.Ye(k);I.free(h);I.free(k);h=I.allocAt(e.x+e.width/2,e.y+e.height/2);k=a.port.li();for(g=g.iterator;g.next();){var l=g.value;if(l.isVisible()&&
l.fromPort!==l.toPort){var m=l.fromPort===a.port||null!==l.fromNode&&l.fromNode.Td(d),n=l.computeSpot(m,a.port);if(n.$c()&&(m=m?l.toPort:l.fromPort,null!==m)){var p=m.part;if(null!==p){var q=p.findVisibleNode();null!==q&&q!==p&&(p=q,m=p.port);m=l.computeOtherPoint(p,m);p=h.ab(m);p-=k;0>p?p+=360:360<=p&&(p-=360);n=uq(e,n,p,l.isOrthogonal);0===n?(n=4,180<p&&(p-=360)):90===n?(n=8,270<p&&(p-=360)):180===n?n=2:(n=1,90>p&&(p+=360));q=a.pe[f];void 0===q?(q=new vq(l,p,n),a.pe[f]=q):(q.link=l,q.angle=p,q.Nc=
n);q.iv.set(m);f++}}}}I.free(h);a.pe.sort(Up.prototype.j);k=a.pe.length;d=-1;for(f=h=0;f<k;f++)g=a.pe[f],void 0!==g&&(g.Nc!==d&&(d=g.Nc,h=0),g.Uo=h,h++);d=-1;h=0;for(f=k-1;0<=f;f--)k=a.pe[f],void 0!==k&&(k.Nc!==d&&(d=k.Nc,h=k.Uo+1),k.Gl=h);f=a.pe;n=a.port;d=a.node.portSpreading;h=I.alloc();k=I.alloc();g=I.alloc();l=I.alloc();n.ga(sc,h);n.ga(uc,k);n.ga(Bc,g);n.ga(yc,l);q=p=m=n=0;if(d===Bp)for(var r=0;r<f.length;r++){var u=f[r];if(null!==u){var w=u.link.computeThickness();switch(u.Nc){case 8:p+=w;break;
case 2:q+=w;break;case 1:n+=w;break;default:case 4:m+=w}}}var v=r=0,y=1,z=u=0;for(w=0;w<f.length;w++){var A=f[w];if(null!==A){if(r!==A.Nc){r=A.Nc;switch(r){case 8:var B=g;v=l;break;case 2:B=l;v=h;break;case 1:B=h;v=k;break;default:case 4:B=k,v=g}u=v.x-B.x;z=v.y-B.y;switch(r){case 8:p>Math.abs(u)?(y=Math.abs(u)/p,p=Math.abs(u)):y=1;break;case 2:q>Math.abs(z)?(y=Math.abs(z)/q,q=Math.abs(z)):y=1;break;case 1:n>Math.abs(u)?(y=Math.abs(u)/n,n=Math.abs(u)):y=1;break;default:case 4:m>Math.abs(z)?(y=Math.abs(z)/
m,m=Math.abs(z)):y=1}v=0}var E=A.Zo;if(d===Bp){A=A.link.computeThickness();A*=y;E.set(B);switch(r){case 8:E.x=B.x+u/2+p/2-v-A/2;break;case 2:E.y=B.y+z/2+q/2-v-A/2;break;case 1:E.x=B.x+u/2-n/2+v+A/2;break;default:case 4:E.y=B.y+z/2-m/2+v+A/2}v+=A}else{var L=.5;d===mp&&(L=(A.Uo+1)/(A.Gl+1));E.x=B.x+u*L;E.y=B.y+z*L}}}I.free(h);I.free(k);I.free(g);I.free(l);B=a.pe;for(f=0;f<B.length;f++)d=B[f],null!==d&&(d.Ku=a.computeEndSegmentLength(d));a.ap=c;N.free(e)}c=a.pe}for(a=0;a<c.length;a++)if(e=c[a],null!==
e&&e.link===b)return e;return null}Up.prototype.j=function(a,b){return a===b?0:null===a?-1:null===b?1:a.Nc<b.Nc?-1:a.Nc>b.Nc?1:a.angle<b.angle?-1:a.angle>b.angle?1:0};
Up.prototype.computeEndSegmentLength=function(a){var b=a.link,c=b.computeEndSegmentLength(this.node,this.port,lc,b.fromPort===this.port),d=a.Uo;if(0>d)return c;var e=a.Gl;if(1>=e||!b.isOrthogonal)return c;b=a.iv;var f=a.Zo;if(2===a.Nc||8===a.Nc)d=e-1-d;return((a=2===a.Nc||4===a.Nc)?b.y<f.y:b.x<f.x)?c+8*d:(a?b.y===f.y:b.x===f.x)?c:c+8*(e-1-d)};Up.className="Knot";function vq(a,b,c){this.link=a;this.angle=b;this.Nc=c;this.iv=new I;this.Gl=this.Uo=0;this.Zo=new I;this.Ku=0}
vq.prototype.toString=function(){return this.link.toString()+" "+this.angle.toString()+" "+this.Nc.toString()+":"+this.Uo.toString()+"/"+this.Gl.toString()+" "+this.Zo.toString()+" "+this.Ku.toString()+" "+this.iv.toString()};vq.className="LinkInfo";function ti(a){Qa(this);this.o=this.H=null;this.Gh=this.sn=!0;this.yn=!1;this.Am=(new I(0,0)).freeze();this.vn=!0;this.Em=this.un=null;this.xn=!1;this.j=null;a&&Object.assign(this,a)}
ti.prototype.cloneProtected=function(a){a.sn=this.sn;a.Gh=this.Gh;a.yn=this.yn;a.Am.assign(this.Am);a.vn=this.vn;a.un=this.un;a.Em=this.Em;a.xn=!0};ti.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};ti.prototype.qb=function(){};ti.prototype.toString=function(){var a=Ha(this.constructor);a+="(";null!==this.group&&(a+=" in "+this.group);null!==this.diagram&&(a+=" for "+this.diagram);return a+")"};
ti.prototype.C=function(){if(this.isValidLayout){var a=this.diagram;if(null!==a&&!a.undoManager.isUndoingRedoing){var b=a.animationManager;!b.isTicking&&(b.defaultAnimation.isAnimating&&b.Cc(),this.isOngoing&&a.pi||this.isInitial&&!a.pi)&&(this.isValidLayout=!1,a.Rb())}}};ti.prototype.createNetwork=function(){return new wq(this)};ti.prototype.makeNetwork=function(a){var b=this.createNetwork();a instanceof Q?(b.lg(a.nodes,!0),b.lg(a.links,!0)):a instanceof jf?b.lg(a.memberParts):b.lg(a.iterator);return b};
ti.prototype.updateParts=function(){this.isValidLayout=!0;var a=this.diagram;if(null===a&&null!==this.network)for(var b=this.network.vertexes.iterator;b.next();){var c=b.value.node;if(null!==c&&(a=c.diagram,null!==a))break}try{null!==a&&a.Ea("Layout"),this.commitLayout()}finally{null!==a&&a.eb("Layout")}};ti.prototype.commitLayout=function(){if(null!==this.network){for(var a=this.network.vertexes.iterator;a.next();)a.value.commit();if(this.isRouting)for(a=this.network.edges.iterator;a.next();)a.value.commit()}};
ti.prototype.doLayout=function(a){var b=new H;a instanceof Q?(xq(this,b,a.nodes,!0,this.rh,!0,!1,!0),xq(this,b,a.parts,!0,this.rh,!0,!1,!0)):a instanceof jf?xq(this,b,a.memberParts,!1,this.rh,!0,!1,!0):b.addAll(a.iterator);0<b.count&&(a=this.diagram,null!==a&&a.Ea("Layout"),this.doMinimalNoNetworkLayout(b),null!==a&&a.eb("Layout"));this.isValidLayout=!0};
ti.prototype.doMinimalNoNetworkLayout=function(a){var b=Math.ceil(Math.sqrt(a.count));this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);var c=this.arrangementOrigin.x,d=c,e=this.arrangementOrigin.y,f=0,g=0;for(a=a.iterator;a.next();){var h=a.value;yq(h);var k=h.measuredBounds,l=k.width;k=k.height;h.moveTo(d,e);h instanceof jf&&(h.rh=!1);d+=Math.max(l,50)+20;g=Math.max(g,Math.max(k,50));f>=b-1?(f=0,d=c,e+=g+20,g=0):f++}};
ti.prototype.doMinimalNetworkLayout=function(){var a=Math.ceil(Math.sqrt(this.network.vertexes.count));this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);for(var b=this.arrangementOrigin.x,c=b,d=this.arrangementOrigin.y,e=0,f=0,g=this.network.vertexes.iterator;g.next();){var h=g.value.node;if(!(null===h||h instanceof T)&&(yq(h),this.rh(h))){var k=h.measuredBounds,l=k.width;k=k.height;h.moveTo(c,d);h instanceof jf&&(h.rh=!1);c+=Math.max(l,50)+20;f=Math.max(f,Math.max(k,50));e>=a-1?(e=
0,c=b,d+=f+20,f=0):e++}}};ti.prototype.rh=function(a){return!a.location.w()||a instanceof jf&&a.rh?!0:!1};function xq(a,b,c,d,e,f,g,h){for(c=c.iterator;c.next();){var k=c.value;d&&!k.isTopLevel||null!==e&&!e(k)||!k.canLayout()||(f&&k instanceof V?k.isLinkLabel||(k instanceof jf?null===k.layout?xq(a,b,k.memberParts,!1,e,f,g,h):(yq(k),b.add(k)):(yq(k),b.add(k))):g&&k instanceof T?b.add(k):!h||!k.Zc()||k instanceof V||(yq(k),b.add(k)))}}
function yq(a){var b=a.actualBounds;(0===b.width||0===b.height||isNaN(b.width)||isNaN(b.height))&&a.Va()}ti.prototype.ni=function(a,b){var c=this.boundsComputation;if(null!==c)return b||(b=new N),c(a,this,b);if(!b)return a.actualBounds;b.set(a.actualBounds);return b};
ti.prototype.gx=function(a){var b=new H;a instanceof Q?(xq(this,b,a.nodes,!0,null,!0,!0,!0),xq(this,b,a.links,!0,null,!0,!0,!0),xq(this,b,a.parts,!0,null,!0,!0,!0)):a instanceof jf?xq(this,b,a.memberParts,!1,null,!0,!0,!0):xq(this,b,a.iterator,!1,null,!0,!0,!0);return b};
ti.prototype.initialOrigin=function(a){var b=this.group;if(null!==b){var c=b.position.copy();(isNaN(c.x)||isNaN(c.y))&&c.set(a);b=b.placeholder;null!==b&&(c=b.ga(sc),(isNaN(c.x)||isNaN(c.y))&&c.set(a),a=b.padding,c.x+=a.left,c.y+=a.top);return c}return a};
ma.Object.defineProperties(ti.prototype,{diagram:{get:function(){return this.H},set:function(a){this.H=a}},group:{get:function(){return this.o},set:function(a){this.o!==a&&(this.o=a,null!==a&&(this.H=a.diagram))}},isOngoing:{get:function(){return this.sn},set:function(a){this.sn!==a&&(this.sn=a)}},isInitial:{get:function(){return this.Gh},set:function(a){this.Gh=a;a||(this.xn=!0)}},
isViewportSized:{get:function(){return this.yn},set:function(a){this.yn!==a&&(this.yn=a)&&this.C()}},isRouting:{get:function(){return this.vn},set:function(a){this.vn!==a&&(this.vn=a)}},isRealtime:{get:function(){return this.un},set:function(a){this.un!==a&&(this.un=a)}},isValidLayout:{get:function(){return this.xn},set:function(a){this.xn!==a&&(this.xn=a,a||(a=this.diagram,null!==
a&&(a.xh=!0)))}},network:{get:function(){return this.j},set:function(a){this.j!==a&&(this.j=a,null!==a&&(a.layout=this))}},boundsComputation:{get:function(){return this.Em},set:function(a){this.Em!==a&&(this.Em=a,this.C())}},arrangementOrigin:{get:function(){return this.Am},set:function(a){this.Am.D(a)||(this.Am.assign(a),this.C())}}});ti.prototype.collectParts=ti.prototype.gx;ti.prototype.getLayoutBounds=ti.prototype.ni;
ti.prototype.invalidateLayout=ti.prototype.C;ti.className="Layout";function wq(a){Qa(this);this.Sc=a;this.wf=new H;this.ye=new H;this.Es=new ob;this.zs=new ob}wq.prototype.clear=function(){if(this.wf)for(var a=this.wf.iterator;a.next();)a.value.clear();if(this.ye)for(a=this.ye.iterator;a.next();)a.value.clear();this.wf=new H;this.ye=new H;this.Es=new ob;this.zs=new ob};
wq.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutNetwork"+(null!==this.layout?"("+this.layout.toString()+")":"");if(0>=a)return b;b+=" vertexes: "+this.wf.count+" edges: "+this.ye.count;if(1<a){for(var c=this.wf.iterator;c.next();)b+="\n    "+c.value.toString(a-1);for(c=this.ye.iterator;c.next();)b+="\n    "+c.value.toString(a-1)}return b};wq.prototype.createVertex=function(){return new zq(this)};wq.prototype.createEdge=function(){return new Aq(this)};
wq.prototype.lg=function(a,b,c){if(null!==a){void 0===b&&(b=!1);void 0===c&&(c=null);null===c&&(c=function(a){if(a instanceof V)return!a.isLinkLabel;if(a instanceof T){var b=a.fromNode;if(null===b||b.isLinkLabel)return!1;a=a.toNode;return null===a||a.isLinkLabel?!1:!0}return!1});for(a=a.iterator;a.next();){var d=a.value;if(d instanceof V&&(!b||d.isTopLevel)&&d.canLayout()&&c(d))if(d instanceof jf&&null===d.layout)this.lg(d.memberParts,!1);else if(null===this.ki(d)){var e=this.createVertex();e.node=
d;this.dh(e)}}for(a.reset();a.next();)if(d=a.value,d instanceof T&&(!b||d.isTopLevel)&&d.canLayout()&&c(d)&&null===this.Lo(d)){var f=d.fromNode;e=d.toNode;null!==f&&null!==e&&f!==e&&(f=this.findGroupVertex(f),e=this.findGroupVertex(e),null!==f&&null!==e&&this.Yj(f,e,d))}}};wq.prototype.findGroupVertex=function(a){if(null===a)return null;var b=a.findVisibleNode();if(null===b)return null;a=this.ki(b);if(null!==a)return a;for(b=b.containingGroup;null!==b;){a=this.ki(b);if(null!==a)return a;b=b.containingGroup}return null};
t=wq.prototype;t.dh=function(a){if(null!==a){this.wf.add(a);var b=a.node;null!==b&&this.Es.add(b,a);a.network=this}};t.Bl=function(a){if(null===a)return null;var b=this.ki(a);null===b&&(b=this.createVertex(),b.node=a,this.dh(b));return b};t.Ju=function(a){if(null!==a&&Bq(this,a)){for(var b=a.tg,c=b.count-1;0<=c;c--){var d=b.M(c);this.Lj(d)}b=a.mg;for(a=b.count-1;0<=a;a--)c=b.M(a),this.Lj(c)}};
function Bq(a,b){if(null===b)return!1;var c=a.wf.remove(b);c&&(b=b.node,null!==b&&a.Es.remove(b));return c}t.$y=function(a){null!==a&&(a=this.ki(a),null!==a&&this.Ju(a))};t.ki=function(a){return null===a?null:this.Es.I(a)};t.Bo=function(a){if(null!==a){Cq(this,a);var b=a.toVertex;null!==b&&b.Cl(a);b=a.fromVertex;null!==b&&b.zl(a)}};function Cq(a,b){if(null!==b){a.ye.add(b);var c=b.link;null!==c&&null===a.Lo(c)&&a.zs.add(c,b);b.network=a}}
t.Ey=function(a){if(null===a)return null;var b=a.fromNode,c=a.toNode,d=this.Lo(a);null===d?(d=this.createEdge(),d.link=a,null!==b&&(d.fromVertex=this.Bl(b)),null!==c&&(d.toVertex=this.Bl(c)),this.Bo(d)):(null!==b?d.fromVertex=this.Bl(b):d.fromVertex=null,null!==c?d.toVertex=this.Bl(c):d.toVertex=null);return d};t.Lj=function(a){if(null!==a){var b=a.toVertex;null!==b&&b.Kl(a);b=a.fromVertex;null!==b&&b.Jl(a);Dq(this,a)}};
function Dq(a,b){null!==b&&a.ye.remove(b)&&(b=b.link,null!==b&&a.zs.remove(b))}t.Zy=function(a){null!==a&&(a=this.Lo(a),null!==a&&this.Lj(a))};t.Lo=function(a){return null===a?null:this.zs.I(a)};t.Yj=function(a,b,c){if(null===a||null===b)return null;if(a.network===this&&b.network===this){var d=this.createEdge();d.link=c;d.fromVertex=a;d.toVertex=b;this.Bo(d);return d}return null};t.gm=function(a){if(null!==a){var b=a.fromVertex,c=a.toVertex;null!==b&&null!==c&&(b.Jl(a),c.Kl(a),a.gm(),b.Cl(a),c.zl(a))}};
t.Ko=function(){for(var a=Ea(),b=this.ye.iterator;b.next();){var c=b.value;c.fromVertex===c.toVertex&&a.push(c)}b=a.length;for(c=0;c<b;c++)this.Lj(a[c]);Ga(a)};wq.prototype.deleteArtificialVertexes=function(){for(var a=Ea(),b=this.wf.iterator;b.next();){var c=b.value;null===c.node&&null===c.data&&a.push(c)}c=a.length;for(b=0;b<c;b++)this.Ju(a[b]);b=Ea();for(c=this.ye.iterator;c.next();){var d=c.value;null===d.link&&null===d.data&&b.push(d)}c=b.length;for(d=0;d<c;d++)this.Lj(b[d]);Ga(a);Ga(b)};
function Eq(a){for(var b=Ea(),c=a.ye.iterator;c.next();){var d=c.value;null!==d.fromVertex&&null!==d.toVertex||b.push(d)}c=b.length;for(d=0;d<c;d++)a.Lj(b[d]);Ga(b)}
wq.prototype.$x=function(a){void 0===a&&(a=!0);a&&(this.deleteArtificialVertexes(),Eq(this),this.Ko());a=new F;for(var b=!0;b;){b=!1;for(var c=this.wf.iterator;c.next();){var d=c.value;if(0<d.tg.count||0<d.mg.count){b=this.layout.createNetwork();a.add(b);Fq(this,b,d);b=!0;break}}}a.sort(function(a,b){return null===a||null===b||a===b?0:b.vertexes.count-a.vertexes.count});return a};
function Fq(a,b,c){if(null!==c&&c.network!==b){Bq(a,c);b.dh(c);for(var d=c.sourceEdges;d.next();){var e=d.value;e.network!==b&&(Dq(a,e),Cq(b,e),Fq(a,b,e.fromVertex))}for(d=c.destinationEdges;d.next();)c=d.value,c.network!==b&&(Dq(a,c),Cq(b,c),Fq(a,b,c.toVertex))}}wq.prototype.ez=function(){for(var a=new H,b=this.wf.iterator;b.next();)a.add(b.value.node);for(b=this.ye.iterator;b.next();)a.add(b.value.link);return a};
ma.Object.defineProperties(wq.prototype,{layout:{get:function(){return this.Sc},set:function(a){null!==a&&(this.Sc=a)}},vertexes:{get:function(){return this.wf}},edges:{get:function(){return this.ye}}});wq.prototype.findAllParts=wq.prototype.ez;wq.prototype.splitIntoSubNetworks=wq.prototype.$x;wq.prototype.deleteSelfEdges=wq.prototype.Ko;wq.prototype.reverseEdge=wq.prototype.gm;wq.prototype.linkVertexes=wq.prototype.Yj;
wq.prototype.findEdge=wq.prototype.Lo;wq.prototype.deleteLink=wq.prototype.Zy;wq.prototype.deleteEdge=wq.prototype.Lj;wq.prototype.addLink=wq.prototype.Ey;wq.prototype.addEdge=wq.prototype.Bo;wq.prototype.findVertex=wq.prototype.ki;wq.prototype.deleteNode=wq.prototype.$y;wq.prototype.deleteVertex=wq.prototype.Ju;wq.prototype.addNode=wq.prototype.Bl;wq.prototype.addVertex=wq.prototype.dh;wq.prototype.addParts=wq.prototype.lg;wq.className="LayoutNetwork";
function zq(a){Qa(this);this.ob=a;this.j=(new N(0,0,10,10)).freeze();this.o=(new I(5,5)).freeze();this.Sh=this.ub=null;this.tg=new F;this.mg=new F}zq.prototype.clear=function(){this.Sh=this.ub=null;this.tg=new F;this.mg=new F};
zq.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutVertex#"+bb(this);if(0<a&&(b+=null!==this.node?"("+this.node.toString()+")":"",1<a)){a="";for(var c=!0,d=this.tg.iterator;d.next();){var e=d.value;c?c=!1:a+=",";a+=e.toString(0)}e="";c=!0;for(d=this.mg.iterator;d.next();){var f=d.value;c?c=!1:e+=",";e+=f.toString(0)}b+=" sources: "+a+" destinations: "+e}return b};
zq.prototype.commit=function(){var a=this.ub;if(null!==a){var b=this.bounds,c=a.bounds;za(c)?(c.x=b.x,c.y=b.y,c.width=b.width,c.height=b.height):a.bounds=b.copy()}else if(a=this.node,null!==a){b=this.bounds;if(!(a instanceof jf)){c=N.alloc();a.Va();var d=this.network.layout.ni(a,c),e=a.locationObject.ga(wc);if(d.w()&&e.w()){a.moveTo(b.x+this.focusX-(e.x-d.x),b.y+this.focusY-(e.y-d.y));N.free(c);return}N.free(c)}a.moveTo(b.x,b.y)}};zq.prototype.Cl=function(a){null!==a&&(this.tg.contains(a)||this.tg.add(a))};
zq.prototype.Kl=function(a){null!==a&&this.tg.remove(a)};zq.prototype.zl=function(a){null!==a&&(this.mg.contains(a)||this.mg.add(a))};zq.prototype.Jl=function(a){null!==a&&this.mg.remove(a)};function Gq(a,b){a=a.Sh;b=b.Sh;return a?b?(a=a.text,b=b.text,a<b?-1:a>b?1:0):1:null!==b?-1:0}
ma.Object.defineProperties(zq.prototype,{sourceEdgesArrayAccess:{get:function(){return this.tg._dataArray}},destinationEdgesArrayAccess:{get:function(){return this.mg._dataArray}},data:{get:function(){return this.ub},set:function(a){this.ub=a;if(null!==a&&a.bounds){var b=a.bounds;a=b.x;var c=b.y,d=b.width;b=b.height;this.o.h(d/2,b/2);this.j.h(a,c,d,b)}}},node:{get:function(){return this.Sh},
set:function(a){if(this.Sh!==a){this.Sh=a;a.Va();var b=this.network.layout,c=N.alloc(),d=b.ni(a,c);b=d.x;var e=d.y,f=d.width;d=d.height;isNaN(b)&&(b=0);isNaN(e)&&(e=0);this.j.h(b,e,f,d);N.free(c);if(!(a instanceof jf)&&(a=a.locationObject.ga(wc),a.w())){this.o.h(a.x-b,a.y-e);return}this.o.h(f/2,d/2)}}},bounds:{get:function(){return this.j},set:function(a){this.j.D(a)||this.j.assign(a)}},focus:{get:function(){return this.o},set:function(a){this.o.D(a)||
this.o.assign(a)}},centerX:{get:function(){return this.j.x+this.o.x},set:function(a){var b=this.j;b.x+this.o.x!==a&&(b.ka(),b.x=a-this.o.x,b.freeze())}},centerY:{get:function(){return this.j.y+this.o.y},set:function(a){var b=this.j;b.y+this.o.y!==a&&(b.ka(),b.y=a-this.o.y,b.freeze())}},focusX:{get:function(){return this.o.x},set:function(a){var b=this.o;b.x!==a&&(b.ka(),b.x=a,b.freeze())}},focusY:{
get:function(){return this.o.y},set:function(a){var b=this.o;b.y!==a&&(b.ka(),b.y=a,b.freeze())}},x:{get:function(){return this.j.x},set:function(a){var b=this.j;b.x!==a&&(b.ka(),b.x=a,b.freeze())}},y:{get:function(){return this.j.y},set:function(a){var b=this.j;b.y!==a&&(b.ka(),b.y=a,b.freeze())}},width:{get:function(){return this.j.width},set:function(a){var b=this.j;b.width!==a&&(b.ka(),b.width=
a,b.freeze())}},height:{get:function(){return this.j.height},set:function(a){var b=this.j;b.height!==a&&(b.ka(),b.height=a,b.freeze())}},network:{get:function(){return this.ob},set:function(a){this.ob=a}},sourceVertexes:{get:function(){for(var a=new H,b=this.sourceEdges;b.next();)a.add(b.value.fromVertex);return a.iterator}},destinationVertexes:{get:function(){for(var a=new H,b=
this.destinationEdges;b.next();)a.add(b.value.toVertex);return a.iterator}},vertexes:{get:function(){for(var a=new H,b=this.sourceEdges;b.next();)a.add(b.value.fromVertex);for(b=this.destinationEdges;b.next();)a.add(b.value.toVertex);return a.iterator}},sourceEdges:{get:function(){return this.tg.iterator}},destinationEdges:{get:function(){return this.mg.iterator}},edges:{get:function(){for(var a=
new F,b=this.sourceEdges;b.next();)a.add(b.value);for(b=this.destinationEdges;b.next();)a.add(b.value);return a.iterator}},edgesCount:{get:function(){return this.tg.count+this.mg.count}}});zq.prototype.deleteDestinationEdge=zq.prototype.Jl;zq.prototype.addDestinationEdge=zq.prototype.zl;zq.prototype.deleteSourceEdge=zq.prototype.Kl;zq.prototype.addSourceEdge=zq.prototype.Cl;zq.className="LayoutVertex";zq.standardComparer=Gq;
zq.smartComparer=function(a,b){if(null!==a){if(null!==b){a=a.Sh;var c=b.Sh;if(null!==a){if(null!==c){b=a.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/);a=c.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/);for(c=0;c<b.length;c++)if(""!==a[c]&&void 0!==a[c]){var d=parseFloat(b[c]),e=parseFloat(a[c]);if(isNaN(d))if(isNaN(e)){if(0!==b[c].localeCompare(a[c]))return b[c].localeCompare(a[c])}else return 1;else{if(isNaN(e))return-1;if(0!==d-e)return d-e}}else if(""!==
b[c])return 1;return""!==a[c]&&void 0!==a[c]?-1:0}return 1}return null!==c?-1:0}return 1}return null!==b?-1:0};function Aq(a){Qa(this);this.nb=a;this.qd=this.kd=this.Zk=this.ub=null}Aq.prototype.clear=function(){this.qd=this.kd=this.Zk=this.ub=null};Aq.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutEdge#"+bb(this);0<a&&(b+=null!==this.Zk?"("+this.Zk.toString()+")":"",1<a&&(b+=" "+(this.kd?this.kd.toString():"null")+" --\x3e "+(this.qd?this.qd.toString():"null")));return b};
Aq.prototype.gm=function(){var a=this.kd;this.kd=this.qd;this.qd=a};Aq.prototype.commit=function(){};Aq.prototype.Bx=function(a){return this.qd===a?this.kd:this.kd===a?this.qd:null};
ma.Object.defineProperties(Aq.prototype,{network:{get:function(){return this.nb},set:function(a){this.nb=a}},data:{get:function(){return this.ub},set:function(a){this.ub!==a&&(this.ub=a)}},link:{get:function(){return this.Zk},set:function(a){this.Zk!==a&&(this.Zk=a)}},fromVertex:{get:function(){return this.kd},set:function(a){this.kd!==a&&(this.kd=a)}},toVertex:{
get:function(){return this.qd},set:function(a){this.qd!==a&&(this.qd=a)}}});Aq.prototype.getOtherVertex=Aq.prototype.Bx;Aq.className="LayoutEdge";function Ik(a){ti.call(this);this.isViewportSized=!0;this.yo=this.zo=NaN;this.yg=(new M(NaN,NaN)).freeze();this.tf=(new M(10,10)).freeze();this.Kb=Hq;this.Tb=Iq;this.nd=Jq;this.hd=Kq;a&&Object.assign(this,a)}la(Ik,ti);
Ik.prototype.cloneProtected=function(a){ti.prototype.cloneProtected.call(this,a);a.zo=this.zo;a.yo=this.yo;a.yg.assign(this.yg);a.tf.assign(this.tf);a.Kb=this.Kb;a.Tb=this.Tb;a.nd=this.nd;a.hd=this.hd};Ik.prototype.qb=function(a){a.classType===Ik?a===Jq||a===Lq||a===Mq||a===Nq?this.sorting=a:a===Iq||a===Oq?this.arrangement=a:a===Hq||a===Pq?this.alignment=a:C("Unknown enum value: "+a):ti.prototype.qb.call(this,a)};
Ik.prototype.doLayout=function(a){this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);var b=this.gx(a);a=this.diagram;for(var c=b.copy().iterator;c.next();){var d=c.value;if(!d.ph()||null===d.fromNode&&null===d.toNode){if(d.Va(),d instanceof jf)for(d=d.memberParts;d.next();)b.remove(d.value)}else b.remove(d)}var e=b.Fa();if(0!==e.length){switch(this.sorting){case Nq:e.reverse();break;case Jq:e.sort(this.comparer);break;case Lq:e.sort(this.comparer),e.reverse()}var f=this.wrappingColumn;
isNaN(f)&&(f=0);var g=this.wrappingWidth;isNaN(g)&&null!==a?(b=a.padding,g=Math.max(a.viewportBounds.width-b.left-b.right,0)):g=Math.max(this.wrappingWidth,0);0>=f&&0>=g&&(f=1);b=this.spacing.width;isFinite(b)||(b=0);c=this.spacing.height;isFinite(c)||(c=0);null!==a&&a.Ea("Layout");d=[];switch(this.alignment){case Pq:var h=b,k=c,l=N.alloc(),m=Math.max(this.cellSize.width,1);if(!isFinite(m))for(var n=m=0;n<e.length;n++){var p=this.ni(e[n],l);m=Math.max(m,p.width)}m=Math.max(m+h,1);n=Math.max(this.cellSize.height,
1);if(!isFinite(n))for(p=n=0;p<e.length;p++){var q=this.ni(e[p],l);n=Math.max(n,q.height)}n=Math.max(n+k,1);p=this.arrangement;for(var r=q=this.arrangementOrigin.x,u=this.arrangementOrigin.y,w=0,v=0,y=0;y<e.length;y++){var z=e[y],A=this.ni(z,l),B=Math.ceil((A.width+h)/m)*m,E=Math.ceil((A.height+k)/n)*n;switch(p){case Oq:var L=Math.abs(r-A.width);break;default:L=r+A.width}if(0<f&&w>f-1||0<g&&0<w&&L-q>g)d.push(new N(0,u,g+h,v)),w=0,r=q,u+=v,v=0;v=Math.max(v,E);switch(p){case Oq:A=-A.width;break;default:A=
0}z.moveTo(r+A,u);switch(p){case Oq:r-=B;break;default:r+=B}w++}d.push(new N(0,u,g+h,v));N.free(l);break;case Hq:k=g;m=f;n=b;p=c;g=N.alloc();q=Math.max(this.cellSize.width,1);f=u=l=0;h=I.alloc();for(r=0;r<e.length;r++)v=e[r],w=this.ni(v,g),v=Yo(v,v.locationObject,v.locationSpot,h),l=Math.max(l,v.x),u=Math.max(u,w.width-v.x),f=Math.max(f,v.y);r=this.arrangement;switch(r){case Oq:l+=n;break;default:u+=n}q=isFinite(q)?Math.max(q+n,1):Math.max(l+u,1);var R=v=this.arrangementOrigin.x;y=this.arrangementOrigin.y;
u=0;k>=l&&(k-=l);l=z=0;B=Math.max(this.cellSize.height,1);A=f=0;E=!0;w=I.alloc();for(L=0;L<e.length;L++){var S=e[L],J=this.ni(S,g),G=Yo(S,S.locationObject,S.locationSpot,h);if(0<u)switch(r){case Oq:R=(R-v-(J.width-G.x))/q;R=K.$(Math.round(R),R)?Math.round(R):Math.floor(R);R=R*q+v;break;default:R=(R-v+G.x)/q,R=K.$(Math.round(R),R)?Math.round(R):Math.ceil(R),R=R*q+v}else switch(r){case Oq:z=R+G.x+J.width;break;default:z=R-G.x}switch(r){case Oq:var ca=-(R+G.x)+z;break;default:ca=R+J.width-G.x-z}if(0<
m&&u>m-1||0<k&&0<u&&ca>k){d.push(new N(0,E?y-f:y,k+n,A+f+p));for(R=0;R<u&&L!==u;R++){ca=e[L-u+R];var X=Yo(ca,ca.locationObject,ca.locationSpot,w);ca.moveTo(ca.position.x,ca.position.y+f-X.y)}A+=p;y=E?y+A:y+(A+f);u=A=f=0;R=v;E=!1}R===v&&(l=r===Oq?Math.max(l,J.width-G.x):Math.min(l,-G.x));f=Math.max(f,G.y);A=Math.max(A,J.height-G.y);isFinite(B)&&(A=Math.max(A,Math.max(J.height,B)-G.y));E?S.moveTo(R-G.x,y-G.y):S.moveTo(R-G.x,y);switch(r){case Oq:R-=G.x+n;break;default:R+=J.width-G.x+n}u++}d.push(new N(0,
y,k+n,(E?A:A+f)+p));if(e.length!==u)for(k=0;k<u;k++)m=e[e.length-u+k],n=Yo(m,m.locationObject,m.locationSpot,h),m.moveTo(m.position.x,m.position.y+f-n.y);I.free(h);I.free(w);if(r===Oq)for(e=0;e<d.length;e++)f=d[e],f.width+=l,f.x-=l;else for(e=0;e<d.length;e++)f=d[e],f.x>l&&(f.width+=f.x-l,f.x=l);N.free(g)}for(h=f=g=e=0;h<d.length;h++)k=d[h],e=Math.min(e,k.x),g=Math.min(g,k.y),f=Math.max(f,k.x+k.width);this.arrangement===Oq?this.commitLayers(d,new I(e+b/2-(f+e),g-c/2)):this.commitLayers(d,new I(e-
b/2,g-c/2));null!==a&&a.eb("Layout");this.isValidLayout=!0}};Ik.prototype.commitLayers=function(){};function Kq(a,b){a=a.text;b=b.text;return a<b?-1:a>b?1:0}
ma.Object.defineProperties(Ik.prototype,{wrappingWidth:{get:function(){return this.zo},set:function(a){this.zo!==a&&(0<a||isNaN(a))&&(this.zo=a,this.isViewportSized=isNaN(a),this.C())}},wrappingColumn:{get:function(){return this.yo},set:function(a){this.yo!==a&&(0<a||isNaN(a))&&(this.yo=a,this.C())}},cellSize:{get:function(){return this.yg},set:function(a){this.yg.D(a)||(this.yg.assign(a),this.C())}},spacing:{
get:function(){return this.tf},set:function(a){this.tf.D(a)||(this.tf.assign(a),this.C())}},alignment:{get:function(){return this.Kb},set:function(a){this.Kb===a||a!==Hq&&a!==Pq||(this.Kb=a,this.C())}},arrangement:{get:function(){return this.Tb},set:function(a){this.Tb===a||a!==Iq&&a!==Oq||(this.Tb=a,this.C())}},sorting:{get:function(){return this.nd},set:function(a){this.nd===a||a!==Mq&&a!==Nq&&
a!==Jq&&a!==Lq||(this.nd=a,this.C())}},comparer:{get:function(){return this.hd},set:function(a){this.hd!==a&&(this.hd=a,this.C())}}});var Pq=new D(Ik,"Position",0),Hq=new D(Ik,"Location",1),Iq=new D(Ik,"LeftToRight",2),Oq=new D(Ik,"RightToLeft",3),Mq=new D(Ik,"Forward",4),Nq=new D(Ik,"Reverse",5),Jq=new D(Ik,"Ascending",6),Lq=new D(Ik,"Descending",7);Ik.className="GridLayout";Ik.standardComparer=Kq;
Ik.smartComparer=function(a,b){if(null!==a){if(null!==b){a=a.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/);b=b.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/);for(var c=0;c<a.length;c++)if(""!==b[c]&&void 0!==b[c]){var d=parseFloat(a[c]),e=parseFloat(b[c]);if(isNaN(d))if(isNaN(e)){if(0!==a[c].localeCompare(b[c]))return a[c].localeCompare(b[c])}else return 1;else{if(isNaN(e))return-1;if(0!==d-e)return d-e}}else if(""!==a[c])return 1;return""!==b[c]&&
void 0!==b[c]?-1:0}return 1}return null!==b?-1:0};Ik.Position=Pq;Ik.Location=Hq;Ik.LeftToRight=Iq;Ik.RightToLeft=Oq;Ik.Forward=Mq;Ik.Reverse=Nq;Ik.Ascending=Jq;Ik.Descending=Lq;function pi(){this.On=new H;this.Dn=new H;this.Pa=new H;this.cf=new ob;this.bf=new ob;this.aj=new ob;this.H=null;this.tp=!1}t=pi.prototype;t.clear=function(){this.On.clear();this.Dn.clear();this.Pa.clear();this.cf.clear();this.bf.clear();this.aj.clear()};t.Xe=function(a){this.H=a};
t.oi=function(a){if(a instanceof V){if(this.On.add(a),a instanceof jf){var b=a.containingGroup;null===b?this.H.ai.add(a):b.fl.add(a);b=a.layout;null!==b&&(b.diagram=this.H)}}else a instanceof T?this.Dn.add(a):a instanceof pe||this.Pa.add(a);b=a.data;null===b||a instanceof pe||(a instanceof T?this.bf.add(b,a):this.cf.add(b,a))};
t.qc=function(a){a.Dj();if(a instanceof V){if(this.On.remove(a),a instanceof jf){var b=a.containingGroup;null===b?this.H.ai.remove(a):b.fl.remove(a);b=a.layout;null!==b&&(b.diagram=null)}}else a instanceof T?this.Dn.remove(a):a instanceof pe||this.Pa.remove(a);b=a.data;null===b||a instanceof pe||(a instanceof T?this.bf.remove(b):this.cf.remove(b))};
t.Wd=function(){var a=this.H;if(null!==a){for(var b=a.nodeTemplateMap.iterator;b.next();){var c=b.value,d=b.key;(!c.Zc()||c instanceof jf)&&C('Invalid node template in Diagram.nodeTemplateMap: template for "'+d+'" must be a Node or a simple Part, not a Group or Link: '+c)}for(b=a.groupTemplateMap.iterator;b.next();)c=b.value,d=b.key,c instanceof jf||C('Invalid group template in Diagram.groupTemplateMap: template for "'+d+'" must be a Group, not a normal Node or Link: '+c);for(b=a.linkTemplateMap.iterator;b.next();)c=
b.value,d=b.key,c instanceof T||C('Invalid link template in Diagram.linkTemplateMap: template for "'+d+'" must be a Link, not a normal Node or simple Part: '+c);b=Ea();for(c=a.selection.iterator;c.next();)(d=c.value.data)&&b.push(d);c=Ea();for(a=a.highlighteds.iterator;a.next();)(d=a.value.data)&&c.push(d);a=Ea();for(d=this.nodes.iterator;d.next();){var e=d.value;null!==e.data&&(a.push(e.data),a.push(e.location))}for(d=this.links.iterator;d.next();)e=d.value,null!==e.data&&(a.push(e.data),a.push(e.location));
for(d=this.parts.iterator;d.next();)e=d.value,null!==e.data&&(a.push(e.data),a.push(e.location));this.removeAllModeledParts();this.addAllModeledParts();for(d=0;d<b.length;d++)e=this.Lc(b[d]),null!==e&&(e.isSelected=!0);for(d=0;d<c.length;d++)e=this.Lc(c[d]),null!==e&&(e.isHighlighted=!0);for(d=0;d<a.length;d+=2)e=this.Lc(a[d]),null!==e&&(e.location=a[d+1]);Ga(b);Ga(c);Ga(a)}};pi.prototype.addAllModeledParts=function(){this.addModeledParts(this.diagram.model.nodeDataArray)};
pi.prototype.addModeledParts=function(a,b){var c=this,d=this.diagram.model;a.forEach(function(a){d.lb(a)&&Qq(c,a,!1)});a.forEach(function(a){d.lb(a)&&c.resolveReferencesForData(a)});!1!==b&&kk(this.diagram,!1)};
function Qq(a,b,c){if(void 0!==b&&null!==b&&!a.diagram.undoManager.isUndoingRedoing&&!a.cf.contains(b)){void 0===c&&(c=!0);a:{if(void 0!==b&&null!==b&&!a.H.undoManager.isUndoingRedoing&&!a.cf.contains(b)){var d=a.Ro(b);var e=jp(a,b,d);if(null!==e&&(jg(e),e=e.copy(),null!==e)){var f=a.diagram.skipsModelSourceBindings;a.diagram.skipsModelSourceBindings=!0;e.Tf=d;e.ub=b;a.tp&&(e.Jg="Tool");a.diagram.add(e);e.ub=null;e.data=b;a.diagram.skipsModelSourceBindings=f;d=e;break a}}d=null}null!==d&&c&&a.resolveReferencesForData(b)}}
pi.prototype.insertLink=function(){return null};pi.prototype.resolveReferencesForData=function(){};pi.prototype.Ro=function(a){return this.H.model.Ro(a)};
function jp(a,b,c){a=a.H;var d=a.model;d.Wj()&&d.Zu(b)?(b=a.groupTemplateMap.I(c),null===b&&(b=a.groupTemplateMap.I(""),null===b&&(Rq||(Rq=!0,ya('No Group template found for category "'+c+'"'),ya("  Using default group template")),b=a.my))):(b=a.nodeTemplateMap.I(c),null===b&&(b=a.nodeTemplateMap.I(""),null===b&&(Sq||(Sq=!0,ya('No Node template found for category "'+c+'"'),ya("  Using default node template")),b=a.oy)));return b}pi.prototype.getLinkCategoryForData=function(){return""};
pi.prototype.setLinkCategoryForData=function(){};pi.prototype.setFromNodeForLink=function(){};pi.prototype.setToNodeForLink=function(){};pi.prototype.findLinkTemplateForCategory=function(a){var b=this.H,c=b.linkTemplateMap.I(a);null===c&&(c=b.linkTemplateMap.I(""),null===c&&(Tq||(Tq=!0,ya('No Link template found for category "'+a+'"'),ya("  Using default link template")),c=b.ny));return c};pi.prototype.removeAllModeledParts=function(){this.Gs(this.diagram.model.nodeDataArray)};
pi.prototype.Gs=function(a){var b=this;a.forEach(function(a){b.ip(a)})};pi.prototype.ip=function(a){a=this.Lc(a);null!==a&&(Sj(this.diagram,a,!1),this.unresolveReferencesForPart(a))};pi.prototype.unresolveReferencesForPart=function(){};pi.prototype.removeDataForLink=function(){};pi.prototype.findPartForKey=function(a){if(null===a||void 0===a)return null;a=this.H.model.ic(a);return null!==a?this.cf.I(a):null};
pi.prototype.Xb=function(a){if(null===a||void 0===a)return null;a=this.H.model.ic(a);if(null===a)return null;a=this.cf.I(a);return a instanceof V?a:null};pi.prototype.findLinkForKey=function(){return null};t=pi.prototype;t.Lc=function(a){if(null===a)return null;var b=this.cf.I(a);return null!==b?b:b=this.bf.I(a)};t.ii=function(a){if(null===a)return null;a=this.cf.I(a);return a instanceof V?a:null};t.Kc=function(a){return null===a?null:this.bf.I(a)};
t.ms=function(a){for(var b=0;b<arguments.length;++b);b=new H;for(var c=this.On.iterator;c.next();){var d=c.value,e=d.data;if(null!==e)for(var f=0;f<arguments.length;f++){var g=arguments[f];if(za(g)&&Uq(this,e,g)){b.add(d);break}}}return b.iterator};t.ls=function(a){for(var b=0;b<arguments.length;++b);b=new H;for(var c=this.Dn.iterator;c.next();){var d=c.value,e=d.data;if(null!==e)for(var f=0;f<arguments.length;f++){var g=arguments[f];if(za(g)&&Uq(this,e,g)){b.add(d);break}}}return b.iterator};
function Uq(a,b,c){for(var d in c){var e=b[d],f=c[d];if(Aa(f)){if(!Aa(e)||e.length<f.length)return!1;for(var g=0;g<e.length;g++){var h=f[g];if(void 0!==h&&!Vq(a,e[g],h))return!1}}else if(!Vq(a,e,f))return!1}return!0}function Vq(a,b,c){if("function"===typeof c){if(!c(b))return!1}else if(c instanceof RegExp){if(!b||!c.test(b.toString()))return!1}else if(za(b)&&za(c)){if(!Uq(a,b,c))return!1}else if(b!==c)return!1;return!0}
pi.prototype.doModelChanged=function(a){if(this.H){var b=this.H;if(a.model===b.model){var c=a.change;b.doModelChanged(a);if(b.aa){b.aa=!1;try{var d=a.modelChange;if(""!==d)if(c===de){if("nodeCategory"===d){var e=this.Lc(a.object),f=a.newValue;null!==e&&"string"===typeof f&&(e.category=f)}else"nodeDataArray"===d&&(this.Gs(a.oldValue),this.addModeledParts(a.newValue));b.isModified=!0}else if(c===fe){var g=a.newValue;"nodeDataArray"===d&&za(g)&&Qq(this,g);b.isModified=!0}else if(c===ge){var h=a.oldValue;
"nodeDataArray"===d&&za(h)&&this.ip(h);b.isModified=!0}else c===ee&&("SourceChanged"===d?null!==a.object?this.updateDataBindings(a.object,a.propertyName):(this.rp(),this.updateAllTargetBindings()):"ModelDisplaced"===d&&this.Wd());else if(c===de){var k=a.propertyName,l=a.object;if(l===b.model){if("nodeKeyProperty"===k||"nodeCategoryProperty"===k)b.undoManager.isUndoingRedoing||this.Wd()}else this.updateDataBindings(l,k);b.isModified=!0}else if(c===fe||c===ge){var m=a.change===fe,n=m?a.newParam:a.oldParam,
p=m?a.newValue:a.oldValue,q=this.aj.I(a.object);if(Array.isArray(q))for(a=0;a<q.length;a++){var r=q[a];if(m)Gn(r,p,n,!0);else if(!(0>n)){var u=n+yn(r);r.qc(u,!0);Jn(r,u,n)}}b.isModified=!0}}finally{b.aa=!0}}}}};pi.prototype.updateAllTargetBindings=function(a){void 0===a&&(a="");for(var b=this.parts.iterator;b.next();)b.value.La(a);for(b=this.nodes.iterator;b.next();)b.value.La(a);for(b=this.links.iterator;b.next();)b.value.La(a)};
pi.prototype.rp=function(){for(var a=this.H.model,b=new H,c=a.nodeDataArray,d=0;d<c.length;d++)b.add(c[d]);var e=[];this.nodes.each(function(a){null===a.data||b.contains(a.data)||e.push(a.data)});this.parts.each(function(a){null===a.data||b.contains(a.data)||e.push(a.data)});e.forEach(function(b){Wq(a,b,!1)});for(d=0;d<c.length;d++){var f=c[d];null===this.Lc(f)&&Xq(a,f,!1)}this.refreshDataBoundLinks();for(c=this.parts.iterator;c.next();)c.value.updateRelationshipsFromData();for(c=this.nodes.iterator;c.next();)c.value.updateRelationshipsFromData();
for(c=this.links.iterator;c.next();)c.value.updateRelationshipsFromData()};pi.prototype.refreshDataBoundLinks=function(){};pi.prototype.updateRelationshipsFromData=function(){};
pi.prototype.updateDataBindings=function(a,b){if("string"===typeof b){var c=this.Lc(a);if(null!==c)c.La(b);else{c=null;for(var d=this.aj.iterator;d.next();){for(var e=d.value,f=0;f<e.length;f++){var g=e[f].tx(a);null!==g&&(null===c&&(c=Ea()),c.push(g))}if(null!==c)break}if(null!==c){for(d=0;d<c.length;d++)c[d].La(b);Ga(c)}}a===this.diagram.model.modelData&&this.updateAllTargetBindings(b)}};
function Oj(a,b){var c=b.Hh;if(Aa(c)){var d=a.aj.I(c);if(null===d)d=[],d.push(b),a.aj.add(c,d);else{for(a=0;a<d.length;a++)if(d[a]===b)return;d.push(b)}}}function Rj(a,b,c){Mj(b,function(a){a=a.O.s;for(var b=a.length,d=0;d<b;d++)jk(c,a[d])});var d=b.Hh;if(Aa(d)){var e=a.aj.I(d);if(null!==e)for(var f=0;f<e.length;f++)if(e[f]===b){e.splice(f,1);0===e.length&&a.aj.remove(d);break}}}
pi.prototype.Jj=function(a,b,c){void 0===c&&(c=!1);var d=new ob;if(Aa(a))for(var e=0;e<a.length;e++)Yq(this,a[e],b,d,c);else for(a=a.iterator;a.next();)Yq(this,a.value,b,d,c);if(null!==b){c=b.model;a=b.toolManager.findTool("Dragging");a=null!==a?a.dragOptions.dragsLink:b.Wm.dragsLink;e=new H;for(var f=new ob,g=d.iterator;g.next();){var h=g.value;if(h instanceof T)a||null!==h.fromNode&&null!==h.toNode||e.add(h);else if(h instanceof V&&null!==h.data&&c.Tl()){var k=h;h=g.key;var l=h.pg();null!==l&&(l=
d.I(l),null!==l?(c.qe(k.data,c.wa(l.data)),k=b.Kc(k.data),h=h.kh(),null!==h&&null!==k&&f.add(h,k)):c.qe(k.data,void 0))}}0<e.count&&b.Is(e,!1);if(0<f.count)for(c=f.iterator;c.next();)d.add(c.key,c.value)}if(null!==b&&null!==this.H&&(b=b.model,c=b.afterCopyFunction,null!==c)){var m=new ob;d.each(function(a){null!==a.key.data&&m.add(a.key.data,a.value.data)});c(m,b,this.H.model)}for(b=d.iterator;b.next();)b.value.La();return d};
function Yq(a,b,c,d,e){if(null===b||e&&!b.canCopy())return null;if(d.contains(b))return d.I(b);var f=a.copyPartData(b,c);if(!(f instanceof U))return null;f.isSelected=!1;f.isHighlighted=!1;d.add(b,f);if(b instanceof V){for(var g=b.linksConnected;g.next();){var h=g.value;if(h.fromNode===b){var k=d.I(h);null!==k&&(k.fromNode=f)}h.toNode===b&&(h=d.I(h),null!==h&&(h.toNode=f))}if(b instanceof jf&&f instanceof jf)for(b=b.memberParts;b.next();)g=Yq(a,b.value,c,d,e),g instanceof T||null===g||(g.containingGroup=
f)}else if(b instanceof T&&f instanceof T)for(g=b.fromNode,null!==g&&(g=d.I(g),null!==g&&(f.fromNode=g)),g=b.toNode,null!==g&&(g=d.I(g),null!==g&&(f.toNode=g)),b=b.labelNodes;b.next();)g=Yq(a,b.value,c,d,e),null!==g&&g instanceof V&&(g.labeledLink=f);return f}
pi.prototype.copyPartData=function(a,b){var c=null,d=a.data;if(null!==d&&null!==b){var e=b.model;a instanceof T||(d=e.copyNodeData(d),za(d)&&(e.xf(d),c=b.Lc(d)))}else jg(a),c=a.copy(),null!==c&&(e=this.H,null!==b?b.add(c):null!==d&&null!==e&&null!==e.commandHandler&&e.commandHandler.copiesClipboardData&&(b=e.model,e=null,c instanceof T||(e=b.copyNodeData(d)),za(e)&&(c.data=e)));return c};
ma.Object.defineProperties(pi.prototype,{nodes:{get:function(){return this.On}},links:{get:function(){return this.Dn}},parts:{get:function(){return this.Pa}},diagram:{get:function(){return this.H}},addsToTemporaryLayer:{get:function(){return this.tp},set:function(a){this.tp=a}}});pi.prototype.updateAllRelationshipsFromData=pi.prototype.rp;
pi.prototype.findLinksByExample=pi.prototype.ls;pi.prototype.findNodesByExample=pi.prototype.ms;pi.prototype.findLinkForData=pi.prototype.Kc;pi.prototype.findNodeForData=pi.prototype.ii;pi.prototype.findPartForData=pi.prototype.Lc;pi.prototype.findNodeForKey=pi.prototype.Xb;pi.prototype.removeModeledPart=pi.prototype.ip;pi.prototype.removeModeledParts=pi.prototype.Gs;pi.prototype.rebuildParts=pi.prototype.Wd;var Sq=!1,Rq=!1,Tq=!1;pi.className="PartManager";
function Zq(a){pi.apply(this,arguments)}la(Zq,pi);Zq.prototype.addAllModeledParts=function(){var a=this.diagram.model;this.addModeledParts(a.nodeDataArray);$q(this,a.linkDataArray)};Zq.prototype.addModeledParts=function(a){pi.prototype.addModeledParts.call(this,a,!1);for(a=this.links.iterator;a.next();)kp(a.value);kk(this.diagram,!1)};function $q(a,b){b.forEach(function(b){ar(a,b)});kk(a.diagram,!1)}
function ar(a,b){if(void 0!==b&&null!==b&&!a.diagram.undoManager.isUndoingRedoing&&!a.bf.contains(b)){var c=a.getLinkCategoryForData(b),d=a.findLinkTemplateForCategory(c);if(null!==d){jg(d);var e=d.copy();if(null!==e){d=a.diagram.skipsModelSourceBindings;a.diagram.skipsModelSourceBindings=!0;e.Tf=c;e.ub=b;c=a.diagram.model;var f=br(c,b,!0);""!==f&&(e.fromPortId=f);f=cr(c,b,!0);void 0!==f&&(f=a.Xb(f),f instanceof V&&(e.fromNode=f));f=br(c,b,!1);""!==f&&(e.toPortId=f);f=cr(c,b,!1);void 0!==f&&(f=a.Xb(f),
f instanceof V&&(e.toNode=f));c=c.Ef(b);Array.isArray(c)&&c.forEach(function(b){b=a.Xb(b);null!==b&&(b.labeledLink=e)});a.tp&&(e.Jg="Tool");a.diagram.add(e);e.ub=null;e.data=b;a.diagram.skipsModelSourceBindings=d}}}}Zq.prototype.removeAllModeledParts=function(){var a=this.diagram.model;dr(this,a.linkDataArray);this.Gs(a.nodeDataArray)};function dr(a,b){b.forEach(function(b){a.ip(b)})}Zq.prototype.getLinkCategoryForData=function(a){return this.diagram.model.ps(a)};
Zq.prototype.setLinkCategoryForData=function(a,b){return this.diagram.model.lp(a,b)};Zq.prototype.setFromNodeForLink=function(a,b){var c=this.diagram.model;c.xv(a.data,c.wa(null!==b?b.data:null))};Zq.prototype.setToNodeForLink=function(a,b){var c=this.diagram.model;c.Cv(a.data,c.wa(null!==b?b.data:null))};Zq.prototype.removeDataForLink=function(a){this.diagram.model.bm(a.data)};
Zq.prototype.findPartForKey=function(a){var b=pi.prototype.findPartForKey.call(this,a);return null===b&&(a=this.diagram.model.jh(a),null!==a)?this.bf.I(a):b};Zq.prototype.findLinkForKey=function(a){if(null===a||void 0===a)return null;a=this.diagram.model.jh(a);return null!==a?this.bf.I(a):null};
Zq.prototype.doModelChanged=function(a){var b=this;pi.prototype.doModelChanged.call(this,a);if(this.diagram){var c=this.diagram;if(a.model===c.model){var d=a.change;if(c.aa){c.aa=!1;try{var e=a.modelChange;if(""!==e)if(d===de){if("linkFromKey"===e){var f=this.Kc(a.object);if(null!==f){var g=this.Xb(a.newValue);f.fromNode=g}}else if("linkToKey"===e){var h=this.Kc(a.object);if(null!==h){var k=this.Xb(a.newValue);h.toNode=k}}else if("linkFromPortId"===e){var l=this.Kc(a.object);if(null!==l){var m=a.newValue;
"string"===typeof m&&(l.fromPortId=m)}}else if("linkToPortId"===e){var n=this.Kc(a.object);if(null!==n){var p=a.newValue;"string"===typeof p&&(n.toPortId=p)}}else if("nodeGroupKey"===e){var q=this.Lc(a.object);if(null!==q){var r=a.newValue;if(void 0!==r){var u=this.Xb(r);u instanceof jf?q.containingGroup=u:q.containingGroup=null}else q.containingGroup=null}}else if("linkLabelKeys"===e){var w=this.Kc(a.object);if(null!==w){var v=a.oldValue,y=a.newValue;Array.isArray(v)&&v.forEach(function(a){0<=y.indexOf(a)||
(a=b.Xb(a),null!==a&&(a.labeledLink=null))});Array.isArray(y)&&y.forEach(function(a){a=b.Xb(a);null!==a&&(a.labeledLink=w)})}}else if("linkCategory"===e){var z=this.Kc(a.object),A=a.newValue;null!==z&&"string"===typeof A&&(z.category=A)}else"linkDataArray"===e&&(dr(this,a.oldValue),$q(this,a.newValue));c.isModified=!0}else if(d===fe){var B=a.newValue;if("linkDataArray"===e&&"object"===typeof B&&null!==B)ar(this,B);else if("linkLabelKeys"===e&&er(B)){var E=this.Kc(a.object),L=this.Xb(B);null!==E&&
null!==L&&(L.labeledLink=E)}c.isModified=!0}else{if(d===ge){var R=a.oldValue;if("linkDataArray"===e&&"object"===typeof R&&null!==R)this.ip(R);else if("linkLabelKeys"===e&&er(R)){var S=this.Xb(R);null!==S&&(S.labeledLink=null)}c.isModified=!0}}else if(d===de){var J=a.propertyName;a.object!==c.model||"linkFromKeyProperty"!==J&&"linkToKeyProperty"!==J&&"linkFromPortIdProperty"!==J&&"linkToPortIdProperty"!==J&&"linkLabelKeysProperty"!==J&&"nodeIsGroupProperty"!==J&&"nodeGroupKeyProperty"!==J&&"linkCategoryProperty"!==
J||c.undoManager.isUndoingRedoing||this.Wd();c.isModified=!0}}finally{c.aa=!0}}}}};Zq.prototype.refreshDataBoundLinks=function(){var a=this,b=this.diagram.model,c=new H,d=b.linkDataArray;d.forEach(function(a){c.add(a)});var e=[];this.links.each(function(a){null===a.data||c.contains(a.data)||e.push(a.data)});e.forEach(function(a){fr(b,a,!1)});d.forEach(function(c){null===a.Kc(c)&&gr(b,c,!1)})};
Zq.prototype.updateRelationshipsFromData=function(a){var b=a.data;if(null!==b){var c=a.diagram;if(null!==c){var d=c.model;if(a instanceof T){var e=cr(d,b,!0);e=c.Xb(e);a.fromNode=e;e=cr(d,b,!1);e=c.Xb(e);a.toNode=e;b=d.Ef(b);if(0<b.length||0<a.labelNodes.count){if(1===b.length&&1===a.labelNodes.count){e=b[0];var f=a.labelNodes.first();if(d.wa(f.data)===e)return}e=(new H).addAll(b);var g=new H;a.labelNodes.each(function(a){null!==a.data&&(a=d.wa(a.data),void 0!==a&&g.add(a))});b=g.copy();b.hp(e);e=
e.copy();e.hp(g);if(0<b.count||0<e.count)b.each(function(b){b=c.Xb(b);null!==b&&b.labeledLink===a&&(b.labeledLink=null)}),e.each(function(b){b=c.Xb(b);null!==b&&b.labeledLink!==a&&(b.labeledLink=a)})}}else!(a instanceof pe)&&(b=d.lh(b),b=c.findPartForKey(b),null===b||b instanceof jf)&&(a.containingGroup=b)}}};
Zq.prototype.resolveReferencesForData=function(a){var b=this.diagram.model,c=b.wa(a);if(void 0!==c){var d=hr(b,c),e=this.Lc(a);if(null!==d&&null!==e){d=d.iterator;for(var f={};d.next();){var g=d.value;b.lb(g)?e instanceof jf&&b.lh(g)===c&&(g=this.Lc(g),null!==g&&(g.containingGroup=e)):(f.link=this.Kc(g),null!==f.link&&e instanceof V&&(cr(b,g,!0)===c&&(f.link.fromNode=e),cr(b,g,!1)===c&&(f.link.toNode=e),g=b.Ef(g),Array.isArray(g)&&g.some(function(a){return function(b){return b===c?(e.labeledLink=
a.link,!0):!1}}(f))));f={link:f.link}}ir(b,c)}a=b.lh(a);void 0!==a&&(a=this.Xb(a),a instanceof jf&&(e.containingGroup=a))}};Zq.prototype.unresolveReferencesForPart=function(a){var b=this.diagram.model;if(a instanceof V){var c=b.wa(a.data);if(void 0!==c){for(var d=a.linksConnected;d.next();)jr(b,c,d.value.data);a.isLinkLabel&&(d=a.labeledLink,null!==d&&jr(b,c,d.data));if(a instanceof jf)for(a=a.memberParts;a.next();)d=a.value.data,b.lb(d)&&jr(b,c,d)}}};
Zq.prototype.copyPartData=function(a,b){var c=pi.prototype.copyPartData.call(this,a,b);if(a instanceof T)if(a=a.data,null!==a&&null!==b){var d=b.model;a=d.Jo(a);"object"===typeof a&&null!==a&&(d.fi(a),c=b.Kc(a))}else null!==c&&(b=this.diagram,null!==a&&null!==b&&null!==b.commandHandler&&b.commandHandler.copiesClipboardData&&(b=b.model.Jo(a),"object"===typeof b&&null!==b&&(c.data=b)));return c};
Zq.prototype.insertLink=function(a,b,c,d){var e=this.diagram,f=e.model,g=e.toolManager.findTool("Linking"),h="";null!==a&&(null===b&&(b=a),h=b.portId,null===h&&(h=""));b="";null!==c&&(null===d&&(d=c),b=d.portId,null===b&&(b=""));d=g.archetypeLinkData;if(d instanceof T){if(jg(d),f=d.copy(),null!==f)return f.fromNode=a,f.fromPortId=h,f.toNode=c,f.toPortId=b,e.add(f),a=g.archetypeLabelNodeData,a instanceof V&&(jg(a),a=a.copy(),null!==a&&(a.labeledLink=f,e.add(a))),f}else if(null!==d&&(d=f.Jo(d),"object"===
typeof d&&null!==d))return null!==a&&kr(f,d,f.wa(a.data),!0),lr(f,d,h,!0),null!==c&&kr(f,d,f.wa(c.data),!1),lr(f,d,b,!1),f.fi(d),a=g.archetypeLabelNodeData,null===a||a instanceof V||(a=f.copyNodeData(a),"object"===typeof a&&null!==a&&(f.xf(a),a=f.wa(a),void 0!==a&&f.Bu(d,a))),e.Kc(d);return null};Zq.prototype.findLinkForKey=Zq.prototype.findLinkForKey;Zq.prototype.findPartForKey=Zq.prototype.findPartForKey;Zq.prototype.removeAllModeledParts=Zq.prototype.removeAllModeledParts;
Zq.prototype.addModeledParts=Zq.prototype.addModeledParts;Zq.prototype.addAllModeledParts=Zq.prototype.addAllModeledParts;Zq.className="GraphLinksPartManager";function mr(){pi.apply(this,arguments);this.Sg=null}la(mr,pi);
function nr(a,b,c){if(null!==b&&null!==c&&null===c.kh()){var d=a.diagram.toolManager.findTool("Linking"),e=b,f=c;a.diagram.isTreePathToChildren||(e=c,f=b);if(null===d||!Ff(d,e,f,null,!0))if(b=a.getLinkCategoryForData(c.data),d=a.findLinkTemplateForCategory(b),null!==d&&(jg(d),d=d.copy(),null!==d)){var g=a.diagram.skipsModelSourceBindings;a.diagram.skipsModelSourceBindings=!0;d.Tf=b;d.ub=c.data;d.fromNode=e;d.toNode=f;a.diagram.add(d);d.ub=null;d.data=c.data;a.diagram.skipsModelSourceBindings=g}}}
mr.prototype.getLinkCategoryForData=function(a){return this.diagram.model.ss(a)};mr.prototype.setLinkCategoryForData=function(a,b){this.diagram.model.Ms(a,b)};mr.prototype.setFromNodeForLink=function(a,b,c){var d=this.diagram.model;void 0===c&&(c=null);b=null!==b?b.data:null;if(this.diagram.isTreePathToChildren)d.qe(a.data,d.wa(b));else{var e=this.Sg;this.Sg=a;null!==c&&d.qe(c.data,void 0);d.qe(b,d.wa(null!==a.toNode?a.toNode.data:null));this.Sg=e}};
mr.prototype.setToNodeForLink=function(a,b,c){var d=this.diagram.model;void 0===c&&(c=null);b=null!==b?b.data:null;if(this.diagram.isTreePathToChildren){var e=this.Sg;this.Sg=a;null!==c&&d.qe(c.data,void 0);d.qe(b,d.wa(null!==a.fromNode?a.fromNode.data:null));this.Sg=e}else d.qe(a.data,d.wa(b))};mr.prototype.removeDataForLink=function(a){this.diagram.model.qe(a.data,void 0)};
mr.prototype.findLinkForKey=function(a){if(null===a||void 0===a)return null;a=this.diagram.model.ic(a);return null!==a?this.bf.I(a):null};
mr.prototype.doModelChanged=function(a){pi.prototype.doModelChanged.call(this,a);if(this.diagram){var b=this.diagram;if(a.model===b.model){var c=a.change;if(b.aa){b.aa=!1;try{var d=a.modelChange;if(""!==d){if(c===de){if("nodeParentKey"===d){var e=a.object,f=this.Xb(a.newValue),g=this.ii(e);if(null!==this.Sg)null!==f&&(this.Sg.data=e,this.Sg.category=this.getLinkCategoryForData(e));else if(null!==g){var h=g.kh();null!==h?null===f?b.remove(h):b.isTreePathToChildren?h.fromNode=f:h.toNode=f:nr(this,f,
g)}}else if("parentLinkCategory"===d){var k=this.ii(a.object),l=a.newValue;if(null!==k&&"string"===typeof l){var m=k.kh();null!==m&&(m.category=l)}}b.isModified=!0}}else if(c===de){var n=a.propertyName;a.object===b.model&&"nodeParentKeyProperty"===n&&(b.undoManager.isUndoingRedoing||this.Wd());b.isModified=!0}}finally{b.aa=!0}}}}};
mr.prototype.updateRelationshipsFromData=function(a){var b=a.data;if(null!==b){var c=a.diagram;if(null!==c){var d=c.model;a instanceof V&&(b=d.mh(b),b=c.Xb(b),d=a.pg(),b!==d&&(d=a.kh(),null!==b?null!==d?c.isTreePathToChildren?d.fromNode=b:d.toNode=b:nr(this,b,a):null!==d&&Sj(c,d,!1)))}}};mr.prototype.updateDataBindings=function(a,b){pi.prototype.updateDataBindings.call(this,a,b);"string"===typeof b&&null!==this.Lc(a)&&(a=this.Kc(a),null!==a&&a.La(b))};
mr.prototype.resolveReferencesForData=function(a){var b=this.diagram.model,c=b.wa(a);if(void 0!==c){var d=hr(b,c),e=this.Lc(a);if(null!==d&&null!==e){for(d=d.iterator;d.next();){var f=d.value;b.lb(f)&&e instanceof V&&b.mh(f)===c&&nr(this,e,this.ii(f))}ir(b,c)}a=b.mh(a);void 0!==a&&e instanceof V&&(a=this.Xb(a),nr(this,a,e))}};
mr.prototype.unresolveReferencesForPart=function(a){var b=this.diagram.model;if(a instanceof V){var c=b.wa(a.data),d=this.Kc(a.data);if(null!==d){d.isSelected=!1;d.isHighlighted=!1;var e=d.layer;if(null!==e){var f=e.qc(-1,d,!1);0<=f&&this.diagram.cb(ge,"parts",e,d,null,f,null);f=d.layerChanged;null!==f&&f(d,e,null)}}d=this.diagram.isTreePathToChildren;for(a=a.linksConnected;a.next();)e=a.value,e=(d?e.toNode:e.fromNode).data,b.lb(e)&&jr(b,c,e)}};
mr.prototype.insertLink=function(a,b,c){b=this.diagram.model;var d=a,e=c;this.diagram.isTreePathToChildren||(d=c,e=a);return null!==d&&null!==e?(b.qe(e.data,b.wa(d.data)),e.kh()):null};mr.prototype.findLinkForKey=mr.prototype.findLinkForKey;mr.className="TreePartManager";
function Z(a,b){this.jl=new H;this.hl=new H;this.Xv=',\n  "insertedNodeKeys": ';this.ky=',\n  "modifiedNodeData": ';this.Zv=',\n  "removedNodeKeys": ';this.vh=null;Qa(this);this.Sm=this.xa="";this.Gg=!1;this.j={};this.Tc=[];this.kb=new ob;this.Th="key";this.Ak=this.al=null;this.Jm=this.Km=!1;this.Mm=!0;this.xm=null;this.lj="category";this.Vf=new ob;this.Lt=new F;this.Wg=!1;this.o=null;this.undoManager=new ie;void 0!==a&&(Aa(a)?this.nodeDataArray=a:b=a);b&&(Object.assign(this,b),a=b.Changed)&&(delete this.Changed,
this.Pe(a))}Z.prototype.cloneProtected=function(a){a.xa=this.xa;a.Sm=this.Sm;a.Gg=this.Gg;a.Th=this.Th;a.al=this.al;a.Ak=this.Ak;a.Km=this.Km;a.Jm=this.Jm;a.Mm=this.Mm;a.xm=this.xm;a.lj=this.lj};Z.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};Z.prototype.clear=function(){this.Tc=[];this.kb.clear();this.Vf.clear();this.undoManager.clear()};
Z.prototype.toString=function(a){void 0===a&&(a=0);if(1<a)return this.np();var b=(""!==this.name?this.name:"")+" Model";if(0<a){b+="\n node data:";a=this.nodeDataArray;for(var c=a.length,d=0;d<c;d++){var e=a[d];b+=" "+this.wa(e)+":"+Ia(e)}}return b};
Z.prototype.yA=function(a){a.change!==ee&&C("Model.toIncrementalData argument is not a Transaction ChangedEvent:"+a.toString());var b=a.object;if(!(a.isTransactionFinished&&b instanceof he))return null;or(this,b);a=this.Rv(b,"FinishedUndo"===a.propertyName);this.vh=null;return a};
Z.prototype.Rv=function(a,b){var c=this,d=!1,e=new H,f=new H,g=new H,h=this.vh;a.changes.each(function(a){a.model===c&&("nodeDataArray"===a.modelChange?a.change===fe?e.add(a.newValue):a.change===ge&&g.add(a.oldValue):c.lb(a.object)?f.add(a.object):a.change!==de||c.modelData!==a.object&&"modelData"!==a.propertyName?null!==a.object&&(h&&h.contains(a.object)?h.get(a.object).each(function(a){c.lb(a)&&f.add(a)}):a.object instanceof Z||c.ri()&&c.Jc(a.object)||pr(c,a.object).each(function(a){f.add(a)})):
d=!0)});var k=this.hl;k.clear();e.each(function(a){k.add(c.wa(a));b||f.add(a)});var l=this.jl;l.clear();g.each(function(a){l.add(c.wa(a));b&&f.add(a)});a=c.cloneDeep(f.Fa());var m=null;d&&(null===m&&(m={}),m.modelData=this.cloneDeep(this.modelData));0<k.count&&(null===m&&(m={}),b?m.removedNodeKeys=k.Fa():m.insertedNodeKeys=k.Fa());0<a.length&&(null===m&&(m={}),m.modifiedNodeData=a);0<l.count&&(null===m&&(m={}),b?m.insertedNodeKeys=l.Fa():m.removedNodeKeys=l.Fa());l.clear();k.clear();return m};
Z.prototype.cloneDeep=function(a){return qr(this,a,!0)};
function qr(a,b,c,d,e,f){function g(a,c){h?(void 0===a.__gohashid&&f.push(a),d.set(a,c)):(a=ib++,d.set(a,c),e.set(a,b))}if(!za(b))return b;f||(f=[]);var h=Object.isExtensible(b);d||(d=new ob);if(h){var k=d.get(b);if(k)return k}else for(e||(e=new ob),k=e.iterator;k.next();){var l=k.key;if(k.value===b&&(l=d.get(l)))return l}if(Array.isArray(b)){k=[];g(b,k);for(var m=0;m<b.length;m++)k.push(qr(a,b[m],!1,d,e,f))}else if(b instanceof I||b instanceof M||b instanceof N||b instanceof cc||b instanceof O)k=
b.copy(),g(b,k);else{if(b instanceof vl||b instanceof P||b instanceof D)return b;if(b instanceof F)k=(new F).addAll(qr(a,b.Fa(),!1,d,e,f)),g(b,k);else if(b instanceof H)k=(new H).addAll(qr(a,b.Fa(),!1,d,e,f)),g(b,k);else if(b instanceof ob)k=(new ob).addAll(qr(a,b.Fa(),!1,d,e,f)),g(b,k);else if(b instanceof Date)k=new Date(b.getTime()),g(b,k);else if(b instanceof RegExp)k=new RegExp(b),g(b,k),k.lastIndex=b.lastIndex;else if("function"===typeof b.copy)k=b.copy(),g(b,k);else for(m in k={},g(b,k),b)"__gohashid"!==
m&&(k[m]=qr(a,b[m],!1,d,e,f))}c&&f.forEach(function(a){delete a.__gohashid});return k}t=Z.prototype;t.quote=function(a){return JSON.stringify(a).replace(/[\u007F-\uFFFF]/g,function(a){return"\\u"+("0000"+a.charCodeAt(0).toString(16)).substr(-4)})};
t.rm=function(){var a="";""!==this.name&&(a+=',\n  "name": '+this.quote(this.name));""!==this.dataFormat&&(a+=',\n  "dataFormat": '+this.quote(this.dataFormat));this.isReadOnly&&(a+=',\n  "isReadOnly": '+this.isReadOnly);"key"!==this.nodeKeyProperty&&"string"===typeof this.nodeKeyProperty&&(a+=',\n  "nodeKeyProperty": '+this.quote(this.nodeKeyProperty));this.copiesArrays&&(a+=',\n  "copiesArrays": true');this.copiesArrayObjects&&(a+=',\n  "copiesArrayObjects": true');this.copiesKey||(a+=',\n  "copiesKey": false');
"category"!==this.nodeCategoryProperty&&"string"===typeof this.nodeCategoryProperty&&(a+=',\n  "nodeCategoryProperty": '+this.quote(this.nodeCategoryProperty));return a};
t.gp=function(a){a.name&&(this.name=a.name);a.dataFormat&&(this.dataFormat=a.dataFormat);a.isReadOnly&&(this.isReadOnly=!0);a.nodeKeyProperty&&(this.nodeKeyProperty=a.nodeKeyProperty);a.copiesArrays&&(this.copiesArrays=!0);a.copiesArrayObjects&&(this.copiesArrayObjects=!0);!1===a.copiesKey&&(this.copiesKey=!1);a.nodeCategoryProperty&&(this.nodeCategoryProperty=a.nodeCategoryProperty)};function rr(a,b){b=b.modelData;za(b)&&(a.dm(b),a.modelData=b)}
t.Sv=function(){var a=this.modelData,b=!1,c;for(c in a)if(!sr(c,a[c])){b=!0;break}a="";b&&(a=',\n  "modelData": '+tr(this,this.modelData));return a+',\n  "nodeDataArray": '+ur(this,this.nodeDataArray,!0)};t.lv=function(a){rr(this,a);a=a.nodeDataArray;Aa(a)&&(this.dm(a),this.nodeDataArray=a)};
function or(a,b){function c(a,b){if(b=b.part)if(b=b.data,a!==b){var c=d.get(a);null===c?(c=new H,c.add(b),d.add(a,c)):c.add(b)}}var d=a.vh;null===d&&(d=new ob,b.changes.each(function(a){if(null!==a.diagram){var b=a.change;if(b===de)(a=a.object.panel)&&(b=a.data)&&c(b,a);else if(b===fe||b===ge)a=a.object,(b=a.itemArray)&&c(b,a)}}),a.vh=d)}function pr(a,b){for(var c=new H,d=0;d<a.nodeDataArray.length;d++){var e=a.nodeDataArray[d];vr(a,b,e,e,c)}return c}
function vr(a,b,c,d,e){if(Array.isArray(c))for(var f=0;f<c.length;f++){var g=c[f];if(g===b)return e.add(d),!0;if(vr(a,b,g,d,e))return!0}else if(za(c)&&Object.getPrototypeOf(c)===Object.prototype)for(f in c){g=c[f];if(g===b)return e.add(d),!0;if(vr(a,b,g,d,e))return!0}return!1}
t.Qv=function(a,b){var c=this,d=!1,e=new H,f=new H,g=new H,h=this.vh;a.changes.each(function(a){a.model===c&&("nodeDataArray"===a.modelChange?a.change===fe?e.add(a.newValue):a.change===ge&&g.add(a.oldValue):c.lb(a.object)?f.add(a.object):a.change!==de||c.modelData!==a.object&&"modelData"!==a.propertyName?null!==a.object&&(h&&h.contains(a.object)?h.get(a.object).each(function(a){c.lb(a)&&f.add(a)}):a.object instanceof Z||c.ri()&&c.Jc(a.object)||pr(c,a.object).each(function(a){f.add(a)})):d=!0)});var k=
this.hl;k.clear();e.each(function(a){k.add(c.wa(a));b||f.add(a)});var l=this.jl;l.clear();g.each(function(a){l.add(c.wa(a));b&&f.add(a)});a="";d&&(a+=',\n  "modelData": '+tr(this,this.modelData));0<k.count&&(a+=(b?this.Zv:this.Xv)+ur(this,k.Fa(),!0));0<f.count&&(a+=this.ky+ur(this,f.Fa(),!0));0<l.count&&(a+=(b?this.Xv:this.Zv)+ur(this,l.Fa(),!0));l.clear();k.clear();return a};
t.fp=function(a){(void 0!==a.name&&a.name!==this.name||void 0!==a.dataFormat&&a.dataFormat!==this.dataFormat||void 0!==a.isReadOnly&&a.isReadOnly!==this.isReadOnly||void 0!==a.nodeKeyProperty&&a.nodeKeyProperty!==this.nodeKeyProperty||void 0!==a.copiesArrays&&a.copiesArrays!==this.copiesArrays||void 0!==a.copiesArrayObjects&&a.copiesArrayObjects!==this.copiesArrayObjects||void 0!==a.copiesKey&&a.copiesKey!==this.copiesKey||void 0!==a.nodeCategoryProperty&&a.nodeCategoryProperty!==this.nodeCategoryProperty)&&
C("applyIncrementalJson cannot change Model properties");rr(this,a);var b=a.insertedNodeKeys,c=a.modifiedNodeData,d=new ob;if(Array.isArray(c))for(var e=0;e<c.length;e++){var f=c[e],g=this.wa(f);void 0!==g&&null!==g&&d.set(g,f)}if(Array.isArray(b))for(e=b.length,f=0;f<e;f++){g=b[f];var h=this.ic(g);null===h&&(h=(h=d.get(g))?h:this.copyNodeData({}),this.km(h,g),this.xf(h))}if(Array.isArray(c))for(b=c.length,d=0;d<b;d++)if(e=c[d],f=this.wa(e),f=this.ic(f),null!==f){for(var k in e)"__gohashid"===k||
k===this.nodeKeyProperty||this.Vj()&&k===this.nodeIsGroupProperty||this.setDataProperty(f,k,e[k]);this.lm(e,f)}a=a.removedNodeKeys;if(Array.isArray(a))for(c=a.length,k=0;k<c;k++)b=this.ic(a[k]),null!==b&&this.cm(b)};
t.ay=function(a,b){a.change!==ee&&C("Model.toIncrementalJson argument is not a Transaction ChangedEvent:"+a.toString());var c=a.object;if(!(a.isTransactionFinished&&c instanceof he))return'{ "incremental": 0 }';void 0===b&&(b=wr(this,this));or(this,c);a=this.Qv(c,"FinishedUndo"===a.propertyName);this.vh=null;return"2"===b?'{ "incremental": 2'+a+"}":'{ "class": '+this.quote(b)+', "incremental": 1'+this.rm()+a+"}"};t.zA=function(a,b){return this.ay(a,b)};
t.np=function(a){void 0===a&&(a=wr(this,this));return'{ "class": '+this.quote(a)+this.rm()+this.Sv()+"}"};t.toJSON=function(a){return this.np(a)};t.$w=function(a){var b=null;if("string"===typeof a)try{b=x.JSON.parse(a)}catch(d){}else"object"===typeof a?b=a:C("Unable to modify a Model from: "+a);var c=b.incremental;"number"!==typeof c&&C("Unable to apply non-incremental changes to Model: "+a);0!==c&&(this.Ea("applyIncrementalJson"),this.fp(b),this.eb("applyIncrementalJson"))};t.Ly=function(a){return this.$w(a)};
Z.constructGraphLinksModel=function(){return new Z};t=Z.prototype;
t.dm=function(a){if(Aa(a))for(var b=a.length,c=0;c<b;c++){var d=a[c];if(za(d)){var e=c;d=this.dm(d);Array.isArray(a)?a[e]=d:C("Cannot replace an object in an HTMLCollection or NodeList at "+e)}}else if(za(a)){for(b in a)if(c=a[b],za(c)&&(c=this.dm(c),a[b]=c,"points"===b&&Array.isArray(c))){e=0===c.length%2;for(d=0;d<c.length;d++)if("number"!==typeof c[d]){e=!1;break}if(e){e=new F;for(d=0;d<c.length/2;d++)e.add(new I(c[2*d],c[2*d+1]));e.freeze();a[b]=e}}return xr(a)}return a};
t.sm=function(a){return void 0===a?"undefined":null===a?"null":!0===a?"true":!1===a?"false":"string"===typeof a?this.quote(a):"number"===typeof a?Infinity===a?"9e9999":-Infinity===a?"-9e9999":isNaN(a)?'{"class":"NaN"}':a.toString():a instanceof Date?'{"class":"Date", "value":"'+a.toJSON()+'"}':a instanceof Number?this.sm(a.valueOf()):Aa(a)?ur(this,a):za(a)?tr(this,a):"function"===typeof a?"null":a.toString()};
function ur(a,b,c){void 0===c&&(c=!1);var d=b.length;if(0>=d)return"[]";var e=new Ta;e.add("[");c&&1<d&&e.add("\n");for(var f=0;f<d;f++){var g=b[f];void 0!==g&&(0<f&&(e.add(","),c&&e.add("\n")),e.add(a.sm(g)))}c&&1<d&&e.add("\n");e.add("]");return e.toString()}function sr(a,b){return void 0===b||"__gohashid"===a||"_"===a[0]||"function"===typeof b?!0:!1}function yr(a){return isNaN(a)?"NaN":Infinity===a?"9e9999":-Infinity===a?"-9e9999":a}
function tr(a,b){var c=b;if(c instanceof I)b={"class":"go.Point",x:yr(c.x),y:yr(c.y)};else if(c instanceof M)b={"class":"go.Size",width:yr(c.width),height:yr(c.height)};else if(c instanceof N)b={"class":"go.Rect",x:yr(c.x),y:yr(c.y),width:yr(c.width),height:yr(c.height)};else if(c instanceof cc)b={"class":"go.Margin",top:yr(c.top),right:yr(c.right),bottom:yr(c.bottom),left:yr(c.left)};else if(c instanceof O)c.sb()?b={"class":"go.Spot",x:yr(c.x),y:yr(c.y),offsetX:yr(c.offsetX),offsetY:yr(c.offsetY)}:
b={"class":"go.Spot","enum":c.toString()};else if(c instanceof vl){b={"class":"go.Brush",type:c.type.name};if(c.type===Ml)b.color=c.color;else if(c.type===Pl||c.type===wl)b.start=c.start,b.end=c.end,c.type===wl&&(0!==c.startRadius&&(b.startRadius=yr(c.startRadius)),isNaN(c.endRadius)||(b.endRadius=yr(c.endRadius)));if(null!==c.colorStops){var d={};for(c=c.colorStops.iterator;c.next();)d[c.key]=c.value;b.colorStops=d}}else c instanceof P?(b={"class":"go.Geometry",type:c.type.name},0!==c.startX&&(b.startX=
yr(c.startX)),0!==c.startY&&(b.startY=yr(c.startY)),0!==c.endX&&(b.endX=yr(c.endX)),0!==c.endY&&(b.endY=yr(c.endY)),c.spot1.D(sc)||(b.spot1=c.spot1),c.spot2.D(Bc)||(b.spot2=c.spot2),c.type===P.j&&(b.path=P.ia(c))):c instanceof D&&(b={"class":"go.EnumValue",classType:wr(a,c.classType),name:c.name});d="{";c=!0;for(var e in b){var f=Kn(b,e);if(!sr(e,f))if(c?c=!1:d+=",",d+=a.quote(e)+":","points"===e&&f instanceof F){var g="[";for(f=f.iterator;f.next();){var h=f.value;1<g.length&&(g+=",");g+=a.sm(h.x);
g+=",";g+=a.sm(h.y)}g+="]";d+=g}else d+=a.sm(f)}return d+"}"}function zr(a){return"number"===typeof a?a:"NaN"===a?NaN:"9e9999"===a?Infinity:"-9e9999"===a?-Infinity:parseFloat(a)}
function xr(a){if("object"!==typeof a)return a;var b=a["class"]||"";if("string"!==typeof b||!b)return a;if("NaN"===b)return NaN;if("Date"===b)return new Date(a.value);if(0!==b.indexOf("go."))return a;b=b.substr(3);var c=a;if("Point"===b)c=new I(zr(a.x),zr(a.y));else if("Size"===b)c=new M(zr(a.width),zr(a.height));else if("Rect"===b)c=new N(zr(a.x),zr(a.y),zr(a.width),zr(a.height));else if("Margin"===b)c=new cc(zr(a.top),zr(a.right),zr(a.bottom),zr(a.left));else if("Spot"===b)"string"===typeof a["enum"]?
c=hd(a["enum"]):c=new O(zr(a.x),zr(a.y),zr(a.offsetX),zr(a.offsetY));else if("Brush"===b){if(c=new vl,c.type=Ra(vl,a.type),"string"===typeof a.color&&(c.color=a.color),a.start instanceof O&&(c.start=a.start),a.end instanceof O&&(c.end=a.end),"number"===typeof a.startRadius&&(c.startRadius=zr(a.startRadius)),"number"===typeof a.endRadius&&(c.endRadius=zr(a.endRadius)),a=a.colorStops,za(a))for(var d in a)c.addColorStop(parseFloat(d),a[d])}else"Geometry"===b?(c=null,"string"===typeof a.path?c=P.parse(a.path):
c=new P,c.type=Ra(P,a.type),"number"===typeof a.startX&&(c.startX=zr(a.startX)),"number"===typeof a.startY&&(c.startY=zr(a.startY)),"number"===typeof a.endX&&(c.endX=zr(a.endX)),"number"===typeof a.endY&&(c.endY=zr(a.endY)),a.spot1 instanceof O&&(c.spot1=a.spot1),a.spot2 instanceof O&&(c.spot2=a.spot2)):"EnumValue"===b&&(d=a.classType,0===d.indexOf("go.")&&(d=d.substr(3)),d=Ar(d),"function"===typeof d&&(c=Ra(d,a.name)));return c}t.Pe=function(a){this.Lt.add(a);return this};t.hk=function(a){this.Lt.remove(a)};
t.ds=function(a){this.skipsUndoManager||this.undoManager.Uu(a);for(var b=this.Lt,c=b.length,d=0;d<c;d++)b.M(d)(a)};t.cb=function(a,b,c,d,e,f,g){Br(this,"",a,b,c,d,e,f,g)};t.g=function(a,b,c,d,e){Br(this,"",de,a,this,b,c,d,e)};t.Fs=function(a,b,c,d,e,f){Br(this,"",de,b,a,c,d,e,f)};function Br(a,b,c,d,e,f,g,h,k){void 0===h&&(h=null);void 0===k&&(k=null);var l=new ce;l.model=a;l.change=c;l.modelChange=b;l.propertyName=d;l.object=e;l.oldValue=f;l.oldParam=h;l.newValue=g;l.newParam=k;a.ds(l)}
Z.prototype.changeState=function(a,b){if(null!==a&&a.model===this)if(a.change===de){var c=a.object,d=a.propertyName,e=a.I(b);d===this.nodeKeyProperty&&this.lb(c)&&(b=a.I(!b),void 0!==b&&this.kb.remove(b),void 0!==e&&this.kb.add(e,c));Zj(c,d,e)}else a.change===fe?(c=a.newParam,"nodeDataArray"===a.modelChange?(a=a.newValue,za(a)&&"number"===typeof c&&(d=this.wa(a),b?(this.Tc[c]===a&&Da(this.Tc,c),void 0!==d&&this.kb.remove(d)):(this.Tc[c]!==a&&Ca(this.Tc,c,a),void 0!==d&&this.kb.add(d,a)))):""===a.modelChange?
((d=a.object)&&!Aa(d)&&a.propertyName&&(d=Kn(a.object,a.propertyName)),Aa(d)&&"number"===typeof c&&(a=a.newValue,b?Da(d,c):Ca(d,c,a))):C("unknown ChangedEvent.Insert modelChange: "+a.toString())):a.change===ge?(c=a.oldParam,"nodeDataArray"===a.modelChange?(a=a.oldValue,za(a)&&"number"===typeof c&&(d=this.wa(a),b?(this.Tc[c]!==a&&Ca(this.Tc,c,a),void 0!==d&&this.kb.add(d,a)):(this.Tc[c]===a&&Da(this.Tc,c),void 0!==d&&this.kb.remove(d)))):""===a.modelChange?((d=a.object)&&!Aa(d)&&a.propertyName&&(d=
Kn(a.object,a.propertyName)),Aa(d)&&"number"===typeof c&&(a=a.oldValue,b?Ca(d,c,a):Da(d,c))):C("unknown ChangedEvent.Remove modelChange: "+a.toString())):a.change!==ee&&C("unknown ChangedEvent: "+a.toString())};Z.prototype.Ea=function(a){return this.undoManager.Ea(a)};Z.prototype.eb=function(a){return this.undoManager.eb(a)};Z.prototype.Kf=function(){return this.undoManager.Kf()};
Z.prototype.commit=function(a,b){void 0===b&&(b="");var c=this.skipsUndoManager;null===b&&(this.skipsUndoManager=!0,b="");this.undoManager.Ea(b);var d=!1;try{a(this),d=!0}finally{d?this.undoManager.eb(b):this.undoManager.Kf(),this.skipsUndoManager=c}};t=Z.prototype;t.La=function(a,b){void 0===b&&(b="");Br(this,"SourceChanged",ee,b,a,null,null)};
t.wa=function(a){if(null!==a){var b=this.Th;if(""!==b&&(b=Kn(a,b),void 0!==b)){if(er(b))return b;C("Key value for node data "+a+" is not a number or a string: "+b)}}};t.km=function(a,b){if(null!==a){var c=this.Th;if(""!==c)if(this.lb(a)){var d=Kn(a,c);d!==b&&null===this.ic(b)&&(Zj(a,c,b),void 0!==d&&this.kb.remove(d),this.kb.add(b,a),Br(this,"nodeKey",de,c,a,d,b),"string"===typeof c&&this.La(a,c),this.jp(d,b))}else Zj(a,c,b)}};function er(a){return"number"===typeof a||"string"===typeof a}
t.lb=function(a){var b=this.wa(a);return void 0===b?!1:this.kb.I(b)===a};t.ic=function(a){null===a&&C("Model.findNodeDataForKey:key must not be null");return void 0!==a&&er(a)?this.kb.I(a):null};
t.av=function(a){if(null!==a){var b=this.Th;if(""!==b){var c=this.wa(a);if(void 0===c||this.kb.contains(c)){var d=this.al;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.kb.contains(c))){Zj(a,b,c);return}if("string"===typeof c){for(d=2;this.kb.contains(c+d);)d++;Zj(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(c=-this.kb.count-1;this.kb.contains(c);)c--;Zj(a,b,c)}}}}};t.xf=function(a){null!==a&&(db(a),this.lb(a)||Xq(this,a,!0))};
function Xq(a,b,c){var d=a.wa(b);if(void 0===d||a.kb.I(d)!==b)a.av(b),d=a.wa(b),void 0===d&&C("Model.makeNodeDataKeyUnique failed on "+b+".  Data not added to Model."),a.kb.add(d,b),d=null,c&&(d=a.Tc.length,Ca(a.Tc,d,b)),Br(a,"nodeDataArray",fe,"nodeDataArray",a,null,b,null,d),a.fm(b),a.em(b)}t.Gy=function(a){if(Aa(a))for(var b=a.length,c=0;c<b;c++)this.xf(a[c]);else for(a=a.iterator;a.next();)this.xf(a.value)};t.cm=function(a){null!==a&&Wq(this,a,!0)};
function Wq(a,b,c){var d=a.wa(b);void 0!==d&&a.kb.remove(d);d=null;if(c){a:if(c=a.Tc,Array.isArray(c))d=c.indexOf(b);else{d=c.length;for(var e=0;e<d;e++)if(c[e]===b){d=e;break a}d=-1}if(0>d)return;Da(a.Tc,d)}Br(a,"nodeDataArray",ge,"nodeDataArray",a,b,null,d,null);a.qp(b)}t.oA=function(a){if(Aa(a))for(var b=a.length,c=0;c<b;c++)this.cm(a[c]);else for(a=a.iterator;a.next();)this.cm(a.value)};
t.aA=function(a){if(a!==this.nodeDataArray&&Aa(a)){var b=this.jl;b.clear();b.addAll(this.kb.iteratorKeys);var c=this.hl;c.clear();for(var d=a.length,e=0;e<d;e++){var f=a[e],g=this.wa(f);if(void 0!==g){c.add(g);var h=this.ic(g);h!==f&&(null!==h?(this.Do(h,f),this.lm(f,h)):(f=this.cloneDeep(f),this.km(f,g),this.xf(f)))}else g=this.cloneDeep(f),this.xf(g),c.add(this.wa(g))}for(a=b.iterator;a.next();)d=a.value,c.contains(d)||(d=this.ic(d))&&this.cm(d);b.clear();c.clear()}};
t.jp=function(a,b){void 0!==b&&(a=hr(this,a),a instanceof H&&this.Vf.add(b,a))};t.Mv=function(){};t.fm=function(){};t.em=function(){};t.qp=function(){};function jr(a,b,c){if(void 0!==b){var d=a.Vf.I(b);null===d&&(d=new H,a.Vf.add(b,d));d.add(c)}}function ir(a,b,c){if(void 0!==b){var d=a.Vf.I(b);d instanceof H&&(void 0===c||null===c?a.Vf.remove(b):(d.remove(c),0===d.count&&a.Vf.remove(b)))}}function hr(a,b){if(void 0===b)return null;a=a.Vf.I(b);return a instanceof H?a:null}
t.Ry=function(a){void 0===a?this.Vf.clear():this.Vf.remove(a)};Z.prototype.copyNodeData=function(a){if(null===a)return null;var b=this.Ak;a=null!==b?b(a,this):Cr(this,a,!0);za(a)&&Qa(a);return a};
function Cr(a,b,c){if(a.copiesArrays&&Array.isArray(b)){var d=[];for(c=0;c<b.length;c++){var e=Cr(a,b[c],a.copiesArrayObjects);d.push(e)}Qa(d);return d}if(c&&za(b)){c=(c=b.constructor)?new c:{};e=a.copiesKey||"string"!==typeof a.nodeKeyProperty?null:a.nodeKeyProperty;for(d in b)if("__gohashid"===d)c.__gohashid=void 0;else if(d===e)c[e]=void 0;else{var f=Kn(b,d),g=wr(a,f);"GraphObject"===g||"Diagram"===g||"Layer"===g||"RowColumnDefinition"===g||"AnimationManager"===g||"Tool"===g||"CommandHandler"===
g||"Layout"===g||"InputEvent"===g||"DiagramEvent"===g||f instanceof Z||f instanceof ie||f instanceof he||f instanceof ce?Zj(c,d,f):(f=Cr(a,f,!1),Zj(c,d,f))}Qa(c);return c}return b instanceof I?b.copy():b instanceof M?b.copy():b instanceof N?b.copy():b instanceof O?b.copy():b instanceof cc?b.copy():b}
Z.prototype.setDataProperty=function(a,b,c){if(this.lb(a)){if(b===this.nodeKeyProperty){this.km(a,c);return}if(b===this.nodeCategoryProperty){this.jm(a,c);return}}else!Dr&&a instanceof Y&&(Dr=!0,ya('Model.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),ya("  Is that really your intent?"));var d=Kn(a,b);d!==c&&(Zj(a,b,c),this.Fs(a,b,d,c))};t=Z.prototype;t.set=function(a,b,c){this.setDataProperty(a,b,c)};
t.Do=function(a,b){if(b){var c=this.lb(a),d;for(d in b)"__gohashid"===d||c&&d===this.nodeKeyProperty||this.setDataProperty(a,d,b[d])}};t.By=function(a,b){this.ts(a,-1,b)};t.ts=function(a,b,c){0>b&&(b=a.length);Ca(a,b,c);Br(this,"",fe,"",a,null,c,null,b)};t.pv=function(a,b){void 0===b&&(b=-1);a===this.Tc&&C("Model.removeArrayItem should not be called on the Model.nodeDataArray");-1===b&&(b=a.length-1);var c=a[b];Da(a,b);Br(this,"",ge,"",a,c,null,b,null)};
t.Ro=function(a){if(null===a)return"";var b=this.lj;if(""===b)return"";b=Kn(a,b);if(void 0===b)return"";if("string"===typeof b)return b;C("getCategoryForNodeData found a non-string category for "+a+": "+b)};t.jm=function(a,b){if(null!==a){var c=this.lj;if(""!==c)if(this.lb(a)){var d=Kn(a,c);void 0===d&&(d="");d!==b&&(Zj(a,c,b),Br(this,"nodeCategory",de,c,a,d,b))}else Zj(a,c,b)}};t.lm=function(a,b){this.jm(b,this.Ro(a))};t.Tl=function(){return!1};t.Vj=function(){return!1};t.ri=function(){return!1};
t.xs=function(){return!1};t.Wj=function(){return!1};function ri(){return new Z}function wr(a,b){if("function"===typeof b){if(b.className)return b.className;if(b.name)return b.name}else if("object"===typeof b&&null!==b&&b.constructor)return wr(a,b.constructor);return typeof b}function Ar(a){return Er[a]?Er[a]:void 0!==x.go&&x.go[a]?x.go[a]:null}
function Kn(a,b){if(!a||!b)return null;try{if("function"===typeof b)var c=b(a);else"function"===typeof a.getAttribute?(c=a.getAttribute(b),null===c&&(c=void 0)):c=a[b]}catch(d){}return c}function Zj(a,b,c){if(a&&b)try{"function"===typeof b?b(a,c):"function"===typeof a.setAttribute?a.setAttribute(b,c):a[b]=c}catch(d){}}
ma.Object.defineProperties(Z.prototype,{name:{get:function(){return this.xa},set:function(a){var b=this.xa;b!==a&&(this.xa=a,this.g("name",b,a))}},dataFormat:{get:function(){return this.Sm},set:function(a){var b=this.Sm;b!==a&&(this.Sm=a,this.g("dataFormat",b,a))}},isReadOnly:{get:function(){return this.Gg},set:function(a){var b=this.Gg;b!==a&&(this.Gg=a,this.g("isReadOnly",b,a))}},modelData:{
get:function(){return this.j},set:function(a){var b=this.j;b!==a&&(this.j=a,this.g("modelData",b,a),this.La(a))}},undoManager:{get:function(){return this.o},set:function(a){var b=this.o;b!==a&&(null!==b&&b.Sx(this),this.o=a,null!==a&&a.Yw(this))}},skipsUndoManager:{get:function(){return this.Wg},set:function(a){this.Wg=a}},nodeKeyProperty:{get:function(){return this.Th},set:function(a){var b=this.Th;
b!==a&&(""===a&&C("Model.nodeKeyProperty may not be the empty string"),0<this.kb.count&&C("Cannot set Model.nodeKeyProperty when there is existing node data"),this.Th=a,this.g("nodeKeyProperty",b,a))}},makeUniqueKeyFunction:{get:function(){return this.al},set:function(a){var b=this.al;b!==a&&(this.al=a,this.g("makeUniqueKeyFunction",b,a))}},nodeDataArray:{get:function(){return this.Tc},set:function(a){var b=this.Tc;if(b!==a){this.kb.clear();
this.Mv();for(var c=a.length,d=0;d<c;d++){var e=a[d];za(e)||C("Model.nodeDataArray must only contain Objects, not: "+e);db(e)}this.Tc=a;d=new F;for(e=0;e<c;e++){var f=a[e],g=this.wa(f);void 0===g?d.add(f):null!==this.kb.I(g)?d.add(f):this.kb.add(g,f)}for(d=d.iterator;d.next();)e=d.value,this.av(e),f=this.wa(e),void 0!==f&&this.kb.add(f,e);Br(this,"nodeDataArray",de,"nodeDataArray",this,b,a);for(b=0;b<c;b++)d=a[b],this.fm(d),this.em(d);Array.isArray(a)||(this.isReadOnly=!0)}}},copyNodeDataFunction:{
get:function(){return this.Ak},set:function(a){var b=this.Ak;b!==a&&(this.Ak=a,this.g("copyNodeDataFunction",b,a))}},copiesArrays:{get:function(){return this.Km},set:function(a){var b=this.Km;b!==a&&(this.Km=a,this.g("copiesArrays",b,a))}},copiesArrayObjects:{get:function(){return this.Jm},set:function(a){var b=this.Jm;b!==a&&(this.Jm=a,this.g("copiesArrayObjects",b,a))}},copiesKey:{get:function(){return this.Mm},
set:function(a){var b=this.Mm;b!==a&&(this.Mm=a,this.g("copiesKey",b,a))}},afterCopyFunction:{get:function(){return this.xm},set:function(a){var b=this.xm;b!==a&&(this.xm=a,this.g("afterCopyFunction",b,a))}},nodeCategoryProperty:{get:function(){return this.lj},set:function(a){var b=this.lj;b!==a&&(this.lj=a,this.g("nodeCategoryProperty",b,a))}},type:{get:function(){return"Model"}}});
Z.prototype.setCategoryForNodeData=Z.prototype.jm;Z.prototype.getCategoryForNodeData=Z.prototype.Ro;Z.prototype.removeArrayItem=Z.prototype.pv;Z.prototype.insertArrayItem=Z.prototype.ts;Z.prototype.addArrayItem=Z.prototype.By;Z.prototype.assignAllDataProperties=Z.prototype.Do;Z.prototype.set=Z.prototype.set;Z.prototype.clearUnresolvedReferences=Z.prototype.Ry;Z.prototype.mergeNodeDataArray=Z.prototype.aA;Z.prototype.removeNodeDataCollection=Z.prototype.oA;Z.prototype.removeNodeData=Z.prototype.cm;
Z.prototype.addNodeDataCollection=Z.prototype.Gy;Z.prototype.addNodeData=Z.prototype.xf;Z.prototype.makeNodeDataKeyUnique=Z.prototype.av;Z.prototype.findNodeDataForKey=Z.prototype.ic;Z.prototype.containsNodeData=Z.prototype.lb;Z.prototype.setKeyForNodeData=Z.prototype.km;Z.prototype.getKeyForNodeData=Z.prototype.wa;Z.prototype.updateTargetBindings=Z.prototype.La;Z.prototype.commit=Z.prototype.commit;Z.prototype.rollbackTransaction=Z.prototype.Kf;Z.prototype.commitTransaction=Z.prototype.eb;
Z.prototype.startTransaction=Z.prototype.Ea;Z.prototype.raiseDataChanged=Z.prototype.Fs;Z.prototype.raiseChanged=Z.prototype.g;Z.prototype.raiseChangedEvent=Z.prototype.cb;Z.prototype.removeChangedListener=Z.prototype.hk;Z.prototype.addChangedListener=Z.prototype.Pe;Z.prototype.writeJsonValue=Z.prototype.sm;Z.prototype.replaceJsonObjects=Z.prototype.dm;Z.prototype.applyIncrementalJSON=Z.prototype.Ly;Z.prototype.applyIncrementalJson=Z.prototype.$w;Z.prototype.toJSON=Z.prototype.toJSON;
Z.prototype.toJson=Z.prototype.np;Z.prototype.toIncrementalJSON=Z.prototype.zA;Z.prototype.toIncrementalJson=Z.prototype.ay;Z.prototype.toIncrementalData=Z.prototype.yA;Z.prototype.clear=Z.prototype.clear;var Dr=!1,Er={};Z.className="Model";
Z.fromJSON=Z.fromJson=function(a,b){void 0===b&&(b=null);var c=null;if("string"===typeof a)try{c=x.JSON.parse(a)}catch(f){}else"object"===typeof a?c=a:C("Unable to construct a Model from: "+a);if(null===b){a=null;var d=c["class"];if("string"===typeof d)try{var e=null;0===d.indexOf("go.")?(d=d.substr(3),e=Ar(d)):(e=Ar(d),null===e&&(e=x[d]));"function"===typeof e&&(a=new e)}catch(f){}null===a||a instanceof Z?b=a:C("Unable to construct a Model of declared class: "+c["class"])}null===b&&(b=Z.constructGraphLinksModel());
b.gp(c);b.lv(c);return b};Z.safePropertyValue=Kn;Z.safePropertySet=Zj;Er.Brush=vl;Er.ChangedEvent=ce;Er.Geometry=P;Er.Margin=cc;Er.Panel=W;Er.Point=I;Er.Rect=N;Er.Size=M;Er.Spot=O;Er.Transaction=he;Er.UndoManager=ie;function zi(a,b,c,d){Qa(this);this._isFrozen=!1;void 0===a&&(a="");void 0===b&&(b=a);void 0===c&&(c=null);this.j=-1;this.xd=null;this.xl=a;this.wl=this.to=0;this.Cr=null;this.wn=!1;this.sl=b;this.Im=c;void 0===d?(this.dl=Fr,this.vk=null):(this.dl=Dn,this.vk=d);this.st=new H}
zi.prototype.copy=function(){var a=new zi;a.xl=this.xl;a.to=this.to;a.wl=this.wl;a.Cr=this.Cr;a.wn=this.wn;a.sl=this.sl;a.Im=this.Im;a.dl=this.dl;a.vk=this.vk;return a};t=zi.prototype;t.qb=function(a){a.classType===zi&&(this.mode=a)};t.toString=function(){return"Binding("+this.targetProperty+":"+this.sourceProperty+(-1!==this.Ai?" "+this.Ai:"")+" "+this.mode.name+")"};t.freeze=function(){this._isFrozen=!0;return this};t.ka=function(){this._isFrozen=!1;return this};
t.Lx=function(a){void 0===a&&(a=null);this.mode=Dn;this.backConverter=a;return this};t.cp=function(a){void 0===a&&(a="");this.sourceName=a;this.isToModel=!1;return this};t.eA=function(){this.sourceName=null;this.isToModel=!0;return this};function bl(a,b,c){a=a.sourceName;return null===a||""===a?b:"/"===a?c.part:"."===a?c:".."===a?c.panel:b.fb(a)}
t.Nv=function(a,b,c){var d=this.sl;if(void 0===c||""===d||d===c){c=this.xl;var e=this.Im;if(null===e&&""===c)ya("Binding error: target property is the empty string: "+this.toString());else{var f=b;""!==d&&(f=Kn(b,d));if(void 0!==f)if(null===e)""!==c&&Zj(a,c,f);else try{if(""!==c){var g=e(f,a);Zj(a,c,g)}else e(f,a)}catch(h){}}}};
t.sp=function(a,b,c,d){if(this.dl===Dn){var e=this.xl;if(void 0===c||e===c){c=this.sl;var f=this.vk,g=a;""!==e&&(g=Kn(a,e));if(void 0!==g&&!this.st.contains(a))try{this.st.add(a);var h=null!==d?d.diagram:null,k=null!==h?h.model:null;if(null===f)if(""!==c)null!==k?k.setDataProperty(b,c,g):Zj(b,c,g);else{if(null!==k&&null!==d&&0<=d.itemIndex&&null!==d.panel&&Array.isArray(d.panel.itemArray)){var l=d.itemIndex,m=d.panel.itemArray;k.pv(m,l);k.ts(m,l,g)}}else try{if(""!==c){var n=f(g,b,k);null!==k?k.setDataProperty(b,
c,n):Zj(b,c,n)}else{var p=f(g,b,k);if(void 0!==p&&null!==k&&null!==d&&0<=d.itemIndex&&null!==d.panel&&Array.isArray(d.panel.itemArray)){var q=d.itemIndex,r=d.panel.itemArray;k.pv(r,q);k.ts(r,q,p)}}}catch(u){}}finally{this.st.remove(a)}}}};
ma.Object.defineProperties(zi.prototype,{Ai:{get:function(){return this.j},set:function(a){this._isFrozen&&va(this);this.j=a}},targetProperty:{get:function(){return this.xl},set:function(a){this._isFrozen&&va(this);this.xl=a}},sourceName:{get:function(){return this.Cr},set:function(a){this._isFrozen&&va(this);this.Cr=a;null!==a&&(this.wn=!1)}},isToModel:{get:function(){return this.wn},
set:function(a){this._isFrozen&&va(this);this.wn=a}},sourceProperty:{get:function(){return this.sl},set:function(a){this._isFrozen&&va(this);this.sl=a}},converter:{get:function(){return this.Im},set:function(a){this._isFrozen&&va(this);this.Im=a}},backConverter:{get:function(){return this.vk},set:function(a){this._isFrozen&&va(this);this.vk=a}},mode:{get:function(){return this.dl},
set:function(a){this._isFrozen&&va(this);this.dl=a}}});zi.prototype.updateSource=zi.prototype.sp;zi.prototype.updateTarget=zi.prototype.Nv;zi.prototype.ofModel=zi.prototype.eA;zi.prototype.ofObject=zi.prototype.cp;zi.prototype.makeTwoWay=zi.prototype.Lx;var Si="Binding",Fr=new D(zi,"OneWay",1),Dn=new D(zi,"TwoWay",2);zi.className="Binding";zi.parseEnum=function(a,b){return function(c){c=Ra(a,c);return null===c?b:c}};zi.toString=Ia;zi.OneWay=Fr;zi.TwoWay=Dn;
function Gr(a,b,c){Z.call(this);this.Wv=',\n  "insertedLinkKeys": ';this.jy=',\n  "modifiedLinkData": ';this.Yv=',\n  "removedLinkKeys": ';this.md=[];this.$f=new H;this.xb=new ob;this.Jh="";this.Ri=this.zk=this.bl=null;this.lf="from";this.mf="to";this.fj=this.ej="";this.dj="category";this.je="";this.il="isGroup";this.Je="group";this.Lm=!1;void 0!==b&&(this.linkDataArray=b);void 0!==a&&(Aa(a)?this.nodeDataArray=a:c=a);c&&(Object.assign(this,c),a=c.Changed)&&(delete this.Changed,this.Pe(a))}la(Gr,Z);
Gr.constructGraphLinksModel=Z.constructGraphLinksModel;Gr.prototype.cloneProtected=function(a){Z.prototype.cloneProtected.call(this,a);a.Jh=this.Jh;a.bl=this.bl;a.zk=this.zk;a.lf=this.lf;a.mf=this.mf;a.ej=this.ej;a.fj=this.fj;a.dj=this.dj;a.je=this.je;a.il=this.il;a.Je=this.Je;a.Lm=this.Lm};t=Gr.prototype;t.clear=function(){Z.prototype.clear.call(this);this.md=[];this.xb.clear();this.$f.clear()};
t.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.np();var b=(""!==this.name?this.name:"")+" GraphLinksModel";if(0<a){b+="\n node data:";a=this.nodeDataArray;var c=a.length,d;for(d=0;d<c;d++){var e=a[d];b+=" "+this.wa(e)+":"+Ia(e)}b+="\n link data:";a=this.linkDataArray;c=a.length;for(d=0;d<c;d++)e=a[d],b+=" "+cr(this,e,!0)+"--\x3e"+cr(this,e,!1)}return b};
t.Rv=function(a,b){""===this.linkKeyProperty&&C("GraphLinksModel.linkKeyProperty must not be an empty string for .toIncrementalData() to succeed.");var c=Z.prototype.Rv.call(this,a,b),d=this,e=new H,f=new H,g=new H,h=this.vh;a.changes.each(function(a){a.model===d&&("linkDataArray"===a.modelChange?a.change===fe?e.add(a.newValue):a.change===ge&&g.add(a.oldValue):d.Jc(a.object)?f.add(a.object):null!==a.object&&(null!==a.object&&h&&h.contains(a.object)?h.get(a.object).each(function(a){d.Jc(a)&&f.add(a)}):
a.object instanceof Z||d.lb(a.object)||Hr(d,a.object).each(function(a){f.add(a)})))});var k=this.hl;k.clear();e.each(function(a){k.add(d.Ac(a));b||f.add(a)});var l=this.jl;l.clear();g.each(function(a){l.add(d.Ac(a));b&&f.add(a)});a=d.cloneDeep(f.Fa());0<k.count&&(null===c&&(c={}),b?c.removedLinkKeys=k.Fa():c.insertedLinkKeys=k.Fa());0<a.length&&(null===c&&(c={}),c.modifiedLinkData=a);0<l.count&&(null===c&&(c={}),b?c.insertedLinkKeys=l.Fa():c.removedLinkKeys=l.Fa());l.clear();k.clear();return c};
t.rm=function(){var a=Z.prototype.rm.call(this),b="";"category"!==this.linkCategoryProperty&&"string"===typeof this.linkCategoryProperty&&(b+=',\n  "linkCategoryProperty": '+this.quote(this.linkCategoryProperty));""!==this.linkKeyProperty&&"string"===typeof this.linkKeyProperty&&(b+=',\n  "linkKeyProperty": '+this.quote(this.linkKeyProperty));"from"!==this.linkFromKeyProperty&&"string"===typeof this.linkFromKeyProperty&&(b+=',\n  "linkFromKeyProperty": '+this.quote(this.linkFromKeyProperty));"to"!==
this.linkToKeyProperty&&"string"===typeof this.linkToKeyProperty&&(b+=',\n  "linkToKeyProperty": '+this.quote(this.linkToKeyProperty));""!==this.linkFromPortIdProperty&&"string"===typeof this.linkFromPortIdProperty&&(b+=',\n  "linkFromPortIdProperty": '+this.quote(this.linkFromPortIdProperty));""!==this.linkToPortIdProperty&&"string"===typeof this.linkToPortIdProperty&&(b+=',\n  "linkToPortIdProperty": '+this.quote(this.linkToPortIdProperty));""!==this.linkLabelKeysProperty&&"string"===typeof this.linkLabelKeysProperty&&
(b+=',\n  "linkLabelKeysProperty": '+this.quote(this.linkLabelKeysProperty));"isGroup"!==this.nodeIsGroupProperty&&"string"===typeof this.nodeIsGroupProperty&&(b+=',\n  "nodeIsGroupProperty": '+this.quote(this.nodeIsGroupProperty));"group"!==this.nodeGroupKeyProperty&&"string"===typeof this.nodeGroupKeyProperty&&(b+=',\n  "nodeGroupKeyProperty": '+this.quote(this.nodeGroupKeyProperty));return a+b};
t.gp=function(a){Z.prototype.gp.call(this,a);a.linkKeyProperty&&(this.linkKeyProperty=a.linkKeyProperty);a.linkFromKeyProperty&&(this.linkFromKeyProperty=a.linkFromKeyProperty);a.linkToKeyProperty&&(this.linkToKeyProperty=a.linkToKeyProperty);a.linkFromPortIdProperty&&(this.linkFromPortIdProperty=a.linkFromPortIdProperty);a.linkToPortIdProperty&&(this.linkToPortIdProperty=a.linkToPortIdProperty);a.linkCategoryProperty&&(this.linkCategoryProperty=a.linkCategoryProperty);a.linkLabelKeysProperty&&(this.linkLabelKeysProperty=
a.linkLabelKeysProperty);a.nodeIsGroupProperty&&(this.nodeIsGroupProperty=a.nodeIsGroupProperty);a.nodeGroupKeyProperty&&(this.nodeGroupKeyProperty=a.nodeGroupKeyProperty)};t.Sv=function(){var a=Z.prototype.Sv.call(this),b=',\n  "linkDataArray": '+ur(this,this.linkDataArray,!0);return a+b};t.lv=function(a){Z.prototype.lv.call(this,a);a=a.linkDataArray;Array.isArray(a)&&(this.dm(a),this.linkDataArray=a)};
function Hr(a,b){for(var c=new H,d=0;d<a.linkDataArray.length;d++){var e=a.linkDataArray[d];vr(a,b,e,e,c)}return c}
t.Qv=function(a,b){""===this.linkKeyProperty&&C("GraphLinksModel.linkKeyProperty must not be an empty string for .toIncrementalJson() to succeed.");var c=Z.prototype.Qv.call(this,a,b),d=this,e=new H,f=new H,g=new H,h=this.vh;a.changes.each(function(a){a.model===d&&("linkDataArray"===a.modelChange?a.change===fe?e.add(a.newValue):a.change===ge&&g.add(a.oldValue):d.Jc(a.object)?f.add(a.object):null!==a.object&&(null!==a.object&&h&&h.contains(a.object)?h.get(a.object).each(function(a){d.Jc(a)&&f.add(a)}):
a.object instanceof Z||d.lb(a.object)||Hr(d,a.object).each(function(a){f.add(a)})))});var k=this.hl;k.clear();e.each(function(a){k.add(d.Ac(a));b||f.add(a)});var l=this.jl;l.clear();g.each(function(a){l.add(d.Ac(a));b&&f.add(a)});a=c;0<k.count&&(a+=(b?this.Yv:this.Wv)+ur(this,k.Fa(),!0));0<f.count&&(a+=this.jy+ur(this,f.Fa(),!0));0<l.count&&(a+=(b?this.Wv:this.Yv)+ur(this,l.Fa(),!0));l.clear();k.clear();return a};
t.fp=function(a){(void 0!==a.linkCategoryProperty&&a.linkCategoryProperty!==this.linkCategoryProperty||void 0!==a.linkKeyProperty&&a.linkKeyProperty!==this.linkKeyProperty||void 0!==a.linkFromKeyProperty&&a.linkFromKeyProperty!==this.linkFromKeyProperty||void 0!==a.linkToKeyProperty&&a.linkToKeyProperty!==this.linkToKeyProperty||void 0!==a.linkFromPortIdProperty&&a.linkFromPortIdProperty!==this.linkFromPortIdProperty||void 0!==a.linkToPortIdProperty&&a.linkToPortIdProperty!==this.linkToPortIdProperty||
void 0!==a.linkLabelKeysProperty&&a.linkLabelKeysProperty!==this.linkLabelKeysProperty||void 0!==a.nodeIsGroupProperty&&a.nodeIsGroupProperty!==this.nodeIsGroupProperty||void 0!==a.nodeGroupKeyProperty&&a.nodeGroupKeyProperty!==this.nodeGroupKeyProperty)&&C("applyIncrementalJson cannot change Model properties");Z.prototype.fp.call(this,a);var b=a.insertedLinkKeys;if(Array.isArray(b))for(var c=b.length,d=0;d<c;d++){var e=b[d],f=this.jh(e);null===f&&(f=this.Jo({}),this.Ks(f,e),this.fi(f))}b=a.modifiedLinkData;
if(Array.isArray(b))for(c=b.length,d=0;d<c;d++)if(e=b[d],f=this.Ac(e),f=this.jh(f),null!==f){for(var g in e)"__gohashid"!==g&&g!==this.linkKeyProperty&&this.setDataProperty(f,g,e[g]);Ir(this,e,f)}a=a.removedLinkKeys;if(Array.isArray(a))for(g=a.length,b=0;b<g;b++)c=this.jh(a[b]),null!==c&&this.bm(c)};
Gr.prototype.changeState=function(a,b){if(null!==a&&a.model===this){if(a.change===de){var c=a.object,d=a.propertyName;if(d===this.linkKeyProperty&&this.Jc(c)){var e=a.I(b);b=a.I(!b);void 0!==b&&this.xb.remove(b);void 0!==e&&this.xb.add(e,c);Zj(c,d,e);return}}else if(a.change===fe){c=a.newParam;if("linkDataArray"===a.modelChange){a=a.newValue;za(a)&&"number"===typeof c&&(d=this.Ac(a),b?(this.$f.remove(a),this.md[c]===a&&this.md.splice(c,1),void 0!==d&&this.xb.remove(d)):(this.$f.add(a),this.md[c]!==
a&&this.md.splice(c,0,a),void 0!==d&&this.xb.add(d,a)));return}if("linkLabelKeys"===a.modelChange){d=this.Ef(a.object);Array.isArray(d)&&"number"===typeof c&&(b?(c=d.indexOf(a.newValue),0<=c&&d.splice(c,1)):0>d.indexOf(a.newValue)&&d.splice(c,0,a.newValue));return}}else if(a.change===ge){c=a.oldParam;if("linkDataArray"===a.modelChange){a=a.oldValue;za(a)&&"number"===typeof c&&(d=this.Ac(a),b?(this.$f.add(a),this.md[c]!==a&&this.md.splice(c,0,a),void 0!==d&&this.xb.add(d,a)):(this.$f.remove(a),this.md[c]===
a&&this.md.splice(c,1),void 0!==d&&this.xb.remove(d)));return}if("linkLabelKeys"===a.modelChange){d=this.Ef(a.object);Array.isArray(d)&&"number"===typeof c&&(b?0>d.indexOf(a.newValue)&&d.splice(c,0,a.newValue):(c=d.indexOf(a.newValue),0<=c&&d.splice(c,1)));return}}Z.prototype.changeState.call(this,a,b)}};t=Gr.prototype;t.Vl=function(a){if(void 0!==a){var b=this.Ri;if(null!==b){var c=this.ic(a);null===c&&(c=this.copyNodeData(b),Zj(c,this.nodeKeyProperty,a),this.xf(c))}return a}};
t.zx=function(a){return cr(this,a,!0)};t.xv=function(a,b){kr(this,a,b,!0)};t.Cx=function(a){return cr(this,a,!1)};t.Cv=function(a,b){kr(this,a,b,!1)};function cr(a,b,c){if(null!==b&&(a=c?a.lf:a.mf,""!==a&&(a=Kn(b,a),void 0!==a))){if(er(a))return a;C((c?"FromKey":"ToKey")+" value for link data "+b+" is not a number or a string: "+a)}}
function kr(a,b,c,d){null===c&&(c=void 0);if(null!==b){var e=d?a.lf:a.mf;if(""!==e)if(c=a.Vl(c),a.Jc(b)){var f=Kn(b,e);f!==c&&(ir(a,f,b),Zj(b,e,c),null===a.ic(c)&&jr(a,c,b),Br(a,d?"linkFromKey":"linkToKey",de,e,b,f,c),"string"===typeof e&&a.La(b,e))}else Zj(b,e,c)}}t.Ax=function(a){return br(this,a,!0)};t.yv=function(a,b){lr(this,a,b,!0)};t.Ex=function(a){return br(this,a,!1)};t.Dv=function(a,b){lr(this,a,b,!1)};
function br(a,b,c){if(null===b)return"";a=c?a.ej:a.fj;if(""===a)return"";b=Kn(b,a);return void 0===b?"":b}function lr(a,b,c,d){if(null!==b){var e=d?a.ej:a.fj;if(""!==e)if(a.Jc(b)){var f=Kn(b,e);void 0===f&&(f="");f!==c&&(Zj(b,e,c),Br(a,d?"linkFromPortId":"linkToPortId",de,e,b,f,c),"string"===typeof e&&a.La(b,e))}else Zj(b,e,c)}}t.Ef=function(a){if(null===a)return Jr;var b=this.je;if(""===b)return Jr;a=Kn(a,b);return void 0===a?Jr:a};
t.Ls=function(a,b){if(null!==a){var c=this.je;if(""!==c)if(this.Jc(a)){var d=Kn(a,c);void 0===d&&(d=Jr);if(d!==b){if(Array.isArray(d))for(var e=d.length,f=0;f<e;f++)ir(this,d[f],a);Zj(a,c,b);e=b.length;for(f=0;f<e;f++){var g=b[f];null===this.ic(g)&&jr(this,g,a)}Br(this,"linkLabelKeys",de,c,a,d,b);"string"===typeof c&&this.La(a,c)}}else Zj(a,c,b)}};
t.Bu=function(a,b){if(null!==b&&void 0!==b&&null!==a){var c=this.je;if(""!==c){var d=Kn(a,c);if(void 0===d)c=[],c.push(b),this.Ls(a,c);else if(Array.isArray(d)){var e=d.indexOf(b);0<=e||(e=d.length,d.push(b),this.Jc(a)&&(null===this.ic(b)&&jr(this,b,a),Br(this,"linkLabelKeys",fe,c,a,null,b,null,e)))}else C(c+" property is not an Array; cannot addLabelKeyForLinkData: "+a)}}};
t.Rx=function(a,b){if(null!==b&&void 0!==b&&null!==a){var c=this.je;if(""!==c){var d=Kn(a,c);if(Array.isArray(d)){var e=d.indexOf(b);0>e||(d.splice(e,1),this.Jc(a)&&(ir(this,b,a),Br(this,"linkLabelKeys",ge,c,a,b,null,e,null)))}else void 0!==d&&C(c+" property is not an Array; cannot removeLabelKeyforLinkData: "+a)}}};t.Ac=function(a){if(null!==a){var b=this.Jh;if(""!==b&&(b=Kn(a,b),void 0!==b)){if(er(b))return b;C("Key value for link data "+a+" is not a number or a string: "+b)}}};
t.Ks=function(a,b){if(null!==a){var c=this.Jh;if(""!==c)if(this.Jc(a)){var d=Kn(a,c);d!==b&&null===this.jh(b)&&(Zj(a,c,b),void 0!==d&&this.xb.remove(d),this.xb.add(b,a),Br(this,"linkKey",de,c,a,d,b),"string"===typeof c&&this.La(a,c))}else Zj(a,c,b)}};t.jh=function(a){null===a&&C("GraphLinksModel.findLinkDataForKey:key must not be null");return void 0!==a&&er(a)?this.xb.I(a):null};
t.Bs=function(a){if(null!==a){var b=this.Jh;if(""!==b){var c=this.Ac(a);if(void 0===c||this.xb.contains(c)){var d=this.bl;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.xb.contains(c))){Zj(a,b,c);return}if("string"===typeof c){for(d=2;this.xb.contains(c+d);)d++;Zj(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(c=-this.xb.count-1;this.xb.contains(c);)c--;Zj(a,b,c)}}}}};t.Jc=function(a){return null===a?!1:this.$f.contains(a)};t.fi=function(a){null!==a&&(db(a),this.Jc(a)||gr(this,a,!0))};
function gr(a,b,c){if(""!==a.linkKeyProperty){var d=a.Ac(b);if(void 0!==d&&a.xb.I(d)===b)return;a.Bs(b);d=a.Ac(b);void 0===d&&C("GraphLinksModel.makeLinkDataKeyUnique failed on "+b+". Data not added to model.");a.xb.add(d,b)}a.$f.add(b);d=null;c&&(d=a.md.length,a.md.splice(d,0,b));Br(a,"linkDataArray",fe,"linkDataArray",a,null,b,null,d);Kr(a,b)}t.Fy=function(a){if(Array.isArray(a))for(var b=a.length,c=0;c<b;c++)this.fi(a[c]);else for(a=a.iterator;a.next();)this.fi(a.value)};
t.bm=function(a){null!==a&&fr(this,a,!0)};function fr(a,b,c){a.$f.remove(b);var d=a.Ac(b);void 0!==d&&a.xb.remove(d);d=null;if(c){d=a.md.indexOf(b);if(0>d)return;a.md.splice(d,1)}Br(a,"linkDataArray",ge,"linkDataArray",a,b,null,d,null);c=cr(a,b,!0);ir(a,c,b);c=cr(a,b,!1);ir(a,c,b);d=a.Ef(b);if(Array.isArray(d))for(var e=d.length,f=0;f<e;f++)c=d[f],ir(a,c,b)}t.mA=function(a){if(Array.isArray(a))for(var b=a.length,c=0;c<b;c++)this.bm(a[c]);else for(a=a.iterator;a.next();)this.bm(a.value)};
t.$z=function(a){""===this.linkKeyProperty&&C("GraphLinksModel.linkKeyProperty must not be an empty string for .mergeLinkDataArray() to succeed.");if(a!==this.linkDataArray&&Aa(a)){var b=this.jl;b.clear();b.addAll(this.xb.iteratorKeys);var c=this.hl;c.clear();for(var d=a.length,e=0;e<d;e++){var f=a[e],g=this.Ac(f);if(void 0!==g){c.add(g);var h=this.jh(g);h!==f&&(null!==h?(this.Do(h,f),Ir(this,f,h)):(f=this.cloneDeep(f),this.Ks(f,g),this.fi(f)))}else g=this.cloneDeep(f),this.fi(g),c.add(this.Ac(g))}for(a=
b.iterator;a.next();)d=a.value,c.contains(d)||(d=this.jh(d))&&this.bm(d);b.clear();c.clear()}};function Kr(a,b){var c=cr(a,b,!0);c=a.Vl(c);null===a.ic(c)&&jr(a,c,b);c=cr(a,b,!1);c=a.Vl(c);null===a.ic(c)&&jr(a,c,b);var d=a.Ef(b);if(Array.isArray(d))for(var e=d.length,f=0;f<e;f++)c=d[f],null===a.ic(c)&&jr(a,c,b)}
t.Jo=function(a){if(null===a)return null;var b=this.zk;a=null!==b?b(a,this):Cr(this,a,!0);za(a)&&(Qa(a),""!==this.lf&&Zj(a,this.lf,void 0),""!==this.mf&&Zj(a,this.mf,void 0),""!==this.je&&Zj(a,this.je,[]));return a};t.Zu=function(a){if(null===a)return!1;var b=this.il;return""===b?!1:Kn(a,b)?!0:!1};t.lh=function(a){if(null!==a){var b=this.Je;if(""!==b&&(b=Kn(a,b),void 0!==b)){if(er(b))return b;C("GroupKey value for node data "+a+" is not a number or a string: "+b)}}};
t.mp=function(a,b){null===b&&(b=void 0);if(null!==a){var c=this.Je;if(""!==c)if(this.lb(a)){var d=Kn(a,c);d!==b&&(ir(this,d,a),Zj(a,c,b),null===this.ic(b)&&jr(this,b,a),Br(this,"nodeGroupKey",de,c,a,d,b),"string"===typeof c&&this.La(a,c))}else Zj(a,c,b)}};Gr.prototype.copyNodeData=function(a){if(null===a)return null;a=Z.prototype.copyNodeData.call(this,a);this.Hj||""===this.Je||void 0===Kn(a,this.Je)||Zj(a,this.Je,void 0);return a};
Gr.prototype.setDataProperty=function(a,b,c){if(this.lb(a)){if(b===this.nodeKeyProperty){this.km(a,c);return}if(b===this.nodeCategoryProperty){this.jm(a,c);return}if(b===this.nodeGroupKeyProperty){this.mp(a,c);return}}else if(this.Jc(a)){if(b===this.linkFromKeyProperty){kr(this,a,c,!0);return}if(b===this.linkToKeyProperty){kr(this,a,c,!1);return}if(b===this.linkFromPortIdProperty){lr(this,a,c,!0);return}if(b===this.linkToPortIdProperty){lr(this,a,c,!1);return}if(b===this.linkKeyProperty){this.Ks(a,
c);return}if(b===this.linkCategoryProperty){this.lp(a,c);return}if(b===this.linkLabelKeysProperty){this.Ls(a,c);return}}var d=Kn(a,b);d!==c&&(Zj(a,b,c),this.Fs(a,b,d,c))};t=Gr.prototype;t.Do=function(a,b){if(b){var c=this.lb(a),d=this.Jc(a),e;for(e in b)"__gohashid"===e||c&&e===this.nodeKeyProperty||c&&e===this.nodeIsGroupProperty&&Kn(a,e)===b[e]||d&&e===this.linkKeyProperty||this.setDataProperty(a,e,b[e])}};
t.jp=function(a,b){Z.prototype.jp.call(this,a,b);for(var c=this.kb.iterator;c.next();)this.rv(c.value,a,b);for(c=this.$f.iterator;c.next();){var d=c.value,e=a,f=b;if(cr(this,d,!0)===e){var g=this.lf;Zj(d,g,f);Br(this,"linkFromKey",de,g,d,e,f);"string"===typeof g&&this.La(d,g)}cr(this,d,!1)===e&&(g=this.mf,Zj(d,g,f),Br(this,"linkToKey",de,g,d,e,f),"string"===typeof g&&this.La(d,g));g=this.Ef(d);if(Array.isArray(g))for(var h=g.length,k=this.je,l=0;l<h;l++)g[l]===e&&(g[l]=f,Br(this,"linkLabelKeys",fe,
k,d,e,f,l,l))}};t.rv=function(a,b,c){if(this.lh(a)===b){var d=this.Je;Zj(a,d,c);Br(this,"nodeGroupKey",de,d,a,b,c);"string"===typeof d&&this.La(a,d)}};t.Mv=function(){Z.prototype.Mv.call(this);for(var a=this.linkDataArray,b=a.length,c=0;c<b;c++)Kr(this,a[c])};
t.fm=function(a){Z.prototype.fm.call(this,a);a=this.wa(a);var b=hr(this,a);if(null!==b){var c=Ea();for(b=b.iterator;b.next();){var d=b.value;if(this.lb(d)){if(this.lh(d)===a){var e=this.Je;Br(this,"nodeGroupKey",de,e,d,a,a);"string"===typeof e&&this.La(d,e);c.push(d)}}else if(cr(this,d,!0)===a&&(e=this.lf,Br(this,"linkFromKey",de,e,d,a,a),"string"===typeof e&&this.La(d,e),c.push(d)),cr(this,d,!1)===a&&(e=this.mf,Br(this,"linkToKey",de,e,d,a,a),"string"===typeof e&&this.La(d,e),c.push(d)),e=this.Ef(d),
Array.isArray(e))for(var f=e.length,g=this.je,h=0;h<f;h++)e[h]===a&&(Br(this,"linkLabelKeys",fe,g,d,a,a,h,h),c.push(d))}for(b=0;b<c.length;b++)ir(this,a,c[b]);Ga(c)}};t.em=function(a){Z.prototype.em.call(this,a);var b=this.lh(a);null===this.ic(b)&&jr(this,b,a)};t.qp=function(a){Z.prototype.qp.call(this,a);var b=this.lh(a);ir(this,b,a)};
t.ps=function(a){if(null===a)return"";var b=this.dj;if(""===b)return"";b=Kn(a,b);if(void 0===b)return"";if("string"===typeof b)return b;C("getCategoryForLinkData found a non-string category for "+a+": "+b)};Gr.prototype.getLinkCategoryForData=function(a){return this.ps(a)};Gr.prototype.lp=function(a,b){if(null!==a){var c=this.dj;if(""!==c)if(this.Jc(a)){var d=Kn(a,c);void 0===d&&(d="");d!==b&&(Zj(a,c,b),Br(this,"linkCategory",de,c,a,d,b),"string"===typeof c&&this.La(a,c))}else Zj(a,c,b)}};
Gr.prototype.setLinkCategoryForData=function(a,b){this.lp(a,b)};t=Gr.prototype;t.lm=function(a,b){Z.prototype.lm.call(this,a,b);this.mp(b,this.lh(a))};function Ir(a,b,c){a.lp(c,a.ps(b));a.xv(c,a.zx(b));a.Cv(c,a.Cx(b));a.Ls(c,a.Ef(b));a.yv(c,a.Ax(b));a.Dv(c,a.Ex(b))}t.Vj=function(){return!0};t.ri=function(){return!0};t.xs=function(){return!0};t.Wj=function(){return!0};
ma.Object.defineProperties(Gr.prototype,{archetypeNodeData:{get:function(){return this.Ri},set:function(a){var b=this.Ri;b!==a&&(this.Ri=a,this.g("archetypeNodeData",b,a))}},linkFromKeyProperty:{get:function(){return this.lf},set:function(a){var b=this.lf;b!==a&&(this.lf=a,this.g("linkFromKeyProperty",b,a))}},linkToKeyProperty:{get:function(){return this.mf},set:function(a){var b=this.mf;b!==a&&(this.mf=a,this.g("linkToKeyProperty",
b,a))}},linkFromPortIdProperty:{get:function(){return this.ej},set:function(a){var b=this.ej;b!==a&&(this.ej=a,this.g("linkFromPortIdProperty",b,a))}},linkToPortIdProperty:{get:function(){return this.fj},set:function(a){var b=this.fj;b!==a&&(this.fj=a,this.g("linkToPortIdProperty",b,a))}},linkLabelKeysProperty:{get:function(){return this.je},set:function(a){var b=this.je;b!==a&&(this.je=a,this.g("linkLabelKeysProperty",
b,a))}},linkDataArray:{get:function(){return this.md},set:function(a){var b=this.md;if(b!==a){this.xb.clear();for(var c=a.length,d=0;d<c;d++){var e=a[d];za(e)||C("GraphLinksModel.linkDataArray must only contain Objects, not: "+e);db(e)}this.md=a;if(""!==this.linkKeyProperty){d=new F;for(e=0;e<c;e++){var f=a[e],g=this.Ac(f);void 0===g?d.add(f):null!==this.xb.I(g)?d.add(f):this.xb.add(g,f)}for(d=d.iterator;d.next();)e=d.value,this.Bs(e),f=this.Ac(e),void 0!==f&&this.xb.add(f,
e)}d=new H;for(e=0;e<c;e++)d.add(a[e]);this.$f=d;Br(this,"linkDataArray",de,"linkDataArray",this,b,a);for(b=0;b<c;b++)Kr(this,a[b])}}},linkKeyProperty:{get:function(){return this.Jh},set:function(a){var b=this.Jh;if(b!==a){this.Jh=a;this.xb.clear();for(var c=this.linkDataArray.length,d=0;d<c;d++){var e=this.linkDataArray[d],f=this.Ac(e);void 0===f&&(this.Bs(e),f=this.Ac(e));void 0!==f&&this.xb.add(f,e)}this.g("linkKeyProperty",b,a)}}},makeUniqueLinkKeyFunction:{
get:function(){return this.bl},set:function(a){var b=this.bl;b!==a&&(this.bl=a,this.g("makeUniqueLinkKeyFunction",b,a))}},copyLinkDataFunction:{get:function(){return this.zk},set:function(a){var b=this.zk;b!==a&&(this.zk=a,this.g("copyLinkDataFunction",b,a))}},nodeIsGroupProperty:{get:function(){return this.il},set:function(a){var b=this.il;b!==a&&(this.il=a,this.g("nodeIsGroupProperty",b,a))}},nodeGroupKeyProperty:{
get:function(){return this.Je},set:function(a){var b=this.Je;b!==a&&(this.Je=a,this.g("nodeGroupKeyProperty",b,a))}},Hj:{get:function(){return this.Lm},set:function(a){this.Lm!==a&&(this.Lm=a)}},linkCategoryProperty:{get:function(){return this.dj},set:function(a){var b=this.dj;b!==a&&(this.dj=a,this.g("linkCategoryProperty",b,a))}},type:{get:function(){return"GraphLinksModel"}}});
Gr.prototype.setCategoryForLinkData=Gr.prototype.lp;Gr.prototype.getCategoryForLinkData=Gr.prototype.ps;Gr.prototype.assignAllDataProperties=Gr.prototype.Do;Gr.prototype.setGroupKeyForNodeData=Gr.prototype.mp;Gr.prototype.getGroupKeyForNodeData=Gr.prototype.lh;Gr.prototype.isGroupForNodeData=Gr.prototype.Zu;Gr.prototype.copyLinkData=Gr.prototype.Jo;Gr.prototype.mergeLinkDataArray=Gr.prototype.$z;Gr.prototype.removeLinkDataCollection=Gr.prototype.mA;Gr.prototype.removeLinkData=Gr.prototype.bm;
Gr.prototype.addLinkDataCollection=Gr.prototype.Fy;Gr.prototype.addLinkData=Gr.prototype.fi;Gr.prototype.containsLinkData=Gr.prototype.Jc;Gr.prototype.makeLinkDataKeyUnique=Gr.prototype.Bs;Gr.prototype.findLinkDataForKey=Gr.prototype.jh;Gr.prototype.setKeyForLinkData=Gr.prototype.Ks;Gr.prototype.getKeyForLinkData=Gr.prototype.Ac;Gr.prototype.removeLabelKeyForLinkData=Gr.prototype.Rx;Gr.prototype.addLabelKeyForLinkData=Gr.prototype.Bu;Gr.prototype.setLabelKeysForLinkData=Gr.prototype.Ls;
Gr.prototype.getLabelKeysForLinkData=Gr.prototype.Ef;Gr.prototype.setToPortIdForLinkData=Gr.prototype.Dv;Gr.prototype.getToPortIdForLinkData=Gr.prototype.Ex;Gr.prototype.setFromPortIdForLinkData=Gr.prototype.yv;Gr.prototype.getFromPortIdForLinkData=Gr.prototype.Ax;Gr.prototype.setToKeyForLinkData=Gr.prototype.Cv;Gr.prototype.getToKeyForLinkData=Gr.prototype.Cx;Gr.prototype.setFromKeyForLinkData=Gr.prototype.xv;Gr.prototype.getFromKeyForLinkData=Gr.prototype.zx;Gr.prototype.clear=Gr.prototype.clear;
var Jr=Object.freeze([]);Gr.className="GraphLinksModel";Er.GraphLinksModel=Gr;Z.constructGraphLinksModel=Z.constructGraphLinksModel=function(){return new Gr};Z.initDiagramModel=ri=function(){return new Gr};function Lr(a,b){Z.call(this);this.Ke="parent";this.Nm=!1;this.nj="parentLinkCategory";void 0!==a&&(Aa(a)?this.nodeDataArray=a:b=a);b&&(Object.assign(this,b),a=b.Changed)&&(delete this.Changed,this.Pe(a))}la(Lr,Z);Lr.constructGraphLinksModel=Z.constructGraphLinksModel;
Lr.prototype.cloneProtected=function(a){Z.prototype.cloneProtected.call(this,a);a.Ke=this.Ke;a.Nm=this.Nm;a.nj=this.nj};t=Lr.prototype;t.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.np();var b=(""!==this.name?this.name:"")+" TreeModel";if(0<a){b+="\n node data:";a=this.nodeDataArray;for(var c=a.length,d=0;d<c;d++){var e=a[d];b+=" "+this.wa(e)+":"+Ia(e)}}return b};
t.rm=function(){var a=Z.prototype.rm.call(this),b="";"parent"!==this.nodeParentKeyProperty&&"string"===typeof this.nodeParentKeyProperty&&(b+=',\n  "nodeParentKeyProperty": '+this.quote(this.nodeParentKeyProperty));return a+b};t.gp=function(a){Z.prototype.gp.call(this,a);a.nodeParentKeyProperty&&(this.nodeParentKeyProperty=a.nodeParentKeyProperty)};
t.fp=function(a){void 0!==a.nodeParentKeyProperty&&a.nodeParentKeyProperty!==this.nodeParentKeyProperty&&C("applyIncrementalJson cannot change Model properties");Z.prototype.fp.call(this,a)};t.Vl=function(a){return a};t.mh=function(a){if(null!==a){var b=this.Ke;if(""!==b&&(b=Kn(a,b),void 0!==b)){if(er(b))return b;C("ParentKey value for node data "+a+" is not a number or a string: "+b)}}};
t.qe=function(a,b){null===b&&(b=void 0);if(null!==a){var c=this.Ke;if(""!==c)if(b=this.Vl(b),this.lb(a)){var d=Kn(a,c);d!==b&&(ir(this,d,a),Zj(a,c,b),null===this.ic(b)&&jr(this,b,a),Br(this,"nodeParentKey",de,c,a,d,b),"string"===typeof c&&this.La(a,c))}else Zj(a,c,b)}};t.ss=function(a){if(null===a)return"";var b=this.nj;if(""===b)return"";b=Kn(a,b);if(void 0===b)return"";if("string"===typeof b)return b;C("getParentLinkCategoryForNodeData found a non-string category for "+a+": "+b);return""};
Lr.prototype.getLinkCategoryForData=function(a){return this.ss(a)};Lr.prototype.Ms=function(a,b){if(null!==a){var c=this.nj;if(""!==c)if(this.lb(a)){var d=Kn(a,c);void 0===d&&(d="");d!==b&&(Zj(a,c,b),Br(this,"parentLinkCategory",de,c,a,d,b),"string"===typeof c&&this.La(a,c))}else Zj(a,c,b)}};Lr.prototype.setLinkCategoryForData=function(a,b){this.Ms(a,b)};
Lr.prototype.copyNodeData=function(a){if(null===a)return null;a=Z.prototype.copyNodeData.call(this,a);this.Ij||""===this.Ke||void 0===Kn(a,this.Ke)||Zj(a,this.Ke,void 0);return a};Lr.prototype.setDataProperty=function(a,b,c){if(this.lb(a)){if(b===this.nodeKeyProperty){this.km(a,c);return}if(b===this.nodeCategoryProperty){this.jm(a,c);return}if(b===this.nodeParentKeyProperty){this.qe(a,c);return}}var d=Kn(a,b);d!==c&&(Zj(a,b,c),this.Fs(a,b,d,c))};t=Lr.prototype;
t.jp=function(a,b){Z.prototype.jp.call(this,a,b);for(var c=this.kb.iterator;c.next();)this.rv(c.value,a,b)};t.rv=function(a,b,c){if(this.mh(a)===b){var d=this.Ke;Zj(a,d,c);Br(this,"nodeParentKey",de,d,a,b,c);"string"===typeof d&&this.La(a,d)}};
t.fm=function(a){Z.prototype.fm.call(this,a);a=this.wa(a);var b=hr(this,a);if(null!==b){var c=Ea();for(b=b.iterator;b.next();){var d=b.value;if(this.lb(d)&&this.mh(d)===a){var e=this.Ke;Br(this,"nodeParentKey",de,e,d,a,a);"string"===typeof e&&this.La(d,e);c.push(d)}}for(b=0;b<c.length;b++)ir(this,a,c[b]);Ga(c)}};t.em=function(a){Z.prototype.em.call(this,a);var b=this.mh(a);b=this.Vl(b);null===this.ic(b)&&jr(this,b,a)};t.qp=function(a){Z.prototype.qp.call(this,a);var b=this.mh(a);ir(this,b,a)};
t.lm=function(a,b){Z.prototype.lm.call(this,a,b);this.Ms(b,this.ss(a));this.qe(b,this.mh(a))};t.Tl=function(){return!0};t.xs=function(){return!0};
ma.Object.defineProperties(Lr.prototype,{nodeParentKeyProperty:{get:function(){return this.Ke},set:function(a){var b=this.Ke;b!==a&&(this.Ke=a,this.g("nodeParentKeyProperty",b,a))}},Ij:{get:function(){return this.Nm},set:function(a){this.Nm!==a&&(this.Nm=a)}},parentLinkCategoryProperty:{get:function(){return this.nj},set:function(a){var b=this.nj;b!==a&&(this.nj=a,this.g("parentLinkCategoryProperty",b,a))}},
linkCategoryProperty:{get:function(){return this.parentLinkCategoryProperty},set:function(a){this.parentLinkCategoryProperty=a}},type:{get:function(){return"TreeModel"}}});Lr.prototype.setParentLinkCategoryForNodeData=Lr.prototype.Ms;Lr.prototype.getParentLinkCategoryForNodeData=Lr.prototype.ss;Lr.prototype.setParentKeyForNodeData=Lr.prototype.qe;Lr.prototype.getParentKeyForNodeData=Lr.prototype.mh;Lr.className="TreeModel";Er.TreeModel=Lr;
function Nr(a){ti.call(this);this.mw=this.Zm=this.mc=0;this.Wp=360;this.lw=Or;this.Ti=0;this.$v=new I;this.Jp=this.me=0;this.Xr=new Pr;this.gt=this.mj=0;this.uy=600;this.eo=NaN;this.Cm=1;this.ro=0;this.ul=360;this.Tb=Or;this.K=Qr;this.nd=Rr;this.hd=Gq;this.tf=6;this.Nn=Sr;a&&Object.assign(this,a)}la(Nr,ti);
Nr.prototype.cloneProtected=function(a){ti.prototype.cloneProtected.call(this,a);a.eo=this.eo;a.Cm=this.Cm;a.ro=this.ro;a.ul=this.ul;a.Tb=this.Tb;a.K=this.K;a.nd=this.nd;a.hd=this.hd;a.tf=this.tf;a.Nn=this.Nn};
Nr.prototype.qb=function(a){if(a.classType===Nr)if(a===Tr||a===Ur||a===Vr||a===Wr||a===Rr)this.sorting=a;else if(a===Xr||a===Yr||a===Qr||a===Zr)this.direction=a;else if(a===$r||a===as||a===Or||a===bs)this.arrangement=a;else{if(a===cs||a===Sr)this.nodeDiameterFormula=a}else ti.prototype.qb.call(this,a)};Nr.prototype.createNetwork=function(){return new ds(this)};
Nr.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);a=this.network.vertexes;if(1>=a.count)1===a.count&&(a=a.first(),a.centerX=0,a.centerY=0);else{var b=new F;b.addAll(a.iterator);a=new F;var c=new F;var d=this.sort(b);var e,f,g=this.Jp;var h=this.arrangement;var k=this.nodeDiameterFormula;var l=this.radius;if(!isFinite(l)||0>=l)l=NaN;var m=this.aspectRatio;if(!isFinite(m)||0>=m)m=1;var n=this.startAngle;
isFinite(n)||(n=0);var p=this.sweepAngle;if(!isFinite(p)||360<p||1>p)p=360;b=this.spacing;isFinite(b)||(b=NaN);h===bs&&k===cs?h=Or:h===bs&&k!==cs&&(h=this.arrangement);if((this.direction===Xr||this.direction===Yr)&&this.sorting!==Rr){for(k=0;!(k>=d.length);k+=2){a.add(d.M(k));if(k+1>=d.length)break;c.add(d.M(k+1))}this.direction===Xr?(this.arrangement===bs&&a.reverse(),d=new F,d.addAll(a),d.addAll(c)):(this.arrangement===bs&&c.reverse(),d=new F,d.addAll(c),d.addAll(a))}k=d.length;for(var q=f=e=0;q<
d.length;q++){var r=n+p*f*(this.direction===Qr?1:-1)/k,u=d.M(q).diameter;isNaN(u)&&(u=es(d.M(q),r));360>p&&(0===q||q===d.length-1)&&(u/=2);e+=u;f++}if(isNaN(l)||h===bs){isNaN(b)&&(b=6);if(h!==Or&&h!==bs){f=-Infinity;for(g=0;g<k;g++)q=d.M(g),e=d.M(g===k-1?0:g+1),isNaN(q.diameter)&&es(q,0),isNaN(e.diameter)&&es(e,0),f=Math.max(f,(q.diameter+e.diameter)/2);g=f+b;h===$r?l=(f+b)/(2*Math.PI/k):l=fs(this,g*(360<=p?k:k-1),m,n*Math.PI/180,p*Math.PI/180)}else l=fs(this,e+(360<=p?k:k-1)*(h!==bs?b:1.6*b),m,n*
Math.PI/180,p*Math.PI/180);f=l*m}else if(f=l*m,q=gs(this,l,f,n*Math.PI/180,p*Math.PI/180),isNaN(b)){if(h===Or||h===bs)b=(q-e)/(360<=p?k:k-1)}else if(h===Or||h===bs)q=(q-e)/(360<=p?k:k-1),q<b?(l=fs(this,e+b*(360<=p?k:k-1),m,n*Math.PI/180,p*Math.PI/180),f=l*m):b=q;else{g=-Infinity;for(e=0;e<k;e++)r=d.M(e),u=d.M(e===k-1?0:e+1),isNaN(r.diameter)&&es(r,0),isNaN(u.diameter)&&es(u,0),g=Math.max(g,(r.diameter+u.diameter)/2);g+=b;e=fs(this,g*(360<=p?k:k-1),m,n*Math.PI/180,p*Math.PI/180);e>l?(l=e,f=l*m):g=
q/(360<=p?k:k-1)}this.lw=h;this.mc=l;this.Zm=m;this.mw=n;this.Wp=p;this.Ti=b;this.me=f;this.Jp=g;b=d;d=this.lw;h=this.mc;l=this.mw;m=this.Wp;n=this.Ti;p=this.me;k=this.Jp;if(this.direction!==Xr&&this.direction!==Yr||d!==bs)if(this.direction===Xr||this.direction===Yr){g=0;switch(d){case as:g=180*hs(this,h,p,l,k)/Math.PI;break;case Or:k=b=0;g=a.first();null!==g&&(b=es(g,Math.PI/2));g=c.first();null!==g&&(k=es(g,Math.PI/2));g=180*hs(this,h,p,l,n+(b+k)/2)/Math.PI;break;case $r:g=m/b.length}if(this.direction===
Xr){switch(d){case as:is(this,a,l,Zr);break;case Or:js(this,a,l,Zr);break;case $r:ks(this,a,m/2,l,Zr)}switch(d){case as:is(this,c,l+g,Qr);break;case Or:js(this,c,l+g,Qr);break;case $r:ks(this,c,m/2,l+g,Qr)}}else{switch(d){case as:is(this,c,l,Zr);break;case Or:js(this,c,l,Zr);break;case $r:ks(this,c,m/2,l,Zr)}switch(d){case as:is(this,a,l+g,Qr);break;case Or:js(this,a,l+g,Qr);break;case $r:ks(this,a,m/2,l+g,Qr)}}}else switch(d){case as:is(this,b,l,this.direction);break;case Or:js(this,b,l,this.direction);
break;case $r:ks(this,b,m,l,this.direction);break;case bs:ls(this,b,m,l,this.direction)}else ls(this,b,m,l-m/2,Qr)}this.updateParts();this.network=null;this.isValidLayout=!0};function ks(a,b,c,d,e){var f=a.Wp,g=a.mc;a=a.me;d=d*Math.PI/180;c=c*Math.PI/180;for(var h=b.length,k=0;k<h;k++){var l=d+(e===Qr?k*c/(360<=f?h:h-1):-(k*c)/h),m=b.M(k),n=g*Math.tan(l)/a;n=Math.sqrt((g*g+a*a*n*n)/(1+n*n));m.centerX=n*Math.cos(l);m.centerY=n*Math.sin(l);m.actualAngle=180*l/Math.PI}}
function js(a,b,c,d){var e=a.mc,f=a.me,g=a.Ti;c=c*Math.PI/180;for(var h=b.length,k=0;k<h;k++){var l=b.M(k),m=b.M(k===h-1?0:k+1),n=f*Math.sin(c);l.centerX=e*Math.cos(c);l.centerY=n;l.actualAngle=180*c/Math.PI;isNaN(l.diameter)&&es(l,0);isNaN(m.diameter)&&es(m,0);l=hs(a,e,f,d===Qr?c:-c,(l.diameter+m.diameter)/2+g);c+=d===Qr?l:-l}}
function is(a,b,c,d){var e=a.mc,f=a.me,g=a.Jp;c=c*Math.PI/180;for(var h=b.length,k=0;k<h;k++){var l=b.M(k);l.centerX=e*Math.cos(c);l.centerY=f*Math.sin(c);l.actualAngle=180*c/Math.PI;l=hs(a,e,f,d===Qr?c:-c,g);c+=d===Qr?l:-l}}function ls(a,b,c,d,e){var f=a.Wp;a.mj=0;a.Xr=new Pr;if(360>c){for(f=d+(e===Qr?f:-f);0>f;)f+=360;f%=360;180<f&&(f-=360);f*=Math.PI/180;a.gt=f;ms(a,b,c,d,e)}else ns(a,b,c,d,e);a.Xr.commit(b)}
function ns(a,b,c,d,e){var f=a.mc,g=a.Ti,h=a.Zm,k=f*Math.cos(d*Math.PI/180),l=a.me*Math.sin(d*Math.PI/180),m=b.Fa();if(3===m.length)m[0].centerX=f,m[0].centerY=0,m[1].centerX=m[0].centerX-m[0].width/2-m[1].width/2-g,m[1].y=m[0].y,m[2].centerX=(m[0].centerX+m[1].centerX)/2,m[2].y=m[0].y-m[2].height-g;else if(4===m.length)m[0].centerX=f,m[0].centerY=0,m[2].centerX=-m[0].centerX,m[2].centerY=m[0].centerY,m[1].centerX=0,m[1].y=Math.min(m[0].y,m[2].y)-m[1].height-g,m[3].centerX=0,m[3].y=Math.max(m[0].y+
m[0].height+g,m[2].y+m[2].height+g);else{f=I.alloc();for(var n=0;n<m.length;n++){m[n].centerX=k;m[n].centerY=l;if(n>=m.length-1)break;os(a,k,l,m,n,e,f)||ps(a,k,l,m,n,e,f);k=f.x;l=f.y}I.free(f);a.mj++;if(!(23<a.mj)){k=m[0].centerX;l=m[0].centerY;f=m[m.length-1].centerX;n=m[m.length-1].centerY;var p=Math.abs(k-f)-((m[0].width+m[m.length-1].width)/2+g),q=Math.abs(l-n)-((m[0].height+m[m.length-1].height)/2+g);g=0;1>Math.abs(q)?Math.abs(k-f)<(m[0].width+m[m.length-1].width)/2&&(g=0):g=0<q?q:1>Math.abs(p)?
0:p;k=Math.abs(f)>Math.abs(n)?0<f!==l>n:0<n!==k<f;if(k=e===Qr?k:!k)g=-Math.abs(g),g=Math.min(g,-m[m.length-1].width),g=Math.min(g,-m[m.length-1].height);a.Xr.compare(g,m);1<Math.abs(g)&&(a.mc=8>a.mj?a.mc-g/(2*Math.PI):5>m.length&&10<g?a.mc/2:a.mc-(0<g?1.7:-2.3),a.me=a.mc*h,ns(a,b,c,d,e))}}}
function ms(a,b,c,d,e){for(var f=a.mc,g=a.me,h=a.Zm,k=f*Math.cos(d*Math.PI/180),l=g*Math.sin(d*Math.PI/180),m=I.alloc(),n=b.Fa(),p=0;p<n.length;p++){n[p].centerX=k;n[p].centerY=l;if(p>=n.length-1)break;os(a,k,l,n,p,e,m)||ps(a,k,l,n,p,e,m);k=m.x;l=m.y}I.free(m);a.mj++;if(!(23<a.mj)){k=Math.atan2(l,k);k=e===Qr?a.gt-k:k-a.gt;k=Math.abs(k)<Math.abs(k-2*Math.PI)?k:k-2*Math.PI;f=k*(f+g)/2;g=a.Xr;if(Math.abs(f)<Math.abs(g.Pl))for(g.Pl=f,g.lk=[],g.tm=[],k=0;k<n.length;k++)g.lk[k]=n[k].bounds.x,g.tm[k]=n[k].bounds.y;
1<Math.abs(f)&&(a.mc=8>a.mj?a.mc-f/(2*Math.PI):a.mc-(0<f?1.7:-2.3),a.me=a.mc*h,ms(a,b,c,d,e))}}function os(a,b,c,d,e,f,g){var h=a.mc,k=a.me,l=0;a=(d[e].width+d[e+1].width)/2+a.Ti;var m=!1;if(0<=c!==(f===Qr)){if(f=b+a,f>h){f=b-a;if(f<-h)return g.x=f,g.y=l,!1;m=!0}}else if(f=b-a,f<-h){f=b+a;if(f>h)return g.x=f,g.y=l,!1;m=!0}l=Math.sqrt(1-Math.min(1,f*f/(h*h)))*k;0>c!==m&&(l=-l);if(Math.abs(c-l)>(d[e].height+d[e+1].height)/2)return g.x=f,g.y=l,!1;g.x=f;g.y=l;return!0}
function ps(a,b,c,d,e,f,g){var h=a.mc,k=a.me,l=0;a=(d[e].height+d[e+1].height)/2+a.Ti;d=!1;if(0<=b!==(f===Qr)){if(f=c-a,f<-k){f=c+a;if(f>k){g.x=l;g.y=f;return}d=!0}}else if(f=c+a,f>k){f=c-a;if(f<-k){g.x=l;g.y=f;return}d=!0}l=Math.sqrt(1-Math.min(1,f*f/(k*k)))*h;0>b!==d&&(l=-l);g.x=l;g.y=f}Nr.prototype.commitLayout=function(){this.commitNodes();this.isRouting&&this.commitLinks()};
Nr.prototype.commitNodes=function(){var a=null!==this.group&&null!==this.group.placeholder&&this.group.isSubGraphExpanded,b=a?this.group.location.copy():null,c=this.actualCenter;a?c=new I(0,0):(c.x=this.arrangementOrigin.x+this.mc,c.y=this.arrangementOrigin.y+this.me);for(var d=this.network.vertexes.iterator;d.next();){var e=d.value;e.x+=c.x;e.y+=c.y;e.commit()}a&&(this.group.Va(),a=this.group.position.copy(),c=this.group.location.copy(),b=b.re(c.re(a)),this.group.move(b),this.$v=b.re(a))};
Nr.prototype.commitLinks=function(){for(var a=this.network.edges.iterator;a.next();)a.value.commit()};function gs(a,b,c,d,e){var f=a.uy;if(.001>Math.abs(a.Zm-1))return void 0!==d&&void 0!==e?e*b:2*Math.PI*b;a=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c;var g=0;var h=void 0!==d&&void 0!==e?e/(f+1):Math.PI/(2*(f+1));for(var k=0,l=0;l<=f;l++){void 0!==d&&void 0!==e?k=d+l*e/f:k=l*Math.PI/(2*f);var m=Math.sin(k);g+=Math.sqrt(1-a*a*m*m)*h}return void 0!==d&&void 0!==e?(b>c?b:c)*g:4*(b>c?b:c)*g}
function fs(a,b,c,d,e){return b/(void 0!==d&&void 0!==e?gs(a,1,c,d,e):gs(a,1,c))}function hs(a,b,c,d,e){if(.001>Math.abs(a.Zm-1))return e/b;var f=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c,g=0;a=2*Math.PI/(700*a.network.vertexes.count);b>c&&(d+=Math.PI/2);for(var h=0;;h++){var k=Math.sin(d+h*a);g+=(b>c?b:c)*Math.sqrt(1-f*f*k*k)*a;if(g>=e)return h*a}}
Nr.prototype.sort=function(a){switch(this.sorting){case Vr:break;case Wr:a.reverse();break;case Tr:a.sort(this.comparer);break;case Ur:a.sort(this.comparer);a.reverse();break;case Rr:return this.$j(qs(a));default:C("Invalid sorting type.")}return a};
function qs(a){for(var b=[],c=0;c<a.length;c++)b.push(0);c=new F;for(var d=0;d<a.length;d++){var e=-1,f=-1;if(0===d)for(var g=0;g<a.length;g++){var h=a.M(g).edgesCount;h>e&&(e=h,f=g)}else for(g=0;g<a.length;g++)h=b[g],h>e&&(e=h,f=g);c.add(a.M(f));b[f]=-1;f=a.M(f);for(g=f.sourceEdges;g.next();)e=a.indexOf(g.value.fromVertex),0>e||0<=b[e]&&b[e]++;for(f=f.destinationEdges;f.next();)e=a.indexOf(f.value.toVertex),0>e||0<=b[e]&&b[e]++}return c}
Nr.prototype.$j=function(a){for(var b=[],c=0;c<a.length;c++){var d=a.M(c);b[c]=[];for(var e,f=d.destinationEdges;f.next();)e=a.indexOf(f.value.toVertex),e!==c&&0>b[c].indexOf(e)&&b[c].push(e);for(d=d.sourceEdges;d.next();)e=a.indexOf(d.value.fromVertex),e!==c&&0>b[c].indexOf(e)&&b[c].push(e)}f=[];for(c=0;c<b.length;c++)f[c]=0;c=[];var g=[],h=[];d=[];e=new F;for(var k=0,l=0;l<b.length;l++){var m=b[l].length;if(1===m)d.push(l);else if(0===m)e.add(a.M(l));else{if(0===k)c.push(l);else{for(var n=m=Infinity,
p=-1,q=[],r=0;r<c.length;r++)0>b[c[r]].indexOf(c[r===c.length-1?0:r+1])&&q.push(r===c.length-1?0:r+1);if(0===q.length)for(r=0;r<c.length;r++)q.push(r);for(r=0;r<q.length;r++){for(var u=q[r],w=b[l],v=0,y=0;y<g.length;y++){var z=f[g[y]],A=f[h[y]];if(z<A){var B=z;z=A}else B=A;if(B<u&&u<=z)for(A=0;A<w.length;A++){var E=w[A];0>c.indexOf(E)||B<f[E]&&f[E]<z||B===f[E]||z===f[E]||v++}else for(A=0;A<w.length;A++)E=w[A],0>c.indexOf(E)||B<f[E]&&f[E]<z&&B!==f[E]&&z!==f[E]&&v++}w=v;for(y=v=0;y<b[l].length;y++)B=
c.indexOf(b[l][y]),0<=B&&(B=Math.abs(u-(B>=u?B+1:B)),v+=B<c.length+1-B?B:c.length+1-B);for(y=0;y<g.length;y++)B=f[g[y]],z=f[h[y]],B>=u&&B++,z>=u&&z++,B>z&&(A=z,z=B,B=A),z-B<(c.length+2)/2===(B<u&&u<=z)&&v++;if(w<m||w===m&&v<n)m=w,n=v,p=u}c.splice(p,0,l);for(m=0;m<c.length;m++)f[c[m]]=m;for(m=0;m<b[l].length;m++)n=b[l][m],0<=c.indexOf(n)&&(g.push(l),h.push(n))}k++}}for(g=c.length;;){f=!0;for(h=0;h<d.length;h++)if(k=d[h],l=b[k][0],m=c.indexOf(l),0<=m){for(p=n=0;p<b[l].length;p++)q=c.indexOf(b[l][p]),
0>q||q===m||(r=q>m?q-m:m-q,n+=q<m!==r>g-r?1:-1);c.splice(0>n?m:m+1,0,k);d.splice(h,1);h--}else f=!1;if(f)break;else c.push(d[0]),d.splice(0,1)}for(b=0;b<c.length;b++)e.add(a.M(c[b]));return e};
ma.Object.defineProperties(Nr.prototype,{radius:{get:function(){return this.eo},set:function(a){this.eo!==a&&(0<a||isNaN(a))&&(this.eo=a,this.C())}},aspectRatio:{get:function(){return this.Cm},set:function(a){this.Cm!==a&&0<a&&(this.Cm=a,this.C())}},startAngle:{get:function(){return this.ro},set:function(a){this.ro!==a&&(this.ro=a,this.C())}},sweepAngle:{get:function(){return this.ul},
set:function(a){this.ul!==a&&(0<a&&360>=a?this.ul=a:this.ul=360,this.C())}},arrangement:{get:function(){return this.Tb},set:function(a){this.Tb===a||a!==bs&&a!==Or&&a!==as&&a!==$r||(this.Tb=a,this.C())}},direction:{get:function(){return this.K},set:function(a){this.K===a||a!==Qr&&a!==Zr&&a!==Xr&&a!==Yr||(this.K=a,this.C())}},sorting:{get:function(){return this.nd},set:function(a){this.nd===a||a!==Vr&&a!==Wr&&
a!==Tr&&!Ur&&a!==Rr||(this.nd=a,this.C())}},comparer:{get:function(){return this.hd},set:function(a){this.hd!==a&&(this.hd=a,this.C())}},spacing:{get:function(){return this.tf},set:function(a){this.tf!==a&&(this.tf=a,this.C())}},nodeDiameterFormula:{get:function(){return this.Nn},set:function(a){this.Nn===a||a!==Sr&&a!==cs||(this.Nn=a,this.C())}},actualXRadius:{get:function(){return this.mc}},
actualYRadius:{get:function(){return this.me}},actualSpacing:{get:function(){return this.Ti}},actualCenter:{get:function(){return this.$v}}});
var Or=new D(Nr,"ConstantSpacing",0),as=new D(Nr,"ConstantDistance",1),$r=new D(Nr,"ConstantAngle",2),bs=new D(Nr,"Packed",3),Qr=new D(Nr,"Clockwise",4),Zr=new D(Nr,"Counterclockwise",5),Xr=new D(Nr,"BidirectionalLeft",6),Yr=new D(Nr,"BidirectionalRight",7),Vr=new D(Nr,"Forwards",8),Wr=new D(Nr,"Reverse",9),Tr=new D(Nr,"Ascending",10),Ur=new D(Nr,"Descending",11),Rr=new D(Nr,"Optimized",12),Sr=new D(Nr,"Pythagorean",13),cs=new D(Nr,"Circular",14);Nr.className="CircularLayout";Nr.ConstantSpacing=Or;
Nr.ConstantDistance=as;Nr.ConstantAngle=$r;Nr.Packed=bs;Nr.Clockwise=Qr;Nr.Counterclockwise=Zr;Nr.BidirectionalLeft=Xr;Nr.BidirectionalRight=Yr;Nr.Forwards=Vr;Nr.Reverse=Wr;Nr.Ascending=Tr;Nr.Descending=Ur;Nr.Optimized=Rr;Nr.Pythagorean=Sr;Nr.Circular=cs;function Pr(){this.Pl=-Infinity;this.tm=this.lk=null}
Pr.prototype.compare=function(a,b){if(0<a&&0>this.Pl||Math.abs(a)<Math.abs(this.Pl)&&!(0>a&&0<this.Pl))for(this.Pl=a,this.lk=[],this.tm=[],a=0;a<b.length;a++)this.lk[a]=b[a].bounds.x,this.tm[a]=b[a].bounds.y};Pr.prototype.commit=function(a){if(null!==this.lk&&null!==this.tm)for(var b=0;b<this.lk.length;b++){var c=a.M(b);c.x=this.lk[b];c.y=this.tm[b]}};Pr.className="VertexArrangement";function ds(a){wq.call(this,a)}la(ds,wq);ds.prototype.createVertex=function(){return new rs(this)};
ds.prototype.createEdge=function(){return new ss(this)};ds.className="CircularNetwork";function rs(a){zq.call(this,a);this.u=this.Si=NaN}la(rs,zq);
function es(a,b){var c=a.network;if(null===c)return NaN;c=c.layout;if(null===c)return NaN;if(c.arrangement===bs)if(c.nodeDiameterFormula===cs)a.Si=Math.max(a.width,a.height);else{c=Math.abs(Math.sin(b));b=Math.abs(Math.cos(b));if(0===c)return a.width;if(0===b)return a.height;a.Si=Math.min(a.height/c,a.width/b)}else a.Si=c.nodeDiameterFormula===cs?Math.max(a.width,a.height):Math.sqrt(a.width*a.width+a.height*a.height);return a.Si}
ma.Object.defineProperties(rs.prototype,{diameter:{get:function(){return this.Si},set:function(a){this.Si!==a&&(this.Si=a)}},actualAngle:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a)}}});rs.className="CircularVertex";function ss(a){Aq.call(this,a)}la(ss,Aq);ss.className="CircularEdge";
function ts(a){ti.call(this);this.ah=null;this.An=0;this.$d=(new M(100,100)).freeze();this.Bm=!1;this.sf=!0;this.td=!1;this.cl=100;this.$m=1;this.Zf=1E3;this.In=10;this.fo=Math;this.Gk=.05;this.Fk=50;this.Dk=150;this.Ek=0;this.Vm=10;this.Um=5;a&&Object.assign(this,a)}la(ts,ti);
ts.prototype.cloneProtected=function(a){ti.prototype.cloneProtected.call(this,a);a.$d.assign(this.$d);a.Bm=this.Bm;a.sf=this.sf;a.td=this.td;a.cl=this.cl;a.$m=this.$m;a.Zf=this.Zf;a.In=this.In;a.fo=this.fo;a.Gk=this.Gk;a.Fk=this.Fk;a.Dk=this.Dk;a.Ek=this.Ek;a.Vm=this.Vm;a.Um=this.Um};ts.prototype.createNetwork=function(){return new us(this)};
ts.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));a=this.maxIterations;if(0<this.network.vertexes.count){this.network.Ko();for(var b=this.network.vertexes.iterator;b.next();){var c=b.value;c.charge=this.electricalCharge(c);c.mass=this.gravitationalMass(c)}for(b=this.network.edges.iterator;b.next();)c=b.value,c.stiffness=this.springStiffness(c),c.length=this.springLength(c);this.Cu();this.An=0;if(this.needsClusterLayout()){b=this.network;c=b.$x();for(var d=c.iterator;d.next();){this.network=
d.value;for(var e=this.network.vertexes.iterator;e.next();){var f=e.value;f.Vd=f.vertexes.count;f.sh=1;f.Gj=null;f.We=null}vs(this,0,a)}this.network=b;var g=d=c.iterator;d=this.arrangementSpacing;var h=g.count,k=!0;f=e=0;for(var l=Ea(),m=0;m<h+b.vertexes.count+2;m++)l[m]=null;h=0;g.reset();for(var n=N.alloc();g.next();)if(m=g.value,this.computeBounds(m,n),k)k=!1,e=n.x+n.width/2,f=n.y+n.height/2,l[0]=new I(n.x+n.width+d.width,n.y),l[1]=new I(n.x,n.y+n.height+d.height),h=2;else{var p=ws(l,h,e,f,n.width,
n.height,d),q=l[p],r=new I(q.x+n.width+d.width,q.y),u=new I(q.x,q.y+n.height+d.height);p+1<h&&l.splice(p+1,0,null);l[p]=r;l[p+1]=u;h++;p=q.x-n.x;q=q.y-n.y;for(m=m.vertexes.iterator;m.next();)r=m.value,r.centerX+=p,r.centerY+=q}N.free(n);for(m=b.vertexes.iterator;m.next();)g=m.value,p=g.bounds,2>h?(e=p.x+p.width/2,f=p.y+p.height/2,l[0]=new I(p.x+p.width+d.width,p.y),l[1]=new I(p.x,p.y+p.height+d.height),h=2):(k=ws(l,h,e,f,p.width,p.height,d),n=l[k],q=new I(n.x+p.width+d.width,n.y),p=new I(n.x,n.y+
p.height+d.height),k+1<h&&l.splice(k+1,0,null),l[k]=q,l[k+1]=p,h++,g.centerX=n.x+g.width/2,g.centerY=n.y+g.height/2);Ga(l);for(d=c.iterator;d.next();){c=d.value;for(e=c.vertexes.iterator;e.next();)b.dh(e.value);for(c=c.edges.iterator;c.next();)b.Bo(c.value)}}xs(this,a);this.updateParts()}this.cl=a;this.network=null;this.isValidLayout=!0};
ts.prototype.needsClusterLayout=function(){if(3>this.network.vertexes.count)return!1;for(var a=0,b=0,c=this.network.vertexes.first().bounds,d=this.network.vertexes.iterator;d.next();){if(d.value.bounds.Yc(c)&&(a++,2<a))return!0;if(10<b)break;b++}return!1};ts.prototype.computeBounds=function(a,b){var c=!0;for(a=a.vertexes.iterator;a.next();){var d=a.value;c?(c=!1,b.set(d.bounds)):b.ed(d.bounds)}return b};
function vs(a,b,c){if(ys(a,b)){var d=a.Zf;a.Zf*=1+1/(b+1);var e=zs(a,b),f=Math.max(0,Math.max(Math.min(a.network.vertexes.count,c*(b+1)/11),10));a.maxIterations+=f;vs(a,b+1,c);xs(a,f);As(a,e);b=e.vertexes.Fa();b.sort(function(a,b){return null===a||null===b||a===b?0:b.Vd-a.Vd});for(c=0;c<b.length;c++)Bs(a,b[c]);a.Zf=d}}
function ys(a,b){if(10<b||3>a.network.vertexes.count)return!1;a.ah=a.network.vertexes.Fa();a=a.ah;a.sort(function(a,b){return null===a||null===b||a===b?0:b.Vd-a.Vd});for(b=a.length-1;0<=b&&1>=a[b].Vd;)b--;return 1<a.length-b}
function zs(a,b){for(var c=a.network,d=new us(a),e=0;e<a.ah.length;e++){var f=a.ah[e];if(1<f.Vd){d.dh(f);var g=new Cs;g.Rs=f.Vd;g.Ss=f.width;g.Qs=f.height;g.Tv=f.focus.x;g.Uv=f.focus.y;null===f.We&&(f.We=new F);f.We.add(g);f.tv=f.We.count-1}else break}for(f=c.edges.iterator;f.next();){var h=f.value;e=h.fromVertex;g=h.toVertex;e.network===d&&g.network===d?d.Bo(h):e.network===d?(h=e.Gj,null===h&&(h=new F,e.Gj=h),h.add(g),e.Vd--,e.sh+=g.sh):g.network===d&&(h=g.Gj,null===h&&(h=new F,g.Gj=h),h.add(e),
g.Vd--,g.sh+=e.sh)}for(e=d.edges.iterator;e.next();)f=e.value,f.length*=Math.max(1,K.sqrt((f.fromVertex.sh+f.toVertex.sh)/(4*b+1)));for(b=d.vertexes.iterator;b.next();){e=b.value;var k=e.Gj;if(null!==k&&0<k.count&&(g=e.We.M(e.We.count-1).Rs-e.Vd,!(0>=g))){for(var l=h=0,m=k.count-g;m<k.count;m++){var n=k.M(m),p=null;for(f=n.edges.iterator;f.next();){var q=f.value;if(q.Bx(n)===e){p=q;break}}null!==p&&(l+=p.length,h+=n.width*n.height)}f=e.centerX;k=e.centerY;m=e.width;n=e.height;p=e.focus;q=m*n;1>q&&
(q=1);h=K.sqrt((h+q+l*l*4/(g*g))/q);g=(h-1)*m/2;h=(h-1)*n/2;e.bounds=new N(f-p.x-g,k-p.y-h,m+2*g,n+2*h);e.focus=new I(p.x+g,p.y+h)}}a.network=d;return c}function As(a,b){for(var c=a.network.vertexes.iterator;c.next();){var d=c.value;d.network=b;if(null!==d.We){var e=d.We.M(d.tv);d.Vd=e.Rs;var f=e.Tv,g=e.Uv;d.bounds=new N(d.centerX-f,d.centerY-g,e.Ss,e.Qs);d.focus=new I(f,g);d.tv--}}for(c=a.network.edges.iterator;c.next();)c.value.network=b;a.network=b}
function Bs(a,b){var c=b.Gj;if(null!==c&&0!==c.count){var d=b.centerX,e=b.centerY,f=b.width,g=b.height;null!==b.We&&0<b.We.count&&(g=b.We.M(0),f=g.Ss,g=g.Qs);f=K.sqrt(f*f+g*g)/2;for(var h=!1,k=g=0,l=0,m=b.vertexes.iterator;m.next();){var n=m.value;1>=n.Vd?k++:(h=!0,l++,g+=Math.atan2(b.centerY-n.centerY,b.centerX-n.centerX))}if(0!==k)for(0<l&&(g/=l),l=b=0,b=h?2*Math.PI/(k+1):2*Math.PI/k,0===k%2&&(l=b/2),1<c.count&&c.sort(function(a,b){return null===a||null===b||a===b?0:b.width*b.height-a.width*a.height}),
h=0===k%2?0:1,c=c.iterator;c.next();)if(k=c.value,!(1<k.Vd||a.isFixed(k))){m=null;for(n=k.edges.iterator;n.next();){m=n.value;break}n=k.width;var p=k.height;n=K.sqrt(n*n+p*p)/2;m=f+m.length+n;n=g+(b*(h/2>>1)+l)*(0===h%2?1:-1);k.centerX=d+m*Math.cos(n);k.centerY=e+m*Math.sin(n);h++}}}
function ws(a,b,c,d,e,f,g){var h=9E19,k=-1,l=0;a:for(;l<b;l++){var m=a[l],n=m.x-c,p=m.y-d;n=n*n+p*p;if(n<h){for(p=l-1;0<=p;p--)if(a[p].y>m.y&&a[p].x-m.x<e+g.width)continue a;for(p=l+1;p<b;p++)if(a[p].x>m.x&&a[p].y-m.y<f+g.height)continue a;k=l;h=n}}return k}ts.prototype.Cu=function(){if(this.comments)for(var a=this.network.vertexes.iterator;a.next();)this.addComments(a.value)};
ts.prototype.addComments=function(a){var b=a.node;if(null!==b)for(b=b.Nu();b.next();){var c=b.value;if("Comment"===c.category&&c.isVisible()){var d=this.network.ki(c);null===d&&(d=this.network.Bl(c));d.charge=this.defaultCommentElectricalCharge;c=null;for(var e=d.destinationEdges;e.next();){var f=e.value;if(f.toVertex===a){c=f;break}}if(null===c)for(e=d.sourceEdges;e.next();)if(f=e.value,f.fromVertex===a){c=f;break}null===c&&(c=this.network.Yj(a,d,null));c.length=this.defaultCommentSpringLength}}};
function Ds(a,b){var c=a.bounds,d=c.x;a=c.y;var e=c.width;c=c.height;var f=b.bounds,g=f.x;b=f.y;var h=f.width;f=f.height;return d+e<g?a>b+f?(c=d+e-g,a=a-b-f,K.sqrt(c*c+a*a)):a+c<b?(d=d+e-g,a=a+c-b,K.sqrt(d*d+a*a)):g-(d+e):d>g+h?a>b+f?(c=d-g-h,a=a-b-f,K.sqrt(c*c+a*a)):a+c<b?(d=d-g-h,a=a+c-b,K.sqrt(d*d+a*a)):d-(g+h):a>b+f?a-(b+f):a+c<b?b-(a+c):.1}function xs(a,b){a.ah=null;for(b=a.An+b;a.An<b&&(a.An++,Es(a)););a.ah=null}
function Es(a){null===a.ah&&(a.ah=a.network.vertexes.Fa());var b=a.ah;if(0>=b.length)return!1;var c=b[0];c.forceX=0;c.forceY=0;for(var d=c.centerX,e=d,f=c=c.centerY,g=1;g<b.length;g++){var h=b[g];h.forceX=0;h.forceY=0;var k=h.centerX;h=h.centerY;d=Math.min(d,k);e=Math.max(e,k);c=Math.min(c,h);f=Math.max(f,h)}(e=e-d>f-c)?b.sort(function(a,b){return null===a||null===b||a===b?0:a.centerX-b.centerX}):b.sort(function(a,b){return null===a||null===b||a===b?0:a.centerY-b.centerY});c=a.Zf;var l=d=h=0;for(f=
0;f<b.length;f++){g=b[f];d=g.bounds;h=g.focus;k=d.x+h.x;var m=d.y+h.y;d=g.charge*a.electricalFieldX(k,m);l=g.charge*a.electricalFieldY(k,m);d+=g.mass*a.gravitationalFieldX(k,m);l+=g.mass*a.gravitationalFieldY(k,m);g.forceX+=d;g.forceY+=l;for(var n=f+1;n<b.length;n++){var p=b[n];if(p!==g){d=p.bounds;h=p.focus;l=d.x+h.x;var q=d.y+h.y;if(k-l>c||l-k>c){if(e)break}else if(m-q>c||q-m>c){if(!e)break}else{var r=Ds(g,p);1>r?(d=a.randomNumberGenerator,null===d&&(a.randomNumberGenerator=d=new Fs),r=d.random(),
h=d.random(),k>l?(d=Math.abs(p.bounds.right-g.bounds.x),d=(1+d)*r):k<l?(d=Math.abs(p.bounds.x-g.bounds.right),d=-(1+d)*r):(d=Math.max(p.width,g.width),d=(1+d)*r-d/2),m>q?(l=Math.abs(p.bounds.bottom-g.bounds.y),l=(1+l)*h):k<l?(l=Math.abs(p.bounds.y-g.bounds.bottom),l=-(1+l)*h):(l=Math.max(p.height,g.height),l=(1+l)*h-l/2)):(h=-(g.charge*p.charge)/(r*r),d=(l-k)/r*h,l=(q-m)/r*h);g.forceX+=d;g.forceY+=l;p.forceX-=d;p.forceY-=l}}}}for(e=a.network.edges.iterator;e.next();)h=e.value,c=h.fromVertex,f=h.toVertex,
g=c.bounds,k=c.focus,d=g.x+k.x,g=g.y+k.y,m=f.bounds,n=f.focus,k=m.x+n.x,m=m.y+n.y,n=Ds(c,f),1>n?(n=a.randomNumberGenerator,null===n&&(a.randomNumberGenerator=n=new Fs),h=n.random(),n=n.random(),d=(d>k?1:-1)*(1+(f.width>c.width?f.width:c.width))*h,l=(g>m?1:-1)*(1+(f.height>c.height?f.height:c.height))*n):(h=h.stiffness*(n-h.length),d=(k-d)/n*h,l=(m-g)/n*h),c.forceX+=d,c.forceY+=l,f.forceX-=d,f.forceY-=l;for(e=d=0;e<b.length;e++)c=b[e],a.isFixed(c)?a.moveFixedVertex(c):d=Math.max(d,a.moveVertex(c)||
0);return d>a.epsilonDistance*a.epsilonDistance}ts.prototype.moveVertex=function(a){var b=a.forceX,c=a.forceY,d=this.moveLimit;b<-d?b=-d:b>d&&(b=d);c<-d?c=-d:c>d&&(c=d);a.centerX+=b;a.centerY+=c;return b*b+c*c};ts.prototype.moveFixedVertex=function(){};ts.prototype.commitLayout=function(){this.zv();this.commitNodes();this.isRouting&&this.commitLinks()};
ts.prototype.zv=function(){if(this.setsPortSpots)for(var a=this.network.edges.iterator;a.next();){var b=a.value.link;null!==b&&(b.fromSpot=Sc,b.toSpot=Sc)}};ts.prototype.commitNodes=function(){var a=0,b=0;if(this.arrangesToOrigin){var c=N.alloc();this.computeBounds(this.network,c);b=this.arrangementOrigin;a=b.x-c.x;b=b.y-c.y;N.free(c)}c=N.alloc();for(var d=this.network.vertexes.iterator;d.next();){var e=d.value;if(0!==a||0!==b)c.assign(e.bounds),c.x+=a,c.y+=b,e.bounds=c;e.commit()}N.free(c)};
ts.prototype.commitLinks=function(){for(var a=this.network.edges.iterator;a.next();)a.value.commit()};ts.prototype.springStiffness=function(a){a=a.stiffness;return isNaN(a)?this.Gk:a};ts.prototype.springLength=function(a){a=a.length;return isNaN(a)?this.Fk:a};ts.prototype.electricalCharge=function(a){a=a.charge;return isNaN(a)?this.Dk:a};ts.prototype.electricalFieldX=function(){return 0};ts.prototype.electricalFieldY=function(){return 0};
ts.prototype.gravitationalMass=function(a){a=a.mass;return isNaN(a)?this.Ek:a};ts.prototype.gravitationalFieldX=function(){return 0};ts.prototype.gravitationalFieldY=function(){return 0};ts.prototype.isFixed=function(a){return a.isFixed};
ma.Object.defineProperties(ts.prototype,{currentIteration:{get:function(){return this.An}},arrangementSpacing:{get:function(){return this.$d},set:function(a){this.$d.D(a)||(this.$d.assign(a),this.C())}},arrangesToOrigin:{get:function(){return this.Bm},set:function(a){this.Bm!==a&&(this.Bm=a,this.C())}},setsPortSpots:{get:function(){return this.sf},set:function(a){this.sf!==a&&(this.sf=
a,this.C())}},comments:{get:function(){return this.td},set:function(a){this.td!==a&&(this.td=a,this.C())}},maxIterations:{get:function(){return this.cl},set:function(a){this.cl!==a&&0<=a&&(this.cl=a,this.C())}},epsilonDistance:{get:function(){return this.$m},set:function(a){this.$m!==a&&0<a&&(this.$m=a,this.C())}},infinityDistance:{get:function(){return this.Zf},set:function(a){this.Zf!==
a&&1<a&&(this.Zf=a,this.C())}},moveLimit:{get:function(){return this.In},set:function(a){this.In!==a&&1<a&&(this.In=a,this.C())}},randomNumberGenerator:{get:function(){return this.fo},set:function(a){this.fo!==a&&(null!==a&&"function"!==typeof a.random&&C('ForceDirectedLayout.randomNumberGenerator must have a "random()" function on it: '+a),this.fo=a)}},defaultSpringStiffness:{get:function(){return this.Gk},
set:function(a){this.Gk!==a&&(this.Gk=a,this.C())}},defaultSpringLength:{get:function(){return this.Fk},set:function(a){this.Fk!==a&&(this.Fk=a,this.C())}},defaultElectricalCharge:{get:function(){return this.Dk},set:function(a){this.Dk!==a&&(this.Dk=a,this.C())}},defaultGravitationalMass:{get:function(){return this.Ek},set:function(a){this.Ek!==a&&(this.Ek=a,this.C())}},defaultCommentSpringLength:{
get:function(){return this.Vm},set:function(a){this.Vm!==a&&(this.Vm=a,this.C())}},defaultCommentElectricalCharge:{get:function(){return this.Um},set:function(a){this.Um!==a&&(this.Um=a,this.C())}}});ts.className="ForceDirectedLayout";function Cs(){this.Uv=this.Tv=this.Qs=this.Ss=this.Rs=0}Cs.className="ForceDirectedSubnet";function us(a){wq.call(this,a)}la(us,wq);us.prototype.createVertex=function(){return new Gs(this)};us.prototype.createEdge=function(){return new Hs(this)};
us.className="ForceDirectedNetwork";function Gs(a){zq.call(this,a);this.ia=!1;this.Ha=this.u=NaN;this.sh=this.Vd=this.V=this.F=0;this.We=this.Gj=null;this.tv=0}la(Gs,zq);
ma.Object.defineProperties(Gs.prototype,{isFixed:{get:function(){return this.ia},set:function(a){this.ia!==a&&(this.ia=a)}},charge:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a)}},mass:{get:function(){return this.Ha},set:function(a){this.Ha!==a&&(this.Ha=a)}},forceX:{get:function(){return this.F},set:function(a){this.F!==a&&(this.F=a)}},forceY:{
get:function(){return this.V},set:function(a){this.V!==a&&(this.V=a)}}});Gs.className="ForceDirectedVertex";function Hs(a){Aq.call(this,a);this.j=this.o=NaN}la(Hs,Aq);ma.Object.defineProperties(Hs.prototype,{stiffness:{get:function(){return this.o},set:function(a){this.o!==a&&(this.o=a)}},length:{get:function(){return this.j},set:function(a){this.j!==a&&(this.j=a)}}});Hs.className="ForceDirectedEdge";
function Fs(){var a=0;void 0===a&&(a=42);this.seed=a;this.by=48271;this.gy=2147483647;this.Q=44488.07041494893;this.hy=3399;this.ey=1/2147483647;this.random()}Fs.prototype.random=function(){var a=this.seed%this.Q*this.by-this.seed/this.Q*this.hy;0<a?this.seed=a:this.seed=a+this.gy;return this.seed*this.ey};Fs.className="RandomNumberGenerator";
function Is(a){ti.call(this);this.lc=this.ie=25;this.K=0;this.Ck=Js;this.Yk=Ks;this.Pk=Ls;this.bj=4;this.qk=Ms;this.bg=15;this.kl=10;this.sf=!0;this.Cn=4;this.Ra=this.Iq=this.Ia=-1;this.Md=this.Hn=0;this.Ta=this.Kd=this.Ld=this.he=this.vb=null;this.Kn=0;this.Jn=this.kj=null;this.le=0;this.el=null;this.Rf=new I;this.He=[];this.He.length=100;this.yw=this.ve=0;a&&Object.assign(this,a)}la(Is,ti);
Is.prototype.cloneProtected=function(a){ti.prototype.cloneProtected.call(this,a);a.ie=this.ie;a.lc=this.lc;a.K=this.K;a.Ck=this.Ck;a.Yk=this.Yk;a.Pk=this.Pk;a.bj=this.bj;a.qk=this.qk;a.bg=this.bg;a.kl=this.kl;a.sf=this.sf;a.Cn=this.Cn;a.ve=this.ve};
Is.prototype.qb=function(a){a.classType===Is?0===a.name.indexOf("Aggressive")?this.aggressiveOption=a:0===a.name.indexOf("Cycle")?this.cycleRemoveOption=a:0===a.name.indexOf("Init")?this.initializeOption=a:0===a.name.indexOf("Layer")?this.layeringOption=a:C("Unknown enum value: "+a):ti.prototype.qb.call(this,a)};Is.prototype.createNetwork=function(){return new Ns(this)};
Is.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);this.Iq=-1;this.Md=this.Hn=0;this.el=this.Jn=this.kj=null;for(a=0;a<this.He.length;a++)this.He[a]=null;if(0<this.network.vertexes.count){this.network.Ko();this.cycleRemoveOption!==Os&&this.removeCycles();for(a=this.network.vertexes.iterator;a.next();)a.value.layer=-1;this.Ia=-1;this.assignLayers();for(a.reset();a.next();)this.Ia=Math.max(this.Ia,
a.value.layer);this.cycleRemoveOption===Os&&this.removeCycles();a=this.network;for(var b=[],c=a.edges.iterator;c.next();){var d=c.value;d.valid=!1;b.push(d)}for(c=0;c<b.length;c++){d=b[c];var e=d.fromVertex,f=d.toVertex;if(!(d.valid||(null===e.node&&null===e.data||null===f.node&&null===f.data)&&e.layer===f.layer)){var g=0,h=0,k=0,l=0;if(null!==d.link){h=d.link;if(null===h)continue;var m=e.node;g=f.node;if(null===m||null===g)continue;var n=h.fromNode;k=h.toNode;for(var p=h.fromPort;null!==p&&!p.Ed();)p=
p.panel;for(h=h.toPort;null!==h&&!h.Ed();)h=h.panel;if(d.rev){l=n;var q=p;n=k;p=h;k=l;h=q}var r=e.focus;l=f.focus;var u=d.rev?f.bounds:e.bounds;q=I.alloc();m!==n?u.w()&&n.isVisible()?n.actualBounds.w()?(Yo(n,p,wc,q),q.x+=n.actualBounds.x-u.x,q.y+=n.actualBounds.y-u.y):(Yo(n,p,wc,q),q.w()||q.assign(r)):q.assign(r):u.w()?(Yo(n,p,wc,q),q.w()||q.assign(r)):q.assign(r);n=d.rev?e.bounds:f.bounds;m=I.alloc();g!==k?n.w()&&k.isVisible()?k.actualBounds.w()?(Yo(k,h,wc,m),m.x+=k.actualBounds.x-n.x,m.y+=k.actualBounds.y-
n.y):(Yo(k,h,wc,m),m.w()||m.assign(l)):m.assign(l):n.w()?(Yo(k,h,wc,m),m.w()||m.assign(l)):m.assign(l);90===this.K||270===this.K?(g=Math.round((q.x-r.x)/this.lc),k=q.x,h=Math.round((m.x-l.x)/this.lc),l=m.x):(g=Math.round((q.y-r.y)/this.lc),k=q.y,h=Math.round((m.y-l.y)/this.lc),l=m.y);I.free(q);I.free(m);d.portFromColOffset=g;d.portFromPos=k;d.portToColOffset=h;d.portToPos=l}else d.portFromColOffset=0,d.portFromPos=0,d.portToColOffset=0,d.portToPos=0;q=e.layer;m=f.layer;n=0;u=d.link;if(null!==u){var w=
u.fromPort,v=u.toPort;if(null!==w&&null!==v){var y=u.fromNode;p=u.toNode;if(null!==y&&null!==p){var z=Ps(this,!0),A=Ps(this,!1),B=this.setsPortSpots?z:u.computeSpot(!0,w);r=this.setsPortSpots?A:u.computeSpot(!1,v);var E=u.isOrthogonal;B.$c()&&B.Hf(A)&&r.$c()&&r.Hf(z)?n=0:(z=u.getLinkPoint(y,w,B,!0,E,p,v,I.alloc()),A=u.getLinkDirection(y,w,z,B,!0,E,p,v),I.free(z),B.ys()||A!==Qs(this,d,!0)?this.setsPortSpots&&null!==y&&1===y.ports.count&&d.rev&&(n+=1):n+=1,B=u.getLinkPoint(p,v,r,!1,E,y,w,I.alloc()),
u=u.getLinkDirection(p,v,B,r,!1,E,y,w),I.free(B),r.ys()||u!==Qs(this,d,!1)?this.setsPortSpots&&null!==p&&1===p.ports.count&&d.rev&&(n+=2):n+=2)}}}p=n;n=1===p||3===p?!0:!1;p=2===p||3===p?!0:!1;u=null;w=void 0;p&&(this._experimental&&(r=e,u=[],u.push(f),u=Rs(this,r,u)?u.reverse():[],w=1),r=a.createVertex(),r.node=null,r.Du=1,r.layer=q,r.near=e,a.dh(r),e=a.Yj(e,r,d.link),e.valid=!1,e.rev=d.rev,e.portFromColOffset=g,e.portToColOffset=0,e.portFromPos=k,e.portToPos=0,e=r);v=1;n&&v--;if(q-m>v&&0<q){d.valid=
!1;r=a.createVertex();r.node=null;r.Du=2;r.layer=q-1;u&&w<u.length&&r.layer===u[w].layer&&(r.near=u[w++]);a.dh(r);e=a.Yj(e,r,d.link);e.valid=!0;e.rev=d.rev;e.portFromColOffset=p?0:g;e.portToColOffset=0;e.portFromPos=p?0:k;e.portToPos=0;e=r;for(q--;q-m>v&&0<q;)r=a.createVertex(),r.node=null,r.Du=3,r.layer=q-1,u&&w<u.length&&r.layer===u[w].layer&&(r.near=u[w++]),a.dh(r),e=a.Yj(e,r,d.link),e.valid=!0,e.rev=d.rev,e.portFromColOffset=0,e.portToColOffset=0,e.portFromPos=0,e.portToPos=0,e=r,q--;e=a.Yj(r,
f,d.link);e.valid=!n;n&&(r.near=f);e.rev=d.rev;e.portFromColOffset=0;e.portToColOffset=h;e.portFromPos=0;e.portToPos=l}else d.valid=!0}}a=this.vb=[];for(b=0;b<=this.Ia;b++)a[b]=0;for(b=this.network.vertexes.iterator;b.next();)b.value.index=-1;this.initializeIndices();this.Iq=-1;for(c=this.Md=this.Hn=0;c<=this.Ia;c++)a[c]>a[this.Md]&&(this.Iq=a[c]-1,this.Md=c),a[c]<a[this.Hn]&&(this.Hn=c);this.el=[];for(c=0;c<a.length;c++)this.el[c]=[];for(b.reset();b.next();)a=b.value,this.el[a.layer][a.index]=a;
this.Ra=-1;for(a=0;a<=this.Ia;a++){b=Ss(this,a);c=0;d=this.vb[a];for(f=0;f<d;f++)e=b[f],c+=this.nodeMinColumnSpace(e,!0),e.column=c,c+=1,c+=this.nodeMinColumnSpace(e,!1);this.Ra=Math.max(this.Ra,c-1);Ts(this,a,b)}this.reduceCrossings();this.straightenAndPack();this.updateParts()}this.network=null;this.isValidLayout=!0};Is.prototype.linkMinLength=function(){return 1};
function Us(a){var b=a.fromVertex.node||a.fromVertex.data;a=a.toVertex.node||a.toVertex.data;return null===b&&null===a?8:null===b||null===a?4:1}Is.prototype.nodeMinLayerSpace=function(a,b){return null===a.node&&null===a.data?0:90===this.K||270===this.K?b?a.focus.y+10:a.bounds.height-a.focus.y+10:b?a.focus.x+10:a.bounds.width-a.focus.x+10};
Is.prototype.nodeMinColumnSpace=function(a,b){if(null===a.node&&null===a.data)return 0;var c=b?a.fv:a.ev;if(null!==c)return c;c=this.K;return 90===c||270===c?b?a.fv=a.focus.x/this.lc+1|0:a.ev=(a.bounds.width-a.focus.x)/this.lc+1|0:b?a.fv=a.focus.y/this.lc+1|0:a.ev=(a.bounds.height-a.focus.y)/this.lc+1|0};function Vs(a){null===a.kj&&(a.kj=[]);for(var b=0,c=a.network.vertexes.iterator;c.next();){var d=c.value;a.kj[b]=d.layer;b++;a.kj[b]=d.column;b++;a.kj[b]=d.index;b++}return a.kj}
function Ws(a,b){var c=0;for(a=a.network.vertexes.iterator;a.next();){var d=a.value;d.layer=b[c];c++;d.column=b[c];c++;d.index=b[c];c++}}
function Xs(a,b,c){var d=Ss(a,b),e=a.vb[b];if(null===a.Jn||a.Jn.length<e*e)a.Jn=[];for(var f=a.Jn,g=0;g<e;g++){var h=0,k=d[g],l=k.near;null!==l&&l!==k&&l.layer===k.layer&&(h+=Math.max(0,Math.abs(l.index-k.index)-1));var m;if(0<=c){var n=d[g].sourceEdgesArrayAccess;for(k=0;k<n.length;k++){var p=n[k];if(p.valid&&p.fromVertex.layer!==b){l=p.fromVertex.index;var q=p.portToPos;p=p.portFromPos;for(m=k+1;m<n.length;m++){var r=n[m];if(r.valid&&r.fromVertex.layer!==b){var u=r.fromVertex.index;var w=r.portToPos;
r=r.portFromPos;q<w&&(l>u||l===u&&p>r)&&h++;w<q&&(u>l||u===l&&r>p)&&h++}}}}}if(0>=c)for(n=d[g].destinationEdgesArrayAccess,k=0;k<n.length;k++)if(p=n[k],p.valid&&p.toVertex.layer!==b)for(l=p.toVertex.index,q=p.portToPos,p=p.portFromPos,m=k+1;m<n.length;m++)r=n[m],r.valid&&r.toVertex.layer!==b&&(u=r.toVertex.index,w=r.portToPos,r=r.portFromPos,p<r&&(l>u||l===u&&q>w)&&h++,r<p&&(u>l||u===l&&w>q)&&h++);f[g*e+g]=h;for(h=g+1;h<e;h++){var v=0,y=0;if(0<=c){n=d[g].sourceEdgesArrayAccess;var z=d[h].sourceEdgesArrayAccess;
for(k=0;k<n.length;k++)if(p=n[k],p.valid&&p.fromVertex.layer!==b)for(l=p.fromVertex.index,p=p.portFromPos,m=0;m<z.length;m++)r=z[m],r.valid&&r.fromVertex.layer!==b&&(u=r.fromVertex.index,r=r.portFromPos,(l<u||l===u&&p<r)&&y++,(u<l||u===l&&r<p)&&v++)}if(0>=c)for(n=d[g].destinationEdgesArrayAccess,z=d[h].destinationEdgesArrayAccess,k=0;k<n.length;k++)if(p=n[k],p.valid&&p.toVertex.layer!==b)for(l=p.toVertex.index,q=p.portToPos,m=0;m<z.length;m++)r=z[m],r.valid&&r.toVertex.layer!==b&&(u=r.toVertex.index,
w=r.portToPos,(l<u||l===u&&q<w)&&y++,(u<l||u===l&&w<q)&&v++);f[g*e+h]=v;f[h*e+g]=y}}Ts(a,b,d);return f}Is.prototype.countCrossings=function(){for(var a=0,b=0;b<=this.Ia;b++)for(var c=Xs(this,b,1),d=this.vb[b],e=0;e<d;e++)for(var f=e;f<d;f++)a+=c[e*d+f];return a};
function Ys(a){for(var b=0,c=0;c<=a.Ia;c++){for(var d=a,e=c,f=Ss(d,e),g=d.vb[e],h=0,k=0;k<g;k++){var l=f[k].destinationEdgesArrayAccess;if(null!==l)for(var m=0;m<l.length;m++){var n=l[m];if(n.valid&&n.toVertex.layer!==e){var p=n.fromVertex.column+n.portFromColOffset;var q=n.toVertex.column+n.portToColOffset;h+=(Math.abs(p-q)+1)*Us(n)}}}Ts(d,e,f);b+=h}return b}
Is.prototype.normalize=function(){var a=Infinity;this.Ra=-1;for(var b=this.network.vertexes.iterator;b.next();){var c=b.value;a=Math.min(a,c.column-this.nodeMinColumnSpace(c,!0));this.Ra=Math.max(this.Ra,c.column+this.nodeMinColumnSpace(c,!1))}for(b.reset();b.next();)b.value.column-=a;this.Ra-=a};
function Zs(a,b,c){for(var d=Ss(a,b),e=a.vb[b],f=[],g=0;g<e;g++){var h=d[g],k=null;0>=c&&(k=h.sourceEdgesArrayAccess);var l=null;0<=c&&(l=h.destinationEdgesArrayAccess);var m=0,n=0,p=h.near;null!==p&&p.layer===h.layer&&(m+=p.column-1,n++);if(null!==k)for(p=0;p<k.length;p++){h=k[p];var q=h.fromVertex;h.valid&&!h.rev&&q.layer!==b&&(m+=q.column,n++)}if(null!==l)for(k=0;k<l.length;k++)h=l[k],p=h.toVertex,h.valid&&!h.rev&&p.layer!==b&&(m+=p.column,n++);f[g]=0===n?-1:m/n}Ts(a,b,d);return f}
function $s(a,b,c){for(var d=Ss(a,b),e=a.vb[b],f=[],g=0;g<e;g++){var h=d[g],k=null;0>=c&&(k=h.sourceEdgesArrayAccess);var l=null;0<=c&&(l=h.destinationEdgesArrayAccess);var m=0,n=[],p=h.near;null!==p&&p.layer===h.layer&&(n[m]=p.column-1,m++);h=void 0;if(null!==k)for(p=0;p<k.length;p++){h=k[p];var q=h.fromVertex;h.valid&&!h.rev&&q.layer!==b&&(n[m]=q.column+h.portFromColOffset,m++)}if(null!==l)for(k=0;k<l.length;k++)h=l[k],p=h.toVertex,h.valid&&!h.rev&&p.layer!==b&&(n[m]=p.column+h.portToColOffset,
m++);0===m?f[g]=-1:(n.sort(function(a,b){return a-b}),l=m>>1,f[g]=0!==(m&1)?n[l]:n[l-1]+n[l]>>1)}Ts(a,b,d);return f}function at(a,b,c,d,e,f){if(b.component===d){b.component=c;if(e)for(var g=b.destinationEdges;g.next();){var h=g.value;var k=h.toVertex;var l=b.layer-k.layer;h=a.linkMinLength(h);l===h&&at(a,k,c,d,e,f)}if(f)for(g=b.sourceEdges;g.next();)h=g.value,k=h.fromVertex,l=k.layer-b.layer,h=a.linkMinLength(h),l===h&&at(a,k,c,d,e,f)}}
function bt(a,b,c,d,e,f){if(b.component===d){b.component=c;if(e)for(var g=b.destinationEdges;g.next();)bt(a,g.value.toVertex,c,d,e,f);if(f)for(b=b.sourceEdges;b.next();)bt(a,b.value.fromVertex,c,d,e,f)}}
Is.prototype.removeCycles=function(){for(var a=this.network.edges.iterator;a.next();)a.value.rev=!1;switch(this.Ck){default:case ct:a=this.network;var b=0,c=a.vertexes.count-1,d=[];d.length=c+1;for(var e=a.vertexes.iterator;e.next();)e.value.valid=!0;for(;null!==dt(a);){for(e=et(a);null!==e;)d[c]=e,c--,e.valid=!1,e=et(a);for(e=ft(a);null!==e;)d[b]=e,b++,e.valid=!1,e=ft(a);e=null;for(var f=0,g=this.network.vertexes.iterator;g.next();){var h=g.value;if(h.valid){for(var k=0,l=h.destinationEdges;l.next();)l.value.toVertex.valid&&
k++;l=0;for(var m=h.sourceEdges;m.next();)m.value.fromVertex.valid&&l++;if(null===e||f<k-l)e=h,f=k-l}}null!==e&&(d[b]=e,b++,e.valid=!1)}for(b=0;b<a.vertexes.count;b++)d[b].index=b;for(d=a.edges.iterator;d.next();)b=d.value,b.fromVertex.index>b.toVertex.index&&(a.gm(b),b.rev=!0);break;case Js:for(d=this.network.vertexes.iterator;d.next();)a=d.value,a.Ll=-1,a.finish=-1;for(a=this.network.edges.iterator;a.next();)a.value.forest=!1;this.Kn=0;for(d.reset();d.next();)b=d.value,0===b.sourceEdges.count&&
gt(this,b);for(d.reset();d.next();)b=d.value,-1===b.Ll&&gt(this,b);for(a.reset();a.next();)d=a.value,d.forest||(b=d.fromVertex,c=b.finish,e=d.toVertex,f=e.finish,e.Ll<b.Ll&&c<f&&(this.network.gm(d),d.rev=!0));break;case Os:a=this.network;b=a.vertexes.iterator;for(d=Infinity;b.next();)d=Math.min(d,b.value.layer);if(Infinity>d){if(0>d)for(b.reset();b.next();)b.value.layer-=d;d=[];for(b.reset();b.next();)c=b.value,e=d[c.layer],void 0===e?d[c.layer]=[c]:e.push(c);for(c=b=0;c<d.length;c++)if(e=d[c],!e||
0===e.length)b++;else if(0<c)for(f=0;f<e.length;f++)e[f].layer-=b;for(d=a.edges.iterator;d.next();)b=d.value,b.fromVertex.layer<b.toVertex.layer&&(a.gm(b),b.rev=!0)}}};function dt(a){for(a=a.vertexes.iterator;a.next();){var b=a.value;if(b.valid)return b}return null}function et(a){for(a=a.vertexes.iterator;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.destinationEdges;d.next();)if(d.value.toVertex.valid){c=!1;break}if(c)return b}}return null}
function ft(a){for(a=a.vertexes.iterator;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.sourceEdges;d.next();)if(d.value.fromVertex.valid){c=!1;break}if(c)return b}}return null}function gt(a,b){b.Ll=a.Kn;a.Kn++;for(var c=b.destinationEdges;c.next();){var d=c.value,e=d.toVertex;-1===e.Ll&&(d.forest=!0,gt(a,e))}b.finish=a.Kn;a.Kn++}
Is.prototype.assignLayers=function(){switch(this.Yk){case ht:it(this);break;case jt:for(var a,b=this.network.vertexes.iterator;b.next();)a=kt(this,b.value),this.Ia=Math.max(a,this.Ia);for(b.reset();b.next();)a=b.value,a.layer=this.Ia-a.layer;break;default:case Ks:it(this);for(b=this.network.vertexes.iterator;b.next();)b.value.valid=!1;for(b.reset();b.next();)a=b.value,0===a.sourceEdges.count&&lt(this,a);a=Infinity;for(b.reset();b.next();)a=Math.min(a,b.value.layer);this.Ia=-1;for(b.reset();b.next();){var c=
b.value;c.layer-=a;this.Ia=Math.max(this.Ia,c.layer)}}};function it(a){for(var b=a.network.vertexes.iterator;b.next();){var c=mt(a,b.value);a.Ia=Math.max(c,a.Ia)}}function mt(a,b){var c=0;if(-1===b.layer){for(var d=b.destinationEdges;d.next();){var e=d.value,f=e.toVertex;e=a.linkMinLength(e);c=Math.max(c,mt(a,f)+e)}b.layer=c}else c=b.layer;return c}
function kt(a,b){var c=0;if(-1===b.layer){for(var d=b.sourceEdges;d.next();){var e=d.value,f=e.fromVertex;e=a.linkMinLength(e);c=Math.max(c,kt(a,f)+e)}b.layer=c}else c=b.layer;return c}
function lt(a,b){if(!b.valid){b.valid=!0;for(var c=b.destinationEdges;c.next();)lt(a,c.value.toVertex);for(c=a.network.vertexes.iterator;c.next();)c.value.component=-1;for(var d=b.sourceEdgesArrayAccess,e=d.length,f=0;f<e;f++){var g=d[f],h=g.fromVertex,k=g.toVertex;g=a.linkMinLength(g);h.layer-k.layer>g&&at(a,h,0,-1,!0,!1)}for(at(a,b,1,-1,!0,!0);0!==b.component;){f=0;d=Infinity;h=0;k=null;for(g=a.network.vertexes.iterator;g.next();){var l=g.value;if(1===l.component){var m=0,n=!1,p=l.sourceEdgesArrayAccess;
e=p.length;for(var q=0;q<e;q++){var r=p[q],u=r.fromVertex;m+=1;1!==u.component&&(f+=1,u=u.layer-l.layer,r=a.linkMinLength(r),d=Math.min(d,u-r))}p=l.destinationEdgesArrayAccess;e=p.length;for(q=0;q<e;q++)r=p[q].toVertex,--m,1!==r.component?--f:n=!0;(null===k||m<h)&&!n&&(k=l,h=m)}}if(0<f){for(c.reset();c.next();)e=c.value,1===e.component&&(e.layer+=d);b.component=0}else k.component=0}for(c=a.network.vertexes.iterator;c.next();)c.value.component=-1;for(at(a,b,1,-1,!0,!1);0!==b.component;){d=0;e=Infinity;
f=0;h=null;for(k=a.network.vertexes.iterator;k.next();)if(g=k.value,1===g.component){l=0;m=!1;p=g.sourceEdgesArrayAccess;n=p.length;for(q=0;q<n;q++)r=p[q].fromVertex,l+=1,1!==r.component?d+=1:m=!0;p=g.destinationEdgesArrayAccess;n=p.length;for(q=0;q<n;q++)r=p[q],u=r.toVertex,--l,1!==u.component&&(--d,u=g.layer-u.layer,r=a.linkMinLength(r),e=Math.min(e,u-r));(null===h||l>f)&&!m&&(h=g,f=l)}if(0>d){for(c.reset();c.next();)d=c.value,1===d.component&&(d.layer-=e);b.component=0}else h.component=0}}}
function Qs(a,b,c){return 90===a.K?c&&!b.rev||!c&&b.rev?270:90:180===a.K?c&&!b.rev||!c&&b.rev?0:180:270===a.K?c&&!b.rev||!c&&b.rev?90:270:c&&!b.rev||!c&&b.rev?180:0}function Rs(a,b,c){for(var d=c[c.length-1].sourceEdges.iterator;d.next();){var e=d.value;if(!e.rev&&e.valid){e=e.fromVertex;c.push(e);if(e===b||Rs(a,b,c))return!0;c.pop()}}return!1}
Is.prototype.initializeIndices=function(){switch(this.Pk){default:case nt:ot(this);break;case Ls:var a=null,b=this.network.vertexes.iterator;if(this._experimental)for(;b.next();){var c=b.value;if(c.near&&(null===a&&(a=new ob),c.layer===c.near.layer)){var d=a.get(c.near)||[];d.push(c);a.set(c.near,d)}}for(c=this.Ia;0<=c;c--)for(b.reset();b.next();)d=b.value,d.layer===c&&-1===d.index&&pt(this,d,a);break;case qt:a=null;b=this.network.vertexes.iterator;if(this._experimental)for(;b.next();)c=b.value,c.near&&
(null===a&&(a=new ob),c.layer===c.near.layer&&(d=a.get(c.near)||[],d.push(c),a.set(c.near,d)));for(c=0;c<=this.Ia;c++)for(b.reset();b.next();)d=b.value,d.layer===c&&-1===d.index&&rt(this,d,a)}};
function ot(a){var b=null,c=a.network.vertexes.iterator;if(a._experimental){for(;c.next();){var d=c.value;if(d.near&&(null===b&&(b=new ob),d.layer===d.near.layer)){var e=b.get(d.near)||[];e.push(d);b.set(d.near,e)}}c.reset()}for(d={};c.next();)e=c.value,b&&e.near||(d.layer=e.layer,e.index=a.vb[d.layer],a.vb[d.layer]++,b&&(e=b.get(e),Array.isArray(e)&&e.forEach(function(b){return function(c){c.index=a.vb[b.layer];a.vb[b.layer]++}}(d)))),d={layer:d.layer}}
function pt(a,b,c){if(!c||!b.near){var d=b.layer;b.index=a.vb[d];a.vb[d]++;if(c){var e=c.get(b);Array.isArray(e)&&e.forEach(function(b){b.index=a.vb[d];a.vb[d]++})}b=b.destinationEdgesArrayAccess;for(e=!0;e;){e=!1;for(var f=0;f<b.length-1;f++){var g=b[f],h=b[f+1];g.portFromColOffset>h.portFromColOffset&&(e=!0,b[f]=h,b[f+1]=g)}}for(e=0;e<b.length;e++)f=b[e],f.valid&&(f=f.toVertex,-1===f.index&&pt(a,f,c))}}
function rt(a,b,c){if(!c||!b.near){var d=b.layer;b.index=a.vb[d];a.vb[d]++;if(c){var e=c.get(b);Array.isArray(e)&&e.forEach(function(b){b.index=a.vb[d];a.vb[d]++})}b=b.sourceEdgesArrayAccess;var f=!0;for(e=0;f;)for(f=!1,e=0;e<b.length-1;e++){var g=b[e],h=b[e+1];g.portToColOffset>h.portToColOffset&&(f=!0,b[e]=h,b[e+1]=g)}for(e=0;e<b.length;e++)f=b[e],f.valid&&(f=f.fromVertex,-1===f.index&&rt(a,f,c))}}
Is.prototype.reduceCrossings=function(){var a=this.countCrossings(),b=Vs(this),c,d;for(c=0;c<this.bj;c++){for(d=0;d<=this.Ia;d++)st(this,d,1),tt(this,d,1);var e=this.countCrossings();e<a&&(a=e,b=Vs(this));for(d=this.Ia;0<=d;d--)st(this,d,-1),tt(this,d,-1);e=this.countCrossings();e<a&&(a=e,b=Vs(this))}Ws(this,b);for(c=0;c<this.bj;c++){for(d=0;d<=this.Ia;d++)st(this,d,0),tt(this,d,0);e=this.countCrossings();e<a&&(a=e,b=Vs(this));for(d=this.Ia;0<=d;d--)st(this,d,0),tt(this,d,0);e=this.countCrossings();
e<a&&(a=e,b=Vs(this))}Ws(this,b);var f,g,h;switch(this.qk){case ut:break;case vt:for(h=a+1;(d=this.countCrossings())<h;)for(h=d,c=this.Ia;0<=c;c--)for(g=0;g<=c;g++){for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=tt(this,d,-1)||f;e=this.countCrossings();e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=tt(this,d,1)||f;e=this.countCrossings();e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=tt(this,d,1)||f;e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=
tt(this,d,-1)||f;e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=tt(this,d,0)||f;e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=tt(this,d,0)||f;e>=a?Ws(this,b):(a=e,b=Vs(this))}break;default:case Ms:for(c=this.Ia,g=0,h=a+1;(d=this.countCrossings())<h;){h=d;for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=tt(this,d,-1)||f;e=this.countCrossings();e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=tt(this,d,1)||f;e=this.countCrossings();e>=a?Ws(this,b):
(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=tt(this,d,1)||f;e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=tt(this,d,-1)||f;e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=tt(this,d,0)||f;e>=a?Ws(this,b):(a=e,b=Vs(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=tt(this,d,0)||f;e>=a?Ws(this,b):(a=e,b=Vs(this))}}Ws(this,b)};
function st(a,b,c){var d=Ss(a,b),e=a.vb[b],f=$s(a,b,c),g=Zs(a,b,c);for(c=0;c<e;c++)-1===g[c]&&(g[c]=d[c].column),-1===f[c]&&(f[c]=d[c].column);for(var h=!0,k;h;)for(h=!1,c=0;c<e-1;c++)if(f[c+1]<f[c]||f[c+1]===f[c]&&g[c+1]<g[c])h=!0,k=f[c],f[c]=f[c+1],f[c+1]=k,k=g[c],g[c]=g[c+1],g[c+1]=k,k=d[c],d[c]=d[c+1],d[c+1]=k;for(c=f=0;c<e;c++)k=d[c],k.index=c,f+=a.nodeMinColumnSpace(k,!0),k.column=f,f+=1,f+=a.nodeMinColumnSpace(k,!1);Ts(a,b,d)}
function tt(a,b,c){var d=Ss(a,b),e=a.vb[b];c=Xs(a,b,c);var f;var g=[];for(f=0;f<e;f++)g[f]=-1;var h=[];for(f=0;f<e;f++)h[f]=-1;for(var k=!1,l=!0;l;)for(l=!1,f=0;f<e-1;f++){var m=c[d[f].index*e+d[f+1].index],n=c[d[f+1].index*e+d[f].index],p=0,q=0,r=d[f].column,u=d[f+1].column,w=a.nodeMinColumnSpace(d[f],!0),v=a.nodeMinColumnSpace(d[f],!1),y=a.nodeMinColumnSpace(d[f+1],!0),z=a.nodeMinColumnSpace(d[f+1],!1);w=r-w+y;v=u-v+z;var A=d[f].sourceEdges.iterator;for(A.reset();A.next();)if(y=A.value,z=y.fromVertex,
y.valid&&z.layer===b){for(y=0;y<d.length&&d[y]!==z;)y++;y<f&&(p+=2*(f-y),q+=2*(f+1-y));y===f+1&&(p+=1);y>f+1&&(p+=4*(y-f),q+=4*(y-(f+1)))}A=d[f].destinationEdges.iterator;for(A.reset();A.next();)if(y=A.value,z=y.toVertex,y.valid&&z.layer===b){for(y=0;y<d.length&&d[y]!==z;)y++;y===f+1&&(q+=1)}A=d[f+1].sourceEdges.iterator;for(A.reset();A.next();)if(y=A.value,z=y.fromVertex,y.valid&&z.layer===b){for(y=0;y<d.length&&d[y]!==z;)y++;y<f&&(p+=2*(f+1-y),q+=2*(f-y));y===f&&(q+=1);y>f+1&&(p+=4*(y-(f+1)),q+=
4*(y-f))}A=d[f+1].destinationEdges.iterator;for(A.reset();A.next();)if(y=A.value,z=y.toVertex,y.valid&&z.layer===b){for(y=0;y<d.length&&d[y]!==z;)y++;y===f&&(p+=1)}y=z=0;A=g[d[f].index];var B=h[d[f].index],E=g[d[f+1].index],L=h[d[f+1].index];-1!==A&&(z+=Math.abs(A-r),y+=Math.abs(A-v));-1!==B&&(z+=Math.abs(B-r),y+=Math.abs(B-v));-1!==E&&(z+=Math.abs(E-u),y+=Math.abs(E-w));-1!==L&&(z+=Math.abs(L-u),y+=Math.abs(L-w));if(q<p-.5||q===p&&n<m-.5||q===p&&n===m&&y<z-.5)l=k=!0,d[f].column=v,d[f+1].column=w,
m=d[f],d[f]=d[f+1],d[f+1]=m}for(f=0;f<e;f++)d[f].index=f;Ts(a,b,d);return k}
Is.prototype.straightenAndPack=function(){if(0===this.ve){var a=0!==(this.bg&1);var b=0!==(this.bg&wt);1E3<this.network.edges.count&&b&&(a=!1);if(a){var c=[];for(b=0;b<=this.Ia;b++)c[b]=0;for(var d,e=this.network.vertexes.iterator;e.next();){var f=e.value;b=f.layer;d=f.column;f=this.nodeMinColumnSpace(f,!1);c[b]=Math.max(c[b],d+f)}for(e.reset();e.next();)f=e.value,b=f.layer,d=f.column,f.column=(8*(this.Ra-c[b])>>1)+8*d;this.Ra*=8}if(0!==(this.bg&2))for(c=!0,d=0;c&&d<this.kl;){c=!1;for(b=this.Md+1;b<=
this.Ia;b++)c=xt(this,b,1)||c;for(b=this.Md-1;0<=b;b--)c=xt(this,b,-1)||c;c=xt(this,this.Md,0)||c;d++}if(0!==(this.bg&4)){for(b=this.Md+1;b<=this.Ia;b++)yt(this,b,1);for(b=this.Md-1;0<=b;b--)yt(this,b,-1);yt(this,this.Md,0)}a&&(zt(this,-1),zt(this,1));if(0!==(this.bg&2))for(c=!0,a=0;c&&a<this.kl;){c=!1;c=xt(this,this.Md,0)||c;for(b=this.Md+1;b<=this.Ia;b++)c=xt(this,b,0)||c;for(b=this.Md-1;0<=b;b--)c=xt(this,b,0)||c;a++}}else At(this)};function xt(a,b,c){for(var d=!1;Bt(a,b,c);)d=!0;return d}
function Bt(a,b,c){var d,e=Ss(a,b),f=a.vb[b],g=Zs(a,b,-1);if(0<c)for(d=0;d<f;d++)g[d]=-1;var h=Zs(a,b,1);if(0>c)for(d=0;d<f;d++)h[d]=-1;for(var k=!1,l=!0;l;)for(l=!1,d=0;d<f;d++){var m=e[d].column,n=a.nodeMinColumnSpace(e[d],!0),p=a.nodeMinColumnSpace(e[d],!1),q=0;0>d-1||m-e[d-1].column-1>n+a.nodeMinColumnSpace(e[d-1],!1)?q=m-1:q=m;n=d+1>=f||e[d+1].column-m-1>p+a.nodeMinColumnSpace(e[d+1],!0)?m+1:m;var r=p=0,u=0;if(0>=c)for(var w=e[d].sourceEdges.iterator;w.next();){var v=w.value;var y=v.fromVertex;
if(v.valid&&y.layer!==b){var z=Us(v);var A=v.portFromColOffset;v=v.portToColOffset;y=y.column;p+=(Math.abs(m+v-(y+A))+1)*z;r+=(Math.abs(q+v-(y+A))+1)*z;u+=(Math.abs(n+v-(y+A))+1)*z}}if(0<=c)for(w=e[d].destinationEdges.iterator;w.next();)v=w.value,y=v.toVertex,v.valid&&y.layer!==b&&(z=Us(v),A=v.portFromColOffset,v=v.portToColOffset,y=y.column,p+=(Math.abs(m+A-(y+v))+1)*z,r+=(Math.abs(q+A-(y+v))+1)*z,u+=(Math.abs(n+A-(y+v))+1)*z);v=A=z=0;w=g[e[d].index];y=h[e[d].index];-1!==w&&(z+=Math.abs(w-m),A+=
Math.abs(w-q),v+=Math.abs(w-n));-1!==y&&(z+=Math.abs(y-m),A+=Math.abs(y-q),v+=Math.abs(y-n));if(r<p||r===p&&A<z)l=k=!0,e[d].column=q;else if(u<p||u===p&&v<z)l=k=!0,e[d].column=n}Ts(a,b,e);a.normalize();return k}
function yt(a,b,c){var d=Ss(a,b),e=a.vb[b],f=$s(a,b,c),g=[];for(c=0;c<e;c++)g[c]=f[c];for(f=!0;f;)for(f=!1,c=0;c<e;c++){var h=d[c].column,k=a.nodeMinColumnSpace(d[c],!0),l=a.nodeMinColumnSpace(d[c],!1),m=0;if(-1===g[c])if(0===c&&c===e-1)m=h;else if(0===c){var n=d[c+1].column;n-h===l+a.nodeMinColumnSpace(d[c+1],!0)?m=h-1:m=h}else c===e-1?(n=d[c-1].column,m=h-n===k+a.nodeMinColumnSpace(d[c-1],!1)?h+1:h):(n=d[c-1].column,k=n+a.nodeMinColumnSpace(d[c-1],!1)+k+1,n=d[c+1].column,l=n-a.nodeMinColumnSpace(d[c+
1],!0)-l-1,m=(k+l)/2|0);else 0===c&&c===e-1?m=g[c]:0===c?(n=d[c+1].column,l=n-a.nodeMinColumnSpace(d[c+1],!0)-l-1,m=Math.min(g[c],l)):c===e-1?(n=d[c-1].column,k=n+a.nodeMinColumnSpace(d[c-1],!1)+k+1,m=Math.max(g[c],k)):(n=d[c-1].column,k=n+a.nodeMinColumnSpace(d[c-1],!1)+k+1,n=d[c+1].column,l=n-a.nodeMinColumnSpace(d[c+1],!0)-l-1,k<g[c]&&g[c]<l?m=g[c]:k>=g[c]?m=k:l<=g[c]&&(m=l));m!==h&&(f=!0,d[c].column=m)}Ts(a,b,d);a.normalize()}
function Ct(a,b){for(var c=!0,d=a.network.vertexes.iterator;d.next();){var e=d.value,f=a.nodeMinColumnSpace(e,!0),g=a.nodeMinColumnSpace(e,!1);if(e.column-f<=b&&e.column+g>=b){c=!1;break}}a=!1;if(c)for(d.reset();d.next();)c=d.value,c.column>b&&(--c.column,a=!0);return a}
function Dt(a,b){var c=b+1;var d,e=[],f=[];for(d=0;d<=a.Ia;d++)e[d]=!1,f[d]=!1;for(var g=a.network.vertexes.iterator;g.next();){d=g.value;var h=d.column-a.nodeMinColumnSpace(d,!0),k=d.column+a.nodeMinColumnSpace(d,!1);h<=b&&k>=b&&(e[d.layer]=!0);h<=c&&k>=c&&(f[d.layer]=!0)}h=!0;c=!1;for(d=0;d<=a.Ia;d++)h=h&&!(e[d]&&f[d]);if(h)for(g.reset();g.next();)a=g.value,a.column>b&&(--a.column,c=!0);return c}
function zt(a,b){for(var c=0;c<=a.Ra;c++)for(;Ct(a,c););a.normalize();for(c=0;c<a.Ra;c++)for(;Dt(a,c););a.normalize();var d;if(0<b)for(c=0;c<=a.Ra;c++){var e=Vs(a);var f=Ys(a);for(d=f+1;f<d;){d=f;Et(a,c,1);var g=Ys(a);g>f?Ws(a,e):g<f&&(f=g,e=Vs(a))}}if(0>b)for(c=a.Ra;0<=c;c--)for(e=Vs(a),f=Ys(a),d=f+1;f<d;)d=f,Et(a,c,-1),g=Ys(a),g>f?Ws(a,e):g<f&&(f=g,e=Vs(a));a.normalize()}
function Et(a,b,c){a.le=0;for(var d=a.network.vertexes.iterator;d.next();)d.value.component=-1;if(0<c)for(d.reset();d.next();){var e=d.value;e.column-a.nodeMinColumnSpace(e,!0)<=b&&(e.component=a.le)}if(0>c)for(d.reset();d.next();)e=d.value,e.column+a.nodeMinColumnSpace(e,!1)>=b&&(e.component=a.le);a.le++;for(d.reset();d.next();)b=d.value,-1===b.component&&(bt(a,b,a.le,-1,!0,!0),a.le++);var f;b=[];for(f=0;f<a.le*a.le;f++)b[f]=!1;e=[];for(f=0;f<(a.Ia+1)*(a.Ra+1);f++)e[f]=-1;for(d.reset();d.next();){f=
d.value;for(var g=f.layer,h=Math.max(0,f.column-a.nodeMinColumnSpace(f,!0)),k=Math.min(a.Ra,f.column+a.nodeMinColumnSpace(f,!1));h<=k;h++)e[g*(a.Ra+1)+h]=f.component}for(f=0;f<=a.Ia;f++){if(0<c)for(g=0;g<a.Ra;g++)-1!==e[f*(a.Ra+1)+g]&&-1!==e[f*(a.Ra+1)+g+1]&&e[f*(a.Ra+1)+g]!==e[f*(a.Ra+1)+g+1]&&(b[e[f*(a.Ra+1)+g]*a.le+e[f*(a.Ra+1)+g+1]]=!0);if(0>c)for(g=a.Ra;0<g;g--)-1!==e[f*(a.Ra+1)+g]&&-1!==e[f*(a.Ra+1)+g-1]&&e[f*(a.Ra+1)+g]!==e[f*(a.Ra+1)+g-1]&&(b[e[f*(a.Ra+1)+g]*a.le+e[f*(a.Ra+1)+g-1]]=!0)}e=
[];for(f=0;f<a.le;f++)e[f]=!0;g=[];for(g.push(0);0!==g.length;)if(k=g[g.length-1],g.pop(),e[k])for(e[k]=!1,f=0;f<a.le;f++)b[k*a.le+f]&&g.splice(0,0,f);if(0<c)for(d.reset();d.next();)a=d.value,e[a.component]&&--a.column;if(0>c)for(d.reset();d.next();)c=d.value,e[c.component]&&(c.column+=1)}
function At(a){for(var b=Ea(),c=a.el.length,d=0;d<=c;d++)b[d]=[];for(d=a.network.vertexes.iterator;d.next();){var e=d.value;b[c-e.layer][e.index]=e}c=new H;Ft(b,c);var f=null,g=null,h=null,k=null,l=0!==(a.ve&1),m=0!==(a.ve&2),n=0!==(a.ve&4),p=0!==(a.ve&8);l&&(a.verticalAlignment(b,c,!0),f=Gt(a,b,!0,!1));b.reverse();n&&(a.verticalAlignment(b,c,!1),h=Gt(a,b,!1,!1));d=ba(b);for(e=d.next();!e.done;e=d.next())e.value.reverse();p&&(a.verticalAlignment(b,c,!1),k=Gt(a,b,!1,!0));b.reverse();m&&(a.verticalAlignment(b,
c,!0),g=Gt(a,b,!0,!0));Ga(b);a.u(f,g,h,k);a.network.vertexes.each(function(a){var b=Ea();l&&b.push(f.get(a));m&&b.push(g.get(a));n&&b.push(h.get(a));p&&b.push(k.get(a));b.sort(function(a,b){return a-b});var c=b.length;c=(b[Math.floor((c-1)/2)]+b[Math.ceil((c-1)/2)])/2;Ga(b);a.zc=c})}
function Ft(a,b){for(var c=a.length,d=1;d<c-1;d++)for(var e=0,f=0,g=a[d],h=a[d+1],k=0;f<h.length;k++){var l=h[k];a:{if(null===l.node){var m=Ht(l);if(0<m.length){m=null===m[0].node;break a}}m=!1}var n=m;if(k===h.length-1||n){m=g.length-1;n&&(m=Ht(l)[0].index);for(;f<=k;){l=h[f];if(!l.bo){n=[];for(var p=ba(l.sourceEdgesArrayAccess),q=p.next();!q.done;q=p.next())q=q.value,q.valid&&n.push(q);l.bo=n}l=ba(l.bo);for(n=l.next();!n.done;n=l.next())n=n.value,p=n.fromVertex.index,(p<e||p>m)&&b.add(n);f++}e=
m}}}Is.prototype.verticalAlignment=function(a,b,c){It(a);a=ba(a);for(var d=a.next();!d.done;d=a.next()){var e=-1;d=ba(d.value);for(var f=d.next();!f.done;f=d.next()){f=f.value;var g=c?Ht(f):Jt(f),h=g.length;if(0<h){g.sort(function(a,b){return a.vi-b.vi});var k=(h-1)/2;h=Math.floor(k);for(k=Math.ceil(k);h<=k;h++)if(f.align===f){var l=g[h],m=void 0;m=c?Kt(l,f):Kt(f,l);!b.contains(m)&&e<l.vi&&(l.align=f,f.root=l.root,f.align=l.root,e=l.vi)}}}}};
function It(a){for(var b=0;b<a.length;b++)for(var c=a[b],d=0;d<c.length;d++){var e=c[d];e.root=e;e.align=e;e.Uz=b;e.vi=d}}
function Gt(a,b,c,d){for(var e=90===a.K||270===a.K,f=ba(b),g=f.next();!g.done;g=f.next()){var h=ba(g.value);for(g=h.next();!g.done;g=h.next())g=g.value,g.sd=g,g.shift=Infinity,g.zc=NaN,g.qg=0}g=90===a.K||270===a.K;f=ba(b);for(h=f.next();!h.done;h=f.next()){h=ba(h.value);for(var k=h.next();!k.done;k=h.next())if(k=k.value,k.root===k){for(var l=0,m=k;m.align!==k;){var n=m.align,p=g?m.width:m.height,q=g?n.width:n.height;if(c){var r=Kt(m,n);var u=r.portFromPos;var w=r.portToPos;null!==r.link&&(m.node&&
m.node!==r.link.fromNode&&(u=g?m.focusX:m.focusY),n.node&&n.node!==r.link.toNode&&(w=g?n.focusX:n.focusY))}else r=Kt(n,m),u=r.portToPos,w=r.portFromPos,null!==r.link&&(m.node&&m.node!==r.link.toNode&&(u=g?m.focusX:m.focusY),n.node&&n.node!==r.link.fromNode&&(w=g?n.focusX:n.focusY));p=d?m.qg+(p-u)-(q-w):m.qg+u-w;n.qg=p;l=Math.min(l,p);m=m.align}m=k;do m.qg=m.qg-l,m=m.align;while(m!==k)}}c=ba(b);for(g=c.next();!g.done;g=c.next())for(f=ba(g.value),g=f.next();!g.done;g=f.next())g=g.value,g.root===g&&
Lt(a,g,b);for(c=0;c<b.length;c++)if(!(0>=b[c].length)&&(g=b[c][0],g.sd===g)){Infinity===g.sd.shift&&(g.sd.shift=0);g=c;h=0;do{for(f=b[g][h];f.align!==f.root;)f=f.align,g++,0<f.vi&&(h=Mt(f,b),h.sd.shift=Math.min(h.sd.shift,f.sd.shift+f.zc+f.qg-(h.zc+h.qg+(e?h.width:h.height)+a.columnSpacing)));h=f.vi+1}while(g<b.length&&h<b[g].length&&f.sd===b[g][h].sd)}a=new ob;b=ba(b);for(g=b.next();!g.done;g=b.next())for(c=ba(g.value),g=c.next();!g.done;g=c.next())g=g.value,g.zc=g.zc+g.sd.shift+g.qg,f=e?g.width:
g.height,a.add(g,d?-g.zc-f:g.zc);return a}function Lt(a,b,c){if(isNaN(b.zc)){b.zc=0;var d=b;do{if(0<d.vi){var e=Mt(d,c),f=e.root;Lt(a,f,c);b.sd===b&&(b.sd=f.sd);var g=90===a.K||270===a.K?e.width:e.height;b.sd===f.sd&&(b.zc=Math.max(b.zc,f.zc+e.qg+g-d.qg+a.columnSpacing))}d=d.align}while(d!==b);for(;d.align!==b;)d=d.align,d.zc=b.zc,d.sd=b.sd}}function Mt(a,b){var c=a.Uz;a=a.vi;1>a&&C("Could not determine previous vertex in layer");return b[c][a-1]}
Is.prototype.u=function(a){for(var b=[],c=0;c<arguments.length;++c)b[c]=arguments[c];c=-1;for(var d=Ea(),e=Ea(),f=Infinity,g=0;4>g;g++)if(b[g]){var h=Nt(this,b[g],d,e,g);h<f&&(c=g,f=h)}f={};for(g=0;4>g;f={delta:f.delta},g++)b[g]&&(f.delta=0===g||2===g?d[c]-d[g]:e[c]-e[g],0!==f.delta&&b[g].each(function(a){return function(b){b.value+=a.delta}}(f)));Ga(d);Ga(e)};
function Nt(a,b,c,d,e){if(!b||0===b.count)return c[e]=0,d[e]=0,Infinity;var f=Infinity,g=-Infinity;b.each(function(b){var c=b.key;b=b.value;c=90===a.K||270===a.K?c.width:c.height;b<f&&(f=b);b+c>g&&(g=b+c)});c[e]=f;d[e]=g;return g-f}Is.prototype.commitLayout=function(){if(this.setsPortSpots)for(var a=Ps(this,!0),b=Ps(this,!1),c=this.network.edges.iterator;c.next();){var d=c.value.link;null!==d&&(d.fromSpot=a,d.toSpot=b)}this.commitNodes();this.Hu();this.isRouting&&this.commitLinks()};
function Ps(a,b){return 270===a.K?b?ad:dd:90===a.K?b?dd:ad:180===a.K?b?bd:cd:b?cd:bd}
Is.prototype.commitNodes=function(){this.he=[];this.Ld=[];this.Kd=[];this.Ta=[];for(var a=0;a<=this.Ia;a++)this.he[a]=0,this.Ld[a]=0,this.Kd[a]=0,this.Ta[a]=0;for(a=this.network.vertexes.iterator;a.next();){var b=a.value,c=b.layer;this.he[c]=Math.max(this.he[c],this.nodeMinLayerSpace(b,!0));this.Ld[c]=Math.max(this.Ld[c],this.nodeMinLayerSpace(b,!1))}b=0;c=this.ie;for(var d=0;d<=this.Ia;d++){var e=c;0>=this.he[d]+this.Ld[d]&&(e=0);0<d&&(b+=e/2);90===this.K||0===this.K?(b+=this.Ld[d],this.Kd[d]=b,
b+=this.he[d]):(b+=this.he[d],this.Kd[d]=b,b+=this.Ld[d]);d<this.Ia&&(b+=e/2);this.Ta[d]=b}c=b;b=this.arrangementOrigin;for(d=0;d<=this.Ia;d++)270===this.K?this.Kd[d]=b.y+this.Kd[d]:90===this.K?(this.Kd[d]=b.y+c-this.Kd[d],this.Ta[d]=c-this.Ta[d]):180===this.K?this.Kd[d]=b.x+this.Kd[d]:(this.Kd[d]=b.x+c-this.Kd[d],this.Ta[d]=c-this.Ta[d]);c=0!==this.ve;a.reset();d=270===this.K||90===this.K;for(var f=Infinity,g=e=Infinity,h=-Infinity;a.next();){var k=a.value,l=k.layer,m=k.column|0;d?(g=b.x+this.lc*
m,l=this.Kd[l]):(g=this.Kd[l],l=b.y+this.lc*m);k.centerX=g;k.centerY=l;c&&isFinite(k.zc)&&(d?k.node?k.x=b.x+k.zc:k.centerX=b.x+k.zc:k.node?k.y=b.y+k.zc:k.centerY=b.y+k.zc);f=Math.min(k.x,f);e=Math.min(k.y,e);d?(g=f,h=Math.max(h,k.bounds.right)):(g=e,h=Math.max(h,k.bounds.bottom))}this.yw=h-g;f=b.x-f;b=b.y-e;this.Rf=new I(c&&d?0:f,c&&!d?0:b);for(a.reset();a.next();)c=a.value,c.x+=f,c.y+=b,c.commit()};
Is.prototype.Hu=function(){for(var a=0,b=this.ie,c=0;c<=this.Ia;c++)a+=this.he[c],a+=this.Ld[c];a+=this.Ia*b;b=[];c=0!==this.ve?this.yw:this.lc*this.Ra;for(var d=this.maxLayer;0<=d;d--)270===this.K?0===d?b.push(new N(0,0,c,Math.abs(this.Ta[0]))):b.push(new N(0,this.Ta[d-1],c,Math.abs(this.Ta[d-1]-this.Ta[d]))):90===this.K?0===d?b.push(new N(0,this.Ta[0],c,Math.abs(this.Ta[0]-a))):b.push(new N(0,this.Ta[d],c,Math.abs(this.Ta[d-1]-this.Ta[d]))):180===this.K?0===d?b.push(new N(0,0,Math.abs(this.Ta[0]),
c)):b.push(new N(this.Ta[d-1],0,Math.abs(this.Ta[d-1]-this.Ta[d]),c)):0===d?b.push(new N(this.Ta[0],0,Math.abs(this.Ta[0]-a),c)):b.push(new N(this.Ta[d],0,Math.abs(this.Ta[d-1]-this.Ta[d]),c));this.commitLayers(b,this.Rf)};Is.prototype.commitLayers=function(){};
Is.prototype.commitLinks=function(){for(var a=this.network.edges.iterator,b;a.next();)b=a.value.link,null!==b&&(b.uh(),b.Ej(),b.zf());for(a.reset();a.next();)b=a.value.link,null!==b&&b.vg();for(a.reset();a.next();){var c=a.value;b=c.link;if(null!==b){b.uh();var d=b,e=d.fromNode,f=d.toNode,g=d.fromPort,h=d.toPort;if(null!==e){var k=e.findVisibleNode();null!==k&&k!==e&&(e=k,g=k.port)}null!==f&&(k=f.findVisibleNode(),null!==k&&k!==f&&(f=k,h=k.port));var l=b.computeSpot(!0,g);k=b.computeSpot(!1,h);var m=
c.fromVertex,n=c.toVertex;if(c.valid){if(b.curve===bg&&4===b.pointsCount)if(m.column===n.column&&0===this.alignOption)c=b.getLinkPoint(e,g,l,!0,!1,f,h),h=b.getLinkPoint(f,h,k,!1,!1,e,g),c.w()||c.set(e.actualBounds.center),h.w()||h.set(f.actualBounds.center),b.Ej(),b.yf(c.x,c.y),b.yf((2*c.x+h.x)/3,(2*c.y+h.y)/3),b.yf((c.x+2*h.x)/3,(c.y+2*h.y)/3),b.yf(h.x,h.y);else if(c=d=!1,null!==g&&l===lc&&(d=!0),null!==h&&k===lc&&(c=!0),d||c)k=null,d&&(k=b.getLinkPointFromPoint(e,g,g.ga(wc),b.i(3),!0),k.w()||k.set(e.actualBounds.center),
b.L(0,k.x,k.y)),e=null,c&&(e=b.getLinkPointFromPoint(f,h,h.ga(wc),b.i(0),!1),e.w()||e.set(f.actualBounds.center),b.L(3,e.x,e.y)),k&&(f=b.i(2),b.L(1,(2*k.x+f.x)/3,(2*k.y+f.y)/3)),e&&(f=b.i(1),b.L(2,(2*e.x+f.x)/3,(2*e.y+f.y)/3));b.zf()}else if(m.layer===n.layer)b.zf();else{var p=!1,q=!1,r=this.ie,u=b.firstPickIndex+1;if(b.isOrthogonal){q=!0;var w=b.pointsCount;4<w&&b.points.removeRange(2,w-3)}else if(b.curve===bg)p=!0,w=b.pointsCount,4<w&&b.points.removeRange(2,w-3),4===w&&(u=2);else{w=b.pointsCount;
var v=l===lc,y=k===lc;2<w&&v&&y?b.points.removeRange(1,w-2):3<w&&v&&!y?b.points.removeRange(1,w-3):3<w&&!v&&y?b.points.removeRange(2,w-2):4<w&&!v&&!y&&b.points.removeRange(2,w-3)}if(c.rev){for(;null!==n&&m!==n;){var z=w=null;for(v=n.sourceEdges.iterator;v.next()&&(y=v.value,y.link!==c.link||(w=y.fromVertex,z=y.toVertex,null!==w.node)););if(null===w)break;if(w!==m){v=b.i(u-1).x;y=b.i(u-1).y;var A=w.centerX;var B=w.centerY;if(q)180===this.K||0===this.K?u===b.firstPickIndex+1?(b.m(u++,v,y),b.m(u++,v,
B)):(null!==z?z.centerY:y)!==B&&(z=this.Ta[w.layer-1]+this.Rf.x,b.m(u++,z,y),b.m(u++,z,B)):u===b.firstPickIndex+1?(b.m(u++,v,y),b.m(u++,A,y)):(null!==z?z.centerX:v)!==A&&(z=this.Ta[w.layer-1]+this.Rf.y,b.m(u++,v,z),b.m(u++,A,z));else if(u===b.firstPickIndex+1){z=Math.max(10,this.he[n.layer]);var E=Math.max(10,this.Ld[n.layer]);if(p)180===this.K?A<=n.bounds.x?(n=n.bounds.x,b.m(u++,n-z,B),b.m(u++,n,B),b.m(u++,n+E,B)):(b.m(u++,A-z,B),b.m(u++,A,B),b.m(u++,A+E,B)):90===this.K?B>=n.bounds.bottom?(n=n.bounds.y+
n.bounds.height,b.m(u++,A,n+E),b.m(u++,A,n),b.m(u++,A,n-z)):(b.m(u++,A,B+E),b.m(u++,A,B),b.m(u++,A,B-z)):270===this.K?B<=n.bounds.y?(n=n.bounds.y,b.m(u++,A,n-z),b.m(u++,A,n),b.m(u++,A,n+E)):(b.m(u++,A,B-z),b.m(u++,A,B),b.m(u++,A,B+E)):0===this.K&&(A>=n.bounds.right?(n=n.bounds.x+n.bounds.width,b.m(u++,n+E,B),b.m(u++,n,B),b.m(u++,n-z,B)):(b.m(u++,A+E,B),b.m(u++,A,B),b.m(u++,A-z,B)));else{b.m(u++,v,y);var L=0;if(180===this.K||0===this.K){if(180===this.K?A>=n.bounds.right:A<=n.bounds.x)L=(0===this.K?
-z:E)/2;b.m(u++,v+L,B)}else{if(270===this.K?B>=n.bounds.bottom:B<=n.bounds.y)L=(90===this.K?-z:E)/2;b.m(u++,A,y+L)}b.m(u++,A,B)}}else z=Math.max(10,this.he[w.layer]),E=Math.max(10,this.Ld[w.layer]),p?180===this.K?(b.m(u++,A-z-r,B),b.m(u++,Math.min(A-z+r/2,A),B),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,Math.max(A+E-r/2,A),B),b.m(u++,A+E+r,B)):90===this.K?(b.m(u++,A,B+E+r),b.m(u++,A,Math.max(B+E-r/2,B)),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,A,Math.min(B-z+r/2,B)),b.m(u++,A,B-z-r)):270===this.K?(b.m(u++,A,B-z-
r),b.m(u++,A,Math.min(B-z+r/2,B)),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,A,Math.max(B+E-r/2,B)),b.m(u++,A,B+E+r)):(b.m(u++,A+E+r,B),b.m(u++,Math.max(A+E-r/2,A),B),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,Math.min(A-z+r/2,A),B),b.m(u++,A-z-r,B)):b.m(u++,A,B)}n=w}if(null===h||l!==lc||q)if(v=b.i(u-1).x,y=b.i(u-1).y,A=b.i(u).x,B=b.i(u).y,q)r=this.Ld[m.layer],180===this.K||0===this.K?(q=y,q>=m.bounds.y&&q<=m.bounds.bottom&&(180===this.K?A>=m.bounds.x:A<=m.bounds.right)&&(n=m.centerX+(180===this.K?-r:r),q<m.bounds.y+
m.bounds.height/2?q=m.bounds.y-this.lc/2:q=m.bounds.bottom+this.lc/2,b.m(u++,n,y),b.m(u++,n,q)),b.m(u++,A,q)):(q=v,q>=m.bounds.x&&q<=m.bounds.right&&(270===this.K?B>=m.bounds.y:B<=m.bounds.bottom)&&(n=m.centerY+(270===this.K?-r:r),q<m.bounds.x+m.bounds.width/2?q=m.bounds.x-this.lc/2:q=m.bounds.right+this.lc/2,b.m(u++,v,n),b.m(u++,q,n)),b.m(u++,q,B)),b.m(u++,A,B);else if(p)z=Math.max(10,this.he[m.layer]),E=Math.max(10,this.Ld[m.layer]),180===this.K&&A>=m.bounds.x?(n=m.bounds.x+m.bounds.width,b.L(u-
2,n,y),b.L(u-1,n+E,y)):90===this.K&&B<=m.bounds.bottom?(n=m.bounds.y,b.L(u-2,v,n),b.L(u-1,v,n-z)):270===this.K&&B>=m.bounds.y?(n=m.bounds.y+m.bounds.height,b.L(u-2,v,n),b.L(u-1,v,n+E)):0===this.K&&A<=m.bounds.right&&(n=m.bounds.x,b.L(u-2,n,y),b.L(u-1,n-z,y));else{z=Math.max(10,this.he[m.layer]);E=Math.max(10,this.Ld[m.layer]);q=0;if(180===this.K||0===this.K){if(180===this.K?A<=m.bounds.x:A>=m.bounds.right)q=(0===this.K?E:-z)/2;b.m(u++,A+q,y)}else{if(270===this.K?B<=m.bounds.y:B>=m.bounds.bottom)q=
(90===this.K?E:-z)/2;b.m(u++,v,B+q)}b.m(u++,A,B)}}else{for(;null!==m&&m!==n;){z=w=null;for(m=m.destinationEdges.iterator;m.next()&&(v=m.value,v.link!==c.link||(w=v.toVertex,z=v.fromVertex,null!==z.node&&(z=null),null!==w.node)););if(null===w)break;w!==n&&(v=b.i(u-1).x,y=b.i(u-1).y,A=w.centerX,B=w.centerY,q?180===this.K||0===this.K?(null!==z?z.centerY:y)!==B&&(z=this.Ta[w.layer]+this.Rf.x,u===b.firstPickIndex+1&&(z=0===this.K?Math.max(z,v):Math.min(z,v)),b.m(u++,z,y),b.m(u++,z,B)):(null!==z?z.centerX:
v)!==A&&(z=this.Ta[w.layer]+this.Rf.y,u===b.firstPickIndex+1&&(z=90===this.K?Math.max(z,y):Math.min(z,y)),b.m(u++,v,z),b.m(u++,A,z)):(z=Math.max(10,this.he[w.layer]),E=Math.max(10,this.Ld[w.layer]),p?180===this.K?(b.m(u++,A+E+r,B),b.m(u++,Math.max(A+E-r/2,A),B),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,Math.min(A-z+r/2,A),B),b.m(u++,A-z-r,B)):90===this.K?(b.m(u++,A,B-z-r),b.m(u++,A,Math.min(B-z+r/2,B)),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,A,Math.max(B+E-r/2,B)),b.m(u++,A,B+E+r)):270===this.K?(b.m(u++,A,B+E+
r),b.m(u++,A,Math.max(B+E-r/2,B)),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,A,Math.min(B-z+r/2,B)),b.m(u++,A,B-z-r)):(b.m(u++,A-z-r,B),b.m(u++,Math.min(A-z+r/2,A),B),b.m(u++,A,B),b.m(u++,A,B),b.m(u++,Math.max(A+E-r/2,A),B),b.m(u++,A+E+r,B)):180===this.K?(b.m(u++,A+E,B),b.m(u++,A-z,B)):90===this.K?(b.m(u++,A,B-z),b.m(u++,A,B+E)):270===this.K?(b.m(u++,A,B+E),b.m(u++,A,B-z)):(b.m(u++,A-z,B),b.m(u++,A+E,B))));m=w}q&&(v=b.i(u-1).x,y=b.i(u-1).y,A=b.i(u).x,B=b.i(u).y,180===this.K||0===this.K?y!==B&&(z=0===this.K?
Math.min(Math.max((A+v)/2,this.Ta[n.layer]+this.Rf.x),A):Math.max(Math.min((A+v)/2,this.Ta[n.layer]+this.Rf.x),A),b.m(u++,z,y),b.m(u++,z,B)):v!==A&&(z=90===this.K?Math.min(Math.max((B+y)/2,this.Ta[n.layer]+this.Rf.y),B):Math.max(Math.min((B+y)/2,this.Ta[n.layer]+this.Rf.y),B),b.m(u++,v,z),b.m(u++,A,z)))}null!==d&&p&&4<=b.pointsCount&&(null!==g&&(g=l===lc?b.getLinkPointFromPoint(e,g,g.ga(wc),b.i(3),!0):b.i(0),l===lc&&(l=b.i(2),d=b.i(3),90===this.direction||270===this.direction?(b.L(1,(g.x+l.x)/2,l.y),
b.L(2,d.x,(l.y+d.y)/2)):(b.L(1,l.x,(g.y+l.y)/2),b.L(2,(l.x+d.x)/2,d.y))),g.w()||g.set(e.actualBounds.center),b.L(0,g.x,g.y)),null!==h&&(e=k===lc?b.getLinkPointFromPoint(f,h,h.ga(wc),b.i(b.pointsCount-4),!1):b.i(b.pointsCount-1),k===lc&&(h=b.i(b.pointsCount-3),k=b.i(b.pointsCount-4),90===this.direction||270===this.direction?(b.L(b.pointsCount-2,(e.x+h.x)/2,h.y),b.L(b.pointsCount-2,k.x,(h.y+k.y)/2)):(b.L(b.pointsCount-2,h.x,(e.y+h.y)/2),b.L(b.pointsCount-3,(h.x+k.x)/2,k.y))),e.w()||e.set(f.actualBounds.center),
b.L(b.pointsCount-1,e.x,e.y)));b.zf();c.commit()}}}this.avoidOrthogonalOverlaps()};
Is.prototype.avoidOrthogonalOverlaps=function(){if(!(0>=this.linkSpacing)){for(var a=new F,b=this.network.edges.iterator;b.next();){var c=b.value.link;null!==c&&c.isOrthogonal&&!a.contains(c)&&a.add(c)}if(0<a.count)if(90===this.K||270===this.K){b=0;c=[];for(a=a.iterator;a.next();){var d=a.value;if(null!==d&&d.isOrthogonal)for(var e=2;e<d.pointsCount-3;e++){var f=d.i(e);var g=d.i(e+1);if(this.B(f.y,g.y)&&!this.B(f.x,g.x)){var h=new Ot;h.layer=Math.floor(f.y/2);var k=d.i(0),l=d.i(d.pointsCount-1);h.first=
k.x*k.x+k.y;h.kc=l.x*l.x+l.y;h.rd=Math.min(f.x,g.x);h.Ic=Math.max(f.x,g.x);h.index=e;h.link=d;if(e+2<d.pointsCount){k=d.i(e-1);l=d.i(e+2);var m=0;k.y<f.y?m=l.y<f.y?3:f.x<g.x?2:1:k.y>f.y&&(m=l.y>f.y?0:g.x<f.x?2:1);h.j=m}c.push(h)}}}if(1<c.length)for(c.sort(this.Wx),a=0;a<c.length;){f=c[a].layer;for(d=a+1;d<c.length&&c[d].layer===f;)d++;if(1<d-a)for(f=a;f<d;){g=c[f].Ic;for(e=a+1;e<d&&c[e].rd<g;)g=Math.max(g,c[e].Ic),e++;g=e-f;if(1<g){this.zi(c,this.Js,f,f+g);m=1;h=c[f].kc;for(k=f;k<e;k++)l=c[k],l.kc!==
h&&(m++,h=l.kc);this.zi(c,this.Vx,f,f+g);var n=1;h=c[f].first;for(k=f;k<e;k++)l=c[k],l.first!==h&&(n++,h=l.first);k=!0;l=n;m<n?(k=!1,l=m,h=c[f].kc,this.zi(c,this.Js,f,f+g)):h=c[f].first;m=0;for(n=f;n<e;n++){var p=c[n];(k?p.first:p.kc)!==h&&(m++,h=k?p.first:p.kc);var q=p.link;f=q.i(p.index);g=q.i(p.index+1);var r=this.linkSpacing*(m-(l-1)/2);b++;q.uh();q.L(p.index,f.x,f.y+r);q.L(p.index+1,g.x,g.y+r);q.zf()}}f=e}a=d}}else{b=0;c=[];for(a=a.iterator;a.next();)if(d=a.value,null!==d&&d.isOrthogonal)for(e=
2;e<d.pointsCount-3;e++)f=d.i(e),g=d.i(e+1),this.B(f.x,g.x)&&!this.B(f.y,g.y)&&(h=new Ot,h.layer=Math.floor(f.x/2),k=d.i(0),l=d.i(d.pointsCount-1),h.first=k.x+k.y*k.y,h.kc=l.x+l.y*l.y,h.rd=Math.min(f.y,g.y),h.Ic=Math.max(f.y,g.y),h.index=e,h.link=d,e+2<d.pointsCount&&(k=d.i(e-1),l=d.i(e+2),m=0,k.x<f.x?m=l.x<f.x?3:f.y<g.y?2:1:k.x>f.x&&(m=l.x>f.x?0:g.y<f.y?2:1),h.j=m),c.push(h));if(1<c.length)for(c.sort(this.Wx),a=0;a<c.length;){f=c[a].layer;for(d=a+1;d<c.length&&c[d].layer===f;)d++;if(1<d-a)for(f=
a;f<d;){g=c[f].Ic;for(e=a+1;e<d&&c[e].rd<g;)g=Math.max(g,c[e].Ic),e++;g=e-f;if(1<g){this.zi(c,this.Js,f,f+g);m=1;h=c[f].kc;for(k=f;k<e;k++)l=c[k],l.kc!==h&&(m++,h=l.kc);this.zi(c,this.Vx,f,f+g);n=1;h=c[f].first;for(k=f;k<e;k++)l=c[k],l.first!==h&&(n++,h=l.first);k=!0;l=n;m<n?(k=!1,l=m,h=c[f].kc,this.zi(c,this.Js,f,f+g)):h=c[f].first;m=0;for(n=f;n<e;n++)p=c[n],(k?p.first:p.kc)!==h&&(m++,h=k?p.first:p.kc),q=p.link,f=q.i(p.index),g=q.i(p.index+1),r=this.linkSpacing*(m-(l-1)/2),b++,q.uh(),q.L(p.index,
f.x+r,f.y),q.L(p.index+1,g.x+r,g.y),q.zf()}f=e}a=d}}}};t=Is.prototype;t.Wx=function(a,b){return a instanceof Ot&&b instanceof Ot&&a!==b?a.layer<b.layer?-1:a.layer>b.layer?1:a.rd<b.rd?-1:a.rd>b.rd?1:a.Ic<b.Ic?-1:a.Ic>b.Ic?1:0:0};t.Vx=function(a,b){return a instanceof Ot&&b instanceof Ot&&a!==b?a.first<b.first?-1:a.first>b.first||a.j<b.j?1:a.j>b.j||a.rd<b.rd?-1:a.rd>b.rd?1:a.Ic<b.Ic?-1:a.Ic>b.Ic?1:0:0};
t.Js=function(a,b){return a instanceof Ot&&b instanceof Ot&&a!==b?a.kc<b.kc?-1:a.kc>b.kc||a.j<b.j?1:a.j>b.j||a.rd<b.rd?-1:a.rd>b.rd?1:a.Ic<b.Ic?-1:a.Ic>b.Ic?1:0:0};t.B=function(a,b){a-=b;return-1<a&&1>a};
t.zi=function(a,b,c,d){var e=a.length,f=d-c;if(!(1>=f))if((0>c||c>=e-1)&&C("not in range 0 <= from < length: "+c),2===f)d=a[c],e=a[c+1],0<b(d,e)&&(a[c]=e,a[c+1]=d);else if(0===c)if(d>=e)a.sort(b);else for(c=a.slice(0,d),c.sort(b),b=0;b<d;b++)a[b]=c[b];else if(d>=e)for(d=a.slice(c),d.sort(b),b=c;b<e;b++)a[b]=d[b-c];else for(e=a.slice(c,d),e.sort(b),b=c;b<d;b++)a[b]=e[b-c]};
function Ss(a,b){var c=a.vb[b];if(c>=a.He.length){var d=[];for(var e=0;e<a.He.length;e++)d[e]=a.He[e];a.He=d}void 0===a.He[c]||null===a.He[c]?d=[]:(d=a.He[c],a.He[c]=null);a=a.el[b];for(b=0;b<a.length;b++)c=a[b],d[c.index]=c;return d}function Ts(a,b,c){a.He[a.vb[b]]=c}
ma.Object.defineProperties(Is.prototype,{layerSpacing:{get:function(){return this.ie},set:function(a){this.ie!==a&&0<=a&&(this.ie=a,this.C())}},columnSpacing:{get:function(){return this.lc},set:function(a){this.lc!==a&&0<a&&(this.lc=a,this.C())}},direction:{get:function(){return this.K},set:function(a){this.K!==a&&(0===a||90===a||180===a||270===a?(this.K=a,this.C()):C("LayeredDigraphLayout.direction must be 0, 90, 180, or 270"))}},
cycleRemoveOption:{get:function(){return this.Ck},set:function(a){this.Ck===a||a!==ct&&a!==Js&&a!==Os||(this.Ck=a,this.C())}},layeringOption:{get:function(){return this.Yk},set:function(a){this.Yk===a||a!==Ks&&a!==ht&&a!==jt||(this.Yk=a,this.C())}},initializeOption:{get:function(){return this.Pk},set:function(a){this.Pk===a||a!==Ls&&a!==qt&&a!==nt||(this.Pk=a,this.C())}},iterations:{
get:function(){return this.bj},set:function(a){this.bj!==a&&0<=a&&(this.bj=a,this.C())}},aggressiveOption:{get:function(){return this.qk},set:function(a){this.qk===a||a!==ut&&a!==Ms&&a!==vt||(this.qk=a,this.C())}},packOption:{get:function(){return this.bg},set:function(a){this.bg!==a&&0<=a&&8>a&&(this.bg=a,this.C())}},packIterations:{get:function(){return this.kl},set:function(a){this.kl=a}},alignOption:{
get:function(){return this.ve},set:function(a){this.ve!==a&&0<=a&&16>a&&(this.ve=a,this.C())}},setsPortSpots:{get:function(){return this.sf},set:function(a){this.sf!==a&&(this.sf=a,this.C())}},linkSpacing:{get:function(){return this.Cn},set:function(a){this.Cn!==a&&0<=a&&(this.Cn=a,this.C())}},maxLayer:{get:function(){return this.Ia}},maxIndex:{get:function(){return this.Iq}},
maxColumn:{get:function(){return this.Ra}},minIndexLayer:{get:function(){return this.Hn}},maxIndexLayer:{get:function(){return this.Md}}});
var Js=new D(Is,"CycleDepthFirst",0),ct=new D(Is,"CycleGreedy",1),Os=new D(Is,"CycleFromLayers",2),Ks=new D(Is,"LayerOptimalLinkLength",0),ht=new D(Is,"LayerLongestPathSink",1),jt=new D(Is,"LayerLongestPathSource",2),Ls=new D(Is,"InitDepthFirstOut",0),qt=new D(Is,"InitDepthFirstIn",1),nt=new D(Is,"InitNaive",2),ut=new D(Is,"AggressiveNone",0),Ms=new D(Is,"AggressiveLess",1),vt=new D(Is,"AggressiveMore",2),wt=8;Is.className="LayeredDigraphLayout";Is.CycleDepthFirst=Js;Is.CycleGreedy=ct;
Is.CycleFromLayers=Os;Is.LayerOptimalLinkLength=Ks;Is.LayerLongestPathSink=ht;Is.LayerLongestPathSource=jt;Is.InitDepthFirstOut=Ls;Is.InitDepthFirstIn=qt;Is.InitNaive=nt;Is.AggressiveNone=ut;Is.AggressiveLess=Ms;Is.AggressiveMore=vt;Is.PackNone=0;Is.PackExpand=1;Is.PackStraighten=2;Is.PackMedian=4;Is.PackAll=15;Is.AlignNone=0;Is.AlignUpperLeft=1;Is.AlignUpperRight=2;Is.AlignLowerLeft=4;Is.AlignLowerRight=8;Is.AlignAll=15;
function Ot(){this.index=this.Ic=this.rd=this.kc=this.first=this.layer=0;this.link=null;this.j=0}Ot.className="SegInfo";function Ns(a){wq.call(this,a)}la(Ns,wq);Ns.prototype.createVertex=function(){return new Pt(this)};Ns.prototype.createEdge=function(){return new Qt(this)};Ns.className="LayeredDigraphNetwork";function Pt(a){zq.call(this,a);this.$n=this.bo=this.ao=this.co=null;this.Za=this.Ag=this.Ig=-1;this.u=NaN;this.F=null;this.valid=!1;this.finish=this.Ll=NaN;this.Du=0;this.ev=this.fv=null}
la(Pt,zq);function Rt(a,b){return a.index-b.index}function Kt(a,b){if(!a.$n){for(var c=[],d=ba(a.destinationEdgesArrayAccess),e=d.next();!e.done;e=d.next())e=e.value,e.valid&&c.push(e);a.$n=c}a=ba(a.$n);for(c=a.next();!c.done;c=a.next())if(c=c.value,c.toVertex===b)return c;C("Unable to find destination edge to given vertex")}
function Ht(a){if(!a.co){for(var b=[],c=ba(a.sourceEdgesArrayAccess),d=c.next();!d.done;d=c.next())d=d.value,!d.valid||d.fromVertex.layer<=d.toVertex.layer||b.push(d.fromVertex);b.sort(Rt);a.co=b}return a.co}function Jt(a){if(!a.ao){for(var b=[],c=ba(a.destinationEdgesArrayAccess),d=c.next();!d.done;d=c.next())d=d.value,!d.valid||d.fromVertex.layer<=d.toVertex.layer||b.push(d.toVertex);b.sort(Rt);a.ao=b}return a.ao}Pt.prototype.Cl=function(a){zq.prototype.Cl.call(this,a);this.bo=this.co=null};
Pt.prototype.Kl=function(a){zq.prototype.Kl.call(this,a);this.bo=this.co=null};Pt.prototype.zl=function(a){zq.prototype.zl.call(this,a);this.$n=this.ao=null};Pt.prototype.Jl=function(a){zq.prototype.Jl.call(this,a);this.$n=this.ao=null};
ma.Object.defineProperties(Pt.prototype,{layer:{get:function(){return this.Ig},set:function(a){this.Ig!==a&&(this.Ig=a)}},column:{get:function(){return this.Ag},set:function(a){this.Ag!==a&&(this.Ag=a)}},index:{get:function(){return this.Za},set:function(a){this.Za!==a&&(this.Za=a)}},component:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a)}},near:{
get:function(){return this.F},set:function(a){this.F!==a&&(this.F=a)}}});Pt.prototype.deleteDestinationEdge=Pt.prototype.Jl;Pt.prototype.addDestinationEdge=Pt.prototype.zl;Pt.prototype.deleteSourceEdge=Pt.prototype.Kl;Pt.prototype.addSourceEdge=Pt.prototype.Cl;Pt.className="LayeredDigraphVertex";function Qt(a){Aq.call(this,a);this.j=this.ia=this.Ha=!1;this.V=this.u=NaN;this.F=this.o=0}la(Qt,Aq);
ma.Object.defineProperties(Qt.prototype,{fromVertex:{get:function(){return this.kd},set:function(a){this.kd!==a&&(this.kd=a)}},toVertex:{get:function(){return this.qd},set:function(a){this.qd!==a&&(this.qd=a)}},valid:{get:function(){return this.Ha},set:function(a){this.Ha!==a&&(this.Ha=a)}},rev:{get:function(){return this.ia},set:function(a){this.ia!==a&&(this.ia=a)}},forest:{
get:function(){return this.j},set:function(a){this.j!==a&&(this.j=a)}},portFromPos:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a)}},portToPos:{get:function(){return this.V},set:function(a){this.V!==a&&(this.V=a)}},portFromColOffset:{get:function(){return this.o},set:function(a){this.o!==a&&(this.o=a)}},portToColOffset:{get:function(){return this.F},
set:function(a){this.F!==a&&(this.F=a)}}});Qt.className="LayeredDigraphEdge";function St(a){ti.call(this);this.Wb=new H;this.Wn=Tt;this.ud=Ut;this.vo=Vt;this.Bq=Wt;this.aw=[];this.td=!0;this.Tb=Xt;this.$d=(new M(10,10)).freeze();var b=new Yt(this);this.W=new Zt(b);this.Y=new Zt(b);this.ru=[];a&&Object.assign(this,a)}la(St,ti);
St.prototype.cloneProtected=function(a){ti.prototype.cloneProtected.call(this,a);a.Wn=this.Wn;a.vo=this.vo;a.Bq=this.Bq;a.td=this.td;a.Tb=this.Tb;a.$d.assign(this.$d);a.W.copyInheritedPropertiesFrom(this.W);a.Y.copyInheritedPropertiesFrom(this.Y)};
St.prototype.qb=function(a){a.classType===St?0===a.name.indexOf("Alignment")?this.alignment=a:0===a.name.indexOf("Arrangement")?this.arrangement=a:0===a.name.indexOf("Compaction")?this.compaction=a:0===a.name.indexOf("Path")?this.path=a:0===a.name.indexOf("Sorting")?this.sorting=a:0===a.name.indexOf("Style")?this.treeStyle=a:C("Unknown enum value: "+a):ti.prototype.qb.call(this,a)};St.prototype.createNetwork=function(){return new Yt(this)};
St.prototype.makeNetwork=function(a){function b(a){if(a instanceof V)return!a.isLinkLabel&&"Comment"!==a.category;if(a instanceof T){var b=a.fromNode;if(null===b||b.isLinkLabel||"Comment"===b.category)return!1;a=a.toNode;return null===a||a.isLinkLabel||"Comment"===a.category?!1:!0}return!1}var c=this.createNetwork();a instanceof Q?(c.lg(a.nodes,!0,b),c.lg(a.links,!0,b)):a instanceof jf?c.lg(a.memberParts,!1,b):c.lg(a.iterator,!1,b);return c};
St.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));this.arrangement!==$t&&(this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin));var b=this.diagram;null===b&&a instanceof Q&&(b=a);this.path===Tt&&null!==b?this.ud=b.isTreePathToChildren?Ut:au:this.ud=this.path===Tt?Ut:this.path;if(0<this.network.vertexes.count){this.network.Ko();for(a=this.network.vertexes.iterator;a.next();)b=a.value,b.initialized=!1,b.level=0,b.parent=null,b.children=[];if(0<this.Wb.count){a=
new H;for(b=this.Wb.iterator;b.next();){var c=b.value;c instanceof V?(c=this.network.ki(c),null!==c&&a.add(c)):c instanceof Zt&&a.add(c)}this.Wb=a}0===this.Wb.count&&this.findRoots();for(a=this.Wb.copy().iterator;a.next();)b=a.value,b.initialized||(b.initialized=!0,bu(this,b));b=this.network.vertexes;for(a=null;a=cu(b),0<a.count;)b=du(this,a),null!==b&&this.Wb.add(b),b.initialized=!0,bu(this,b),b=a;for(a=this.Wb.iterator;a.next();)b=a.value,b instanceof Zt&&eu(this,b);for(a=this.Wb.iterator;a.next();)b=
a.value,b instanceof Zt&&fu(this,b);for(a=this.Wb.iterator;a.next();)b=a.value,b instanceof Zt&&gu(this,b);this.Cu();if(this.layerStyle===hu){a=[];for(b=this.network.vertexes.iterator;b.next();){c=b.value;var d=c.parent;null===d&&(d=c);d=0===d.angle||180===d.angle;var e=a[c.level];void 0===e&&(e=0);a[c.level]=Math.max(e,d?c.width:c.height)}for(b=0;b<a.length;b++)void 0===a[b]&&(a[b]=0);this.aw=a;for(b=this.network.vertexes.iterator;b.next();)c=b.value,d=c.parent,null===d&&(d=c),0===d.angle||180===
d.angle?(180===d.angle&&(c.focusX+=a[c.level]-c.width),c.width=a[c.level]):(270===d.angle&&(c.focusY+=a[c.level]-c.height),c.height=a[c.level])}else if(this.layerStyle===iu)for(a=this.network.vertexes.iterator;a.next();){b=a.value;c=0===b.angle||180===b.angle;d=-1;for(e=0;e<b.children.length;e++){var f=b.children[e];d=Math.max(d,c?f.width:f.height)}if(0<=d)for(e=0;e<b.children.length;e++)f=b.children[e],c?(180===b.angle&&(f.focusX+=d-f.width),f.width=d):(270===b.angle&&(f.focusY+=d-f.height),f.height=
d)}for(a=this.Wb.iterator;a.next();)b=a.value,b instanceof Zt&&this.layoutTree(b);this.arrangeTrees();this.updateParts()}this.network=null;this.Wb=new H;this.isValidLayout=!0};function cu(a){var b=new H;for(a=a.iterator;a.next();){var c=a.value;c.initialized||b.add(c)}return b}
St.prototype.findRoots=function(){for(var a=this.network.vertexes,b=a.iterator;b.next();){var c=b.value;switch(this.ud){case Ut:0===c.sourceEdges.count&&this.Wb.add(c);break;case au:0===c.destinationEdges.count&&this.Wb.add(c);break;default:C("Unhandled path value "+this.ud.toString())}}0===this.Wb.count&&(a=du(this,a),null!==a&&this.Wb.add(a))};
function du(a,b){var c=999999,d=null;for(b=b.iterator;b.next();){var e=b.value;switch(a.ud){case Ut:e.sourceEdges.count<c&&(c=e.sourceEdges.count,d=e);break;case au:e.destinationEdges.count<c&&(c=e.destinationEdges.count,d=e);break;default:C("Unhandled path value "+a.ud.toString())}}return d}
function bu(a,b){if(null!==b){switch(a.ud){case Ut:if(0<b.destinationEdges.count){for(var c=new F,d=b.destinationVertexes;d.next();){var e=d.value;ju(a,b,e)&&c.add(e)}0<c.count&&(b.children=c.Fa())}break;case au:if(0<b.sourceEdges.count){c=new F;for(d=b.sourceVertexes;d.next();)e=d.value,ju(a,b,e)&&c.add(e);0<c.count&&(b.children=c.Fa())}break;default:C("Unhandled path value"+a.ud.toString())}c=b.children;d=c.length;for(e=0;e<d;e++){var f=c[e];f.initialized=!0;f.level=b.level+1;f.parent=b;a.Wb.remove(f)}for(b=
0;b<d;b++)bu(a,c[b])}}function ju(a,b,c){if(c.initialized){if(null===b)var d=!1;else{for(d=b.parent;null!==d&&d!==c;)d=d.parent;d=d===c}if(d||c.level>b.level)return!1;a.removeChild(c.parent,c)}return!0}St.prototype.removeChild=function(a,b){if(null!==a&&null!==b){for(var c=a.children,d=0,e=0;e<c.length;e++)c[e]===b&&d++;if(0<d){d=Array(c.length-d);for(var f=e=0;f<c.length;f++)c[f]!==b&&(d[e++]=c[f]);a.children=d}}};
function eu(a,b){if(null!==b){a.initializeTreeVertexValues(b);b.alignment===ku&&a.sortTreeVertexChildren(b);for(var c=0,d=b.childrenCount,e=0,f=b.children,g=f.length,h=0;h<g;h++){var k=f[h];eu(a,k);c+=k.descendantCount+1;d=Math.max(d,k.maxChildrenCount);e=Math.max(e,k.maxGenerationCount)}b.descendantCount=c;b.maxChildrenCount=d;b.maxGenerationCount=0<d?e+1:0}}
function lu(a,b){switch(a.vo){default:case Vt:return null!==b.parent?b.parent:a.W;case mu:return null===b.parent?a.W:null===b.parent.parent?a.Y:b.parent;case nu:return null!==b.parent?null!==b.parent.parent?b.parent.parent:a.Y:a.W;case ou:var c=!0;if(0===b.childrenCount)c=!1;else for(var d=b.children,e=d.length,f=0;f<e;f++)if(0<d[f].childrenCount){c=!1;break}return c&&null!==b.parent?a.Y:null!==b.parent?b.parent:a.W}}
St.prototype.initializeTreeVertexValues=function(a){a.copyInheritedPropertiesFrom(lu(this,a));if(null!==a.parent&&a.parent.alignment===ku){for(var b=a.angle,c=a.parent.children,d=0;d<c.length&&a!==c[d];)d++;0===d%2?d!==c.length-1&&(b=90===b?180:180===b?270:270===b?180:270):b=90===b?0:180===b?90:270===b?0:90;a.angle=b}a.initialized=!0};function fu(a,b){if(null!==b){a.assignTreeVertexValues(b);b=b.children;for(var c=b.length,d=0;d<c;d++)fu(a,b[d])}}St.prototype.assignTreeVertexValues=function(){};
function gu(a,b){if(null!==b){b.alignment!==ku&&a.sortTreeVertexChildren(b);b=b.children;for(var c=b.length,d=0;d<c;d++)gu(a,b[d])}}St.prototype.sortTreeVertexChildren=function(a){switch(a.sorting){case pu:break;case qu:a.children.reverse();break;case ru:a.children.sort(a.comparer);break;case su:a.children.sort(a.comparer);a.children.reverse();break;default:C("Unhandled sorting value "+a.sorting.toString())}};St.prototype.Cu=function(){if(this.comments)for(var a=this.network.vertexes.iterator;a.next();)this.addComments(a.value)};
St.prototype.addComments=function(a){var b=a.angle,c=a.parent,d=0;var e=!1;null!==c&&(d=c.angle,e=c.alignment,e=tu(e));b=90===b||270===b;d=90===d||270===d;c=0===a.childrenCount;var f=0,g=0,h=0,k=a.commentSpacing;if(null!==a.node)for(var l=a.node.Nu();l.next();){var m=l.value;"Comment"===m.category&&m.canLayout()&&(null===a.comments&&(a.comments=[]),a.comments.push(m),m.Va(),m=m.measuredBounds,b&&!c||!e&&!d&&c||e&&d&&c?(f=Math.max(f,m.width),g+=m.height+Math.abs(h)):(f+=m.width+Math.abs(h),g=Math.max(g,
m.height)),h=k)}null!==a.comments&&(b&&!c||!e&&!d&&c||e&&d&&c?(f+=Math.abs(a.commentMargin),g=Math.max(0,g-a.height)):(g+=Math.abs(a.commentMargin),f=Math.max(0,f-a.width)),e=N.allocAt(0,0,a.bounds.width+f,a.bounds.height+g),a.bounds=e,N.free(e))};function tu(a){return a===uu||a===ku||a===vu||a===wu}function Au(a){return a===uu||a===ku}
function Bu(a){var b=a.parent;if(null!==b){var c=b.alignment;if(tu(c)){if(Au(c)){b=b.children;for(c=0;c<b.length&&a!==b[c];)c++;return 0===c%2}if(c===vu)return!0}}return!1}
St.prototype.layoutComments=function(a){if(null!==a.comments){var b=a.node.measuredBounds,c=a.parent,d=a.angle,e=0;var f=!1;null!==c&&(e=c.angle,f=c.alignment,f=tu(f));d=90===d||270===d;c=90===e||270===e;for(var g=0===a.childrenCount,h=Bu(a),k=0,l=a.comments,m=l.length,n=I.alloc(),p=0;p<m;p++){var q=l[p],r=q.measuredBounds;if(d&&!g||!f&&!c&&g||f&&c&&g){if(135<e&&!f||c&&h)if(0<=a.commentMargin)for(n.h(a.bounds.x-a.commentMargin-r.width,a.bounds.y+k),q.move(n),q=q.Sd();q.next();){var u=q.value;u.fromSpot=
bd;u.toSpot=cd}else for(n.h(a.bounds.x+2*a.focus.x-a.commentMargin,a.bounds.y+k),q.move(n),q=q.Sd();q.next();)u=q.value,u.fromSpot=cd,u.toSpot=bd;else if(0<=a.commentMargin)for(n.h(a.bounds.x+2*a.focus.x+a.commentMargin,a.bounds.y+k),q.move(n),q=q.Sd();q.next();)u=q.value,u.fromSpot=cd,u.toSpot=bd;else for(n.h(a.bounds.x+a.commentMargin-r.width,a.bounds.y+k),q.move(n),q=q.Sd();q.next();)u=q.value,u.fromSpot=bd,u.toSpot=cd;k=0<=a.commentSpacing?k+(r.height+a.commentSpacing):k+(a.commentSpacing-r.height)}else{if(135<
e&&!f||!c&&h)if(0<=a.commentMargin)for(n.h(a.bounds.x+k,a.bounds.y-a.commentMargin-r.height),q.move(n),q=q.Sd();q.next();)u=q.value,u.fromSpot=ad,u.toSpot=dd;else for(n.h(a.bounds.x+k,a.bounds.y+2*a.focus.y-a.commentMargin),q.move(n),q=q.Sd();q.next();)u=q.value,u.fromSpot=dd,u.toSpot=ad;else if(0<=a.commentMargin)for(n.h(a.bounds.x+k,a.bounds.y+2*a.focus.y+a.commentMargin),q.move(n),q=q.Sd();q.next();)u=q.value,u.fromSpot=dd,u.toSpot=ad;else for(n.h(a.bounds.x+k,a.bounds.y+a.commentMargin-r.height),
q.move(n),q=q.Sd();q.next();)u=q.value,u.fromSpot=ad,u.toSpot=dd;k=0<=a.commentSpacing?k+(r.width+a.commentSpacing):k+(a.commentSpacing-r.width)}}I.free(n);b=k-a.commentSpacing-(d?b.height:b.width);if(this.ud===Ut)for(a=a.destinationEdges;a.next();)e=a.value.link,null===e||e.isAvoiding||(e.fromEndSegmentLength=0<b?b:NaN);else for(a=a.sourceEdges;a.next();)e=a.value.link,null===e||e.isAvoiding||(e.toEndSegmentLength=0<b?b:NaN)}};
St.prototype.layoutTree=function(a){if(null!==a){for(var b=a.children,c=b.length,d=0;d<c;d++)this.layoutTree(b[d]);switch(a.compaction){case Cu:Du(this,a);break;case Eu:if(a.alignment===ku)Du(this,a);else if(0===a.childrenCount)d=a.parent,c=!1,b=0,null!==d&&(b=d.angle,c=d.alignment,c=tu(c)),d=Bu(a),a.T.h(0,0),a.Da.h(a.width,a.height),null===a.parent||null===a.comments||(180!==b&&270!==b||c)&&!d?a.la.h(0,0):180===b&&!c||(90===b||270===b)&&d?a.la.h(a.width-2*a.focus.x,0):a.la.h(0,a.height-2*a.focus.y),
a.Yo=null,a.kp=null;else{var e=Fu(a);b=90===e||270===e;var f=0,g=a.children,h=g.length;for(c=0;c<h;c++)d=g[c],f=Math.max(f,b?d.Da.width:d.Da.height);var k=a.alignment;d=k===Gu;var l=k===Hu,m=tu(k),n=Math.max(0,a.breadthLimit);c=this.computeLayerSpacing(a);var p=a.nodeSpacing,q=this.computeNodeIndent(a),r=a.rowSpacing,u=0;if(d||l||a.hm||a.im&&1===a.maxGenerationCount)u=Math.max(0,a.rowIndent);d=a.width;var w=a.height,v=0,y=0,z=0,A=null,B=null,E=0,L=0,R=0,S=0,J=0,G=0,ca=0,X=0;m&&!Au(k)&&135<e&&g.reverse();
if(Au(k))if(1<h)for(var na=0;na<h;na++)0===na%2&&na!==h-1&&(X=Math.max(X,b?g[na].Da.width:g[na].Da.height));else 1===h&&(X=b?g[0].Da.width:g[0].Da.height);if(m){switch(k){case uu:y=135>e?Iu(this,a,g,X,v,y):Ju(this,a,g,X,v,y);X=y.x;v=y.width;y=y.height;break;case vu:for(A=0;A<h;A++)B=g[A],n=B.Da,z=0===G?0:r,b?(B.T.h(f-n.width,S+z),v=Math.max(v,n.width),y=Math.max(y,S+z+n.height),S+=z+n.height):(B.T.h(R+z,f-n.height),v=Math.max(v,R+z+n.width),y=Math.max(y,n.height),R+=z+n.width),G++;break;case wu:for(A=
0;A<h;A++)B=g[A],f=B.Da,n=0===G?0:r,b?(B.T.h(p/2+a.focus.x,S+n),v=Math.max(v,f.width),y=Math.max(y,S+n+f.height),S+=n+f.height):(B.T.h(R+n,p/2+a.focus.y),v=Math.max(v,R+n+f.width),y=Math.max(y,f.height),R+=n+f.width),G++}A=Ku(this,2);B=Ku(this,2);b?(A[0].h(0,0),A[1].h(0,y),B[0].h(v,0)):(A[0].h(0,0),A[1].h(v,0),B[0].h(0,y));B[1].h(v,y)}else for(na=0;na<h;na++){var wa=g[na],eb=wa.Da;if(b){0<n&&0<G&&R+p+eb.width>n&&(R<f&&Lu(a,k,f-R,0,ca,na-1),J++,G=0,ca=na,z=y,R=0,S=135<e?-y-r:y+r);Mu(this,wa,0,S);var gb=
0;if(0===G){if(A=wa.Yo,B=wa.kp,E=eb.width,L=eb.height,null===A||null===B||e!==Fu(wa))A=Ku(this,2),B=Ku(this,2),A[0].h(0,0),A[1].h(0,L),B[0].h(E,0),B[1].h(E,L)}else{var Eb=Ea();L=Nu(this,a,wa,A,B,E,L,Eb);gb=L.x;A=Eb[0];B=Eb[1];E=L.width;L=L.height;Ga(Eb);R<eb.width&&0>gb&&(Ou(a,-gb,0,ca,na-1),Pu(A,-gb,0),Pu(B,-gb,0),gb=0)}wa.T.h(gb,S);v=Math.max(v,E);y=Math.max(y,z+(0===J?0:r)+eb.height);R=E}else{0<n&&0<G&&S+p+eb.height>n&&(S<f&&Lu(a,k,0,f-S,ca,na-1),J++,G=0,ca=na,z=v,S=0,R=135<e?-v-r:v+r);Mu(this,
wa,R,0);gb=0;if(0===G){if(A=wa.Yo,B=wa.kp,E=eb.width,L=eb.height,null===A||null===B||e!==Fu(wa))A=Ku(this,2),B=Ku(this,2),A[0].h(0,0),A[1].h(E,0),B[0].h(0,L),B[1].h(E,L)}else Eb=Ea(),L=Nu(this,a,wa,A,B,E,L,Eb),gb=L.x,A=Eb[0],B=Eb[1],E=L.width,L=L.height,Ga(Eb),S<eb.height&&0>gb&&(Ou(a,0,-gb,ca,na-1),Pu(A,0,-gb),Pu(B,0,-gb),gb=0);wa.T.h(R,gb);y=Math.max(y,L);v=Math.max(v,z+(0===J?0:r)+eb.width);S=L}G++}0<J&&(b?(y+=Math.max(0,c),R<v&&Lu(a,k,v-R,0,ca,h-1),0<u&&(l||Ou(a,u,0,0,h-1),v+=u)):(v+=Math.max(0,
c),S<y&&Lu(a,k,0,y-S,ca,h-1),0<u&&(l||Ou(a,0,u,0,h-1),y+=u)));u=l=0;switch(k){case Qu:b?l+=v/2-a.focus.x-q/2:u+=y/2-a.focus.y-q/2;break;case Ru:0<J?b?l+=v/2-a.focus.x-q/2:u+=y/2-a.focus.y-q/2:b?(X=g[0].T.x+g[0].la.x,l+=X+(g[h-1].T.x+g[h-1].la.x+2*g[h-1].focus.x-X)/2-a.focus.x-q/2):(X=g[0].T.y+g[0].la.y,u+=X+(g[h-1].T.y+g[h-1].la.y+2*g[h-1].focus.y-X)/2-a.focus.y-q/2);break;case Gu:b?(l-=q,v+=q):(u-=q,y+=q);break;case Hu:b?(l+=v-a.width+q,v+=q):(u+=y-a.height+q,y+=q);break;case uu:b?1<h?l+=X+p/2-a.focus.x:
l+=g[0].focus.x-a.focus.x+g[0].la.x:1<h?u+=X+p/2-a.focus.y:u+=g[0].focus.y-a.focus.y+g[0].la.y;break;case vu:b?l+=v+p/2-a.focus.x:u+=y+p/2-a.focus.y;break;case wu:break;default:y=this.customAlignment(a,l,u,v,y),l=y[0],u=y[1],v=y[2],y=y[3]}for(q=0;q<h;q++)X=g[q],b?X.T.h(X.T.x+X.la.x-l,X.T.y+(135<e?(m?-y:-X.Da.height)+X.la.y-c:w+c+X.la.y)):X.T.h(X.T.x+(135<e?(m?-v:-X.Da.width)+X.la.x-c:d+c+X.la.x),X.T.y+X.la.y-u);h=g=0;m?b?(v=Su(a,v,l),0>l&&(l=0),135<e&&(u+=y+c),y+=w+c,k===wu&&(g+=p/2+a.focus.x),h+=
w+c):(135<e&&(l+=v+c),v+=d+c,y=Tu(a,y,u),0>u&&(u=0),k===wu&&(h+=p/2+a.focus.y),g+=d+c):b?(null===a.comments?d>v&&(v=Uu(k,d-v,0),g=v.x,h=v.y,v=d,l=0):v=Su(a,v,l),0>l&&(g-=l,l=0),135<e&&(u+=y+c),y=Math.max(Math.max(y,w),y+w+c),h+=w+c):(135<e&&(l+=v+c),v=Math.max(Math.max(v,d),v+d+c),null===a.comments?w>y&&(y=Uu(k,0,w-y),g=y.x,h=y.y,y=w,u=0):y=Tu(a,y,u),0>u&&(h-=u,u=0),g+=d+c);if(0<J)e=Ku(this,4),J=Ku(this,4),b?(e[2].h(0,w+c),e[3].h(e[2].x,y),J[2].h(v,e[2].y),J[3].h(J[2].x,e[3].y)):(e[2].h(d+c,0),e[3].h(v,
e[2].y),J[2].h(e[2].x,y),J[3].h(e[3].x,J[2].y));else{e=Ku(this,A.length+2);J=Ku(this,B.length+2);for(k=0;k<A.length;k++)m=A[k],e[k+2].h(m.x+g,m.y+h);for(k=0;k<B.length;k++)m=B[k],J[k+2].h(m.x+g,m.y+h)}b?(e[0].h(l,0),e[1].h(e[0].x,w),e[2].y<e[1].y&&(e[2].x>e[0].x?e[2].assign(e[1]):e[1].assign(e[2])),e[3].y<e[2].y&&(e[3].x>e[0].x?e[3].assign(e[2]):e[2].assign(e[3])),J[0].h(l+d,0),J[1].h(J[0].x,w),J[2].y<J[1].y&&(J[2].x<J[0].x?J[2].assign(J[1]):J[1].assign(J[2])),J[3].y<J[2].y&&(J[3].x<J[0].x?J[3].assign(J[2]):
J[2].assign(J[3])),e[2].y-=c/2,J[2].y-=c/2):(e[0].h(0,u),e[1].h(d,e[0].y),e[2].x<e[1].x&&(e[2].y>e[0].y?e[2].assign(e[1]):e[1].assign(e[2])),e[3].x<e[2].x&&(e[3].y>e[0].y?e[3].assign(e[2]):e[2].assign(e[3])),J[0].h(0,u+w),J[1].h(d,J[0].y),J[2].x<J[1].x&&(J[2].y<J[0].y?J[2].assign(J[1]):J[1].assign(J[2])),J[3].x<J[2].x&&(J[3].y<J[0].y?J[3].assign(J[2]):J[2].assign(J[3])),e[2].x-=c/2,J[2].x-=c/2);Vu(this,A);Vu(this,B);a.Yo=e;a.kp=J;a.la.h(l,u);a.Da.h(v,y)}break;default:C("Unhandled compaction value "+
a.compaction.toString())}}};
function Du(a,b){if(0===b.childrenCount){var c=!1,d=0;null!==b.parent&&(d=b.parent.angle,c=b.parent.alignment,c=tu(c));var e=Bu(b);b.T.h(0,0);b.Da.h(b.width,b.height);null===b.parent||null===b.comments||(180!==d&&270!==d||c)&&!e?b.la.h(0,0):180===d&&!c||(90===d||270===d)&&e?b.la.h(b.width-2*b.focus.x,0):b.la.h(0,b.height-2*b.focus.y)}else{d=Fu(b);c=90===d||270===d;var f=0;e=b.children;for(var g=e.length,h=0;h<g;h++){var k=e[h];f=Math.max(f,c?k.Da.width:k.Da.height)}var l=b.alignment,m=l===Gu,n=l===
Hu;h=tu(l);var p=Math.max(0,b.breadthLimit);k=a.computeLayerSpacing(b);var q=b.nodeSpacing,r=a.computeNodeIndent(b),u=m||n?0:r/2,w=b.rowSpacing,v=0;if(m||n||b.hm||b.im&&1===b.maxGenerationCount)v=Math.max(0,b.rowIndent);m=b.width;var y=b.height,z=0,A=0,B=0,E=0,L=0,R=0,S=0,J=0,G=0,ca=0;h&&!Au(l)&&135<d&&e.reverse();if(Au(l))if(1<g)for(var X=0;X<g;X++){var na=e[X],wa=na.Da;0===X%2&&X!==g-1?G=Math.max(G,(c?wa.width:wa.height)+a.computeBusNodeSpacing(na)-q):0!==X%2&&(ca=Math.max(ca,(c?wa.width:wa.height)+
a.computeBusNodeSpacing(na)-q))}else 1===g&&(G=c?e[0].Da.width:e[0].Da.height);if(h)switch(l){case uu:case ku:A=135>d?Iu(a,b,e,G,z,A):Ju(a,b,e,G,z,A);G=A.x;z=A.width;A=A.height;break;case vu:for(p=0;p<g;p++)u=e[p],B=u.Da,ca=0===S?0:w,c?(u.T.h(f-B.width,L+ca),z=Math.max(z,B.width),A=Math.max(A,L+ca+B.height),L+=ca+B.height):(u.T.h(E+ca,f-B.height),z=Math.max(z,E+ca+B.width),A=Math.max(A,B.height),E+=ca+B.width),S++;break;case wu:for(f=0;f<g;f++)p=e[f],u=p.Da,B=0===S?0:w,c?(p.T.h(q/2+b.focus.x,L+B),
z=Math.max(z,u.width),A=Math.max(A,L+B+u.height),L+=B+u.height):(p.T.h(E+B,q/2+b.focus.y),z=Math.max(z,E+B+u.width),A=Math.max(A,u.height),E+=B+u.width),S++}else for(ca=0;ca<g;ca++)X=e[ca],na=X.Da,c?(0<p&&0<S&&E+q+na.width>p&&(E<f&&Lu(b,l,f-E,0,J,ca-1),R++,S=0,J=ca,B=A,E=0,L=135<d?-A-w:A+w),wa=0===S?u:q,Mu(a,X,0,L),X.T.h(E+wa,L),z=Math.max(z,E+wa+na.width),A=Math.max(A,B+(0===R?0:w)+na.height),E+=wa+na.width):(0<p&&0<S&&L+q+na.height>p&&(L<f&&Lu(b,l,0,f-L,J,ca-1),R++,S=0,J=ca,B=z,L=0,E=135<d?-z-w:
z+w),wa=0===S?u:q,Mu(a,X,E,0),X.T.h(E,L+wa),A=Math.max(A,L+wa+na.height),z=Math.max(z,B+(0===R?0:w)+na.width),L+=wa+na.height),S++;0<R&&(c?(A+=Math.max(0,k),E<z&&Lu(b,l,z-E,0,J,g-1),0<v&&(n||Ou(b,v,0,0,g-1),z+=v)):(z+=Math.max(0,k),L<A&&Lu(b,l,0,A-L,J,g-1),0<v&&(n||Ou(b,0,v,0,g-1),A+=v)));v=n=0;switch(l){case Qu:c?n+=z/2-b.focus.x-r/2:v+=A/2-b.focus.y-r/2;break;case Ru:0<R?c?n+=z/2-b.focus.x-r/2:v+=A/2-b.focus.y-r/2:c?(a=e[0].T.x+e[0].la.x,n+=a+(e[g-1].T.x+e[g-1].la.x+2*e[g-1].focus.x-a)/2-b.focus.x-
r/2):(a=e[0].T.y+e[0].la.y,v+=a+(e[g-1].T.y+e[g-1].la.y+2*e[g-1].focus.y-a)/2-b.focus.y-r/2);break;case Gu:c?(n-=r,z+=r):(v-=r,A+=r);break;case Hu:c?(n+=z-b.width+r,z+=r):(v+=A-b.height+r,A+=r);break;case uu:case ku:c?1<g?n+=G+q/2-b.focus.x:n+=e[0].focus.x-b.focus.x+e[0].la.x:1<g?v+=G+q/2-b.focus.y:v+=e[0].focus.y-b.focus.y+e[0].la.y;break;case vu:c?n+=z+q/2-b.focus.x:v+=A+q/2-b.focus.y;break;case wu:break;default:a=a.customAlignment(b,n,v,z,A),n=a[0],v=a[1],z=a[2],A=a[3]}for(a=0;a<g;a++)r=e[a],c?
r.T.h(r.T.x+r.la.x-n,r.T.y+(135<d?(h?-A:-r.Da.height)+r.la.y-k:y+k+r.la.y)):r.T.h(r.T.x+(135<d?(h?-z:-r.Da.width)+r.la.x-k:m+k+r.la.x),r.T.y+r.la.y-v);c?(z=Su(b,z,n),0>n&&(n=0),135<d&&(v+=A+k),A=Math.max(Math.max(A,y),A+y+k)):(135<d&&(n+=z+k),z=Math.max(Math.max(z,m),z+m+k),A=Tu(b,A,v),0>v&&(v=0));b.la.h(n,v);b.Da.h(z,A)}}St.prototype.customAlignment=function(a,b,c,d,e){return[b,c,d,e]};
function Iu(a,b,c,d,e,f){var g=c.length;if(0===g)return new N(d,0,e,f);if(1===g)return b=c[0],e=b.Da.width,f=b.Da.height,new N(d,0,e,f);for(var h=b.nodeSpacing,k=b.rowSpacing,l=90===Fu(b),m=0,n=0,p=0,q=0;q<g;q++)if(!(0!==q%2||1<g&&q===g-1)){var r=c[q],u=r.Da,w=0===m?0:k;if(l){var v=a.computeBusNodeSpacing(r)-h;r.T.h(d-(u.width+v),p+w);e=Math.max(e,u.width+v);f=Math.max(f,p+w+u.height);p+=w+u.height}else v=a.computeBusNodeSpacing(r)-h,r.T.h(n+w,d-(u.height+v)),f=Math.max(f,u.height+v),e=Math.max(e,
n+w+u.width),n+=w+u.width;m++}m=0;r=n;q=p;l?(n=d+h,p=0):(n=0,p=d+h);for(u=0;u<g;u++)if(0!==u%2){w=c[u];v=w.Da;var y=0===m?0:k;if(l){var z=a.computeBusNodeSpacing(w)-h;w.T.h(n+z,p+y);e=Math.max(e,n+v.width+z);f=Math.max(f,p+y+v.height);p+=y+v.height}else z=a.computeBusNodeSpacing(w)-h,w.T.h(n+y,p+z),e=Math.max(e,n+y+v.width),f=Math.max(f,p+v.height+z),n+=y+v.width;m++}1<g&&1===g%2&&(c=c[g-1],g=c.Da,a=a.computeBusLastRowSpacing(c,l?Math.max(Math.abs(q),Math.abs(p)):Math.max(Math.abs(r),Math.abs(n))),
l?(c.T.h(d+h/2-c.focus.x-c.la.x,f+a),l=d+h/2-c.focus.x-c.la.x,e=Math.max(e,l+g.width),0>l&&(e-=l),f=Math.max(f,Math.max(q,p)+a+g.height),0>c.T.x&&(d=Wu(b,c.T.x,!1,d,h))):(c.T.h(e+a,d+h/2-c.focus.y-c.la.y),e=Math.max(e,Math.max(r,n)+a+g.width),p=d+h/2-c.focus.y-c.la.y,f=Math.max(f,p+g.height),0>p&&(f-=p),0>c.T.y&&(d=Wu(b,c.T.y,!0,d,h))));return new N(d,0,e,f)}
function Ju(a,b,c,d,e,f){var g=c.length;if(0===g)return new N(d,0,e,f);if(1===g)return c=c[0],e=c.Da.width,f=c.Da.height,new N(d,0,e,f);for(var h=b.nodeSpacing,k=b.rowSpacing,l=270===Fu(b),m=0,n=0,p=0,q=0;q<g;q++)if(!(0!==q%2||1<g&&q===g-1)){var r=c[q],u=r.Da,w=0===m?0:k;if(l){var v=a.computeBusNodeSpacing(r)-h;p-=w+u.height;r.T.h(d-(u.width+v),p);e=Math.max(e,u.width+v);f=Math.max(f,Math.abs(p))}else v=a.computeBusNodeSpacing(r)-h,n-=w+u.width,r.T.h(n,d-(u.height+v)),f=Math.max(f,u.height+v),e=Math.max(e,
Math.abs(n));m++}m=0;r=n;q=p;l?(n=d+h,p=0):(n=0,p=d+h);for(u=0;u<g;u++)if(0!==u%2){w=c[u];v=w.Da;var y=0===m?0:k;if(l){var z=a.computeBusNodeSpacing(w)-h;p-=y+v.height;w.T.h(n+z,p);e=Math.max(e,n+v.width+z);f=Math.max(f,Math.abs(p))}else z=a.computeBusNodeSpacing(w)-h,n-=y+v.width,w.T.h(n,p+z),f=Math.max(f,p+v.height+z),e=Math.max(e,Math.abs(n));m++}1<g&&1===g%2&&(k=c[g-1],m=k.Da,a=a.computeBusLastRowSpacing(k,l?Math.max(Math.abs(q),Math.abs(p)):Math.max(Math.abs(r),Math.abs(n))),l?(k.T.h(d+h/2-k.focus.x-
k.la.x,-f-m.height-a),n=d+h/2-k.focus.x-k.la.x,e=Math.max(e,n+m.width),0>n&&(e-=n),f=Math.max(f,Math.abs(Math.min(q,p))+a+m.height),0>k.T.x&&(d=Wu(b,k.T.x,!1,d,h))):(k.T.h(-e-m.width-a,d+h/2-k.focus.y-k.la.y),e=Math.max(e,Math.abs(Math.min(r,n))+a+m.width),p=d+h/2-k.focus.y-k.la.y,f=Math.max(f,p+m.height),0>p&&(f-=p),0>k.T.y&&(d=Wu(b,k.T.y,!0,d,h))));for(b=0;b<g;b++)h=c[b],l?h.T.h(h.T.x,h.T.y+f):h.T.h(h.T.x+e,h.T.y);return new N(d,0,e,f)}
St.prototype.computeBusNodeSpacing=function(a){return null===a.parent?0:a.parent.nodeSpacing};St.prototype.computeBusLastRowSpacing=function(a){return null===a.parent?0:a.parent.rowSpacing};function Wu(a,b,c,d,e){a=a.children;for(var f=a.length,g=0;g<f;g++)c?a[g].T.h(a[g].T.x,a[g].T.y-b):a[g].T.h(a[g].T.x-b,a[g].T.y);b=a[f-1];return Math.max(d,c?b.la.y+b.focus.y-e/2:b.la.x+b.focus.x-e/2)}
function Su(a,b,c){switch(a.alignment){case Ru:case Qu:return c+a.width>b&&(b=c+a.width),0>c&&(b-=c),b;case Gu:return a.width>b?a.width:b;case Hu:return 2*a.focus.x>b?a.width:b+a.width-2*a.focus.x;case uu:case ku:return Math.max(a.width,Math.max(b,c+a.width)-Math.min(0,c));case vu:return a.width-a.focus.x+a.nodeSpacing/2+b;case wu:return Math.max(a.width,a.focus.x+a.nodeSpacing/2+b);default:return b}}
function Tu(a,b,c){switch(a.alignment){case Ru:case Qu:return c+a.height>b&&(b=c+a.height),0>c&&(b-=c),b;case Gu:return a.height>b?a.height:b;case Hu:return 2*a.focus.y>b?a.height:b+a.height-2*a.focus.y;case uu:case ku:return Math.max(a.height,Math.max(b,c+a.height)-Math.min(0,c));case vu:return a.height-a.focus.y+a.nodeSpacing/2+b;case wu:return Math.max(a.height,a.focus.y+a.nodeSpacing/2+b);default:return b}}
function Uu(a,b,c){switch(a){case Qu:b/=2;c/=2;break;case Ru:b/=2;c/=2;break;case Gu:c=b=0;break;case Hu:break;default:C("Unhandled alignment value "+a.toString())}return new I(b,c)}function Lu(a,b,c,d,e,f){b=Uu(b,c,d);Ou(a,b.x,b.y,e,f)}function Ou(a,b,c,d,e){if(0!==b||0!==c)for(a=a.children;d<=e;d++){var f=a[d].T;f.x+=b;f.y+=c}}
function Mu(a,b,c,d){var e=b.parent;switch(a.ud){case Ut:for(a=b.sourceEdges;a.next();)b=a.value,b.fromVertex===e&&b.relativePoint.h(c,d);break;case au:for(a=b.destinationEdges;a.next();)b=a.value,b.toVertex===e&&b.relativePoint.h(c,d);break;default:C("Unhandled path value "+a.ud.toString())}}function Pu(a,b,c){for(var d=0;d<a.length;d++){var e=a[d];e.x+=b;e.y+=c}}
function Nu(a,b,c,d,e,f,g,h){var k=Fu(b),l=90===k||270===k,m=b.nodeSpacing;b=d;var n=e;d=f;var p=g,q=c.Yo,r=c.kp;g=c.Da;var u=l?Math.max(p,g.height):Math.max(d,g.width);if(null===q||k!==Fu(c))q=Ku(a,2),r=Ku(a,2),l?(q[0].h(0,0),q[1].h(0,g.height),r[0].h(g.width,0),r[1].h(r[0].x,q[1].y)):(q[0].h(0,0),q[1].h(g.width,0),r[0].h(0,g.height),r[1].h(q[1].x,r[0].y));if(l){p=9999999;if(!(null===n||2>n.length||null===q||2>q.length))for(e=c=0;c<n.length&&e<q.length;){f=n[c];var w=q[e];k=w.x;l=w.y;k+=d;var v=
f;c+1<n.length&&(v=n[c+1]);var y=w;w=y.x;y=y.y;e+1<q.length&&(y=q[e+1],w=y.x,y=y.y,w+=d);var z=p;f.y===l?z=k-f.x:f.y>l&&f.y<y?z=k+(f.y-l)/(y-l)*(w-k)-f.x:l>f.y&&l<v.y&&(z=k-(f.x+(l-f.y)/(v.y-f.y)*(v.x-f.x)));z<p&&(p=z);v.y<=f.y?c++:y<=l?e++:(v.y<=y&&c++,y<=v.y&&e++)}p=d-p;p+=m;c=q;e=p;if(null===b||2>b.length||null===c||2>c.length)d=null;else{m=Ku(a,b.length+c.length);for(d=f=k=0;f<c.length&&c[f].y<b[0].y;)l=c[f++],m[d++].h(l.x+e,l.y);for(;k<b.length;)l=b[k++],m[d++].h(l.x,l.y);for(k=b[b.length-1].y;f<
c.length&&c[f].y<=k;)f++;for(;f<c.length&&c[f].y>k;)l=c[f++],m[d++].h(l.x+e,l.y);c=Ku(a,d);for(k=0;k<d;k++)c[k].assign(m[k]);Vu(a,m);d=c}f=r;k=p;if(null===n||2>n.length||null===f||2>f.length)e=null;else{m=Ku(a,n.length+f.length);for(e=l=c=0;c<n.length&&n[c].y<f[0].y;)v=n[c++],m[e++].h(v.x,v.y);for(;l<f.length;)v=f[l++],m[e++].h(v.x+k,v.y);for(f=f[f.length-1].y;c<n.length&&n[c].y<=f;)c++;for(;c<n.length&&n[c].y>f;)k=n[c++],m[e++].h(k.x,k.y);f=Ku(a,e);for(c=0;c<e;c++)f[c].assign(m[c]);Vu(a,m);e=f}f=
Math.max(0,p)+g.width;g=u;Vu(a,b);Vu(a,q);Vu(a,n);Vu(a,r);h[0]=d;h[1]=e;return new N(p,0,f,g)}d=9999999;if(!(null===n||2>n.length||null===q||2>q.length))for(e=c=0;c<n.length&&e<q.length;)f=n[c],w=q[e],k=w.x,l=w.y,l+=p,v=f,c+1<n.length&&(v=n[c+1]),y=w,w=y.x,y=y.y,e+1<q.length&&(y=q[e+1],w=y.x,y=y.y,y+=p),z=d,f.x===k?z=l-f.y:f.x>k&&f.x<w?z=l+(f.x-k)/(w-k)*(y-l)-f.y:k>f.x&&k<v.x&&(z=l-(f.y+(k-f.x)/(v.x-f.x)*(v.y-f.y))),z<d&&(d=z),v.x<=f.x?c++:w<=k?e++:(v.x<=w&&c++,w<=v.x&&e++);p-=d;p+=m;c=q;e=p;if(null===
b||2>b.length||null===c||2>c.length)d=null;else{m=Ku(a,b.length+c.length);for(d=f=k=0;f<c.length&&c[f].x<b[0].x;)l=c[f++],m[d++].h(l.x,l.y+e);for(;k<b.length;)l=b[k++],m[d++].h(l.x,l.y);for(k=b[b.length-1].x;f<c.length&&c[f].x<=k;)f++;for(;f<c.length&&c[f].x>k;)l=c[f++],m[d++].h(l.x,l.y+e);c=Ku(a,d);for(k=0;k<d;k++)c[k].assign(m[k]);Vu(a,m);d=c}f=r;k=p;if(null===n||2>n.length||null===f||2>f.length)e=null;else{m=Ku(a,n.length+f.length);for(e=l=c=0;c<n.length&&n[c].x<f[0].x;)v=n[c++],m[e++].h(v.x,v.y);
for(;l<f.length;)v=f[l++],m[e++].h(v.x,v.y+k);for(f=f[f.length-1].x;c<n.length&&n[c].x<=f;)c++;for(;c<n.length&&n[c].x>f;)k=n[c++],m[e++].h(k.x,k.y);f=Ku(a,e);for(c=0;c<e;c++)f[c].assign(m[c]);Vu(a,m);e=f}f=u;g=Math.max(0,p)+g.height;Vu(a,b);Vu(a,q);Vu(a,n);Vu(a,r);h[0]=d;h[1]=e;return new N(p,0,f,g)}function Ku(a,b){a=a.ru[b];if(void 0!==a&&(a=a.pop(),void 0!==a))return a;a=[];for(var c=0;c<b;c++)a[c]=new I;return a}
function Vu(a,b){var c=b.length,d=a.ru[c];void 0===d&&(d=[],a.ru[c]=d);d.push(b)}
St.prototype.arrangeTrees=function(){if(this.Tb===$t)for(var a=this.Wb.iterator;a.next();){var b=a.value;if(b instanceof Zt){var c=b.node;if(null!==c){var d=c.position;c=d.x;d=d.y;isFinite(c)||(c=0);isFinite(d)||(d=0);Xu(this,b,c,d)}}}else{a=[];for(b=this.Wb.iterator;b.next();)c=b.value,c instanceof Zt&&a.push(c);switch(this.sorting){case pu:break;case qu:a.reverse();break;case ru:a.sort(this.comparer);break;case su:a.sort(this.comparer);a.reverse();break;default:C("Unhandled sorting value "+this.sorting.toString())}c=
this.arrangementOrigin;b=c.x;c=c.y;for(d=0;d<a.length;d++){var e=a[d];Xu(this,e,b+e.la.x,c+e.la.y);switch(this.Tb){case Xt:c+=e.Da.height+this.$d.height;break;case Yu:b+=e.Da.width+this.$d.width;break;default:C("Unhandled arrangement value "+this.Tb.toString())}}}};function Xu(a,b,c,d){if(null!==b){b.x=c;b.y=d;b=b.children;for(var e=b.length,f=0;f<e;f++){var g=b[f];Xu(a,g,c+g.T.x,d+g.T.y)}}}St.prototype.commitLayout=function(){this.zv();this.commitNodes();this.Hu();this.isRouting&&this.commitLinks()};
St.prototype.commitNodes=function(){for(var a=this.network.vertexes.iterator;a.next();)a.value.commit();for(a.reset();a.next();)this.layoutComments(a.value)};
St.prototype.Hu=function(){if(this.layerStyle===hu){for(var a=this.aw,b=[],c=null,d=this.network.vertexes.iterator;d.next();){var e=d.value;null===c?c=e.bounds.copy():c.ed(e.bounds);var f=b[e.level];void 0===f?f=this.computeLayerSpacing(e):f=Math.max(f,this.computeLayerSpacing(e));b[e.level]=f}for(d=0;d<b.length;d++)void 0===b[d]&&(b[d]=0);90===this.angle||270===this.angle?(c.Bc(this.nodeSpacing/2,this.layerSpacing),d=new I(-this.nodeSpacing/2,-this.layerSpacing/2)):(c.Bc(this.layerSpacing,this.nodeSpacing/
2),d=new I(-this.layerSpacing/2,-this.nodeSpacing/2));e=[];c=90===this.angle||270===this.angle?c.width:c.height;f=0;if(180===this.angle||270===this.angle)for(var g=0;g<a.length;g++)f+=a[g]+b[g];for(g=0;g<a.length;g++){var h=a[g]+b[g];270===this.angle?(f-=h,e.push(new N(0,f,c,h))):90===this.angle?(e.push(new N(0,f,c,h)),f+=h):180===this.angle?(f-=h,e.push(new N(f,0,h,c))):(e.push(new N(f,0,h,c)),f+=h)}this.commitLayers(e,d)}};St.prototype.commitLayers=function(){};
St.prototype.commitLinks=function(){for(var a=this.network.edges.iterator;a.next();)a.value.commit()};St.prototype.zv=function(){for(var a=this.Wb.iterator;a.next();){var b=a.value;b instanceof Zt&&Zu(this,b)}};function Zu(a,b){if(null!==b){a.setPortSpots(b);b=b.children;for(var c=b.length,d=0;d<c;d++)Zu(a,b[d])}}
St.prototype.setPortSpots=function(a){var b=a.alignment;if(tu(b)){var c=this.ud===Ut,d=Fu(a);switch(d){case 0:var e=cd;break;case 90:e=dd;break;case 180:e=bd;break;default:e=ad}var f=a.children,g=f.length;switch(b){case uu:case ku:for(b=0;b<g;b++){var h=f[b];h=(c?h.sourceEdges:h.destinationEdges).first();if(null!==h&&(h=h.link,null!==h)){var k=90===d||270===d?bd:ad;if(1===g||b===g-1&&1===g%2)switch(d){case 0:k=bd;break;case 90:k=ad;break;case 180:k=cd;break;default:k=dd}else 0===b%2&&(k=90===d||270===
d?cd:dd);c?(a.setsPortSpot&&(h.fromSpot=e),a.setsChildPortSpot&&(h.toSpot=k)):(a.setsPortSpot&&(h.fromSpot=k),a.setsChildPortSpot&&(h.toSpot=e))}}break;case vu:d=90===d||270===d?cd:dd;for(f=c?a.destinationEdges:a.sourceEdges;f.next();)g=f.value.link,null!==g&&(c?(a.setsPortSpot&&(g.fromSpot=e),a.setsChildPortSpot&&(g.toSpot=d)):(a.setsPortSpot&&(g.fromSpot=d),a.setsChildPortSpot&&(g.toSpot=e)));break;case wu:for(d=90===d||270===d?bd:ad,f=c?a.destinationEdges:a.sourceEdges;f.next();)g=f.value.link,
null!==g&&(c?(a.setsPortSpot&&(g.fromSpot=e),a.setsChildPortSpot&&(g.toSpot=d)):(a.setsPortSpot&&(g.fromSpot=d),a.setsChildPortSpot&&(g.toSpot=e)))}}else if(c=Fu(a),this.ud===Ut)for(e=a.destinationEdges;e.next();){if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.Zb())switch(c){case 0:d.fromSpot=cd;break;case 90:d.fromSpot=dd;break;case 180:d.fromSpot=bd;break;default:d.fromSpot=ad}else d.fromSpot=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Zb())switch(c){case 0:d.toSpot=bd;break;
case 90:d.toSpot=ad;break;case 180:d.toSpot=cd;break;default:d.toSpot=dd}else d.toSpot=a.childPortSpot}}else for(e=a.sourceEdges;e.next();)if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.Zb())switch(c){case 0:d.toSpot=cd;break;case 90:d.toSpot=dd;break;case 180:d.toSpot=bd;break;default:d.toSpot=ad}else d.toSpot=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Zb())switch(c){case 0:d.fromSpot=bd;break;case 90:d.fromSpot=ad;break;case 180:d.fromSpot=cd;break;default:d.fromSpot=dd}else d.fromSpot=
a.childPortSpot}};function Fu(a){a=a.angle;return 45>=a?0:135>=a?90:225>=a?180:315>=a?270:0}St.prototype.computeLayerSpacing=function(a){var b=Fu(a);b=90===b||270===b;var c=a.layerSpacing;if(0<a.layerSpacingParentOverlap){var d=Math.min(1,a.layerSpacingParentOverlap);c-=b?a.height*d:a.width*d}c<(b?-a.height:-a.width)&&(c=b?-a.height:-a.width);return c};
St.prototype.computeNodeIndent=function(a){var b=Fu(a),c=a.nodeIndent;if(0<a.nodeIndentPastParent){var d=Math.min(1,a.nodeIndentPastParent);c+=90===b||270===b?a.width*d:a.height*d}return c=Math.max(0,c)};
ma.Object.defineProperties(St.prototype,{roots:{get:function(){return this.Wb},set:function(a){this.Wb!==a&&(this.Wb=a,this.C())}},path:{get:function(){return this.Wn},set:function(a){this.Wn!==a&&(this.Wn=a,this.C())}},treeStyle:{get:function(){return this.vo},set:function(a){this.Tb===a||a!==Vt&&a!==nu&&a!==ou&&a!==mu||(this.vo=a,this.C())}},layerStyle:{get:function(){return this.Bq},
set:function(a){this.Tb===a||a!==Wt&&a!==iu&&a!==hu||(this.Bq=a,this.C())}},comments:{get:function(){return this.td},set:function(a){this.td!==a&&(this.td=a,this.C())}},arrangement:{get:function(){return this.Tb},set:function(a){this.Tb===a||a!==Xt&&a!==Yu&&a!==$t||(this.Tb=a,this.C())}},arrangementSpacing:{get:function(){return this.$d},set:function(a){this.$d.D(a)||(this.$d.assign(a),this.C())}},rootDefaults:{
get:function(){return this.W},set:function(a){this.W!==a&&(this.W=a,this.C())}},alternateDefaults:{get:function(){return this.Y},set:function(a){this.Y!==a&&(this.Y=a,this.C())}},sorting:{get:function(){return this.W.sorting},set:function(a){this.W.sorting===a||a!==pu&&a!==qu&&a!==ru&&!su||(this.W.sorting=a,this.C())}},comparer:{get:function(){return this.W.comparer},set:function(a){this.W.comparer!==
a&&(this.W.comparer=a,this.C())}},angle:{get:function(){return this.W.angle},set:function(a){this.W.angle!==a&&(0===a||90===a||180===a||270===a?(this.W.angle=a,this.C()):C("TreeLayout.angle must be 0, 90, 180, or 270"))}},alignment:{get:function(){return this.W.alignment},set:function(a){this.W.alignment!==a&&(this.W.alignment=a,this.C())}},nodeIndent:{get:function(){return this.W.nodeIndent},set:function(a){this.W.nodeIndent!==
a&&0<=a&&(this.W.nodeIndent=a,this.C())}},nodeIndentPastParent:{get:function(){return this.W.nodeIndentPastParent},set:function(a){this.W.nodeIndentPastParent!==a&&0<=a&&1>=a&&(this.W.nodeIndentPastParent=a,this.C())}},nodeSpacing:{get:function(){return this.W.nodeSpacing},set:function(a){this.W.nodeSpacing!==a&&(this.W.nodeSpacing=a,this.C())}},layerSpacing:{get:function(){return this.W.layerSpacing},set:function(a){this.W.layerSpacing!==
a&&(this.W.layerSpacing=a,this.C())}},layerSpacingParentOverlap:{get:function(){return this.W.layerSpacingParentOverlap},set:function(a){this.W.layerSpacingParentOverlap!==a&&0<=a&&1>=a&&(this.W.layerSpacingParentOverlap=a,this.C())}},compaction:{get:function(){return this.W.compaction},set:function(a){this.W.compaction===a||a!==Cu&&a!==Eu||(this.W.compaction=a,this.C())}},breadthLimit:{get:function(){return this.W.breadthLimit},
set:function(a){this.W.breadthLimit!==a&&0<=a&&(this.W.breadthLimit=a,this.C())}},rowSpacing:{get:function(){return this.W.rowSpacing},set:function(a){this.W.rowSpacing!==a&&(this.W.rowSpacing=a,this.C())}},rowIndent:{get:function(){return this.W.rowIndent},set:function(a){this.W.rowIndent!==a&&0<=a&&(this.W.rowIndent=a,this.C())}},commentSpacing:{get:function(){return this.W.commentSpacing},set:function(a){this.W.commentSpacing!==
a&&(this.W.commentSpacing=a,this.C())}},commentMargin:{get:function(){return this.W.commentMargin},set:function(a){this.W.commentMargin!==a&&(this.W.commentMargin=a,this.C())}},setsPortSpot:{get:function(){return this.W.setsPortSpot},set:function(a){this.W.setsPortSpot!==a&&(this.W.setsPortSpot=a,this.C())}},portSpot:{get:function(){return this.W.portSpot},set:function(a){this.W.portSpot.D(a)||(this.W.portSpot=
a,this.C())}},setsChildPortSpot:{get:function(){return this.W.setsChildPortSpot},set:function(a){this.W.setsChildPortSpot!==a&&(this.W.setsChildPortSpot=a,this.C())}},childPortSpot:{get:function(){return this.W.childPortSpot},set:function(a){this.W.childPortSpot.D(a)||(this.W.childPortSpot=a,this.C())}},alternateSorting:{get:function(){return this.Y.sorting},set:function(a){this.Y.sorting===a||a!==pu&&a!==qu&&
a!==ru&&!su||(this.Y.sorting=a,this.C())}},alternateComparer:{get:function(){return this.Y.comparer},set:function(a){this.Y.comparer!==a&&(this.Y.comparer=a,this.C())}},alternateAngle:{get:function(){return this.Y.angle},set:function(a){this.Y.angle===a||0!==a&&90!==a&&180!==a&&270!==a||(this.Y.angle=a,this.C())}},alternateAlignment:{get:function(){return this.Y.alignment},set:function(a){this.Y.alignment!==
a&&(this.Y.alignment=a,this.C())}},alternateNodeIndent:{get:function(){return this.Y.nodeIndent},set:function(a){this.Y.nodeIndent!==a&&0<=a&&(this.Y.nodeIndent=a,this.C())}},alternateNodeIndentPastParent:{get:function(){return this.Y.nodeIndentPastParent},set:function(a){this.Y.nodeIndentPastParent!==a&&0<=a&&1>=a&&(this.Y.nodeIndentPastParent=a,this.C())}},alternateNodeSpacing:{get:function(){return this.Y.nodeSpacing},
set:function(a){this.Y.nodeSpacing!==a&&(this.Y.nodeSpacing=a,this.C())}},alternateLayerSpacing:{get:function(){return this.Y.layerSpacing},set:function(a){this.Y.layerSpacing!==a&&(this.Y.layerSpacing=a,this.C())}},alternateLayerSpacingParentOverlap:{get:function(){return this.Y.layerSpacingParentOverlap},set:function(a){this.Y.layerSpacingParentOverlap!==a&&0<=a&&1>=a&&(this.Y.layerSpacingParentOverlap=a,this.C())}},alternateCompaction:{
get:function(){return this.Y.compaction},set:function(a){this.Y.compaction===a||a!==Cu&&a!==Eu||(this.Y.compaction=a,this.C())}},alternateBreadthLimit:{get:function(){return this.Y.breadthLimit},set:function(a){this.Y.breadthLimit!==a&&0<=a&&(this.Y.breadthLimit=a,this.C())}},alternateRowSpacing:{get:function(){return this.Y.rowSpacing},set:function(a){this.Y.rowSpacing!==a&&(this.Y.rowSpacing=a,this.C())}},alternateRowIndent:{
get:function(){return this.Y.rowIndent},set:function(a){this.Y.rowIndent!==a&&0<=a&&(this.Y.rowIndent=a,this.C())}},alternateCommentSpacing:{get:function(){return this.Y.commentSpacing},set:function(a){this.Y.commentSpacing!==a&&(this.Y.commentSpacing=a,this.C())}},alternateCommentMargin:{get:function(){return this.Y.commentMargin},set:function(a){this.Y.commentMargin!==a&&(this.Y.commentMargin=a,this.C())}},alternateSetsPortSpot:{
get:function(){return this.Y.setsPortSpot},set:function(a){this.Y.setsPortSpot!==a&&(this.Y.setsPortSpot=a,this.C())}},alternatePortSpot:{get:function(){return this.Y.portSpot},set:function(a){this.Y.portSpot.D(a)||(this.Y.portSpot=a,this.C())}},alternateSetsChildPortSpot:{get:function(){return this.Y.setsChildPortSpot},set:function(a){this.Y.setsChildPortSpot!==a&&(this.Y.setsChildPortSpot=a,this.C())}},alternateChildPortSpot:{
get:function(){return this.Y.childPortSpot},set:function(a){this.Y.childPortSpot.D(a)||(this.Y.childPortSpot=a,this.C())}}});
var Tt=new D(St,"PathDefault",-1),Ut=new D(St,"PathDestination",0),au=new D(St,"PathSource",1),pu=new D(St,"SortingForwards",10),qu=new D(St,"SortingReverse",11),ru=new D(St,"SortingAscending",12),su=new D(St,"SortingDescending",13),Qu=new D(St,"AlignmentCenterSubtrees",20),Ru=new D(St,"AlignmentCenterChildren",21),Gu=new D(St,"AlignmentStart",22),Hu=new D(St,"AlignmentEnd",23),uu=new D(St,"AlignmentBus",24),ku=new D(St,"AlignmentBusBranching",25),vu=new D(St,"AlignmentTopLeftBus",26),wu=new D(St,
"AlignmentBottomRightBus",27),Cu=new D(St,"CompactionNone",30),Eu=new D(St,"CompactionBlock",31),Vt=new D(St,"StyleLayered",40),ou=new D(St,"StyleLastParents",41),nu=new D(St,"StyleAlternating",42),mu=new D(St,"StyleRootOnly",43),Xt=new D(St,"ArrangementVertical",50),Yu=new D(St,"ArrangementHorizontal",51),$t=new D(St,"ArrangementFixedRoots",52),Wt=new D(St,"LayerIndividual",60),iu=new D(St,"LayerSiblings",61),hu=new D(St,"LayerUniform",62);St.className="TreeLayout";St.PathDefault=Tt;
St.PathDestination=Ut;St.PathSource=au;St.SortingForwards=pu;St.SortingReverse=qu;St.SortingAscending=ru;St.SortingDescending=su;St.AlignmentCenterSubtrees=Qu;St.AlignmentCenterChildren=Ru;St.AlignmentStart=Gu;St.AlignmentEnd=Hu;St.AlignmentBus=uu;St.AlignmentBusBranching=ku;St.AlignmentTopLeftBus=vu;St.AlignmentBottomRightBus=wu;St.CompactionNone=Cu;St.CompactionBlock=Eu;St.StyleLayered=Vt;St.StyleLastParents=ou;St.StyleAlternating=nu;St.StyleRootOnly=mu;St.ArrangementVertical=Xt;
St.ArrangementHorizontal=Yu;St.ArrangementFixedRoots=$t;St.LayerIndividual=Wt;St.LayerSiblings=iu;St.LayerUniform=hu;function Yt(a){wq.call(this,a)}la(Yt,wq);Yt.prototype.createVertex=function(){return new Zt(this)};Yt.prototype.createEdge=function(){return new $u(this)};Yt.className="TreeNetwork";
function Zt(a){zq.call(this,a);this.V=!1;this.hb=null;this.u=[];this.nb=this.Ha=this.F=this.ia=0;this.td=null;this.T=new I(0,0);this.Da=new M(0,0);this.la=new I(0,0);this.im=this.hm=this.rA=!1;this.kp=this.Yo=null;this.nd=pu;this.hd=Gq;this.Lb=0;this.Kb=Ru;this.Vq=this.Uq=0;this.Wq=20;this.ie=50;this.Aq=0;this.Hp=Eu;this.Ap=0;this.nr=25;this.Gp=this.mr=10;this.Fp=20;this.xr=!0;this.dr=Sc;this.wr=!0;this.Dp=Sc}la(Zt,zq);
Zt.prototype.copyInheritedPropertiesFrom=function(a){null!==a&&(this.nd=a.sorting,this.hd=a.comparer,this.Lb=a.angle,this.Kb=a.alignment,this.Uq=a.nodeIndent,this.Vq=a.nodeIndentPastParent,this.Wq=a.nodeSpacing,this.ie=a.layerSpacing,this.Aq=a.layerSpacingParentOverlap,this.Hp=a.compaction,this.Ap=a.breadthLimit,this.nr=a.rowSpacing,this.mr=a.rowIndent,this.Gp=a.commentSpacing,this.Fp=a.commentMargin,this.xr=a.setsPortSpot,this.dr=a.portSpot,this.wr=a.setsChildPortSpot,this.Dp=a.childPortSpot)};
ma.Object.defineProperties(Zt.prototype,{initialized:{get:function(){return this.V},set:function(a){this.V!==a&&(this.V=a)}},parent:{get:function(){return this.hb},set:function(a){this.hb!==a&&(this.hb=a)}},children:{get:function(){return this.u},set:function(a){if(this.u!==a){if(null!==a)for(var b=a.length,c=0;c<b;c++);this.u=a}}},level:{get:function(){return this.ia},set:function(a){this.ia!==
a&&(this.ia=a)}},descendantCount:{get:function(){return this.F},set:function(a){this.F!==a&&(this.F=a)}},maxChildrenCount:{get:function(){return this.Ha},set:function(a){this.Ha!==a&&(this.Ha=a)}},maxGenerationCount:{get:function(){return this.nb},set:function(a){this.nb!==a&&(this.nb=a)}},comments:{get:function(){return this.td},set:function(a){if(this.td!==a){if(null!==a)for(var b=
a.length,c=0;c<b;c++);this.td=a}}},sorting:{get:function(){return this.nd},set:function(a){this.nd!==a&&(this.nd=a)}},comparer:{get:function(){return this.hd},set:function(a){this.hd!==a&&(this.hd=a)}},angle:{get:function(){return this.Lb},set:function(a){this.Lb!==a&&(this.Lb=a)}},alignment:{get:function(){return this.Kb},set:function(a){this.Kb!==a&&(this.Kb=a)}},nodeIndent:{
get:function(){return this.Uq},set:function(a){this.Uq!==a&&(this.Uq=a)}},nodeIndentPastParent:{get:function(){return this.Vq},set:function(a){this.Vq!==a&&(this.Vq=a)}},nodeSpacing:{get:function(){return this.Wq},set:function(a){this.Wq!==a&&(this.Wq=a)}},layerSpacing:{get:function(){return this.ie},set:function(a){this.ie!==a&&(this.ie=a)}},layerSpacingParentOverlap:{
get:function(){return this.Aq},set:function(a){this.Aq!==a&&(this.Aq=a)}},compaction:{get:function(){return this.Hp},set:function(a){this.Hp!==a&&(this.Hp=a)}},breadthLimit:{get:function(){return this.Ap},set:function(a){this.Ap!==a&&(this.Ap=a)}},rowSpacing:{get:function(){return this.nr},set:function(a){this.nr!==a&&(this.nr=a)}},rowIndent:{get:function(){return this.mr},set:function(a){this.mr!==
a&&(this.mr=a)}},commentSpacing:{get:function(){return this.Gp},set:function(a){this.Gp!==a&&(this.Gp=a)}},commentMargin:{get:function(){return this.Fp},set:function(a){this.Fp!==a&&(this.Fp=a)}},setsPortSpot:{get:function(){return this.xr},set:function(a){this.xr!==a&&(this.xr=a)}},portSpot:{get:function(){return this.dr},set:function(a){this.dr.D(a)||(this.dr=a)}},setsChildPortSpot:{
get:function(){return this.wr},set:function(a){this.wr!==a&&(this.wr=a)}},childPortSpot:{get:function(){return this.Dp},set:function(a){this.Dp.D(a)||(this.Dp=a)}},childrenCount:{get:function(){return this.children.length}},relativePosition:{get:function(){return this.T},set:function(a){this.T.set(a)}},subtreeSize:{get:function(){return this.Da},set:function(a){this.Da.set(a)}},
subtreeOffset:{get:function(){return this.la},set:function(a){this.la.set(a)}}});Zt.className="TreeVertex";function $u(a){Aq.call(this,a);this.$t=new I(0,0)}la($u,Aq);
$u.prototype.commit=function(){var a=this.link;if(null!==a&&!a.isAvoiding){var b=this.network.layout,c=null,d=null;switch(b.ud){case Ut:c=this.fromVertex;d=this.toVertex;break;case au:c=this.toVertex;d=this.fromVertex;break;default:C("Unhandled path value "+b.ud.toString())}if(null!==c&&null!==d){var e=this.$t;if(0!==e.x||0!==e.y||c.rA){d=c.bounds;var f=Fu(c),g=b.computeLayerSpacing(c),h=c.rowSpacing;a.vg();var k=a.curve===bg,l=a.isOrthogonal,m;a.uh();if(l||k){for(m=2;4<a.pointsCount;)a.qv(2);var n=
a.i(1);var p=a.i(2)}else{for(m=1;3<a.pointsCount;)a.qv(1);n=a.i(0);p=a.i(a.pointsCount-1)}var q=a.i(a.pointsCount-1);0===f?(c.alignment===Hu?(f=d.bottom+e.y,0===e.y&&n.y>q.y+c.rowIndent&&(f=Math.min(f,Math.max(n.y,f-b.computeNodeIndent(c))))):c.alignment===Gu?(f=d.top+e.y,0===e.y&&n.y<q.y-c.rowIndent&&(f=Math.max(f,Math.min(n.y,f+b.computeNodeIndent(c))))):f=c.hm||c.im&&1===c.maxGenerationCount?d.top-c.la.y+e.y:d.y+d.height/2+e.y,k?(a.m(m,n.x,f),m++,a.m(m,d.right+g,f),m++,a.m(m,d.right+g+(e.x-h)/
3,f),m++,a.m(m,d.right+g+2*(e.x-h)/3,f),m++,a.m(m,d.right+g+(e.x-h),f),m++,a.m(m,p.x,f)):(l&&(a.m(m,d.right+g/2,n.y),m++),a.m(m,d.right+g/2,f),m++,a.m(m,d.right+g+e.x-(l?h/2:h),f),m++,l&&a.m(m,a.i(m-1).x,p.y))):90===f?(c.alignment===Hu?(f=d.right+e.x,0===e.x&&n.x>q.x+c.rowIndent&&(f=Math.min(f,Math.max(n.x,f-b.computeNodeIndent(c))))):c.alignment===Gu?(f=d.left+e.x,0===e.x&&n.x<q.x-c.rowIndent&&(f=Math.max(f,Math.min(n.x,f+b.computeNodeIndent(c))))):f=c.hm||c.im&&1===c.maxGenerationCount?d.left-c.la.x+
e.x:d.x+d.width/2+e.x,k?(a.m(m,f,n.y),m++,a.m(m,f,d.bottom+g),m++,a.m(m,f,d.bottom+g+(e.y-h)/3),m++,a.m(m,f,d.bottom+g+2*(e.y-h)/3),m++,a.m(m,f,d.bottom+g+(e.y-h)),m++,a.m(m,f,p.y)):(l&&(a.m(m,n.x,d.bottom+g/2),m++),a.m(m,f,d.bottom+g/2),m++,a.m(m,f,d.bottom+g+e.y-(l?h/2:h)),m++,l&&a.m(m,p.x,a.i(m-1).y))):180===f?(c.alignment===Hu?(f=d.bottom+e.y,0===e.y&&n.y>q.y+c.rowIndent&&(f=Math.min(f,Math.max(n.y,f-b.computeNodeIndent(c))))):c.alignment===Gu?(f=d.top+e.y,0===e.y&&n.y<q.y-c.rowIndent&&(f=Math.max(f,
Math.min(n.y,f+b.computeNodeIndent(c))))):f=c.hm||c.im&&1===c.maxGenerationCount?d.top-c.la.y+e.y:d.y+d.height/2+e.y,k?(a.m(m,n.x,f),m++,a.m(m,d.left-g,f),m++,a.m(m,d.left-g+(e.x+h)/3,f),m++,a.m(m,d.left-g+2*(e.x+h)/3,f),m++,a.m(m,d.left-g+(e.x+h),f),m++,a.m(m,p.x,f)):(l&&(a.m(m,d.left-g/2,n.y),m++),a.m(m,d.left-g/2,f),m++,a.m(m,d.left-g+e.x+(l?h/2:h),f),m++,l&&a.m(m,a.i(m-1).x,p.y))):270===f?(c.alignment===Hu?(f=d.right+e.x,0===e.x&&n.x>q.x+c.rowIndent&&(f=Math.min(f,Math.max(n.x,f-b.computeNodeIndent(c))))):
c.alignment===Gu?(f=d.left+e.x,0===e.x&&n.x<q.x-c.rowIndent&&(f=Math.max(f,Math.min(n.x,f+b.computeNodeIndent(c))))):f=c.hm||c.im&&1===c.maxGenerationCount?d.left-c.la.x+e.x:d.x+d.width/2+e.x,k?(a.m(m,f,n.y),m++,a.m(m,f,d.top-g),m++,a.m(m,f,d.top-g+(e.y+h)/3),m++,a.m(m,f,d.top-g+2*(e.y+h)/3),m++,a.m(m,f,d.top-g+(e.y+h)),m++,a.m(m,f,p.y)):(l&&(a.m(m,n.x,d.top-g/2),m++),a.m(m,f,d.top-g/2),m++,a.m(m,f,d.top-g+e.y+(l?h/2:h)),m++,l&&a.m(m,p.x,a.i(m-1).y))):C("Invalid angle "+f);a.zf()}else e=d,a=this.link,
d=this.network.layout,b=Fu(c),b!==Fu(e)&&(g=d.computeLayerSpacing(c),h=c.bounds,c=e.bounds,0===b&&c.left-h.right<g+1||90===b&&c.top-h.bottom<g+1||180===b&&h.left-c.right<g+1||270===b&&h.top-c.bottom<g+1||(a.vg(),c=a.curve===bg,e=a.isOrthogonal,d=tu(this.fromVertex.alignment),a.uh(),0===b?(b=h.right+g/2,c?4===a.pointsCount&&(c=a.i(3).y,a.L(1,b-20,a.i(1).y),a.m(2,b-20,c),a.m(3,b,c),a.m(4,b+20,c),a.L(5,a.i(5).x,c)):e?d?a.L(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.L(2,b,a.i(2).y),a.L(3,b,a.i(3).y)):
4===a.pointsCount?a.m(2,b,a.i(2).y):3===a.pointsCount?a.L(1,b,a.i(2).y):2===a.pointsCount&&a.m(1,b,a.i(1).y)):90===b?(b=h.bottom+g/2,c?4===a.pointsCount&&(c=a.i(3).x,a.L(1,a.i(1).x,b-20),a.m(2,c,b-20),a.m(3,c,b),a.m(4,c,b+20),a.L(5,c,a.i(5).y)):e?d?a.L(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.L(2,a.i(2).x,b),a.L(3,a.i(3).x,b)):4===a.pointsCount?a.m(2,a.i(2).x,b):3===a.pointsCount?a.L(1,a.i(2).x,b):2===a.pointsCount&&a.m(1,a.i(1).x,b)):180===b?(b=h.left-g/2,c?4===a.pointsCount&&(c=a.i(3).y,a.L(1,
b+20,a.i(1).y),a.m(2,b+20,c),a.m(3,b,c),a.m(4,b-20,c),a.L(5,a.i(5).x,c)):e?d?a.L(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.L(2,b,a.i(2).y),a.L(3,b,a.i(3).y)):4===a.pointsCount?a.m(2,b,a.i(2).y):3===a.pointsCount?a.L(1,b,a.i(2).y):2===a.pointsCount&&a.m(1,b,a.i(1).y)):270===b&&(b=h.top-g/2,c?4===a.pointsCount&&(c=a.i(3).x,a.L(1,a.i(1).x,b+20),a.m(2,c,b+20),a.m(3,c,b),a.m(4,c,b-20),a.L(5,c,a.i(5).y)):e?d?a.L(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.L(2,a.i(2).x,b),a.L(3,a.i(3).x,b)):4===a.pointsCount?
a.m(2,a.i(2).x,b):3===a.pointsCount?a.L(1,a.i(2).x,b):2===a.pointsCount&&a.m(1,a.i(1).x,b)),a.zf()))}}};ma.Object.defineProperties($u.prototype,{fromVertex:{get:function(){return this.kd},set:function(a){this.kd!==a&&(this.kd=a)}},toVertex:{get:function(){return this.qd},set:function(a){this.qd!==a&&(this.qd=a)}},relativePoint:{get:function(){return this.$t},set:function(a){this.$t.set(a)}}});$u.className="TreeEdge";
La.prototype.initializeStandardTools=function(){this.gb("Action",new pg,this.mouseDownTools);this.gb("Relinking",new Le,this.mouseDownTools);this.gb("LinkReshaping",new $f,this.mouseDownTools);this.gb("Rotating",new ng,this.mouseDownTools);this.gb("Resizing",new gg,this.mouseDownTools);this.gb("Linking",new Mf,this.mouseMoveTools);this.gb("Dragging",new De,this.mouseMoveTools);this.gb("DragSelecting",new sg,this.mouseMoveTools);this.gb("Panning",new tg,this.mouseMoveTools);this.gb("ContextMenu",new vg,
this.mouseUpTools);this.gb("TextEditing",new Mg,this.mouseUpTools);this.gb("ClickCreating",new qg,this.mouseUpTools);this.gb("ClickSelecting",new og,this.mouseUpTools)};Nn("Horizontal",new Pm);Nn("Spot",new Rm);Nn("Table",new Wm);Nn("Viewbox",new an);Nn("TableRow",new Zm);Nn("TableColumn",new $m);Nn("Graduated",new mn);Nn("Grid",new bn);oi.add("GraphLinksModel",Zq);oi.add("TreeModel",mr);
var av=x.go,bv={get licenseKey(){return Q.licenseKey},set licenseKey(a){Q.licenseKey=a},get version(){return Q.version},Group:jf,EnumValue:D,List:F,Set:H,Map:ob,Point:I,Size:M,Rect:N,Margin:cc,Spot:O,Geometry:P,PathFigure:Qd,PathSegment:Rd,InputEvent:Td,DiagramEvent:Vd,ChangedEvent:ce,Model:Z,GraphLinksModel:Gr,TreeModel:Lr,Binding:zi,Transaction:he,UndoManager:ie,CommandHandler:Mk,Tool:ke,DraggingTool:De,DraggingInfo:Me,DraggingOptions:Ee,LinkingBaseTool:Af,LinkingTool:Mf,RelinkingTool:Le,LinkReshapingTool:$f,
ResizingTool:gg,RotatingTool:ng,ClickSelectingTool:og,ActionTool:pg,ClickCreatingTool:qg,HTMLInfo:Be,ContextMenuTool:vg,DragSelectingTool:sg,PanningTool:tg,TextEditingTool:Mg,ToolManager:La,Animation:hh,AnimationManager:dh,AnimationTrigger:Zh,Layer:di,Diagram:Q,Palette:Gk,Overview:Ak,Brush:vl,GraphObject:Y,Panel:W,RowColumnDefinition:ak,Shape:Bf,TextBlock:Ng,Picture:ik,Part:U,Adornment:pe,Node:V,Link:T,Placeholder:ig,Layout:ti,LayoutNetwork:wq,LayoutVertex:zq,LayoutEdge:Aq,GridLayout:Ik,PanelLayout:dm,
CircularLayout:Nr,CircularNetwork:ds,CircularVertex:rs,CircularEdge:ss,ForceDirectedLayout:ts,ForceDirectedNetwork:us,ForceDirectedVertex:Gs,ForceDirectedEdge:Hs,LayeredDigraphLayout:Is,LayeredDigraphNetwork:Ns,LayeredDigraphVertex:Pt,LayeredDigraphEdge:Qt,TreeLayout:St,TreeNetwork:Yt,TreeVertex:Zt,TreeEdge:$u,GeometryType:{Line:P.o,Rectangle:P.u,Ellipse:P.F,Path:P.j},GeometryStretch:{None:kg,Default:Sk,Fill:nd,Uniform:mg},SegmentType:{Move:Ad,Line:pd,Bezier:Bd,QuadraticBezier:Cd,Arc:Dd,SvgArc:Jd},
ChangeType:{Transaction:ee,Property:de,Insert:fe,Remove:ge},WheelMode:{Scroll:oe,Zoom:ne,None:Ce},GestureMode:{Zoom:qe,Cancel:ye,None:ze},LinkingDirection:{Either:Uf,ForwardsOnly:Vf,BackwardsOnly:Wf},ReshapingBehavior:{None:ag,Horizontal:dg,Vertical:cg,All:eg},TextEditingAccept:{LostFocus:bh,MouseDown:Xg,Tab:Sg,Enter:Rg},TextEditingStarting:{SingleClick:ch,SingleClickSelected:Og,DoubleClick:Yg},TextEditingState:{None:Pg,Active:Tg,Editing:Ug,Validating:$g,Invalid:Vg,Validated:Zg},AnimationStyle:{Default:gh,
AnimateLocations:mh,None:lh},TriggerStart:{Default:$h,Immediate:ai,Bundled:ci},AutoScale:{None:oh,Uniform:nj,UniformToFill:oj},CycleMode:{All:Df,NotDirected:Hf,NotUndirected:Kf,DestinationTree:Ef,SourceTree:Gf},ScrollMode:{Document:Fh,Infinite:Th},CollapsePolicy:{TreeParent:yi,AllParents:Ck,AnyParents:Dk},Stretch:{None:kg,Default:Sk,Vertical:Rk,Horizontal:Qk,Fill:nd},Orientation:{None:Zf,Along:on,Plus90:fn,Minus90:hn,Opposite:Ip,Upright:pn,Plus90Upright:gn,Minus90Upright:jn,Upright45:kn},Flip:{None:kg,
Vertical:Uk,Horizontal:Vk,Both:Wk},BrushType:{Solid:Ml,Linear:Pl,Radial:wl,Pattern:Ol},ColorSpace:{Lab:vm,HSL:Lm},ViewboxStretch:{Uniform:mg,UniformToFill:Tk},Sizing:{Default:Rn,None:Ym,ProportionalExtra:Qn},Wrap:{None:uo,Fit:ro,DesiredSize:Go,BreakAll:wo},TextOverflow:{Clip:Ho,Ellipsis:oo},TextFormat:{Trim:lo,None:yo},ImageStretch:{None:kg,Fill:nd,Uniform:mg,UniformToFill:Tk},LayoutConditions:{None:0,Added:1,Removed:2,Shown:4,Hidden:8,NodeSized:16,GroupLayout:32,NodeReplaced:64,Standard:127},PortSpreading:{None:Ap,
Evenly:mp,Packed:Bp},LinkAdjusting:{None:Zf,End:Fp,Scale:Gp,Stretch:Hp},Routing:{Normal:Ep,Orthogonal:mq,AvoidsNodes:nq},Curve:{None:Zf,Bezier:bg,JumpGap:Yf,JumpOver:Xf},GridAlignment:{Position:Pq,Location:Hq},GridArrangement:{LeftToRight:Iq,RightToLeft:Oq},GridSorting:{Forwards:Mq,Reverse:Nq,Ascending:Jq,Descending:Lq},BindingMode:{OneWay:Fr,TwoWay:Dn},CircularArrangement:{ConstantSpacing:Or,ConstantDistance:as,ConstantAngle:$r,Packed:bs},CircularDirection:{Clockwise:Qr,Counterclockwise:Zr,BidirectionalLeft:Xr,
BidirectionalRight:Yr},CircularSorting:{Forwards:Vr,Reverse:Wr,Ascending:Tr,Descending:Ur,Optimized:Rr},CircularNodeDiameterFormula:{Pythagorean:Sr,Circular:cs},LayeredDigraphCycleRemove:{DepthFirst:Js,Greedy:ct,FromLayers:Os},LayeredDigraphLayering:{OptimalLinkLength:Ks,LongestPathSink:ht,LongestPathSource:jt},LayeredDigraphInit:{DepthFirstOut:Ls,DepthFirstIn:qt,Naive:nt},LayeredDigraphAggressive:{None:ut,Less:Ms,More:vt},LayeredDigraphPack:{None:0,Expand:1,Straighten:2,Median:4,All:15},LayeredDigraphAlign:{None:0,
UpperLeft:1,UpperRight:2,LowerLeft:4,LowerRight:8,All:15},TreePath:{Default:Tt,Destination:Ut,Source:au},TreeSorting:{Forwards:pu,Reverse:qu,Ascending:ru,Descending:su},TreeAlignment:{CenterSubtrees:Qu,CenterChildren:Ru,Start:Gu,End:Hu,Bus:uu,BusBranching:ku,TopLeftBus:vu,BottomRightBus:wu},TreeCompaction:{None:Cu,Block:Eu},TreeStyle:{Layered:Vt,LastParents:ou,Alternating:nu,RootOnly:mu},TreeArrangement:{Vertical:Xt,Horizontal:Yu,FixedRoots:$t},TreeLayerStyle:{Individual:Wt,Siblings:iu,Uniform:hu}};
"object"===typeof av&&av.version&&ya("WARNING: a `go` object on the root object is already defined.  "+("Debug"in av?"debug ":"")+"version: "+av.version+", replaced with version: "+bv.version);Q.prototype.go=bv;x.go=bv; 'undefined'!==typeof module&&'object'===typeof module.exports&&(module.exports='undefined'!==typeof global?global.go:self.go); })();

  "function"===typeof define&&define.amd&&define("undefined"!==typeof global?global.go:self.go);
  