var _paq = _paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function () {
    var u = window.location.origin + "/piwik/";
    _paq.push(['setTrackerUrl', u + 'piwik.php']);
    _paq.push(['setSiteId', '1']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'piwik.js'; s.parentNode.insertBefore(g, s);
})();

/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
//  (function() {
//    var u="https://tatacommunications.matomo.cloud/";
//    _paq.push(['setTrackerUrl', u+'matomo.php']);
//    _paq.push(['setSiteId', '2']);
//    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
//    g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
//  })(); 


// (function() {
// window.__insp = window.__insp || [];
// __insp.push(['wid', 1439594724]);
// var ldinsp = function(){
// if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1439594724&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
// setTimeout(ldinsp, 0);
// })();


